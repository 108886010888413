"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectService = void 0;
const ApiAction_1 = require("../../actions/ApiAction");
const types_1 = require("../types");
const frameworks_1 = require("../../frameworks");
class ProjectService {
    constructor() { }
    uploadImage(params) {
        const type = types_1.UploadImageAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        action.dispatchAction();
    }
    getListProjects(params) {
        const type = types_1.GetListProjectsAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        action.dispatchAction();
    }
    getProject(params) {
        const type = types_1.GetProjectAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        action.dispatchAction();
    }
    createProject(params) {
        const type = types_1.CreateProjectAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        return action.triggerAction();
    }
    createProjectFlow(params) {
        const action = this.createProject(params.createParams);
        Promise.resolve(action).then((res) => {
            params.createParams.callBackParams.successCallback && params.createParams.callBackParams.successCallback(res);
            const state = frameworks_1.store.getState();
            const selectedImages = state.projects.selectedImages;
            const projectId = res && res.project && res.project.id;
            if (!projectId)
                throw 'Không tìm thấy thông tin dự án';
            selectedImages.map((img) => {
                const photo = {
                    imageName: img.orgImgName,
                    imageData: img.base64String,
                    projectId
                };
                const newUploadParams = {
                    inputParams: {
                        photo
                    },
                    callBackParams: {
                        errorCallback: (err) => {
                            params.uploadParams &&
                                params.uploadParams.callBackParams &&
                                params.uploadParams.callBackParams.errorCallback(err);
                        }
                    }
                };
                this.uploadImage(newUploadParams);
            });
        }).catch((err) => {
            params.createParams &&
                params.createParams.callBackParams &&
                params.createParams.callBackParams.errorCallback(err);
        });
    }
    updateProject(params) {
        const type = types_1.UpdateProjectAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        action.dispatchAction();
    }
    deleteProject(params) {
        const type = types_1.DeleteProjectAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        action.dispatchAction();
    }
    getProjectImage(params) {
        const type = types_1.GetProjectImageAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        action.dispatchAction();
    }
    updateSelectedImage(params) {
        const type = types_1.UpdateProjectAction.image;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        action.dispatchAction();
    }
    deleteImage(params) {
        const type = types_1.DeleteImageAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        action.dispatchAction();
    }
    clearSelectedProject(params) {
        const type = types_1.GetProjectAction.clear;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        action.dispatchAction();
    }
    validateDeleteProject(params) {
        const type = types_1.ValidateDeleteProjectAction.type;
        const action = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        action.dispatchAction();
    }
}
exports.ProjectService = ProjectService;
