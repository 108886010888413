import * as React from 'react';
import Table from 'components/tables/base';
import { CustomCellProps } from 'components/tables/base/index.d';
import { Customer } from 'core';
import styled from 'styled-components';
import EditButton from './EditButton';

interface PropsType {
  customers: Array<Customer>;
}

function CustomersTable(props: PropsType) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'NGÀY TẠO',
        accessor: 'created_at',
      },
      {
        Header: 'NGÀY CẬP NHẬT',
        accessor: 'updated_at',
      },
      {
        Header: 'Tên KH',
        accessor: 'full_name',
      },
      {
        Header: 'SĐT',
        accessor: 'phone_no',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'KHU VỰC',
        accessor: 'location',
      },
      {
        Header: 'TÀI CHÍNH',
        accessor: 'budget',
      },
      {
        Header: 'NHU CẦU',
        accessor: 'preference',
      },
      {
        Header: 'NGUỒN KH',
        accessor: 'ad_source',
      },
      {
        Header: '',
        accessor: 'edit_customer',
        Cell: (cellprops: CustomCellProps<Customer>) => {
          return (
            <EditButton
              key={cellprops.row.original.id}
              comfirmMessage={<h3>Chỉnh sửa thông tin</h3>}
              cancelTitle={'Hủy bỏ'}
              confirmTitle={'Lưu'}
              customer={cellprops.row.original}
              onClick={() => {}}
            >
              Sửa
            </EditButton>
          );
        },
      },
    ],
    []
  );

  return (
    <Styles>
      <Table columns={columns} data={props.customers} activeGroup activePermission />
    </Styles>
  );
}


const Styles = styled.div`
  padding: 40px 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      :first-child {
        td {
          :first-child {
            border-top-left-radius: 12px;
          }
          :last-child {
            border-top-right-radius: 12px;
          }
        }
      }
      :last-child {
        td {
          border-bottom: 0;
          :first-child {
            border-bottom-left-radius: 12px;
          }
          :last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    thead th {
      font-family: Propins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #aeaeb2;
      padding: 15px;
      @media (max-width: 1300px) {
        padding: 10px;
      }
    }

    tbody {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      td:last-child {
        .btn-group {
          opacity: 0;
          visibility: hidden;
        }
      }
      tr:hover {
        td:last-child {
          .btn-group {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    tbody th,
    tbody td {
      margin: 0;
      padding: 20px;
      border-bottom: solid 1px rgba(216, 216, 216, 0.5);
      @media (max-width: 1300px) {
        padding: 8px 10px;
      }
      :last-child {
        border-right: 0;
        text-align: left;
      }
    }

    tbody th {
      padding: 12px 15px;
      background-color: #f7f8f8;
      font-family: Propins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: #aeaeb2;
      position: relative;
      @media (max-width: 1300px) {
        font-size: 14px;
        padding: 8px 10px;
      }
    }
    tbody th:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 45px;
      border-radius: 1.5px;
      background-color: #4c7cf3;
      @media (max-width: 1300px) {
        height: 34px;
      }
    }
    tbody td {
      font-family: Propins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #000000;
      @media (max-width: 1300px) {
        font-size: 14px;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  .banner-row td {
    padding: 0; 
  }
  .banner-wrapper {
    padding: 40px 30px;
    width: 100%;
    background-image: url(https://cdn.reiviet.com/pimage/fake-lead-Background.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .banner-wrapper h3 {
    display: flex;
    margin: 0 0 0 10px;
    font-family: Propins-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.38px;
    color: #ffffff;
  }
  .banner-wrapper p {
    margin: 15px 0;
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.3px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    max-width: 500px;
  }
  .banner-wrapper p span {
    color: #f450a5;
    font-family: Propins-Bold;
  }
  .banner-wrapper .btn-upgrade {
    color: #ffffff;
    font-family: Propins-Medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    padding: 8px 16px;
    border-radius: 6px;
    border: solid 1px #ffffff;
  }
  @media (max-width: 1300px) {
    padding: 10px 20px;
  }
`;

export default CustomersTable;
