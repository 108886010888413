import { ChannelsData, VersionsData } from 'core';
import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { History } from 'src/route/Route';

type CampaignProps = {
  campaign: ChannelsData | VersionsData;
  channel: string | number;
};
interface ParamType {
  lpId: string;
}

export default function Campaign(props: CampaignProps): React.ReactElement {
  const { lpId: landingPageId } = useParams<ParamType>();
  const { campaign, channel } = props;
  const { name, num_session, num_visitor, num_conversion, conversion_rate, bounce_rate, scroll_rate, dwelltime_rate } = campaign;
  const colorName = typeof channel === 'string' ? channel.toLowerCase() : channel;
  const history = new History();

  return (
    <tr className="campaign-row">
      <td><a className={`td-line line-${colorName}`}></a></td>
      <td colSpan={1}><Link to={history.generateComponent('CampaignAnalytics', {
        lpId: landingPageId,
        cId: channel,
        campaign: name || 'untitle'
      })}>{name || ''}</Link></td>
      <td colSpan={1}>{num_session}</td>
      <td colSpan={1}>{num_visitor}</td>
      <td colSpan={1}>{num_conversion}</td>
      <td colSpan={1}>{conversion_rate}%</td>
      <td colSpan={1}>{bounce_rate}%</td>
      <td colSpan={1}>{scroll_rate}%</td>
      <td colSpan={1}>{dwelltime_rate}s</td>
    </tr>
  );
}
