import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlatList from 'flatlist-react';
import WarningLimitedFeatureModal from 'pages/menu/modals/WarningLimitedFeatureModal';
import MemberItem from './MemberItem';
import MemberInformationModal from './modals/MemberInformationModal';
import styled from 'styled-components';
import {User} from 'core';

interface PropsType {
  fetchMoreOrders?: () => void;
  renderItemExt?: ((order: Record<string, any>) => Element);
  searchTerm?: string;
  listtype?: string;
  role?: string;
  title?: string;
  data?: any[];
}

const ListMembers: React.FC<PropsType> = (props) => {
  const {
    renderItemExt,
    data, searchTerm = '',
    listtype = 'withoutTitle'
  } = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);
  const currentUser: User = useSelector((state: any) => state.user.user);
  const [user, setCurrentUser] = useState(currentUser);

  const showModal = (user: any) => {
    setCurrentUser(user);
    setIsOpen(true);
  };

  const showWarningModal = () => {
    setIsOpenNeedToUpgradeModalState(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderItem =  (member: any) => {
    if (renderItemExt) {
      return renderItemExt(member);
    }
    return (
      <MemberItem 
        key={member.id}
        showWarningLimitedFeatureModal={showWarningModal}
        showMemberInformationModal={showModal} 
        type={props.role} 
        user={member}
      />
    );
  };

  const countRecord = () => {
    if(searchTerm.length > 2){
      const search = searchTerm.toLowerCase().trim();
      const count = data && data.filter((el: any) => {
        return el.name && el.name.toLowerCase().search(search) > -1 || el.email && el.email.toLowerCase().search(search) > -1 ;
      }).length || 0;
      return count;
    }

    return 1;
  };

  return (
    <Styles>
      <MemberInformationModal isOpen={modalIsOpen} closeModal={closeModal} user={user}/>
      <WarningLimitedFeatureModal 
        isOpen={isOpenNeedToUpgradeModalState} 
        closeModal={() => {setIsOpenNeedToUpgradeModalState(false);}} 
      />
      {data && data.length > 0 && <div className="flatlist-wrapper">
        {props.title && countRecord() > 0 && <div className="group-title">{props.title}</div>}
        <FlatList
          list={data}
          renderItem={renderItem}
          searchTerm={searchTerm}
          searchBy={['name', 'email']}
          searchCaseInsensitive
          renderWhenEmpty={() => <div></div>}
        />
      </div>}
    </Styles>
  );
};
const Styles = styled.div`
  .flatlist-wrapper{
    margin-bottom: 15px;
  }
  .group-title{
    padding: 0 20px;
    margin: 5px 0 8px;
    font-family: Propins;
    font-size: 16px;
  }
`;
export default ListMembers;