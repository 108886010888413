import Table from 'components/tables/base';
import { Purchases } from 'core';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface PropsType {
  payment_histories: Array<Purchases>;
}

function PaymentHistoryTable(props: PropsType) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nhóm',
        accessor: 'team_name',
      },
      {
        Header: 'Loại gói',
        accessor: 'package.name',
      },
      {
        Header: 'Ngày mua',
        accessor: 'created_at',
      },
      {
        Header: 'Ngày hết hạn',
        accessor: 'end_at',
      },
      {
        Header: 'Người mua',
        accessor: 'buyer',
      },
    ],
    []
  );

  return (
    <Styles>
      <Table columns={columns} data={props.payment_histories} />
    </Styles>
  );
}

const Styles = styled.div`
  padding: 40px 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      :first-child {
        td {
          :first-child {
            border-top-left-radius: 12px;
          }
          :last-child {
            border-top-right-radius: 12px;
          }
        }
      }
      :last-child {
        td {
          border-bottom: 0;
          :first-child {
            border-bottom-left-radius: 12px;
          }
          :last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    thead th {
      font-family: Propins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #aeaeb2;
      padding: 15px;
      @media (max-width: 1300px) {
        padding: 10px;
      }
    }

    tbody {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      td:last-child {
        .btn-group {
          opacity: 0;
          visibility: hidden;
        }
      }
      tr:hover {
        td:last-child {
          .btn-group {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    tbody th,
    tbody td {
      margin: 0;
      padding: 20px;
      border-bottom: solid 1px rgba(216, 216, 216, 0.5);
      @media (max-width: 1300px) {
        padding: 8px 10px;
      }
      :last-child {
        border-right: 0;
        text-align: left;
      }
    }

    tbody th {
      padding: 12px 15px;
      background-color: #f7f8f8;
      font-family: Propins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: #aeaeb2;
      position: relative;
      @media (max-width: 1300px) {
        font-size: 14px;
        padding: 8px 10px;
      }
    }
    tbody th:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 45px;
      border-radius: 1.5px;
      background-color: #4c7cf3;
      @media (max-width: 1300px) {
        height: 34px;
      }
    }
    tbody td {
      font-family: Propins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #000000;
      @media (max-width: 1300px) {
        font-size: 14px;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  @media (max-width: 1300px) {
    padding: 10px 20px;
  }
`;

export default PaymentHistoryTable;
