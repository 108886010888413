import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GoogleAdsEnum, GoogleService, ConnectAccountParameter, TeamPermissionService , ConnectFbAccountParams } from 'core';
import * as ReactModal from 'react-modal';
import { Toast } from 'reactstrap';
import { toast } from 'react-toastify';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  maxAllowAccounts: string;
  callbackOriginlocation: string;
  ggAdsCustomer?: any;
  connectedAccounts?: any[];
  userToken?: string;
}
ReactModal.setAppElement('#root');

export const ConnectAccountGoogleModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  // const fbAccessToken = useSelector((state) => state.adsCampaign.fbAccessToken);
  const customers = props.ggAdsCustomer && props.ggAdsCustomer.data ? props.ggAdsCustomer.data.customers : [];
  const connectedAccounts = props.connectedAccounts || [];
  const { userToken } = props;
  const ggAdsAccountIds = connectedAccounts
    .filter((acc: any) => acc.account_type == GoogleAdsEnum.ConnectedAccountType.GoogleAds)
    .map((acc: any) => (acc.provider_account_id));

  const [customerChecked, setCustomerChecked] = useState({});

  const history = (globalThis as any).useHistory;
  history.hook();
  
  const service = new GoogleService();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const item = event.target.value;
    
    setCustomerChecked({
      ...customerChecked, 
      [item]: isChecked,
    });
  };

  const handleConnect = () => {
    const connectedIds = Object.keys(customerChecked).filter(key => customerChecked[key]);
    const connectedCustomers = customers.filter((c: any) => connectedIds.includes(c.id));
    const maxAllowAccounts = +props.maxAllowAccounts;
    if (connectedCustomers.length == 0) {
      toast.warn('Vui lòng chọn ít nhất 1 tài khoản quảng cáo');
      return;
    }
    if (connectedCustomers.length > maxAllowAccounts) {
      toast.warn('Bạn chỉ được quyền kết nối thêm với ' + maxAllowAccounts + ' tài khoản');
      return;
    }
    const customersParam = connectedCustomers.map((c: any) => ({
      provider_account_id: c.id,
      name: c.name,
      extra_info: c.login_customer_id,
      status: GoogleAdsEnum.ConnectedAccountStatus.Connected,
      access_token: props.ggAdsCustomer && props.ggAdsCustomer.data ? props.ggAdsCustomer.data.refreshToken : '',
      account_type: GoogleAdsEnum.ConnectedAccountType.GoogleAds,
    }));

    const params: ConnectFbAccountParams = {
      inputParams: {
        accounts: customersParam,
        setUserToken: userToken
      },
      callBackParams: {
        successCallback: (res: any) => {
          closeModal();    
          service.fetchConnectedAccount();
          const path = teamPermissionService.CanUseAdCreation ? '/create-google-campaign' : '/google-ads-dashboard';
          if (props.callbackOriginlocation) {   
            window.top.location.href = `${props.callbackOriginlocation}/dashboard${path}`;
          } else {   
            window.top.location.href = history.url(path);
          }
        },
        errorCallback: (err: any) => {
          console.log('onErrGG', err);
          // closeModal();
        }
      }
    };

    service.connectAccount(params);
  };

  const checkedCount = Object.keys(customerChecked).filter(key => customerChecked[key]).length;
  const disabledCheckbox = checkedCount >= +props.maxAllowAccounts;
  
  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="connect-account-modal">
        <div className="modal__heading">
          <h3>Kết nối tài khoản Google</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-890 -65) translate(355 47) translate(527 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal__content">
          <div>Lựa chọn tài khoản Google Ads để kết nối. Tài khoản của bạn sẽ được thêm vào Tài khoản người quản lý Google Ads của cokhach.vn.
          Bạn sẽ nhận được email xác nhận từ Google.
          Bạn có thể liên kết thêm tối đa {props.maxAllowAccounts} tài khoản quảng cáo.</div>
          <div className="form-check">
            {customers ? customers.map((c: any) => 
              <div className="d-flex align-items-center" key={c.id}>
                <input
                  className="form-check-input"
                  id={c.id}
                  type="checkbox"
                  name="googleAccount"
                  value={c.id}
                  onChange={handleChange}
                  checked={customerChecked[c.id] || ggAdsAccountIds.includes(c.id) || false}
                  disabled={(!customerChecked[c.id] && disabledCheckbox) || ggAdsAccountIds.includes(c.id)}
                />
                <label className="radio-label ml-2 mr-2" htmlFor={`${c.id}`}>{`${c.name}`} ({`${c.id}`})</label>
              </div>) : null
            }
          </div>
        </div>
        <div className="modal__bottom">
          <button onClick={handleConnect} className="btn-default ml-3">Kết nối</button>
        </div>
      </div>
    </ReactModal>
  );
};

ConnectAccountGoogleModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, calc(-50% + 40px))'
    }
  }
};