"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Customer = void 0;
class Customer {
    constructor(id, full_name, phone_no, email, location, budget, preference, ad_source, landing_page_id, landing_page_version, created_at, updated_at, pid, pname, is_owner) {
        this.id = id;
        this.full_name = full_name;
        this.phone_no = phone_no;
        this.email = email;
        this.location = location;
        this.budget = budget;
        this.preference = preference;
        this.ad_source = ad_source;
        this.landing_page_id = landing_page_id;
        this.landing_page_version = landing_page_version;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.pid = pid;
        this.pname = pname;
        this.is_owner = is_owner || false;
    }
    get groupId() {
        return this.pid;
    }
    get groupName() {
        return this.pname;
    }
}
exports.Customer = Customer;
