"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdGroupAdDataInfoService = void 0;
const frameworks_1 = require("../../frameworks");
const BaseDataInfoService_1 = require("./BaseDataInfoService");
const ads_campaign_1 = require("../../ads-campaign");
class AdGroupAdDataInfoService extends BaseDataInfoService_1.BaseDataInfoService {
    setOperation(adGroupAds) {
        const state = frameworks_1.store.getState();
        const oldAdGroupAds = state.adsCampaign.AdGroupAds ? state.adsCampaign.AdGroupAds : [];
        return this.setArrayOperation(oldAdGroupAds, adGroupAds, true);
    }
    removeAdGroup(adGroupAds) {
        const state = frameworks_1.store.getState();
        if (!state.adsCampaign.AdGroups) {
            return undefined;
        }
        const adGroups = state.adsCampaign.AdGroups.cloneResource();
        const adGroupResourceNames = adGroupAds.filter(x => x.operation !== ads_campaign_1.OperationTypes.REMOVE);
        adGroups.keyword_groups = adGroups.keyword_groups.filter(x => adGroupResourceNames.some(y => (x.resource_name && x.resource_name === y.ad_group_resource_name)));
        return adGroups;
    }
    needRemoveAdGroup(keywordData) {
        if (!keywordData) {
            return false;
        }
        const state = frameworks_1.store.getState();
        if (!state.adsCampaign.AdGroups) {
            return false;
        }
        return state.adsCampaign.AdGroups.keyword_groups.length !== keywordData.keyword_groups.length;
    }
}
exports.AdGroupAdDataInfoService = AdGroupAdDataInfoService;
