"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticService = void 0;
const types_1 = require("../types");
const ApiAction_1 = require("../../actions/ApiAction");
class AnalyticService {
    constructor() { }
    getTotalUserAnalytics(params) {
        const type = types_1.UserAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getTotalSessionAnalytics(params) {
        const type = types_1.SessionAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getTotalConversionAnalytics(params) {
        const type = types_1.ConversionAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getConversionRateAnalytics(params) {
        const type = types_1.ConversionRateAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    setAnalyticDuration(params) {
        const type = types_1.UpdateAnalyticAction.setDuration;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        apiAction.dispatchAction();
        params.callBackParams && params.callBackParams.successCallback();
    }
    getDataRealtime(params) {
        const type = types_1.RealtimeAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
        // type: GET_DATA_REALTIME,
        // landingPageId
    }
    updateDetailDataInAnalyticsRealtime(params) {
        const type = types_1.UpdateAnalyticAction.updateRealtime;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
        // type: UPDATE_DETAIL_DATA_IN_REALTIME_ANALYTICS,
        // data
    }
    getLandingPageOverviewAnalytics(params) {
        const type = types_1.LandingpageOverviewAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
        // type: GET_LANDINGPAGE_OVERVIEW_ANALYTICS
    }
    updateDataLastMinuteInRealtimeAnalytics(params) {
        const type = types_1.UpdateAnalyticAction.updateLastMinute;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
        // type: UPDATE_DATA_LAST_MINUTE_IN_REALTIME_ANALYTICS
        // 
    }
    updateDataByChannelInRealtimeAnalytics(params) {
        const type = types_1.UpdateAnalyticAction.updateChannel;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        apiAction.dispatchAction();
        // type: UPDATE_DATA_BY_CHANNEL_IN_REALTIME_ANALYTICS
    }
    getDashboardAnalytics(params) {
        const type = types_1.DashboardAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
        // type: GET_DASHBOARD_ANALYTICS,
        // landingPageId
    }
    getChannelAnalytics(params) {
        const type = types_1.ChannelAnalyticAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
        // type: GET_CHANNEL_ANALYTICS,
        // landingPageId,
        // channelId
    }
}
exports.AnalyticService = AnalyticService;
