"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamPermissionService = void 0;
const frameworks_1 = require("../../frameworks");
class TeamPermissionService {
    constructor(team) {
        this._team = team;
    }
    static getTeamPermission(teamId) {
        const state = frameworks_1.store.getState();
        const team = state.team.teams.find(x => {
            if (!teamId || teamId === 0) {
                return x.id === null || x.id === undefined;
            }
            else {
                return x.id === teamId;
            }
        });
        return new TeamPermissionService(team);
    }
    static getCurrentTeamPermission() {
        const findCurrentTeam = () => {
            const state = frameworks_1.store.getState();
            if (state.team.selectedTeam !== null) {
                return state.team.teams.find((x) => { var _a; return x.id === ((_a = state.team.selectedTeam) === null || _a === void 0 ? void 0 : _a.team.id); });
            }
            else {
                // Home is current team.
                return state.team.teams.find((x) => { return x.id === null || x.id === undefined; });
            }
        };
        return new TeamPermissionService(findCurrentTeam());
    }
    get WorkingTeam() {
        return this._team;
    }
    get LicensePackage() {
        var _a, _b;
        return (_b = (_a = this._team) === null || _a === void 0 ? void 0 : _a.license_infor) === null || _b === void 0 ? void 0 : _b.package;
    }
    get TeamName() {
        var _a;
        return (_a = this._team) === null || _a === void 0 ? void 0 : _a.name;
    }
    get TeamMemberNum() {
        var _a;
        return (_a = this._team) === null || _a === void 0 ? void 0 : _a.num_members;
    }
    get PackageName() {
        var _a, _b;
        return (_b = (_a = this._team) === null || _a === void 0 ? void 0 : _a.license_infor) === null || _b === void 0 ? void 0 : _b.package.name;
    }
    get MaxTeamSize() {
        return this._getNumberByKey('max_team_size');
    }
    get MinTeamSize() {
        switch (this.MaxTeamSize) {
            case 5:
                return 1;
            case 10:
                return 6;
            case 20:
                return 11;
            case 40:
                return 21;
            default:
                return 1;
        }
    }
    get IsHomeTeam() {
        return !this._team || !this._team.id || this._team.id === 0;
    }
    get DisplayPackageName() {
        var _a;
        if (!this._team) {
            return '';
        }
        if (this.IsHomeTeam) {
            return (_a = this._team.license_infor) === null || _a === void 0 ? void 0 : _a.package.name;
        }
        return `${this._team.license_infor.package.name} cho nhóm ${this.MinTeamSize} đến ${this.MaxTeamSize} thành viên`;
    }
    get CanInviteTeamMember() {
        if (!this._team) {
            return false;
        }
        if (this.IsHomeTeam) {
            return false;
        }
        return this._team.num_members < this.MaxTeamSize;
    }
    get CanChangeTeamRole() {
        return this._getBooleanByKey('can_change_team_role');
    }
    get MaxAdwordAccount() {
        return this._getNumberByKey("max_adword_account_per_user");
    }
    get MaxAdwordAccountCanConnect() {
        const connectedAccountGroup = this._getConnectedAccount();
        const numConnectedAccount = connectedAccountGroup ? connectedAccountGroup.facebook.length + connectedAccountGroup.google.length : 0;
        const maxAccounts = this.MaxAdwordAccount - numConnectedAccount;
        return maxAccounts > 0 ? maxAccounts : 0;
    }
    get CanConnectMoreAccount() {
        return this.MaxAdwordAccountCanConnect > 0;
    }
    get CanUseExternalDomain() {
        return this._getBooleanByKey('can_use_external_domain');
    }
    get CanUseChatBot() {
        return this._getBooleanByKey('can_use_chatbot');
    }
    get CanUseAdCreation() {
        return this._getBooleanByKey('can_use_ad_creation');
    }
    get CanUseAnalytics() {
        return this._getBooleanByKey('can_use_analytics');
    }
    get CanRemoveTeamMember() {
        return this._getBooleanByKey('can_remove_team_member');
    }
    get MaxLeadPerMonth() {
        return this._getNumberByKey("max_contact_for_team_per_month");
    }
    _findPackagePermissionByKey(key) {
        var _a;
        if (!this._team) {
            return undefined;
        }
        return (_a = this._team.license_infor) === null || _a === void 0 ? void 0 : _a.package.permissions.find(x => x.key === key);
    }
    _getBooleanByKey(key) {
        const permission = this._findPackagePermissionByKey(key);
        if (!permission) {
            return false;
        }
        return !!permission.value;
    }
    _getNumberByKey(key) {
        const permission = this._findPackagePermissionByKey(key);
        if (!permission) {
            return 0;
        }
        return +permission.value;
    }
    _getConnectedAccount() {
        const state = frameworks_1.store.getState();
        return state.adsCampaign.connectedAccountGroup;
    }
}
exports.TeamPermissionService = TeamPermissionService;
// export class PermissionInfoService {
//   static getLicenseInfo() : LicenseInfor {
//     const state = store.getState();
//     return state.team.licenseInfor;
//   }
//   static getLicensePackage() : TeamPackage | undefined {
//     return PermissionInfoService.getLicenseInfo()?.package;
//   }
//   static licensePackageName() : string {
//     const licensPackage = PermissionInfoService.getLicensePackage();
//     const name = licensPackage ? licensPackage.name : '';
//     const maxTeamSize = UserPermissionService.maxTeamSize();
//     const fullName = maxTeamSize === 1 ? 
//       name : 
//       `${name} cho nhóm ${maxTeamSize - 4} đến ${maxTeamSize} thành viên`;
//     return fullName;
//   }
//   static getPackagePermissions() : PackagePermission[] | undefined {
//     return PermissionInfoService.getLicensePackage()?.permissions;
//   }
//   static findPackagePermissionByKey(key: string) : PackagePermission | undefined {
//     const permissions = PermissionInfoService.getPackagePermissions();
//     return permissions?.find(x => x.key === key);
//   }
//   static getBooleanByKey(key: string) : boolean {
//     const permission = PermissionInfoService.findPackagePermissionByKey(key);
//     if (!permission) {
//       return false;
//     }
//     return !!permission.value;
//   }
//   static getNumberByKey(key: string) : number {
//     const permission = PermissionInfoService.findPackagePermissionByKey(key);
//     if (!permission) {
//       return 0;
//     }
//     return +permission.value;
//   }
//   static getWorkingTeam() : Team | null {
//     const state = store.getState();
//     if (!state.team.selectedTeam) {
//       return null;
//     }
//     return state.team.selectedTeam.team;
//   }
//   static getConnectedAccount() : AdsAccount | null {
//     const state = store.getState();
//     return state.adsCampaign.connectedAccountGroup;
//   }
// }
// export class UserPermissionService {  
//   static maxTeamSize() : number {
//     return PermissionInfoService.getNumberByKey("max_team_size");
//   }
//   static minTeamSize() : number {
//     return PermissionInfoService.getNumberByKey("min_team_size");
//   }
//   static canInviteTeamMember() : boolean {
//     const workingTeam = PermissionInfoService.getWorkingTeam();
//     if (!workingTeam) {
//       return false;
//     }
//     return UserPermissionService.maxTeamSize() > workingTeam.num_members;
//   }
//   static canChangeTeamRole() : boolean {
//     return PermissionInfoService.getBooleanByKey('can_change_team_role');
//   }
//   static maxAdwordAccount() : number {
//     return PermissionInfoService.getNumberByKey("max_adword_account_per_user");
//   }
//   static maxAdwordAccountCanConnect() : number {
//     const connectedAccountGroup = PermissionInfoService.getConnectedAccount();
//     const numConnectedAccount = connectedAccountGroup ? connectedAccountGroup.facebook.length + connectedAccountGroup.google.length : 0;
//     const maxAccounts =  UserPermissionService.maxAdwordAccount() - numConnectedAccount;
//     return maxAccounts > 0 ? maxAccounts : 0;
//   }
//   static canConnectMoreAccount() : boolean {
//     return UserPermissionService.maxAdwordAccountCanConnect() > 0;
//   }
//   static canUseExternalDomain() : boolean {
//     return PermissionInfoService.getBooleanByKey('can_use_external_domain');
//   }
//   static canUseChatBot() : boolean {
//     return PermissionInfoService.getBooleanByKey('can_use_chatbot');
//   }
//   static canUseAdCreation() : boolean {
//     return PermissionInfoService.getBooleanByKey('can_use_ad_creation');
//   }
//   static canUseAnalytics() : boolean {
//     return PermissionInfoService.getBooleanByKey('can_use_analytics');
//   }
//   static canRemoveTeamMember() : boolean {
//     return PermissionInfoService.getBooleanByKey('can_remove_team_member');
//   }
//   static maxLeadPerMonth() : number {
//     return PermissionInfoService.getNumberByKey("max_contact_for_team_per_month");
//   }
// }
