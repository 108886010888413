import { Cookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { CookieSetOptions } from 'universal-cookie';

export interface TokenCookies{
  userToken: string;
  masterUserToken?: string;
  fanpageData?: string;
  isRefreshConnectFb?: string;
}

export enum Token {
  UserToken = 'userToken',
  MasterUserToken = 'masterUserToken',
  FanpageData = 'fanpageData',
  IsRefreshConnectFb = 'isRefreshConnectFb',
}

export const useCookie = (cookies: Cookies) => {
  if (!cookies) throw 'Cookies function is not available';
  const userTokenSelector: TokenCookies = useSelector((state: any) => state.auth);
  const modifyKeyBasedOnEnv = (key: string): string => {
    if (process.env.REACT_APP_ENV !== 'production') {
      return `${key}_test`;
    }

    return key;
  };

  const keys = {
    [Token.UserToken]: modifyKeyBasedOnEnv('user_token'),
    [Token.MasterUserToken]: modifyKeyBasedOnEnv('master_token'),
    [Token.FanpageData]: modifyKeyBasedOnEnv('fanpage_data'),
    [Token.IsRefreshConnectFb]: modifyKeyBasedOnEnv('is_refresh_connect_fb'),
  };

  const keyOption = (option?: CookieSetOptions): CookieSetOptions=>{
    if(!option) option = {};
    return {
      domain: `${process.env.REACT_APP_DOMAIN === 'localhost' ? '' : '.'}${process.env.REACT_APP_DOMAIN}`,
      sameSite: 'lax',
      path: '/',
      ...option
    };
  };
  
  const getAllCookies = (key?: Token): TokenCookies | string => {
    const tokenStoraged = cookies.getAll();
    if(key) return tokenStoraged[keys[key]];
    return {
      userToken: tokenStoraged[keys[Token.UserToken]],
      masterUserToken: tokenStoraged[keys[Token.MasterUserToken]],
    };
  };

  const removeAllCookies = () => {
    Object.values(keys).map((key: string)=>{
      cookies.remove(key, keyOption());
    });
  };

  const setCookie = (name: string, value: string, option?: CookieSetOptions) => {
    const key = keys[name];
    if(!key) throw 'Please define your cookie keys first';
    cookies.set(key, value, keyOption(option));
  };

  const setCookies = (token?: TokenCookies, option?: CookieSetOptions) => {
    const cookies = {
      ...token,
      [Token.UserToken]: token && token[Token.UserToken] ? token[Token.UserToken] : userTokenSelector.userToken,
      [Token.MasterUserToken]: token && token[Token.MasterUserToken] ? token[Token.MasterUserToken] : userTokenSelector.masterUserToken,
    };

    const allCookies = Object.keys(cookies);
    allCookies.map((key: string)=>{
      setCookie(key, cookies[key], option);
    });
  };

  return {
    modifyKeyBasedOnEnv,
    getAllCookies,
    removeAllCookies,
    setCookies,
    keyOption,
    setCookie,
  };
};