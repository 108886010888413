import React, { useState, useEffect } from 'react';
import { CampaignTypes } from 'core';
import classNames from 'classnames';

export const CampaignTypeField: React.FC<{campaignType: CampaignTypes; onChange: (type: CampaignTypes) => void}> = (props) => {
  const { campaignType, onChange } = props;
  return <div className="row">
    <div className="col-6" onClick={() => onChange(CampaignTypes.Search)}>
      <div className={classNames('box-shadow', { 'active': campaignType === CampaignTypes.Search })}>
        {campaignType === CampaignTypes.Search && <div className="icon-anchor">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#FFF"> <g> <g> <g> <g> <path d="M0 0H12.5V12.5H0z" opacity=".01" transform="translate(-447 -311) translate(434 258) translate(0 40) translate(10 10) translate(3.75 3.75)"/> <path d="M5.185 10.805c-.295 0-.518-.108-.72-.352l-2.68-3.29c-.16-.187-.222-.364-.222-.55 0-.43.316-.741.756-.741.26 0 .446.098.617.31l2.228 2.81 4.364-6.903c.181-.29.368-.394.663-.394.436 0 .746.305.746.73 0 .161-.051.332-.17.519l-4.851 7.483c-.171.254-.415.378-.731.378z" transform="translate(-447 -311) translate(434 258) translate(0 40) translate(10 10) translate(3.75 3.75)"/> </g> </g> </g> </g> </g> </g> </svg>
        </div>}
        <div className="icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" viewBox="0 0 100 80"> <g fill="none" fillRule="evenodd"> <g fillRule="nonzero"> <g> <g> <g transform="translate(-440 -351) translate(355 306) translate(0 30) translate(85 15)"> <rect width="100" height="16" fill="#E8E8E8" rx="4"/> <path fill="#FFF" stroke="#E8E8E8" strokeWidth=".5" d="M99.75 14.917V76c0 1.036-.42 1.973-1.098 2.652-.679.678-1.616 1.098-2.652 1.098h0H4c-1.036 0-1.973-.42-2.652-1.098C.67 77.973.25 77.036.25 76h0V14.917h99.5z"/> <path fill="#FFF" d="M19 4H75V12H19z"/> <path fill="silver" d="M8 25L59 25 59 29 8 29zM8 57L59 57 59 61 8 61z"/> <path fill="#E7E7E7" d="M22 35L51 35 51 39 22 39zM8 43L75 43 75 47 8 47zM8 65L75 65 75 69 8 69z"/> <path fill="#FFC107" d="M8 33H18V39H8z"/> <path fill="silver" d="M8 6.667L10 6.667 10 12 8 12zM3 4L7 4 7 12 3 12z"/> <path fill="#D8D8D8" d="M11 6.667L13 6.667 13 12 11 12z"/> <path fill="silver" d="M14 6.667L16 6.667 16 12 14 12zM95 10.83c0 .643-.546 1.17-1.214 1.17H80.214C79.546 12 79 11.473 79 10.83V5.17c0-.643.546-1.17 1.214-1.17h13.572C94.454 4 95 4.527 95 5.17v5.66"/> <path fill="#FFF" d="M88.573 8.644h-.227l-.079-.078c.28-.324.45-.746.45-1.208 0-1.026-.833-1.858-1.86-1.858C85.832 5.5 85 6.332 85 7.358c0 1.026.831 1.858 1.858 1.858.462 0 .883-.168 1.208-.447l.078.079v.226l1.43 1.426.426-.426-1.427-1.43zm-1.715 0c-.71 0-1.287-.576-1.287-1.286 0-.71.576-1.286 1.287-1.286.71 0 1.286.576 1.286 1.286 0 .71-.576 1.286-1.286 1.286z"/> <path fill="#E8E8E8" d="M0 14H100V16H0z"/> </g> </g> </g> </g> </g> </svg>
        </div>
        <div className="box-title">Tìm kiếm</div>
        <p className="box-description">Tiếp cận những khách hàng quan tâm đến sản phẩm hoặc dịch vụ của bạn bằng quảng cáo văn bản</p>
      </div>
    </div>
    <div className="col-6">
      <div className="box-shadow">
        <div className="icon-wrapper blur">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" viewBox="0 0 100 80"> <g fill="none" fillRule="evenodd" opacity=".148"> <g> <g> <g> <g> <g transform="translate(-740 -351) translate(355 306) translate(300 30) translate(15 15) translate(70)"> <path fill="#FFF" fillRule="nonzero" stroke="#E8E8E8" strokeWidth=".5" d="M99.75 16.25V76c0 1.036-.42 1.973-1.098 2.652-.679.678-1.616 1.098-2.652 1.098h0H4c-1.036 0-1.973-.42-2.652-1.098C.67 77.973.25 77.036.25 76h0V16.25h99.5z"/> <rect width="100" height="16" fill="#E8E8E8" fillRule="nonzero" rx="4"/> <path d="M6 2L17.4 2 17.4 13.4 6 13.4z"/> <path fill="#E7E7E7" fillRule="nonzero" d="M8 56h38c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2v-2c0-1.1.9-2 2-2zM8 66h38c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2v-2c0-1.1.9-2 2-2z"/> <path fill="#F5F5F5" fillRule="nonzero" d="M52 22L94 22 94 52 52 52z"/> <path fill="#E7E7E7" fillRule="nonzero" d="M8 22h38c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V24c0-1.1.9-2 2-2z"/> <path fill="silver" fillRule="nonzero" d="M58 26L86 26 86 30 58 30z"/> <path fill="#E7E7E7" fillRule="nonzero" d="M58 42L86 42 86 46 58 46zM70 35L86 35 86 39 70 39z"/> <path fill="#FFC107" fillRule="nonzero" d="M58 33H68V39H58z"/> <path fill="#E8E8E8" fillRule="nonzero" d="M0 14H100V16H0z"/> </g> </g> </g> </g> </g> </g> </svg>
        </div>
        <div className="box-title blur">Hiển thị</div>
        <p className="box-description">Đang cập nhật</p>
      </div>
    </div>
  </div>;
};