import * as React from 'react';
import { Link } from 'react-router-dom';
import 'stylesheets/App.css';

const EmptyPage: React.FC = () => {

  return (
    <div className="empty-content">
      <div className="media align-items-center">
        <img className="mr-5" src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/495x356/landingpage-empty.png`} alt="empty landing page" />
        <div className="media-body">
          <h3>Tạo landing page đầu tiên của bạn</h3>
          <p>Danh sách landing page của bạn sẽ được hiển thị ở đây.</p>
          <Link to={(globalThis as any).useHistory.getPath('/choose-project')}>Tạo ngay</Link>
        </div>
      </div>
    </div>
  );
};


export { EmptyPage };
