"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleCampaignClass = exports.AdGroupClass = exports.AdGroupAdClass = void 0;
const datatypes_1 = require("../ads-campaign/types/datatypes");
const moment = require("moment");
const optCreate = (fields, addMoreEl) => {
    const options = addMoreEl ? Object.assign({}, addMoreEl) : {};
    if (!options.operation) {
        options.operation = 'create';
    }
    return fields.map((el) => (Object.assign(Object.assign({}, el), options)));
};
class AdGroupAdClass {
    constructor() {
    }
}
exports.AdGroupAdClass = AdGroupAdClass;
class AdGroupClass {
    constructor() {
        this.campaign_resource_name = '';
        this.adgroup_resource_name = '';
        this.keyword_groups = [];
        this.negative_keywords = [];
    }
    getAdGroupResourceName() {
        return this.adgroup_resource_name;
    }
    setAdGroupResourceName(adgroup_resource_name) {
        this.adgroup_resource_name = adgroup_resource_name;
    }
    getKeywordData() {
        try {
            let keyword_groups = this.keyword_groups.map((el) => {
                el.keywords = optCreate(el.keywords);
                el.ages = optCreate(el.ages, { "negative": false });
                return el;
            });
            keyword_groups = optCreate(keyword_groups);
            return {
                campaign_resource_name: this.campaign_resource_name,
                keyword_groups,
                negative_keywords: optCreate(this.negative_keywords),
            };
        }
        catch (err) {
            console.error(err);
        }
    }
}
exports.AdGroupClass = AdGroupClass;
class GoogleCampaignClass {
    constructor() {
        const start_date = new Date();
        this.start_date = moment(start_date).format('YYYY-MM-DD');
        this.campaign_type = 0;
        this.landing_page_id = 0;
        this.account_id = 0;
        this.name = '';
        this.operation = 'update';
        this.resource_name = '';
    }
    getCampaignName() {
        return this.name;
    }
    getProjectName() {
        return '';
    }
    getAges() {
        return this.ages || [];
    }
    getCampaignResourceName() {
        return this.resource_name;
    }
    getAdGroupInput() {
        // if(!this.ages) throw Error('ages not found')
        // if(!this.landing_page_id) throw Error('landing_page_id not found')
        return {
            ages: this.ages || [],
            landing_page_id: this.landing_page_id,
        };
    }
    getInitialData() {
        return {
            name: this.name,
            account_id: this.account_id,
            landing_page_id: this.landing_page_id,
            campaign_type: this.campaign_type
        };
    }
    getCurrentCampaignData() {
        const data = {
            name: this.name,
            account_id: this.account_id,
            landing_page_id: this.landing_page_id,
            campaign_type: this.campaign_type,
            start_date: this.start_date,
            end_date: this.end_date,
            daily_schedules: this.daily_schedules,
            languages: this.languages,
            locations: this.locations,
            bidding_strategy: this.bidding_strategy,
            campaign_budget: this.campaign_budget,
            ages: this.ages,
            devices: this.devices,
            site_link: this.site_link,
            call: this.call,
            operation: this.operation,
            resource_name: this.resource_name,
        };
        return data;
    }
    filterDailySchedule(data) {
        //TODO: update more filter conditions here
        // - filter duplicate schedule
        let daily_schedules = data.filter(el => !(el.start_hour === el.end_hour && el.start_minute === el.end_minute));
        return daily_schedules;
    }
    generateSchedule(day, option = {}) {
        const weekdays = [datatypes_1.GoogleAdsEnum.DayOfWeek.MONDAY, datatypes_1.GoogleAdsEnum.DayOfWeek.TUESDAY, datatypes_1.GoogleAdsEnum.DayOfWeek.WEDNESDAY, datatypes_1.GoogleAdsEnum.DayOfWeek.THURSDAY, datatypes_1.GoogleAdsEnum.DayOfWeek.FRIDAY];
        const weekends = [datatypes_1.GoogleAdsEnum.DayOfWeek.SATURDAY, datatypes_1.GoogleAdsEnum.DayOfWeek.SUNDAY];
        const everydays = [...weekdays, ...weekends];
        const generateDay = (input, option = {}) => {
            return input.map((el) => {
                return Object.assign(Object.assign({ start_hour: 0, start_minute: datatypes_1.GoogleAdsEnum.MinuteOfHour.ZERO, end_hour: 24, end_minute: datatypes_1.GoogleAdsEnum.MinuteOfHour.ZERO }, option), { day_of_week: el });
            });
        };
        let data = [];
        if (day === datatypes_1.GoogleAdsEnum.DayOfWeek.WEEKDAYS) {
            data = generateDay(weekdays, option);
        }
        else if (day === datatypes_1.GoogleAdsEnum.DayOfWeek.WEEKENDS) {
            data = generateDay(weekends, option);
        }
        else if (day === datatypes_1.GoogleAdsEnum.DayOfWeek.EVERYDAY) {
            data = generateDay(everydays, option);
        }
        else {
            data = generateDay([day], option);
        }
        return data;
    }
    getCreateCampaignData() {
        if (!this.daily_schedules)
            throw Error('daily_schedules not found');
        if (!this.bidding_strategy)
            throw Error('bidding_strategy not found');
        if (!this.campaign_budget)
            throw Error('campaign_budget not found');
        let daily_schedules = optCreate(this.daily_schedules);
        daily_schedules = this.filterDailySchedule(daily_schedules);
        daily_schedules = daily_schedules.map((el) => {
            const data = this.generateSchedule(el.day_of_week, el);
            return data;
        }).flat();
        const languages = optCreate(this.languages);
        let locations = this.locations && this.locations.some(el => el.geo_target_constant.resource_name)
            ? this.locations.map(el => ({ geo_target_constant: el.geo_target_constant.resource_name }))
            : this.locations;
        locations = optCreate(locations);
        const ages = optCreate(this.ages);
        const devices = optCreate(this.devices);
        const campaign_budget = Object.assign(Object.assign({}, this.campaign_budget), { operation: 'create' });
        const bidding_strategy = Object.assign(Object.assign({}, this.bidding_strategy), { auto_optimize_bid: this.bidding_strategy.auto_optimize_bid ? 1 : 0, operation: 'create' });
        const sitelinks = this.site_link && optCreate(this.site_link.sitelinks);
        const site_link = {
            sitelinks,
            operation: 'create'
        };
        const call = Object.assign(Object.assign({}, this.call), { operation: 'create' });
        return {
            name: this.name,
            account_id: this.account_id,
            landing_page_id: this.landing_page_id,
            campaign_type: this.campaign_type,
            start_date: moment(this.start_date).format('YYYY-MM-DD'),
            end_date: this.end_date ? moment(this.end_date).format('YYYY-MM-DD') : undefined,
            daily_schedules,
            languages,
            locations,
            devices,
            bidding_strategy: this.bidding_strategy ? bidding_strategy : undefined,
            campaign_budget: this.campaign_budget ? campaign_budget : undefined,
            site_link: this.site_link ? site_link : undefined,
            call: this.call ? call : undefined,
            operation: 'create'
        };
    }
}
exports.GoogleCampaignClass = GoogleCampaignClass;
