import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, 
  SelectedTeam, 
  WorkSpaceService,
  UserTeam,
  GetTeamMemberListParams,
  TeamPermissionService
} from 'core';
import * as ReactModal from 'react-modal';
import NeedToUpgradeModal from 'pages/menu/modals/NeedToUpgradeModal';
import { Link, useHistory } from 'react-router-dom';
import ListMembers from '../ListMembers';
import { plainToClass } from 'class-transformer';
import { State } from '../service';
import { History } from 'src/route/Route';
import { useRedirectDomain } from 'pages/auth/hooks';
import { withCookies, Cookies } from 'react-cookie';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  cookies: Cookies;
}
ReactModal.setAppElement('#root');

const ManageMembersModal: React.FC<ModalProps> = (props) => {
  const [nameMember, setNameMember] = useState('');
  const mounted = useRef(false);
  const selectedTeam = useSelector((state: State) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const members = useSelector((state: State) => state.team.members);
  const pendingInvites = useSelector((state: State) => state.team.pendingInvites);
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);
  const history = new History();
  const service = new WorkSpaceService();
  const {gotoHome} = useRedirectDomain(props.cookies);
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  
  useEffect(() => {
    mounted.current = true;
    handleGetTeamList();
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if(props.isOpen) handleGetTeamList();
  }, [props.isOpen]);

  const closeModal = () => {
    props.closeModal();
  };

  const handleGetTeamList = () => {
    const params: GetTeamMemberListParams = {
      callBackParams: {
        errorCallback: (err: any)=>{
          console.log(err);
          if(err.code === 414){
            gotoHome({message: 303});
          }
        }
      }
    };
    service.getTeamMemberList(params);
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setNameMember(value);
  };

  const getMemberQuantity = () => {
    return members.length;
  };

  const getMemberByType = (type: string) => {
    const result = members.filter((el: any)=>{
      const roles = el.roles ? el.roles.map((role: any)=>role.code) : []; 
      let id = '';
      ['owner', 'manager', 'member'].every((s: string)=>{const check = roles.includes(s); if(check) id = s; return !check;}); 
      const filterRole = [id];
      return el.id && filterRole.includes(type); 
    });

    return result;
  };

  const filterPending = () => {
    const memberEmail = members.map((el: UserTeam)=>el.email);
    return pendingInvites.filter((el: any)=>el.id && !memberEmail.includes(el.email));
  };

  const addMember = () => {
    if(!teamPermissionService.CanInviteTeamMember) {
      props.closeModal();
      setIsOpenNeedToUpgradeModalState(true);
      return;
    }
    history.push('/create-team/invite');
  };
  const onlyOwner = members.length < 2 && pendingInvites.filter((el: any)=>el.id).length === 0;
  const hasPermission = selectedTeam.checkUserRole(['owner', 'manager']);
  
  const groups = [{
    role: 'owner',
    title: 'Người sở hữu',
    type: 'group-button',
    data: getMemberByType('owner'),
  }, {
    role: 'manager',
    title: 'Quản lý',
    type: 'group-button',
    data: getMemberByType('manager')
  }, {
    role: 'member',
    title: 'Thành viên',
    type: 'group-button',
    data: getMemberByType('member')
  }];

  const pending = [{
    role: 'pending',
    title: 'Đang chờ',
    type: 'single-button',
    data: filterPending()
  }];

  return (
    <>
    <NeedToUpgradeModal 
      teamName={selectedTeam.getTeamName()}
      isOpen={isOpenNeedToUpgradeModalState} 
      closeModal={() => {setIsOpenNeedToUpgradeModalState(false);}} 
    />
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="manage-members-modal">
        <div className="modal__heading">
          <h3 className="modal__heading--title">{getMemberQuantity()} thành viên trong nhóm <span>#{selectedTeam.getTeamName()}</span></h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content" style={{padding: '20px 0'}}>
          {!onlyOwner && hasPermission && <div className="modal__content--row" style={{marginBottom: 15, padding: '0 20px'}}>
            <label className="blue-label clickable" onClick={addMember}>Thêm thành viên</label>
            <input type="text" name="name" className="form-control" 
              placeholder="Tìm kiếm tên/email thành viên" 
              value={nameMember} 
              onChange={handleOnChange}
            />
          </div>}
          {groups.map((el: any)=><ListMembers listtype="withTitle" key={el.role} searchTerm={nameMember} {...el}/>)}
          {pending.map((el: any)=><ListMembers key={el.role} listtype="withTitle" searchTerm={nameMember} {...el}/>)}
          {onlyOwner && <div className="no-other" style={{display: 'flex', alignItems: 'center', margin: '20px 0', flexDirection: 'column', lineHeight: '55px'}}>
            <span>Hãy thêm thành viên để bắt đầu hoạt động nhóm.</span>
            <button className="btn btn-default" onClick={addMember}>Thêm thành viên</button>
          </div>}
        </div>
      </div>
    </ReactModal>
    </>
  );
};

ManageMembersModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      height: '546px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default withCookies(ManageMembersModal);
