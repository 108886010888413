import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { EmailService, InputErrorMsg, ApiErrorMsg } from '../service';
import { AuthService, LoginParameter } from 'core';
import { History } from 'src/route/Route';
import { useKeyboard } from '@react-aria/interactions';

type LoginByEmailProps = ReactCookieProps & {
  redirectPrevious: (res: any) => void;
};

const LoginByEmail: React.FC<LoginByEmailProps> = (props) => {
  const dispatch = useDispatch();
  const apiError: any = useSelector((state: any) => state.auth.error);
  const history = new History();
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e: any) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        handleLogin();
      }
    }
  });

  const [emailState, setEmailState] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [isVisiblePasswordState, setIsVisiblePassword] = useState(false);
  const [isInvalidEmailState, setIsInvalidEmailState] = useState(false);
  const [isInvalidPasswordState, setIsInvalidPasswordState] = useState(false);
  
  const [apiErrorCodeState, setApiErrorCodeState] = useState(0);
  const [shouldShowApiErrorState, setShouldShowApiErrorState] = useState(false);
  
  const authService = new AuthService();
  useEffect(() => {
    if(!apiError) return;
    setShouldShowApiErrorState(true);
    setApiErrorCodeState(apiError.code);
  }, [apiError]);

  const onChangeInputEmail = (e: any) => {
    const { value } = e.target;
    setEmailState(value);
    setShouldShowApiErrorState(false);
    if (value && EmailService.validateEmailFormat(value)) {
      setIsInvalidEmailState(false);
    }
  };

  const onBlurInputEmail = (e: any) => {
    const { value } = e.target;
    if (!value || !EmailService.validateEmailFormat(value)) {
      setIsInvalidEmailState(true);
    }
  };

  const onChangeInputPassword = (e: any) => {
    const { value } = e.target;
    setPasswordState(value);
    setShouldShowApiErrorState(false);
    if (value) {
      setIsInvalidPasswordState(false);
    }
  };

  const handleLogin = () => {
    if (!validToLogin()) return;
    const params: LoginParameter = {
      inputParams: {
        email: emailState.toLowerCase(),
        password: passwordState,
        provider: 'email',
      },
      callBackParams: {
        successCallback: (res: any) => {
          props.redirectPrevious(res.token);
        },
        errorCallback: (res: any) => {
          setShouldShowApiErrorState(true);
          if(res) setApiErrorCodeState(res.code);
        }
      }
    };
    authService.login(params);
  };

  const validToLogin = (): boolean => {
    return (
      !!(emailState && EmailService.validateEmailFormat(emailState)) &&
      !!passwordState
    );
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg) => {
    if (!ifError) return null;
    return (
      <div style={{color: 'red'}}>{errMsg}</div>
    );
  };

  const renderApiError = (shouldShow: boolean, code: number) => {
    if (!shouldShow) return null;
    return (
      <div style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  return (
    <Styles>
      <label>Email <span className="red-label">*</span></label>
      <input
        type="text"
        className="form-control"
        placeholder=""
        onChange={onChangeInputEmail}
        onBlur={onBlurInputEmail}
      />
      {renderInputError(isInvalidEmailState, InputErrorMsg.InvalidEmail)}
      <div className="position-relative">
        <div className="pass-forgot">
          <label>Mật khẩu <span className="red-label">*</span></label>
          <Link to={(globalThis as any).useHistory.url('/forgot-pass')}>Quên mật khẩu</Link>
        </div>
        <input
          {...keyboardProps}
          type={isVisiblePasswordState ? 'text' : 'password' }
          className="form-control"
          placeholder=""
          onChange={onChangeInputPassword}
        />
        {passwordState && <div className="show-password" onClick={() => {setIsVisiblePassword(!isVisiblePasswordState);}}>
          {isVisiblePasswordState ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <g> <path d="M10.003 15.858c5.524 0 9.338-4.463 9.338-5.858 0-1.401-3.821-5.858-9.338-5.858C4.555 4.142.66 8.599.66 10c0 1.395 3.89 5.858 9.344 5.858zm0-1.08c-4.504 0-8.148-3.814-8.148-4.778 0-.813 3.644-4.778 8.148-4.778 4.485 0 8.142 3.965 8.142 4.778 0 .964-3.657 4.778-8.142 4.778zm0-.95c2.126 0 3.835-1.743 3.835-3.828 0-2.14-1.709-3.828-3.835-3.828-2.14 0-3.855 1.688-3.848 3.828.013 2.085 1.709 3.828 3.848 3.828zm0-2.557c-.71 0-1.285-.574-1.285-1.271 0-.704.574-1.271 1.285-1.271.705 0 1.279.567 1.279 1.271 0 .697-.574 1.271-1.279 1.271z" transform="translate(-827 -368) translate(402 113) translate(20 222) translate(405 33)"/> </g> </g> </g> </g> </g> </svg>
          : 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <path d="M16.06 16.079c.219-.226.205-.533 0-.745L4.63 3.91c-.205-.205-.547-.205-.752 0-.198.198-.198.547 0 .745l11.437 11.423c.205.212.533.225.745 0zm-.164-1.873c2.16-1.402 3.445-3.24 3.445-4.047 0-1.401-3.814-5.858-9.338-5.858-1.189 0-2.27.205-3.274.54l.902.895c.752-.226 1.525-.355 2.372-.355 4.498 0 8.142 3.964 8.142 4.778 0 .554-1.196 2.044-3.09 3.206l.841.84zm-5.893 1.811c1.272 0 2.434-.225 3.494-.588l-.896-.895c-.813.253-1.675.403-2.598.403-4.504 0-8.148-3.814-8.148-4.778 0-.479 1.265-2.071 3.281-3.281L4.29 6.03C2.012 7.438.659 9.332.659 10.159c0 1.394 3.89 5.858 9.344 5.858zm3.507-4.361c.212-.458.328-.97.328-1.497 0-2.14-1.709-3.828-3.835-3.828-.54 0-1.045.116-1.503.314l5.01 5.011zm-3.507 2.331c.602 0 1.17-.15 1.675-.396L6.551 8.464c-.253.505-.396 1.08-.396 1.695.007 2.085 1.709 3.828 3.848 3.828z" transform="translate(-1090 -331) translate(555 298) translate(535 33)"/> </g> </g> </g> </g> </svg>
          }
        </div>}
      </div>
      {renderInputError(isInvalidPasswordState, InputErrorMsg.InvalidPassword)}
      {renderApiError(shouldShowApiErrorState, apiErrorCodeState)}
      <button 
        className={validToLogin() ? 'btn-default' : ' ml-0 btn-disabled' } 
        disabled={!validToLogin()}
        onClick={handleLogin}
      >Đăng nhập</button>
      <div className="login-footer">
        Bạn chưa có tài khoản? <Link to={(globalThis as any).useHistory.url('/register')}>Đăng ký ngay</Link>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  h1 {
    font-family: Propins-Bold;
    font-size: 28px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.32px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 46px;
  }
  label span.red-label {
    color: #ff3b30;
  }
  .form-check {
    margin: 20px 0;
  }
  .login-footer {
    margin-top: 30px;
  }
  .show-password {
    cursor: pointer;
    position: absolute;
    right: 15px;
    bottom: 12px;
  }
  .pass-forgot{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a{
      color: #666;
      font-size: 16px;
      margin-top: 20px;
    }
  }
`;

export default withCookies(LoginByEmail);
