"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatChannelData = void 0;
const formatChannelData = (data) => {
    if (!data || !data.campaigns) {
        return Object.assign(Object.assign({}, defaultData), data);
    }
    data.conversion_rate = data.conversion_rate + '%';
    data.bounce_rate = data.bounce_rate + '%';
    data.scroll_rate = data.scroll_rate + '%';
    data.dwelltime_rate = data.dwelltime_rate + 's';
    data.campaigns.unshift({
        name: data.name,
        num_conversion: data.num_conversion,
        num_session: data.num_session,
        num_visitor: data.num_visitor,
        conversion_rate: data.conversion_rate,
        bounce_rate: data.bounce_rate,
        scroll_rate: data.scroll_rate,
        dwelltime_rate: data.dwelltime_rate,
        num_session_by_day: [],
        num_visitor_by_day: [],
        num_conversion_by_day: [],
    });
    return Object.assign(Object.assign({}, defaultData), data);
};
exports.formatChannelData = formatChannelData;
const defaultData = {
    name: '',
    num_session: 0,
    num_session_by_day: [],
    num_visitor: 0,
    num_visitor_by_day: [],
    num_conversion: 0,
    num_conversion_by_day: [],
    conversion_rate: 0,
    bounce_rate: 0,
    scroll_rate: 0,
    dwelltime_rate: 0,
    campaigns: [],
    lpLabel: '',
    projectName: ''
};
