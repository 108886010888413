import * as React from 'react';
import { useState } from 'react';
import 'stylesheets/App.css';
import 'stylesheets/Analytic.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { subDays } from 'date-fns';
import { vi } from 'date-fns/locale';
import { AnalyticService } from 'core';
import { getDateRangeLabel } from 'pages/analytics/helpers/getDateRangeLabel';

type PropsType = {
  startDate: Date;
  endDate: Date;
  openPopup: boolean;
  setOpenPopup: (isOpen: boolean) => void;
  onSelect: (startDate: Date, endDate: Date) => void;
  callbackOpenDatePicker?: () => void;
}

const RangeDatePicker: React.FC<PropsType> = (props) => {
  
  const [dateRange, setDateRange] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: 'selection',
    },
  ]);
  const [selectedRange, setSelectedRange] = useState(
    getDateRangeLabel(props.startDate, props.endDate)
  );

  const handleOnChange = (item: any) => {
    const dateRange = getDateRangeLabel(item.selection.startDate, item.selection.endDate);
    setSelectedRange(dateRange);
    setDateRange([item.selection]);
  };

  const onSelect = () => {
    props.onSelect(dateRange[0].startDate, dateRange[0].endDate);
    props.setOpenPopup(false);
  };

  const handleCancel = () => {
    setDateRange([
      {
        startDate: props.startDate,
        endDate: props.endDate,
        key: 'selection',
      },
    ]);
    props.setOpenPopup(false);
  };
  return (
    <div className="date-range-container w-100">
      <div
        className="fake-select fixed-height align-items-center justify-content-between"
        onClick={() => {
          props.setOpenPopup(!props.openPopup);
          props.callbackOpenDatePicker && props.callbackOpenDatePicker();
        }}
      >
        <div className="date-selected">
          <p>{selectedRange}</p>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M0 0H14V14H0z" opacity=".01" />
            <path
              fill="#8E8E93"
              fillRule="nonzero"
              d="M6.83 10.346c-.209 0-.382-.084-.55-.251L1.736 5.441c-.132-.131-.21-.299-.21-.496 0-.395.311-.712.712-.712.197 0 .37.084.508.215l4.092 4.193 4.08-4.193c.143-.137.322-.215.514-.215.394 0 .705.317.705.712 0 .197-.065.365-.203.496l-4.546 4.654c-.155.167-.34.245-.556.251z"
            />
          </g>
        </svg>
      </div>
      {props.openPopup && (
        <div className="date-picker-wrapper">
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            locale={vi}
            showMonthAndYearPickers={false}
            dateDisplayFormat={'dd/MM/yyyy'}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            direction="horizontal"
            staticRanges={[
              {
                label: 'Hôm nay',
                hasCustomRendering: false,
                range: () => ({
                  startDate: new Date(),
                  endDate: new Date(),
                }),
                isSelected() {
                  return selectedRange === 'Hôm nay';
                },
              },
              {
                label: 'Hôm qua',
                hasCustomRendering: false,
                range: () => ({
                  startDate: subDays(new Date(), 1),
                  endDate: subDays(new Date(), 1),
                }),
                isSelected() {
                  return selectedRange === 'Hôm qua';
                },
              },
              {
                label: '7 ngày gần đây',
                hasCustomRendering: false,
                range: () => ({
                  startDate: subDays(new Date(), 7),
                  endDate: subDays(new Date(), 1),
                }),
                isSelected() {
                  return selectedRange === '7 ngày gần đây';
                },
              },
              {
                label: '30 ngày gần đây',
                hasCustomRendering: false,
                range: () => ({
                  startDate: subDays(new Date(), 29),
                  endDate: new Date(),
                }),
                isSelected() {
                  return selectedRange === '30 ngày gần đây';
                },
              },
            ]}
          />
          <div className="date-picker-button__wrapper">
            <button className="date-picker-btn--cancel" onClick={handleCancel}>Hủy</button>
            <button className="date-picker-btn--apply" onClick={onSelect}>Áp dụng</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeDatePicker;
