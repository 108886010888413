import styled from 'styled-components';
import { IDailySchedule, CampaignDataInfoService, GoogleAdsEnum } from 'core';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { FieldErrors } from 'react-hook-form';
interface PropType{
  onChange: (value: IDailySchedule[]) => void; 
  errors?: FieldErrors;
  value: IDailySchedule[] | undefined;
  // control: Control;
}

const StyleDatePicker = styled(DatePicker)`
  &.error{
    border: 2px solid red !important;
    color: red;
  }
`;

type OnUpdateScheduleItem = (value: string, originalItem: IDailySchedule) => IDailySchedule;

//TODO: Validate for these days have plan duplicate
export const DailySchedulesField: React.FC<PropType> = (props) => {
  const hours = new Array(24).fill(undefined).map((_, id)=>id);
  const { errors, value: daily_schedules, onChange } = props;

  const [dailySchedules, setDailySchedules] = useState<IDailySchedule[]>(daily_schedules ? daily_schedules : []);

  const onAddDayScheduleItem = () => {
    const updatedDailySchedules = [...dailySchedules, CampaignDataInfoService.getDefaultSchedule()].flat();
    setDailySchedules(updatedDailySchedules);
    onChange(updatedDailySchedules);
  };

  const onRemoveDayScheduleItem = (index: number) => {
    const existingItems = dailySchedules.filter((x, i) => i !== index);
    setDailySchedules(existingItems);
    onChange(existingItems);
  };

  const onChangeStartHour = (value: string, index: number) => {
    const updateScheduleItem = (value: string, originalItem: IDailySchedule): IDailySchedule =>  {
      const data = JSON.parse(value);
      const updatedItem = {
        ...originalItem, 
        start_hour: data.hour,
        start_minute: data.minute
      };
      return updatedItem;
    };

    onScheduleItemChanged(value, index, updateScheduleItem);
  };

  const onChangeEndHour = (value: string, index: number) => {
    const updateScheduleItem = (value: string, originalItem: IDailySchedule): IDailySchedule =>  {
      const data = JSON.parse(value);
      const updatedItem = {
        ...originalItem, 
        end_hour: data.hour,
        end_minute: data.minute
      };
      return updatedItem;
    };

    onScheduleItemChanged(value, index, updateScheduleItem);
  };
  
  const onScheduleItemChanged = (value: string, index: number, updateScheduleItem: OnUpdateScheduleItem) => {
    if (index >= dailySchedules.length) {
      return;
    }
    const updatedItem = updateScheduleItem(value, dailySchedules[index]);
    const updatedDailySchedules = [
      ...dailySchedules.slice(0, index),
      updatedItem,
      ...dailySchedules.slice(index + 1)
    ];

    setDailySchedules(updatedDailySchedules);
    onChange(updatedDailySchedules);
  };

  const onChangeDayOfWeek = (value: string, index: number) => {
    const updateScheduleItem = (value: string, originalItem: IDailySchedule): IDailySchedule =>  {
      const dayOfWeekValue = parseInt(value);
      const updatedItem: IDailySchedule = {
        ...originalItem, 
        day_of_week: dayOfWeekValue
      };
      return updatedItem;
    };

    onScheduleItemChanged(value, index, updateScheduleItem);
  };
  
  return (<>
    <div className="col-lg-10">
      <label>Lịch trình quảng cáo</label>
      {dailySchedules?.map((item: IDailySchedule, id: number)=><div key={item.day_of_week + id} className="input-group schedules-inputs align-items-center form-inline justify-content-start">
        <div className="schedules-inputs__wrapper">
          <select className="w-auto" defaultValue={item.day_of_week} onChange={(event: React.ChangeEvent<HTMLSelectElement>)=>{ onChangeDayOfWeek(event.target.value, id); }}>
            <option value={GoogleAdsEnum.DayOfWeek.EVERYDAY}>Tất cả các ngày</option>
            <option value={GoogleAdsEnum.DayOfWeek.WEEKDAYS}>Thứ 2 - Thứ 6</option>
            <option value={GoogleAdsEnum.DayOfWeek.WEEKENDS}>Thứ 7 - Chủ nhật</option>
            <option value={GoogleAdsEnum.DayOfWeek.MONDAY}>Thứ 2</option>
            <option value={GoogleAdsEnum.DayOfWeek.TUESDAY}>Thứ 3</option>
            <option value={GoogleAdsEnum.DayOfWeek.WEDNESDAY}>Thứ 4</option>
            <option value={GoogleAdsEnum.DayOfWeek.THURSDAY}>Thứ 5</option>
            <option value={GoogleAdsEnum.DayOfWeek.FRIDAY}>Thứ 6</option>
            <option value={GoogleAdsEnum.DayOfWeek.SATURDAY}>Thứ 7</option>
            <option value={GoogleAdsEnum.DayOfWeek.SUNDAY}>Chủ nhật</option>
          </select>
          <label className="w-auto">từ</label>
          <select
            className="w-auto" 
            defaultValue={JSON.stringify({hour: item.start_hour, minute: item.start_minute})}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>)=>{onChangeStartHour(event.target.value, id);}}>
            {hours.map((el, id) => <React.Fragment key={'start_hour_minute_' + id}>
              <option key={'start_hour_minute' + id + 1} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.ZERO})}>{id + ':00'}</option>
              <option key={'start_hour_minute' + id + 2} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.FIFTEEN})}>{id + ':15'}</option>
              <option key={'start_hour_minute' + id + 3} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.THIRTY})}>{id + ':30'}</option>
              <option key={'start_hour_minute' + id + 4} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.FORTY_FIVE})}>{id + ':45'}</option>
            </React.Fragment>)}
          </select>
          <label className="w-auto">đến</label>
          <select className="w-auto" defaultValue={JSON.stringify({hour: item.end_hour, minute: item.end_minute})} onChange={(event: React.ChangeEvent<HTMLSelectElement>)=>{onChangeEndHour(event.target.value, id);}}>
            {hours.map((el, id) => <React.Fragment key={'end_hour_minute_' + id}>
              <option key={'end_hour_minute' + id + 1} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.ZERO})}>{id + ':00'}</option>
              <option key={'end_hour_minute' + id + 2} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.FIFTEEN})}>{id + ':15'}</option>
              <option key={'end_hour_minute' + id + 3} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.THIRTY})}>{id + ':30'}</option>
              <option key={'end_hour_minute' + id + 4} value={JSON.stringify({hour: id, minute: GoogleAdsEnum.MinuteOfHour.FORTY_FIVE})}>{id + ':45'}</option>
            </React.Fragment>)}
            <React.Fragment key={'end_hour_minute_' + 24}>
              <option key={'end_hour_minute' + 25} selected={item.end_hour === 24 && item.end_minute === GoogleAdsEnum.MinuteOfHour.ZERO} value={JSON.stringify({hour: 24, minute: GoogleAdsEnum.MinuteOfHour.ZERO})}>{24 + ':00'}</option>
            </React.Fragment>
          </select>
        </div>
          
        {dailySchedules.length > 1 && <a className="delete-link" href="#" onClick={()=>onRemoveDayScheduleItem(id)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2" fillRule="nonzero"> <g> <g> <path d="M670 46c4.924 0 9-4.085 9-9 0-4.924-4.085-9-9.009-9-4.915 0-8.991 4.076-8.991 9 0 4.915 4.085 9 9 9zm3.097-5.153c-.203 0-.38-.088-.52-.22l-2.586-2.586-2.567 2.585c-.142.142-.327.221-.539.221-.397 0-.723-.335-.723-.741 0-.194.08-.37.212-.512l2.585-2.585-2.585-2.585c-.133-.142-.212-.318-.212-.512 0-.406.326-.724.723-.724.212 0 .38.07.512.212l2.594 2.585 2.62-2.594c.15-.15.31-.22.504-.22.397 0 .723.326.723.723 0 .203-.062.362-.212.52l-2.594 2.595 2.586 2.576c.14.15.211.318.211.52 0 .407-.326.742-.732.742z" transform="translate(-1016 -830) translate(355 754) translate(0 48)"/> </g> </g> </g> </g> </svg></a>}
        
      </div>)}
      <div className="link-add-another">
        <div className="cursor-pointer" style={{display: 'inline-block'}} onClick={onAddDayScheduleItem}>Thêm lịch quảng cáo {dailySchedules.length > 0 ? 'khác' : ''}</div>
      </div>
    </div>
  </>);
};