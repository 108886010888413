import * as FileSaver from 'file-saver';
import * as React from 'react';
import * as XLSX from 'xlsx';
interface PropsType {
  data: object;
  fileName: string;
}
// export const ExportCSV = ({csvData, fileName}) => {
export const ExportData: React.FC<PropsType> = ({
  data,
  fileName,
}: PropsType) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (data: object, fileName: string) => {
    const wb = XLSX.utils.book_new();
    const projectNames: Array<string> = []; // this array for check duplicate name if any
    let i = 0;
    for (const key in data) {
      const { name, customers, maxWidths } = data[key];
      let sheetName = name;
      if (projectNames.indexOf(name.toLowerCase()) > -1) {
        const limitNumber = 31 - 2 - i.toString().length; //31: limit of sheetName length, 2: 2 characters ( & ), i: i = index of loop
        if (name.length > limitNumber) {
          sheetName = name.substr(0, 28);
        }
        sheetName = `${sheetName}(${i})`; // if duplicate name, new name will be name(number of key)
      }
      projectNames.push(name.toLowerCase());
      const ws = XLSX.utils.json_to_sheet(customers);
      const COLUMN_MARGIN_RIGHT = 5;
      const wscols = [
        { wch: maxWidths.created_at + COLUMN_MARGIN_RIGHT },
        { wch: maxWidths.full_name + COLUMN_MARGIN_RIGHT },
        { wch: maxWidths.phone_no + COLUMN_MARGIN_RIGHT },
        { wch: maxWidths.email + COLUMN_MARGIN_RIGHT },
        { wch: maxWidths.budget + COLUMN_MARGIN_RIGHT },
        { wch: maxWidths.preference + COLUMN_MARGIN_RIGHT },
        { wch: maxWidths.ad_source + COLUMN_MARGIN_RIGHT },
      ];
      if (maxWidths.lp_name) {
        wscols.unshift({ wch: maxWidths.lp_name + COLUMN_MARGIN_RIGHT });
      }
      wscols.unshift({ wch: maxWidths.stt + COLUMN_MARGIN_RIGHT });
      ws['!cols'] = wscols;
      if (sheetName.length > 31) {
        sheetName = sheetName.substr(0, 31);
      }
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      i++;
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data2 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data2, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-green"
      onClick={(e) => exportToExcel(data, fileName)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#FFF"> <g> <g> <g> <g> <path d="M0 0H20V20H0z" opacity=".01" transform="translate(-1143 -29) translate(256 20) translate(875) translate(12 8) translate(0 1)"/> <g> <path d="M3.418 0h4.499v3.878c0 1.114.116 1.519.333 1.926.218.409.538.728.946.945.407.219.812.334 1.925.334H15v9.499c0 1.188-.124 1.619-.356 2.054-.233.434-.574.775-1.008 1.008-.435.232-.866.356-2.054.356H3.418c-1.188 0-1.619-.124-2.054-.356C.93 19.413.59 19.07.356 18.636.125 18.2 0 17.77 0 16.582V3.418C0 2.23.124 1.8.356 1.364.588.93.93.59 1.364.356 1.798.123 2.23 0 3.418 0zm7.636 14.167c.134 0 .242.025.331.073.09.048.16.118.208.208.048.09.074.197.074.331v.025c0 .134-.026.242-.074.331-.048.09-.118.16-.208.208-.09.048-.197.074-.331.074H3.946c-.134 0-.242-.026-.331-.074-.09-.048-.16-.118-.208-.208-.048-.09-.074-.197-.074-.331v-.025c0-.134.026-.241.074-.331.048-.09.118-.16.208-.208.09-.048.197-.073.331-.073h7.108zm0-2.5c.134 0 .242.025.331.073.09.048.16.118.208.208.048.09.074.197.074.331v.025c0 .134-.026.242-.074.331-.048.09-.118.16-.208.208-.09.048-.197.074-.331.074H3.946c-.134 0-.242-.026-.331-.074-.09-.048-.16-.118-.208-.208-.048-.09-.074-.197-.074-.331v-.025c0-.134.026-.241.074-.331.048-.09.118-.16.208-.208.09-.048.197-.073.331-.073h7.108zM9.167 0L15 5.833h-3.91c-.67 0-.912-.07-1.156-.2-.241-.128-.439-.325-.566-.566-.132-.246-.201-.489-.201-1.157V0z" transform="translate(-1143 -29) translate(256 20) translate(875) translate(12 8) translate(0 1) translate(2.5)"/> </g> </g> </g> </g> </g> </g> </g> </svg>
      Xuất dữ liệu
    </button>
  );
};
