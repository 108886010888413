import ConfirmButton from 'components/buttons/ConfirmButton';
import React, { useState , Fragment, useEffect } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ManageMembersModal from 'pages/team/modals/ManageMembersModal';
import TeamInformationModal from 'pages/team/modals/TeamInformationModal';
import InviteMembersModal from 'pages/team/modals/InviteMembersModal';
import SentInviteMembersModal from 'pages/team/modals/SentInviteMembersModal';
import NeedToUpgradeModal from './modals/NeedToUpgradeModal';
import { toast } from 'react-toastify';
import { WorkSpaceService, TeamPermissionService, LeaveTeamParams, GoToTeamParameter, SelectedTeam, Team, Role, RoleCode } from 'core';
import { plainToClass } from 'class-transformer';
import { RoutePath } from 'src/route/Route';
import { History } from 'src/route/Route';
import { useRedirectDomain } from 'pages/auth/hooks';

type PropsType = {
  cookies: Cookies;
};

type LocationState = {
  shouldShowManageMember?: boolean;
} | undefined;

const GroupPanel: React.FC<PropsType> = (props) => {
  const history = new History();
  const route = new RoutePath();
  const location = useLocation<LocationState>();
  const shouldShowManageMember = location && location.state && (location.state.shouldShowManageMember === true) || false;
  const { redirect, gotoTeam } = useRedirectDomain(props.cookies);
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const selectedTeam = useSelector((state) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const teams: Team[] = useSelector((state) => state.team.teams);
  const userToken: string = useSelector((state: any) => state.auth.userToken);
  const members = useSelector((state) => state.team.members);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const hasPermissionInvite = teamPermissionService.CanInviteTeamMember;
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);
  const [isOpenManageMemberModalState, setIsOpenManageMemberModalState] = useState(shouldShowManageMember);
  const [isOpenInviteMemberModalState, setIsOpenInviteMemberModalState] = useState(false);
  const [isOpenTeamInformationModalState, setIsOpenTeamInformationModalState] = useState(false);
  const [isOpenSentInviteMembersModalState, setIsOpenSentInviteMembersModalState] = useState(false);
  const [isListTeamsOpen, setIsListTeamsOpen] = useState(false);
  const [inviteEmailsState, setInviteEmailsState] = useState(new Array<string>());

  const isHomeWorkspace = useSelector((state) => state.team.config.isHomeWorkspace);
  const workspaceSrv = new WorkSpaceService();
  const selectedTeamId = selectedTeam && selectedTeam.getTeamId();

  useEffect(() => {
    history.replace(location.pathname, null);
  }, []);

  const openSentInviteMembersModal = (emails: string[]) => {
    setInviteEmailsState(emails);
    setIsOpenSentInviteMembersModalState(true);
  };
  
  const openManageMembersModal = () => {
    setIsOpenManageMemberModalState(true);
  };

  const openInviteMembersModal = () => {
    if (!hasPermissionInvite) {
      setIsOpenNeedToUpgradeModalState(true);
      return;
    }
    setIsOpenInviteMemberModalState(true);
  };

  const openTeamInformationModal = () => {
    setIsOpenTeamInformationModalState(true);
  };

  const handleLeftTeam = () => {
    if (!selectedTeam || !selectedTeam.getTeamId()) {
      return;
    }
    const params: LeaveTeamParams = {
      inputParams: {},
      callBackParams: {
        successCallback: (response: any) => {
          if(response['success']){
            toast.success('Bạn đã rời khỏi nhóm.');
          }
        },
        errorCallback: (err: any) => {
          console.log(err, 'err');
        }
      }
    };
    workspaceSrv.leaveTeam(params);
  };

  const onShowListTeams = () => {
    setIsListTeamsOpen(true);
  };

  const onSelectTeam = (team: any) => {
    const params: GoToTeamParameter = {
      inputParams: {
        team_id: team.id,
      },
      callBackParams: {
        successCallback: (res: any) => {
          gotoTeam(res.selectedTeam.token, res.selectedTeam, {});
        },
        errorCallback: (res: any) => {
          console.log('onSelectTeamErr', res);
        }
      }
    };

    workspaceSrv.gotoTeam(params);
  };

  const showMember = () => {
    const roles = selectedTeam.getUserRoles();
    const rolesWithInput = selectedTeam.checkUserRole(['owner', 'manager']);
    const isOwner = typeof roles !== 'boolean' && roles.map((el: any) => el.code).includes('owner');
    const isManager = typeof roles !== 'boolean' && roles.map((el: any) => el.code).includes('manager');
    // return selectedTeam && selectedTeam.getTeamId() && (isOwner || isManager);
    return selectedTeam && selectedTeam.getTeamId() && rolesWithInput;
  };

  const showTeamInformation = () => {
    return selectedTeam && selectedTeam.getTeamId();
  };

  const showSetupTeam = () => {
    // const showOnlyFor: RoleCode[] = [RoleCode[RoleCode.owner], RoleCode[RoleCode.manager]];
    return selectedTeam && selectedTeam.getTeamId() && selectedTeam.checkUserRole(['owner', 'manager']);
  };

  const showLeaveTeam = () => {
    return false;
    // return selectedTeam && selectedTeam.getTeamId() && !selectedTeam.checkUserRole(['owner']);
  };

  const showCreateTeam = () => {
    return isHomeWorkspace;
  };

  const teamName = selectedTeam.getTeamName();
  
  const domainLink = () => {
    const domain = selectedTeam.getTeamDomain();
    return domain ? <div className="group-popup__domain">{domain}</div> : null;
  };

  const renderListTeams = () => {
    const teamList = !teams ? [] : teams.length > 0 && selectedTeamId ?  teams.filter((el: Team)=>el.id != selectedTeamId) : teams;
    return (
      <div className="group-popup__teams">
        {teams && teams.length > 0 && teamList.map((team: Team) => ( 
          <div className="popup__teams--item" key={team.id} onClick={() => onSelectTeam(team)}>
            {team.name}
          </div>
        ))}
        <div className="popup__teams--item hidden" key={'Team_Home'} onClick={() => onSelectTeam({id: null, name: 'Home'})}>Home</div>
      </div>
    );
  };

  return (
    <Styles>
      <InviteMembersModal 
        isOpen={isOpenInviteMemberModalState} 
        openSentInviteMembersModal={openSentInviteMembersModal} 
        closeModal={() => {setIsOpenInviteMemberModalState(false);}} 
      />
      <SentInviteMembersModal 
        isOpen={isOpenSentInviteMembersModalState} 
        goBack={() => {setIsOpenSentInviteMembersModalState(false);openInviteMembersModal();}} 
        closeModal={() => {setIsOpenSentInviteMembersModalState(false);}}
        inviteEmails={inviteEmailsState}
        teamName={selectedTeam.getTeamName()}
      />
      <TeamInformationModal 
        isOpen={isOpenTeamInformationModalState} 
        closeModal={() => {setIsOpenTeamInformationModalState(false);}} 
      />
      <ManageMembersModal 
        isOpen={isOpenManageMemberModalState} 
        closeModal={() => {setIsOpenManageMemberModalState(false);}} 
      />
      <NeedToUpgradeModal 
        teamName={selectedTeam.getTeamName()}
        isOpen={isOpenNeedToUpgradeModalState} 
        closeModal={() => {setIsOpenNeedToUpgradeModalState(false);}} 
      />
      <div className="group-detail-panel">
        {isPopupOpen && (
          <Fragment>
          <div className="group-popup">
            {showTeamInformation() && <div className="group-popup__information">
              <div className="group-popup__name">{selectedTeam.getTeamName()}</div>
              {domainLink()}
              {selectedTeam.checkUserRole(['owner']) &&
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M8 1.69c.178 0 .355.032.527.09.736.253 3.11 1.116 3.853 1.421.533.222.812.432.812 1.086v4.786c0 2.279-1.161 3.224-4.773 5.116-.159.082-.317.12-.419.12-.102 0-.254-.031-.419-.12-3.548-2-4.773-2.837-4.773-5.116V4.287c0-.654.285-.87.812-1.086.325-.13.962-.373 1.638-.624l.583-.216c.676-.248 1.314-.476 1.638-.582.166-.05.343-.089.521-.089zm0 2.014L6.915 5.902l-2.425.352 1.755 1.71-.414 2.417L8 9.24l2.17 1.14-.415-2.415 1.755-1.71-2.425-.353L8 3.704z" transform="translate(-284 -93) translate(274 36) translate(10 56) translate(0 1)"/> </g> </g> </g> </g> </g> </svg>
                Chủ sở hữu
              </div>}
              {selectedTeam.checkUserRole(['manager']) &&
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M8 14.31c.102 0 .26-.039.419-.121 3.612-1.892 4.773-2.837 4.773-5.116V4.287c0-.654-.279-.864-.812-1.086-.743-.305-3.117-1.168-3.853-1.422-.172-.057-.35-.089-.527-.089-.178 0-.355.039-.52.09-.325.105-.963.333-1.639.581l-.583.216c-.676.251-1.313.494-1.638.624-.527.216-.812.432-.812 1.086v4.786c0 2.279 1.225 3.116 4.773 5.116.165.089.317.12.419.12zm-.682-3.395c-.189 0-.331-.07-.46-.225L5.142 8.584C5.04 8.464 5 8.352 5 8.232c0-.275.202-.474.484-.474.166 0 .285.063.395.199l1.426 1.798 2.793-4.418c.116-.186.235-.252.424-.252.279 0 .478.195.478.467 0 .103-.033.213-.11.332l-3.104 4.79c-.11.162-.265.241-.468.241z" transform="translate(-284 -114) translate(274 36) translate(10 77) translate(0 1)"/> </g> </g> </g> </g> </g> </svg>
                Quản lý
              </div>}
            </div>}
            {showMember() && <div className="group-popup__management--link border-top" onClick={openManageMembersModal}>Quản lý thành viên {members && <span>{members.length}</span>}</div>}
            {showMember() && <div className="group-popup__management--link border-bottom" onClick={openInviteMembersModal}>Thêm thành viên
            {!hasPermissionInvite && <svg style={{marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <defs>
                    <linearGradient id="c2chuk3sna" x1="100%" x2="0%" y1="50%" y2="50%">
                        <stop offset="0%" stopColor="#F450A5"/>
                        <stop offset="100%" stopColor="#E47B49"/>
                    </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g fill="url(#c2chuk3sna)" transform="translate(-182 -242)">
                        <g>
                            <g>
                                <path d="M11.875 9.57l.74-3.016.046.002c.243 0 .466-.06.67-.18.203-.12.365-.28.487-.481.121-.202.182-.426.182-.673 0-.243-.06-.465-.18-.666-.12-.202-.28-.362-.484-.482-.203-.12-.428-.18-.675-.18-.243 0-.464.06-.664.18s-.359.281-.478.484c-.12.204-.18.425-.18.664 0 .198.038.38.115.55l.02.04-1.582 1.142c-.156.11-.283.092-.382-.057L7.648 4.12l.022-.01c.2-.12.361-.28.481-.482.12-.202.18-.426.18-.673 0-.239-.06-.46-.18-.663-.12-.204-.28-.365-.481-.485-.202-.12-.424-.18-.667-.18-.247 0-.471.06-.673.18-.2.12-.361.28-.481.482-.12.201-.18.423-.18.666 0 .247.061.471.183.673.121.201.283.362.484.481l.018.01-1.864 2.79c-.046.065-.096.104-.151.118-.055.013-.126-.01-.214-.072L2.531 5.801l.015-.03c.077-.168.115-.351.115-.549 0-.24-.06-.46-.18-.664-.12-.203-.28-.364-.481-.484-.201-.12-.424-.18-.667-.18-.247 0-.47.06-.672.18-.201.12-.362.28-.482.482-.12.2-.179.423-.179.666 0 .247.06.471.182.673.122.2.282.361.482.481s.422.18.67.18l.057-.003.746 3.017h9.738zm-1.96 2.803c.452 0 .803-.11 1.054-.33.25-.22.429-.547.535-.98l.171-.684H2.33l.166.684c.106.433.285.76.535.98.251.22.604.33 1.06.33h5.824z" transform="translate(15 239) translate(167 3)"/>
                            </g>
                        </g>
                    </g>
                </g>
              </svg>}
            </div>}
            {showSetupTeam() && <div className="group-popup__management--link" onClick={openTeamInformationModal}>Thiết lập nhóm</div>}
            {showCreateTeam() && <Link className="group-popup__management--link" to={history.getPath('/create-team')}>Tạo nhóm mới</Link>}
            <div 
              className="group-popup__management--link hoverable"
              onClick={onShowListTeams}
            >
              Chuyển nhóm
              {renderListTeams()}
            </div>
            {showLeaveTeam() && <ConfirmButton
              customButtonStyle={'button-logout group-popup__management--link gray-color'}
              onClick={handleLeftTeam}
              comfirmMessage={
                <div>
                  <h3 className="text-center">Thông báo</h3>
                  <p className="pt-4 pb-3">Bạn có chắc chắn muốn rời nhóm?</p>
                </div>
              }
            >
              Rời nhóm
            </ConfirmButton>}
          </div>
          <div className="popup-bg" onClick={() => {
            setIsPopupOpen((prevState) => !prevState);
          }}></div>
        </Fragment>
        )}
        <button
          className="group-menu"
          onClick={() => {
            setIsPopupOpen((prevState) => !prevState);
            setIsListTeamsOpen(false);
          }}
        >
          <div className="group-panel__label">TEAM</div>
          <div className="group-panel-info">
            <div className="group-panel-name">
              <span style={{ marginRight: '10px' }}>{teamName ? teamName : 'Home'}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <g fill="#FFF" fillRule="evenodd">
                  <path d="M0 0H14V14H0z" opacity=".01" />
                  <path d="M6.83 10.346c-.209 0-.382-.084-.55-.251L1.736 5.441c-.132-.131-.21-.299-.21-.496 0-.395.311-.712.712-.712.197 0 .37.084.508.215l4.092 4.193 4.08-4.193c.143-.137.322-.215.514-.215.394 0 .705.317.705.712 0 .197-.065.365-.203.496l-4.546 4.654c-.155.167-.34.245-.556.251z" />
                </g>
              </svg>
            </div>
          </div>
        </button>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .group-panel {
    position: relative;
    padding: 30px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
  .account-popup::before {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 26px;
    background-repeat: no-repeat;
    left: 12px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='9' viewBox='0 0 26 9'%3E%3Cpath fill='%23FFF' fillOpacity='.8' fillRule='evenodd' d='M11.417 7.833C7.385 2.61 3.579 0 0 0h26c-3.58 0-7.385 2.61-11.417 7.833-.675.874-1.931 1.035-2.805.36-.135-.104-.257-.225-.361-.36z'/%3E%3C/svg%3E%0A");
  }
  .group-popup {
    position: absolute;
    min-width: 200px;
    max-width: 300px;
    top: 100%;
    left: 358px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
    z-index: 999;
  }
  .popup-bg{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
  .account-popup svg {
    margin-right: 5px;
  }
  .account-info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 15px;
  }
  .group-panel-name, .group-panel__create-team a {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.32px;
    color: #ffffff;
    text-align: left;
  }
  .group-panel__label {
    padding: 2px 8px;
    font-family: Propins-Bold;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #ffffff;
    border-radius: 4px;
    background-color: #4c7cf3;
    margin-right: 8px;
  }
  .group-menu {
    margin-left: 144px;
    display: flex;
    padding: 0;
    background-color: transparent;
    border: none;
    align-items: center;
  }
  .group-menu:focus {
    outline: none;
  }
  .group-panel-info {
    display: flex;
    align-items: center;
  }
  .group-popup__name {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
  }
  .group-popup__information {
    padding: 10px;
    font-family: Propins-Regular;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: -0.26px;
    color: #666666;
  }
  .group-popup__management--link.border-top {
    border-top: solid 1px rgba(216, 216, 216, 0.5);
  }
  .group-popup__management--link.border-bottom {
    border-bottom: solid 1px rgba(216, 216, 216, 0.5);
  }
  .group-popup__management--link span {
    margin-left: 4px;
    padding: 0 8px;
    border-radius: 8px;
    background-color: #d8d8d8;
    color: #000000;
  }
  .group-popup__management--link {
    position: relative;
    text-align: left;
    cursor: pointer;
    padding: 5px 10px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000;
    text-decoration: none;
  }
  .group-popup__management--link.gray-color {
    border-top: solid 1px rgba(216, 216, 216, 0.5);
    color: #666666;
  }
  .group-popup__management--link:last-child:hover {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .group-popup__management--link:first-child:hover {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .group-popup__management--link:hover {
    background-color: #d8d8d8;
  }
  .group-popup__management--link.hoverable:hover .group-popup__teams {
    display: block;
    top: 0;
  }
  .group-popup__teams {
    display: none;
    width: 300px;
    max-height: 400px;
    overflow: auto;
    position: absolute;
    left: 100%;
    top: 20%;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.2);
    background-color: rgba(255,255,255,1);
    z-index: 1000;
    border-radius: 6px;
  }
  .popup__teams--item {
    padding: 10px;
    border-bottom: solid 1px rgba(216, 216, 216, 0.5);
  }
  .popup__teams--item:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .popup__teams--item:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .popup__teams--item:hover {
    background-color: #d8d8d8;
    cursor: pointer;
  }
  @media (max-width: 1300px) {
    .group-menu {
      margin-left: 80px;
    }
    .group-popup {
      left: 308px;
    }
    .account-panel {
      padding: 10px;
    }
  }
`;

const GroupPanelWithCookies = withCookies(GroupPanel);

export default GroupPanelWithCookies;
