"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
const types_1 = require("../types");
const ApiAction_1 = require("../../actions/ApiAction");
const team_1 = require("../../team");
class AuthService {
    constructor() {
        const teamService = new team_1.WorkSpaceService();
        this.teamService = teamService;
    }
    login(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.LoginAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    verifyEmail(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.VerifyEmailAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    refresh(params, getTeamParams) {
        const apiAction = new ApiAction_1.ApiAction(types_1.RefreshAction.type, ApiAction_1.FuncType.Async, params);
        Promise.resolve(apiAction.triggerAction()).then((res) => {
            apiAction.success(params, res);
            const hostname = params.inputParams.hostname;
            this.teamService.getTeamList();
            if (hostname !== process.env.REACT_APP_DOMAIN) {
                const getTeamMemberParams = {
                    inputParams: {},
                    callBackParams: {
                        successCallback: (res) => { },
                        errorCallback: (err) => { }
                    }
                };
                this.teamService.getTeamFlow(getTeamParams, getTeamMemberParams);
            }
            else {
                const params = {
                    inputParams: {
                        team_id: null
                    },
                    callBackParams: {
                        successCallback: (res) => { },
                        errorCallback: (err) => { }
                    }
                };
                this.teamService.gotoTeam(params);
            }
        }).catch((err) => {
            console.log(err);
            apiAction.error(params, err);
        });
    }
}
exports.AuthService = AuthService;
