"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCustomer = exports.getCustomers = void 0;
const types_1 = require("../types");
const getCustomers = () => ({
    type: types_1.GET_CUSTOMER
});
exports.getCustomers = getCustomers;
const updateCustomer = (customer) => ({
    type: types_1.UPDATE_CUSTOMER,
    customer
});
exports.updateCustomer = updateCustomer;
