"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLPWithGoogleAdsCampaign = exports.getDefaultKeywords = exports.getDefaultComponents = exports.getDefaultExtension = void 0;
const effects_1 = require("redux-saga/effects");
const adapters_1 = require("../../adapters");
const types_1 = require("../types");
const Keyword_1 = require("../../entities/GoogleAds/Keyword");
function* getDefaultExtension(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/google_ads/default_extensions`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetDefaultExtension.success,
            payload: response.data.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetDefaultExtension.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getDefaultExtension = getDefaultExtension;
function* getDefaultComponents(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/google_ads/component_list`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetDefaultComponents.success,
            payload: response.data.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetDefaultComponents.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getDefaultComponents = getDefaultComponents;
function* getDefaultKeywords(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/google_ads/default_keywords`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const keywords = response.data.data;
        const keywordData = new Keyword_1.KeywordData();
        keywordData.fromKeywordSuggest(keywords);
        yield effects_1.put({
            type: types_1.GetListKeywordSuggestion.success,
            payload: keywordData,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetListKeywordSuggestion.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getDefaultKeywords = getDefaultKeywords;
function* getLPWithGoogleAdsCampaign(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/google_ads/published_landing_pages`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const landingpages = response.data.data;
        yield effects_1.put({
            type: types_1.GetLPWithGoogleAdsCampaign.success,
            payload: landingpages,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetLPWithGoogleAdsCampaign.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getLPWithGoogleAdsCampaign = getLPWithGoogleAdsCampaign;
