"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseDataInfoService = void 0;
const ads_campaign_1 = require("../../ads-campaign");
class BaseDataInfoService {
    constructor() {
        this.setArrayOperation = (oldArrParam, newArrParam, notAllowUpdate = false) => {
            const checkAndSetOperation = (newData, oldArr) => {
                const oldData = oldArr.find(x => x && x.resource_name === newData.resource_name);
                if (!oldData) {
                    return newData;
                }
                newData.operation = newData.isEqual(oldData) ? undefined : ads_campaign_1.OperationTypes.UPDATE;
            };
            const oldArr = oldArrParam ? oldArrParam : [];
            const newArr = newArrParam ? newArrParam : [];
            const createdResources = newArr.filter(x => { return x.resource_name; });
            const notCreatedResources = newArr.filter(x => { return !x.resource_name; });
            const deletedResources = oldArr.filter(x => {
                return (x === null || x === void 0 ? void 0 : x.resource_name) && !newArr.find(y => (y === null || y === void 0 ? void 0 : y.resource_name) == (x === null || x === void 0 ? void 0 : x.resource_name));
            });
            deletedResources.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
            createdResources.forEach(x => checkAndSetOperation(x, oldArr));
            const noChangeResources = createdResources.filter(x => { return !x.operation; });
            const needUpdateResources = createdResources.filter(x => { return x.operation === ads_campaign_1.OperationTypes.UPDATE; });
            notCreatedResources.forEach(x => x.operation = ads_campaign_1.OperationTypes.CREATE);
            let update2CreateResources = [];
            if (notAllowUpdate) {
                // 1. Each UPDATE resource --> will have a CREATE resource
                update2CreateResources = needUpdateResources.map((x) => {
                    const cloneResource = x.cloneResource();
                    cloneResource.operation = ads_campaign_1.OperationTypes.CREATE;
                    return cloneResource;
                });
                // 2. Each UPDATE resource --> become a REMOVE resource
                needUpdateResources.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
            }
            return [...noChangeResources, ...needUpdateResources, ...notCreatedResources, ...update2CreateResources, ...deletedResources];
        };
    }
}
exports.BaseDataInfoService = BaseDataInfoService;
