import styled from 'styled-components';

export const SettingStyle = styled.div`
  background-color: #f5f4f9;
  strong {
    font-family: Propins-Medium;
    color: #000000;
  }
  label {
    font-family: Propins-Medium;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.36px;
    color: #000000;
    margin: 0;
    margin-bottom: 10px;
  }
  .input-group {
    margin-bottom: 20px;
  }
  .warning-text {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 1px;
    background-color: rgba(216, 216, 216, 0.5);
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #666666;
    display: flex;
    align-items: center;
  }
  .warning-text svg {
    margin-right: 8px;
  }
  .box-shadow {
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    &.active {
      border: solid 2px #4c7cf3;
    }
    img {
      margin: 25px auto;
    }
  }
  .connect-account h2 {
    font-family: Propins-Medium;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: #000000;
  }
  .connect-account p {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #666666;
  }
  .connect-box {
    display: flex;
    padding: 15px;
    width: 500px;
    margin: 0 auto;
    border-radius: 6px;
    border: solid 1px #4c7cf3;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
    margin-bottom: 15px;
  }
  .connect-box svg {
    margin-right: 8px;
  }
  .connect-box p {
    margin-bottom: 0;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    color: #666666;
  }
  .icon-anchor {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    background-color: #4c7cf3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-wrapper {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(216, 216, 216, 0.8);
  }
  .icon-wrapper.blur, 
  .box-title.blur {
    opacity: 0.15;
  }
  .box-title {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #000000;
  }
  .box-description {
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.26px;
    text-align: center;
    color: #666666;
    margin: 0 30px 30px;
  }
  .input-buttons {
    padding-top: 20px;
  }
  .closeButton {
    cusor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 10px;
    left: 30px;
  }
`;

export const LandingPageDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 20px;
`;