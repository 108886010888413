import * as React from 'react';
import { Link } from 'react-router-dom';
import { Project } from 'core';

interface SystemProjectResultProps {
  projects: Array<Project>;
}

const SystemProjectResult = (props: SystemProjectResultProps) => {
  const { projects } = props;
  if (!projects.length) return null;
  return (
    <div className="list-projects">
      <h4>Dự án có sẵn</h4>
      <div className="row">
        {projects.map(({ id, name, thumbnail }: Project, index: number) => (
          <Link to={(globalThis as any).useHistory.generateComponent('CloneProject', {id: id})} className="col-lg-3" key={index}>
            {!thumbnail && <img className="img-fluid" src="https://via.placeholder.com/361x226" alt="placeholder" />}
            {thumbnail && (
              <img
                className="img-fluid"
                src={`${process.env.REACT_APP_CDN_URL}/361x226/${thumbnail}`}
                alt="thumbnail"
              />
            )}
            <div className="project-title">{name} </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
export default SystemProjectResult;
