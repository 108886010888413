"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adsSagas = exports.getLandingPageAdSaga = exports.getLandingPageDataSaga = exports.getUserPublishedLandingPagesSaga = void 0;
const class_transformer_1 = require("class-transformer");
const effects_1 = require("redux-saga/effects");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
function* getUserPublishedLandingPagesSaga(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/user_published_landing_pages`,
            authenticated: true
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        yield effects_1.put({ type: types_1.GET_USER_PUBLISHED_LANDING_PAGES_SUCCESS, data: responseData.data });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.GET_USER_PUBLISHED_LANDING_PAGES_ERROR, err });
    }
}
exports.getUserPublishedLandingPagesSaga = getUserPublishedLandingPagesSaga;
function* getLandingPageDataSaga(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/extract_landing_page_data`,
            authenticated: true,
            params: action.params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        const lpData = class_transformer_1.plainToClass(types_1.ExtractLandingPageData, responseData.data);
        yield effects_1.put({
            type: types_1.GET_LANDING_PAGE_DATA_SUCCESS,
            payload: lpData,
            meta: action.meta,
        });
    }
    catch (error) {
    }
}
exports.getLandingPageDataSaga = getLandingPageDataSaga;
function* getLandingPageAdSaga(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/landing_page_ad`,
            authenticated: true,
            params: {
                landing_page_id: action.lpId,
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        yield effects_1.put({
            type: types_1.GET_LANDING_PAGE_AD_SUCCESS,
            payload: responseData,
            meta: action.meta,
        });
    }
    catch (error) {
    }
}
exports.getLandingPageAdSaga = getLandingPageAdSaga;
exports.adsSagas = [
    effects_1.takeLatest(types_1.GET_USER_PUBLISHED_LANDING_PAGES, getUserPublishedLandingPagesSaga),
    effects_1.takeLatest(types_1.GET_LANDING_PAGE_DATA, getLandingPageDataSaga),
    effects_1.takeLatest(types_1.GET_LANDING_PAGE_AD, getLandingPageAdSaga),
];
