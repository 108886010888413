import * as React from 'react';
import { LandingPageVersionStatus, LandingPage, LandingPageVersion } from 'core';
import { TableActions } from '../index';
import { CustomRowProps } from '../index.d';
import Version from './Version';

type VersionsProps = {
  row: CustomRowProps;
};

export default function Versions(props: VersionsProps): React.ReactElement {
  const { row } = props;
  const original = row.original as LandingPage;
  const versions = original.versions as LandingPageVersion[];
  const getActions = (verion: LandingPageVersion, index: number): Array<TableActions> => {
    return verion.status === LandingPageVersionStatus.published || index === 0
      ? [TableActions.Edit, TableActions.View]
      : [TableActions.Restore, TableActions.View];
  };

  return (
    <React.Fragment key={row.id}>
      {versions.map((v, index) => {
        return <Version key={v.id} version={v} builder_version={v.builder_version} landingPageId={original.id} actions={getActions(v, index)} />;
      })}
    </React.Fragment>
  );
}
