"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportService = void 0;
const actions_1 = require("../../actions");
const Report_1 = require("../types/Report");
class ReportService {
    report(params) {
        const apiAction = new actions_1.ApiAction(Report_1.GoogleAdsReportTypes.type, actions_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
}
exports.ReportService = ReportService;
