import React from 'react';

export const TrashIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H18V18H0z" opacity=".01" transform="translate(-155.000000, -2025.000000) translate(155.000000, 1972.000000) translate(0.000000, 53.000000)" /> <g fill="#FF3B30"> <path d="M5.25 0h4.5l.499.998c.153.307.468.502.813.502H3.939c.344 0 .66-.195.813-.502L5.25 0zM1.294 1.5h12.412c.201 0 .363.038.497.11.133.07.241.18.312.312.072.135.11.296.11.497v.037c0 .201-.038.362-.11.497-.07.133-.18.241-.312.312-.134.072-.296.11-.497.11H1.294c-.201 0-.363-.038-.497-.11-.133-.07-.241-.18-.312-.312-.077-.154-.115-.325-.11-.497V2.42c0-.201.038-.362.11-.497.07-.133.18-.241.312-.312.134-.073.296-.11.497-.11zm.956 3h10.5l-1.157 10.711c-.108 1-.241 1.352-.463 1.705-.222.353-.524.623-.899.806-.374.183-.74.278-1.744.278H6.513c-1.005 0-1.37-.095-1.744-.277-.37-.179-.682-.458-.899-.807-.222-.352-.355-.706-.463-1.705L2.25 4.5z" transform="translate(-155.000000, -2025.000000) translate(155.000000, 1972.000000) translate(0.000000, 53.000000) translate(1.500000, 0.000000)" /> </g> </g> </g> </g> </g> </svg>;
};

export const CollapseError = () => {
    return <svg style={{ width: 15, height: 15, marginBottom: 20, marginRight: 10 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h24v24H0z" opacity=".01" /> <path fill="#ff3b30" d="M12.447 1.342a1 1 0 0 1 .447.447l10.382 20.764A1 1 0 0 1 22.382 24H1.618a1 1 0 0 1-.894-1.447L11.106 1.789a1 1 0 0 1 1.341-.447zM12 18a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-8.571c-.71 0-1.286.575-1.286 1.285v5.143a1.286 1.286 0 0 0 2.572 0v-5.143c0-.71-.576-1.285-1.286-1.285z" /> </g> </svg>;
};

export const ExpandCollapseIcon: React.FC<{ state: boolean; largeIcon?: boolean }> = (props) => {
    if (props.largeIcon)
        return <>{props.state
            ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)" /> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)" /> </g> </g> </g> </g> </svg>
            : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)" /> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)" /> </g> </g> </g> </g> </svg>
        }</>;

    return <>{props.state
        ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H12V12H0z" opacity=".01" transform="translate(-481 -763) translate(355 758) translate(126 5)" /> <path fill="#000" fillRule="nonzero" d="M5.855 8.868c-.18 0-.328-.072-.472-.215L1.487 4.664c-.113-.113-.18-.256-.18-.425 0-.339.267-.61.61-.61.17 0 .318.071.436.184L5.86 7.407l3.497-3.594c.123-.118.277-.185.44-.185.339 0 .606.272.606.61 0 .17-.057.313-.175.426L6.332 8.653c-.134.143-.292.21-.477.215z" transform="translate(-481 -763) translate(355 758) translate(126 5) rotate(-180 5.855 6.248)" /> </g> </g> </g> </g> </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)" /> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)" /> </g> </g> </g> </g> </svg>
    }</>;
};

export const TooltipIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2"> <g> <g> <path d="M7 0c3.875 0 7 3.125 7 7s-3.125 7-7 7-7-3.125-7-7 3.125-7 7-7zm.426 5.833h-1.77c-.104 0-.17.017-.227.047-.057.03-.102.075-.132.132-.03.058-.047.123-.047.227v.227c0 .09.008.122.022.157.015.034.037.062.067.085.03.022.059.037.146.06l.58.144c.13.033.175.055.22.09.043.032.077.076.098.126.023.052.034.1.034.235v2.19c0 .135-.011.184-.034.235-.021.05-.055.094-.099.127-.045.034-.089.057-.22.09l-.58.144c-.086.023-.116.037-.145.06-.03.023-.052.051-.067.085-.014.034-.022.067-.022.156v.228c0 .104.016.17.047.226.03.057.075.102.132.133.057.03.123.046.226.046h2.981c.104 0 .17-.016.227-.046.056-.03.102-.076.132-.133.03-.057.047-.122.047-.226v-.228c0-.09-.008-.121-.022-.156-.015-.034-.037-.062-.067-.085-.03-.023-.059-.037-.146-.06l-.58-.144c-.13-.033-.175-.056-.22-.09-.043-.033-.077-.076-.099-.127-.022-.051-.033-.1-.033-.235V6.282c0-.156-.016-.213-.047-.27-.03-.056-.075-.102-.132-.132-.057-.03-.114-.047-.27-.047zM7 2.625c-.642 0-1.167.525-1.167 1.167 0 .641.525 1.166 1.167 1.166s1.167-.525 1.167-1.166c0-.642-.525-1.167-1.167-1.167z" transform="translate(-522 -550) translate(155 464) translate(367 86)" /> </g> </g> </g> </g> </svg>;

};