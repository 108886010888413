"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamSagas = exports.gotoTeam = exports.getTeamMembers = exports.getTeamList = exports.getTeam = void 0;
const class_transformer_1 = require("class-transformer");
const effects_1 = require("redux-saga/effects");
const entities_1 = require("entities");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
const user_1 = require("../../user");
function* getTeam(action) {
    try {
        const params = action && action.params || { team_id: undefined };
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/team`,
            authenticated: true,
            params: params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const selectedTeam = class_transformer_1.plainToClass(entities_1.SelectedTeam, response.data.data);
        yield effects_1.put({
            type: types_1.GetTeamAction.success,
            payload: { selectedTeam },
            meta: action && action.meta,
            selectedTeam
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetTeamAction.error,
            meta: action && action.meta,
            payload,
            error: true
        });
    }
}
exports.getTeam = getTeam;
function* getTeamList(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/teams`,
            authenticated: true
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const teams = response.data.data;
        const team = class_transformer_1.plainToClass(entities_1.Team, response.data.data);
        yield effects_1.put({
            type: types_1.GetTeamListAction.success,
            payload: { success: true },
            meta: action && action.meta,
            teams
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.GetTeamListAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action ? action.meta : false
        });
    }
}
exports.getTeamList = getTeamList;
function* updateTeam(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/teams`,
            authenticated: true,
            data: params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const team = class_transformer_1.plainToClass(entities_1.Team, response.data.data);
        yield effects_1.put({
            type: types_1.UpdateTeamAction.success,
            payload: { success: true, team },
            selectedTeam: team,
            meta: action.meta
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.UpdateTeamAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
function* createTeam(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/teams`,
            authenticated: true,
            data: params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const team = class_transformer_1.plainToClass(entities_1.Team, response.data.data);
        yield effects_1.put({
            type: types_1.CreateTeamAction.success,
            payload: { success: true, team },
            selectedTeam: team,
            meta: action.meta
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.CreateTeamAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
function* getTeamMembers(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/team/members`,
            authenticated: true
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const selectedTeam = class_transformer_1.plainToClass(entities_1.SelectedTeam, response.data.data);
        yield effects_1.put({
            type: types_1.TeamMembersAction.success,
            payload: Object.assign({ success: true }, selectedTeam),
            selectedTeam,
            meta: action && action.meta
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.TeamMembersAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action && action.meta
        });
    }
}
exports.getTeamMembers = getTeamMembers;
function* leaveTeam(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/team/left`,
            authenticated: true,
            data: action.params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.LeaveTeamAction.success,
            meta: action.meta,
            payload: { success: true },
        });
        yield getTeamList();
        yield getTeam();
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.LeaveTeamAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
function* joinTeam(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/team/join`,
            authenticated: true,
            data: params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.JoinTeamAction.success,
            payload: { success: true, data: response.data.data },
            meta: action.meta,
            userToken: response.data.data.token,
        });
        yield user_1.getUserSaga();
    }
    catch (error) {
        const err = error.response && error.response.data && error.response.data.error || {};
        yield effects_1.put({
            type: types_1.JoinTeamAction.error,
            payload: Object.assign({ error: true }, err),
            error: true,
            meta: action.meta
        });
    }
}
function* inviteTeam(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/team/invite`,
            authenticated: true,
            data: action.params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.InviteTeamAction.success,
            payload: { success: true },
            meta: action.meta,
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.InviteTeamAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
function* cancelInviteTeam(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/team/cancel_invite`,
            authenticated: true,
            data: action.params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.InviteTeamAction.success,
            payload: { success: true },
            meta: action.meta,
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.InviteTeamAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
function* gotoTeam(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/team/workspace`,
            authenticated: true,
            data: params
        };
        let response = yield effects_1.call(api_1.callApi, request);
        const selectedTeam = class_transformer_1.plainToClass(entities_1.SelectedTeam, response.data.data);
        yield effects_1.put({
            type: types_1.GoToTeamAction.success,
            payload: { selectedTeam },
            meta: action.meta,
            selectedTeam,
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.GoToTeamAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
exports.gotoTeam = gotoTeam;
function* grantRoleTeam(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/team/roles`,
            authenticated: true,
            data: params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const selectedTeam = class_transformer_1.plainToClass(entities_1.SelectedTeam, response.data.data);
        yield effects_1.put({
            type: types_1.TransferRoleAction.success,
            payload: Object.assign({ success: true }, selectedTeam),
            meta: action.meta,
            selectedTeam,
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.TransferRoleAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
function* getTeamIntro(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/team/intro`,
            authenticated: false,
            params: params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetTeamIntroAction.success,
            payload: { data: response.data.data },
            meta: action.meta,
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.GetTeamIntroAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
exports.teamSagas = [
    effects_1.takeLatest(types_1.GetTeamAction.type, getTeam),
    effects_1.takeLatest(types_1.GetTeamListAction.type, getTeamList),
    effects_1.takeLatest(types_1.UpdateTeamAction.type, updateTeam),
    effects_1.takeLatest(types_1.CreateTeamAction.type, createTeam),
    effects_1.takeLatest(types_1.TeamMembersAction.type, getTeamMembers),
    effects_1.takeLatest(types_1.UpdateTeamAction.join, joinTeam),
    effects_1.takeLatest(types_1.LeaveTeamAction.type, leaveTeam),
    effects_1.takeLatest(types_1.InviteTeamAction.type, inviteTeam),
    effects_1.takeLatest(types_1.InviteTeamAction.cancel, cancelInviteTeam),
    effects_1.takeLatest(types_1.GoToTeamAction.type, gotoTeam),
    effects_1.takeLatest(types_1.JoinTeamAction.type, joinTeam),
    effects_1.takeLatest(types_1.TransferRoleAction.type, grantRoleTeam),
    effects_1.takeLatest(types_1.GetTeamIntroAction.type, getTeamIntro),
];
