import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, PaymentHistory, UserService, PaymentService, Purchases } from 'core';
import * as ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailService, {ApiErrorMsg, InputErrorMsg, FieldType} from 'pages/auth/service/EmailService';
import SubmitButton from 'buttons/SubmitButton';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

const AccountInformationModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const user: User = useSelector((state: any) => state.user.user);
  const [stateUser, setStateUser] = useState(user);
  const [inputErrorState, setInputErrorState] = useState({
    email: false,
    name: false,
    phone_no: false
  });
  const paymentHistory: PaymentHistory = useSelector((state: any) => state.payment.paymentHistory);
  const { name, email, phone_no } = stateUser;
  const [apiErrorCode, setApiErrorCode] = useState({
    error: false,
    code: '0'
  });
  const service = new UserService();
  const paymentService = new PaymentService();
  
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [user]);

  useEffect(() => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {
          // console.log('res', res);
        },
        errorCallback: (err: any) => {
          // console.log('err', err);
        }
      }
    };
    paymentService.fetchPaymentHistory(params);
  }, []);

  const handleSaveInfo = () => {
    if(isDisabledButton) return;
    const params = {
      inputParams: {
        // email,
        name,
        phone_no,
      },
      callBackParams: {
        successCallback: (res: any) => {
          toast.success('Cập nhật thông tin người dùng thành công.', {position: 'top-center'});
          setApiErrorCode({
            code: '0',
            error: false,
          });
          closeModal();
        },
        errorCallback: (err: any) => {
          const message = 'Không thể lưu thông tin người dùng.';
          setApiErrorCode({
            code: err.code,
            error: true,
          });
          toast.error(message);
        }
      }
    };

    service.updateUser(params);
  };

  const closeModal = () => {
    props.closeModal();
  };

  const ref = {
    email: FieldType.Email,
    name: FieldType.Name,
    phone_no: FieldType.PhoneNo,
  };
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const newStateUser: User = Object.assign({}, stateUser, { [name]: value });
    setStateUser(newStateUser);

    if (checkValidField(ref[name], value)) {
      setInputErrorState({
        ...inputErrorState,
        [name]: false
      });
    }
  };
  const handleOnChangePhone = (event: any) => {
    const { name } = event.currentTarget;
    const valuePhoneState = event.target.validity.valid ? event.target.value : phone_no; 

    const newStateUser: User = Object.assign({}, stateUser, { phone_no: valuePhoneState });
    setStateUser(newStateUser);

    setInputErrorState({
      ...inputErrorState,
      [name]: !checkValidField(ref[name], valuePhoneState)
    });
  };

  const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const newStateUser: User = Object.assign({}, stateUser, { [name]: value });
    setStateUser(newStateUser);

    if (!checkValidField(ref[name], value)) {
      setInputErrorState({
        ...inputErrorState,
        [name]: true
      });
    }
  };

  const checkValidField = (type: FieldType, input: any): boolean => {
    switch (type) {
      case FieldType.Email:
        return !!(input && EmailService.validateEmailFormat(input));
      case FieldType.Name:
        return !!input.trim();
      case FieldType.PhoneNo:
        return !!(input && EmailService.validatePhoneNo(input));
      default:
        return true;
    }
  };

  const isDisabledButton = !checkValidField(ref['name'], name) || !checkValidField(ref['phone_no'], phone_no);

  const renderApiError = (shouldShow: boolean, code: string) => {
    if (!shouldShow) return null;
    return (
      <div className='mt-2 ml-3' style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg) => {
    if (!ifError) return null;
    return (
      <div className='mt-2' style={{color: 'red'}}>{errMsg}</div>
    );
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="account-information-modal">
        <div className="modal__heading">
          <h3>Thông tin tài khoản</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="account-information-panel">
            <div className="account-avatar--wrapper">
              <img
                className="avatar-img"
                src={user.getAvatarUrl()}
                alt="avatar"
              />
            </div>
            <div className="account-info--wrapper">
              <h4>{user.getUserName()}</h4>
              <p>ID: {user.getUserMasterId()}</p>
              <p className="account-user-type">
                {user.getAccountMembership() === 'normal' && <>Tài khoản thường<Link to={(globalThis as any).useHistory.generateComponent('PricingPage')} className="btn btn-default">• Nâng cấp tài khoản</Link></>}
              </p>
              <p className="account-type">Tài khoản đăng nhập bằng {user.getAcountType()}</p>
              <p className="package-list">
                {paymentHistory && paymentHistory.active_payments && paymentHistory.active_payments.filter((purchase: any) => {
                  return purchase.package.code === 'PRO';
                }).length} gói Chuyên nghiệp{'  •  '}
                {paymentHistory && paymentHistory.active_payments && paymentHistory.active_payments.filter((purchase: any) => {
                  return purchase.package.code === 'STARTER';
                }).length} gói Cơ bản{'  •  '}
                {paymentHistory && paymentHistory.active_payments && paymentHistory.active_payments.filter((purchase: any) => {
                  return purchase.package.code === 'FREE';
                }).length} gói Free
              </p>
              <Link to={(globalThis as any).useHistory.generateComponent('PackageInfoPage')} className="view-package-info">Xem thông tin gói</Link>
            </div>
          </div>
          <div className="modal__content--row">
            <label>Email</label>
            <input 
              type="text" name="email" className="form-control" value={email}  
              // onChange={handleOnChange}
              // onBlur={handleOnBlur}
              // onKeyPress={(event)=>{if(event.key === 'Enter' || event.which === 13 || event.keyCode === 13) handleSaveInfo();}}
              disabled
            />
          </div>
          {renderInputError(inputErrorState.email, InputErrorMsg.InvalidEmail)}
          <div className="modal__content--row">
            <label>Họ và tên</label>
            <input 
              type="text" 
              name="name" 
              className="form-control" 
              value={name} 
              onChange={handleOnChange} 
              onBlur={handleOnBlur}
              onKeyPress={(event)=>{if(event.key === 'Enter' || event.which === 13 || event.keyCode === 13) handleSaveInfo();}}
            />
          </div>
          {renderInputError(inputErrorState.name, InputErrorMsg.EmptyName)}
          <div className="modal__content--row">
            <label>Số điện thoại</label>
            <input 
              pattern="[0-9]*" 
              type="text" 
              name="phone_no" 
              className="form-control" 
              value={phone_no}
              onChange={handleOnChangePhone}
              onBlur={handleOnBlur}
              onKeyPress={(event)=>{if(event.key === 'Enter' || event.which === 13 || event.keyCode === 13) handleSaveInfo();}}
            />
          </div>
          {renderInputError(inputErrorState.phone_no, InputErrorMsg.InvalidPhoneNo)}
        </div>
        {renderApiError(apiErrorCode.error, apiErrorCode.code)}
        <div className="modal__bottom">
          <SubmitButton
            alignLeft
            isDisabled={isDisabledButton}
            className="btn-submit"
            onClick={handleSaveInfo}
          >
            Lưu
          </SubmitButton>
        </div>
      </div>
    </ReactModal>
  );
};

AccountInformationModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default AccountInformationModal;
