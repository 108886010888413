import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import 'stylesheets/App.css';
import CustomersTable from './CustomerTable';
import { EmptyPage } from './CustomersEmpty';
import {
  Customer,
  Project,
  LandingPage,
  transformCustomerExportData,
} from 'core';
import { ExportData } from './ExportData';
interface PropsType {
  customers: Array<Customer>;
  projects: Array<Project>;
  landing_pages: Array<LandingPage>;
}

const Content: React.FC<PropsType> = (props) => {
  const [projectId, setProjectId] = useState(0);
  const [landingPageId, setLandingPageId] = useState(0);
  const onProjectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setProjectId(parseInt(e.currentTarget.value));
    setLandingPageId(0);
  };
  const onLandingPageChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setLandingPageId(parseInt(e.currentTarget.value));
  };
  const retry = () => {
    setLandingPageId(0);
    setProjectId(0);
  };
  const renderProjects = () => {
    const projects: Array<Project> = props.projects;
    return (
      <div className="input-group">
        <label>Dự án</label>
        <select
          value={projectId}
          className="w-100 form-control filter-selection"
          onChange={onProjectChange}
        >
          <option value={0}>Tất cả Dự án</option>
          {projects.length &&
            projects.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
        </select>
      </div>
    );
  };

  const renderLandingPages = () => {
    let landingPages: Array<LandingPage> = props.landing_pages;
    if (projectId) {
      landingPages = landingPages.filter(({ pid }) => pid === projectId);
    }
    return (
      <div className="input-group">
        <label>Landing page</label>
        <select
          value={landingPageId}
          className="w-100 form-control filter-selection"
          onChange={onLandingPageChange}
          disabled={!projectId}
        >
          <option value={0}>Tất cả Landing page</option>
          {landingPages.length &&
            landingPages.map(({ lp_id: landingPageId, lp_name: landingPageName }) => (
              <option value={landingPageId} key={landingPageId}>
                {landingPageName}
              </option>
            ))}
        </select>
      </div>
    );
  };

  let { customers } = props;
  const showFilter = !!customers.length;
  if (projectId) {
    customers = customers.filter(({pid}) => pid === projectId);
    if (landingPageId) {
      customers = customers.filter(({ landing_page_id }) => landing_page_id === landingPageId);
    }
  }
  const isNotEmptyData = customers && customers.length > 0;

  let selectedLP: LandingPage | undefined;
  if (landingPageId) {
    selectedLP = props.landing_pages.find(
      ({ lp_id }) => lp_id === landingPageId
    );
  }

  return (
    <div
      className={isNotEmptyData ? 'main-content' : 'main-content fix-height'}
    >
      <div className="page-heading">
        <h1 className="page-title">Quản lý khách hàng</h1>
        {!!isNotEmptyData && (
          <div className="d-flex">
            <ExportData
              data={transformCustomerExportData(customers, selectedLP)}
              fileName={`danh-sach-KH-${moment().format('DDMMYYYY')}`}
            />
          </div>
        )}
      </div>
      {showFilter && (
        <div className="filter-container">
          <div className="row">
            <div className="col-lg-3">{renderProjects()}</div>
            <div className="col-lg-3">{renderLandingPages()}</div>
          </div>
        </div>
      )}
      {isNotEmptyData ? (
        <CustomersTable customers={customers} />
      ) : projectId ? (
        <div className="no-search-result">
          <img src="https://cdn-static.ymeet.me/general/REI/icon/no-data-filter.svg" />
          <div className="no-search-result__text">Không có dữ liệu</div>
          <div>
            <Link to="#" onClick={() => retry()}>
              thử lại
            </Link>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};

export { Content };
