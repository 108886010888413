import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, ICampaignData, LandingPageService } from 'core';
import * as ReactModal from 'react-modal';

interface InputType {
  // id?: number;
  title: string;
  description1?: string; 
  description2?: string;
  component: string;
  value: number;
  url: string;
}

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  dataSiteLink: InputType[];
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

export const PreviewSiteLinkModal: React.FC<ModalProps> = (props) => {
  const currentCampaign = useSelector<any, ICampaignData | null>((state: AppState) => state.adsCampaign.currentCampaign);
  const landingPage = LandingPageService.findUserLandingPage(currentCampaign?.landing_page_id ? currentCampaign?.landing_page_id : 0);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const getRandomSiteLinks = (maxItem: number, siteLinks: InputType[]): InputType[] => {
    const getRandomIndex = (length: number): number => {
      return Math.floor(Math.random() * length);
    };

    if (siteLinks.length <= maxItem) {
      return siteLinks;
    }

    const selectedIndexs: number[] = [];
    while (selectedIndexs.length < maxItem) {
      const index = getRandomIndex(siteLinks.length);
      if (!selectedIndexs.some((x) => {return x === index;})) {
        selectedIndexs.push(index);
      }
    }

    return selectedIndexs.map((index) => {return siteLinks[index];});
  };

  const selectedSiteLinks = getRandomSiteLinks(4, props.dataSiteLink);

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="preview-sitelink-modal">
        <div className="modal__heading">
          <h3>Xem trước tiện ích mở rộng</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-890 -65) translate(355 47) translate(527 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal__content">
          <div className="previewBox">
            <h4>Điện thoại</h4>
            <div className="mobile-preview">
              <div className="previewBox--shadow">
                <div className="project-name"><strong>Quảng cáo</strong>   •   {landingPage?.url}</div>
                <div className="ad-title">Dòng tiêu đề 1 | Dòng tiêu đề 2 | Dòng tiêu đề 3</div>
                <div className="ad-description">Nội dung mô tả 1 | Nội dung mô tả 2</div>
                <div className="sitelink-url__wrapper">
                  {selectedSiteLinks.map((x, index) => {
                    return <div key={index} className="sitelink-url">{x.title}</div>;
                  })}
                  </div>
              </div>
            </div>
            <h4>Máy tính</h4>
            <div className="desktop-preview">
              <div className="project-name"><strong>Quảng cáo</strong>   •   {landingPage?.url}</div>
              <div className="ad-title">Dòng tiêu đề 1 | Dòng tiêu đề 2 | Dòng tiêu đề 3</div>
              <div className="ad-description">Nội dung mô tả 1 | Nội dung mô tả 2</div>
              <div className="sitelink-url__wrapper row">
                {selectedSiteLinks.map((x, index) => {
                  return <div key={index} className="col-6 sitelink-url">{x.title}</div>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

PreviewSiteLinkModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};
