import {
  LandingPage, Project, UserPublishedLandingPagesData, userPublishedLandingPagesState
} from 'core';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Select, { ValueType } from 'react-select';
import 'stylesheets/App.css';
import { useFetchUserPublishedUrls } from './hooks/fetchUserPublishedUrls';

type OptionType = { label: string; value: number };

interface PropsType {
  projects: Array<Project>;
  landing_pages: Array<LandingPage>;
  userPublishedUrls: userPublishedLandingPagesState;
}

const Content: React.FC<PropsType> = (props) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedUrlId, setSelectedUrlId] = useState(0);
  const [projectIdState, setProjectIdState] = useState(0);

  useFetchUserPublishedUrls();
  const urlData: UserPublishedLandingPagesData[] = props.userPublishedUrls.data;

  const options: Array<{value: number; label: string}> = urlData.filter((item: UserPublishedLandingPagesData)=>item.is_owner).map((item: UserPublishedLandingPagesData) => ({value: item.lp_id, label: item.url}));

  const customStyles = {
    container: () => ({
      width: '100%',
    }),
    valueContainer: (provided: object) => ({
      ...provided,
      padding: '15px 20px',
    }),
    input: (provided: object) => ({
      ...provided,
      margin: 0,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      color: '#000000'
    }),
    placeholder: (provided: object) => ({
      ...provided,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      color: '#666666'
    }),
  };


  const renderPublishedUrlSelection = () => {
    return (
      <div className="input-group">
        <label>1. Chọn landing page để chạy quảng cáo</label>
        <Select
          onChange={(selectedOption: ValueType<OptionType, false>) => {
            const value = selectedOption ? (selectedOption as OptionType).value : 0;
            setSelectedUrlId(value);
            const pInfo = props.landing_pages.find(lp => lp.lp_id === value);
            const pId = pInfo ? pInfo.pid : 0;
            setProjectIdState(pId);
          }}
          styles={customStyles}
          isSearchable={true}
          placeholder={'Tìm kiếm hoặc lựa chọn landing page đang chạy'}
          isClearable
          noOptionsMessage={({ inputValue: string }) => 'Landingpage url không hợp lệ. Vui lòng kiểm tra lại.'}
          // onChange={setSelectedOption}
          options={options}
        />
      </div>
    );
  };

  const renderSelectTypeExport = () => {
    return (
      <div>
        <label>2. Chọn một mục để bắt đầu tạo mẫu quảng cáo</label>
        <div className="row">
          <div className="col-6" onClick={() => setSelectedOption(!selectedOption)}>
            <div className={`box-shadow ${selectedOption ? 'active' : ''}`}>
              {!!selectedOption && <div className="icon-anchor">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#FFF"> <g> <g> <g> <g> <path d="M0 0H12.5V12.5H0z" opacity=".01" transform="translate(-447 -311) translate(434 258) translate(0 40) translate(10 10) translate(3.75 3.75)"/> <path d="M5.185 10.805c-.295 0-.518-.108-.72-.352l-2.68-3.29c-.16-.187-.222-.364-.222-.55 0-.43.316-.741.756-.741.26 0 .446.098.617.31l2.228 2.81 4.364-6.903c.181-.29.368-.394.663-.394.436 0 .746.305.746.73 0 .161-.051.332-.17.519l-4.851 7.483c-.171.254-.415.378-.731.378z" transform="translate(-447 -311) translate(434 258) translate(0 40) translate(10 10) translate(3.75 3.75)"/> </g> </g> </g> </g> </g> </g> </svg>
              </div>}
              <div className="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <g> <path fill="#FFF" fillOpacity=".01" d="M0 0H24V24H0z" transform="translate(-576 -342) translate(434 260) translate(0 40) translate(130 30) translate(12 12)"/> <path fill="#000" fillRule="nonzero" d="M20.451 20.953c2.051 0 3.067-1.015 3.067-3.027V6.002c0-2.012-1.016-3.027-3.067-3.027H3.557C1.516 2.975.49 3.98.49 6.002v11.924c0 2.021 1.026 3.027 3.067 3.027H20.45zm-12.637-8.75c-1.328 0-2.412-1.084-2.412-2.412 0-1.318 1.084-2.412 2.412-2.412 1.319 0 2.403 1.094 2.403 2.412 0 1.328-1.084 2.412-2.403 2.412zm12.627 7.178H3.576c-.947 0-1.514-.567-1.514-1.524v-.595l2.999-2.617c.43-.381.898-.577 1.328-.577.478 0 .976.196 1.406.596l1.865 1.68 4.668-4.14c.479-.43 1.016-.626 1.572-.626.537 0 1.094.205 1.553.635l4.492 4.16v1.504c0 .937-.576 1.504-1.504 1.504z" transform="translate(-576 -342) translate(434 260) translate(0 40) translate(130 30) translate(12 12)"/> </g> </g> </g> </g> </g> </g> </svg>
              </div>
              <div className="box-title">Phương tiện truyền thông</div>
              <p className="box-description">Tạo hình ảnh/ video cho mẫu quảng cáo</p>
            </div>
          </div>
          <div className="col-6">
            <div className="box-shadow">
              <div className="icon-wrapper blur">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <g> <path fill="#FFF" fillOpacity=".01" d="M0 0H24V24H0z" transform="translate(-576 -342) translate(434 260) translate(0 40) translate(130 30) translate(12 12)"/> <path fill="#000" fillRule="nonzero" d="M20.451 20.953c2.051 0 3.067-1.015 3.067-3.027V6.002c0-2.012-1.016-3.027-3.067-3.027H3.557C1.516 2.975.49 3.98.49 6.002v11.924c0 2.021 1.026 3.027 3.067 3.027H20.45zm-12.637-8.75c-1.328 0-2.412-1.084-2.412-2.412 0-1.318 1.084-2.412 2.412-2.412 1.319 0 2.403 1.094 2.403 2.412 0 1.328-1.084 2.412-2.403 2.412zm12.627 7.178H3.576c-.947 0-1.514-.567-1.514-1.524v-.595l2.999-2.617c.43-.381.898-.577 1.328-.577.478 0 .976.196 1.406.596l1.865 1.68 4.668-4.14c.479-.43 1.016-.626 1.572-.626.537 0 1.094.205 1.553.635l4.492 4.16v1.504c0 .937-.576 1.504-1.504 1.504z" transform="translate(-576 -342) translate(434 260) translate(0 40) translate(130 30) translate(12 12)"/> </g> </g> </g> </g> </g> </g> </svg>
              </div>
              <div className="box-title blur">Văn bản</div>
              <p className="box-description">Đang cập nhật</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={'main-content fix-height'}
    >
      <div className="page-heading">
        <div className="d-flex justify-content-start align-items-start">
          <div>
            <h1 className="page-title">Tạo mẫu quảng cáo</h1>
            <p>Hoàn tất 2 bước sau để bắt đầu tạo mẫu quảng cáo dành riêng cho bạn.</p>
          </div>
        </div>
      </div>
      <div className="filter-container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">{renderPublishedUrlSelection()}</div>
          <div className="col-lg-6 offset-lg-3">{!!selectedUrlId && renderSelectTypeExport()}</div>   
        </div>
      </div>
      {!!selectedUrlId && !!selectedOption && <div className='panel-fixed-bottom full button-group'>
        <div className="container">
          <Link to={(globalThis as any).useHistory.generateComponent('CreationPreviewPage', {type: 1}) + `?project_id=${projectIdState}&landing_page_id=${selectedUrlId}`} className="btn btn-default float-right">Bắt đầu</Link>
        </div>
      </div>}
    </div>
  );
};

export { Content };
