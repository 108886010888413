"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchFanpage = exports.ConnectFbAccount = exports.GetFbAdAccount = exports.GetFbAdsId = void 0;
exports.GetFbAdsId = {
    type: 'GetFbAdsId',
    success: 'GetFbAdsId_SUCCESS',
    error: 'GetFbAdsId_ERROR',
};
exports.GetFbAdAccount = {
    type: 'GetFbAdAccount',
    success: 'GetFbAdAccount_SUCCESS',
    error: 'GetFbAdAccount_ERROR',
};
exports.ConnectFbAccount = {
    type: 'ConnectFbAccount',
    success: 'ConnectFbAccount_SUCCESS',
    error: 'ConnectFbAccount_ERROR',
};
exports.FetchFanpage = {
    type: 'FetchFanpage',
    success: 'FetchFanpage_SUCCESS',
    error: 'FetchFanpage_ERROR',
};
