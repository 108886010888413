"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messageCode = {
    100: {
        text: 'Authen',
        type: 'success'
    },
    200: {
        text: 'Home workspace',
        type: 'success'
    },
    300: {
        text: 'Team workspace',
        type: 'success'
    },
    301: {
        text: 'Bạn đã tham gia vào nhóm thành công.',
        type: 'success'
    },
    302: {
        text: 'Gửi lời mời thành công.',
        type: 'success'
    },
    303: {
        text: 'Bạn đã rời khỏi nhóm.',
        type: 'success'
    }
};
