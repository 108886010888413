"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootSaga = void 0;
const effects_1 = require("redux-saga/effects");
const ads_1 = require("../ads");
const analytics_1 = require("../analytics");
const auth_1 = require("../auth");
const adapters_1 = require("../customer/adapters");
const landing_page_1 = require("../landing-page");
const project_1 = require("../project");
const user_1 = require("../user");
const team_1 = require("../team");
const ads_campaign_1 = require("../ads-campaign");
const GoogleAds_1 = require("../GoogleAds");
const notification_1 = require("../notification");
const payment_1 = require("../payment");
function* rootSaga() {
    yield effects_1.all([
        ...auth_1.authSagas,
        ...user_1.userSagas,
        ...team_1.teamSagas,
        ...project_1.projectSagas,
        ...landing_page_1.landingPageSagas,
        ...adapters_1.customerSagas,
        ...analytics_1.analyticsSagas,
        ...ads_1.adsSagas,
        ...ads_campaign_1.adsCampaignSagas,
        ...GoogleAds_1.googleAdsSaga,
        ...GoogleAds_1.googleCampaignForcastSaga,
        ...notification_1.notificationSagas,
        ...payment_1.paymentSagas,
    ]);
}
exports.rootSaga = rootSaga;
