"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLandingPageAd = exports.getLandingPageData = exports.getUserPublishedUrls = void 0;
const types_1 = require("../types");
const getUserPublishedUrls = () => ({
    type: types_1.GET_USER_PUBLISHED_LANDING_PAGES
});
exports.getUserPublishedUrls = getUserPublishedUrls;
const getLandingPageData = (params) => ({
    type: types_1.GET_LANDING_PAGE_DATA,
    meta: {
        thunk: true
    },
    params
});
exports.getLandingPageData = getLandingPageData;
const getLandingPageAd = (lpId) => ({
    type: types_1.GET_LANDING_PAGE_AD,
    meta: {
        thunk: true
    },
    lpId
});
exports.getLandingPageAd = getLandingPageAd;
