import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, ISitelinkExtensionSetting } from 'core';
import * as ReactModal from 'react-modal';
import { ExtensionAction } from '../forms/CommonSettings';
import { InputLength, InputType, InputCallType, ErrorInput, UpdateSitelinkIO, importSiteLinkData } from '../fields';
import { useGoogleForm } from 'utils/hooks';
import { useFieldArray } from 'react-hook-form';
import classNames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, Validate } from 'react-hook-form';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  submit: () => void;
  title: string;
}

ReactModal.setAppElement('#root');

export const EditExtensionModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };
  
  const submit = () => {
    props.submit();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles} shouldCloseOnOverlayClick={false}>
      <div className="edit-sitelink-modal">
        <div className="modal__heading">
          <h3>{props.title}</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-890 -65) translate(355 47) translate(527 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal__content">
        {props.children}
        </div>
        <div className="modal__bottom">
          <button onClick={closeModal} className="btn-subsidiary btn-grey-suit">Huỷ</button>
          <button onClick={submit} className="btn-default ml-3">Lưu</button>
        </div>
      </div>
    </ReactModal>
  );
};

interface ExtensionModalType<T> {
  value?: T;
  isOpen: boolean;
  closeModal: () => void;
  onAction: (data: T[], action: ExtensionAction) => void;
}
type SiteLinkPropsType = ExtensionModalType<InputType> & {
  allItems: UpdateSitelinkIO;
}

interface SiteLink {
  sitelinks: InputType[];
}
export const EditSiteLinkModal: React.FC<SiteLinkPropsType> = ({closeModal, isOpen, value, ...props}) => {
  const isCreateNew = !value;
  const createNew = new Array(2).fill(undefined).map((el, id)=>({
    title: '', description1: '', description2: '', url: ''
    // title: 'Test ' + id, 
    // url: 'htts://test' + id + '.com'
  } as InputType));
  
  const {
    useForm, vestResolver
  } = useGoogleForm();
  const { register, control, reset, handleSubmit, watch, formState: {errors} } = useForm<InputType | SiteLink>({
    defaultValues: isCreateNew ? { sitelinks: createNew } : {
      title: value?.title,
      description1: value?.description1,
      description2: value?.description2,
      component: value?.component,
      url: value?.url,
    }
  });
  const { fields, append } = useFieldArray({
    control: control,
    name: 'sitelinks',
  });
  const allData = watch();
  
  useEffect(() => {
    if(isCreateNew)
      reset({ sitelinks: createNew });
    else
      reset(value);
      
    return undefined;
  }, [value, isOpen]);
  
  const handleOnSave = () => {
    const action = isCreateNew ? ExtensionAction.Add : ExtensionAction.Edit;
    
    if(errors)
      console.log(errors);
    
    if(isCreateNew) {
      const final: SiteLink = allData as SiteLink;
      const sitelinks: InputType[] = final.sitelinks.filter(el=>el.title);
      props.onAction(sitelinks, action);
    }else{
      if(value) props.onAction([allData as InputType, value], action);
    }
  };
  
  const handleAdd = () => {
    append({title: '', description1: '', description2: '', url: ''});
  };
  
  const title = value ? 'Sửa đổi thông tin liên kết' : 'Thêm phần mở rộng mới về đường liên kết trang web';
  const allItems: InputType[] = importSiteLinkData(props.allItems);
  
  const renderMultiItem = (data: InputType, id: number, allData: any[]) => {
    const fieldData = {
      titleLeng: watch(`sitelinks.${id}.title` as const) || '',
      description1Leng: watch(`sitelinks.${id}.description1` as const) || '',
      description2Leng: watch(`sitelinks.${id}.description2` as const) || '',
      urlLeng: watch(`sitelinks.${id}.url` as const) || '',
    };
    const allDataWatch = watch();
    const validation = (key: string) => {
      // There're at least one title input has value
      const hasAtLeastOneTitle = (allDataWatch as SiteLink)?.sitelinks.filter(el=>el.title).length < 1;
      const hasTitle = !!(allDataWatch as SiteLink)?.sitelinks[id].title;
      
      const ret = {
        'title': hasAtLeastOneTitle,
        'url': hasTitle
      };
      return ret[key];
    };
    const validate = validation('url');
    const validateTitle = validation('title');
    
    const validateSchema = {
      title: {
        maxLength: {value: 25, message: 'Văn bản liên kết nhập 25 ký tự trở xuống'}, 
        required: {value: validateTitle, message: 'Hãy nhập văn bản liên kết trang web'},
      },
      desc1: {
        maxLength: {value: 35, message: 'Mô tả nhập 35 ký tự trở xuống'}, 
        required: { value: false, message: 'Hãy nhập mô tả 1' },
        validate: {
          bothValueOrEmpty: (value: string) => {
            return (!!value === !!watch(`sitelinks.${id}.description2` as const)) || 'Cần nhập mô tả cho tất cả các tiện ích liên kết trang web';
          }
        }
      },
      desc2: {
        maxLength: {value: 35, message: 'Mô tả nhập 35 ký tự trở xuống'}, 
        required: { value: false, message: 'Hãy nhập mô tả 2' },
        validate: {
          bothValueOrEmpty: (value: string) => {
            return (!!value === !!watch(`sitelinks.${id}.description1` as const)) || 'Cần nhập mô tả cho tất cả các tiện ích liên kết trang web';
          }
        }
      },
      url: {
        required: {value: validate, message: 'Hãy nhập đường dẫn đầy đủ (khuyến nghị sử dụng https)'},
        validate: {
          pattern: (value: string) => {
            if(validate){
              const regex = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
              const message = 'Xin hãy nhập đúng định dạng đường dẫn, bắt đầu bằng http:// hoặc https://';
              return regex.test(value) || message; 
            }
            return;
          },
          checkDuplicate: async (value: string)=>{
            const found = await allItems.find((el: InputType)=>el.url === value);
            const message = 'Đường dẫn đã tồn tại';
            return !found || message;
          }
        }
      }
    };
    
    return <React.Fragment key={id + 1}><div className="modal__content--row position-relative">
          <label>Liên kết trang web {id + 1}</label>
          <input
            {...register(`sitelinks.${id}.title` as const, validateSchema.title)}
            type="text"
            maxLength={25}
            className={classNames('form-control mb-2', {'mb-2 error': errors[`sitelinks[${id}]title`]})}
            defaultValue={data?.title} 
            placeholder="Văn bản liên kết trang web" 
          />
          <InputLength className="modal__content--input-counter" text={fieldData.titleLeng} max={26}/>
          <ErrorMessage 
          errors={errors} 
          name={`sitelinks.${id}.title` as never} 
          render={({message})=><ErrorInput text={message}/>}/>
          
        </div>
        <div className="modal__content--row position-relative">
          <input
            {...register(`sitelinks.${id}.description1` as const, validateSchema.desc1)}
            type="text" 
            maxLength={35}
            className={classNames('form-control mb-2', {'error': (errors as any).description1})}
            defaultValue={data?.description1} 
            placeholder="Mô tả 1" 
          />
          <InputLength className="modal__content--input-counter" text={fieldData.description1Leng} max={36}/>
          <ErrorMessage 
          errors={errors} 
          name={`sitelinks.${id}.description1` as never} 
          render={({message})=><ErrorInput text={message}/>}/>
          
        </div>
        <div className="modal__content--row position-relative">
          <input
            {...register(`sitelinks.${id}.description2` as const, validateSchema.desc2)}
            maxLength={35}
            type="text" 
            className={classNames('form-control mb-2', {'error': (errors as any).description2})}
            defaultValue={data?.description2} 
            placeholder="Mô tả 2" 
          />
          <InputLength className="modal__content--input-counter" text={fieldData.description2Leng} max={36}/>
          <ErrorMessage 
          errors={errors} 
          name={`sitelinks.${id}.description2` as never} 
          render={({message})=><ErrorInput text={message}/>}/>
          
        </div>
        <div className="modal__content--row position-relative">
          <input
            {...register(`sitelinks.${id}.url` as const, validateSchema.url)}
            type="text" 
            className={classNames('form-control mb-2', {'error': (errors as any).url})}
            defaultValue={data?.url} 
            placeholder="URL cuối cùng" 
          />
          <ErrorMessage 
          errors={errors} 
          name={`sitelinks.${id}.url` as never} 
          render={({message})=><ErrorInput text={message}/>}/>
          
          {/*<InputLength className="modal__content--input-counter" text={!isCreateNew ? fieldData.single.urlLeng : fieldData.multi.urlLeng} max={35}/>*/}
        </div>
      </React.Fragment>;};
      
  const renderSingleItem = (data: InputType) => {
    const fieldData = {
      titleLeng: watch('title') || '',
      description1Leng: watch('description1') || '',
      description2Leng: watch('description2') || '',
      urlLeng: watch('url') || '',
    };
    
    const validateSchema = {
      title: {
        maxLength: {value: 25, message: 'Văn bản liên kết nhập 25 ký tự trở xuống'}, 
        required: {value: true, message: 'Hãy nhập văn bản liên kết trang web'},
      },
      desc1: {
        maxLength: {value: 35, message: 'Mô tả nhập 35 ký tự trở xuống'}, 
        required: { value: false, message: 'Hãy nhập mô tả 1' },
        validate: {
          bothValueOrEmpty: (value: string) => {
            return (!!value === !!watch('description2')) || 'Cần nhập mô tả cho tất cả các tiện ích liên kết trang web';
          }
        }
      },
      desc2: {
        maxLength: {value: 35, message: 'Mô tả nhập 35 ký tự trở xuống'}, 
        required: { value: false, message: 'Hãy nhập mô tả 2' },
        validate: {
          bothValueOrEmpty: (value: string) => {
            return (!!value === !!watch('description1')) || 'Cần nhập mô tả cho tất cả các tiện ích liên kết trang web';
          }
        }
      },
      url: {
        required: {value: true, message: 'Hãy nhập đường dẫn đầy đủ (khuyến nghị sử dụng https)'},
        validate: {
          pattern: (value: string) => {
            const regex = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            const message = 'Xin hãy nhập đúng định dạng đường dẫn, bắt đầu bằng http:// hoặc https://';
            return regex.test(value) || message; 
          },
          checkDuplicate: async (value: string)=>{
            //find all links except current item
            const found = await allItems.filter(el=>el.url !== data.url).find((el: InputType)=>el.url === value);
            const message = 'Đường dẫn đã tồn tại';
            return !found || message;
          }
        }
      }
    };
    
    return <><div className="modal__content--row position-relative">
          <label>Liên kết trang web</label>
          <input
            {...register('title', validateSchema.title)}
            maxLength={25}
            type="text" className="form-control mb-2" 
            defaultValue={data.title} 
            placeholder="Văn bản liên kết trang web" 
          />
          {<InputLength className="modal__content--input-counter" text={fieldData.titleLeng} max={26}/>}
          <ErrorMessage 
          errors={errors} 
          name={'title' as never} 
          render={({message})=><ErrorInput text={message}/>}/>
        </div>
        <div className="modal__content--row position-relative">
          <input
            {...register('description1', validateSchema.desc1)}
            maxLength={35}
            type="text" 
            className="form-control mb-2" 
            defaultValue={data.description1} 
            placeholder="Mô tả 1" 
          />
          <InputLength className="modal__content--input-counter" text={fieldData.description1Leng} max={36}/>
          <ErrorMessage 
          errors={errors} 
          name={'description1' as never} 
          render={({message})=><ErrorInput text={message}/>}/>
        </div>
        <div className="modal__content--row position-relative">
          <input
            {...register('description2', validateSchema.desc2)}
            maxLength={35}
            type="text" 
            className="form-control mb-2" 
            defaultValue={data.description2} 
            placeholder="Mô tả 2" 
          />
          <InputLength className="modal__content--input-counter" text={fieldData.description2Leng} max={36}/>
          <ErrorMessage 
          errors={errors} 
          name={'description2' as never} 
          render={({message})=><ErrorInput text={message}/>}/>
        </div>
        <div className="modal__content--row position-relative">
          <input
            {...register('url', validateSchema.url)}
            type="text" 
            className="form-control mb-2" 
            defaultValue={data.url} 
            placeholder="URL cuối cùng" 
          />
          {/*<InputLength className="modal__content--input-counter" text={!isCreateNew ? fieldData.single.urlLeng : fieldData.multi.urlLeng} max={35}/>*/}
          <ErrorMessage 
          errors={errors} 
          name={'url' as never} 
          render={({message})=><ErrorInput text={message}/>}/>
        </div>
      </>;};

  return <EditExtensionModal closeModal={closeModal} title={title} isOpen={isOpen} submit={handleSubmit(handleOnSave)}>
      {!isCreateNew && value
        ? renderSingleItem(value)
        : fields.map((field: any, id: number)=>renderMultiItem(field.value, id, fields))
      }
      
      {!value && <div className="add-extend-link cursor-pointer" onClick={handleAdd}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M6.997 16C10.833 16 14 12.832 14 9s-3.174-7-7.01-7C3.16 2 0 5.168 0 9s3.167 7 6.997 7zm0-1.395c-3.106 0-5.59-2.498-5.59-5.605S3.891 3.401 6.99 3.401c3.106 0 5.603 2.492 5.61 5.599.006 3.107-2.498 5.605-5.603 5.605zm-.014-2.572c.4 0 .643-.27.643-.69V9.65h1.813c.406 0 .69-.244.69-.636 0-.4-.27-.644-.69-.644H7.626v-1.8c0-.42-.244-.69-.643-.69-.392 0-.63.283-.63.69v1.8H4.548c-.42 0-.697.244-.697.644 0 .392.291.636.697.636h1.807v1.692c0 .407.237.69.63.69z" transform="translate(-365 -1109) translate(355 754) translate(0 48) translate(10 305)"/> </g> </g> </g> </g> </g> </svg>
        <span>Thêm mới liên kết trang web</span>
      </div>}
  </EditExtensionModal>;
};


type CallPropsType = ExtensionModalType<InputCallType>
interface Call {
  calls: InputCallType[];
}

export const EditCallModal: React.FC<CallPropsType> = ({closeModal, isOpen, value, onAction}) => {
  const isCreateNew = !value;
  const user: User = useSelector((state: any) => state.user.user);
  const [stateUser, setStateUser] = useState(user);
  const { name, email, phone_no: phoneNo } = stateUser;
  
  const createNew = new Array(2).fill(undefined).map((el, id)=>({
    phone_number: '', value: 0
  } as InputCallType));
  
  const {
    useForm, vestResolver, CampaignExtensionCallSchema
  } = useGoogleForm();
  const { register, control, reset, handleSubmit, watch, formState: {errors} } = useForm<InputCallType | Call>({
    // resolver: vestResolver(CampaignExtensionCallSchema),
    defaultValues: isCreateNew ? { calls: createNew } : {
      phone_number: value?.phone_number,
    }
  });

  const { fields, append } = useFieldArray({
    control: control,
    name: 'calls',
  });
  const allData = watch();
  
  useEffect(() => {
    if(isCreateNew)
      reset({ calls: createNew });
    else
      reset(value);
      
    return undefined;
  }, [value, isOpen]);
  
  const handleOnSave = (data: any) => {
    if(isCreateNew) {
      const final: Call = allData as Call;
      const calls: InputCallType[] = final.calls.filter(el=>el.phone_number);
      onAction(calls, ExtensionAction.Add);
    }else{
      if(value) onAction([allData as InputCallType, value], ExtensionAction.Edit);
    }
  };
  
  const handleAdd = () => {
    append({phone_number: ''});
  };

  const hasAtLeastOne = (allData as Call)?.calls?.filter(el=>el.phone_number).length < 1;
  
  const validateSchema = {
    phone_number: {
      required: {value: hasAtLeastOne, message: 'Hãy nhập số điện thoại'},
      pattern: {
        value: /((0|\+84)+(3|5|7|8|9)+([0-9]{8})\b)/g,
        message: 'Xin hãy nhập đúng định dạng số điện thoại'
      }
    }
  };
  
  const title = value ? 'Sửa đổi số điện thoại' : 'Thêm số điện thoại';
  
  return <EditExtensionModal closeModal={closeModal} title={title} isOpen={isOpen} submit={handleSubmit(handleOnSave)}>
      {isCreateNew ? fields.map((item: any, id: number)=><React.Fragment key={`call-item-${id}`}>
        <div className="modal__content--row">
          <label>Số điện thoại</label>
          <input 
            {...register(`calls.${id}.phone_number` as const, validateSchema.phone_number)}
            type="text" 
            // name="text_link-1" 
            maxLength={12}
            className="form-control mb-3" 
            defaultValue={item ? item.phone_number : phoneNo} 
            placeholder="Số điện thoại" 
            // onChange={handleOnChange}
          />
          
          <ErrorMessage 
          errors={errors} 
          name={`calls.${id}.phone_number` as never} 
          render={({message})=><ErrorInput text={message}/>}/>
          
        </div>
      </React.Fragment>)
      : <div className="modal__content--row">
          <label>Số điện thoại</label>
          <input 
            {...register('phone_number', validateSchema.phone_number)}
            type="text" 
            maxLength={12}
            // name="text_link-1" 
            className="form-control mb-3" 
            defaultValue={allData ? (allData as InputCallType).phone_number : phoneNo} 
            placeholder="Số điện thoại" 
            // onChange={handleOnChange}
          />
          
          <ErrorMessage 
          errors={errors} 
          name={'phone_number' as never} 
          render={({message})=><ErrorInput text={message}/>}/>
          
        </div>
      }
      {!value && <div className="add-extend-link cursor-pointer" onClick={handleAdd}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M6.997 16C10.833 16 14 12.832 14 9s-3.174-7-7.01-7C3.16 2 0 5.168 0 9s3.167 7 6.997 7zm0-1.395c-3.106 0-5.59-2.498-5.59-5.605S3.891 3.401 6.99 3.401c3.106 0 5.603 2.492 5.61 5.599.006 3.107-2.498 5.605-5.603 5.605zm-.014-2.572c.4 0 .643-.27.643-.69V9.65h1.813c.406 0 .69-.244.69-.636 0-.4-.27-.644-.69-.644H7.626v-1.8c0-.42-.244-.69-.643-.69-.392 0-.63.283-.63.69v1.8H4.548c-.42 0-.697.244-.697.644 0 .392.291.636.697.636h1.807v1.692c0 .407.237.69.63.69z" transform="translate(-365 -1109) translate(355 754) translate(0 48) translate(10 305)"/> </g> </g> </g> </g> </g> </svg>
        <span>Thêm mới số điện thoại</span>
      </div>}
  </EditExtensionModal>;
};

EditExtensionModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};