import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import * as ReactModal from 'react-modal';
import { SelectedTeam, LicenseInfor, PackageInfo, PaymentType, PackageName, Team, TeamPermissionService } from 'core';
import { History } from 'src/route/Route';
import _ from 'lodash';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  goNext: () => void;
  activedLicense?: LicenseInfor;
  selectedPackage?: PackageInfo;
  selectNumberMembers: number;
  paymentType?: PaymentType;
  selectedTeam?: Team;
}
ReactModal.setAppElement('#root');

const UpgradePackageModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const history = new History();
  const { selectedPackage, paymentType, selectNumberMembers, activedLicense, selectedTeam } = props;
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const nextToPaymentStep = () => {
    history.push('/payment/payment-method', { selectedPackage, paymentType, selectNumberMembers, selectedTeam, activedLicense });
  };

  let activedLicenseName = '';
  let selectedPackageName = '';
  
  const teamPermissionService = TeamPermissionService.getTeamPermission(selectedTeam?.id);
  const numberTeamMembers = teamPermissionService.MaxTeamSize;

  if (!_.isEmpty(activedLicense) && activedLicense) {
    activedLicenseName = PackageName[activedLicense.package.code];
  }
  if (!_.isEmpty(selectedPackage) && selectedPackage) {
    selectedPackageName = PackageName[selectedPackage.code];
  }

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="package-upgrade-modal-container">
        <div className="modal__heading">
          <h3>Thông báo</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            {
              !selectedTeam || !selectedTeam.license_infor.id ? 
              (<p className="pb-0">Bạn đang chuyển từ gói <span>{PackageName.STARTER}</span> sang gói <span>{PackageName.PRO}</span>. Sau khi thanh toán thành công, gói <span>{PackageName.PRO}</span> sẽ có hiệu lực</p>) :
              (<p className="pb-0">Bạn đang chuyển từ gói <span>{activedLicenseName} {numberTeamMembers} người</span> sang gói <span>{selectedPackageName} {selectNumberMembers} người</span>. Sau khi thanh toán thành công, gói <span>{selectedPackageName} {selectNumberMembers} người</span> sẽ có hiệu lực</p>)
            }
          </div>
        </div>
        <div className="modal__bottom">
          <button onClick={closeModal} className="btn-subsidiary btn-grey-suit">Quay lại</button>
          <button onClick={nextToPaymentStep} className="btn-default ml-3">Xem chi tiết & Tiếp tục</button>
        </div>
      </div>
    </ReactModal>
  );
};

UpgradePackageModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default UpgradePackageModal;
