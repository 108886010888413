import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withCookies } from 'react-cookie';
import { EmailService, InputErrorMsg, FieldType, ApiErrorMsg } from '../service';

interface RegisterByEmailProps {
}

const RegisterByEmail: React.FC<RegisterByEmailProps> = (props) => {
  const dispatch = useDispatch();
  const apiError: any = useSelector((state: any) => state.auth.error);

  const [emailState, setEmailState] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [confirmPasswordState, setConfirmPasswordState] = useState('');
  const [nameState, setNameState] = useState('');
  const [phoneNoState, setPhoneNoState] = useState('');
  const [acceptedPolicyState, setAcceptedPolicyState] = useState(false);

  const [isInvalidEmailState, setIsInvalidEmailState] = useState(false);
  const [isInvalidPasswordState, setIsInvalidPasswordState] = useState(false);
  const [isUnmatchedConfirmPasswordState, setIsUnmatchedConfirmPasswordState] = useState(false);
  const [isEmptyNameState, setIsEmptyNameState] = useState(false);
  const [isEmptyPhoneNoState, setIsEmptyPhoneNoState] = useState(false);
  const [isRefusedPolicyState, setIsRefusedPolicyState] = useState(false);

  const [hasEnoughCharacterInPwState, setHasEnoughCharacterInPwState] = useState(false);
  const [hasEnoughCharacterTypeInPwState, setHasEnoughCharacterTypeInPwState] = useState(false);
  const [isVisiblePasswordState, setIsVisiblePassword] = useState(false);
  const [isVisibleConfirmPasswordState, setIsVisibleConfirmPassword] = useState(false);

  const [apiErrorCodeState, setApiErrorCodeState] = useState(0);
  const [shouldShowApiErrorState, setShouldShowApiErrorState] = useState(false);

  useEffect(() => {
    if(!apiError) return;
    setShouldShowApiErrorState(true);
    setApiErrorCodeState(apiError.code);
  }, [apiError]);

  const onChangeInputEmail = (e: any) => {
    const { value } = e.target;
    setEmailState(value);
    setShouldShowApiErrorState(false);
    if (checkValidField(FieldType.Email, value)) {
      setIsInvalidEmailState(false);
    }
  };

  const onBlurInputEmail = (e: any) => {
    const { value } = e.target;
    if (!checkValidField(FieldType.Email, value)) {
      setIsInvalidEmailState(true);
    }
  };

  const onChangeInputPassword = (e: any) => {
    const { value } = e.target;
    setPasswordState(value);
    setShouldShowApiErrorState(false);
    if (checkValidField(FieldType.Password, value)) {
      setIsInvalidPasswordState(false);
    }
    if (value && EmailService.hasEnoughCharacterInPassword(value)) {
      setHasEnoughCharacterInPwState(true);
    } else {
      setHasEnoughCharacterInPwState(false);
    }
    if (value && EmailService.hasEnoughCharacterTypeInPassword(value)) {
      setHasEnoughCharacterTypeInPwState(true);
    } else {
      setHasEnoughCharacterTypeInPwState(false);
    }
  };

  const onBlurInputPassword = (e: any) => {
    const { value } = e.target;
    if (!checkValidField(FieldType.Password, value)) {
      setIsInvalidPasswordState(true);
    }
    if (passwordState && confirmPasswordState) {
      !EmailService.isCorrectConfirmPassword(confirmPasswordState, passwordState) ? 
      setIsUnmatchedConfirmPasswordState(true) : 
      setIsUnmatchedConfirmPasswordState(false);
    } 
  };

  const onChangeInputConfirmPassword = (e: any) => {
    const { value } = e.target;
    setConfirmPasswordState(value);
    setShouldShowApiErrorState(false);
    if (checkValidField(FieldType.ConfirmPassword, value)) {
      setIsUnmatchedConfirmPasswordState(false);
    } else {
      setIsUnmatchedConfirmPasswordState(true);
    }
  };

  const onChangeInputName = (e: any) => {
    const { value } = e.target;
    setNameState(value);
    setShouldShowApiErrorState(false);
    if (checkValidField(FieldType.Name, value)) {
      setIsEmptyNameState(false);
    } 
  };

  const onBlurInputName = (e: any) => {
    const { value } = e.target;
    if (!checkValidField(FieldType.Name, value)) {
      setIsEmptyNameState(true);
    }
  };

  const onChangeInputPhoneNo = (e: any) => {
    const value = e.target.validity.valid ? e.target.value : phoneNoState; 
    setPhoneNoState(value);
    setShouldShowApiErrorState(false);
    if (checkValidField(FieldType.PhoneNo, value)) {
      setIsEmptyPhoneNoState(false);
    }
  };

  const onBlurInputPhoneNo = (e: any) => {
    const { value } = e.target;
    if (!checkValidField(FieldType.PhoneNo, value)) {
      setIsEmptyPhoneNoState(true);
    }
  };

  const onChangeAcceptPolicy = (e: any) => {
    const { checked } = e.target;
    setAcceptedPolicyState(checked);
    setShouldShowApiErrorState(false);
    if (checkValidField(FieldType.AcceptPolicy, checked)) {
      setIsRefusedPolicyState(false);
    }
  };

  const onChangeCreateGroup = (e: any) => {
    //TODO
  };

  const onRegister = () => {
    if (!validToRegister()) return;

    EmailService.dispatchRegister(dispatch, emailState, passwordState, nameState, phoneNoState);
  };

  const validToRegister = (): boolean => {
    return (
      checkValidField(FieldType.Email, emailState) &&
      checkValidField(FieldType.Password, passwordState) &&
      checkValidField(FieldType.ConfirmPassword ,confirmPasswordState) &&
      checkValidField(FieldType.Name, nameState) &&
      checkValidField(FieldType.PhoneNo, phoneNoState) &&
      checkValidField(FieldType.AcceptPolicy, acceptedPolicyState)
    );
  };

  const checkValidField = (type: FieldType, input: any): boolean => {
    switch (type) {
      case FieldType.Email:
        return !!(input && EmailService.validateEmailFormat(input));
      case FieldType.Password:
        return !!(input && EmailService.validatePasswordFormat(input));
      case FieldType.ConfirmPassword:
        return !!(input && EmailService.isCorrectConfirmPassword(input, passwordState));
      case FieldType.Name:
        return !!input.trim();
      case FieldType.PhoneNo:
        return !!(input && EmailService.validatePhoneNo(input));
      case FieldType.AcceptPolicy:
        return !!input;
      default:
        return true;
    }
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg) => {
    if (!ifError) return null;
    return (
      <div style={{color: 'red'}}>{errMsg}</div>
    );
  };

  const renderApiError = (shouldShow: boolean, code: number) => {
    if (!shouldShow) return null;
    return (
      <div style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };
  
  return (
    <Styles>
      <div className="form-title">
        Tạo tài khoản
      </div>
      <label>Email <span className="red-label">*</span></label>
      <input
        type="text"
        className="form-control"
        value={emailState}
        placeholder=""
        onChange={onChangeInputEmail}
        onBlur={onBlurInputEmail}
      />
      {renderInputError(isInvalidEmailState, InputErrorMsg.InvalidEmail)}
      <div className="position-relative">
        <label>Mật khẩu <span className="red-label">*</span></label>
        <input
          type={isVisiblePasswordState ? 'text' : 'password' }
          className="form-control"
          value={passwordState}
          placeholder=""
          onChange={onChangeInputPassword}
          onBlur={onBlurInputPassword}
        />
        {passwordState && <div className="show-password" onClick={() => {setIsVisiblePassword(!isVisiblePasswordState);}}>
          {isVisiblePasswordState ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <g> <path d="M10.003 15.858c5.524 0 9.338-4.463 9.338-5.858 0-1.401-3.821-5.858-9.338-5.858C4.555 4.142.66 8.599.66 10c0 1.395 3.89 5.858 9.344 5.858zm0-1.08c-4.504 0-8.148-3.814-8.148-4.778 0-.813 3.644-4.778 8.148-4.778 4.485 0 8.142 3.965 8.142 4.778 0 .964-3.657 4.778-8.142 4.778zm0-.95c2.126 0 3.835-1.743 3.835-3.828 0-2.14-1.709-3.828-3.835-3.828-2.14 0-3.855 1.688-3.848 3.828.013 2.085 1.709 3.828 3.848 3.828zm0-2.557c-.71 0-1.285-.574-1.285-1.271 0-.704.574-1.271 1.285-1.271.705 0 1.279.567 1.279 1.271 0 .697-.574 1.271-1.279 1.271z" transform="translate(-827 -368) translate(402 113) translate(20 222) translate(405 33)"/> </g> </g> </g> </g> </g> </svg>
          : 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <path d="M16.06 16.079c.219-.226.205-.533 0-.745L4.63 3.91c-.205-.205-.547-.205-.752 0-.198.198-.198.547 0 .745l11.437 11.423c.205.212.533.225.745 0zm-.164-1.873c2.16-1.402 3.445-3.24 3.445-4.047 0-1.401-3.814-5.858-9.338-5.858-1.189 0-2.27.205-3.274.54l.902.895c.752-.226 1.525-.355 2.372-.355 4.498 0 8.142 3.964 8.142 4.778 0 .554-1.196 2.044-3.09 3.206l.841.84zm-5.893 1.811c1.272 0 2.434-.225 3.494-.588l-.896-.895c-.813.253-1.675.403-2.598.403-4.504 0-8.148-3.814-8.148-4.778 0-.479 1.265-2.071 3.281-3.281L4.29 6.03C2.012 7.438.659 9.332.659 10.159c0 1.394 3.89 5.858 9.344 5.858zm3.507-4.361c.212-.458.328-.97.328-1.497 0-2.14-1.709-3.828-3.835-3.828-.54 0-1.045.116-1.503.314l5.01 5.011zm-3.507 2.331c.602 0 1.17-.15 1.675-.396L6.551 8.464c-.253.505-.396 1.08-.396 1.695.007 2.085 1.709 3.828 3.848 3.828z" transform="translate(-1090 -331) translate(555 298) translate(535 33)"/> </g> </g> </g> </g> </svg>
          }
        </div>}
      </div>
      <div className="verify-pass1">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> 
        <g fill={hasEnoughCharacterInPwState ? '#38cd52' : '#AEAEB2'} fillRule="nonzero"> <g> <g> 
        <path d="M8 17c4.376 0 8-3.631 8-8 0-4.376-3.631-8-8.008-8C3.624 1 0 4.624 0 9c0 4.369 3.631 8 8 8zm0-1.333c-3.702 0-6.659-2.965-6.659-6.667s2.95-6.667 6.651-6.667c3.702 0 6.667 2.965 6.675 6.667.008 3.702-2.965 6.667-6.667 6.667zm-.863-2.942c.26 0 .479-.125.636-.368l3.584-5.64c.086-.148.188-.329.188-.501 0-.345-.314-.573-.643-.573-.196 0-.392.118-.541.353l-3.255 5.216L5.56 9.22c-.188-.251-.361-.322-.58-.322-.338 0-.605.275-.605.627 0 .165.071.338.18.487l1.915 2.345c.196.259.407.368.666.368z" transform="translate(-555 -397) translate(555 298) translate(0 98)"/> </g> </g> </g> </g> </svg>
        Có độ dài tối thiểu 8 ký tự.</div>
      <div className="verify-pass2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> 
        <g fill={hasEnoughCharacterTypeInPwState ? '#38cd52' : '#AEAEB2'} fillRule="nonzero"> <g> <g> 
        <path d="M8 17c4.376 0 8-3.631 8-8 0-4.376-3.631-8-8.008-8C3.624 1 0 4.624 0 9c0 4.369 3.631 8 8 8zm0-1.333c-3.702 0-6.659-2.965-6.659-6.667s2.95-6.667 6.651-6.667c3.702 0 6.667 2.965 6.675 6.667.008 3.702-2.965 6.667-6.667 6.667zm-.863-2.942c.26 0 .479-.125.636-.368l3.584-5.64c.086-.148.188-.329.188-.501 0-.345-.314-.573-.643-.573-.196 0-.392.118-.541.353l-3.255 5.216L5.56 9.22c-.188-.251-.361-.322-.58-.322-.338 0-.605.275-.605.627 0 .165.071.338.18.487l1.915 2.345c.196.259.407.368.666.368z" transform="translate(-555 -397) translate(555 298) translate(0 98)"/> </g> </g> </g> </g> </svg>
        Bao gồm chữ in hoa, in thường và chữ số.
      </div>
      {renderInputError(isInvalidPasswordState, InputErrorMsg.InvalidPassword)}
      <div className="position-relative">
        <label>Nhập lại mật khẩu <span className="red-label">*</span></label>
        <input
          type={isVisibleConfirmPasswordState ? 'text' : 'password' }
          className="form-control"
          value={confirmPasswordState}
          placeholder=""
          onChange={onChangeInputConfirmPassword}
        />
        {confirmPasswordState && <div className="show-password" onClick={() => {setIsVisibleConfirmPassword(!isVisibleConfirmPasswordState);}}>
          {isVisibleConfirmPasswordState ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <g> <path d="M10.003 15.858c5.524 0 9.338-4.463 9.338-5.858 0-1.401-3.821-5.858-9.338-5.858C4.555 4.142.66 8.599.66 10c0 1.395 3.89 5.858 9.344 5.858zm0-1.08c-4.504 0-8.148-3.814-8.148-4.778 0-.813 3.644-4.778 8.148-4.778 4.485 0 8.142 3.965 8.142 4.778 0 .964-3.657 4.778-8.142 4.778zm0-.95c2.126 0 3.835-1.743 3.835-3.828 0-2.14-1.709-3.828-3.835-3.828-2.14 0-3.855 1.688-3.848 3.828.013 2.085 1.709 3.828 3.848 3.828zm0-2.557c-.71 0-1.285-.574-1.285-1.271 0-.704.574-1.271 1.285-1.271.705 0 1.279.567 1.279 1.271 0 .697-.574 1.271-1.279 1.271z" transform="translate(-827 -368) translate(402 113) translate(20 222) translate(405 33)"/> </g> </g> </g> </g> </g> </svg>
          : 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <path d="M16.06 16.079c.219-.226.205-.533 0-.745L4.63 3.91c-.205-.205-.547-.205-.752 0-.198.198-.198.547 0 .745l11.437 11.423c.205.212.533.225.745 0zm-.164-1.873c2.16-1.402 3.445-3.24 3.445-4.047 0-1.401-3.814-5.858-9.338-5.858-1.189 0-2.27.205-3.274.54l.902.895c.752-.226 1.525-.355 2.372-.355 4.498 0 8.142 3.964 8.142 4.778 0 .554-1.196 2.044-3.09 3.206l.841.84zm-5.893 1.811c1.272 0 2.434-.225 3.494-.588l-.896-.895c-.813.253-1.675.403-2.598.403-4.504 0-8.148-3.814-8.148-4.778 0-.479 1.265-2.071 3.281-3.281L4.29 6.03C2.012 7.438.659 9.332.659 10.159c0 1.394 3.89 5.858 9.344 5.858zm3.507-4.361c.212-.458.328-.97.328-1.497 0-2.14-1.709-3.828-3.835-3.828-.54 0-1.045.116-1.503.314l5.01 5.011zm-3.507 2.331c.602 0 1.17-.15 1.675-.396L6.551 8.464c-.253.505-.396 1.08-.396 1.695.007 2.085 1.709 3.828 3.848 3.828z" transform="translate(-1090 -331) translate(555 298) translate(535 33)"/> </g> </g> </g> </g> </svg>
          }
        </div>}
      </div>
      {renderInputError(isUnmatchedConfirmPasswordState, InputErrorMsg.UnmatchedConfirmPassword)}
      <div className="row">
        <div className="col-lg-6">
          <label>Họ và Tên <span className="label-required"> *</span></label>
          <input type="text" name="fullname" className="form-control" value={nameState} onChange={onChangeInputName} onBlur={onBlurInputName}/>
          {renderInputError(isEmptyNameState, InputErrorMsg.EmptyName)}
        </div>
        <div className="col-lg-6">
          <label>Số điện thoại <span className="label-required"> *</span></label>
          <input type="text" pattern="[0-9]*" name="phone_no" className="form-control" value={phoneNoState} onChange={onChangeInputPhoneNo} onBlur={onBlurInputPhoneNo}/>
          {renderInputError(isEmptyPhoneNoState, InputErrorMsg.InvalidPhoneNo)}
        </div>
      </div>
      <div className="form-check">
        {/*<div>
          <input type="checkbox" className="form-check-input" id="create-group" onChange={onChangeCreateGroup}/>
          <label className="form-check-label" htmlFor="create-group">Tôi muốn tạo đội nhóm</label>
        </div>*/}
        <div>
          <input type="checkbox" className="form-check-input" id="accept-policy" onChange={onChangeAcceptPolicy}/>
          <label className="form-check-label" htmlFor="accept-policy">Tôi đã đọc và chấp nhận <a target="_blank" href="https://docs.google.com/document/d/1VNvGDRJc3qeIhNsnE8nZOu-gdgHMq8uqpO3wf_J-XnY/edit" rel="noreferrer">Điều khoản sử dụng</a></label>
        </div>
      </div>
      {renderInputError(isRefusedPolicyState, InputErrorMsg.RefusePolicy)}
      {renderApiError(shouldShowApiErrorState, apiErrorCodeState)}
      <button 
        className={validToRegister() ? 'btn-default' : 'ml-0 btn-disabled' } 
        disabled={!validToRegister()} 
        onClick={onRegister}
      >Đăng ký</button>
      <div className="login-footer">
        Bạn đã có tài khoản?  <Link to={(globalThis as any).useHistory.url('/login')}>Đăng nhập ngay</Link>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .form-title {
    font-family: Propins-Medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  h1 {
    font-family: Propins-Bold;
    font-size: 28px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.32px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 46px;
  }
  label span.red-label {
    color: #ff3b30;
  }
  .verify-pass1, .verify-pass2 {
    margin-top: 10px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeb2;
    align-items: center;
    display: flex;
  }
  .verify-pass1 svg, .verify-pass2 svg {
    margin-right: 8px;
  }
  .show-password {
    cursor: pointer;
    position: absolute;
    right: 15px;
    bottom: 12px;
  }
`;

export default withCookies(RegisterByEmail);