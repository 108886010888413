"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sampleHook = function (message) {
    var handleClick = function () { return new Promise(function (resolve, reject) {
        var timer = setTimeout(function () {
            clearTimeout(timer);
            resolve(message + 'blah');
        }, 300);
    }); };
    return [handleClick];
};
