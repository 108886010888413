"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignForecastService = void 0;
const actions_1 = require("../../actions");
const CampaignForecast_1 = require("../types/CampaignForecast");
class CampaignForecastService {
    static get(params) {
        const apiAction = new actions_1.ApiAction(CampaignForecast_1.GoogleAdsCampaignForecastTypes.type, actions_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
}
exports.CampaignForecastService = CampaignForecastService;
