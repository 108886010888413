import ConfirmButton from 'components/buttons/ConfirmButton';
import { User, logout, SelectedTeam, LicenseInfor } from 'core';
import { useCookie } from 'pages/auth/hooks';
import React, { useState } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AccountInformationModal from './modals/AccountInformationModal';
import { plainToClass } from 'class-transformer';
import { History } from 'src/route/Route';

interface PropsType {
  cookies: Cookies;
}

const AccountPanel: React.FC<PropsType> = (props) => {
  const { cookies } = props;
  const dispatch = useDispatch();
  const history = new History();
  const user = useSelector((state) => plainToClass(User, state.user.user));
  const team: SelectedTeam = useSelector((state: any) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const licenseInfor: LicenseInfor = useSelector((state: any) => state.team.licenseInfor);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenAccountInformationState, setIsOpenAccountInformationState] = useState(false);
  const { removeAllCookies } = useCookie(cookies);

  const logoutClick = (): void => {
    removeAllCookies();
    dispatch(logout());
  };

  const openAccountInformationModal = () => {
    setIsOpenAccountInformationState(true);
  };

  const goToAccountAdsManagement = () => {
    history.push('/account-ads-management');
  };

  const goToPackageInfo = () => {
    history.push('/payment/package-info');
  };

  return (
    <Styles>
      <AccountInformationModal isOpen={isOpenAccountInformationState} closeModal={() => {setIsOpenAccountInformationState(false);}} />
      <div className="account-panel">
        {isPopupOpen && (<>
          <div className="account-popup">
            <div className="account-popup__information">
              <div className="account-popup__name">
                {user.getUserName()}
                {licenseInfor && licenseInfor.id != null 
                ? 
                <span className="account-type pro">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> 
                    <g fill="none" fillRule="evenodd"> <g fill="#FFF" fillRule="nonzero"> <g> <g> <g> <path d="M11.875 9.57l.74-3.016.046.002c.243 0 .466-.06.67-.18.203-.12.365-.28.487-.481.121-.202.182-.426.182-.673 0-.243-.06-.465-.18-.666-.12-.202-.28-.362-.484-.482-.203-.12-.428-.18-.675-.18-.243 0-.464.06-.664.18s-.359.281-.478.484c-.12.204-.18.425-.18.664 0 .198.038.38.115.55l.02.04-1.582 1.142c-.156.11-.283.092-.382-.057L7.648 4.12l.022-.01c.2-.12.361-.28.481-.482.12-.202.18-.426.18-.673 0-.239-.06-.46-.18-.663-.12-.204-.28-.365-.481-.485-.202-.12-.424-.18-.667-.18-.247 0-.471.06-.673.18-.2.12-.361.28-.481.482-.12.201-.18.423-.18.666 0 .247.061.471.183.673.121.201.283.362.484.481l.018.01-1.864 2.79c-.046.065-.096.104-.151.118-.055.013-.126-.01-.214-.072L2.531 5.801l.015-.03c.077-.168.115-.351.115-.549 0-.24-.06-.46-.18-.664-.12-.203-.28-.364-.481-.484-.201-.12-.424-.18-.667-.18-.247 0-.47.06-.672.18-.201.12-.362.28-.482.482-.12.2-.179.423-.179.666 0 .247.06.471.182.673.122.2.282.361.482.481s.422.18.67.18l.057-.003.746 3.017h9.738zm-1.96 2.803c.452 0 .803-.11 1.054-.33.25-.22.429-.547.535-.98l.171-.684H2.33l.166.684c.106.433.285.76.535.98.251.22.604.33 1.06.33h5.824z" transform="translate(-1199 -50) translate(1066 37) translate(128 11) translate(5 2)"/> </g> </g> </g> </g> </g> 
                  </svg>{licenseInfor?.package?.code}
                </span> 
                  : <span className="account-type">FREE</span>}
                {licenseInfor && licenseInfor.id != null && <div className="account-popup__view-package" onClick={goToPackageInfo}>Xem thông tin gói</div>}
              </div>
              <div>
                {user.getUserEmail()}
              </div>
              <div>
                Id: {user.getUserMasterId()}
              </div>
              <div className="account-popup__upgrade-user">
                Nâng cấp tài khoản để không bị giới hạn nhận thông tin khách hàng mới.
                <Link to={(globalThis as any).useHistory.generateComponent('PricingPage')} className="btn btn-default">Xem gói nâng cấp</Link>
              </div>
            </div>
            <div className="account-popup__management">
              <div className="account-popup__management--link" onClick={openAccountInformationModal}>Thông tin tài khoản</div>
              <div className="account-popup__management--link" onClick={goToAccountAdsManagement}>Quản lý tài khoản QC</div>
              <div className="account-popup__management--link">Đổi mật khẩu</div>
            </div>
            <ConfirmButton
              customButtonStyle={'button-logout account-popup__management--link'}
              onClick={logoutClick}
              comfirmMessage={
                <div className="logout-modal-container">
                  <h3 className="text-center">Thông báo</h3>
                  <p className="pt-4 pb-3">Bạn có chắc chắn muốn đăng xuất?</p>
                </div>
              }
            >
              Đăng xuất
            </ConfirmButton>
          </div>
          <div className="popup-bg" onClick={() => {
            setIsPopupOpen((prevState) => !prevState);
          }}></div>
          </>
        )}
        <button
          className="avatar-menu"
          onClick={() => {
            setIsPopupOpen((prevState) => !prevState);
          }}
        >
          <img
            className="avatar-img"
            width="24"
            height="24"
            src={user.getAvatarUrl()}
            alt="avatar"
          />
        </button>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .account-panel {
    position: relative;
  }
  .btn-default {
    display: inline-block;
    font-size: 13px;
    margin-top: 10px;
    padding: 6px 12px;
  }
  .popup-bg {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
  .account-popup {
    position: absolute;
    top: 100%;
    width: 242px;
    right: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
    z-index: 999;
  }
  .account-popup__upgrade-user {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgba(216, 216, 216, 0.5);
  }
  .account-popup__name {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
  }
  .account-popup__information {
    padding: 10px;
    font-family: Propins-Regular;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    color: #666666;
  }
  .account-popup svg {
    margin-right: 5px;
  }
  .account-type {
    background-color: #aeaeb2;
    margin-top: 2px;
    margin-left: 5px;
    display: inline-block;
    padding: 2px 6px;
    text-transform: uppercase;
    font-family: Propins-Medium;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.71px;
    color: #ffffff;
    border-radius: 4px;
    border: solid 1px #ffffff;
  }
  .account-type.pro {
    display: inline-flex;
    background-image: linear-gradient(to left, #ea4c89, #e47b49 0%);
  }
  .account-id {
    height: 16px;
    font-family: Propins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: rgba(255, 255, 255, 0.8);
    flex: 1 0 100%;
    text-align: left;
  }
  .avatar-menu {
    display: flex;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    align-items: center;
  }
  .avatar-menu:focus {
    outline: none;
  }
  .avatar-img {
    border-radius: 100%;
    overflow: hidden;
    width: 24px;
    height: 24px;
    // border: solid 0.5px #d8d8d8;
    min-width: 24px;
  }
  .account-popup__view-package {
    cursor: pointer;
    font-family: Propins-Regular;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    color: #4c7cf3;
    margin-bottom: 8px;
    margin-top: 4px;
  }
  .account-popup__management {
    border-top: solid 1px rgba(216, 216, 216, 0.5);
    border-bottom: solid 1px rgba(216, 216, 216, 0.5);
  }
  .account-popup__management--link {
    text-align: left;
    cursor: pointer;
    padding: 5px 10px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000;
  }
  .account-popup__management--link.gray-color {
    border-top: solid 1px rgba(216, 216, 216, 0.5);
    color: #666666;
  }
  .account-popup__management--link:hover {
    overflow: hidden;
    background-color: #d8d8d8;
  }
`;

const AccountPanelWithCookies = withCookies(AccountPanel);

export default AccountPanelWithCookies;
