import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppState, CustomerState, TeamPermissionService  } from 'core';
import { useSelector } from 'react-redux';
interface PropsType {
}

export default function UpgradePackageBanner(props: PropsType): React.ReactElement | null {

  const customers = useSelector<any, CustomerState>((state: AppState) => state.customers);
  if (customers.totalRemaining === 0) {
    return null;
  }

  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();

  return (
    <tr className="banner-row">
      <td colSpan={10}>
        <div className="banner-wrapper">
          <h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g fill="none" fillRule="evenodd"> <g fill="#FFF"> <g> <g> <g> <g> <path d="M20.357 16.405l1.27-5.17.078.003c.416 0 .799-.102 1.147-.308.349-.205.627-.48.835-.825.209-.345.313-.73.313-1.153 0-.416-.103-.797-.308-1.142-.205-.346-.482-.62-.83-.826-.348-.205-.734-.308-1.157-.308-.417 0-.797.103-1.138.308-.342.205-.616.482-.821.83-.205.349-.308.728-.308 1.138 0 .339.066.653.197.941l.036.071-2.714 1.958c-.267.189-.485.156-.654-.098l-3.191-4.763.036-.018c.345-.205.62-.48.825-.826.205-.345.308-.729.308-1.152 0-.41-.103-.79-.308-1.138-.205-.349-.48-.625-.825-.83-.345-.206-.726-.308-1.143-.308-.423 0-.808.102-1.153.307-.345.206-.62.48-.825.826-.205.345-.308.726-.308 1.143 0 .423.104.807.313 1.152.208.345.485.62.83.826l.03.017-3.195 4.784c-.078.11-.164.177-.259.2-.094.023-.216-.018-.366-.122L4.338 9.944l.027-.05c.131-.29.197-.603.197-.942 0-.41-.103-.79-.308-1.138-.205-.348-.48-.625-.825-.83-.346-.205-.727-.308-1.143-.308-.424 0-.808.103-1.153.308-.345.205-.62.48-.825.826C.103 8.155 0 8.536 0 8.952c0 .424.104.808.313 1.153.208.345.483.62.825.825.342.206.724.308 1.148.308l.1-.005 1.277 5.172h16.694zm-3.36 4.806c.774 0 1.377-.189 1.806-.566.43-.378.736-.938.919-1.68l.293-1.173H3.995l.283 1.173c.183.742.489 1.302.919 1.68.43.377 1.035.566 1.816.566h9.983z" transform="translate(-870 -836) translate(266 197) translate(458 638) translate(146) translate(0 1)"/> </g> </g> </g> </g> </g> </g> </svg>
            &nbsp;Nâng cấp tài khoản
          </h3>
          <p>
            Bạn đang sử dụng gói {teamPermissionService.DisplayPackageName} - chỉ xem được tối đa <span>{teamPermissionService.MaxLeadPerMonth}</span> khách/tháng.
            Để xem được nhiều khách hơn, vui lòng nâng cấp tài khoản.
          </p>
          <Link className="btn-upgrade" to={(globalThis as any).useHistory.generateComponent('PricingPage')}>Nâng cấp ngay</Link>
        </div>
      </td>
    </tr>
  );
}
