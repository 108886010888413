import {GoogleAdsEnum, CampaignTypes, ICampaignData, UserPublishedLandingPagesData, KeywordData, KeywordGroup, KeywordInfo} from 'core';
import vest, { test, enforce } from 'vest';
import { AdGroupAdExtends, TemplateAdsFormState } from './TemplateAdsForm';
import * as moment from 'moment';
import Utils from 'utils/string';
import { plainToClass } from 'class-transformer';

export type FormData = {
  account_id: number;
  CampaignName: string;
  LandingPageId: number;
  CampaignType: CampaignTypes;
}

/* 
* Usage: For more criteria pls check List of Enforce rules
* https://ealush.com/vest/#/./n4s/rules 
*/
export const initGoogleCampaignSchema = vest.create((data = FormData)=>{
  test('CampaignName', 'Tên chiến dịch không được bỏ trống', ()=>{
    enforce(data.CampaignName).isNotEmpty();
  });

  test('CampaignName', 'Tên chiến dịch không hợp lệ', ()=>{
    enforce(data.CampaignName).shorterThan(256);
    enforce(data.CampaignName).isNotNumeric();
  });

  test('CampaignName', 'Tên chiến dịch phải nhiều hơn 3 kí tự', () => {
    enforce(data.CampaignName).longerThan(3);
  });

  test('LandingPageId', 'Bạn phải chọn một trang Landing Page để bắt đầu', () => {
    enforce(data.LandingPageId).isNotEmpty();
  });

  test('CampaignType', 'Hiện tại chỉ cho phép tạo Search Campaign', () => {
    enforce(data.CampaignType).equals(CampaignTypes.Search);
  });
  
  test('account_id', 'Bạn phải chọn một tài khoản quảng cáo để tiếp tục', () => {
    enforce(data.account_id).isNotEmpty();
  });
  
});

export const initGoogleCampaignEditSchema = vest.create((data = FormData)=>{
  test('LandingPageId', 'Bạn phải chọn một trang Landing Page để bắt đầu', () => {
    enforce(data.LandingPageId).isNotEmpty();
  });

  test('CampaignType', 'Hiện tại chỉ cho phép tạo Search Campaign', () => {
    enforce(data.CampaignType).equals(CampaignTypes.Search);
  });
  
  test('account_id', 'Bạn phải chọn một tài khoản quảng cáo để tiếp tục', () => {
    enforce(data.account_id).isNotEmpty();
  });
  
});

const commonSchema = (data: ICampaignData) => {
  test('name', 'Tên chiến dịch không được bỏ trống', ()=>{
    enforce(data.name).isNotEmpty();
  });

  test('name', 'Tên chiến dịch không hợp lệ', ()=>{
    enforce(data.name).shorterThan(256);
    enforce(data.name).isNotNumeric();
  });

  test('name', 'Tên chiến dịch phải nhiều hơn 3 kí tự', () => {
    enforce(data.name).longerThan(3);
  });

  test('account_id', 'Không tìm thấy Tài khoản quảng cáo', ()=>{
    enforce(data.account_id).isNotEmpty();
  });

  test('landing_page_id', 'Không tìm thấy Trang quảng cáo', ()=>{
    enforce(data.landing_page_id).isNotEmpty();
  });

  test('campaign_type', 'Hiện tại chỉ cho phép tạo Search Campaign', () => {   
    enforce(data.campaign_type).equals(CampaignTypes.Search);
  });

  test('start_date', 'Cần chọn một ngày bắt đầu quảng cáo!', ()=>{
    enforce(data.start_date).isTruthy();
  });



  if (data.start_date && data.end_date) {
    test('start_date', 'Ngày bắt đầu không được sau ngày kết thúc', () => {
      const a = data.end_date ? moment(data.start_date).isAfter(data.end_date, 'day') : false;
      enforce(a).isFalsy();
    });

    test('end_date', 'Ngày kết thúc không được trước ngày bắt đầu', ()=>{
      const a = data.end_date ? moment(data.start_date).isAfter(data.end_date, 'day') : false;
      enforce(a).isFalsy();
    });
  }

  test('start_date', 'Ngày bắt đầu không được sau ngày giới hạn (30/12/2037)', ()=>{
    const a = data.start_date ? new Date(data.start_date) > new Date('2037-12-30') : false;
    enforce(a).isFalsy();
  });

  test('end_date', 'Ngày kết thúc không được sau ngày giới hạn (30/12/2037)', ()=>{
    const a = data.end_date ? new Date(data.end_date) > new Date('2037-12-30') : false;
    enforce(a).isFalsy();
  });


  test('daily_schedules', 'Hãy chọn lịch cho nhóm quảng cáo', ()=>{
    //TODO: update this
    // const check = importBatch(data.daily_schedules);
    
  //   enforce(data.daily_schedules).lengthNotEquals(0);
  //   enforce(data.daily_schedules).isNotEmpty();
  });

  // languages?: Array<ILanguages>;
  test('languages', 'Cần chọn ít nhất một ngôn ngữ để thiết lập quảng cáo', ()=>{
    enforce(data.languages).lengthNotEquals(0);
    enforce(data.languages).isNotEmpty();
  });
  // locations?: Array<ILocation>;
  test('locations', 'Cần chọn ít nhất một vị trí để thiết lập quảng cáo', ()=>{
    enforce(data.locations).lengthNotEquals(0);
    enforce(data.locations).isNotEmpty();
  });
  // bidding_strategy: IBiddingStrategy;
  // campaign_budget: ICampaignBudget;

  test('campaign_budget.amount', 'Cần nhập một ngân sách cho chiến dịch', ()=>{
    enforce(data.campaign_budget.amount).isNotEmpty();
  });
  
  test('campaign_budget.amount', 'Ngân sách phải là số', ()=>{
    enforce(data.campaign_budget.amount).isNumeric();
  });

  test('campaign_budget.amount', 'Ngân sách phải lớn hơn 0', () => {
    enforce(data.campaign_budget.amount).greaterThan(0);
    enforce(data.campaign_budget.amount).isNotEmpty();
  });

  test('campaign_budget.amount', 'Nhập một ngân sách nhỏ hơn', () => {
    enforce(data.campaign_budget.amount).lessThan(1000000000);
  });

  if (data.maximize_conversion_value?.target_roas) {
    test('maximize_conversion_value.target_roas', 'Lợi tức mục tiêu trên chi tiêu quảng cáo phải là số', ()=>{
      enforce(data.maximize_conversion_value?.target_roas).isNumeric();
    });
  
    test('maximize_conversion_value.target_roas', 'Lợi tức mục tiêu trên chi tiêu quảng cáo phải lớn hơn 0 và nhỏ hơn 100', ()=>{
      enforce(data.maximize_conversion_value?.target_roas).greaterThan(0);
      enforce(data.maximize_conversion_value?.target_roas).lessThanOrEquals(100);
    });
  }

  if(data.bidding_strategy_type === GoogleAdsEnum.BiddingStrategyType.TARGET_SPEND && data.bidding_strategy) {
    test('bidding_strategy.max_cpc', 'Cần nhập một mức giá thầu tối đa', ()=>{
      enforce(data.bidding_strategy?.max_cpc).isNotEmpty();
    });

    test('bidding_strategy.max_cpc', 'Giá thầu tối đa phải là số', ()=>{
      enforce(data.bidding_strategy?.max_cpc).isNumeric();
    });
    if (data.campaign_budget.amount) {
      test('bidding_strategy.max_cpc', 'Giá thầu tối đa phải nhỏ hơn ngân sách hàng ngày', ()=>{
        enforce(data.bidding_strategy?.max_cpc).lessThanOrEquals(data.campaign_budget.amount ? data.campaign_budget.amount : 0);
      });
    }
  }
  
  test('ages', 'Cần chọn ít nhất một nhóm tuổi để thiết lập quảng cáo', ()=>{
    enforce(data.ages).lengthNotEquals(0);
    enforce(data.ages).isNotEmpty();
  });

  test('devices', 'Cần chọn ít nhất một thiết bị để thiết lập quảng cáo', ()=>{
    enforce(data.devices).lengthNotEquals(0);
    enforce(data.devices).isNotEmpty();
  });

  // Update 2/6: Sitelink is an optional and the list can be empty,
  // but restricts to maximum 6 items
  const len = data.site_link?.sitelinks && data.site_link.sitelinks.length;
  test('site_link', 'Hãy chọn ít nhất 2 (tối đa 6) tiện ích liên kết trang web', ()=>{
    vest.warn();
    enforce(len).gt(1);
  });
  
  if(len && len > 0)
  test('site_link', SPECS.commonSetting.site_link.message, ()=>{
    enforce(len).lte(SPECS.commonSetting.site_link.value);
  });
  
  //Update the req: sitelinks are no need descriptions as mandantory fields
  test('site_link', 'Hãy điền đầy đủ thông tin tiện ích liên kết trang web', ()=>{
    const missingDatas = !data.site_link ? false : data.site_link.sitelinks.some(x => {   
      return x.title.trim().length === 0 || 
      // x.description1.trim().length === 0 ||
      // x.description2.trim().length === 0 ||
      x.url.trim().length === 0;
    });

    enforce(missingDatas).isFalsy();
  });
  // test('call', 'Bạn chưa nhập đúng định dạng số điện thoại', ()=>{
  //   const reg = /((0|\+84)+(3|5|7|8|9)+([0-9]{8,9})\b)/g;
  //   enforce(data.call.calls).matches(reg);
  // });
};

export const createGoogleCampaignSchema = vest.create((data: ICampaignData)=>{
  commonSchema(data);

  test('start_date', 'Ngày bắt đầu không được là ngày trong quá khứ', ()=>{
    const a = moment(data.start_date).isBefore(moment(), 'day');
    enforce(a).isFalsy();
  });
});

export const editGoogleCampaignSchema = vest.create((data: ICampaignData)=>{
  commonSchema(data);
});

export const keywordAdSchema = vest.create((data: KeywordData) => {
  try {    
    const keywordGroups = (data.keyword_groups) ? data.keyword_groups : [];

    const allKeywords = (keywordGroups.length > 0) ? [...keywordGroups.filter(g => g.keywords).map(g => g.keywords).flat()].filter(e => e) : [];

    if (allKeywords.length === 0) {
      test('keyword_groups', 'Cần nhập ít nhất một từ khoá để thiết lập quảng cáo. Nên bắt đầu với 2 bộ từ khoá trở lên.', ()=>{
        enforce(false).isTruthy();
      });
    }

    keywordGroups.forEach((ele: KeywordGroup, index: number) => {
      if (!ele.name || ele.name === '') {
        test(`keyword_groups.${index}.name`, 'Không được để trống tên bộ từ khoá', ()=>{
          enforce(false).isTruthy();
        });
      }
    });

    const specialChar = ', ! @ % ^ ( ) = { } ; ~ ` < > ? \\ |'.split(' ');
    
    // These indices are according to order on the UI
    const matchTypeIndex = {
      [GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]: 0,
      [GoogleAdsEnum.KeywordMatchType.BROAD]: 1,
      [GoogleAdsEnum.KeywordMatchType.PHRASE]: 2,
      [GoogleAdsEnum.KeywordMatchType.EXACT]: 3,
    };
    keywordGroups.forEach((ele: KeywordGroup, index: number) => {
      ele = plainToClass(KeywordGroup, ele);
      const matchTypeGroups = ele.splitIntoMatchTypeGroup();
      for (const group in matchTypeGroups) {    
        const keywordGroup: KeywordInfo[] = matchTypeGroups[group]; 
        if (keywordGroup.length === 0) continue;
        const keywords: string[] = [];
        keywordGroup && keywordGroup.length > 0 && keywordGroup.forEach(e => e && e.text && keywords.push(e.text));    
        test('keyword_groups.' + index + '.keywords.' + matchTypeIndex[group], 'Không sử dụng các ký tự đặc biệt , ! @ % ^ ( ) = { } ; ~ ` < > ? \\ | trong từ khóa quảng cáo', ()=>{
          enforce(keywords.every(value => specialChar.every(char => !value.includes(char)))).isTruthy();
        });
        test('keyword_groups.' + index + '.keywords.' + matchTypeIndex[group], 'Độ dài tối đa của từ khoá là 80 ký tự', () => {
          enforce(keywords.some(value => value && value.length > 80)).isFalsy();
        });
        test('keyword_groups.' + index + '.keywords.' + matchTypeIndex[group], 'Một từ khoá không được dài hơn 10 từ', () => {
          enforce(keywords.some(value => value && value.split(' ').length > 10)).isFalsy();
        });
      }
  });

  const negativeKeywords: string[] = [];
  data.negative_keywords && data.negative_keywords.length > 0 && data.negative_keywords.forEach(e => e && e.text && negativeKeywords.push(e.text));
  test('negative_keywords', 'Không sử dụng các ký tự đặc biệt , ! @ % ^ ( ) = { } ; ~ ` < > ? \\ | trong từ khóa phủ định', ()=>{
    enforce(negativeKeywords.every(value => specialChar.every(char => !value.includes(char)))).isTruthy();
  });

  if ( keywordGroups.length > 1) {
    const len = keywordGroups.length;
    for ( let i = 0; i < len - 1; i++) {
      for ( let j = i + 1; j < len; j++ ) {
        if ( keywordGroups[i].name === keywordGroups[j].name) {
          test(`keyword_groups.${j}.name`, 'Đã tồn tại bộ từ khoá trùng tên. Hãy sử dụng tên khác cho bộ từ khoá này.', ()=>{
            enforce(false).isTruthy();
          });
          i = len - 1;
          j = len;
        }
      }
    }
  }
} catch (err) {
  // Please don't delete this to prevent stuck in validation
  console.error(err);
}

});

export const CampaignExtensionCallSchema = vest.create((data)=>{
  const isCreateCampaign = data.calls !== undefined;
  
  if(isCreateCampaign){
    test('call', 'Bạn chưa nhập đúng định dạng số điện thoại', ()=>{
      const reg = /((0|\+84)+(3|5|7|8|9)+([0-9]{8})\b)/g;
      enforce(data.call).matches(reg);
    });
    
    const dataFill = data.call.filter((el: any)=>el.title);
      
  } else {
    test('call', 'Bạn chưa nhập đúng định dạng số điện thoại', ()=>{
      const reg = /((0|\+84)+(3|5|7|8|9)+([0-9]{8})\b)/g;
      enforce(data.call).matches(reg);
    });
  }
});

// For more detail please visit
// https://confluence.mmj.ne.jp/pages/viewpage.action?spaceKey=REM&title=Google+Ads+creation+simplified+UI

export const SPECS = {
  commonSetting: {
    site_link: {
      value: 6,
      message: 'Hãy chọn tối đa 6 tiện ích liên kết trang web'
    }
  },
  common: {
    specialHeadline: 'Không sử dụng các ký tự đặc biệt (dấu chấm than, chữ @, dấu ", dấu hoa thị, gạch đầu dòng và dấu ba chấm) trong tiêu đề quảng cáo',
    twomoreSpecial: 'Không sử dụng dấu chấm than hoặc dấu chấm hỏi từ 2 lần trở lên trong văn bản quảng cáo ',
    symbolMoreThanOnce: 'Không sử dụng số, ký hiệu hoặc dấu câu từ 2 lần trở lên trong 1 từ',
    numberOverused: 'Không sử dụng số, ký hiệu hoặc dấu câu từ 2 lần trở lên trong 1 từ',
    capitalized: 'Không được viết hoa toàn bộ văn bản quảng cáo',
    emoji: 'Không sử dụng biểu tượng cảm xúc trong văn bản quảng cáo',
    extraSpaces: 'Không được thừa dấu cách',
    lackSpaces: 'Không được thiếu hoặc thừa dấu cách',
    phoneNo: 'Không sử dụng số điện thoại trong văn bản quảng cáo, thay vào đó, hãy thêm Tiện ích mở rộng cuộc gọi',
    projectName: 'Không lặp lại tên dự án từ 2 lần trở lên trong văn bản quảng cáo',
    duplicate: 'Giá trị bị trùng lặp'
  },
  [GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD]: {
    minDesc: {
      value: 2, message: 'Cần tối thiểu {d} mô tả cho loại quảng cáo này'
    },
    maxDesc: {
      value: 4, message: 'Cần tối đa {d} mô tả cho loại quảng cáo này'
    },
    defaultDesc: {
      value: 2, message: ''
    },
    minHeadline: {
      value: 3, message: 'Cần tối thiểu {d} tiêu đề cho loại quảng cáo này'
    },
    maxHeadline: {
      value: 15, message: 'Cần tối đa {d} tiêu đề cho loại quảng cáo này'
    },
    defaultHeadline: {
      value: 7, message: ''
    },
    maxLengthDesc: {
      value: 90, message: 'Độ dài tối đa {d} ký tự'
    },
    maxLengthHeadline: {
      value: 30, message: 'Độ dài tối đa {d} ký tự'
    },
  },
  [GoogleAdsEnum.AdType.EXPANDED_TEXT_AD]: {
    minHeadline: {
      value: 2, message: 'Cần tối thiểu {d} tiêu đề cho loại quảng cáo này'
    },
    maxHeadline: {
      value: 3, message: 'Cần tối đa {d} tiêu đề cho loại quảng cáo này'
    },
    defaultHeadline: {
      value: 3, message: ''
    },
    minDesc: {
      value: 1, message: 'Cần tối thiểu {d} mô tả cho loại quảng cáo này'
    },
    maxDesc: {
      value: 2, message: 'Cần tối đa {d} mô tả cho loại quảng cáo này'
    },
    defaultDesc: {
      value: 2, message: ''
    },
    maxLengthDesc: {
      value: 90, message: 'Độ dài tối đa {d} ký tự'
    },
    maxLengthHeadline: {
      value: 30, message: 'Độ dài tối đa {d} ký tự'
    },
  },
  keywordGroup: {
    maxLengthWord: { value: 10, message: 'Một từ khoá không được dài hơn 10 từ'},
    // maxLengthItem: { value: 100, message: 'Một nhóm không được dài hơn 100 từ khóa'},
    maxLengthChars: { value: 80, message: 'Độ dài tối da của từ khóa là 80 ký tự'}
  }
};

interface ErrorIDs {idError: number; property: string; isHeadline: boolean}
interface DupEl{duplicateId: number; isHeadline: boolean }
interface CombineArr{
  id: number;
  text: string;
  isHeadline: boolean;
}

export const findAllInArray = (headlineArr: string[], descArr: string[], lp: {pname: string}): {errorIds: ErrorIDs[]} => {
  const combineArr: CombineArr[] = [
    ...(headlineArr?.map((el: string, id: number)=>({text: el, id, isHeadline: true} as CombineArr)) || [] as CombineArr[]), 
    ...(descArr?.map((el: string, id: number)=>({text: el, id, isHeadline: false} as CombineArr)) || [] as CombineArr[])
  ];
  // console.log(combineArr);
  if(!combineArr.length) return {errorIds: []};
  const common = {
    phoneNo: (phrase: string)=>{
      const getDigits = phrase.match(/\d{1,}/g)?.join('');
      return getDigits ? /((0|\+84)+(3|5|7|8|9)+([0-9]{8,9})\b)/g.test(getDigits) : false;
    },
    twomoreSpecial: /(!.*?!)|(\?.*?\?)/,
    // symbolMoreThanOnce: (input: string)=>{
    //   const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      
    //   const reg = '.,:;–-()[]&+×÷=';
    //   const regex = new RegExp(reg.split('').map(el=>'(' + escapeRegExp(el) + '.*?' + escapeRegExp(el) + ')').join('|'));
    //   return regex.test(input);
    // },
    // symbolMoreThanTwice: (phrase: string)=>{
    //   const getSymbol = '”"\'`'.split('').some(el=>(phrase.match(new RegExp(`(${el}).*?`, 'g')) || []).length > 2);
    //   return getSymbol;
    // },
    // numberOverused: /\d{2,}/, //please check number overused after showing the phone error message :D We need to warning people using Sitelink and Call
    
    // test: ( check url )
    // https://www.typescriptlang.org/play?#code/G4QwTgBAzgngtgIwPYBsCySwFMAqALEAOwHlCBjLCAXggAoBLQgBwFcAXALmjbEYHMAlFQB8AbwBQEKdLJJCUNhDbg+WTt16E+AbQC61CI1ZsAdFCYp6bWgHIINgQG5J0qbPmKsUMiCZYASlh8AKIAHkwGtApgXNH8AtTCGibYFiAUtAD02iYAVADUAPwAegAkogC+tAIAPtoAOrr1jZl8ADT2zaUAZA7Orm5yChBkeFhkANYGymCqplBIcFi0THhgIFBYIhIDMkOK2HwGNgDEpQACAKTF3bnVALQA+vlUAIQABgA8woVtJpnNGoVUS6Bo2ABEjg4Nn6uwgKDUECYSFQVBsMJcu3cwzmAGV4MgUJFzON6CAUAAZegKWI8fgdJDsFAoiI0ABm5M2Ai4yFQWCIiWgfjIZMp1PmFistgcZkWy1oWBQtM0gm2mLhUkymRGQz5JmZfAVKAE6qx+wgXh8fnxiFQBktvgCQTCTCNTlNA2xijpcBthIMq3WmxSWDSGUIWAA7hBAiFwrR3sVaOUHdaCagKjVmgATAT5GrJ0SprB+jNZ+q5-Kld4dGx8By1voe1xeiBwEBsUaRH2llAmdudvC0CPR2MuhOF4u9zM5gR5Qo1+z1gQJGo1CB6OcIrRsPCwjX0Nm0RlsZlIJgJbBsFhgQhtjtdpIABn3cMPtAHj4gAEZL2ob3ePAsFgzbSO+n54NQNC-hAV4ARAeLpigtCHCGYbLFOSGNgIHRAVgJoalIcG3hAHIoJsoEVO6GrEXeiG2shhzUQMVGvkR-4kaM4wTK+FTiCOEAAIJgOsMC0AALHObL0CgyHonO7auoqIjYnqBrSiuzjqto4KvLIEyvCcBBkN+pngh0un6a8UBQEZ6Smd+5mWUgEznLZxkOU5ekua8bLZn5+QeWZFkAGxPpkADMABMEVwFFTlhc00WxfFbTqjY9yZVlWU2GlrjORMxl4K85kQAVQWORZ3kGRVpXgvpRUQGFcWleqBWvK8tVVbIryFa86TFXV1WmUVJUWQ137pJ1Q1WRMc2dfZwUQOl2XZbl6q6P2vi0EayrxNsrZsoyhDZgYsAMRg2D4EQpAZIqTg6h4EATPQeD0FgLAGEdLAnRAhT2FBVBAyI9j5DYeEQIAWEQ2FwNgAB8wo9CwIvqSCGoqED5ADIN2FjL1vR9AhUeqQA
    // !cokhach! => false
    // !cokhac111 => false
    // !cok!hac111 => false
    // cokhach 60m2 => false
    // "!cok!#hac111", "!cok!ss#hac111","!cok@s#hac111","!cok!fdfd+hac111", "60/323m2","60\323m2" => true
    // !cok!kkk!hac111 => true
    // !cok!!hac111 => true
    // !co!kh!ach! => true
    // !cok11hach! => true
    // cokh1ac!h => true

    symbolMoreThanOnce: (input: string)=>{
      const target: string[] = input.split(' ');
      const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const check = target.some(phrase=>{
        const reg = '#$@%^&*()-_+=!`<>?,./\\|}{][\'";:';
        const getSymbol = (specialList: string, outloop = false): boolean => specialList.split('').some((el: string)=>{
          const escapeSymbol = escapeRegExp(el);
          const trimSymbol = phrase.replace(new RegExp(`^(${escapeSymbol}|\\d)+|(${escapeSymbol}|\\d)+$`, 'g'), '');
          const match = (trimSymbol.match(new RegExp(`(${escapeSymbol}|\\d).*?`, 'g')) || []).length;
          if(outloop) return match > 0;
          if(match == 1) return getSymbol(reg.replace(escapeSymbol, ''), true);
          return (match > 1);
        });
        return getSymbol(reg);
      });
      return check;
    },
    emoji: /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug,
    extraSpaces: /[ ]{2,}/,
    // lackSpaces: /[ ]{1}/,
    projectName: (prjName: string, phrase: string)=>{
      const total = Utils.findAllInString(prjName, phrase).length;
      return total > 1;
    },
    duplicate: (): {duplicateArr: DupEl[]; result: boolean} => {
      let duplicateArr: DupEl[] = [];
      const result = combineArr.some((element: CombineArr, index: number) => {
        if(combineArr.findIndex(el=>el.text.toLowerCase() === element.text.toLowerCase()) !== index){
          //TODO: index all duplicate items
          duplicateArr = combineArr.reduce((a: DupEl[], e: CombineArr, i: number)=>{
              if (e === element)
                  a.push({duplicateId: e.id, isHeadline: element.isHeadline});
              return a;
          }, [] as DupEl[]);
          return true;
        }
        return false;
      });
      
      return {
        duplicateArr,
        result
      };
    },
    capitalized: (str: string)=>{
      const convert = Utils.nonAccentVietnamese(str, false);
      return /^[^a-z]+$/.test(convert);
    },
  };
  
  //if we've found any issues push it in here: the issue name and the id which is error
  const indices: ErrorIDs[] = [];
  
  combineArr.map((search: CombineArr, id: number)=>{
     // headlines has some differences need to be validate separately
    if(search.isHeadline) {
      const specialHeadline = /[@!"*-]/;
      const found = specialHeadline.test(search.text);
      if(found) {
        indices.push({isHeadline: true, idError: search.id, property: 'specialHeadline'});
      }
    }
  
    const checkKeys = Object.keys(common);
    checkKeys.map(el=>{
      
      const condition = common[el];
      if(condition instanceof RegExp){
        const found = condition.test(search.text);
        if(found) {
          indices.push({isHeadline: search.isHeadline, idError: search.id, property: el.toString()});
        }
      }
      
      if(el == 'duplicate'){
        const { duplicateArr } = common.duplicate();
        duplicateArr.map((el: DupEl)=>indices.push({isHeadline: el.isHeadline, idError: el.duplicateId, property: 'duplicate'}));
      }
      if(el == 'capitalized'){
        if(common.capitalized(search.text)) indices.push({isHeadline: search.isHeadline, idError: search.id, property: 'capitalized'});
      }
      if(el == 'phoneNo'){
        if(common.phoneNo(search.text)) indices.push({isHeadline: search.isHeadline, idError: search.id, property: 'phoneNo'});
      }
      if(el == 'symbolMoreThanOnce'){
        if(common.symbolMoreThanOnce(search.text)) indices.push({isHeadline: search.isHeadline, idError: search.id, property: 'symbolMoreThanOnce'});
      }
      // if(el == 'symbolMoreThanTwice'){
      //   if(common.symbolMoreThanTwice(search.text)) indices.push({isHeadline: search.isHeadline, idError: search.id, property: 'symbolMoreThanTwice'});
      // }
      if(el == 'projectName'){
        const pname = lp?.pname;
        if(common.projectName(pname, search.text)) indices.push({isHeadline: search.isHeadline, idError: search.id, property: 'projectName'});
      }
      
    });
  });
  
  return {
    errorIds: indices
  };
};

export const TemplateAdsSchema = vest.create((data: TemplateAdsFormState, landingPage: {pname: string})=>{
    const validation = data.adgroupad;
    validation.map((validateData: AdGroupAdExtends, id: number)=>{
      if(!validateData) return;

      const reqByType = SPECS[validateData.adGroupAd.ad.type];
      if(!reqByType) throw 'Không tìm thấy reqs cho ad type = ' + validateData.adGroupAd.ad.type;
        
        const headlines = validateData.adGroupAd?.ad?.headlines?.filter((item: any)=>item);
        const descriptions = validateData.adGroupAd?.ad?.descriptions?.filter((item: any)=>item);
        const headlinesLength = headlines?.length;
        const descriptionsLength = descriptions?.length;
        const { minHeadline, maxHeadline, minDesc, maxDesc } = reqByType;
        test('adgroupad.' + id + '.Headline', minHeadline.message.replace('{d}', minHeadline.value.toString()), ()=>{
          const ok = headlinesLength >= minHeadline.value;
          enforce(ok).isTruthy();
        });
        
        test('adgroupad.' + id + '.Headline', maxHeadline.message.replace('{d}', maxHeadline.value.toString()), ()=>{
          const ok = headlinesLength <= maxHeadline.value;
          enforce(ok).isTruthy();
        });
        
        test('adgroupad.' + id + '.Desc', minDesc.message.replace('{d}', minDesc.value.toString()), ()=>{
          const ok = descriptionsLength >= minDesc.value;
          enforce(ok).isTruthy();
        });
        
        test('adgroupad.' + id + '.Desc', maxDesc.message.replace('{d}', maxDesc.value.toString()), ()=>{
          const ok = descriptionsLength <= maxDesc.value;
          enforce(ok).isTruthy();
        });
        
        const {errorIds} = findAllInArray(headlines, descriptions, landingPage);
        errorIds && errorIds.map((item: ErrorIDs)=>{
          const isHeadline = item.isHeadline ? 'CommonHeadline' : 'CommonDesc';
          test('adgroupad.' + id + '.' + isHeadline + '.' + item.idError, SPECS.common[item.property], ()=>{
            enforce(false).isTruthy();
          });
        });
    });
});