import { ChannelsData } from 'core';
import * as React from 'react';
import { CustomRowProps } from '../index.d';
import Campaign from './Campaign';

type CampaignsProps = {
  row: CustomRowProps;
};

export default function Campaigns(props: CampaignsProps): React.ReactElement | null {
  const { row } = props;
  const original  = row.original as ChannelsData;
  // if (original.name != 'Facebook') return null;
  const campaigns = original.campaigns as ChannelsData[];
  if (!campaigns || !campaigns.length) return null;
  return (
    <React.Fragment key={row.id}>
      {campaigns.map((v, index) => {
        return <Campaign key={index} campaign={v} channel={original.name} />;
      })}
    </React.Fragment>
  );
}
