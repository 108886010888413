"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adsReport = void 0;
const auth_1 = require("../../auth");
const ads_campaign_1 = require("../../ads-campaign");
const campaignReport_1 = require("../../entities/GoogleAds/campaignReport");
const Report_1 = require("../types/Report");
;
const initialState = {
    campaignReports: undefined
};
const adsReport = (state = initialState, action) => {
    switch (action.type) {
        case Report_1.GoogleAdsReportTypes.success:
            return Object.assign({}, state, {
                campaignReports: action.payload
            });
        case ads_campaign_1.UpdateGoogleCampaignStatus.success:
            const params = action.payload;
            if (!state.campaignReports) {
                return state;
            }
            return Object.assign({}, state, {
                campaignReports: Object.assign(Object.assign({}, state.campaignReports), { campaigns: state.campaignReports.campaigns.map((item) => {
                        if (params.resource_names.includes(item.campaign.resource_name)) {
                            const newCampaign = campaignReport_1.CampaignInfo.clone(item.campaign);
                            newCampaign.status = params.status;
                            item.campaign = newCampaign;
                        }
                        return item;
                    }) })
            });
        case auth_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.adsReport = adsReport;
