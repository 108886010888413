import * as React from 'react';
import styled from 'styled-components';
import { Project, ProjectTypes } from 'core';
import TextareaAutosize from 'react-autosize-textarea';
import 'stylesheets/App.css';

const Styles = styled.div`
  label {
    margin: 20px 0 10px;
    font-family: Propins;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #8e8e93;
  }

  .form-control {
    font-family: Propins;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #000000;
    padding: 10px 15px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
  }

  .form-control::placeholder {
    color: #aeaeb2;
  }
  @media (max-width: 1300px) {
    .project-infor-form {
      min-height: auto;
    }
    .form-control {
      padding: 8px 10px;
      height: 36px;
      font-size: 14px;
    }
    label {
      font-size: 12px;
      margin: 15px 0 8px;
    }
  }
`;

type PropsType = {
  project?: Project;
  handleInputChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  projectType: ProjectTypes;
}

export function ProjectModelHouseForm(props: PropsType) {
  const { project } = props;

  return (
    <Styles>
      <div className="project-infor-form justify-content-between flex-column row m-0">
        <div className="col-12">
          <label>
            Bạn hãy miêu tả về nhà mẫu của dự án này
          </label>
          <TextareaAutosize
            name="model_house"
            autoFocus={true}
            rows={3}
            className="form-control"
            placeholder="VD: Căn hộ mẫu hoàn thiện đầy đủ nội thất"
            defaultValue={project ? project.model_house : ''}
            onChange={props.handleInputChange}
          />
        </div>
      </div>
    </Styles>
  );
}
