import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'core';
import * as ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import TeamService from '../service';
import { plainToClass } from 'class-transformer';

interface ModalProps {
  customStyles?: object;
  user: any;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

const MemberInformationModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const user = plainToClass(User, props.user);
  const [stateUser, setStateUser] = useState(user);
  const { name, email, phone_no: phoneNo, roles } = user;
  const role = user.getRoles();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [user]);

  const closeModal = () => {
    props.closeModal();
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const newStateUser: User = Object.assign({}, stateUser, { [name]: value });
    setStateUser(newStateUser);
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="member-information-modal">
        <div className="modal__heading">
          <h3>Thông tin thành viên</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="member-information-panel">
            <div className="account-avatar--wrapper">
              <img
                className="avatar-img"
                src={user.getAvatarUrl()}
                alt="avatar"
              />
            </div>
            <div className="account-info--wrapper">
              <h4>{name}</h4>
              <p>ID: {user.getUserMasterId()}</p>
              <p className="account-user-type">
                {user.getAccountMembership() === 'normal' && <>Tài khoản thường<span className="hidden"> • Nâng cấp tài khoản</span></>}
                {user.checkUserRole(['owner']) &&
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M8 1.69c.178 0 .355.032.527.09.736.253 3.11 1.116 3.853 1.421.533.222.812.432.812 1.086v4.786c0 2.279-1.161 3.224-4.773 5.116-.159.082-.317.12-.419.12-.102 0-.254-.031-.419-.12-3.548-2-4.773-2.837-4.773-5.116V4.287c0-.654.285-.87.812-1.086.325-.13.962-.373 1.638-.624l.583-.216c.676-.248 1.314-.476 1.638-.582.166-.05.343-.089.521-.089zm0 2.014L6.915 5.902l-2.425.352 1.755 1.71-.414 2.417L8 9.24l2.17 1.14-.415-2.415 1.755-1.71-2.425-.353L8 3.704z" transform="translate(-284 -93) translate(274 36) translate(10 56) translate(0 1)"/> </g> </g> </g> </g> </g> </svg>
                  Chủ sở hữu
                </div>}
                {user.checkUserRole(['manager']) &&
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M8 14.31c.102 0 .26-.039.419-.121 3.612-1.892 4.773-2.837 4.773-5.116V4.287c0-.654-.279-.864-.812-1.086-.743-.305-3.117-1.168-3.853-1.422-.172-.057-.35-.089-.527-.089-.178 0-.355.039-.52.09-.325.105-.963.333-1.639.581l-.583.216c-.676.251-1.313.494-1.638.624-.527.216-.812.432-.812 1.086v4.786c0 2.279 1.225 3.116 4.773 5.116.165.089.317.12.419.12zm-.682-3.395c-.189 0-.331-.07-.46-.225L5.142 8.584C5.04 8.464 5 8.352 5 8.232c0-.275.202-.474.484-.474.166 0 .285.063.395.199l1.426 1.798 2.793-4.418c.116-.186.235-.252.424-.252.279 0 .478.195.478.467 0 .103-.033.213-.11.332l-3.104 4.79c-.11.162-.265.241-.468.241z" transform="translate(-284 -114) translate(274 36) translate(10 77) translate(0 1)"/> </g> </g> </g> </g> </g> </svg>
                  Quản lý
                </div>}
              </p>
              {user.auth_provider && <p className="account-type">Tài khoản đăng nhập bằng {user.auth_provider}</p>}
            </div>
          </div>
          <div className="modal__content--row">
            <label>Email</label>
            {email}
          </div>
          <div className="modal__content--row">
            <label>Họ và tên</label>
            {name}
          </div>
          <div className="modal__content--row">
            <label>Số điện thoại</label>
            {phoneNo}
          </div>
        </div>
        <div className="modal__bottom">
          <button onClick={closeModal} className="btn-default">Đóng</button>
        </div>
      </div>
    </ReactModal>
  );
};

MemberInformationModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default MemberInformationModal;
