"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationAds = exports.disconnectAdsAccount = exports.updateCampaignStatus = exports.createOrUpdateAdGroup = exports.createOrUpdateAdGroupAd = exports.getAdGroupsBelongToCampaign = exports.getListAdGroupAdByCampaign = exports.createGoogleCampaign = exports.fetchGoogleCampaign = exports.getDetailGoogleCampaign = void 0;
const class_transformer_1 = require("class-transformer");
const entities_1 = require("../../entities");
const effects_1 = require("redux-saga/effects");
const adapters_1 = require("../../adapters");
const types_1 = require("../types");
const GoogleAds_1 = require("../../GoogleAds");
const entities_2 = require("../../entities");
function* getDetailGoogleCampaign(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/campaign`,
            authenticated: true,
            data: params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetDetailGoogleCampaign.success,
            payload: response.data.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.GetDetailGoogleCampaign.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getDetailGoogleCampaign = getDetailGoogleCampaign;
// export function* getAllGoogleCampaign(action: DispatchAction) {
//   try {
//     const params: GetAllGoogleCampaignParams = action.params as GetAllGoogleCampaignParams;
//     const request: Request = {
//       method: 'get',
//       url: `${process.env.REACT_APP_ADS_URL}/google_ads/default_extensions`,
//       authenticated: true,
//       params
//     };
//     const response: AxiosResponse = yield call(callApi, request);
//     yield put({
//       type: GetAllGoogleCampaign.success,
//       payload: response.data.data,
//       meta: action.meta
//     });
//   } catch (error) {
//     const payload = error.response && error.response.data
//     yield put({
//       type: GetAllGoogleCampaign.error,
//       error: true,
//       payload,
//       meta: action.meta
//     });
//   }
// }
function* fetchGoogleCampaign(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/campaign`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetDetailGoogleCampaign.success,
            payload: response.data.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.GetDetailGoogleCampaign.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchGoogleCampaign = fetchGoogleCampaign;
function* createGoogleCampaign(action) {
    try {
        const params = action.params;
        const postData = GoogleAds_1.CampaignDataInfoService.jsonToString(params);
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/campaign`,
            authenticated: true,
            data: postData,
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const payload = {
            resource_name: response.data.data,
        };
        yield effects_1.put({
            type: types_1.CreateGoogleCampaign.success,
            payload,
            params: params,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.CreateGoogleCampaign.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.createGoogleCampaign = createGoogleCampaign;
function* getListAdGroupAdByCampaign(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/adgroupads`,
            authenticated: true,
            params,
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const payload = class_transformer_1.plainToClass(entities_1.AdGroupAd, response.data.data.ad_group_ads);
        yield effects_1.put({
            type: types_1.GetListAdGroupByCampaign.success,
            payload,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.GetListAdGroupByCampaign.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getListAdGroupAdByCampaign = getListAdGroupAdByCampaign;
function* getAdGroupsBelongToCampaign(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/adgroups`,
            authenticated: true,
            params,
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const adGroup = class_transformer_1.plainToClass(entities_2.KeywordData, response.data.data);
        const payload = {
            AdGroups: adGroup
        };
        yield effects_1.put({
            type: types_1.GetAdGroupsBelongToCampaign.success,
            payload: payload,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.GetAdGroupsBelongToCampaign.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getAdGroupsBelongToCampaign = getAdGroupsBelongToCampaign;
function* createOrUpdateAdGroupAd(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/adgroupads`,
            authenticated: true,
            data: { ad_group_ads: params.AdGroupAds.map(x => { return x.toJson(); }) },
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const payload = response.data.data;
        yield effects_1.put({
            type: types_1.CreateOrUpdateAdGroupAd.success,
            payload,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.CreateOrUpdateAdGroupAd.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.createOrUpdateAdGroupAd = createOrUpdateAdGroupAd;
function* createOrUpdateAdGroup(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/adgroup`,
            authenticated: true,
            data: Object.assign({ campaign_resource_name: params.campaign_resource_name }, params.keywordData.toJson()),
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        // const adGroup = plainToClass(KeywordData, response.data.data);
        yield effects_1.put({
            type: types_1.CreateOrUpdateAdGroup.success,
            payload: response.data.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.CreateOrUpdateAdGroup.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.createOrUpdateAdGroup = createOrUpdateAdGroup;
function* updateCampaignStatus(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/campaign_status`,
            authenticated: true,
            data: params,
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: types_1.UpdateGoogleCampaignStatus.success,
            payload: params,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.UpdateGoogleCampaignStatus.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.updateCampaignStatus = updateCampaignStatus;
function* disconnectAdsAccount(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/connected_account`,
            authenticated: true,
            data: params.account,
            setUserToken: params.setUserToken
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: types_1.DisconnectAdsAccount.success,
            payload: params,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.DisconnectAdsAccount.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.disconnectAdsAccount = disconnectAdsAccount;
function* getLocationAds(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/locations`,
            authenticated: true,
            params,
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        const payload = response.data.data;
        yield effects_1.put({
            type: types_1.GetLocationAds.success,
            payload,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data;
        yield effects_1.put({
            type: types_1.GetLocationAds.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getLocationAds = getLocationAds;
