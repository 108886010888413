import styled from 'styled-components';
import { IDailySchedule, GoogleAdsEnum, DatePickerType, OperationTypes, CustomError, CustomErrorCode } from 'core';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Control, FieldErrors } from 'react-hook-form';
import { ErrorInput } from '../fields';
import classNames from 'classnames';

interface PropType{
  onChange: (value: Date | undefined) => void; 
  errors: FieldErrors;
  value: any;
  customError?: CustomError;
}

const StyleDatePicker = styled(DatePicker)`
  &.error{
    border: 2px solid red !important;
    color: red;
  }
`;

export const maxEndDate = '2037-12-30';

export const EndDateField: React.FC<PropType> = (props) => {
  const { errors, customError } = props;
  const end_date = props.value || maxEndDate; 
  const EndDateRef = useRef<DatePicker>(null);
  const endDate = new Date(end_date || undefined);
  const endDateNotExist = endDate.getTime() == (new Date(maxEndDate)).getTime();
  return (<>
    <div className="col-lg-6">
      <label>Ngày kết thúc</label>
      <div className="input-group form-inline">
        <div className="form-check">
          <input
            id="nonStop-ad"
            type="radio"
            name="hasStopAds"
            value={'nonStop-ad'}
            onChange={() => {props.onChange(undefined);}}
            checked={endDateNotExist}
          />
          <label className="radio-label ml-2 mr-2" htmlFor="nonStop-ad"> Không</label>
        </div>
        <div className="form-check flex-1">
          <input
            id="none"
            type="radio"
            name="hasStopAds"
            value={'locationVN'}
            onChange={() => {props.onChange(EndDateRef.current?.props.selected || new Date());}}
            checked={!endDateNotExist}
          />
          <StyleDatePicker 
            ref={EndDateRef}
            className={classNames('datepicker ml-2', {error: errors.end_date})}
            dateFormat="dd/MM/yyyy"
            selected={!endDateNotExist ? endDate : null} 
            placeholderText="Chọn ngày kết thúc"
            onChange={(data: Date) => {
              props.onChange(data);
            }}
          />
        </div>
      </div>
        {errors.end_date && <ErrorInput text={errors.end_date.message}/>}
        {/* {customError && customError.code === CustomErrorCode. && <ErrorInput text={customError.message}/>} */}
    </div>
  </>);
};