"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationSagas = exports.generatorAction = void 0;
const effects_1 = require("redux-saga/effects");
const _1 = require(".");
function* generatorAction(action) {
    try {
        const params = action.params;
        yield effects_1.put({
            type: _1.SetNotificationAction.success,
            payload: {},
            meta: action.meta
        });
    }
    catch (error) {
        const payload = {};
        yield effects_1.put({
            type: _1.SetNotificationAction.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.generatorAction = generatorAction;
exports.notificationSagas = [
    effects_1.takeLatest(_1.SetNotificationAction.type, generatorAction),
];
