import * as React from 'react';
import { useState, useEffect } from 'react';
import {EditSiteLinkModal, EstimatePricingModal, EditCallModal} from '../modals';
import { Tooltip } from 'reactstrap';
import 'stylesheets/App.css';
import { useGoogleForm, useExtensionCall } from 'utils/hooks';
import styled from 'styled-components';
import { CommonSettingsType } from '../Settings';
import SubmitButton from 'buttons/SubmitButton';
import { SiteLinkServiceType, CallNumber, GoogleAdsEnum, ExtensionServiceType, ICampaignData, CampaignDataInfoService, InitCampaignData, IBiddingStrategy, AppState, 
  ApiRequestError, GoogleApiErrorHandlerService, CustomError, CustomErrorCode, ISitelinkExtensionSetting, ICallExtensionSetting } from 'core';
import { GoogleService, CreateGoogleCampaignParams } from 'core';
import { InputType, InputCallType, updateSitelinkIO, addSitelinkIO, updateCallIO, addCallIO,ErrorInput } from '../fields';
import { LoadingScreen } from 'components/common';
import { SPECS } from './validate';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

export const WatchData = styled.div`
  display: none;
  overflow: auto;
  background-color: #ffbaba;
  width: 500px;
  max-height: 500px;
  text-align: left;
  margin: 0 auto;
  color: white;
  word-break: break-word;
  position: fixed;
  z-index: 999;
  right: 0;
`;

export enum ExtensionAction {
  Edit = 1,
  Delete = 2,
  Toggle = 3,
  Add = 4,
  Remove = 5,
}

enum ExtensionType {
  SiteLink = 1,
  Call = 2,
}

export function CommonSettings(props: CommonSettingsType) {
  const needFetchCurrentCampaign = useSelector<any, boolean>((state: AppState) => state.adsCampaign.needFetchCurrentCampaign);
  const isUpdating = useSelector<any, boolean>((state: AppState) => !!(state.adsCampaign.currentCampaign && !!state.adsCampaign.currentCampaign.resource_name));
  const [loading, setLoading] = useState(needFetchCurrentCampaign);
  const history = (globalThis as any).useHistory;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [openSiteLinkModal, setOpenSiteLinkModal] = useState(false);
  const [openCallModal, setOpenCallModal] = useState(false);
  const [isShowEstimatePricingModal, setShowEstimatePricingModal] = useState(false);
  const [adScheduleOpenState, setAdScheduleOpenState] = useState(true);
  const [adLanguageLocationOpenState, setAdLanguageLocationOpenState] = useState(true);
  const [adBudgetOpenState, setAdBudgetOpenState] = useState(true);
  const [adExtendOpenState, setAdExtendOpenState] = useState(true);
  const [adAgeAndDeviceOpenState, setAdAgeAndDeviceOpenState] = useState(true);
  const [sitelinksApi, setSitelinksApi] = useState<InputType[]>([]);
  const [callsApi, setCallsApi] = useState<InputCallType[]>([]);
  const [extensionData, setExtensionData] = useState<any>();
  const [customError, setCustomError] = useState<CustomError | undefined>();
  
  const { 
    CreateFields, initGoogleCampaignData,
    Controller, useForm, vestResolver, createGoogleCampaignSchema, editGoogleCampaignSchema
  } = useGoogleForm();
  const service = new GoogleService();
  
  const initData: InitCampaignData = {
    name: initGoogleCampaignData.name,
    account_id: initGoogleCampaignData.account_id,
    landing_page_id: initGoogleCampaignData.landing_page_id,
    campaign_type: initGoogleCampaignData.campaign_type,
  };
  
  const campaignDataSvc = new CampaignDataInfoService();
  const stateData = campaignDataSvc.getStateData();

  if (!stateData) {
    history.push('/create-google-campaign');
  }

  const collapsedErrorMessage = 'Cần khắc phục vấn đề thiết lập quảng cáo';
  const iconSvg = <svg style={{width: 15, height: 15, marginBottom: 20, marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h24v24H0z" opacity=".01"/> <path fill="#ff3b30" d="M12.447 1.342a1 1 0 0 1 .447.447l10.382 20.764A1 1 0 0 1 22.382 24H1.618a1 1 0 0 1-.894-1.447L11.106 1.789a1 1 0 0 1 1.341-.447zM12 18a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-8.571c-.71 0-1.286.575-1.286 1.285v5.143a1.286 1.286 0 0 0 2.572 0v-5.143c0-.71-.576-1.285-1.286-1.285z"/> </g> </svg>;

  const defaultValues = stateData ? stateData : campaignDataSvc.initNewCampaignData(initData);

  const resolver = isUpdating ? editGoogleCampaignSchema : createGoogleCampaignSchema;

  const {
    StartDateField, EndDateField, DailySchedulesField,
    LocationSelect, LanguageField, CallComponent, SitelinkComponent, 
    AgeField, DeviceField,  } = CreateFields;
  const { register, setValue, reset, handleSubmit, watch, formState: {errors}, control, setFocus, getValues } = useForm<ICampaignData>({
    resolver: vestResolver(resolver),
    defaultValues
  });

  useEffect(() => {
    // This will select error that are not hidden
    const firstError = document.querySelector('.error-input:not(.hidden *)');
    firstError?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }, [errors, customError]); 

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      setCustomError(undefined);
    }
  }, [errors]);

  const watchAllFields = watch();

  useEffect(() => {
    errors.languages && setFocus('languages');
  }, [setFocus]);

  useEffect(() => {
    customError && customError.code === CustomErrorCode.MaxConversionValueTargetRoas && setFocus('maximize_conversion_value.target_roas');
  }, [customError]);
  
  useEffect(() => {
    if(Object.values(initData).some(el=>el === undefined) || !watch('landing_page_id')){
      history.push('/create-google-campaign');
    }
  }, [watch('landing_page_id'), initGoogleCampaignData]);

  useEffect(() => {
    if (props.backToHistory) {
        const data = watch();
        saveDraftGoogleCampaign(data);
  
        if (props.onSaveDraffSuccess) {
          props.onSaveDraffSuccess();
        }
        return;
    }
  }, [props.backToHistory]);

  useEffect(() => {
    if (!needFetchCurrentCampaign && stateData !== null) {
      reset(stateData);
    }
  }, [stateData]);

  useEffect(() => {
    setLoading(needFetchCurrentCampaign);
  }, [needFetchCurrentCampaign]);
  
  const enhanceDefaultCallSideEffect = (input: CallNumber[]): InputCallType[] => {
    // const calls = defaultValues.call?.calls;
    const defaultCalls = input.map((el)=>{
      // const addedCall = calls ? calls.find(x => x.phone_number === el) : undefined;
      const inputCall: InputCallType = {
        phone_number: el,
        value: 0
      };
      return inputCall;
    });

    /**
     * When creating new campaign, all call extensions are choosed as default
     */
     if (!isUpdating) {
      defaultCalls.forEach((x) => x.value = 1);
      const callExtension: ICallExtensionSetting = {
        calls: defaultCalls
      };
      setValue('call', callExtension);
     }

     return defaultCalls;
  };
  
  const enhanceDefaultSiteLinkSideEffect = (input: SiteLinkServiceType[]): InputType[] => {
   
    const siteLinks = defaultValues.site_link;
    const allSiteLinks = input.map((el, id) => {
      const addedSiteLink = siteLinks ? siteLinks.sitelinks.find(x => x.url === el.url) : undefined;
      const result: InputType = {
        title: el.name,
        value: 0,
        component: el.component,
        url: el.url,
        description1: addedSiteLink?.description1,
        description2: addedSiteLink?.description2
      };
      
      return result;
    });

    /**
     * When creating new campaign, some sitelinks are choosed as default
     */
    if (!isUpdating) {
      allSiteLinks.forEach((x, index) => {
        if (index < SPECS.commonSetting.site_link.value) {
          x.value = 1;
        }
      });
      const siteLinkExtension: ISitelinkExtensionSetting = {
        sitelinks: allSiteLinks.filter(x => x.value === 1)
      };
      setValue('site_link', siteLinkExtension);
    }

    return allSiteLinks;
  };
  
  const handleDeleteExtensionItem = (type: ExtensionType, item?: InputType | InputCallType) => {
    if(!item) return;
    if(type === ExtensionType.SiteLink){
      setSitelinksApi(prep=>[...prep.filter((el, id)=>el.url !== (item as InputType).url)]);
    }
    if(type === ExtensionType.Call){
      setCallsApi(prep=>[...prep.filter((el, id)=>el.phone_number !== (item as InputCallType).phone_number)]);
    }
  };
  
  const handleGetExtensionSuccess = (res: ExtensionServiceType) => {
    //Init extension data
    if(res.default_sitelinks && res.default_sitelinks.length > 0){
      const site_link = enhanceDefaultSiteLinkSideEffect(res.default_sitelinks); 
      setSitelinksApi(site_link);
    }
    if(res.default_calls && res.default_calls.length > 0){
      const call = enhanceDefaultCallSideEffect(res.default_calls);
      setCallsApi(call);
    }
  };
  
  const handleSitelinkInteractive = (data: InputType[], action: ExtensionAction) => {
    // When user edit campaign; site_link will be load as a field of Campaign form 
    // You need to use watch method to get that field
    const valueSitelink = watch('site_link');
    
    let sitelinks = undefined;
    if(action === ExtensionAction.Edit){
      sitelinks = updateSitelinkIO(
        {
          sitelinksApi,
          value: valueSitelink
        }, 
        {updateItem: data[0], oldItem: data[1]},
        defaultValues.site_link?.resource_name
      );
    }
    if(action === ExtensionAction.Add){
      sitelinks = addSitelinkIO(
        {
          sitelinksApi,
          value: valueSitelink
        }, 
        data,
        defaultValues.site_link?.resource_name
      );
    }

    setValue('site_link', sitelinks);
    setOpenSiteLinkModal(false);
  };

  const handleCallInteractive = (data: InputCallType[], action: ExtensionAction) => {
    // When user edit campaign; site_link will be load as a field of Campaign form 
    // You need to use watch method to get that field
    const watchCall = watch('call');
 
    let calls = undefined;
    if(action === ExtensionAction.Edit){     
      calls = updateCallIO(
        {
          calls: callsApi,
          value: watchCall
        }, 
        {
          updateItem: data[0], 
          oldItem: data[1]
        }, 
        defaultValues.call?.resource_name);
    }
    if(action === ExtensionAction.Add){
      calls = addCallIO(
        {
          calls: callsApi,
          value: watchCall
        }, 
        data,
        defaultValues.call?.resource_name
      );
    }
    
    setValue('call', calls);
    setOpenCallModal(false);
  };

  useExtensionCall({
    onSuccess: handleGetExtensionSuccess,
    landing_page_id: defaultValues.landing_page_id
  });

  const submit = (data: ICampaignData) => {    
    setCustomError(undefined);

    const params: CreateGoogleCampaignParams = {
      inputParams: data,
      callBackParams: {
        successCallback: (res: any) => {
          setLoading(false);
          props.onSaveSuccess(1);
        },
        errorCallback: (err: ApiRequestError) => {
          setLoading(false);
          setCustomError(GoogleApiErrorHandlerService.getErrorMessage(err));
          props.onError(err);
        }
      }
    };
    setLoading(true);
    service.createGoogleCampaign(params);
  };
  
  const saveDraftGoogleCampaign = (data: ICampaignData) => {
    const params: CreateGoogleCampaignParams = {
      inputParams: data
    };
    
    service.saveDraftGoogleCampaign(params);
  };
  
  const handleExtensionAction = (type: ExtensionType, item?: InputType | InputCallType, action?: ExtensionAction) => {
    if(type === ExtensionType.SiteLink){
      if(action === ExtensionAction.Remove){
        handleDeleteExtensionItem(type, item);
      }
      if(action === ExtensionAction.Edit){
        setOpenSiteLinkModal(true);
        setExtensionData(item);
      }
      if(action === ExtensionAction.Add){
        setOpenSiteLinkModal(true);
        setExtensionData(undefined);
      }
    }
    
    if(type === ExtensionType.Call){
      if(action === ExtensionAction.Remove){
        handleDeleteExtensionItem(type, item);
      }
      if(action === ExtensionAction.Edit){
        setOpenCallModal(true);
        setExtensionData(item);
      }
      if(action === ExtensionAction.Add){
        setOpenCallModal(true);
        setExtensionData(undefined);
      }
    }
  };

  const onBiddingStrategyTypeChanged = (event: React.FormEvent<HTMLSelectElement>) => {
    setValue('bidding_strategy_type', +event.currentTarget.value );
    if (+event.currentTarget.value === GoogleAdsEnum.BiddingStrategyType.TARGET_SPEND) {
      const biddingStrategy: IBiddingStrategy = {
        auto_optimize_bid: defaultValues.bidding_strategy ? defaultValues.bidding_strategy.auto_optimize_bid : true,
        max_cpc: defaultValues.bidding_strategy ? defaultValues.bidding_strategy.max_cpc : 60000
      };
      setValue('bidding_strategy.auto_optimize_bid', biddingStrategy.auto_optimize_bid as never);
      setValue('bidding_strategy.max_cpc', biddingStrategy.max_cpc as never);
    }

    if (+event.currentTarget.value === GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE) {
      setValue('maximize_conversion_value', {
        target_roas: defaultValues.maximize_conversion_value ? defaultValues.maximize_conversion_value.target_roas : undefined
      });
    }
  };

  const renderMaxClick = () => {
    const renderAutoBidding = () => {
      return (
        <>
          <div className="input-group mt-3">
            <label>Giá thầu tối đa/click (Max CPC)</label>
            <div className="input-group__inner">
              <div className="input-group__prefix">đ</div>
              <Controller
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <NumberFormat
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    className="form-control"
                    placeholder="Nhập số tiền"
                    onValueChange={v => setValue('bidding_strategy.max_cpc', v.floatValue as never)}
                    defaultValue={value}
                  />
                )}
                name="bidding_strategy.max_cpc"
                control={control}
              />
            </div>
          </div>
          <div>
          {errors.bidding_strategy?.max_cpc && <ErrorInput text={errors?.bidding_strategy?.max_cpc.message}/>}
          </div>
        </>
      );
    };

    if (watch('bidding_strategy_type') !== GoogleAdsEnum.BiddingStrategyType.TARGET_SPEND) {
      return;
    }

    return (
      <>
        <div className="form-check mt-3">
          <input
            id="auto-adjust-bidding"
            className="form-check-input"
            type="checkbox"
            {...register('bidding_strategy.auto_optimize_bid')}
            value={1}
          />
          <label className="form-check-label" htmlFor="auto-adjust-bidding">
            <span className="mr-2">Tự động tối ưu giá thầu</span>
            <span id="TooltipAutomaticBidding">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2"> <g> <g> <path d="M7 0c3.875 0 7 3.125 7 7s-3.125 7-7 7-7-3.125-7-7 3.125-7 7-7zm.426 5.833h-1.77c-.104 0-.17.017-.227.047-.057.03-.102.075-.132.132-.03.058-.047.123-.047.227v.227c0 .09.008.122.022.157.015.034.037.062.067.085.03.022.059.037.146.06l.58.144c.13.033.175.055.22.09.043.032.077.076.098.126.023.052.034.1.034.235v2.19c0 .135-.011.184-.034.235-.021.05-.055.094-.099.127-.045.034-.089.057-.22.09l-.58.144c-.086.023-.116.037-.145.06-.03.023-.052.051-.067.085-.014.034-.022.067-.022.156v.228c0 .104.016.17.047.226.03.057.075.102.132.133.057.03.123.046.226.046h2.981c.104 0 .17-.016.227-.046.056-.03.102-.076.132-.133.03-.057.047-.122.047-.226v-.228c0-.09-.008-.121-.022-.156-.015-.034-.037-.062-.067-.085-.03-.023-.059-.037-.146-.06l-.58-.144c-.13-.033-.175-.056-.22-.09-.043-.033-.077-.076-.099-.127-.022-.051-.033-.1-.033-.235V6.282c0-.156-.016-.213-.047-.27-.03-.056-.075-.102-.132-.132-.057-.03-.114-.047-.27-.047zM7 2.625c-.642 0-1.167.525-1.167 1.167 0 .641.525 1.166 1.167 1.166s1.167-.525 1.167-1.166c0-.642-.525-1.167-1.167-1.167z" transform="translate(-522 -550) translate(155 464) translate(367 86)"/> </g> </g> </g> </g> </svg>
            </span>
            <Tooltip placement="bottom" isOpen={tooltipOpen} target="TooltipAutomaticBidding" toggle={toggle}>
              Hệ thống tự động điều chỉnh giá thầu tốt nhất dựa theo dữ liệu hiện có.
            </Tooltip>
          </label>
        </div>
        {renderAutoBidding()}
      </>
    );
  };

  const renderMaxConversionValue = () => {
    if (watch('bidding_strategy_type') !== GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE) {
      return;
    }

    return (
      <div className="input-group">
        <label>Lợi tức mục tiêu trên chi tiêu quảng cáo</label>
        
        <input
          {...register('maximize_conversion_value.target_roas')}
          type="text"
          className="form-control"
          placeholder={'Nhập số %'}
          maxLength={15}
        />
        <div>
          {errors.maximize_conversion_value?.target_roas && <ErrorInput text={errors?.maximize_conversion_value?.target_roas.message}/>}
          {customError && customError.code === CustomErrorCode.MaxConversionValueTargetRoas && <ErrorInput text={customError.message}/>}
        </div>
      </div>
    );
  };

  if(loading) return <LoadingScreen />;
  return (
    <div key="Common-setting">
    <EditSiteLinkModal 
      isOpen={openSiteLinkModal} 
      closeModal={() => setOpenSiteLinkModal(false)}
      allItems={{
        sitelinksApi,
        value: watch('site_link')
      }}
      value={extensionData}
      onAction={handleSitelinkInteractive}
    />
    <EditCallModal 
      isOpen={openCallModal} 
      closeModal={() => setOpenCallModal(false)}
      value={extensionData}
      onAction={handleCallInteractive}
    />
    <EstimatePricingModal 
      isOpen={isShowEstimatePricingModal} 
      closeModal={() => {setShowEstimatePricingModal(false);}} 
    />
    <WatchData>
      {JSON.stringify(watchAllFields)}
    </WatchData>
    
    <div className="page-content">
      <div className="container overflow-visible">
        {Object.keys(initData).map((key: any, id: number)=>
          <React.Fragment key={id}>
          <input key={'hiddenProps' + id} type="hidden" defaultValue={initData[key]} {...register(key)}/>
          {errors[key] && <ErrorInput text={errors[key].message}/>}
          </React.Fragment>)}
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3 className="d-flex justify-content-between">Lịch quảng cáo
              <div className="section-toogle cursor-pointer" onClick={() => {setAdScheduleOpenState(!adScheduleOpenState);}}>
                {!adScheduleOpenState ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>  
                : 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>
                }
              </div>
            </h3>
          </div>
          <div className="col-lg-8">
            <div className={`row ${!adScheduleOpenState ? 'hidden' : ''}`}>

              <Controller
                name="start_date"
                control={control}
                render={({field})=>(
                <StartDateField 
                  // disabled={isUpdating && (new Date(defaultValues.start_date) < new Date())}
                  value={field.value} 
                  errors={errors} 
                  onChange={field.onChange}
                  customError={customError}
                />)}
              />
              <Controller
                name="end_date"
                control={control}
                render={({field})=>(
                <EndDateField 
                  value={field.value} 
                  errors={errors} 
                  onChange={field.onChange}
                  customError={customError}
                />)
                }
              />
              <Controller
                name="daily_schedules"
                control={control}
                render={({field})=><DailySchedulesField
                  errors={errors}
                  onChange={field.onChange}
                  value={field.value}
                />}
              />
            </div>
            { 
            !adScheduleOpenState
            && (
              (errors && (errors.start_date || errors.end_date || errors.daily_schedules)) 
              || (customError && 
                [CustomErrorCode.AdScheduleOverlap, 
                  CustomErrorCode.PastDay, 
                  CustomErrorCode.DatePassSystemLastDate, 
                  CustomErrorCode.CantModifyStartDateIfStarted
                ].includes(customError.code) )
            )
            && <div style={{display: 'flex'}}>{iconSvg}<ErrorInput text={collapsedErrorMessage}/></div>
            }
          </div>
        </div>
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3 className="d-flex justify-content-between">Ngôn ngữ & Vị trí
              <div className="section-toogle cursor-pointer" onClick={() => {setAdLanguageLocationOpenState(!adLanguageLocationOpenState);}}>
                {!adLanguageLocationOpenState ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>  
                : 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>
                }
              </div>
            </h3>
          </div>
          
            <div className={`col-lg-4 ${!adLanguageLocationOpenState ? 'hidden' : ''}`}>
              <label>Vị trí</label>
              <div className="input-group">
                <Controller
                  name="locations"
                  control={control}
                  render={({field})=><LocationSelect 
                    errors={errors} 
                    onChange={field.onChange} 
                    value={field.value}
                  />}
                />
              </div>
            </div>
            
            <div className={`col-lg-4 ${!adLanguageLocationOpenState ? 'hidden' : ''}`}>
              <div className="input-group">
                <label>Ngôn ngữ</label>
                  <Controller
                    name="languages"
                    control={control}
                    render={({field})=><LanguageField 
                      onChange={field.onChange}
                      value={field.value}
                      errors={errors}
                    />}
                  />
              </div>
            </div>
            {             
            !adLanguageLocationOpenState
            && errors && (errors.locations || errors.languages) && <>{iconSvg}<ErrorInput text={collapsedErrorMessage}/></>
            }
        </div>
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3 className="d-flex justify-content-between">Ngân sách & Giá thầu
              <div className="section-toogle cursor-pointer" onClick={() => {setAdBudgetOpenState(!adBudgetOpenState);}}>
                {!adBudgetOpenState ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>  
                : 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>
                }
              </div>
            </h3>
          </div>
          <div className={`col-lg-4 ${!adBudgetOpenState ? 'hidden' : ''}`}>
            <div className="input-group">
              <label>Nhập mức trung bình bạn muốn chi tiêu mỗi ngày</label>
              <div className="input-group__inner">
                <div className="input-group__prefix">đ</div>
                  <Controller
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <NumberFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        className="form-control"
                        placeholder="Nhập số tiền"
                        onValueChange={v => setValue('campaign_budget.amount', v.floatValue as never)}
                        defaultValue={value}
                      />
                    )}
                    name="campaign_budget.amount"
                    control={control}
                  />
              </div>
            </div>
            <div>
              {errors.campaign_budget?.amount && <ErrorInput text={errors?.campaign_budget?.amount.message}/>}
            </div>
            <input type="hidden" {...register('campaign_budget.name')} defaultValue={defaultValues['campaign_budget'].name}/>
            {renderMaxClick()}
            {renderMaxConversionValue()}
          </div>
          <div className={`col-lg-4 ${!adBudgetOpenState ? 'hidden' : ''}`}>
            <label>Chiến lược giá thầu</label>
            <div className="input-group">
              <Controller
                control={control}
                name="bidding_strategy_type"
                // defaultValue={/*defaultValues.bidding_strategy_type*/GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE}
                render={({field})=>
                  <select 
                    defaultValue={defaultValues.bidding_strategy_type} 
                    onChange={onBiddingStrategyTypeChanged}
                  >
                  <option value={GoogleAdsEnum.BiddingStrategyType.TARGET_SPEND}>Tối đa hóa số lượt click</option>
                  <option value={GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE}>Tối đa hoá giá trị chuyển đổi</option>
                </select>
              }
              />
            </div>
          </div>
          {
            !adBudgetOpenState
            && (
              (errors && (errors.campaign_budget || errors.bidding_strategy_type || errors?.bidding_strategy?.max_cpc) || errors?.maximize_conversion_value?.target_roas)
              || (customError && customError.code === CustomErrorCode.MaxConversionValueTargetRoas)
            )
            && <>{iconSvg}<ErrorInput text={collapsedErrorMessage}/></>
            }
        </div>
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3 className="d-flex justify-content-between">Độ tuổi & Thiết bị
              <div className="section-toogle cursor-pointer" onClick={() => {setAdAgeAndDeviceOpenState(!adAgeAndDeviceOpenState);}}>
                {!adAgeAndDeviceOpenState ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>  
                : 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>
                }
              </div>
            </h3>
          </div>
          <div className={`col-lg-8 ${!adAgeAndDeviceOpenState ? 'hidden' : ''}`}>
            <Controller
              name="ages"
              control={control}
              render={({field})=><AgeField 
                onChange={field.onChange} 
                value={field.value}
                errors={errors}
              />}
            />
            <Controller
              name="devices"
              control={control}
              render={({field})=><DeviceField 
                onChange={field.onChange} 
                value={field.value}
                errors={errors}
              />}
            />
          </div>
          {
            !adAgeAndDeviceOpenState
            && (
              (errors && (errors.devices || errors.ages))
              // || (customError && customError.code === CustomErrorCode.)
            )
            && <>{iconSvg}<ErrorInput text={collapsedErrorMessage}/></>
          }
        </div>
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3 className="d-flex justify-content-between">Mở rộng quảng cáo
              <div className="section-toogle cursor-pointer" onClick={() => {setAdExtendOpenState(!adExtendOpenState);}}>
                {!adExtendOpenState ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>  
                : 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)"/> </g> </g> </g> </g> </svg>
                }
              </div>
            </h3>
          </div>
          <div className={`col-lg-8 ${!adExtendOpenState ? 'hidden' : ''}`}>
            <Controller 
              control={control}
              name="site_link"
              render={({field})=><SitelinkComponent
                sitelinksApi={sitelinksApi}
                handleExtensionAction={(i, a)=>handleExtensionAction(ExtensionType.SiteLink, i, a)}
                onChange={field.onChange}
                value={field.value}
                errors={errors}
              />}
              />
            <Controller 
              control={control}
              name="call"
              render={({field})=><CallComponent
                handleExtensionAction={(i, a)=>handleExtensionAction(ExtensionType.Call, i, a)}
                calls={callsApi}
                onChange={field.onChange}
                value={field.value}
                errors={errors}
              />}
            />
          </div>
          {             
            !adExtendOpenState
            && (
              (errors && (errors.site_link || errors.call))
              // || (customError && customError.code === CustomErrorCode.)
            )
            && <>{iconSvg}<ErrorInput text={collapsedErrorMessage}/></>
          }
        </div>{/*row row-seperate*/}
      </div>{/*container overflow-visible*/}
    </div>{/*page-content*/}
    <div className="container bottom-bar">
      <div className="row">
        <div className="col-lg-10 offset-lg-1 display-flex">
          <SubmitButton isDisabled={false} onClick={handleSubmit(submit)}>{props.stepSettings === 4 ? 'Xuất bản chiến dịch' : 'Lưu và Tiếp tục'}</SubmitButton>
        </div>
      </div>
    </div>
    </div>
  );
}
