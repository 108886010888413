import React, { useState,useMemo, useEffect } from 'react';
import 'stylesheets/App.css';
import { useAdGroupAd, useGoogleForm, useComponentCall } from 'utils/hooks';
import { TemplateAdsType } from '../Settings';
import SubmitButton from 'buttons/SubmitButton';
import { WatchData } from '.';
import { 
  Ad,
  AdGroupAd, 
  AppState, 
  ComponentListService, 
  GoogleAdsEnum,
  ICampaignData,
  KeywordData,
  KeywordGroup
} from 'core';
import { useSelector } from 'react-redux';
import { FormProvider, useFormContext, useFieldArray, FieldArrayWithId } from 'react-hook-form';
import { TextAd, ResponsiveAd, customModalStyles, ErrorInput, adsType } from '../fields';
import { LoadingScreen } from 'components/common/LoadingScreen';
import ConfirmButton from 'components/buttons/ConfirmButton';
import { TrashIcon, ExpandCollapseIcon, CollapseError } from 'components/icons/SVGIcon';
import classNames from 'classnames';
import { SPECS } from './validate';

export interface TemplateAdsFormState { 
  adgroupad: AdGroupAdExtends[];
}
interface ListAdsChain{
  [n: number]: (number | undefined)[];
}
export interface AdsChain{
  next: boolean;
  prev: boolean;
  hasChain: boolean;
  list: ListAdsChain;
  index: number;
}
export interface AdGroupAdExtends {
  adGroupAd: AdGroupAd;
  keywordGroup: KeywordGroup | undefined;
}
export interface CollapseState {
  state: (number | null)[]; 
  isExpandingGroup: boolean;
  setStateGroupAd: () => void;
  setExpandingAd: () => void;
  // setStateAd: () => void;
}
export interface FieldArrayState {
  index: number; 
  meta: {
    adGroup: CollapseState;
    componentListService: ComponentListService[]; 
  };
  action: {
    handleRemove: () => void;
  };
  chain: AdsChain | null;
  value: AdGroupAdExtends;
  url: {origin: string; hostname: string};
}

export interface GroupedField {
  value: AdGroupAdExtends;
  flatIndex: number;
}

function FieldArray({index, ...props}:  FieldArrayState) {
  const { watch, setValue, reset, ...methods } = useFormContext<TemplateAdsFormState>();
  const adgroupad = watch('adgroupad');
  
  const handleChangeType = (value: never) => {
    try {
      const list = props.chain?.list;
      const defaultHeadline = SPECS[parseInt(value)].defaultHeadline.value;
      const defaultDesc = SPECS[parseInt(value)].defaultDesc.value;

      list?.[index].map((id: number) => {
        setValue(`adgroupad.${id}.adGroupAd.ad.type` as const, parseInt(value) as never);
        setValue(`adgroupad.${id}.adGroupAd.ad.headlines` as const, new Array(defaultHeadline).fill('') as never);
        setValue(`adgroupad.${id}.adGroupAd.ad.descriptions` as const, new Array(defaultDesc).fill('') as never);
      });
      reset(watch() as never);
    } catch (err) {
      console.error(err);
    }
  };
  
  
  return <React.Fragment key={'template-ads' + index}>
      {+adgroupad[index]?.adGroupAd.ad.type == GoogleAdsEnum.AdType.EXPANDED_TEXT_AD ? <TextAd
        url={props.url}
        index={index}
        defaultValue={props.value}
        handleChangeType={handleChangeType}
        {...props.action}
        {...props.meta}
        chain={props.chain}
      /> : <ResponsiveAd
        url={props.url}
        index={index}
        defaultValue={props.value}
        handleChangeType={handleChangeType}
        {...props.action}
        {...props.meta}
        chain={props.chain}
      />}

    </React.Fragment>;
}

export function TemplateAdsForm(props: TemplateAdsType) {
  const [loading, setLoading] = useState(true);
  const [componentListService, setComponentListService] = useState<ComponentListService[]>();
  const { createOrUpdateAdGroupAd, useForm, vestResolver, 
    TemplateAdsSchema, GoogleCampaignClass, lpPublished } = useGoogleForm();
  const [ showGroup, setShowGroup ] = useState<(number | null)[]>([0]);

  const adGroupAds = useSelector<any, AdGroupAd[] | null>((state: AppState) => state.adsCampaign.AdGroupAds);
  const adGroups = useSelector<any, KeywordData | null>((state: AppState) => state.adsCampaign.AdGroups); 
  const currentCampaign = useSelector<any, ICampaignData | null>((state: AppState) => state.adsCampaign.currentCampaign);
  const [expandingGroup, setExpandingGroup] = useState<(string | undefined)>('');
  const [url, setUrl] = useState({
    hostname: '',
    origin: ''
  });
  
  useMemo(()=>{
    if(!lpPublished || !lpPublished.url) return;
    const appDomain = lpPublished.url;
    const appDomainOrigin = appDomain.startsWith('http') ? appDomain : '//' + appDomain;
    const url = new URL(appDomainOrigin, window.location.origin);
    setUrl({
      hostname: url.hostname,
      origin: url.toString()
    });
    
  }, [lpPublished]);

  const generateDisplayUrl = (category: string): string[] | undefined => {
    if(!category) return;
    const c = category.toLowerCase();
    if(c === 'vị trí') return ['vị_trí', 'dự_án'];
    if(c === 'mặt bằng') return ['mặt_bằng', 'lô_đẹp'];
    if(c === 'chính sách') return ['chính_sách', 'ưu_đãi_nhất'];
    
    return;
  };
  
  const getAdGroupAdExtends = (): AdGroupAdExtends[] => {
    const getNotCreatedAdGroupAds = (needCreateResponsiveSearchAd: boolean): AdGroupAd[] => {
      let notExistedAdGroups: KeywordGroup[] = [];
      if (adGroups) {
        if (adGroupAds) {
          notExistedAdGroups = adGroups.keyword_groups.filter(x => !adGroupAds.some(y => y.ad_group_resource_name === x.resource_name));
          
        } else {
          notExistedAdGroups = adGroups.keyword_groups;
        }
      }

      return notExistedAdGroups.map((x, index) => {
        const adGroupAd = new AdGroupAd();
        adGroupAd.ad_group_resource_name = x?.resource_name ? x.resource_name : '';
        adGroupAd.ad = new Ad();
        adGroupAd.ad.type = index === 0 && needCreateResponsiveSearchAd ? GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD : GoogleAdsEnum.AdType.EXPANDED_TEXT_AD;
        adGroupAd.ad.paths = generateDisplayUrl(x.name) || ['','']; //needs for updating the paths in case user switch to text_ad from search_ad
      
        return adGroupAd;
      });
    };

    const createdAdGroupAds = adGroupAds ? adGroupAds : [];
    const notCreatedAdGroupAds = getNotCreatedAdGroupAds(createdAdGroupAds.length === 0);
    const defaultAdGroupAds = [...createdAdGroupAds, ...notCreatedAdGroupAds];
    const adGroupAdExs =  defaultAdGroupAds.map(x => {
      const adGroupAdEx: AdGroupAdExtends = {
        adGroupAd : x,
        keywordGroup : adGroups ? adGroups.keyword_groups.find(keywordGroup => keywordGroup?.resource_name === x.ad_group_resource_name) : undefined
      };
      return adGroupAdEx;
    });

    return adGroupAdExs;
  };

  const methods = useForm<TemplateAdsFormState>({
    resolver: vestResolver((data: TemplateAdsFormState)=>TemplateAdsSchema(data, {pname: lpPublished?.pname})),
    defaultValues: {
      adgroupad: []
    }
  });
  
  const errors = methods.formState.errors;

  const { fields, ...fieldMethods } = useFieldArray({
    control: methods.control,
    name: 'adgroupad',
  });
  const watchAll = methods.watch('adgroupad');

  useEffect(() => {
    const adgroupad = getAdGroupAdExtends();
    methods.setValue('adgroupad', adgroupad);
    // Set the first group to be expanding
    if (watchAll && watchAll.length > 0) {
      setExpandingGroup(watchAll[0].adGroupAd.ad_group_resource_name);
    }
  }, [adGroupAds, adGroups, methods.reset]);
  
  useEffect(() => {
    // This will select error that are not hidden
    const firstError = document.querySelector('.error-input:not(.hidden *)');
    firstError?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    // }, [errors, customError]); when add custom error
  }, [errors]);

  //to get visibility of adgroup we need to check showGroup array [index, 0] where index is the adgroup id
  //to get visibility of an ads in adgroup we need to check [0, index] where index is the adgroup id
  //please note that the adGroup is a flat array of ads, there's no id hierarchy.
  const handleGroupToggle = (indexGroup: number, chain: AdsChain | null) => {
    const [currentGroup] = showGroup as number[];
    if(currentGroup === indexGroup)
      setShowGroup([null]);
    else
      setShowGroup([indexGroup]);
  };

  const handleAdd = (position: number) => {
    const data = methods.getValues('adgroupad');
    const createAdGroupAdExtends = (): AdGroupAdExtends => {
      const item = data[position].keywordGroup;
      const keywordGroup = new KeywordGroup();
      keywordGroup.name = item?.name || 'Ad Group';
      keywordGroup.keywords = item?.keywords || [];
      keywordGroup.ages = item?.ages || [];
      keywordGroup.resource_name = item?.resource_name || '';
      
      const aditem = data[position].adGroupAd;
      const adGroupAd = new AdGroupAd();
      adGroupAd.ad_group_resource_name = aditem.ad_group_resource_name;
      adGroupAd.ad = new Ad();
      adGroupAd.ad.final_url = aditem.ad.final_url || '';
      adGroupAd.ad.paths = aditem.ad.paths.length > 0 ? aditem.ad.paths : ['', ''];
      // adGroupAd.ad.type = GoogleAdsEnum.AdType.EXPANDED_TEXT_AD; //REM-2349
      if (aditem.ad.type === GoogleAdsEnum.AdType.EXPANDED_TEXT_AD) {
        adGroupAd.ad.final_url = aditem.ad.final_url;
      }
      adGroupAd.ad.type = aditem.ad.type;
      adGroupAd.ad.operation = aditem.ad.operation;
      
      const newAdGroupAd: AdGroupAdExtends = {
        adGroupAd,
        keywordGroup
      };
      
      return newAdGroupAd;
    };
    
    const newItem = createAdGroupAdExtends();
    // if(!data.length || position === (data.length - 1)){
    if (!data.length) {
      data.splice(position, 0, newItem);
    } else {
      data.splice(position + 1, 0, newItem);
    }

    methods.setValue('adgroupad', data);
  };
  
  const handleRemove = (id: number) => {
    const adGroupAds = methods.getValues('adgroupad');
    adGroupAds.splice(id, 1);
    methods.setValue('adgroupad', adGroupAds);
  };
  
  const handleRemoveGroup = (id: number) => {
    let adGroupAds = methods.getValues('adgroupad');
    const firstGroup = adGroupAds[id];
    adGroupAds = adGroupAds.filter(v => v.adGroupAd.ad_group_resource_name !== firstGroup.adGroupAd.ad_group_resource_name);
    methods.setValue('adgroupad', adGroupAds);
  };

  const submit = (data: TemplateAdsFormState) => {
    const params = {
      inputParams: {
        AdGroupAds: data.adgroupad.filter(x =>{return !!x;}).map(x => {
          const adGroupAd = new AdGroupAd();
          adGroupAd.ad_group_resource_name = x.adGroupAd.ad_group_resource_name;
          adGroupAd.operation = x.adGroupAd.operation;
          adGroupAd.resource_name = x.adGroupAd.resource_name;
          adGroupAd.ad = new Ad();
          adGroupAd.ad.descriptions = x.adGroupAd.ad.descriptions;
          adGroupAd.ad.headlines = x.adGroupAd.ad.headlines;
          adGroupAd.ad.final_url = x.adGroupAd.ad.final_url;
          adGroupAd.ad.paths = x.adGroupAd.ad.paths.length > 0 ? x.adGroupAd.ad.paths : ['',''];
          adGroupAd.ad.type = +x.adGroupAd.ad.type;
          adGroupAd.ad.operation = x.adGroupAd.ad.operation;
          adGroupAd.ad.resource_name = x.adGroupAd.ad.resource_name;

          return adGroupAd;
        })
      },
      callBackParams: {
        successCallback: (res: any) => {
          setLoading(false);
          props.onSaveSuccess();
        },
        errorCallback: (err: any) => {
          setLoading(false);
          props.onError(err);
        }
      }
    };
    setLoading(true);
    createOrUpdateAdGroupAd(params);
  };
  
  const handleNextStep = () => {
    props.onSaveSuccess();
  };

  useAdGroupAd({
    onSuccess: (data: any) => {
      setLoading(false);
    },
    campaignResourceName: currentCampaign?.resource_name ?  currentCampaign.resource_name : ''
  });
  
  useComponentCall({
    onSuccess: (data: ComponentListService[]) => {
      setComponentListService(data);
    },
    landing_page_id: currentCampaign?.landing_page_id ? currentCampaign.landing_page_id : 0,
  });
  
  // const list: (KeywordGroup | undefined)[] = fields.map((el: AdGroupAdExtends)=>el.keywordGroup);
  const listAdsChain = (ads: AdGroupAdExtends, index: number): ListAdsChain =>{
    const isEqual = (el: AdGroupAdExtends): boolean => {
      return el.adGroupAd.ad_group_resource_name === ads.adGroupAd.ad_group_resource_name; // && el.keywordGroup?.name.trim() === keyword?.name?.trim() && el.keywordGroup?.ages?.length === keyword?.ages?.length;
    };
    const arr = watchAll
    // .filter(el=>el.adGroupAd.ad.type == GoogleAdsEnum.AdType.EXPANDED_TEXT_AD)
    // .map((el: AdGroupAdExtends)=>el.keywordGroup)
    .map((el: AdGroupAdExtends, id: number)=>{
      return ads.keywordGroup && isEqual(el) ? id : undefined;
    }).filter(el=>el !== undefined);
    return {[index]: arr};
  };

//generate a group of text ads from any position
  //eg: {1: [], 2: [2,3,4]} means the ads id 2,3,4 in the same group
  const validateChain = (index: number): AdsChain | null => {
    const ads = watchAll[index];

    const list = listAdsChain(ads, index);
    const next = list[index]?.includes(index + 1);
    const prev = list[index]?.includes(index - 1);
    const hasChain = list[index]?.length > 1;
    return {
      next,
      prev,
      index,
      list,
      hasChain
    };
  };
  // From flat array to array of groups
  const createGroupedFields = (fields: AdGroupAdExtends[]): GroupedField[][] => {
    const groupedFields: GroupedField[][] = [];
    let currentResource = '';
    const currentGroup: GroupedField[] = [];
    for (let i = 0; i < fields.length; i++) {
      const fieldItem = fields[i];
      if (fieldItem.adGroupAd.ad_group_resource_name !== currentResource) {
        currentResource = fieldItem.adGroupAd.ad_group_resource_name;
        // Push the previous group so we can start a new one (only if there is something in the previous group)
        if (currentGroup.length > 0) {
          groupedFields.push(currentGroup.splice(0));
        }
        //Push the first ad in
        if (currentGroup.length === 0) {
          currentGroup.push({ value: fieldItem, flatIndex: i },);
        }
      } else {
        currentGroup.push({ value: fieldItem, flatIndex: i },);
      }
    }
    // Push the last group in
    if (currentGroup.length > 0) {
      groupedFields.push(currentGroup.splice(0));
    }
    return groupedFields;
  };

  const watchAllFilter = watchAll.map(el=>({adGroupAd: el?.adGroupAd, keywordGroup: el?.keywordGroup?.name}));
  return (<>
    <WatchData>
      <pre>
        {JSON.stringify(watchAllFilter, null, 4)}
      </pre>
    </WatchData>
    {loading && <LoadingScreen />}
    <div className={`page-content create-ads-form ${loading ? 'hidden' : ''}`}>
      <div className="container overflow-visible">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="warning-text">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#8E8E93" fillRule="nonzero"> <g> <g> <g> <path d="M7 0c3.875 0 7 3.125 7 7s-3.125 7-7 7-7-3.125-7-7 3.125-7 7-7zm.426 5.833h-1.77c-.104 0-.17.017-.227.047-.057.03-.102.075-.132.132-.03.058-.047.123-.047.227v.227c0 .09.008.122.022.157.015.034.037.062.067.085.03.022.059.037.146.06l.58.144c.13.033.175.055.22.09.043.032.077.076.098.126.023.052.034.1.034.235v2.19c0 .135-.011.184-.034.235-.021.05-.055.094-.099.127-.045.034-.089.057-.22.09l-.58.144c-.086.023-.116.037-.145.06-.03.023-.052.051-.067.085-.014.034-.022.067-.022.156v.228c0 .104.016.17.047.226.03.057.075.102.132.133.057.03.123.046.226.046h2.981c.104 0 .17-.016.227-.046.056-.03.102-.076.132-.133.03-.057.047-.122.047-.226v-.228c0-.09-.008-.121-.022-.156-.015-.034-.037-.062-.067-.085-.03-.023-.059-.037-.146-.06l-.58-.144c-.13-.033-.175-.056-.22-.09-.043-.033-.077-.076-.099-.127-.022-.051-.033-.1-.033-.235V6.282c0-.156-.016-.213-.047-.27-.03-.056-.075-.102-.132-.132-.057-.03-.114-.047-.27-.047zM7 2.625c-.642 0-1.167.525-1.167 1.167 0 .641.525 1.166 1.167 1.166s1.167-.525 1.167-1.166c0-.642-.525-1.167-1.167-1.167z" transform="translate(-163 -113) translate(155 94) translate(8 8) translate(0 11)"/> </g> </g> </g> </g> </g> </svg>
              Mỗi nhóm quảng cáo tương ứng với một bộ từ khoá. Để thêm mới nhóm quảng cáo, vui lòng thêm mới bộ từ khoá ở bước trước.
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          {createGroupedFields(fields).map((groupedFields, groupIndex) => {
            const firstField = groupedFields[0].value;
            const firstFieldIndex = groupedFields[0].flatIndex;
            const outsideChain = validateChain(firstFieldIndex);
            const groupResourceName = firstField.adGroupAd.ad_group_resource_name;
            const isExpandingGroup = (expandingGroup === groupResourceName);
            const keywordGroupName = firstField.keywordGroup?.name;
            return (
              <div className="row row-separate-padding" key={groupResourceName} style={{ borderTop: groupIndex === 0 ? 'none' : 'solid 1px #d8d8d8' }}>
                <div className="col-lg-2 offset-lg-1">
                  <h4 className="d-flex justify-content-between">Nhóm QC
                    <div className="section-toogle cursor-pointer"
                      onClick={() => {
                        setExpandingGroup(prev => (prev === groupResourceName) ? '' : groupResourceName);
                        if (isExpandingGroup) {
                          handleGroupToggle(null as never, outsideChain);
                        } else {
                          handleGroupToggle(firstFieldIndex, outsideChain);
                        }
                      }}>
                      <ExpandCollapseIcon state={isExpandingGroup} largeIcon />
                    </div>
                  </h4>
                  <h3>{firstField.keywordGroup?.name}</h3>
                  {isExpandingGroup &&
                    <>
                      <label>Bộ từ khoá</label>
                      <p className="keyword-text">{keywordGroupName}</p>
                    </>
                  }
                  {groupIndex !== 0 &&
                    <ConfirmButton
                      customButtonStyle={'delete-keywords'}
                      cancelButtonClassName={'btn-subsidiary'}
                      onClick={() => handleRemoveGroup(firstFieldIndex)}
                      customStyles={customModalStyles}
                    confirmTitle={'Tiếp tục'}
                      cancelTitle={'Hủy'}
                      comfirmMessage={<div className="transfer-modal__container">
                        <h3 className="text-center">Xác nhận</h3>
                        <div className="transfer-modal__content">
                          Bạn đang thực hiện Xoá nhóm quảng cáo <span>{keywordGroupName}</span>. Thao tác này sẽ đồng thời xoá nhóm từ khoá tương ứng với nhóm quảng cáo này.
                        </div>
                      </div>}
                    >
                      <TrashIcon />
                    </ConfirmButton>}
                </div>
                <div className={classNames('col-lg-8', { 'hidden': !isExpandingGroup })} >
                  {groupedFields.map(groupedField => {
                    const fieldItem = groupedField.value;
                    const index = groupedField.flatIndex;
                    const chain = validateChain(index);
                    return <React.Fragment key={groupIndex + ' ' + index + JSON.stringify(fieldItem.adGroupAd.ad)}>
                      <FieldArray
                        meta={{
                          adGroup: {
                            state: showGroup,
                            isExpandingGroup: isExpandingGroup,
                            setStateGroupAd: () => handleGroupToggle(index, chain),
                            setExpandingAd: () => {
                              setExpandingGroup(prev => (prev === fieldItem.adGroupAd.ad_group_resource_name) ? prev : fieldItem.adGroupAd.ad_group_resource_name);
                              handleGroupToggle(index, chain);
                            }
                          },
                          componentListService: componentListService || [],
                        }}
                        action={{
                          handleRemove: () => handleRemove(index),
                        }}
                        chain={chain}
                        url={url}
                        index={index}
                        value={fieldItem} />
                    </React.Fragment>;
                  })}
                  {/* Check if outer group is not collapsed & satisfy limit according to ads type */}
                  {(isExpandingGroup
                    && (
                      (firstField.adGroupAd.ad.type === GoogleAdsEnum.AdType.EXPANDED_TEXT_AD)
                      || (firstField.adGroupAd.ad.type === GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD && groupedFields.length < 3)
                    ))
                    && <div className="row">
                      <div className="add-extend-link col-lg-12" onClick={() => handleAdd(groupedFields[groupedFields.length - 1].flatIndex)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M6.997 16C10.833 16 14 12.832 14 9s-3.174-7-7.01-7C3.16 2 0 5.168 0 9s3.167 7 6.997 7zm0-1.395c-3.106 0-5.59-2.498-5.59-5.605S3.891 3.401 6.99 3.401c3.106 0 5.603 2.492 5.61 5.599.006 3.107-2.498 5.605-5.603 5.605zm-.014-2.572c.4 0 .643-.27.643-.69V9.65h1.813c.406 0 .69-.244.69-.636 0-.4-.27-.644-.69-.644H7.626v-1.8c0-.42-.244-.69-.643-.69-.392 0-.63.283-.63.69v1.8H4.548c-.42 0-.697.244-.697.644 0 .392.291.636.697.636h1.807v1.692c0 .407.237.69.63.69z" transform="translate(-365 -1109) translate(355 754) translate(0 48) translate(10 305)" /> </g> </g> </g> </g> </g> </svg>
                        <span>Thêm quảng cáo mới</span>
                      </div>
                    </div>}
                </div>
                <div className={classNames('col-lg-8', 'row', { 'hidden': isExpandingGroup })}>
                  <div className='col-lg-4'>
                    <div className="input-group">
                      <h4>Bộ từ khoá</h4>
                      <div className="keyword-key">
                        {keywordGroupName}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className="input-group">
                      <h4>URL hiển thị</h4>
                      <div className="keyword-key">
                        {url.origin}
                      </div>
                    </div>
                  </div>
                  {adsType[firstField.adGroupAd.ad.type] && <div className='col-lg-4'>
                    <div className="input-group">
                      <h4>Loại QC</h4>
                      <div className="keyword-key">
                        {adsType[firstField.adGroupAd.ad.type]}
                      </div>
                    </div>
                  </div>}
                  {(errors.adgroupad && groupedFields.some(field => !!errors?.adgroupad?.[field.flatIndex])) ? <div className='col-lg-12'>
                    <div className="input-group">
                      <span><CollapseError /></span><ErrorInput style={{ marginBottom: 0 }} text="Cần khắc phục vấn đề thiết lập quảng cáo" />
                    </div>
                  </div> : null}
                </div>
              </div>
            );
          })
          }
        </FormProvider>

      </div>{/*container overflow-visible*/}
    </div>{/*page-content create-ads-form*/}
    
    <div className={`container bottom-bar ${loading ? 'hidden' : ''}`}>
      <div className="row">
        <div className="col-lg-10 offset-lg-1 display-flex">
          <SubmitButton isDisabled={false} onClick={methods.handleSubmit(submit)}>{props.stepSettings === 4 ? 'Xuất bản chiến dịch' : 'Lưu và Tiếp tục'}</SubmitButton>
        </div>
      </div>
    </div>
    </>
  );
}

