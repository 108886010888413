"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkSpaceService = void 0;
const types_1 = require("../types");
const ApiAction_1 = require("../../actions/ApiAction");
const frameworks_1 = require("../../frameworks");
const index_1 = require("../../customer/useCases/index");
class WorkSpaceService {
    constructor() {
        const state = frameworks_1.store.getState();
        const user = state['user'].user;
        this._user = user;
        this._auth = state['auth'];
    }
    getTeam(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.GetTeamAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    getTeamFlow(getTeamParams, getTeamMemberParams) {
        const params = Object.assign(Object.assign({}, getTeamParams.inputParams), { callBackParams: Object.assign(Object.assign({}, getTeamParams.callBackParams), { successCallback: (res) => {
                    getTeamParams.callBackParams && getTeamParams.callBackParams.successCallback && getTeamParams.callBackParams.successCallback(res);
                    this.getTeamMemberList(getTeamMemberParams);
                } }) });
        this.getTeam(params);
    }
    getTeamState() {
        const state = frameworks_1.store.getState();
        const team = state.team.selectedTeam;
        return team;
    }
    getTeamList(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.GetTeamListAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    createTeam(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.CreateTeamAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        return apiAction.triggerAction();
    }
    createTeamFlow(createTeamParams, getTeamParams) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.CreateTeamAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, createTeamParams);
        Promise
            .resolve(apiAction.triggerAction())
            .then((response) => {
            createTeamParams.callBackParams
                && createTeamParams.callBackParams.successCallback
                && createTeamParams.callBackParams.successCallback(response);
            this.getTeamList();
            this.getTeam({
                inputParams: {
                    team_id: response.team && response.team.id || null
                },
                callBackParams: {
                    successCallback: (res) => {
                        getTeamParams.callBackParams
                            && getTeamParams.callBackParams.successCallback
                            && getTeamParams.callBackParams.successCallback(Object.assign(Object.assign({}, res.selectedTeam), response));
                    },
                    errorCallback: (err) => {
                        console.log(err);
                        getTeamParams.callBackParams
                            && getTeamParams.callBackParams.errorCallback
                            && getTeamParams.callBackParams.errorCallback(err);
                    }
                }
            });
        })
            .catch((err) => {
            console.log('catch error', err);
            createTeamParams.callBackParams
                && createTeamParams.callBackParams.errorCallback
                && createTeamParams.callBackParams.errorCallback(err);
        });
    }
    updateTeam(params) {
        const team = this.getTeamState();
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.UpdateTeamAction.type;
        const newParams = Object.assign(Object.assign({}, params), { inputParams: Object.assign(Object.assign({}, params.inputParams), { id: team && team.team && team.team.id }) });
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, newParams);
        apiAction.dispatchAction();
    }
    leaveTeam(params) {
        if (this.getTeamState()) {
            const asyncFunc = ApiAction_1.FuncType.Async;
            const type = types_1.LeaveTeamAction.type;
            const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
            Promise.resolve(apiAction.triggerAction()).then((response) => {
                params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(response);
                this.getTeamMemberList();
            }).catch((err) => {
                params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
            });
        }
        else {
            params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback("Selected Team is empty");
        }
    }
    inviteTeam(params) {
        const team = this.getTeamState();
        const teamId = team && team.team.id;
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.InviteTeamAction.type;
        const newParams = Object.assign(Object.assign({}, params), { inputParams: Object.assign({ team_id: teamId }, params.inputParams) });
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, newParams);
        Promise.resolve(apiAction.triggerAction()).then((response) => {
            params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(response);
            this.getTeamList();
        }).catch((err) => {
            params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
        });
    }
    inviteTeamFlow(params, gotoTeamParams) {
        const team = this.getTeamState();
        const teamId = team && team.team.id;
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.InviteTeamAction.type;
        const newParams = Object.assign(Object.assign({}, params), { inputParams: Object.assign({ team_id: teamId }, params.inputParams) });
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, newParams);
        Promise.resolve(apiAction.triggerAction()).then((response) => {
            params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(response);
            this.gotoTeam({
                inputParams: Object.assign({}, gotoTeamParams.inputParams),
                callBackParams: {
                    successCallback: (res) => {
                        gotoTeamParams.callBackParams
                            && gotoTeamParams.callBackParams.successCallback
                            && gotoTeamParams.callBackParams.successCallback(res);
                    },
                    errorCallback: (err) => {
                        console.log(err);
                        gotoTeamParams.callBackParams
                            && gotoTeamParams.callBackParams.errorCallback
                            && gotoTeamParams.callBackParams.errorCallback(err);
                    }
                }
            });
            this.getTeamList();
        }).catch((err) => {
            params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
        });
    }
    cancelInviteTeam(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.InviteTeamAction.cancel;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        Promise.resolve(apiAction.triggerAction()).then((response) => {
            params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(response);
            this.getTeamMemberList();
        }).catch((err) => {
            params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
        });
    }
    joinTeam(joinTeamParams, gotoTeamParams) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.JoinTeamAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, joinTeamParams);
        Promise.resolve(apiAction.triggerAction()).then((res) => {
            joinTeamParams.callBackParams && joinTeamParams.callBackParams.successCallback && joinTeamParams.callBackParams.successCallback(res);
            const gotoTeamCustomParams = {
                inputParams: { team_id: res.data.team.id },
                callBackParams: Object.assign({}, gotoTeamParams.callBackParams)
            };
            this.gotoTeam(gotoTeamCustomParams);
        }).catch((err) => {
            console.log(err);
            joinTeamParams.callBackParams && joinTeamParams.callBackParams.errorCallback && joinTeamParams.callBackParams.errorCallback(err);
        });
    }
    gotoTeam(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.GoToTeamAction.type;
        if (params.inputParams.token === 'master') {
            params = Object.assign(Object.assign({}, params), { inputParams: Object.assign(Object.assign({}, params.inputParams), { token: this._auth.masterUserToken }) });
        }
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        Promise.resolve(apiAction.triggerAction()).then((res) => {
            params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(res);
        }).catch((err) => {
            console.log('gotoTeam', err);
            params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
        });
    }
    getTeamMemberList(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.TeamMembersAction.type;
        if (!params)
            params = {
                inputParams: {},
                callBackParams: {}
            };
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        const team = this.getTeamState();
        const teamId = team && team.team && team.team.id;
        if (teamId)
            apiAction.dispatchAction();
    }
    transferRole(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiAction = new ApiAction_1.ApiAction(types_1.TransferRoleAction.type, ApiAction_1.FuncType.Async, params);
            Promise.resolve(apiAction.triggerAction()).then((res) => {
                const team = this.getTeamState();
                const reloadTeamParams = {
                    inputParams: {
                        team_id: team && team.team && team.team.id || undefined
                    },
                    callBackParams: {
                        successCallback: (res) => {
                            let getCustomer = new ApiAction_1.ApiAction(index_1.getCustomers().type, ApiAction_1.FuncType.Async);
                            getCustomer.triggerAction();
                        }
                    }
                };
                this.gotoTeam(reloadTeamParams);
                this.getTeamList();
                this.getTeamMemberList();
                params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(res);
            }).catch((err) => {
                params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
            });
        });
    }
    // sequence(fns: DispatchAction[]) {
    //   return fns.reduce((promise: any, fn: DispatchAction) => promise.then(() => fn), Promise.resolve());
    // }
    // transferFlow(transferFlowParams: any){
    //   const team = this.getTeamState();
    //   const reloadTeamParams: GoToTeamParameter = {
    //     ...transferFlowParams.reloadTeamParams,
    //     inputParams: {
    //       team_id: team.team.id
    //     },
    //   }
    //   this.sequence([this.transferRole(transferFlowParams[0])])
    // }
    getTeamIntro(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.GetTeamIntroAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
}
exports.WorkSpaceService = WorkSpaceService;
