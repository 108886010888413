"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeLPListAfterDeleteProject = exports.copyLandingPage = exports.updateLandingPage = exports.updateLandingPageStatus = exports.unpublishLandingPage = exports.createLandingPage = exports.getTemplateLandingPage = exports.getLandingPage = void 0;
const types_1 = require("../types");
const types_2 = require("../types");
__exportStar(require("./LandingPageService"), exports);
const getLandingPage = () => {
    return {
        type: types_2.GET_LANDING_PAGES
    };
};
exports.getLandingPage = getLandingPage;
const getTemplateLandingPage = () => {
    return {
        type: types_2.GET_TEMPLATE_LANDING_PAGES
    };
};
exports.getTemplateLandingPage = getTemplateLandingPage;
const createLandingPage = (parameter) => {
    return {
        type: types_2.CREATE_LANDING_PAGE_REQUEST,
        parameter: parameter,
        meta: {
            thunk: true
        }
    };
};
exports.createLandingPage = createLandingPage;
const unpublishLandingPage = (landingPageId) => {
    return {
        type: types_2.UNPUBLISH_LANDING_PAGE_REQUEST,
        landingPageId: landingPageId
    };
};
exports.unpublishLandingPage = unpublishLandingPage;
const updateLandingPageStatus = (parameter) => {
    return {
        type: types_2.UPDATE_LANDING_PAGE_STATUS_REQUEST,
        parameter: parameter
    };
};
exports.updateLandingPageStatus = updateLandingPageStatus;
const updateLandingPage = (data) => {
    return {
        type: types_1.UPDATE_LANDING_PAGE,
        data
    };
};
exports.updateLandingPage = updateLandingPage;
const copyLandingPage = (parameters) => {
    return {
        type: types_2.COPY_LANDING_PAGE_REQUEST,
        parameters
    };
};
exports.copyLandingPage = copyLandingPage;
const removeLPListAfterDeleteProject = (projectId) => {
    return {
        type: types_2.REMOVE_LP_LIST_AFTER_DELETE_PROJECT,
        projectId
    };
};
exports.removeLPListAfterDeleteProject = removeLPListAfterDeleteProject;
