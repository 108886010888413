import React, { useState, useEffect } from 'react';
import { FieldErrors } from 'react-hook-form';
import { ILocation, 
  ILocationCreate,
  GetLocationAdsParams, 
  GoogleService
} from 'core';
import numeral from 'numeral';
import CreatableSelect from 'react-select/creatable';
import { customStyles2 } from './LanguageField';
import { useGoogleForm } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { ErrorInput } from './ErrorInput';

type PropsType = {
  onChange: (value?: ILocationCreate[]) => void;
  value?: ILocationCreate[];
  errors?: FieldErrors;
}
type OptionType = { 
  label: string; 
  value: string;
  location: ILocationCreate;
  __isNew__?: boolean;
};

export const LocationSelect: React.FC<PropsType> = (props) => {
  const locations = useSelector((state) => state.adsCampaign.locations);
  const { defaultLocation } = useGoogleForm();
  const vietnam: ILocation = defaultLocation.vn;

  const transformOutput = (data?: ILocation[]): OptionType[] => {
    if(!data) return [];
    return data.map(el=>({
      label: el.geo_target_constant.name + `${el.reach ? ' (' + numeral(el.reach).format('0,0') + ' tiếp cận)' : ''}`,
      value: el.geo_target_constant.resource_name,
      location: {
        geo_target_constant: el.geo_target_constant.resource_name
      }
    } as OptionType));
  };

  const convertLocationToOptionType = (locations: ILocation[] | null): OptionType[] => {
    if (!locations || locations.length === 0) {
      return transformOutput([vietnam]);
    }

    return transformOutput(locations);
  };

  const [valueArrayInput, setValueArrayInput] = useState<OptionType[]>(convertLocationToOptionType(locations));
  const [selectedLocations, setSelectedLocations] = useState<OptionType[]>([]);
  
  //We don't use Country criterions for Location anymore
  //AdsCriterion.country.map(el=>({label: el.country, value: el.code}));
  //We're using List of Vietnam cities insteads, fetching by Location service
  // let options: OptionType[] = [];
  
  const transformInput = (data: OptionType[]): ILocationCreate[] => {
    return data?.map(el=>{
      return el.location;
    });
  };
  
  const service = new GoogleService();
  useEffect(()=>{
    if (locations) {
      setValueArrayInput(convertLocationToOptionType(locations)); 
      return;
    }
    const params: GetLocationAdsParams = {
      inputParams: {},
    };
      
    service.getLocationAds(params);
  }, [locations]);

  useEffect(() => {
    setSelectedLocations(getSelectedLocations());
  }, [props.value, valueArrayInput]);
  
  const isVietnam = selectedLocations.some((x) => {return x.value === vietnam.geo_target_constant.resource_name;});

  const getSelectedLocations = (): OptionType[] => {
    if (!props.value) {
      return [];
    }

    return props.value.map((x) => {
      if(!x) return {
        label: 'No value',
        location: {
          geo_target_constant: '',
        },
        value: 'novalue'
      } as OptionType;
      const foundLocation = valueArrayInput.find((location) => {return location.value === x.geo_target_constant;});

      const optionType: OptionType = {
        label: foundLocation ? foundLocation.label : x.geo_target_constant,
        value: x.geo_target_constant,
        location: x
      };
      return optionType;
    });
  };

  const changeLocation = (value: string) => {
    if(value === 'locationOther'){
      props.onChange(undefined);
    } else {
      const option = {
        label: vietnam.geo_target_constant.resource_name, 
        value: vietnam.geo_target_constant.resource_name,
        location: {
          geo_target_constant: vietnam.geo_target_constant.resource_name
        }
      };
      const vietnamLocation = transformInput([option]);
      props.onChange(vietnamLocation);
    }
  };

  const onChange = (value: OptionType[], actionMeta: any) => {
    if (value && value.find((v) => {return (v.__isNew__ === true || v.value === 'novalue');}) !== undefined) return;
    props.onChange(transformInput(value));
  };

  return <>
    <div className="form-inline w-100 mt-2">
      <div className="form-check">
        <input
          id="location-vn"
          type="radio"
          name="targetLocation"
          value={'locationVN'}
          onChange={() => changeLocation('vi')}
          checked={isVietnam}
        />
        <label className="radio-label ml-2 mr-2" htmlFor="location-vn"> Việt Nam</label>
      </div>
    </div>
    <div className="form-inline w-100 mt-2 align-items-start">
      <div className="form-check mt-3">
        <input
          id="location-other"
          type="radio"
          name="targetLocation"
          value={'locationOther'}
          onChange={() => changeLocation('locationOther')}
          checked={!isVietnam}
        />
        <label className="radio-label ml-2 mr-2" htmlFor="location-other"> Khác</label>
      </div>
      <CreatableSelect
        value={isVietnam ? undefined : selectedLocations}
        options={valueArrayInput}
        formatCreateLabel={(label: string) => {return 'Không tìm thấy vị trí';}}
        isClearable
        border={props.errors?.locations ? '1px solid red' : '1px solid #d8d8d8'}
        isMulti
        styles={customStyles2}
        onChange={onChange}
        placeholder="Nhập vị trí để nhắm mục tiêu"
      />
    </div>
    {props.errors?.locations && <ErrorInput text={props.errors.locations.message}/>}
  </>;
};