import React, { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { AdsCriterion } from '../../criterion';
import { ILanguages, GoogleAdsEnum } from 'core';
import { ErrorInput } from './ErrorInput';

type PropsType = {
  onChange: (value: ILanguages[]) => void;
  value?: ILanguages[];
  errors: FieldErrors;
}
type OptionType = { 
  label: string; 
  value: GoogleAdsEnum.Languages;
  language: ILanguages;
  __isNew__?: boolean;
};


const components = {
  DropdownIndicator: null,
};

export const LanguageField: React.FC<PropsType> = (props) => {
  const [inputValueState, setInputValueState] = useState('');

  const getAllLanguages = (): OptionType[] => {
    return AdsCriterion.language.map((x) => {
      return {
        label: x.name, 
        value: x.id,
        language: {
          language_constant: x.id
        }
      };
    });
  };

  const options = getAllLanguages();
  
  const getDefaultValue = () => {
    const ret: OptionType[] = [];
    props.value?.map(el=>{
      if(el === undefined) return;
      const found = options.find(opt=>opt.value === el.language_constant);
      if(found) {
        ret.push({
          label: found.label,
          value: found.value,
          language: el
        });
      }
    });
    
    return ret;
  };
  const defaultValue = getDefaultValue();
  
  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValueState) return;

    if (event.key === 'Enter' || event.key === 'Tab') {
      // setNewValueArrayInput(inputValueState);
      // setInputValueState('');
    }
  };
  
  const enhancementData = (input: OptionType[]): ILanguages[]  => {
    if (input) return input.map((x) => {return x.language;});
    return [];
  };
  
  const onChange = (value: OptionType[], actionMeta: any) => {
    // setValueArrayInput(value);
    // console.group('Value Changed');
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    props.onChange(enhancementData(value));
  };

  // const onInputChange = (value: string) => {
  //   setInputValueState(value);
  //   //Have to create email tag once pressing ',' or space btn
  //   const isCommaOrSpacePress = value.includes(' ') || value.includes(',');
  //   const correctInput = value.replace(/[\s,]/g, '');
  //   if (isCommaOrSpacePress) {
  //     setNewValueArrayInput(correctInput);
  //     setInputValueState('');
  //   }
  // };

  // const onBlurInput = (e: any) => {
  //   const { value } = e.target;
  //   if (value) {
  //     setNewValueArrayInput(value);
  //   }
  // };

  // const createOption = (label: string) => ({
  //   label,
  //   value: label,
  // });

  // const setNewValueArrayInput = (value: string) => {
  //   let newValueArrayInput = valueArrayInput;

  //   // if(isIterable(newValueArrayInput)) {
  //   //   newValueArrayInput = [...valueArrayInput, createOption(value)];
  //   // }
  //   // else {
  //   newValueArrayInput = [createOption(value)];
  //   // }
  //   setValueArrayInput(newValueArrayInput);
    
  // };
  
  return <><CreatableSelect
    // components={components}
    defaultValue={defaultValue}
    options={options}
    formatCreateLabel={(label: string) => {return null;}}
    // inputValue={inputValueState}
    isClearable
    isMulti
    styles={customStyles}
    border={props.errors.languages ? '1px solid red' : '1px solid #d8d8d8'}
    // menuIsOpen={false}
    onChange={onChange}
    // onInputChange={onInputChange}
    onKeyDown={onKeyDown}
    placeholder="+ Tìm kiếm ngôn ngữ"
    // value={valueArrayInput}
    // onBlur={onBlurInput}
  />
  {props.errors.languages && <ErrorInput text={props.errors.languages.message}/>}
  </>;
};

export const customStyles = {
  container: () => ({
    width: '100%',
  }),
  control: (provided: object, state: any) => ({
    ...provided,
    minHeight: 'auto',
    backgroundColor: '#f5f4f9',
    // border: '',
    borderRadius: '6px',
    marginTop: '8px',
    border: state.selectProps.border
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    padding: '4px 8px',
  }),
  input: (provided: object) => ({
    ...provided,
    margin: 0,
    fontFamily: 'Propins-Regular',
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: -0.32,
    color: '#000000'
  }),
  multiValue: (provided: object) => ({
    ...provided,
    padding: '3px 8px',
    backgroundColor: '#4c7cf3',
    borderRadius: 13
  }),
  multiValueLabel: (provided: object) => ({
    ...provided,
    color: '#ffffff',
    fontSize: 14,
    padding: 0
  }),
  placeholder: (provided: object) => ({
    ...provided,
    fontFamily: 'Propins-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.28,
    color: '#8e8e93'
  }),
};

export const customStyles2 = {
  ...customStyles,
  container: () => ({
    width: 'auto',
    flex: 1
  }),
  multiValue: (provided: object) => ({
    ...provided,
    padding: '3px 8px',
    backgroundColor: 'transparent',
    borderRadius: 13
  }),
  multiValueLabel: (provided: object) => ({
    ...provided,
    color: '#000000',
    fontSize: 14,
    padding: 0
  }),
};