import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'stylesheets/App.css';
import {
  CommonSettings,
  KeywordAdsForm,
  TemplateAdsForm,
  ReviewAdsForm,
} from './forms';
import {toast} from 'react-toastify';
import { GoogleService, GoogleApiErrorHandlerService, ApiRequestError } from 'core';
interface PropsType {
}

interface GoogleSettingAdsBaseType{
  onSaveSuccess: (gotoStep?: number) => void;
  onError: (err?: any) => void;
  onSaveDraffSuccess: () => void;
  stepSettings: number;
  backToHistory: boolean;
}

export type CommonSettingsType = GoogleSettingAdsBaseType
export type KeywordAdsType = GoogleSettingAdsBaseType
export type ReviewAdsType = GoogleSettingAdsBaseType
export type TemplateAdsType = GoogleSettingAdsBaseType

const GoogleAdsSettings: React.FC<PropsType> = (props) => {
  const history = (globalThis as any).useHistory;
  history.hook();
  const [stepSettings, setStepSettings] = useState(0);
  const [backToHistory, setBackToHistory] = useState(false);
  const goBackHistory = () => {
    if(stepSettings === 0) {
      history.push('/create-google-campaign');
    }
    else 
      setStepSettings((stepSettings - 1) % 4);
  };

  const onStartGoBackHistory = () => {
    if (stepSettings === 0) {
      setBackToHistory(true);
      return;
    }
    goBackHistory();
  };

  const goToDashboard = () => {
    const service = new GoogleService();
    service.startCreatingNewCampaign();
    history.push('/google-ads-dashboard');
  };

  const changeStepSettings = (nextStep?: number) => {
    const step = nextStep ? nextStep : stepSettings + 1;
    setStepSettings(step % 4);
  };

  const handleError = (err: ApiRequestError) => {
    toast.error(GoogleApiErrorHandlerService.getErrorMessage(err).message);
  };

  const onSaveDraffSuccess = () => {
    setBackToHistory(false);
    goBackHistory();
  };

  const renderSettingsList = () => {
    const props = {
      onSaveSuccess: changeStepSettings,
      onError: handleError,
      onSaveDraffSuccess: onSaveDraffSuccess,
      stepSettings,
      backToHistory: backToHistory
    };

    switch (stepSettings) {
      case 0:
        return <CommonSettings {...props}/>;
      case 1:
        return <KeywordAdsForm {...props}/>;
      case 2:
        return <TemplateAdsForm {...props}/>;
      case 3:
        return <ReviewAdsForm {...props}/>;
      default:
        return <CommonSettings {...props}/>;
    }
  };
  const renderIcon = (i: number) => {
    if(stepSettings > i)
      return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#FFF"> <g> <g> <g> <path d="M0 0H16V16H0z" opacity=".01" transform="translate(-363 -26) translate(319.5 20) translate(37.5) translate(6 6)"/> <path d="M6.637 13.83c-.378 0-.664-.138-.922-.45l-3.43-4.213C2.08 8.93 2 8.703 2 8.464c0-.55.405-.948.968-.948.332 0 .571.126.79.398l2.852 3.595 5.586-8.836c.232-.371.47-.504.849-.504.557 0 .955.392.955.935 0 .206-.066.425-.219.664l-6.209 9.579c-.219.325-.53.484-.935.484z" transform="translate(-363 -26) translate(319.5 20) translate(37.5) translate(6 6)"/> </g> </g> </g> </g> </g> </svg>;
    else
      return i + 1;
  };
  
  return (
    <Styles>
      <div className="googleads-settings-page">
        <div className="top-bar">
          <div className="quit-button cursor-pointer" onClick={goToDashboard}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g> <g> <path fill="#FFF" d="M0 0H20V20H0z" opacity=".01" transform="translate(-55 -24) translate(55 24)"/> <path fill="#000" fillRule="nonzero" d="M16.67 16.671c.437-.445.437-1.174.008-1.602l-5.064-5.065 5.064-5.073c.429-.428.437-1.165-.008-1.602-.446-.437-1.174-.437-1.603-.009l-5.064 5.065L4.93 3.32c-.412-.42-1.166-.437-1.603.009C2.89 3.766 2.9 4.52 3.32 4.93l5.064 5.073L3.32 15.07c-.42.42-.437 1.165.008 1.602.437.437 1.191.429 1.603.017l5.073-5.073 5.064 5.065c.429.428 1.166.428 1.603-.009z" transform="translate(-55 -24) translate(55 24)"/> </g> </g> </g> </svg>
            Thoát
          </div>
          <div className="back-button cursor-pointer" onClick={onStartGoBackHistory}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g> <g> <path fill="#FFF" d="M0 0H20V20H0z" opacity=".01" transform="translate(-55 -24) translate(55 24)"/> <g fill="#000"> <path d="M1.67 10.103l-.887-.886C.55 8.982.417 8.664.417 8.332c0-.331.132-.65.366-.884l.875-.875 4.667-4.667L7.822.408 7.841.39l.025-.025c.177-.167.348-.267.525-.322.191-.058.388-.058.58 0 .191.059.375.171.569.364l.05.051c.193.194.307.378.365.57.058.191.058.387 0 .579-.064.198-.175.378-.322.525l-6.2 6.201 6.2 6.2c.167.178.268.349.322.525.058.192.058.389 0 .58-.058.192-.172.375-.365.57l-.05.05c-.193.193-.377.306-.57.364-.19.059-.387.059-.578 0-.198-.064-.378-.174-.525-.322l-6.198-6.196h.001z" transform="translate(-55 -24) translate(55 24) translate(5 1.667)"/> </g> </g> </g> </g> </svg>
            Quay lại
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="step-bar">
                  <div className="step-bar__fillbar" style={{width: stepSettings * 25 + '%'}}></div>
                  <div className={`${stepSettings >= 0 ? 'active' : ''} step-bar__item`}>
                    <div className="step-bar__item--index">{renderIcon(0)}</div>
                    Thiết lập chung
                  </div>
                  <div className={`${stepSettings >= 1 ? 'active' : ''} step-bar__item`}>
                    <div className="step-bar__item--index">{renderIcon(1)}</div>
                    Từ khóa
                  </div>
                  <div className={`${stepSettings >= 2 ? 'active' : ''} step-bar__item`}>
                    <div className="step-bar__item--index">{renderIcon(2)}</div>
                    Mẫu quảng cáo
                  </div>
                  <div className={`${stepSettings >= 3 ? 'active' : ''} step-bar__item`}>
                    <div className="step-bar__item--index">{renderIcon(3)}</div>
                    Xem lại
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderSettingsList()}
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background-color: #f5f4f9;
  min-height: 100vh;
  overflow: hidden;
  h3 {
    font-family: Propins-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    color: #000000;
  }
  .keywordads-form {
    .keyword-rows .delete-row-action {
      display: none;
    }
    .keyword-rows:hover .delete-row-action {
      display: flex;
      vertical-align: middle;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
    }
    h3 {
      text-decoration: underline;
    }
    .delete-keywords {
      background: none;
      padding: 0;
      border: 0;
      cursor: pointer;
      margin-top: 8px;
      font-family: Propins-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.28px;
      color: #ff3b30;
      &.fullwidth{
        width: inherit !important;
        margin-top: 0;
      }
    }
    .keyword-key {
      font-family: Propins-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.28px;
      color: #000000;
      padding: 7px 15px;
      border-radius: 6px;
      border: solid 1px #d8d8d8;
      margin-top: 8px;
      width: 100%;
      @media (max-width: 1380px) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .add-extend-link {
      padding: 0;
      cursor: pointer;
      border-top: 0px;
    }
  }
  .create-ads-form {
    .form-control {
      padding-right: 48px;
      margin-bottom: 8px;
    }
    .form-control.error{
      border: solid red 1px;
    }
    .link-add-another {
      margin-top: 0;
      margin-bottom: 20px;
    }
    .keyword-text {
      margin-bottom: 4px
    }
    .keyword-key {
      font-family: Propins-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.28px;
      color: #000000;
      margin-bottom: 8px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .delete-keywords {
      background: none;
      padding: 0;
      border: 0;
      cursor: pointer;
      margin-top: 8px;
      font-family: Propins-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.28px;
      color: #ff3b30;
      
      &.fullwidth{
        width: inherit !important;
        margin-top: 0;
      }
    }
    .add-extend-link {
      padding-bottom: 0px;
      padding-left: 20px;
    }
  }
  .review-ads-form {
    .keyword-key {
      font-family: Propins-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.28px;
      color: #000000;
      margin-bottom: 8px;
      &.big-text {
        font-size: 18px;
      }
    }
  }
  .label-blue {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #4c7cf3;
    text-decoration: underline;
  }
  h4 {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #666666;
  }
  label {
    font-family: Propins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #666666;
  }
  label.radio-label {
    margin-top: 2px;
  }
  select {
    background: #f5f4f9;
    width: 100%;
    border-radius: 6px;
    padding: 8px 15px;
    border-color: #d8d8d8;
    font-family: Propins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000
  }
  select.auto-width-select {
    width: auto;
    margin-left: 8px;
  }
  .form-description {
    font-family: Propins-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #666666;
  }
  .form-check-input {
    margin-top: 2px;
  }
  .form-check-label {
    cursor: pointer;
    color: #000000;
    user-select: none;
  }
  .row-seperate {
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 0px;
  }
  .row-seperate--blur {
    border-bottom: solid 1px rgba(216, 216, 216, 0.5);
    padding: 8px 0px;
  }
  .row-separate-border{
    border-bottom: 1px solid #d8d8d8;
  }
  .row-separate-padding{
    padding: 20px 0;
  }
  .step-bar {
    margin-top: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .top-bar {
    padding-bottom: 10px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  }
  .top-bar .back-button {
    display: flex;
    align-items: center;
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
    position: absolute;
    top: 24px;
    left: 141px;
  }
  .top-bar .quit-button {
    display: flex;
    align-items: center;
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
    position: absolute;
    top: 24px;
    left: 55px;
  }
  .step-bar__fillbar {
    position: absolute;
    height: 4px;
    left: 14%;
    top: 11px;
    background-color: #4c7cf3;
  }
  .bottom-bar {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .extend-item {
    padding: 10px;
    position: relative;
  }
  .preview-list .extend-item {
    margin-right: 80px;
  }
  .link-add-another {
    margin-top: 12px;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #4c7cf3;
  }
  .add-extend-link {
    user-select: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: solid 1px #d8d8d8;
    padding: 12px 10px;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #4c7cf3;
  }
  .add-extend-link span {
    cursor: pointer;
    margin-left: 8px;
    user-select: none;
  }
  .extends-list-wrapper.errors{
    border: solid red 1px;
    margin-bottom: 10px;
  }
  .extends-list-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
  }
  .step-bar::before {
    content: '';
    position: absolute;
    height: 4px;
    top: 11px;
    left: 14%;
    width: 76%;
    background-color: #d8d8d8;
  }
  .step-bar__item {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #aeaeb2;
  }
  .step-bar__item.active {
    color: #000000;
    font-family: Propins-Medium;
  }
  .step-bar__item--index {
    background-color: #d8d8d8;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    z-index: 1;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #8e8e93;
  }
  .step-bar__item.active .step-bar__item--index {
    background-color: #4c7cf3;
    color: #ffffff;
  }
  .form-control {
    border-color: #d8d8d8;
    border-radius: 6px!important;
    background-color: transparent;
  }
  .form-control.error{
    border: solid red 1px !important;
  }
  .extends-list-wrapper h4 {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000;
  }
  .extends-list-wrapper p {
    margin-bottom: 5px;
    font-family: Propins-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #666666;
  }
  .settings-list, .preview-list {
    width: 50%;
    max-height: 360px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .preview-list {
    border-left: solid 1px #d8d8d8;
  }
  .preview-list__label {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 43px;
    padding: 15px;
    background-color: rgba(216, 216, 216, 0.5);
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    text-align: center;
    color: #4c7cf3;
  }
  .item-action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
  }
  .item-action a {
    margin-right: 12px;
  }
  .extend-item:hover .item-action {
    display: block;
  }
  .schedules-inputs label {
    margin-top: 0;
    margin-left: 12px;
    margin-right: 8px;
    border-left: 1px solid #d8d8d8;
    padding-left: 8px;
  }
  .schedules-inputs .delete-link {
    margin-left: 16px;
  }
  .schedules-inputs select {
    border: 0;
  }
  .schedules-inputs .schedules-inputs__wrapper {
    border-radius: 6px;
    border: solid 1px #d8d8d8;
    display: flex;
  }
  .input-group__inner {
    width: 100%;
    position: relative;
  }
  .input-group__inner input {
    padding-right: 32px;
  }
  .input-group__prefix {
    position: absolute;
    right: 18px;
    top: 12px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    text-align: right;
    color: #8e8e93;
  }
  .url-overfollow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .warning-text {
    margin-top: 10px;
    padding: 8px;
    border-radius: 1px;
    background-color: rgba(216, 216, 216, 0.5);
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #666666;
    display: flex;
    align-items: center;
  }
  .warning-text svg {
    margin-right: 8px;
  }
  .previewBox {
    margin-top: 20px;
    padding: 15px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #666666;
    .project-name {
      font-family: Propins-Regular;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.24px;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      strong {
        font-family: Propins-Bold;
      }
    }
    .ad-title {
      margin-top: 8px;
      margin-bottom: 12px;
      font-family: Propins-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.36px;
      color: #4c7cf3;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media (max-width: 1380px) {
        margin-top: 4px;
        margin-bottom: 6px;
      }
    }
    .ad-description {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .desktop-preview, .mobile-preview {
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
  }
  .mobile-preview {
    margin-bottom: 20px;
    padding-top: 52px;
    padding-left: 32px;
    padding-right: 32px;
    min-height: 136px;
    box-shadow: none;
    background: url(${process.env.REACT_APP_TEMPLATE_CDN_URL}/mobile-preview.png) no-repeat;
    background-size: 100%;
    background-position: center top;
  }
  .previewBox-title {
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000;
    border-bottom: 2px solid #4c7cf3;
    margin-bottom: 15px;
  }
  .ad-template__title {
    display: flex;
    margin-bottom: 12px;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
  }
  .section-toogle {
    margin-left: 6px;
  }
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }
  .react-datepicker__input-container input {
    width: 100%;
    border-radius: 6px;
    padding: 8px 15px;
    background-color: #f5f4f9;
    border: solid 1px #d8d8d8;
  }
  .text-field--input-counter {
    position: absolute;
    right: 6px;
    bottom: 12px;
    font-family: Propins-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: right;
    color: #666666;
  }
  @media (max-width: 1599px) {
    .mobile-preview {
      padding-top: 36px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .previewBox .ad-description {
      white-space: nowrap;
    }
  }
  @media (max-width: 1199px) {
    .mobile-preview {
      padding-top: 30px;
      padding-left: 18px;
      padding-right: 18px;
    }
    .ad-title {
      margin-bottom: 0px!important
    }
  }
`;

export default GoogleAdsSettings;