import React, { Fragment, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import SubmitButton from 'buttons/SubmitButton';
import { toast } from 'react-toastify';
import { isIterable } from '../../utils/typeGuard';
import {
  AppState,
  ProjectService,
  CreateProjectParams, CreateProjectSuccessParams,
  UpdateProjectParams,
  CreateProjectFlowParams,
  UploadImageOnCreateParams,
  Project,
  ProjectTypes,
} from 'core';
import { throttle } from 'lodash';
import {
  ProjectDesignForm,
  ProjectFurnitureForm,
  ProjectGroundForm,
  ProjectInfoForm,
  ProjectLocationForm,
  ProjectModelHouseForm,
  ProjectPolicyForm,
  ProjectProgressForm,
  ProjectUploadImageForm,
  ProjectUtilitesForm,
} from 'pages/project/forms';

import 'react-tabs/style/react-tabs.css';
import 'react-toastify/dist/ReactToastify.css';
import 'stylesheets/App.css';
import 'stylesheets/Tabs.css';
import { History } from 'src/route';
import { useRouter } from 'utils/hooks';

type ProjectTabsPropsType = {
  isEdit: boolean;
  isClone: boolean;
  project?: Project;
  fromChooseProject?: boolean;
  projectType: ProjectTypes;
};

ProjectTabs.defaultProps = {
  isEdit: false,
  isClone: false,
};
interface ProjectParams {
  id: number;
}

export function ProjectTabs(props: ProjectTabsPropsType): React.ReactElement {
  const router = useRouter();
  const { id: projectId } = router.query as ProjectParams;
  const buttonsGroupRef = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [buttonsHeight, setButtonsHeight] = useState(0);
  const [stateProject, setStateProject] = useState(props.project);
  const [stateImages, setStateImages] = useState([]);
  const [isShowFixedPanel, setIsShowFixedPanel] = useState(false);
  const [isClickedOnTheButton, setIsClickedOnTheButton] = useState(false);
  const [updateProject, setUpdateProject] = useState(false);
  const projectReducer = useSelector((state: AppState) => state.projects);
  const service = new ProjectService();


  const projectImages = isIterable(projectReducer.selectedImages) 
  ? [...projectReducer.projectImages, ...projectReducer.selectedImages] 
  : [...projectReducer.projectImages];

  const isProjectHaveImages = projectImages.length > 0;
  const { isProjectInfoValid, isProjectLocationValid, isPaymentPolicyValid, isUtilityValid } = validateInfo(
    stateProject,
    props.projectType
  );

  useEffect(() => {
    setStateProject(props.project);
  }, [props.project]);

  useEffect(() => {
    const id = projectId && (props.isEdit || props.isClone) ? projectId : undefined;
    service.getProjectImage({inputParams:{projectId: id}});
  }, [projectId, props.isEdit, props.isClone]);

  useEffect(() => {
    service.updateSelectedImage({inputParams: {selectedImages: stateImages}});
  }, [stateImages]);

  useEffect(() => {
    const updateButtonsGroupHeight = () => {
      if (buttonsGroupRef.current) {
        setButtonsHeight(buttonsGroupRef.current.clientHeight);
      }
    };

    updateButtonsGroupHeight();
    const updateButtonsGroupHeightThrottle = throttle(updateButtonsGroupHeight, 300);
    window.addEventListener('resize', updateButtonsGroupHeightThrottle);

    return () => {
      window.removeEventListener('resize', updateButtonsGroupHeightThrottle);
    };
  }, []);

  useEffect(() => {
    const toggleFixedPanel = () => {
      const notHasScroll = document.documentElement.scrollHeight <= window.innerHeight;
      const isButtonsGroupVisible =
        document.documentElement.scrollHeight - document.documentElement.scrollTop <
        document.documentElement.clientHeight + buttonsHeight;
      if (notHasScroll || isButtonsGroupVisible) {
        setIsShowFixedPanel(false);
      } else {
        setIsShowFixedPanel(true);
      }
    };

    toggleFixedPanel();
    const toggleFixedPanelThrottle = throttle(toggleFixedPanel, 300);
    window.addEventListener('scroll', toggleFixedPanelThrottle);

    return () => {
      window.removeEventListener('scroll', toggleFixedPanelThrottle);
    };
  }, [buttonsHeight, tabIndex]);

  const updateFieldsOfStateProject = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const editingProject: Project = Object.assign({}, stateProject, { [name]: value });
    setStateProject(editingProject);
  };

  const renderProjectApartmentTabs = () => {
    return (
      <React.Fragment>
        <TabList>
          <Tab className={`${!isProjectInfoValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tổng quan<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isProjectLocationValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Vị trí dự án<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isUtilityValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tiện ích<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isPaymentPolicyValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Chính sách<span className="label-required"> *</span>
          </Tab>
          <Tab>Mặt bằng</Tab>
          <Tab>Nhà mẫu</Tab>
          <Tab>Tiến độ</Tab>
          <Tab className={`${!isProjectHaveImages && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tải ảnh<span className={'label-required'}> *</span>
          </Tab>
        </TabList>
        <TabPanel>
          <ProjectInfoForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectLocationForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUtilitesForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectPolicyForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectGroundForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectModelHouseForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectProgressForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUploadImageForm
            projectType={props.projectType}
            project={stateProject}
            isEdit={props.isEdit}
            stateImages={stateImages}
            setStateImages={setStateImages}
          />
        </TabPanel>
      </React.Fragment>
    );
  };

  const renderProjectShophouseVillaTabs = () => {
    return (
      <React.Fragment>
        <TabList>
          <Tab className={`${!isProjectInfoValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tổng quan<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isProjectLocationValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Vị trí dự án<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isPaymentPolicyValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Chính sách<span className="label-required"> *</span>
          </Tab>
          <Tab>Thiết kế</Tab>
          <Tab>Nội thất</Tab>
          <Tab>Tiến độ</Tab>
          <Tab className={`${!isProjectHaveImages && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tải ảnh<span className={'label-required'}> *</span>
          </Tab>
        </TabList>
        <TabPanel>
          <ProjectInfoForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectLocationForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectPolicyForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectDesignForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectFurnitureForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectProgressForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUploadImageForm
            projectType={props.projectType}
            project={stateProject}
            isEdit={props.isEdit}
            stateImages={stateImages}
            setStateImages={setStateImages}
          />
        </TabPanel>
      </React.Fragment>
    );
  };

  const renderProjectCondotelTabs = () => {
    return (
      <Fragment>
        <TabList>
          <Tab className={`${!isProjectInfoValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tổng quan<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isProjectLocationValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Vị trí dự án<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isUtilityValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tiện ích - Quần thể<span className="label-required"> *</span>
          </Tab>
          <Tab>Thiết kế</Tab>
          <Tab className={`${!isPaymentPolicyValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Chính sách<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isProjectHaveImages && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tải ảnh<span className={'label-required'}> *</span>
          </Tab>
        </TabList>
        <TabPanel>
          <ProjectInfoForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectLocationForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUtilitesForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectDesignForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectPolicyForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUploadImageForm
            projectType={props.projectType}
            project={stateProject}
            isEdit={props.isEdit}
            stateImages={stateImages}
            setStateImages={setStateImages}
          />
        </TabPanel>
      </Fragment>
    );
  };

  const renderProjectLandTabs = () => {
    return (
      <Fragment>
        <TabList>
          <Tab className={`${!isProjectInfoValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tổng quan<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isProjectLocationValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Vị trí dự án<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isUtilityValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tiện ích<span className="label-required"> *</span>
          </Tab>
          <Tab>Mặt bằng</Tab>
          <Tab className={`${!isPaymentPolicyValid && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Chính sách<span className="label-required"> *</span>
          </Tab>
          <Tab className={`${!isProjectHaveImages && isClickedOnTheButton ? 'tab-invalid' : ''} react-tabs__tab`}>
            Tải ảnh<span className={'label-required'}> *</span>
          </Tab>
        </TabList>
        <TabPanel>
          <ProjectInfoForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectLocationForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUtilitesForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectGroundForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectPolicyForm
            projectType={props.projectType}
            project={stateProject}
            handleInputChange={updateFieldsOfStateProject}
          />
        </TabPanel>
        <TabPanel>
          <ProjectUploadImageForm
            projectType={props.projectType}
            project={stateProject}
            isEdit={props.isEdit}
            stateImages={stateImages}
            setStateImages={setStateImages}
          />
        </TabPanel>
      </Fragment>
    );
  };

  const renderTabList = () => {
    switch (props.projectType) {
      case ProjectTypes.APARTMENT:
        return renderProjectApartmentTabs();
      case ProjectTypes.SHOPHOUSE_VILLA:
        return renderProjectShophouseVillaTabs();
      case ProjectTypes.CONDOTEL:
        return renderProjectCondotelTabs();
      case ProjectTypes.LAND:
        return renderProjectLandTabs();
      default:
        return renderProjectApartmentTabs();
    }
  };

  return (
    <Fragment>
    <Prompt when={!updateProject && stateProject && stateProject.is_owner} message={(location: {pathname: string}, action: string) => {
      return action !== 'REPLACE' && !location.pathname.startsWith((globalThis as any).useHistory.getPath('/create-landingpage'))
        ? 'Các chỉnh sửa chưa được lưu lại sẽ bị mất. Bạn có muốn rời trang?'
        : true;
    }}
    />
    <div className="container overflow-visible">
      <Tabs key={stateProject ? stateProject.id : undefined} tabIndex={tabIndex} onSelect={(i) => setTabIndex(i)}>
        {renderTabList()}
        <div className="col-12 button-group" ref={buttonsGroupRef}>
          <SubmitProjectButton
            {...props}
            setUpdateProject={setUpdateProject}
            stateProject={stateProject}
            isProjectHaveImages={isProjectHaveImages}
            onClick={() => setIsClickedOnTheButton(true)}
          />
        </div>
      </Tabs>
      {isShowFixedPanel && (
        <div className={'panel-fixed-bottom button-group'}>
          <SubmitProjectButton
            {...props}
            setUpdateProject={setUpdateProject}
            stateProject={stateProject}
            isProjectHaveImages={isProjectHaveImages}
            onClick={() => setIsClickedOnTheButton(true)}
          />
        </div>
      )}
    </div>
    </Fragment>
  );
}

type SubmitProjectButtonPropsType = ProjectTabsPropsType & {
  stateProject: Project | undefined;
  isProjectHaveImages: boolean;
  setUpdateProject: (res: any) => void;
  onClick: any;
};

function validateInfo(project: Project | undefined, projectType: ProjectTypes): Record<string, any> {
  if (!project)
    return {
      isProjectInfoValid: false,
      isProjectLocationValid: false,
      isPaymentPolicyValid: false,
      isUtilityValid: false,
      isUserAllowed: false
    };

  const res = {
    isProjectInfoValid: !!project.name && !!project.investor && !!project.location,
    isProjectLocationValid: !!project.detail_location,
    isPaymentPolicyValid: !!project.payment_policy,
  };

  if (projectType === ProjectTypes.SHOPHOUSE_VILLA) {
    return res;
  }
  return { ...res, isUtilityValid: !!project.utility, isUserAllowed: project.is_owner };
}

interface ProjectParams {
  id: number;
}

function SubmitProjectButton(props: SubmitProjectButtonPropsType): React.ReactElement {
  const [createdProjectId, setCreatedProjectId] = React.useState<number | null>(null);
  const dispatch = useDispatch();
  const history = new History();
  const router = useRouter();
  const { id: projectId } = router.query as ProjectParams;
  
  const isUploadingImage = useSelector((state: AppState) => state.projects.isUploadingImage);

  const { isEdit, isClone, fromChooseProject, stateProject, isProjectHaveImages, projectType, setUpdateProject } = props;
  const isProjectFulfilled = validateProject(stateProject, projectType) && isProjectHaveImages;
  const service = new ProjectService();
  
  React.useEffect(() => {
    if (isUploadingImage || !createdProjectId) return;
    if (isProjectFulfilled) {
      history.push({
        pathname: `/create-landingpage/${createdProjectId}`,
      });
      return;
    }
    history.push('/project-management');
  }, [history, createdProjectId, isUploadingImage, isProjectFulfilled]);

  const { isProjectInfoValid, isProjectLocationValid, isPaymentPolicyValid, isUtilityValid, isUserAllowed } = validateInfo(
    stateProject,
    props.projectType
  );

  const showMissingInfoNotification = () => {
    const arrErrors = [];
    if (!isProjectInfoValid) {
      arrErrors.push('Thông tin dự án');
    }
    if (!isProjectLocationValid) {
      arrErrors.push('Vị trí dự án');
    }
    if (!isPaymentPolicyValid) {
      arrErrors.push('Chính sách');
    }
    if (projectType !== ProjectTypes.SHOPHOUSE_VILLA && !isUtilityValid) {
      arrErrors.push('Tiện ích');
    }
    if (!isProjectHaveImages) {
      arrErrors.push('Tải ảnh');
    }

    toast.error(`Bạn cần điền đủ thông tin của: ${arrErrors.join(', ')}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCreateProject = () => {
    if (!isProjectFulfilled) {
      showMissingInfoNotification();
      return;
    }
    const projectTemplateId = isClone && projectId ? projectId : undefined;
    const newProject = Object.assign({}, stateProject, { type_code: projectType });
    const createParams: CreateProjectParams = {
      inputParams: {
        project: newProject,
        projectTemplateId
      },
      callBackParams: {
        successCallback: (res: any) => {
          setCreatedProjectId(res.project.id);
        },
        errorCallback: (error: any) => {
          console.error(`Create project: ${error}`);
          toast.error('Không thể tạo dự án');
        }
      }
    };

    const uploadParams: UploadImageOnCreateParams = {
      callBackParams: {
        errorCallback: (err: any) => {
          toast.error('Cập nhật ảnh bị lỗi.');
        }
      }
    };

    const params: CreateProjectFlowParams = {
      createParams,
      uploadParams
    };

    service.createProjectFlow(params);
  };

  const handleEditProject = (showNoti = true): void => {
    if (!stateProject) return;
    if (!isProjectFulfilled) {
      showMissingInfoNotification();
      return;
    }

    const params: UpdateProjectParams = {
      inputParams: {
        project: stateProject
      },
      callBackParams: {
        successCallback: (res: any) => {
          setUpdateProject(true);
          if (showNoti) {
            toast.success('Cập nhật thành công');
          }
          if (fromChooseProject) {
            history.push({
              pathname: `/create-landingpage/${stateProject.id}`
            });
            return;
          }
        },
        errorCallback: (error: any) => {
          console.error(`Edit project: ${error}`);
          alert('Edit project failed!');
        }
      }
    };
    service.updateProject(params);
  };

  if (isEdit) {

    // if(!stateProject || !stateProject.is_owner) return <div></div>;
  
    return (
      <React.Fragment>
        <SubmitButton
          activeClassName={fromChooseProject ? '' : 'btn-subsidiary'}
          onClick={() => {
            props.onClick();
            handleEditProject();
          }}
        >
          {fromChooseProject ? 'Lưu dự án và tạo landing page' : 'Cập nhật'}
        </SubmitButton>
        {!fromChooseProject && (
          <SubmitButton
            alignRight
            onClick={(): void => {
              props.onClick();
              handleEditProject(false);
              history.push({
                pathname: `/create-landingpage/${stateProject!.id}`
              });
            }}
            isDisabled={!isProjectFulfilled}
          >
            Tạo landing page mới
          </SubmitButton>
        )}
      </React.Fragment>
    );
  }

  if (isProjectFulfilled) {
    return (
      <SubmitButton
        onClick={() => {
          props.onClick();
          handleCreateProject();
        }}
      >
        Lưu dự án và tạo landing page
      </SubmitButton>
    );
  }

  return (
    <React.Fragment>
      <SubmitButton
        activeClassName="btn-subsidiary"
        onClick={() => {
          props.onClick();
          handleCreateProject();
        }}
      >
        Lưu dự án
      </SubmitButton>
      <SubmitButton
        alignRight
        onClick={() => {
          props.onClick();
          handleCreateProject();
        }}
      >
        Tạo landing page
      </SubmitButton>
    </React.Fragment>
  );
}

function validateProject(project: Project | undefined, projectType: ProjectTypes): boolean {
  if (!project) return false;

  switch (projectType) {
    case ProjectTypes.SHOPHOUSE_VILLA:
      return (
        !!project.name &&
        !!project.investor &&
        !!project.location &&
        !!project.detail_location &&
        !!project.payment_policy
      );
    default:
      return (
        !!project.name &&
        !!project.investor &&
        !!project.location &&
        !!project.detail_location &&
        !!project.payment_policy &&
        !!project.utility
      );
  }
}
