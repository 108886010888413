"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LANDING_PAGE_AD_ERROR = exports.GET_LANDING_PAGE_AD_SUCCESS = exports.GET_LANDING_PAGE_AD = exports.ExtractLandingPageData = exports.GET_LANDING_PAGE_DATA_ERROR = exports.GET_LANDING_PAGE_DATA_SUCCESS = exports.GET_LANDING_PAGE_DATA = exports.GET_USER_PUBLISHED_LANDING_PAGES_ERROR = exports.GET_USER_PUBLISHED_LANDING_PAGES_SUCCESS = exports.GET_USER_PUBLISHED_LANDING_PAGES = void 0;
exports.GET_USER_PUBLISHED_LANDING_PAGES = 'GET_USER_PUBLISHED_LANDING_PAGES';
exports.GET_USER_PUBLISHED_LANDING_PAGES_SUCCESS = 'GET_USER_PUBLISHED_LANDING_PAGES_SUCCESS';
exports.GET_USER_PUBLISHED_LANDING_PAGES_ERROR = 'GET_USER_PUBLISHED_LANDING_PAGES_ERROR';
exports.GET_LANDING_PAGE_DATA = 'GET_LANDING_PAGE_DATA';
exports.GET_LANDING_PAGE_DATA_SUCCESS = 'GET_LANDING_PAGE_DATA_SUCCESS';
exports.GET_LANDING_PAGE_DATA_ERROR = 'GET_LANDING_PAGE_DATA_ERROR';
class ExtractLandingPageData {
    constructor(header, policy, projectGround, projectLocation, utility) { }
}
exports.ExtractLandingPageData = ExtractLandingPageData;
exports.GET_LANDING_PAGE_AD = 'GET_LANDING_PAGE_AD';
exports.GET_LANDING_PAGE_AD_SUCCESS = 'GET_LANDING_PAGE_AD_SUCCESS';
exports.GET_LANDING_PAGE_AD_ERROR = 'GET_LANDING_PAGE_AD_ERROR';
