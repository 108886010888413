"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalSessionsInMinute = exports.getTotalSessionsByChannels = void 0;
const moment = require("moment");
const TIME_WITHIN = 30;
const getTotalSessionsByChannels = (data) => {
    if (!data || !data.length)
        return {};
    const now = moment(new Date());
    const detailDataByChannels = {};
    data.forEach(({ channel_id, last_event_at, page_close_at }) => {
        const pageClosedInMinutes = page_close_at === '0000-00-00 00:00:00'
            ? 0
            : now.diff(moment.utc(page_close_at), 'minutes');
        const lastEventInMinutes = now.diff(moment.utc(last_event_at), 'minutes');
        if (lastEventInMinutes <= TIME_WITHIN && !pageClosedInMinutes) {
            if (!detailDataByChannels[channel_id]) {
                detailDataByChannels[channel_id] = 0;
            }
            detailDataByChannels[channel_id] = detailDataByChannels[channel_id] + 1;
        }
    });
    return detailDataByChannels;
};
exports.getTotalSessionsByChannels = getTotalSessionsByChannels;
const getTotalSessionsInMinute = (data, minuteNo) => {
    if (!data || !data.length)
        return 0;
    let total = 0;
    const now = moment(new Date());
    data.forEach(({ last_event_at, page_close_at }) => {
        const pageClosedInMinutes = page_close_at === '0000-00-00 00:00:00'
            ? 0
            : now.diff(moment.utc(page_close_at), 'minutes');
        const lastEventInMinutes = now.diff(moment.utc(last_event_at), 'minutes');
        if (lastEventInMinutes - minuteNo <= TIME_WITHIN && minuteNo > pageClosedInMinutes)
            total++;
    });
    return total;
};
exports.getTotalSessionsInMinute = getTotalSessionsInMinute;
