import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  GoogleService,
  ConnectedAccount,
  GoogleAdsReportIP,
  GoogleAdsReportCallback,
  TeamPermissionService,
  CampaignStatus,
  CampaignColumnActive,
  LandingPageWithGoogleAdsCampaign,
  GoogleCampaignAds,
  GetLPWithGoogleAdsCampaignParams,
  GetLPWithGoogleAdsCampaignSuccessType,
  UpdateGoogleCampaignStatusCB,
  UpdateGoogleCampaignStatusParams,
  ErrorMessageService,
  AdsAccount
} from 'core';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { SideMenu, TopMenu } from 'pages/menu';
import WarningLimitedFeatureModal from 'pages/menu/modals/WarningLimitedFeatureModal';
import CampaignMetrics from './CampaignMetrics';
import RangeDatePicker from 'components/common/RangeDatePicker';
import 'stylesheets/App.css';
import { useFetchListProjects, useGoogleForm, useRouter, usePublishLP } from 'utils/hooks';
import { CampaignInfo, CampaignListReport } from 'core/dist/entities/GoogleAds/campaignReport';
import { LoadingScreen } from 'components/common/LoadingScreen';
import numeral from 'numeral';
import { getCampaignReport } from '../hooks/useCampaignReport';
import { WarningLimitAccountModal } from '../ConnectBoard/modals/WarningLimitAccountModal';
import { subDays } from 'date-fns';
import { WarningLandingPageRunningAds } from '../CreateGoogleCampaign/modals';
import { LocationDescriptor } from 'history';
import { toast } from 'react-toastify';

interface PropsType {
}

export interface CampaignUpdateInfo{
  landing_page_id: number | null;
  status: CampaignStatus | null;
  resource_name: string;
  connected_account_id: number;
}

export const listCampaignColumnActive = [CampaignColumnActive.ADSACCOUNT, CampaignColumnActive.COMPOSITESTATUS, CampaignColumnActive.METRICCOST, CampaignColumnActive.METRICIMPRESSIONS,
   CampaignColumnActive.METRICNUMCLICK, CampaignColumnActive.METRICNUMENGAGEMENT, CampaignColumnActive.METRICNUMCONVERSIONS, CampaignColumnActive.METRICEFFECTIVE].sort();


const CampaignMetricDashboard: React.FC<PropsType> = (props) => {
  const history = (globalThis as any).useHistory;
  history.hook();
  const query = useRouter().query as {lp_id: string; ad_account: string};
  const { lpSelected, changeCampaignStatus } = useGoogleForm();

  const [projectId, setProjectId] = useState<number>(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openCampaignStatusPopup, setOpenCampaignStatusPopup] = useState(false);
  const [openCampaignColumnActivePopup, setOpenCampaignColumnActivePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [openRangeTimePopup, setOpenRangeTimePopup] = useState(false);
  const [openWarningLimitAccountModal, setOpenWarningLimitAccountModal] = useState(false);
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);
  const [popover1Open, setPopover1Open] = useState(false);
  const [popover2Open, setPopover2Open] = useState(false);
  const [selectedConnectedAccounts, setSelectedConnectedAccounts] = useState<ConnectedAccount[]>([]);
  const listCampaignStatus = [
    CampaignStatus.ENABLED, 
    CampaignStatus.PAUSED, 
    CampaignStatus.DRAFT, 
    CampaignStatus.REMOVED
  ];
  const [selectedColumnActive, setSelectedColumnActive] = useState<CampaignColumnActive[]>([...listCampaignColumnActive]);
  const [selectedCampaignStatus, setSelectedCampaignStatus] = useState<CampaignStatus[]>([CampaignStatus.ENABLED, CampaignStatus.PAUSED]);
  const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 29));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState<CampaignUpdateInfo>();
  const {landingPages, checkUserExists, getResourceName} = usePublishLP();
  const [pendingLandingPage, setPendingLandingPage] = useState<any>({
    resourceNames: undefined,
    isReady: false,
  });
  
  //TODO: keep notification on disabling the campaign
  // const toastId = React.useRef(null);
  // const toastSuccess = (message: string) => {
  //   toastId.current = toast(message, {
  //     autoClose: true,
  //   });
  // };
  // const update = () => {
  //   toast.update(toastId.current, {
  //     type: toast.TYPE.SUCCESS,
  //     closeButton: null // The closeButton defined on ToastContainer will be used
  //   });
  // };
  
  const service = new GoogleService();

  const getSelectedCampaign = (): {accountIds: Set<number>; campaignName: Set<string>}  => {
    const {lp_id, ad_account} = query;
    if(!!lp_id && !!ad_account) setFirstTime(false);
    const selectLP = lpSelected(+lp_id);
    const listCampaign: Set<string> = new Set();
    const accountIds: Set<number> = new Set();

    selectLP?.google_campaigns.map((el: GoogleCampaignAds)=>{
      if(el.connected_account_id !== +ad_account){
        listCampaign.add(el.campaign_resource_name);
        accountIds.add(el.connected_account_id);
      }
    });
      
    return {accountIds, campaignName: listCampaign};
  }; 

  const projectState = useSelector(state => state.projects);
  const connectedAccountGroup: AdsAccount = useSelector(state => state.adsCampaign.connectedAccountGroup);
  const campaignReports = useSelector<any, CampaignListReport | null>(state => state.adsReport.campaignReports);
  const totalCost = campaignReports ? campaignReports.total_cost : 0;
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const [firstTime, setFirstTime] = useState<boolean>(true);
  useFetchListProjects(projectState);

  React.useEffect(() => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {},
        errorCallback: (err: any) => {
          console.log('onErrGG', err);
        }
      }
    };
    
    service.fetchConnectedAccount(params);
  }, []);

  React.useEffect(() => {
    const {lp_id, ad_account} = query;
      if (connectedAccountGroup && firstTime) { 
        setSelectedConnectedAccounts([...connectedAccountGroup.google, ...connectedAccountGroup.facebook]);
        
        //skip this query if addtitional params' used
        if(!lp_id && !ad_account) {
          queryReport();
        }
        setFirstTime(false);
      }
  }, [connectedAccountGroup]);

  React.useEffect(()=>{
    const {accountIds, campaignName} = getSelectedCampaign();
    if(accountIds.size > 0 || query.lp_id && query.ad_account) {
      const reportParams: GoogleAdsReportIP = {
        accountIds: Array.from(accountIds) || query.ad_account,
        landingPages: [+query.lp_id],
        statuses: [CampaignStatus.ENABLED]
      }; 
      const callBackParams: GoogleAdsReportCallback = {
        successCallback: (res: any) => {
          setLoading(false);
          const connectAdAcct = connectedAccountGroup.google.find((y) => {return y.id === +query.ad_account;});
          if(connectAdAcct) setSelectedConnectedAccounts([connectAdAcct]);
        },
        errorCallback: (err: any) => {
          setLoading(false);
        }
      };
      setLoading(true);
      getCampaignReport(reportParams, callBackParams);
    }

  }, [query, lpSelected]);

  const getResourceNameList = (successCallback: (res: any) => void) => {
    if(currentCampaign && currentCampaign.landing_page_id){
      const {connected_account_id, landing_page_id} = currentCampaign;
      // setLoadingUpdateStatus(true);

      const loadLandingPage: GetLPWithGoogleAdsCampaignParams = {
        inputParams: {
          landing_page_id
        },
        callBackParams: {
          successCallback,
          errorCallback: (err: any) => {
            // setLoadingUpdateStatus(false);
          }
        }
      };
      service.getLPWithGoogleAdsCampaign(loadLandingPage);

    }
  };

  const reviewAds = () => {
    const search = 'lp_id=' + currentCampaign?.landing_page_id + '&ad_account=' + currentCampaign?.connected_account_id;
    // history.push({
    //   pathname: '/google-ads-dashboard', 
    //   search
    // } as LocationDescriptor);
    console.log(search);
    window.open('/dashboard/google-ads-dashboard?' + search)?.focus();
  };

  const closeWarningModal = () => {
    setOpenWarningModal(false);
  };

  const handleToggleAds = (selectedCampaign: CampaignUpdateInfo) => {
    console.log('handleToggleAds', selectedCampaign);
    const nextStatus = selectedCampaign.status === CampaignStatus.ENABLED ? CampaignStatus.PAUSED : CampaignStatus.ENABLED;
    const params: UpdateGoogleCampaignStatusParams = {
      inputParams: {
        resource_names: [selectedCampaign.resource_name],
        status: nextStatus
      },
      callBackParams: {
        successCallback: (res: any) => {
          const message = nextStatus === CampaignStatus.ENABLED ?
            'Đã bật chiến dịch thành công' :
            'Đã tạm dừng chiến dịch thành công';
          toast.success(message, {position: 'top-center'});
        },
        errorCallback: (err: any) => {
          if(err && err.error === 'LP_ALREADY_ACTIVE_CAMP_ON_OTHER_ACC') {
            setOpenWarningModal(true);
          } else {
            toast.error(ErrorMessageService.getErrorMessageByCode(err.code), {position: 'top-center'});
          }
        }
      }
    };
    service.updateGoogleCampaignStatus(params);

    setCurrentCampaign(selectedCampaign);
  };

  const handleStopCampaign = () => {
    const onSuccess = (res: any) => {
      if(currentCampaign && currentCampaign.landing_page_id){
        const { connected_account_id, landing_page_id } = currentCampaign;
        const landing_pages = res.landing_pages || [];
        const resourceNames = getResourceName(connected_account_id, landing_page_id, landing_pages);
        const userExists = checkUserExists(connected_account_id, landing_page_id, landing_pages);
        setPendingLandingPage({
          ...pendingLandingPage,
          resourceNames,
          isReady: userExists
        });

        const callBackParams = {
          successCallback: (res: any) => {
            closeWarningModal();
            toast.success('Đã tạm dừng chiến dịch thành công', {position: 'top-center'});
            handleToggleAds(currentCampaign);
          },
          errorCallback: (err: any) => {
            toast.error(ErrorMessageService.getErrorMessageByCode(err.code), {position: 'top-center'});
          }
        } as UpdateGoogleCampaignStatusCB;

        const params: UpdateGoogleCampaignStatusParams = {
          inputParams: {
            resource_names: resourceNames?.map((el: GoogleCampaignAds)=>el.campaign_resource_name) || [],
            status: CampaignStatus.PAUSED,
          },
          callBackParams
        };
        
        changeCampaignStatus(params);
      }
    };

    getResourceNameList(onSuccess);
  };

  const onProjectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setProjectId(parseInt(e.currentTarget.value));
  };

  const renderProjects = () => {
    const projects = projectState.userProjects;

    return (
      <div className="input-group">
        <label>Dự án</label>
        <select
          value={projectId}
          className="w-100 form-control filter-selection"
          onChange={onProjectChange}
        >
          <option value={0}>Tất cả Dự án</option>
          {projects.length &&
            projects.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
        </select>
      </div>
    );
  };

  const onSelectAccount = (event: React.FormEvent<HTMLInputElement>) => {
    const connectedAccounts = connectedAccountGroup ? [...connectedAccountGroup.google, ...connectedAccountGroup.facebook] : [];
    if (event.currentTarget.checked) {
      const selectedAccount = connectedAccounts.find((x) => {return x.provider_account_id === event.currentTarget.id;});
      if (selectedAccount) {
        setSelectedConnectedAccounts([...selectedConnectedAccounts, selectedAccount]);
      }
    } else {
      setSelectedConnectedAccounts(selectedConnectedAccounts.filter((x) => {return x.provider_account_id !== event.currentTarget.id;}));
    }
  };

  const onSelectCampaignStatus = (event: React.FormEvent<HTMLInputElement>) => {
    const selectedStatus = listCampaignStatus.find((x) => {return x === +event.currentTarget.id;});
    
    if(+event.currentTarget.id === CampaignStatus.DRAFT){
      if (event.currentTarget.checked && selectedStatus)
        setSelectedCampaignStatus([CampaignStatus.DRAFT]);
      else
        setSelectedCampaignStatus([]);
      return;
    }
    
    const currentStatuses = selectedCampaignStatus.filter(x=>x !== CampaignStatus.DRAFT);
    
    if (event.currentTarget.checked) {
      if (selectedStatus) {
        setSelectedCampaignStatus([...currentStatuses, selectedStatus]);
      }
    } else {
      setSelectedCampaignStatus(selectedCampaignStatus.filter((x) => {return x !== +event.currentTarget.id;}));
    }
  };

  const onSelectShowHideColumn = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      const selectedColumn = listCampaignColumnActive.find((x) => {return x === +event.currentTarget.id;});
      if (selectedColumn == 0 || selectedColumn ) {
        setSelectedColumnActive([...selectedColumnActive, selectedColumn]);
      }
    } else {
      setSelectedColumnActive(selectedColumnActive.filter((x) => {return x !== +event.currentTarget.id;}));
    }
  };

  const handleAddMoreAccount = () => {
    if (!connectedAccountGroup) return;

    if (!teamPermissionService.CanConnectMoreAccount) {
      setOpenWarningLimitAccountModal(true);
      return;
    } else {
      history.push('/connect-account?force_adding=1');
    }
  };

  const onSelectTimeRange = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const renderConnectedAccounts = (connectedAccounts: ConnectedAccount[] | undefined) => {
    if (!connectedAccounts || connectedAccounts.length === 0) {
      return;
    }

    return connectedAccounts.map((x) => {
      return (
        <li key={x.id}>
          <input 
            type="checkbox" 
            className="form-check-input" 
            id={x.provider_account_id} 
            onChange={onSelectAccount} 
            checked={selectedConnectedAccounts.find((y) => {return y.provider_account_id === x.provider_account_id;}) != undefined} 
          />
          <label className="form-check-label d-flex justify-content-between" htmlFor="google-acc1">{x.name}<span>{x.provider_account_id}</span></label>
        </li>
      );
    });
  };

  const renderAccountList = () => {
    return (
      <div className="input-group">
        <label>TK quảng cáo</label>
        <div className="ads-accounts__select fixed-height fake-select w-100" onClick={() => setOpenPopup(true)}>
          Đã chọn {selectedConnectedAccounts ? selectedConnectedAccounts.length : 0}
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H14V14H0z" opacity=".01" /> <path fill="#8E8E93" fillRule="nonzero" d="M6.83 10.346c-.209 0-.382-.084-.55-.251L1.736 5.441c-.132-.131-.21-.299-.21-.496 0-.395.311-.712.712-.712.197 0 .37.084.508.215l4.092 4.193 4.08-4.193c.143-.137.322-.215.514-.215.394 0 .705.317.705.712 0 .197-.065.365-.203.496l-4.546 4.654c-.155.167-.34.245-.556.251z" /> </g> </svg>
          {openPopup && <><div className="popup-select-wrapper">
            <ul className="list-unstyled">
              {renderConnectedAccounts(connectedAccountGroup?.google)}
              {renderConnectedAccounts(connectedAccountGroup?.facebook)}
            </ul>
            <div className="add-more-account"><button className="btn btn-link p-0" onClick={handleAddMoreAccount}>Thêm tài khoản khác</button></div>
          </div>
          </>}
        </div>
        {openPopup && <div className="popup-bg" onClick={() => setOpenPopup(false)}></div>}
      </div>
    );
  };

  const renderTimeRange = () => {
    return (
      <div className="input-group">
        <label>Khoảng thời gian</label>
        <RangeDatePicker
          openPopup={openRangeTimePopup}
          setOpenPopup={(isOpen) => {setOpenRangeTimePopup(isOpen);}}
          startDate={startDate} 
          endDate={endDate} 
          callbackOpenDatePicker={() => {setOpenPopup(false);}} 
          onSelect={onSelectTimeRange} 
        />
      </div>
    );
  };

  const redirectToCreateCampagin = (event: any) => {
    event.preventDefault();
    if (!teamPermissionService.CanUseAdCreation) {
      setIsOpenNeedToUpgradeModalState(true);
      return;
    }
    
    history.push('/create-google-campaign');
  };

  const renderSelectedCampaignStatusLabel = () => {
    if (selectedCampaignStatus.filter(el=>el === CampaignStatus.DRAFT).length == listCampaignStatus.length - 1) {
      return 'Hiện tất cả';
    }
    return `Đã chọn ${selectedCampaignStatus.length}`;
  };

  const renderSelectedDisplayColumnLabel = () => {
    if (selectedColumnActive.length == listCampaignColumnActive.length) {
      return 'Hiện tất cả';
    }
    return `Đã chọn ${selectedColumnActive.length}`;
  };

  const renderCampaignStatus = () => {
    return (
      <div className="input-group">
        <label>Trạng thái</label>
        <div className="ads-accounts__select fixed-height fake-select w-100" onClick={() => setOpenCampaignStatusPopup(true)}>
          {renderSelectedCampaignStatusLabel()}
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H14V14H0z" opacity=".01" /> <path fill="#8E8E93" fillRule="nonzero" d="M6.83 10.346c-.209 0-.382-.084-.55-.251L1.736 5.441c-.132-.131-.21-.299-.21-.496 0-.395.311-.712.712-.712.197 0 .37.084.508.215l4.092 4.193 4.08-4.193c.143-.137.322-.215.514-.215.394 0 .705.317.705.712 0 .197-.065.365-.203.496l-4.546 4.654c-.155.167-.34.245-.556.251z" /> </g> </svg>
          {openCampaignStatusPopup && <><div className="popup-select-wrapper small">
            <ul className="list-unstyled">
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'2'} 
                  onChange={onSelectCampaignStatus}
                  disabled={selectedCampaignStatus.includes(CampaignStatus.DRAFT)}
                  checked={selectedCampaignStatus.find((y) => {return y === 2;}) != undefined} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-active">Đang chạy</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'3'} 
                  onChange={onSelectCampaignStatus}
                  disabled={selectedCampaignStatus.includes(CampaignStatus.DRAFT)}
                  checked={selectedCampaignStatus.find((y) => {return y === 3;}) != undefined} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-pendding">Tạm dừng</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'4'} 
                  onChange={onSelectCampaignStatus}
                  disabled={selectedCampaignStatus.includes(CampaignStatus.DRAFT)}
                  checked={selectedCampaignStatus.find((y) => {return y === 4;}) != undefined} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-removed">Đã xóa</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'5'} 
                  onChange={onSelectCampaignStatus}
                  // disabled={!selectedCampaignStatus.includes(CampaignStatus.DRAFT)}
                  checked={selectedCampaignStatus.includes(CampaignStatus.DRAFT)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-draft">Bản nháp</label>
              </li>
            </ul>
          </div>
          </>}
        </div>
        {openCampaignStatusPopup && <div className="popup-bg" onClick={() => setOpenCampaignStatusPopup(false)}></div>}
      </div>
    );
  };

  const renderDisplayColumn = () => {
    return (
      <div className="input-group">
        <label>Ẩn/Hiện cột</label>
        <div className="ads-accounts__select fixed-height fake-select w-100" onClick={() => setOpenCampaignColumnActivePopup(true)}>
          {renderSelectedDisplayColumnLabel()}
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H14V14H0z" opacity=".01" /> <path fill="#8E8E93" fillRule="nonzero" d="M6.83 10.346c-.209 0-.382-.084-.55-.251L1.736 5.441c-.132-.131-.21-.299-.21-.496 0-.395.311-.712.712-.712.197 0 .37.084.508.215l4.092 4.193 4.08-4.193c.143-.137.322-.215.514-.215.394 0 .705.317.705.712 0 .197-.065.365-.203.496l-4.546 4.654c-.155.167-.34.245-.556.251z" /> </g> </svg>
          {openCampaignColumnActivePopup && <><div className="popup-select-wrapper small">
            <ul className="list-unstyled">
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'0'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.ADSACCOUNT)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-active">
                  Tài khoản QC</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'1'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.COMPOSITESTATUS)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-active">
                  Trạng thái P.Phối</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input"
                  id={'2'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.METRICCOST)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-pendding">
                  Chi phí</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'3'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.METRICIMPRESSIONS)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-removed">
                  Lượt hiển thị</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'4'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.METRICNUMCLICK)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-removed">
                  Lượt click</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'5'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.METRICNUMENGAGEMENT)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-removed">
                  Tương tác</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'6'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.METRICNUMCONVERSIONS)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-removed">
                  Chuyển đổi</label>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id={'7'} 
                  onChange={onSelectShowHideColumn}
                  checked={selectedColumnActive.includes(CampaignColumnActive.METRICEFFECTIVE)} 
                /><label className="form-check-label d-flex justify-content-between" htmlFor="campaign-status-removed">
                  Hiệu quả</label>
              </li>
            </ul>
          </div>
          </>}
        </div>
        {openCampaignColumnActivePopup && <div className="popup-bg" onClick={() => setOpenCampaignColumnActivePopup(false)}></div>}
      </div>
    );
  };

  const queryReport = () => {
    const reportParams: GoogleAdsReportIP = {
      accountIds: selectedConnectedAccounts.map((x) => {return x.id;}),
      projectIds: projectId === 0 ? undefined : [projectId],
      startAt: startDate,
      endAt: endDate,
      statuses: selectedCampaignStatus === [] ? undefined : selectedCampaignStatus
    }; 
    const callBackParams: GoogleAdsReportCallback = {
      successCallback: (res: any) => {
        setLoading(false);
      },
      errorCallback: (err: any) => {
        setLoading(false);
      }
    };
    setOpenRangeTimePopup(false);
    setLoading(true);
    getCampaignReport(reportParams, callBackParams);
  };

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <WarningLimitAccountModal 
        maxAdwordAccount={teamPermissionService.MaxAdwordAccount}
        isOpen={openWarningLimitAccountModal} 
        closeModal={() => {setOpenWarningLimitAccountModal(false);}}
      />
      <WarningLimitedFeatureModal 
        isOpen={isOpenNeedToUpgradeModalState} 
        closeModal={() => {setIsOpenNeedToUpgradeModalState(false);}} 
      />
      <WarningLandingPageRunningAds 
        isOpen={openWarningModal}
        isLoading={loadingUpdateStatus}
        closeModal={closeWarningModal}
        reviewAds={reviewAds}
        submit={handleStopCampaign}
      />
      
      <div className={'main-content fix-height'}>
        <div className="filter-container">
          <div className="page-heading pl-0 pr-0 pb-3">
            <div className="d-flex justify-content-start align-items-start">
              <h1 className="page-title">Quản lý quảng cáo</h1>
            </div>
            <Link className="btn btn-default" onClick={redirectToCreateCampagin} to={(globalThis as any).useHistory.generateComponent('CreateGoogleCampaign', {})} style={{alignItems: 'center'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="#FFF" fillRule="evenodd"> <path d="M0 0H20V20H0z" opacity=".01"/> <path d="M10.952 9.048h4.79c.208 0 .368.038.502.11.135.072.24.178.312.312.073.134.11.294.11.503v.054c0 .209-.037.369-.11.503-.072.135-.178.24-.312.312-.134.072-.294.11-.502.11h-4.79v4.79c0 .208-.038.368-.11.502-.072.135-.178.24-.312.312-.134.073-.294.11-.503.11h-.054c-.209 0-.369-.037-.503-.11-.133-.07-.242-.179-.312-.312-.072-.134-.11-.294-.11-.502v-4.79h-4.79c-.208 0-.368-.038-.502-.11-.135-.072-.24-.178-.312-.312-.073-.134-.11-.295-.11-.503v-.054c0-.209.037-.369.11-.503.072-.135.178-.24.312-.312.134-.072.295-.11.502-.11h4.79v-4.79c0-.208.038-.368.11-.502.072-.135.178-.24.312-.312.134-.073.294-.11.503-.11h.054c.209 0 .369.037.503.11.135.072.24.178.312.312.072.134.11.294.11.502v4.79z"/> </g> </svg>
              Tạo chiến dịch
            </Link>
          </div>
          <div className="row">
            <div className="col">{renderProjects()}</div>
            <div className="col">{renderAccountList()}</div>
            <div className="col">{renderTimeRange()}</div>
            <div className="col">{renderCampaignStatus()}</div>
            <div className="col">{renderDisplayColumn()}</div>
            <div className="col">
              <label className="invisible">Dự án</label>
              <button className="btn btn-default" onClick={queryReport}>Lọc</button>
            </div>
          </div>
          <h4>Số liệu tổng quan</h4>
          <div className="row">
            <div className="col-lg-4">
              <div className="box-analytic mb-0">
                <div className="box-analytic__title">
                  Khách thu được
                  <Button className="popover-button" id="Popover1" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <path fill="#AEAEB2" fillRule="evenodd" d="M6 0c3.322 0 6 2.679 6 6 0 3.322-2.678 6-6 6-3.321 0-6-2.678-6-6 0-3.321 2.679-6 6-6zm.365 5H4.848c-.089 0-.145.014-.194.04-.048.026-.087.064-.113.114-.026.048-.04.104-.04.194v.195c0 .077.006.104.019.133.013.03.032.054.057.074.026.019.05.032.126.05l.496.125c.112.027.15.047.188.076.038.028.067.066.085.109.02.044.029.086.029.201v1.877c0 .116-.01.158-.029.202-.018.043-.047.08-.085.109-.038.029-.076.048-.188.076l-.497.125c-.074.019-.1.031-.125.05-.025.02-.044.044-.057.073-.013.03-.019.057-.019.134v.195c0 .09.014.146.04.195.026.048.064.087.114.113.048.026.104.04.194.04h2.554c.09 0 .146-.014.194-.04.049-.026.088-.065.114-.114.026-.049.04-.104.04-.194v-.194c0-.078-.006-.105-.019-.135-.013-.029-.032-.053-.057-.072-.026-.02-.05-.032-.126-.051l-.496-.124c-.112-.029-.15-.048-.188-.077-.038-.028-.067-.066-.085-.109-.02-.044-.029-.086-.029-.202V5.384c0-.133-.014-.182-.04-.23-.026-.049-.064-.088-.114-.114C6.548 5.014 6.5 5 6.366 5zM6 2.25c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
                    </svg>
                  </Button>
                  <Popover placement="bottom" isOpen={popover1Open} target="Popover1" toggle={() => setPopover1Open(!popover1Open)} trigger="focus">
                    <PopoverHeader>Khách thu được</PopoverHeader>
                    <PopoverBody>Lượt khách chuyển đổi từ các trang Landing Page thông qua các chiến dịch quảng cáo</PopoverBody>
                  </Popover>
                </div>
                <div className="box-analytic__content">{campaignReports ? campaignReports.total_leads : 0}</div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box-analytic mb-0">
                <div className="box-analytic__title">
                  Tổng số tiền đã tiêu
                  <Button className="popover-button" id="Popover2" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <path fill="#AEAEB2" fillRule="evenodd" d="M6 0c3.322 0 6 2.679 6 6 0 3.322-2.678 6-6 6-3.321 0-6-2.678-6-6 0-3.321 2.679-6 6-6zm.365 5H4.848c-.089 0-.145.014-.194.04-.048.026-.087.064-.113.114-.026.048-.04.104-.04.194v.195c0 .077.006.104.019.133.013.03.032.054.057.074.026.019.05.032.126.05l.496.125c.112.027.15.047.188.076.038.028.067.066.085.109.02.044.029.086.029.201v1.877c0 .116-.01.158-.029.202-.018.043-.047.08-.085.109-.038.029-.076.048-.188.076l-.497.125c-.074.019-.1.031-.125.05-.025.02-.044.044-.057.073-.013.03-.019.057-.019.134v.195c0 .09.014.146.04.195.026.048.064.087.114.113.048.026.104.04.194.04h2.554c.09 0 .146-.014.194-.04.049-.026.088-.065.114-.114.026-.049.04-.104.04-.194v-.194c0-.078-.006-.105-.019-.135-.013-.029-.032-.053-.057-.072-.026-.02-.05-.032-.126-.051l-.496-.124c-.112-.029-.15-.048-.188-.077-.038-.028-.067-.066-.085-.109-.02-.044-.029-.086-.029-.202V5.384c0-.133-.014-.182-.04-.23-.026-.049-.064-.088-.114-.114C6.548 5.014 6.5 5 6.366 5zM6 2.25c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
                    </svg>
                  </Button>
                  <Popover placement="bottom" isOpen={popover2Open} target="Popover2" toggle={() => setPopover2Open(!popover2Open)} trigger="focus">
                    <PopoverHeader>Tổng số tiền đã tiêu</PopoverHeader>
                    <PopoverBody>Chi tiêu cho các chiến dịch của tất cả các tài khoản quảng cáo đã kết nối với cokhach.vn</PopoverBody>
                  </Popover>
                </div>
                <div className="box-analytic__content">{numeral(totalCost).format('0,0 $')}</div>
              </div>
            </div>
            <div className="col-lg-4">
            </div>
          </div>
          {!!campaignReports && <h4>Danh sách chiến dịch</h4>}
        </div>
        <div>
          {  (firstTime || loading) ? 
          <div className="no-campaign-reports">
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H70V70H0z" opacity=".01" transform="translate(-723 -390) translate(656 390) translate(67)"/> <path fill="#AEAEB2" fillRule="nonzero" d="M32.719 64.5c-1.625-1.438-2.969-3.188-3.907-5.125H16.688c-2.968 0-4.53-1.563-4.53-4.438V10.5c0-2.844 1.53-4.469 4.53-4.469h30.75c2.876 0 4.532 1.594 4.532 4.469v25.719c1.937.906 3.687 2.25 5.125 3.875V10.312c0-6.25-3.125-9.406-9.313-9.406H16.344C10.156.906 7 4.094 7 10.312v44.75c0 6.282 3.156 9.438 9.344 9.438h16.375zm10.406-46.438c1.063 0 1.844-.812 1.844-1.843 0-1.032-.782-1.813-1.844-1.813H21c-1.094 0-1.875.781-1.875 1.813 0 1.031.781 1.843 1.875 1.843h22.125zm0 10.25c1.063 0 1.844-.812 1.844-1.875 0-1-.782-1.812-1.844-1.812H21c-1.094 0-1.875.813-1.875 1.813 0 1.062.781 1.875 1.875 1.875h22.125zM59.75 69.906c1.406 0 2.469-1.094 2.469-2.625 0-.687-.313-1.375-.844-1.875l-6.594-6.625c1.469-2.125 2.313-4.687 2.313-7.468 0-7.313-5.907-13.22-13.188-13.22-7.25 0-13.218 5.97-13.218 13.22 0 7.28 5.968 13.187 13.218 13.187 2.625 0 5.063-.781 7.125-2.094l6.532 6.563c.687.687 1.374.937 2.187.937zm-15.844-9.719c-4.906 0-8.906-4-8.906-8.874 0-4.844 4-8.876 8.906-8.876 4.844 0 8.844 4.032 8.844 8.876 0 4.874-4 8.874-8.844 8.874z" transform="translate(-723 -390) translate(656 390) translate(67)"/> </g> </g> </g> </g> </svg>
            <p>Đang tải các chiến dịch, vui lòng đợi ...</p> 
          </div>
            : ( !campaignReports || (campaignReports && campaignReports.campaigns.length === 0)) ?  
          <div className="no-campaign-reports">
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H70V70H0z" opacity=".01" transform="translate(-723 -390) translate(656 390) translate(67)"/> <path fill="#AEAEB2" fillRule="nonzero" d="M32.719 64.5c-1.625-1.438-2.969-3.188-3.907-5.125H16.688c-2.968 0-4.53-1.563-4.53-4.438V10.5c0-2.844 1.53-4.469 4.53-4.469h30.75c2.876 0 4.532 1.594 4.532 4.469v25.719c1.937.906 3.687 2.25 5.125 3.875V10.312c0-6.25-3.125-9.406-9.313-9.406H16.344C10.156.906 7 4.094 7 10.312v44.75c0 6.282 3.156 9.438 9.344 9.438h16.375zm10.406-46.438c1.063 0 1.844-.812 1.844-1.843 0-1.032-.782-1.813-1.844-1.813H21c-1.094 0-1.875.781-1.875 1.813 0 1.031.781 1.843 1.875 1.843h22.125zm0 10.25c1.063 0 1.844-.812 1.844-1.875 0-1-.782-1.812-1.844-1.812H21c-1.094 0-1.875.813-1.875 1.813 0 1.062.781 1.875 1.875 1.875h22.125zM59.75 69.906c1.406 0 2.469-1.094 2.469-2.625 0-.687-.313-1.375-.844-1.875l-6.594-6.625c1.469-2.125 2.313-4.687 2.313-7.468 0-7.313-5.907-13.22-13.188-13.22-7.25 0-13.218 5.97-13.218 13.22 0 7.28 5.968 13.187 13.218 13.187 2.625 0 5.063-.781 7.125-2.094l6.532 6.563c.687.687 1.374.937 2.187.937zm-15.844-9.719c-4.906 0-8.906-4-8.906-8.874 0-4.844 4-8.876 8.906-8.876 4.844 0 8.844 4.032 8.844 8.876 0 4.874-4 8.874-8.844 8.874z" transform="translate(-723 -390) translate(656 390) translate(67)"/> </g> </g> </g> </g> </svg>
            <p>Chưa có chiến dịch nào</p> 
            <Link onClick={redirectToCreateCampagin} to={(globalThis as any).useHistory.generateComponent('CreateGoogleCampaign', {})}>Tạo chiến dịch mới</Link>
          </div>
          : <CampaignMetrics 
              setIsOpenNeedToUpgradeModalState={setIsOpenNeedToUpgradeModalState} 
              selectedColumnActive={selectedColumnActive} 
              onToggleAd={handleToggleAds} 
            />
          }
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background-color: #f5f4f9;
  .popover-button {
    padding: 0;
    border: 0;
    margin-left: 5px;
    margin-bottom: 10px;
    background-color: transparent;
  }
  .popup-bg {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10
  }
  .filter-container label {
    font-family: Propins-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    color: #666666;
  }
  h4 {
    margin-top: 20px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
  }
  .no-campaign-reports {
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .no-campaign-reports p {
    margin-top: 20px;
  }
  .filter-selection {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    color: #000000;
  }
  .ads-accounts__select {
    align-items: center; 
    justify-content: space-between;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
    margin-bottom: 3px;
  }
  .popup-select-wrapper {
    z-index: 100;
    top: 100%;
    left: 0;
    width: 351px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .popup-select-wrapper.small {
    width: 199px;
  }
  .popup-select-wrapper ul {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #666666;
    margin-bottom: 8px;
    padding-left: 35px;
    padding-right: 15px;
  }
  .popup-select-wrapper ul li {
    display: flex;
    align-items: center;
  }
  .popup-select-wrapper .add-more-account {
    border-top: solid 1px rgba(216, 216, 216, 0.5);
    padding: 8px 10px;
  }
  @media (max-width: 1300px) {
    .ads-accounts__select {
      font-size: 14px;
      min-width: 190px;
    }
    .filter-selection {
      min-width: 190px;
    }
    .filter-selection.status {
      min-width: 150px;
    }
  }
`;

export default CampaignMetricDashboard;
  