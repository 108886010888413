import * as React from 'react';
import { FullHeader } from 'pages/header/FullHeader';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { History } from 'src/route';

const LinkExpiredPage: React.FC = () => {
  const history = new History();
  return (
    <Styles>
      <FullHeader />
      <div className="container link-expired-page">
        <img src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/882x600/expired-link.png`} width="441" height="300" alt="logo" />
        <div className="text-center link-expired__heading">
          <h3>Không có quyền truy cập</h3>
          <p>Bạn không có quyền truy cập vào trang này. Vui lòng thử lại sau.</p>
          <Link 
            className="btn-default d-inline-block"
            to={history.component('Home')}
          >
            Vào dashboard
          </Link>
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .app-header.background-white {
    background-color: transparent;
    box-shadow: none;
  }
  .link-expired-page {
    margin-top: 48px;
    text-align: center;
  }

  h3 {
    font-family: Propins-Bold;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 60px;
    margin-bottom: 16px;
  }

  p {
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }

  .link-expired__heading {
    margin-bottom: 40px;
  }

`;

export default LinkExpiredPage;