"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootReducer = void 0;
const ads_1 = require("../ads");
const analytics_1 = require("../analytics/");
const auth_1 = require("../auth");
const customer_1 = require("../customer");
const landing_page_1 = require("../landing-page");
const project_1 = require("../project");
const user_1 = require("../user");
const team_1 = require("../team");
const ads_campaign_1 = require("../ads-campaign");
const GoogleAds_1 = require("../GoogleAds");
const payment_1 = require("../payment");
const GoogleAds_2 = require("../GoogleAds");
exports.rootReducer = {
    auth: auth_1.authReducer,
    user: user_1.userReducer,
    projects: project_1.projectReducer,
    landingPages: landing_page_1.landingPagesReducer,
    customers: customer_1.customerReducer,
    analytics: analytics_1.analyticsReducer,
    userPublishedUrls: ads_1.userPublishedUrlsReducer,
    team: team_1.teamReducer,
    adsCampaign: ads_campaign_1.adsCampaignReducer,
    adsReport: GoogleAds_1.adsReport,
    payment: payment_1.paymentReducer,
    campaignForecast: GoogleAds_2.campaignForecast,
};
