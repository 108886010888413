import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, 
  SelectedTeam, 
  WorkSpaceService, 
  UpdateOrCreateTeamParameter, 
  TeamCallbackErrorParams, 
  GetTeamMemberListParams 
} from 'core';
import * as ReactModal from 'react-modal';
import { plainToClass } from 'class-transformer';
import { toast } from 'react-toastify';
import TeamService, { ApiErrorMsg, FieldType, InputErrorMsg } from '../service';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

const TeamInformationModal: React.FC<ModalProps> = (props) => {
  const selectedTeam = useSelector((state) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const name = selectedTeam.getTeamName();
  const domain = selectedTeam.getTeamDomain();
  const [teamName, setTeamName] = useState('');
  const [teamLink, setTeamLink] = useState('');
  const mounted = useRef(false);
  const service = new WorkSpaceService();
  // const apiError: any = useSelector((state: any) => state.team.error);

  const [apiErrorCode, setApiErrorCode] = useState({
    error: false,
    code: '0'
  });

  useEffect(() => {
    mounted.current = true;
    handleGetTeamList();
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const handleGetTeamList = () => {
    const params: GetTeamMemberListParams = {
      callBackParams: {}
    };
    service.getTeamMemberList(params);
  };

  const handleSaveTeam = () => {
    const params: UpdateOrCreateTeamParameter = {
      inputParams: {
        name: teamName ? teamName : name
      },
      callBackParams: {
        successCallback: (response: any) => {
          toast.success('Bạn đã cập nhật thông tin nhóm.');
          setApiErrorCode({
            error: false,
            code: '0'
          });
          closeModal();
        },
        errorCallback: (err: any) => {
          toast.error('Tạo nhóm không thành công.');
          setApiErrorCode({
            error: true,
            code: err.code
          });
        },
        errorSwitchTeamCallback: (err: any) => {
          toast.error('Không thể chuyển sang nhóm mới tạo.');
          setApiErrorCode({
            error: true,
            code: err.code
          });
        },
        errorCreateTeamCallback: (err: any) => {
          toast.error('Tạo nhóm không thành công.');
          setApiErrorCode({
            error: true,
            code: err.code
          });
        }
      }
    };

    service.updateTeam(params);
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setTeamName(value);
  };

  const renderApiError = (shouldShow: boolean, code: string) => {
    if (!shouldShow) return null;
    return (
      <div className='mt-2' style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="team-information-modal">
        <div className="modal__heading">
          <h3>Thiết lập nhóm</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            <label>Tên nhóm</label>
            <p>Đây là tên thể hiện hoạt động của đội nhóm hoặc doanh nghiệp của bạn. Hãy sử dụng tên phổ thông, dễ gợi nhớ cho các thành viên trong nhóm. </p>
            <input type="text" name="team_name" 
              className="form-control" placeholder="Nhập tên nhóm" defaultValue={name} onChange={handleOnChange} 
            />
          </div>
          <div className="modal__content--row">
            <label className="mt-3">Đường link doanh nghiệp</label>
            <p>Đây là đường dẫn tới dashboard chung của nhóm. Thành viên của nhóm có thể đăng nhập vào dashboard theo đường dẫn này.</p>
            <input 
              type="text" name="team_link" 
              className="form-control gray-input" disabled placeholder="Nhập tên nhóm" defaultValue={domain} 
            />
          </div>
        </div>
        {renderApiError(apiErrorCode.error, apiErrorCode.code)}
        <div className="modal__bottom">
          <button onClick={handleSaveTeam} className="btn-default">Lưu</button>
        </div>
      </div>
    </ReactModal>
  );
};

TeamInformationModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default TeamInformationModal;
