import React, { useEffect } from 'react';
import { useForm, Controller, useFormContext, useFieldArray} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { 
  GetAdGroupsBelongToCampaignParams,
  GetListAdGroupByCampaignParams, 
  GoogleService, 
  AdGroupAd,IKeywordData,
  GetAdGroupsBelongToCampaignSuccessType,
  GetListKeywordSuggestionParams,
  GetAdGroupsBelongToCampaignIP,
  IAgeRange,
  CreateOrUpdateAdGroupParams,
  KeywordData,
  AppState
} from 'core';
import { InputType } from '../CreateGoogleCampaign/fields/SitelinkComponent';
import { InputCallType} from '../CreateGoogleCampaign/fields/CallComponent';

interface ErrorResponse{}
interface PropsType extends GetAdGroupsBelongToCampaignIP{
  onSuccess?: (res: AdGroupAd[]) => void;
}

export const useAdGroupAd = (props: PropsType) => {
  const service = new GoogleService();
  const { campaignResourceName } = props;
  
  useEffect(()=>{
    if (!campaignResourceName) {
      return;
    }

    const adParams: GetAdGroupsBelongToCampaignParams = {
      inputParams: {
        campaignResourceName
      },
    };
    
    const adGroupAdParams: GetListAdGroupByCampaignParams = {
      callBackParams: {
        successCallback: (res: any) => {
          props.onSuccess && props.onSuccess(res);
        },
        errorCallback: (err: ErrorResponse) => {
          // toast.error('Có lỗi khi lấy thông tin');
        }
      }
    };
    service.getListAdFlow(adParams, adGroupAdParams);
  }, [campaignResourceName]);
};

interface AdGroupPropsType extends GetAdGroupsBelongToCampaignIP{
  landing_page_id: number;
  ages: IAgeRange[];
  onSuccess?: () => void;
}

export const useAdGroup = (props: AdGroupPropsType) => {
  const service = new GoogleService();
  const { campaignResourceName } = props;
  const adGroups = useSelector<any, KeywordData | null>((state: AppState) => state.adsCampaign.AdGroups);
  
  useEffect(()=>{
    if (adGroups && adGroups.keyword_groups.length > 0) {
      props.onSuccess && props.onSuccess();
      return;
    }
    const adParams: GetAdGroupsBelongToCampaignParams = {
      inputParams: {
        campaignResourceName
      },
      callBackParams: {
        successCallback: (res: any) => {
          props.onSuccess && props.onSuccess();
        },
        errorCallback: (err: ErrorResponse) => {
          // toast.error('Có lỗi khi lấy thông tin');
        }
      }
    };
    
    const kwParams: GetListKeywordSuggestionParams = {
      inputParams: {
        landing_page_id: props.landing_page_id,
        ages: props.ages
      },
      callBackParams: {
        successCallback: (res: any) => {
          props.onSuccess && props.onSuccess();
        },
        errorCallback: (err: ErrorResponse) => {
          // toast.error('Có lỗi khi lấy thông tin');
        }
      }
    };
    service.getAdGroupFlow(adParams, kwParams);
  }, [campaignResourceName]);
};

export const useAdGroupAction = () => {
  const service = new GoogleService();
  const updateAdGroup = (params: CreateOrUpdateAdGroupParams) => {
    service.createOrUpdateAdGroup(params);
  };
  
  return {updateAdGroup};
};