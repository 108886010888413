"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerSagas = exports.updateCustomerSaga = exports.getCustomerSaga = void 0;
const class_transformer_1 = require("class-transformer");
const effects_1 = require("redux-saga/effects");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
const entities_1 = require("../../entities");
function* getCustomerSaga() {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/contacts`,
            authenticated: true
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        const customer = responseData.data.contacts.map((ctm) => {
            return class_transformer_1.plainToClass(entities_1.Customer, ctm);
        });
        const totalRemaining = responseData.data.total_remaining;
        yield effects_1.put({ type: types_1.GET_CUSTOMER_SUCCESS, customer, totalRemaining });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.GET_CUSTOMER_ERROR, err });
    }
}
exports.getCustomerSaga = getCustomerSaga;
function* updateCustomerSaga(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/update_contact`,
            authenticated: true,
            data: Object.assign({}, action.customer)
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        const customer = class_transformer_1.plainToClass(entities_1.Customer, responseData.data);
        yield effects_1.put({
            type: types_1.UPDATE_CUSTOMER_SUCCESS,
            customer
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.UPDATE_CUSTOMER_ERROR,
            error: true,
            payload: error
        });
    }
}
exports.updateCustomerSaga = updateCustomerSaga;
exports.customerSagas = [
    effects_1.takeLatest(types_1.GET_CUSTOMER, getCustomerSaga),
    effects_1.takeLatest(types_1.UPDATE_CUSTOMER, updateCustomerSaga)
];
