"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComeInActionTypes = exports.ForgotPasswordResetPassword = exports.ForgotPasswordResetEmail = exports.RESET_PREVIOUS_PAGE = exports.SET_PREVIOUS_PAGE = exports.RESET_IN_EMAIL_REGISTER_PROCESS_SUCCESS = exports.RESET_IN_EMAIL_REGISTER_PROCESS = exports.REGISTER_BY_EMAIL_ERROR = exports.REGISTER_BY_EMAIL_SUCCESS = exports.REGISTER_BY_EMAIL = exports.AUTH_LOGIN_ERROR = exports.AUTH_LOGIN_SUCCESS = exports.LOGOUT = exports.LOGIN = void 0;
exports.LOGIN = 'LOGIN';
exports.LOGOUT = 'LOGOUT';
exports.AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
exports.AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
// export const REFRESH_PAGE = 'REFRESH_PAGE';
// export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
exports.REGISTER_BY_EMAIL = 'REGISTER_BY_EMAIL';
exports.REGISTER_BY_EMAIL_SUCCESS = 'REGISTER_BY_EMAIL_SUCCESS';
exports.REGISTER_BY_EMAIL_ERROR = 'REGISTER_BY_EMAIL_ERROR';
exports.RESET_IN_EMAIL_REGISTER_PROCESS = 'RESET_IN_EMAIL_REGISTER_PROCESS';
exports.RESET_IN_EMAIL_REGISTER_PROCESS_SUCCESS = 'RESET_IN_EMAIL_REGISTER_PROCESS_SUCCESS';
exports.SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE';
exports.RESET_PREVIOUS_PAGE = 'RESET_PREVIOUS_PAGE';
// model for ForgotPassword function, Send email method
exports.ForgotPasswordResetEmail = {
    common: "FORGOT_PASSWORD_RESET_EMAIL",
    success: "FORGOT_PASSWORD_RESET_EMAIL_SUCCESS",
    error: "FORGOT_PASSWORD_RESET_EMAIL_ERROR",
};
// model for ForgotPassword function, Reset password method
exports.ForgotPasswordResetPassword = {
    common: "FORGOT_PASSWORD_RESET_PASSWORD",
    success: "FORGOT_PASSWORD_RESET_PASSWORD_SUCCESS",
    error: "FORGOT_PASSWORD_RESET_PASSWORD_ERROR",
};
//------------ new service
var ComeInActionTypes;
(function (ComeInActionTypes) {
    ComeInActionTypes["Action"] = "ComeInActionTypeAction";
    ComeInActionTypes["Request"] = "ComeInActionTypeRequest";
    ComeInActionTypes["Success"] = "ComeInActionTypeSuccess";
    ComeInActionTypes["Failure"] = "ComeInActionTypeFailure";
})(ComeInActionTypes = exports.ComeInActionTypes || (exports.ComeInActionTypes = {}));
