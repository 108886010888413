import * as React from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useCookie, Token } from 'pages/auth/hooks';
import { logout } from 'core';

interface Props {
  cookies: Cookies;
}

const Logout: React.FC<Props> = (props) => {
  const { getAllCookies, removeAllCookies } = useCookie(props.cookies);
  const user = useSelector((state) => state.user.user);
  const history = (globalThis as any).useHistory;
  history.hook();
  const dispatch = useDispatch();

  React.useEffect(() => {
    removeAllCookies();
    dispatch(logout());
    if (!user) {
      history.push('/login');
    }
  }, []);

  return (
    <div />
  );
};

export default withCookies(Logout);