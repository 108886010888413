"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPreviousPage = exports.setPreviousPage = exports.forgotPasswordResetPassword = exports.forgotPasswordResetEmail = exports.resetInEmailRegisterProcess = exports.registerByEmail = exports.logout = void 0;
const types_1 = require("../types");
const logout = () => ({
    type: types_1.LOGOUT
});
exports.logout = logout;
// export const userRefresh = (userToken: string): UserRefreshActionType => ({
//   type: REFRESH_PAGE,
//   userToken
// });
const registerByEmail = (params) => ({
    type: types_1.REGISTER_BY_EMAIL,
    params
});
exports.registerByEmail = registerByEmail;
const resetInEmailRegisterProcess = () => ({
    type: types_1.RESET_IN_EMAIL_REGISTER_PROCESS,
});
exports.resetInEmailRegisterProcess = resetInEmailRegisterProcess;
const forgotPasswordResetEmail = (params) => ({
    type: types_1.ForgotPasswordResetEmail.common,
    meta: {
        thunk: true
    },
    params
});
exports.forgotPasswordResetEmail = forgotPasswordResetEmail;
const forgotPasswordResetPassword = (params) => ({
    type: types_1.ForgotPasswordResetPassword.common,
    meta: {
        thunk: true
    },
    params
});
exports.forgotPasswordResetPassword = forgotPasswordResetPassword;
const setPreviousPage = (previousPage) => ({
    type: types_1.SET_PREVIOUS_PAGE,
    previousPage
});
exports.setPreviousPage = setPreviousPage;
const resetPreviousPage = () => ({
    type: types_1.RESET_PREVIOUS_PAGE,
});
exports.resetPreviousPage = resetPreviousPage;
