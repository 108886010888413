"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiAction = exports.FuncType = void 0;
const redux_1 = require("../frameworks/redux");
var FuncType;
(function (FuncType) {
    FuncType[FuncType["Async"] = 0] = "Async";
    FuncType[FuncType["Sync"] = 1] = "Sync";
})(FuncType = exports.FuncType || (exports.FuncType = {}));
class ApiAction {
    constructor(type, funcType, params) {
        this._action = () => ({
            type: this._type,
            params: this._params.inputParams,
            meta: {
                thunk: this._funcType === FuncType.Async
            },
        });
        this.dispatchAction = () => {
            const dispatchFunc = redux_1.store.dispatch(this._action());
            Promise.resolve(dispatchFunc).then((response) => {
                if (this._params.callBackParams && this._params.callBackParams.successCallback) {
                    this._params.callBackParams.successCallback(response);
                }
            }).catch(reason => {
                this._params.callBackParams && this._params.callBackParams.errorCallback && this._params.callBackParams.errorCallback(reason);
            });
        };
        this.triggerAction = () => {
            return redux_1.store.dispatch(this._action());
        };
        this._params = params || {};
        this._type = type;
        this._funcType = funcType;
    }
    success(params, response) {
        if (params && params.callBackParams && params.callBackParams.successCallback) {
            params.callBackParams.successCallback(response);
        }
    }
    error(params, response) {
        if (params && params.callBackParams && params.callBackParams.errorCallback) {
            params.callBackParams.errorCallback(response);
        }
    }
}
exports.ApiAction = ApiAction;
