"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callApi = void 0;
const axios_1 = require("axios");
const redux_1 = require("../frameworks/redux");
const callApi = (action) => {
    const { authenticated, useMasterToken, method, url, data, params, setUserToken } = action;
    const config = {
        method,
        url,
        data,
        params
    };
    const { auth } = redux_1.store.getState();
    let { userToken, masterUserToken } = auth;
    const anounymousToken = process.env.REACT_APP_ANONYMOUS_TOKEN || null;
    if (!authenticated) {
        userToken = anounymousToken;
    }
    if (useMasterToken) {
        userToken = masterUserToken;
    }
    if (setUserToken) {
        userToken = setUserToken;
    }
    if (!userToken)
        return Promise.reject('User not logged in!');
    config.headers = {
        Authorization: `Bearer ${userToken}`
    };
    const authService = () => {
        const { auth } = redux_1.store.getState();
        const { masterUserToken } = auth;
        return new Promise((resolve, reject) => {
            resolve(masterUserToken);
        });
    };
    axios_1.default.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        const originalRequest = error.config;
        const res = error.response;
        if (!res) {
            return Promise.reject('Network Error');
        }
        // else if ((res.status == 500 && res.data.error.code == 414) && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     console.log(res, res.data.error)
        //     return authService().then((token: string)=>{
        //       if(!token) Promise.reject({code: 414, message: 'Master token is null'})
        //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        //       originalRequest.headers['Authorization'] = 'Bearer ' + token;
        //       return axios(originalRequest);
        //     })
        // } 
        else {
            return Promise.reject(error);
        }
    });
    const req = axios_1.default.request(config);
    return req;
};
exports.callApi = callApi;
