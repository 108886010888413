import React, { useState, useEffect } from 'react';
import FlatList from 'flatlist-react';
import { ICallExtensionSetting, ICallExtension } from 'core';
import { FieldErrors } from 'react-hook-form';
import { ExtensionAction } from '../forms/CommonSettings';
import { ErrorInput } from './ErrorInput';
import classNames from 'classnames';
import el from 'date-fns/esm/locale/el';

type PropsType = {
  handleExtensionAction: (item?: InputCallType, action?: ExtensionAction) => void;
  onChange: (value?: ICallExtensionSetting) => void;
  value?: ICallExtensionSetting;
  calls: InputCallType[];
  errors: FieldErrors;
}

export interface InputCallType extends ICallExtension {
  value: number;
}
export interface UpdateCallIO {
  calls: InputCallType[];
  value?: ICallExtensionSetting;
}
interface EditItemCallIO{
  updateItem: InputCallType;
  oldItem: InputCallType;
}
// {updateItem: InputCallType[], oldItem?: InputCallType}

const importData = (props: UpdateCallIO): InputCallType[] => {
  const ret: InputCallType[] = props.calls;
  const data: ICallExtension[] | undefined = props.value?.calls;
 
  data?.map((el: ICallExtension)=>{
    const {phone_number} = el;
    const found = ret.find(item=>item.phone_number === el.phone_number);
    if(found) {
      found.value = 1;
      found.resource_name = el.resource_name;
    }
    else
      ret.unshift({phone_number, value: 1, resource_name: el.resource_name});
  });
  return ret;
};

const exportData = (input: InputCallType[], resource_name: string | undefined): ICallExtensionSetting | undefined => {
  const calls: ICallExtension[] = input.filter(el=>el.value).map((x) => {
    return {
      resource_name: x.resource_name,
      phone_number: x.phone_number,
    };
  });
  if(calls.length == 0) return undefined;

  const callExtension: ICallExtensionSetting = {
    resource_name: resource_name,
    calls: calls
  };
  return callExtension;
};

export const updateCallIO = (inputCall: UpdateCallIO, data: EditItemCallIO, resource_name: string | undefined): ICallExtensionSetting | undefined => {
  const input: InputCallType[] = importData(inputCall);
  const {updateItem, oldItem} = data;
 
  if(!oldItem) return;
  //cancel if it's already exists
  const dupl = updateItem.phone_number !== oldItem.phone_number && input.find(el=>el.phone_number === updateItem.phone_number);
  if(dupl) return;
  
  //update new item into current place
  const found = input.find(el=>oldItem ? el.phone_number === oldItem.phone_number : el.phone_number === updateItem.phone_number);
  if(found){
    Object.keys(found).map(el=>found[el] = updateItem[el]);
  }
  
  const res = exportData(input, resource_name);
  return res;
};


export const addCallIO = (inputCalls: UpdateCallIO, updateItem: InputCallType[], resource_name: string | undefined): ICallExtensionSetting | undefined => {
  const input: InputCallType[] = importData(inputCalls);
  
  updateItem.map(item=>{
    const found = input.find(el=>el.phone_number === item.phone_number);
    if(found){
      Object.keys(found).map(el=>found[el] = item[el]);
    }else{
      input.push({...item, value: 1});
    }
  });
  
  const ret = exportData(input, resource_name);
  return ret;
};

export const CallComponent: React.FC<PropsType> = (props) => {
  const callList: InputCallType[] = React.useMemo(()=>importData({calls: props.calls, value: props.value}), [props.calls, props.value]);

  const renderItem = (item: InputCallType, handleChange: (item: InputCallType, action: ExtensionAction) => void) => {
    return (
      <div key={'calls/' + item.phone_number} className="extend-item">
        <div className="form-check mr-3">
          <div className="d-flex align-items-center">
            <input 
            id={`extend-call-${item.phone_number}`} 
            className="form-check-input" 
            type="checkbox"
            value={item.value}
            checked={!!item.value}
            onChange={() => handleChange(item, ExtensionAction.Toggle)} />
            <label htmlFor={`extend-call-${item.phone_number}`}>
              {item.phone_number}
            </label>
          </div>
          <div className="item-action">
            <a className="edit-link cursor-pointer" onClick={() => handleChange(item, ExtensionAction.Edit)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fillRule="nonzero"> <g> <g> <g> <path fill="#FFF" d="M0 0H18V18H0z" opacity=".01" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)" /> <path fill="#8E8E93" d="M15.432 4.472l1.038-1.033c.52-.52.538-1.096.064-1.58l-.392-.403c-.473-.475-1.065-.429-1.575.074L13.52 2.57l1.912 1.9zM1.662 16.853l2.286-.877L14.485 5.404l-1.885-1.9L2.063 14.074l-.91 2.248c-.128.32.21.64.51.53z" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)" /> </g> </g> </g> </g> </g> </svg></a>
            <a className="remove-link hidden" onClick={() => handleChange(item, ExtensionAction.Remove)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2" fillRule="nonzero"> <g> <g> <path d="M670 46c4.924 0 9-4.085 9-9 0-4.924-4.085-9-9.009-9-4.915 0-8.991 4.076-8.991 9 0 4.915 4.085 9 9 9zm3.097-5.153c-.203 0-.38-.088-.52-.22l-2.586-2.586-2.567 2.585c-.142.142-.327.221-.539.221-.397 0-.723-.335-.723-.741 0-.194.08-.37.212-.512l2.585-2.585-2.585-2.585c-.133-.142-.212-.318-.212-.512 0-.406.326-.724.723-.724.212 0 .38.07.512.212l2.594 2.585 2.62-2.594c.15-.15.31-.22.504-.22.397 0 .723.326.723.723 0 .203-.062.362-.212.52l-2.594 2.595 2.586 2.576c.14.15.211.318.211.52 0 .407-.326.742-.732.742z" transform="translate(-1016 -830) translate(355 754) translate(0 48)"/> </g> </g> </g> </g> </svg></a>
          </div>
        </div>
      </div>
    );
  };

  const renderPreviewItem = (item: InputCallType, handleChange: (item: InputCallType, action: ExtensionAction) => void) => {
      return (
        <div key={'reviewcalls/' + item.phone_number} className="extend-item">
          <p>{item.phone_number}</p>
          <div className="item-action">
            <a className="edit-link" onClick={() => handleChange(item, ExtensionAction.Edit)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fillRule="nonzero"> <g> <g> <g> <path fill="#FFF" d="M0 0H18V18H0z" opacity=".01" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)"/> <path fill="#8E8E93" d="M15.432 4.472l1.038-1.033c.52-.52.538-1.096.064-1.58l-.392-.403c-.473-.475-1.065-.429-1.575.074L13.52 2.57l1.912 1.9zM1.662 16.853l2.286-.877L14.485 5.404l-1.885-1.9L2.063 14.074l-.91 2.248c-.128.32.21.64.51.53z" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)"/> </g> </g> </g> </g> </g> </svg></a>
            <a className="delete-link" onClick={() => handleChange(item, ExtensionAction.Delete)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2" fillRule="nonzero"> <g> <g> <path d="M670 46c4.924 0 9-4.085 9-9 0-4.924-4.085-9-9.009-9-4.915 0-8.991 4.076-8.991 9 0 4.915 4.085 9 9 9zm3.097-5.153c-.203 0-.38-.088-.52-.22l-2.586-2.586-2.567 2.585c-.142.142-.327.221-.539.221-.397 0-.723-.335-.723-.741 0-.194.08-.37.212-.512l2.585-2.585-2.585-2.585c-.133-.142-.212-.318-.212-.512 0-.406.326-.724.723-.724.212 0 .38.07.512.212l2.594 2.585 2.62-2.594c.15-.15.31-.22.504-.22.397 0 .723.326.723.723 0 .203-.062.362-.212.52l-2.594 2.595 2.586 2.576c.14.15.211.318.211.52 0 .407-.326.742-.732.742z" transform="translate(-1016 -830) translate(355 754) translate(0 48)"/> </g> </g> </g> </g> </svg></a>
          </div>
        </div>
      );
  };
  
  const handleToggleItem = (item: InputCallType, action: ExtensionAction) => {
    if(action === ExtensionAction.Toggle){
      const calls: InputCallType[] = callList;
      const found = calls.find(el=>el.phone_number == item.phone_number);
      if(found) found.value = found.value ? 0 : 1;
      props.onChange(exportData(calls, props.value?.resource_name));
    }
    if(action === ExtensionAction.Delete){
      const calls: InputCallType[] = callList;
      const found = calls.find(el=>el.phone_number == item.phone_number);
      if(found) found.value = 0;
      props.onChange(exportData(calls, props.value?.resource_name));
    }
    
    if(action === ExtensionAction.Edit){
      props.handleExtensionAction(item, action);
    }
  };
  
  return (<>
          <label className="mt-3">Tiện ích mở rộng cuộc gọi</label>
          <div className={classNames('extends-list-wrapper', {'errors': props.errors.call})}>
            <div className="settings-list">
              <FlatList
                list={callList}
                renderItem={(item: InputCallType)=>renderItem(item, handleToggleItem)}
                renderWhenEmpty={() => <div></div>}
              />
            </div>
            <div className="preview-list">
              <FlatList
                list={callList.filter(el=>el.value)}
                renderItem={(item: InputCallType)=>renderPreviewItem(item, handleToggleItem)}
                renderWhenEmpty={() => <div></div>}
              />
            </div>
            {/* <div className="preview-list__label">
              XEM <br />TRƯỚC
            </div> */}
            <div className="add-extend-link" onClick={()=>props.handleExtensionAction(undefined, ExtensionAction.Add)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M6.997 16C10.833 16 14 12.832 14 9s-3.174-7-7.01-7C3.16 2 0 5.168 0 9s3.167 7 6.997 7zm0-1.395c-3.106 0-5.59-2.498-5.59-5.605S3.891 3.401 6.99 3.401c3.106 0 5.603 2.492 5.61 5.599.006 3.107-2.498 5.605-5.603 5.605zm-.014-2.572c.4 0 .643-.27.643-.69V9.65h1.813c.406 0 .69-.244.69-.636 0-.4-.27-.644-.69-.644H7.626v-1.8c0-.42-.244-.69-.643-.69-.392 0-.63.283-.63.69v1.8H4.548c-.42 0-.697.244-.697.644 0 .392.291.636.697.636h1.807v1.692c0 .407.237.69.63.69z" transform="translate(-365 -1109) translate(355 754) translate(0 48) translate(10 305)"/> </g> </g> </g> </g> </g> </svg>
              <span>Thêm mới tiện ích mở rộng cuộc gọi</span>
            </div>
          </div>{/*extends-list-wrapper*/}
          {props.errors.call && <ErrorInput text={props.errors.call.message}/>}
    </>
  );
};