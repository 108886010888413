"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adsCampaignReducer = void 0;
const src_1 = require("src");
const GoogleAds_1 = require("../../GoogleAds");
const types_1 = require("../types");
const initialState = {
    isFetching: false,
    locations: null,
    campaigns: null,
    currentCampaign: null,
    hasChangedAtInitStep: false,
    needFetchCurrentCampaign: false,
    currentLandingPage: null,
    keywordSuggest: null,
    AdGroups: null,
    AdGroupAds: null,
    access_token: null,
    connectedAccountGroup: null,
    fbAdsIds: null,
    fbFanpages: null,
    fbAccessToken: '',
};
const connectedAccountByType = (accounts, type) => {
    return accounts.filter((acc) => acc.account_type === type);
};
const adsCampaignReducer = (state = initialState, action) => {
    var _a, _b, _c;
    switch (action.type) {
        case types_1.GetLocationAds.success:
            return Object.assign({}, state, {
                locations: action.payload.locations
            });
        case types_1.GetDefaultExtension.success:
            return Object.assign({}, state, {
                currentLandingPage: Object.assign(Object.assign({}, state.currentLandingPage), { sitelinks: action.payload.default_sitelinks, calls: action.payload.default_calls })
            });
        case types_1.GetDefaultComponents.success:
            return Object.assign({}, state, {
                currentLandingPage: Object.assign(Object.assign({}, state.currentLandingPage), { components: action.payload })
            });
        case types_1.GetLPWithGoogleAdsCampaign.success:
            const data = action.payload;
            return Object.assign({}, state, {
                currentLandingPage: Object.assign(Object.assign({}, state.currentLandingPage), data)
            });
        case types_1.UpdateGoogleCampaignStatus.success:
            const params = action.payload;
            if (!state.currentLandingPage || !state.currentLandingPage.landing_pages || !params.landing_page_id) {
                return state;
            }
            const newLP = (_a = state.currentLandingPage) === null || _a === void 0 ? void 0 : _a.landing_pages;
            const id = newLP.findIndex((lp) => lp.lp_id === params.landing_page_id);
            if (!id)
                return state;
            newLP[id].google_campaigns = [];
            return Object.assign({}, state, {
                currentLandingPage: Object.assign(Object.assign({}, state.currentLandingPage), { landing_pages: newLP })
            });
        // case CreateGoogleCampaign.type:
        //   return Object.assign({}, state, {
        //     currentCampaign: {
        //       ...action.params,
        //     }
        //   });
        case types_1.CreateGoogleCampaign.success:
            const newCampaign = Object.assign(Object.assign({}, action.params), action.payload);
            return Object.assign({}, state, {
                currentCampaign: newCampaign,
                hasChangedAtInitStep: false,
                needFetchCurrentCampaign: true
            });
        case types_1.SaveDraftGoogleCampaign.type:
            return Object.assign({}, state, {
                currentCampaign: Object.assign({}, action.params)
            });
        // case CreateOrUpdateAdGroup.success:
        //   return Object.assign({}, state, {
        //     AdGroups: action.payload
        //   });
        case types_1.GetAdGroupsBelongToCampaign.success:
            // Because ages are kept in AdGroup but we allow user set it at common settings then after getting AdGroup success, we must update ages from first AdGroup
            const adGroups = action.payload.AdGroups;
            let ages = adGroups.keyword_groups.length === 0 ?
                (_b = state.currentCampaign) === null || _b === void 0 ? void 0 : _b.ages :
                adGroups.keyword_groups[0].ages.map(x => {
                    return {
                        type: x.type,
                        operation: x.operation,
                        resource_name: x.resource_name
                    };
                });
            return Object.assign({}, state, {
                AdGroups: action.payload.AdGroups,
                currentCampaign: Object.assign(Object.assign({}, state.currentCampaign), { ages: ages })
            });
        case types_1.GetListKeywordSuggestion.success:
            return Object.assign({}, state, {
                keywordSuggest: action.payload
            });
        case types_1.GetListAdGroupByCampaign.success:
            return Object.assign({}, state, {
                AdGroupAds: action.payload
            });
        case types_1.GetDetailGoogleCampaign.success:
            const campaign = action.payload;
            if (campaign.maximize_conversion_value && campaign.maximize_conversion_value.target_roas === 0) {
                campaign.maximize_conversion_value.target_roas = undefined;
            }
            return Object.assign({}, state, {
                isFetching: false,
                currentCampaign: campaign,
                hasChangedAtInitStep: false,
                needFetchCurrentCampaign: false
            });
        case 'pending':
            return Object.assign({}, state, {
                isFetching: true
            });
        case types_1.InitialCampaign.type:
            const campaignDataInfoSvc = new GoogleAds_1.CampaignDataInfoService();
            if (state.currentCampaign) {
                return Object.assign({}, state, {
                    currentCampaign: Object.assign(Object.assign({}, state.currentCampaign), action.params)
                });
            }
            return Object.assign({}, state, {
                currentCampaign: campaignDataInfoSvc.initNewCampaignData(action.params)
            });
        case types_1.GetFbAdAccount.success:
            return Object.assign({}, state, {
                fbAdsIds: action.payload.data.fb_ads_id,
                fbFanpages: action.payload.data.fanpages,
                fbAccessToken: action.payload.data.fb_access_token,
            });
        case types_1.FetchConnectedAccount.success:
            const accounts = action.payload;
            return Object.assign({}, state, {
                connectedAccountGroup: accounts
            });
        case types_1.StartCreatingCampaign.type:
            return Object.assign({}, state, {
                currentCampaign: null,
                hasChangedAtInitStep: false,
                AdGroups: null,
                AdGroupAds: null,
                currentLandingPage: null,
                keywordSuggest: null
            });
        case types_1.UpdateCampaign.type:
            const param = action.params;
            const hasChangedAtInitStep = param.name.trim() !== ((_c = state.currentCampaign) === null || _c === void 0 ? void 0 : _c.name);
            return Object.assign({}, state, {
                currentCampaign: Object.assign(Object.assign({}, state.currentCampaign), { name: param.name }),
                hasChangedAtInitStep: hasChangedAtInitStep
            });
        case src_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.adsCampaignReducer = adsCampaignReducer;
