import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import SubmitButton from 'buttons/SubmitButton';
import { User, SelectedTeam, TeamPermissionService } from 'core';
import CreatableSelect from 'react-select/creatable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NeedToUpgradeModal from 'pages/menu/modals/NeedToUpgradeModal';
import styled from 'styled-components';
import ListMembers from './ListMembers';
import { plainToClass } from 'class-transformer';
import { State } from './service';
import { History } from 'src/route/Route';

type OptionType = { label: string; value: string };

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

const TransferOwnershipPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = new History();
  const [nameMember, setNameMember] = useState('');
  const user = useSelector((state) => state.user.user);
  const team: any = useSelector((state: any) => state.team);
  const selectedTeam = useSelector((state: State) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const pendingInvites = useSelector((state: State) => state.team.pendingInvites);
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const hasPermissionInvite = teamPermissionService.CanInviteTeamMember;
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);
  const teams = team.teams;
  const members = team.members;
  // React.useEffect(() => {
  //   if (!team || teams && teams.length < 2) {
  //     history.push('/');
  //     return;
  //   }
  // }, []);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setNameMember(value);
  };

  const addMember = () => {
    if(!hasPermissionInvite) {
      setIsOpenNeedToUpgradeModalState(true);
      return;
    }
    history.push('/create-team/invite');
  };
  
  const getMemberExceptRole = (type: string) => {
    const result = members.filter((el: any)=>{ 
      const roles = el.roles.map((role: any)=>role.code); 
      return !roles.includes(type); 
    });

    const userId = user && user.email;
    return result.length > 0 && userId ? result.filter((el: any)=>el.id != userId) : [];
  };

  const onlyOwner = () => {
    const list = getMemberExceptRole('owner');
    return list.length > 0;
  };

  const renderListMember = () => {
    return (
      <div className="container transfer-onwer-form">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            {onlyOwner() ? <>
            <div className="transfer-onwer-form__heading">
              <h2>Chuyển quyền sở hữu nhóm <span>#{selectedTeam.getTeamName()}</span></h2>
              <p>Hãy chọn một thành viên trong nhóm để chuyển quyền sở hữu. Chúng tôi sẽ thông báo cho họ qua email.</p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <input type="text" name="name" className="form-control" placeholder="Tìm kiếm tên/email thành viên" value={nameMember} onChange={handleOnChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label>Chuyển cho:</label>
                <ListMembers role="changeOwner" data={getMemberExceptRole('owner')} searchTerm={nameMember}/>
              </div>
            </div>
            </> : <>
            <div className="transfer-onwer-form__heading">
              <h2>Chuyển quyền sở hữu nhóm <span>#{selectedTeam.getTeamName()}</span></h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="no-other" style={{display: 'flex', alignItems: 'center', margin: '20px 0', flexDirection: 'column', lineHeight: '55px'}}>
                  <span>Hãy thêm thành viên để bắt đầu hoạt động nhóm.</span>
                  <button className="btn btn-default" onClick={addMember}>Thêm thành viên</button>
                </div>
              </div>
            </div>
            </>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Styles>
      <NeedToUpgradeModal 
        teamName={selectedTeam.getTeamName()}
        isOpen={isOpenNeedToUpgradeModalState} 
        closeModal={() => {setIsOpenNeedToUpgradeModalState(false);}} 
      />
      {team && renderListMember()}
    </Styles>
  );
};

const Styles = styled.div`

  .transfer-onwer-form__heading {
    margin-top: 68px;
  }

  .right-box {
    padding: 30px 40px;
    border-radius: 6px;
    border: solid 0.5px #d8d8d8;
    background-color: #f7f8f8;
    text-align: center;
  }
  .right-box ul li {
    text-align: left;
    margin-bottom: 10px;
  }
  .right-box ul li svg {
    margin-right: 10px;
  }
  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }
  h2 span {
    color: #1e0e62;
  }
  h3 {
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
  }
  ul li, p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #666666;
  }
  label {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
    margin-top: 20px;
  }
  .create-team-form__step2 .create-team-form__heading p {
    color: #000000;
  }
  .create-team-form__heading {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .create-team-form__heading p span.active {
    color: #4c7cf3;
  }
  .create-team-form__title {
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  }
`;

export default TransferOwnershipPage;