import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import SubmitButton from 'buttons/SubmitButton';
import { FullHeader } from 'pages/header/FullHeader';
import CreatableSelect from 'react-select/creatable';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import TeamService, { ApiErrorMsg, FieldType, InputErrorMsg, EmailsInvite } from './service';
import { WorkSpaceService, User, SelectedTeam, TeamCallbackErrorParams, UpdateOrCreateTeamParameter, GetTeamParams, TeamPermissionService, GoToTeamParameter } from 'core';
import { EmailService } from 'src/pages/auth/service';
import { isIterable } from '../../utils/typeGuard';
import Utils from 'utils/string';
import { History } from 'src/route/Route';
import { withCookies, Cookies } from 'react-cookie';
import { plainToClass } from 'class-transformer';
import { useRedirectDomain } from 'pages/auth/hooks';

type PropsType = {
  cookies: Cookies;
};

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

type OptionType = { label: string; value: string };

const CreateTeamPage: React.FC<PropsType> = (props) => {
  const history = new History();
  history.hook();
  const { step } = useParams<{ step: string | undefined }>();
  const config: any = useSelector((state: any) => state.team.config);
  // const apiError: any = useSelector((state: any) => state.team.error);
  const team: any = useSelector((state: any) => state.team.selectedTeam);
  const service = new WorkSpaceService();
  const { gotoTeam } = useRedirectDomain(props.cookies);
  const [valueArrayInput, setValueArrayInput] = useState(new Array<OptionType>());
  const [inputValueState, setInputValueState] = useState('');
  const selectedTeam = useSelector((state) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const [createTeamInput, setCreateTeamInput] = useState({
    teamName: selectedTeam.getTeamName(),
    teamLink: ''
  });
  const [createTeamProcess, setCreateTeamProcess] = useState({
    currentStep: step === 'invite' ? 'invite' : 'create',
    error: null
  });
  const [isInvalidEmailState, setIsInvalidEmailState] = useState(false);
  const [errorStatus, setErrorStatus] = useState({
    email: false
  });
  const [apiErrorCode, setApiErrorCode] = useState({
    error: false,
    code: '0'
  });

  const [createdTeam, setCreatedTeam] = useState(team);

  const isDiabledButton = createTeamInput.teamLink === '' || createTeamInput.teamName === '';

  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();

  // useEffect(() => {
  //   if(!apiError) return;
  //   setApiErrorCode({
  //     error: true,
  //     code: apiError.code
  //   });
  // }, [apiError]);

  const handleOnChangeName = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setCreateTeamInput({
      ...createTeamInput,
      teamName: value,
    });
  };

  const handleOnBlurName = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const link = Utils.nonAccentVietnamese(value).toLowerCase().split(' ').join('-');
    
    setCreateTeamInput({
      teamName: value,
      teamLink: createTeamInput.teamLink ? createTeamInput.teamLink : link
    });
  };

  const handleOnChangeLink = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setCreateTeamInput({
      ...createTeamInput,
      teamLink: value
    });
  };

  const onChange = (value: any, actionMeta: any) => {
    setValueArrayInput(value);
    if (validToInvite(value)) {
      setIsInvalidEmailState(false);
    }
  };

  const onInputChange = (value: string) => {
    setInputValueState(value);

    if (value && EmailService.validateEmailFormat(value)) {
      setIsInvalidEmailState(false);
    }
    //Have to create email tag once pressing ',' or space btn
    const isCommaOrSpacePress = value.includes(' ') || value.includes(',');
    const correctInput = value.replace(/[\s,]/g, '');
    if (isCommaOrSpacePress) {
      setNewValueArrayInput(correctInput);
      setInputValueState('');
    }
  };

  const onBlurInput = (e: any) => {
    const { value } = e.target;
    if (value) {
      setNewValueArrayInput(value);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValueState) return;

    if (event.key === 'Enter' || event.key === 'Tab') {
      setNewValueArrayInput(inputValueState);
      setInputValueState('');
    }
  };

  const setNewValueArrayInput = (value: string) => {
    let newValueArrayInput = valueArrayInput;

    if(isIterable(newValueArrayInput)) {
      newValueArrayInput = [...valueArrayInput, createOption(value)];
    }
    else {
      newValueArrayInput = [createOption(value)];
    }
    setValueArrayInput(newValueArrayInput);
    
    if (newValueArrayInput && newValueArrayInput.length && !validToInvite(newValueArrayInput)) {
      setIsInvalidEmailState(true);
    }
  };

  const resetAllInput = () => {
    setValueArrayInput(new Array<OptionType>());
    setInputValueState('');
  };

  const renderApiError = (shouldShow: boolean, code: string) => {
    if (!shouldShow) return null;
    return (
      <div className='mt-2' style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg) => {
    if (!ifError) return null;
    return (
      <div className='mt-2' style={{color: 'red'}}>{errMsg}</div>
    );
  };

  const handleUpdateGroupInfo = () => {
    const createTeamParams: UpdateOrCreateTeamParameter = {
      inputParams: {
        name: createTeamInput.teamName,
        domain: createTeamInput.teamLink,
      },
      callBackParams: {
        successCallback: (res: any)=>{
          toast.success('Cập nhật thông tin nhóm thành công.');
          setApiErrorCode({
            code: '0',
            error: false,
          });
          setCreatedTeam(res);
        },
        errorCallback: (err: any) => {
          toast.error(ApiErrorMsg[err.code] ? ApiErrorMsg[err.code] : 'Không tạo được nhóm mới.');
          setApiErrorCode({
            error: true,
            code: err.code
          });
        }
      }
    };
    const getTeamParams: GetTeamParams = {
      callBackParams: {
        successCallback: (res: any)=>{
          setCreateTeamProcess({
            ...createTeamProcess,
            currentStep: 'invite'
          });
          setApiErrorCode({
            code: '0',
            error: false,
          });
          setCreatedTeam(res);
        },
        errorCallback: (err: any) => {
          toast.error('Không thể lấy thông tin nhóm mới tạo.');
          setApiErrorCode({
            error: true,
            code: err.code
          });
        },
      }
    };
    service.createTeamFlow(createTeamParams, getTeamParams);
  };

  const goToUpgradeAccount = () => {
    const teamId = createdTeam && createdTeam.team && createdTeam.team.id;

    const gotoTeamParams: GoToTeamParameter = {
      inputParams: {
        team_id: teamId
      },
      callBackParams: {
        successCallback: (res: any) => {
          gotoTeam(res.selectedTeam.token, res.selectedTeam, {}, '/dashboard/payment/pricing-page');
        },
        errorCallback: (err: any) => {
          toast.error('Không thể chuyển sang Workspace của nhóm.');
        }
      }
    };

    service.gotoTeam(gotoTeamParams);
  };

  const handleSendInviteEmail = () => {
    const emails = valueArrayInput.map(v => v.value);
    const params = {
      inputParams: {
        emails,
        team_id: createdTeam && createdTeam.team && createdTeam.team.id
      },
      callBackParams: {
        successCallback: (res: any) => {
          resetAllInput();
          setApiErrorCode({
            ...apiErrorCode,
            error: false,
          });
        },

        errorCallback: (err: any) => {
          if (err.code == 412) {
            toast.error(`Người dùng với địa chỉ email ${err.description} đã là thành viên của nhóm.`, { autoClose: false });
          }
        }
      }
    };

    const gotoTeamParams = {
      inputParams: {
        team_id: createdTeam && createdTeam.team && createdTeam.team.id
      },
      callBackParams: {
        successCallback: (res: any) => {
          gotoTeam(res.selectedTeam.token, res.selectedTeam, {'team-manager': 'show', 'message': 302});
        },
        errorCallback: (err: any) => {
          toast.error('Không thể chuyển sang Workspace của nhóm.');
        }
      }
    };


    service.inviteTeamFlow(params, gotoTeamParams);
  };

  const validToInvite = (arrInput: OptionType[] | null): boolean => {
    if (!arrInput || !arrInput.length) return false;
    
    for (let i = 0; i < arrInput.length; i++) {
      const email = arrInput[i].value;
      if (!EmailService.validateEmailFormat(email)) return false;
    }

    return true;
  };

  const customStyles = {
    container: () => ({
      width: '100%',
    }),
    valueContainer: (provided: object) => ({
      ...provided,
      padding: '15px 20px',
    }),
    input: (provided: object) => ({
      ...provided,
      margin: 0,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      color: '#000000'
    }),
    multiValue: (provided: object) => ({
      ...provided,
      padding: '6px 10px',
      borderRadius: 15
    }),
    placeholder: (provided: object) => ({
      ...provided,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      color: '#666666'
    }),
  };

  const renderSettingStep = () => {
    return (
      <div className="container create-team-form">
        <div className="text-center create-team-form__heading">
          <h2>Thiết lập đội nhóm của bạn</h2>
          <p><span className="active">Thiết lập chi tiết</span> &gt; <span>Thêm thành viên</span></p>
        </div>
        <div className="row">
          <div className="col-lg-5 offset-lg-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="create-team-form__title">
                  <h3>Thiết lập chi tiết</h3>
                  <p>Thiết lập tên và đường dẫn cho đội nhóm hoặc doanh nghiệp của bạn.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label>Tên nhóm</label>
                <p>Đây là tên thể hiện hoạt động của đội nhóm hoặc doanh nghiệp của bạn. Hãy sử dụng tên phổ thông, dễ gợi nhớ cho các thành viên trong nhóm. </p>
                <input type="text" name="group_name" className="form-control" placeholder="VD: Phòng kinh doanh Cenland 01" value={createTeamInput.teamName || ''} 
                  onChange={handleOnChangeName} 
                  onBlur={handleOnBlurName} 
                />
              </div>
              <div className="col-lg-12">
                <label>Đường link doanh nghiệp</label>
                <p>Đây là đường dẫn tới dashboard chung của nhóm. Thành viên của nhóm có thể đăng nhập vào dashboard theo đường dẫn này.</p>
                <div className="input-suffix-wrapper">
                  <input type="text" name="group_link" className="form-control group-link" placeholder="VD: phongkinhdoanhcenland01" value={createTeamInput.teamLink || ''} 
                    onChange={handleOnChangeLink}
                    onBlur={handleOnChangeLink}
                  />
                  <span className="input-suffix">{`.${config.domainTeam}`}</span>
                </div>
              </div>
            </div>
            {renderApiError(apiErrorCode.error, apiErrorCode.code)}
            <div className="button-group align-center-left">
              <SubmitButton
                alignLeft
                isDisabled={isDiabledButton}
                className="btn-submit"
                onClick={handleUpdateGroupInfo}
              >
                Tạo ngay
              </SubmitButton>
              <Link to={history.url('/home')} className="button-link">
                Để sau
              </Link>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="right-box">
              <img src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/group_image.png`} width="64" height="48" />
              <h4>Tài khoản đội nhóm</h4>
              <ul className="list-unstyled">
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> <path fill="#4C7CF3" fillRule="nonzero" d="M6.637 13.83c-.378 0-.664-.138-.922-.45l-3.43-4.213C2.08 8.93 2 8.703 2 8.464c0-.55.405-.948.968-.948.332 0 .571.126.79.398l2.852 3.595 5.586-8.836c.232-.371.47-.504.849-.504.557 0 .955.392.955.935 0 .206-.066.425-.219.664l-6.209 9.579c-.219.325-.53.484-.935.484z" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> </g> </g> </g> </g> </g> </svg>
                  Quản lý mọi dữ liệu đội nhóm dễ dàng hơn
                </li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> <path fill="#4C7CF3" fillRule="nonzero" d="M6.637 13.83c-.378 0-.664-.138-.922-.45l-3.43-4.213C2.08 8.93 2 8.703 2 8.464c0-.55.405-.948.968-.948.332 0 .571.126.79.398l2.852 3.595 5.586-8.836c.232-.371.47-.504.849-.504.557 0 .955.392.955.935 0 .206-.066.425-.219.664l-6.209 9.579c-.219.325-.53.484-.935.484z" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> </g> </g> </g> </g> </g> </svg>
                  Phân cấp quyền quản lý
                </li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> <path fill="#4C7CF3" fillRule="nonzero" d="M6.637 13.83c-.378 0-.664-.138-.922-.45l-3.43-4.213C2.08 8.93 2 8.703 2 8.464c0-.55.405-.948.968-.948.332 0 .571.126.79.398l2.852 3.595 5.586-8.836c.232-.371.47-.504.849-.504.557 0 .955.392.955.935 0 .206-.066.425-.219.664l-6.209 9.579c-.219.325-.53.484-.935.484z" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> </g> </g> </g> </g> </g> </svg>
                  Bảo mật dữ liệu data
                </li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> <path fill="#4C7CF3" fillRule="nonzero" d="M6.637 13.83c-.378 0-.664-.138-.922-.45l-3.43-4.213C2.08 8.93 2 8.703 2 8.464c0-.55.405-.948.968-.948.332 0 .571.126.79.398l2.852 3.595 5.586-8.836c.232-.371.47-.504.849-.504.557 0 .955.392.955.935 0 .206-.066.425-.219.664l-6.209 9.579c-.219.325-.53.484-.935.484z" transform="translate(-795 -376) translate(755 199) translate(40 175) translate(0 2)"/> </g> </g> </g> </g> </g> </svg>
                  Đường link dành riêng cho đội nhóm của bạn
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderUpgradeAccountButton = () => {
    return (
      <SubmitButton
        alignLeft
        className="btn-submit"
        onClick={goToUpgradeAccount}
      >
        Nâng cấp tài khoản
      </SubmitButton>
    );
  };

  const renderSendInviteButton = () => {
    return (
      <SubmitButton
        alignLeft
        className="btn-submit"
        isDisabled={teamPermissionService.CanInviteTeamMember && !validToInvite(valueArrayInput)}
        onClick={handleSendInviteEmail}
      >
        Thêm ngay
      </SubmitButton>
    );
  };

  const renderButtons = () => {
    return (
      <div className="button-group align-center-left">
        {teamPermissionService.CanInviteTeamMember ? renderSendInviteButton() : renderUpgradeAccountButton()}
        <Link to={history.url('/home')} className="button-link">
          Để sau
        </Link>
      </div>
    );
  };

  const renderInviteStep = () => {

    return (
      <div className="container create-team-form__step2">
        <div className="text-center create-team-form__heading">
          <h2>Thành viên trong {createTeamInput.teamName}</h2>
          <p><span>Thiết lập chi tiết</span> &gt; <span className="active">Thêm thành viên</span></p>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="create-team-form__title">
                  <h3>Thêm thành viên
                  {!teamPermissionService?.CanInviteTeamMember && <svg style={{marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <defs>
                        <linearGradient id="c2chuk3sna" x1="100%" x2="0%" y1="50%" y2="50%">
                            <stop offset="0%" stopColor="#F450A5"/>
                            <stop offset="100%" stopColor="#E47B49"/>
                        </linearGradient>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <g fill="url(#c2chuk3sna)" transform="translate(-182 -242)">
                            <g>
                                <g>
                                    <path d="M11.875 9.57l.74-3.016.046.002c.243 0 .466-.06.67-.18.203-.12.365-.28.487-.481.121-.202.182-.426.182-.673 0-.243-.06-.465-.18-.666-.12-.202-.28-.362-.484-.482-.203-.12-.428-.18-.675-.18-.243 0-.464.06-.664.18s-.359.281-.478.484c-.12.204-.18.425-.18.664 0 .198.038.38.115.55l.02.04-1.582 1.142c-.156.11-.283.092-.382-.057L7.648 4.12l.022-.01c.2-.12.361-.28.481-.482.12-.202.18-.426.18-.673 0-.239-.06-.46-.18-.663-.12-.204-.28-.365-.481-.485-.202-.12-.424-.18-.667-.18-.247 0-.471.06-.673.18-.2.12-.361.28-.481.482-.12.201-.18.423-.18.666 0 .247.061.471.183.673.121.201.283.362.484.481l.018.01-1.864 2.79c-.046.065-.096.104-.151.118-.055.013-.126-.01-.214-.072L2.531 5.801l.015-.03c.077-.168.115-.351.115-.549 0-.24-.06-.46-.18-.664-.12-.203-.28-.364-.481-.484-.201-.12-.424-.18-.667-.18-.247 0-.47.06-.672.18-.201.12-.362.28-.482.482-.12.2-.179.423-.179.666 0 .247.06.471.182.673.122.2.282.361.482.481s.422.18.67.18l.057-.003.746 3.017h9.738zm-1.96 2.803c.452 0 .803-.11 1.054-.33.25-.22.429-.547.535-.98l.171-.684H2.33l.166.684c.106.433.285.76.535.98.251.22.604.33 1.06.33h5.824z" transform="translate(15 239) translate(167 3)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                  </svg>}
                  </h3>
                  {teamPermissionService?.CanInviteTeamMember && <p>Hãy thêm thành viên bằng cách nhập email của họ và nhấn Enter.</p>}
                </div>
              </div>
            </div>
            {teamPermissionService?.CanInviteTeamMember && <div className="row">
              <div className="col-lg-12">
                <CreatableSelect
                  components={components}
                  inputValue={inputValueState}
                  isClearable
                  isMulti
                  isDisabled={!teamPermissionService.CanInviteTeamMember}
                  styles={customStyles}
                  menuIsOpen={false}
                  onChange={onChange}
                  onInputChange={onInputChange}
                  onKeyDown={onKeyDown}
                  placeholder=""
                  value={valueArrayInput}
                  onBlur={onBlurInput}
                />
              </div>
            </div>}
            {renderInputError(isInvalidEmailState, InputErrorMsg.InvalidEmail)}
            {renderApiError(apiErrorCode.error, apiErrorCode.code)}
            {!teamPermissionService.CanInviteTeamMember && <div className="warning-text">Đây là tính năng dành riêng cho nhóm sử dụng Gói cơ bản và Gói chuyên nghiệp. Nhấn "Nâng cấp tài khoản" để thực hiện nâng cấp và tiếp tục hoàn tất tác vụ thêm thành viên vào nhóm</div> }
            {renderButtons()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Styles>
      <FullHeader />
      {createTeamProcess.currentStep === 'create' && renderSettingStep()}
      {createTeamProcess.currentStep !== 'create' && renderInviteStep()}
    </Styles>
  );
};

const Styles = styled.div`

  .warning-text {
    margin-top: 16px;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #000000;
  }
  .right-box {
    padding: 30px 40px;
    border-radius: 6px;
    border: solid 0.5px #d8d8d8;
    background-color: #f7f8f8;
    text-align: center;
  }
  .right-box ul li {
    text-align: left;
    margin-bottom: 10px;
  }
  .right-box ul li svg {
    margin-right: 10px;
  }
  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }
  h3 {
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
  }
  h4 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
  }
  ul li, p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #666666;
  }
  label {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
    margin-top: 20px;
  }
  .create-team-form__step2 .create-team-form__heading p {
    color: #000000;
  }
  .create-team-form__heading {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .create-team-form__heading p span.active {
    color: #4c7cf3;
  }
  .create-team-form__title {
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  }
  .input-suffix-wrapper {
    position: relative;
    // display: inline-block;
    width: 100%;
    min-width: 0;
    // padding: 4px 11px;
    // color: rgba(0,0,0,.85);
    // font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    // border: 1px solid #d9d9d9;
    // border-radius: 2px;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .input-suffix-wrapper .group-link {
    padding-right: 100px;
  }
  .input-suffix{
    margin-left: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 15px;
    bottom: 13px;
  }
`;

export default withCookies(CreateTeamPage);
