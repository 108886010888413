"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authReducer = void 0;
const types_1 = require("../types");
const action_namespace_1 = require("../../team/types/action-namespace");
const initialState = {
    userToken: null,
    error: null,
    forgotError: null,
    inEmailRegisterProcess: false,
    masterUserToken: null,
    previousPage: '',
};
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_1.LoginAction.success:
            return Object.assign({}, state, {
                userToken: action.userToken,
                masterUserToken: action.userToken,
            });
        case types_1.RefreshAction.success:
            return Object.assign({}, state, {
                userToken: action.userToken,
                masterUserToken: action.masterUserToken,
            });
        case types_1.VerifyEmailAction.success:
            return Object.assign({}, state, {
                userToken: action.userToken,
            });
        case action_namespace_1.JoinTeamAction.success:
            return Object.assign({}, state, {
                userToken: action.userToken,
            });
        case types_1.REGISTER_BY_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                userToken: action.userToken,
                masterUserToken: action.userToken,
                inEmailRegisterProcess: true,
            });
        case types_1.RESET_IN_EMAIL_REGISTER_PROCESS_SUCCESS:
            return Object.assign({}, state, {
                inEmailRegisterProcess: false,
            });
        case types_1.REGISTER_BY_EMAIL_ERROR:
        case types_1.AUTH_LOGIN_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        case types_1.ForgotPasswordResetEmail.error:
            return Object.assign({}, state, {
                forgotError: action.error
            });
        case types_1.ForgotPasswordResetPassword.error:
            return Object.assign({}, state, {
                forgotError: action.error
            });
        case action_namespace_1.GoToTeamAction.success:
            return Object.assign({}, state, {
                userToken: action.selectedTeam.token,
            });
        case types_1.SET_PREVIOUS_PAGE:
            return Object.assign({}, state, {
                previousPage: action.previousPage,
            });
        case types_1.RESET_PREVIOUS_PAGE:
            return Object.assign({}, state, {
                previousPage: '',
            });
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.authReducer = authReducer;
