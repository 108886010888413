"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyticsReducer = void 0;
const moment = require("moment");
const types_1 = require("../../auth/types");
const formatChannelData_1 = require("../services/formatChannelData");
const types_2 = require("../types");
const business_1 = require("../useCases/business");
const action_namespace_1 = require("../../team/types/action-namespace");
const initialState = {
    isFetching: false,
    detailData: {
        lpLabel: '',
        projectName: '',
        totalData: {},
        dailyData: [],
    },
    duration: {
        startDate: moment(new Date()).subtract(7, 'days').toDate(),
        endDate: moment(new Date()).subtract(1, 'days').toDate(),
    },
    realtime: {
        isFetching: false,
        detailData: [],
        dataPerMinutes: {},
        dataByChannels: {},
    },
    projectsOverview: [],
    dashboard: {
        highlight: {
            num_user: {
                value: 0,
                compare_rate: {
                    rate: 0,
                    title: 'vs 2 ngày trước đó',
                },
            },
            num_session: {
                value: 0,
                compare_rate: {
                    rate: 0,
                    title: 'vs 2 ngày trước đó',
                },
            },
            num_conversion: {
                value: 0,
                compare_rate: {
                    rate: 0,
                    title: 'vs 2 ngày trước đó',
                },
            },
            conversion_rate: {
                value: 0,
                compare_rate: {
                    rate: 0,
                    title: 'vs 2 ngày trước đó',
                },
            },
        },
        growth_data: {
            num_new_user: [
                {
                    date: '2020-07-10',
                    value: 0,
                },
                {
                    date: '2020-07-11',
                    value: 0,
                },
            ],
            num_session: [
                {
                    date: '2020-07-10',
                    value: 0,
                },
                {
                    date: '2020-07-10',
                    value: 0,
                },
                {
                    date: '2020-07-11',
                    value: 0,
                },
            ],
        },
        engagement_data: {
            bounce_rates: {
                header: {
                    value: 0,
                    compare_rate: {
                        rate: 0,
                        title: 'vs 2 ngày trước đó',
                    },
                },
                daily_data: [
                    {
                        day: '2020-07-08',
                        value: 0,
                    },
                    {
                        day: '2020-07-09',
                        value: 0,
                    },
                    {
                        day: '2020-07-10',
                        value: 100,
                    },
                    {
                        day: '2020-07-11',
                        value: 0,
                    },
                ],
            },
            scroll_rates: {
                header: {
                    value: 0,
                    compare_rate: {
                        rate: 0,
                        title: 'vs 2 ngày trước đó',
                    },
                },
                daily_data: [
                    {
                        day: '2020-07-08',
                        value: 0,
                    },
                    {
                        day: '2020-07-09',
                        value: 0,
                    },
                    {
                        day: '2020-07-10',
                        value: 0,
                    },
                    {
                        day: '2020-07-11',
                        value: 0,
                    },
                ],
            },
            dwelltime_rates: {
                header: {
                    value: 0,
                    compare_rate: {
                        rate: 0,
                        title: 'vs 2 ngày trước đó',
                    },
                },
                daily_data: [
                    {
                        day: '2020-07-08',
                        value: 0,
                    },
                    {
                        day: '2020-07-09',
                        value: 0,
                    },
                    {
                        day: '2020-07-10',
                        value: 0,
                    },
                    {
                        day: '2020-07-11',
                        value: 0,
                    },
                ],
            },
            num_fake_click: 0,
            dwelltime_engaged_user: 0,
            scroll_rate_engaged_user: 0,
        },
        conversion_data: {
            num_conversion: 0,
            conversions: [
                {
                    date: '2020-07-10',
                    value: 0,
                },
                {
                    date: '2020-07-11',
                    value: 0,
                },
            ],
            num_click: 0,
        },
        channels_data: [
            {
                name: 'google',
                num_session: 0,
                num_visitor: 0,
                num_conversion: 0,
                conversion_rate: 0,
                bounce_rate: 0,
                scroll_rate: 0,
                dwelltime_rate: 0,
                campaigns: [
                    {
                        name: '',
                        num_session: 0,
                        num_visitor: 0,
                        num_conversion: 0,
                        conversion_rate: 0,
                        bounce_rate: 0,
                        scroll_rate: 0,
                        dwelltime_rate: 0,
                    },
                ],
            },
            {
                name: 'Organic',
                num_session: 0,
                num_visitor: 0,
                num_conversion: 0,
                conversion_rate: 0,
                bounce_rate: 0,
                scroll_rate: 0,
                dwelltime_rate: 0,
                campaigns: [
                    {
                        name: '',
                        num_session: 0,
                        num_visitor: 0,
                        num_conversion: 0,
                        conversion_rate: 0,
                        bounce_rate: 0,
                        scroll_rate: 0,
                        dwelltime_rate: 0,
                    },
                ],
            },
        ],
        versions_data: [
            {
                name: '',
                num_session: 0,
                num_visitor: 0,
                num_conversion: 0,
                conversion_rate: 0,
                bounce_rate: 0,
                scroll_rate: 0,
                dwelltime_rate: 0,
            },
        ],
    },
    channel: null,
};
const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.UserAnalyticAction.success:
            return Object.assign({}, state, {
                detailData: action.payload.users,
            });
        case types_2.SessionAnalyticAction.success:
            return Object.assign({}, state, {
                detailData: action.payload.sessions,
            });
        case types_2.UpdateAnalyticAction.setDuration:
            const params = action.params;
            return Object.assign({}, state, {
                duration: {
                    startDate: params.startDate,
                    endDate: params.endDate,
                },
            });
        case types_2.RealtimeAction.success:
            return Object.assign({}, state, {
                realtime: Object.assign({}, action.data),
            });
        case types_2.ConversionAnalyticAction.success:
            return Object.assign({}, state, {
                detailData: action.payload.conversions,
            });
        case types_2.ConversionRateAnalyticAction.success:
            return Object.assign({}, state, {
                detailData: action.payload.conversionRates,
            });
        case types_2.UpdateAnalyticAction.updateRealtime: {
            const { data } = action.params;
            const { session_id } = data;
            const { detailData } = state.realtime;
            const dataBySessionId = detailData.find((data) => data.session_id === session_id);
            if (!dataBySessionId) {
                //if not exist session_id, then this is new session, need to add into array
                return Object.assign(Object.assign({}, state), { realtime: Object.assign(Object.assign({}, state.realtime), { detailData: [...state.realtime.detailData, data], dataPerMinutes: Object.assign(Object.assign({}, state.realtime.dataPerMinutes), { [1]: state.realtime.dataPerMinutes[1] + 1 }) }) });
            }
            const indexItem = detailData.findIndex((data) => data.session_id === session_id);
            const newDetailData = [
                ...state.realtime.detailData.slice(0, indexItem),
                Object.assign(Object.assign({}, detailData[indexItem]), { last_event_at: data.last_event_at, page_close_at: data.page_close_at }),
                ...state.realtime.detailData.slice(indexItem + 1),
            ];
            const newTotalDataLastMinute = business_1.getTotalSessionsInMinute(newDetailData, 1);
            return Object.assign(Object.assign({}, state), { realtime: Object.assign(Object.assign({}, state.realtime), { detailData: newDetailData, dataPerMinutes: Object.assign(Object.assign({}, state.realtime.dataPerMinutes), { [1]: newTotalDataLastMinute }) }) });
        }
        case types_2.LandingpageOverviewAnalyticAction.success:
            return Object.assign(Object.assign({}, state), { projectsOverview: action.payload });
        case types_2.UpdateAnalyticAction.updateLastMinute: {
            const { realtime } = state;
            const dataPerMinutes = realtime.dataPerMinutes;
            const totalSessionsInLastMinute = business_1.getTotalSessionsInMinute(realtime.detailData, 1);
            const dataPerMinutesArr = Object.keys(dataPerMinutes).map((minuteNo) => dataPerMinutes[parseInt(minuteNo)]);
            dataPerMinutesArr.unshift(totalSessionsInLastMinute);
            const newDataPerMinutes = {};
            dataPerMinutesArr.forEach((total, idx) => (newDataPerMinutes[idx + 1] = total));
            return Object.assign(Object.assign({}, state), { realtime: Object.assign(Object.assign({}, state.realtime), { dataPerMinutes: newDataPerMinutes }) });
        }
        case types_2.UpdateAnalyticAction.updateChannel:
            return Object.assign(Object.assign({}, state), { realtime: Object.assign(Object.assign({}, state.realtime), { dataByChannels: business_1.getTotalSessionsByChannels(state.realtime.detailData) }) });
        case types_2.DashboardAnalyticAction.success:
            return Object.assign({}, state, {
                dashboard: action.payload,
            });
        case types_2.DashboardAnalyticAction.error:
            return Object.assign({}, state, {
                dashboard: initialState.dashboard,
            });
        case types_2.ChannelAnalyticAction.success:
        case types_2.ChannelAnalyticAction.error:
            return Object.assign({}, state, {
                channel: formatChannelData_1.formatChannelData(action.channel),
            });
        case action_namespace_1.GoToTeamAction.success:
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.analyticsReducer = analyticsReducer;
