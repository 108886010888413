"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userSagas = exports.updateCredentialSaga = exports.resendVerifyEmailSaga = exports.updateUserSaga = exports.getUserSaga = void 0;
const class_transformer_1 = require("class-transformer");
const effects_1 = require("redux-saga/effects");
const user_1 = require("../../entities/user");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
function* getUserSaga() {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/user`,
            authenticated: true
        };
        const userResponse = yield effects_1.call(api_1.callApi, request);
        const user = class_transformer_1.plainToClass(user_1.User, userResponse.data.data);
        yield effects_1.put({ type: types_1.GetUserAction.success, user });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.GetUserAction.error, err });
    }
}
exports.getUserSaga = getUserSaga;
function* updateUserSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/user`,
            authenticated: true,
            data: params
        };
        const userResponse = yield effects_1.call(api_1.callApi, request);
        const user = class_transformer_1.plainToClass(user_1.User, userResponse.data.data);
        yield effects_1.put({ type: types_1.UpdateUserAction.success, user, meta: action.meta, payload: user });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({ type: types_1.UpdateUserAction.error, payload, meta: action.meta, error: payload });
    }
}
exports.updateUserSaga = updateUserSaga;
function* resendVerifyEmailSaga(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/resend_email`,
            authenticated: true,
        };
        yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({ type: types_1.ResendUserAction.success, meta: action.meta });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({ type: types_1.ResendUserAction.error, meta: action.meta, payload, error: payload });
    }
}
exports.resendVerifyEmailSaga = resendVerifyEmailSaga;
function* updateCredentialSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/update_credentials`,
            authenticated: true,
            useMasterToken: true,
            data: params
        };
        const userResponse = yield effects_1.call(api_1.callApi, request);
        const user = class_transformer_1.plainToClass(user_1.User, userResponse.data.data);
        yield effects_1.put({ type: types_1.UpdateCredentialAction.success, user, meta: action.meta, payload: user });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({ type: types_1.UpdateCredentialAction.error, meta: action.meta, payload, error: payload });
    }
}
exports.updateCredentialSaga = updateCredentialSaga;
exports.userSagas = [
    effects_1.takeLatest(types_1.UpdateUserAction.type, updateUserSaga),
    effects_1.takeLatest(types_1.ResendUserAction.type, resendVerifyEmailSaga),
    effects_1.takeLatest(types_1.UpdateCredentialAction.type, updateCredentialSaga),
];
