import socket from 'components/socket';
import { store, User } from 'core';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import * as ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'stylesheets/App.css';
import 'stylesheets/index.css';
import Component from './route/router';
import {History} from 'src/route';
import { setupNumeral } from 'utils/setupNumeral';

toast.configure({
  autoClose: 5000,
  hideProgressBar: true
});

const Socket = () => {
  const history = new History();
  (globalThis as any).useHistory = history;

  const user: User = useSelector((state: any) => state.user.user);
  const [inRoom, setInRoom] = React.useState(false);
  const receive_event = (data: any) => {
  };
  React.useEffect(() => {
    if (user) {
      setInRoom(true);
    }
  });

  React.useEffect(() => {
    if (inRoom) {
      const room_id: any = user.id;
      socket.emit('joinRoom', room_id);
      socket.on('receiveEvent', receive_event);
    }
  }, [inRoom]);

  return null;
};

setupNumeral();

const element: HTMLElement | null = document.getElementById('root');
const ConnectedApp = (
  <CookiesProvider>
    <Provider store={store as any}>
      <Socket />
      <Router basename={`/${process.env.REACT_APP_PUBLIC_URL}`}>
        <Router>
          <Route component={Component} />
        </Router>
      </Router>
    </Provider>
  </CookiesProvider>
);

ReactDOM.render(ConnectedApp, element);
