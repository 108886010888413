import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'core';
import * as ReactModal from 'react-modal';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

export const EstimatePricingModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const user: User = useSelector((state: any) => state.user.user);
  const [stateUser, setStateUser] = useState(user);
  const { name, email, phone_no: phoneNo } = stateUser;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [user]);

  const closeModal = () => {
    props.closeModal();
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    // const { name, value } = event.currentTarget;
    // const newStateUser: User = Object.assign({}, stateUser, { [name]: value });
    // setStateUser(newStateUser);
    // to do
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="edit-sitelink-modal">
        <div className="modal__heading">
          <h3>Ước tính Lượt click & Giá thầu/click (CPC)</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-890 -65) translate(355 47) translate(527 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            <div className="input-group">
              <label>Chi phí hàng ngày</label>
              <div>200.000 ₫</div>
            </div>
          </div>
          <div className="modal__content--row row">
            <div className="col-lg-6">
              <div className="input-group">
                <label>Lượt click</label>
                <div>14</div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-group">
                <label>Giá thầu trung bình/click (CPC)</label>
                <div>14.286 ₫</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

EstimatePricingModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};
