import * as React from 'react';

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick: () => void | Promise<any>;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const withBaseButton = <P extends ButtonProps>(WrappedButton: React.ComponentType<P>): React.FC<P> => {
  const WithBaseButtonComponent: React.FC<P> = (props: P) => {
    const [clicked, setClicked] = React.useState(false);
    const mounted = React.useRef(false);
    React.useEffect(() => {
      mounted.current = true;
      return (): void => {
        mounted.current = false;
      };
    }, []);

    const onClick = async (): Promise<void> => {
      if (clicked) return;
      setClicked(true);
      try {
        await props.onClick();
        if (mounted.current === true) {
          setClicked(false);
        }
      } catch {
        if (mounted.current === true) {
          setClicked(false);
        }
      }
    };

    return (
      <WrappedButton {...(props as P)} onClick={onClick}>
        {props.children}
      </WrappedButton>
    );
  };

  return WithBaseButtonComponent;
};

const Button: React.FC<ButtonProps> = (props) => {
    const {onClick, className, isDisabled } = props;
    return(
      <button
        disabled={isDisabled}
        className={className}
        onClick={onClick}
      >
        {props.children}
      </button>
    );
};

const button = withBaseButton(Button);
export default button;
