"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefreshAction = exports.VerifyEmailAction = exports.LoginAction = void 0;
exports.LoginAction = {
    type: 'LOGIN',
    success: 'LOGIN_SUCCESS',
    error: 'LOGIN_ERROR',
};
exports.VerifyEmailAction = {
    type: 'VERIFY_EMAIL',
    success: 'VERIFY_EMAIL_SUCCESS',
    error: 'VERIFY_EMAIL_ERROR',
};
exports.RefreshAction = {
    type: 'REFRESH_ACTION',
    success: 'REFRESH_ACTION_SUCCESS',
    error: 'REFRESH_ACTION_ERROR',
};
