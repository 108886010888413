"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgeRange = void 0;
const types_1 = require("../../ads-campaign/types");
const ResourceOperation_1 = require("./ResourceOperation");
class AgeRange extends ResourceOperation_1.ResourceOperation {
    constructor() {
        super();
        this.type = types_1.GoogleAdsEnum.AgeRangeType.UNSPECIFIED;
        this.negative = false;
    }
    isEqual(age) {
        return this.type === age.type && this.negative === age.negative;
    }
    cloneResource() {
        const cloneAgeRange = new AgeRange;
        cloneAgeRange.type = this.type;
        cloneAgeRange.negative = this.negative;
        cloneAgeRange.operation = this.operation;
        cloneAgeRange.resource_name = this.resource_name;
        return cloneAgeRange;
    }
}
exports.AgeRange = AgeRange;
