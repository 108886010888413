import * as React from 'react';
import 'stylesheets/Pagination.css';
import { PaginationProps } from './index.d';

export default function Pagination(props: PaginationProps) {
  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    pageOptions,
    pageIndex,
    pageSize,
    setPageSize,
  } = props;
  const [pageNo, setPageNo] = React.useState(0);
  const gotoFirstPage = () => {
    gotoPage(0);
    setPageNo(0);
  };

  const gotoLastPage = () => {
    gotoPage(pageCount - 1);
    setPageNo(pageCount - 1);
  };

  const gotoPreviousPage = () => {
    previousPage();
    setPageNo(pageIndex - 1);
  };

  const gotoNextPage = () => {
    nextPage();
    setPageNo(pageIndex + 1);
  };

  if (pageIndex !== pageNo) {
    try {
      gotoPage(pageNo);
    } catch (e) {
      // TODO: handle error
    }
  }
  return (
    <div className="pagination">
      <button onClick={() => gotoFirstPage()} disabled={!canPreviousPage}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <path
                    fill="#FFF"
                    d="M0 0H14V14H0z"
                    opacity=".01"
                    transform="translate(-921 -592) translate(921 590) translate(0 2)"
                  />
                  <path
                    fill="#AEAEB2"
                    fillRule="nonzero"
                    d="M6.691 11.855c.305 0 .534-.228.534-.533 0-.146-.059-.281-.153-.38L2.807 6.77l4.265-4.172c.094-.1.153-.24.153-.381 0-.305-.229-.54-.534-.54-.152 0-.28.06-.38.159L1.67 6.377c-.111.094-.176.24-.176.393 0 .146.059.28.17.392l4.647 4.535c.1.1.228.158.38.158zm5.233 0c.305 0 .539-.228.539-.533 0-.146-.065-.281-.158-.38L8.045 6.77l4.26-4.172c.093-.1.158-.24.158-.381 0-.305-.234-.54-.54-.54-.146 0-.275.06-.38.159l-4.64 4.541c-.112.094-.176.24-.176.393 0 .146.064.28.175.392l4.641 4.535c.1.1.234.158.38.158z"
                    transform="translate(-921 -592) translate(921 590) translate(0 2)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>{' '}
      <button onClick={() => gotoPreviousPage()} disabled={!canPreviousPage}>
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <path
                    fill="#FFF"
                    d="M0 0H9V14H0z"
                    opacity=".01"
                    transform="translate(-945 -592) translate(921 590) translate(24 2)"
                  />
                  <path
                    fill="#AEAEB2"
                    fillRule="nonzero"
                    d="M6.691 11.855c.305 0 .534-.228.534-.533 0-.146-.059-.281-.153-.38L2.807 6.77l4.265-4.172c.094-.1.153-.24.153-.381 0-.305-.229-.54-.534-.54-.152 0-.28.06-.38.159L1.67 6.377c-.111.094-.176.24-.176.393 0 .146.059.28.17.392l4.647 4.535c.1.1.228.158.38.158z"
                    transform="translate(-945 -592) translate(921 590) translate(24 2)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <span>
        <strong>
          {pageIndex + 1}
        </strong>
      </span>
      <button onClick={() => gotoNextPage()} disabled={!canNextPage}>
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                    <path fill="#FFF" d="M0 0H9V14H0z" opacity=".01" transform="translate(-980 -592) translate(921 590) translate(59 2)"/>
                    <path fill="#AEAEB2" fillRule="nonzero" d="M2.027 11.861c.153 0 .282-.058.381-.158l4.647-4.54c.111-.112.17-.247.17-.393 0-.153-.059-.293-.17-.393l-4.64-4.535c-.106-.106-.235-.164-.388-.164-.304 0-.533.234-.533.539 0 .14.059.281.152.38L5.912 6.77 1.646 10.94c-.093.1-.152.235-.152.381 0 .305.229.54.533.54z" transform="translate(-980 -592) translate(921 590) translate(59 2)"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button onClick={() => gotoLastPage()} disabled={!canNextPage}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g fill="none" fillRule="evenodd">
              <g>
                  <g>
                      <g>
                          <path fill="#FFF" d="M0 0H14V14H0z" opacity=".01" transform="translate(-999 -592) translate(921 590) translate(78 2)"/>
                          <path fill="#AEAEB2" fillRule="nonzero" d="M7.266 11.855c.152 0 .28-.058.38-.158l4.641-4.535c.111-.111.176-.246.176-.392 0-.153-.065-.3-.176-.393l-4.64-4.541c-.106-.1-.229-.158-.381-.158-.305 0-.54.234-.54.539 0 .14.065.281.159.38L11.15 6.77 6.885 10.94c-.094.1-.158.235-.158.381 0 .305.234.533.539.533zm-5.239 0c.153 0 .282-.058.387-.158l4.64-4.535c.112-.111.17-.246.17-.392 0-.153-.058-.3-.17-.393L2.409 1.836c-.1-.1-.228-.158-.38-.158-.305 0-.534.234-.534.539 0 .14.059.281.152.38L5.912 6.77 1.646 10.94c-.093.1-.152.235-.152.381 0 .305.229.533.533.533z" transform="translate(-999 -592) translate(921 590) translate(78 2)"/>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      </button>
      <span className="pagination-page">
        Hiển thị
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        mục mỗi trang
      </span>
    </div>
  );
}
