"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdsAccount = exports.FanpageAdsAccount = exports.FacebookAdsAccount = exports.GoogleAdsAccount = void 0;
const datatypes_1 = require("../../ads-campaign/types/datatypes");
const class_transformer_1 = require("class-transformer");
class GoogleAdsAccount {
    constructor() {
        this.status = datatypes_1.GoogleAdsEnum.ConnectedAccountStatus.Connected;
        this.provider_account_id = '';
        this.name = '';
        this.account_type = 0;
        this.access_token = '';
        this.id = 0;
        this.extra_info = '';
        this.created_at = new Date();
    }
    getAccount() {
        return {
            provider_account_id: this.provider_account_id,
            name: this.name,
            account_type: this.account_type,
            access_token: this.access_token,
            status: this.status,
            extra_info: this.extra_info
        };
    }
    getAccountName() {
        return this.name;
    }
}
exports.GoogleAdsAccount = GoogleAdsAccount;
class FacebookAdsAccount extends GoogleAdsAccount {
}
exports.FacebookAdsAccount = FacebookAdsAccount;
class FanpageAdsAccount extends GoogleAdsAccount {
}
exports.FanpageAdsAccount = FanpageAdsAccount;
class AdsAccount {
    constructor() {
        this.google = [];
        this.facebook = [];
        this.fanpage = [];
    }
    getGoogleAccounts() {
        return this.google;
    }
}
__decorate([
    class_transformer_1.Type(() => GoogleAdsAccount)
], AdsAccount.prototype, "google", void 0);
__decorate([
    class_transformer_1.Type(() => FacebookAdsAccount)
], AdsAccount.prototype, "facebook", void 0);
__decorate([
    class_transformer_1.Type(() => FanpageAdsAccount)
], AdsAccount.prototype, "fanpage", void 0);
exports.AdsAccount = AdsAccount;
