import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { CropPoints, LandingPage, ProjectState } from 'core';
import { throttle } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetchUserLandingPages } from 'pages/landing-page/hooks';
import { useFetchListProjects } from 'pages/project/hooks';
import EditImage from 'pages/ads-creation/components/EditImage';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'stylesheets/Carousel.css';
import { AdFrameState, AdState, ExportMode, FrameType, ImageType } from './entities/Types';
import { Styles } from './styles/EditMediaAdsPage';
import PreviewSection from './preview/PreviewSection';
import { getSearchParams } from './preview/helper';
import { AdFrames } from './entities/AdFrames';
import { toast } from 'react-toastify';
import { History } from 'src/route/Route';

interface CustomerProps {
  projects: ProjectState;
  landingPages: {
    userLandingPages: Array<LandingPage>;
  };
}

type LocationState = {
  adEditState?: AdState;
} | undefined;

const getOrCreateDefaultState = (locationState: LocationState): AdState => {
  if (!locationState || !locationState.adEditState) {
    return {
      isEditing: true,
      exportMode: ExportMode.mode_191_1,
      imageType: ImageType.SINGLE_IMAGE,
      frameType: FrameType.Frame1,
      logoImageSrc: '',
      frameStates: new Array<AdFrameState>()
    };
  }

  return {
    ...locationState.adEditState,
    isEditing: true
  };
};

const MaxWidthEditImage = {
  ExportMode_19_1: 622,
  ExportMode_1_1: 500,
  ExportMode_4_5: 400
};

interface WarningMsgProps {
  message: string;
  submitButtonLabel: string;
  onSubmitButtonClick?: () => void;
  cancelButtonLabel: string;
  onCancelButtonClick?: () => void;
}

const WarningMsg = (props: WarningMsgProps) => {
  const {message, submitButtonLabel, onSubmitButtonClick, cancelButtonLabel, onCancelButtonClick } = props;

  const onCancelClick = () => {
    if (onCancelButtonClick) {
      onCancelButtonClick();
    }
  };

  const onSubmitClick = () => {
    if (onSubmitButtonClick) {
      onSubmitButtonClick();
    }
  };

  return (
    <div>
      {message}
      <div className="d-flex justify-content-between">
        <button className="button-link" onClick={onSubmitClick}>{submitButtonLabel}</button>
        <button className="button-link" onClick={onCancelClick}>{cancelButtonLabel}</button>
      </div>
    </div>
  );
};

const EditMediaAds = (props: CustomerProps) => {
  //State from <Link> of Preview
  const { state } = useLocation<LocationState>();
  const history = new History();
  if (!state || !state.adEditState) {
    history.go(-1);
  }

  const [adState, setAdState] = useState<AdState>(getOrCreateDefaultState(state));
  const searchParams = getSearchParams(useLocation);
	const { landingPageId } = searchParams;

  const [hasChanged, setHasChanged] = useState(false);
  const [imageWidth, setImageWidth] = useState(MaxWidthEditImage.ExportMode_19_1);
  const imageWrapRef2 = useRef<HTMLDivElement>(null);
  let slider: Slider;
  const landingPages: Array<LandingPage> = props.landingPages.userLandingPages;
  const [doDownLoad, setDoDownLoad] = useState(false);
  const adStatePropRef = useRef(adState);
  adStatePropRef.current = adState;

  useFetchListProjects(props.projects);
  useFetchUserLandingPages(landingPages);

  const goBackHistory = () => {
    const onSubmitButtonClick = () => {
      history.go(-1);
    };
    if (hasChanged) {
      toast.warn(
        <WarningMsg 
          message='Dường như bạn đã chỉnh sửa một số nội dung. Vui lòng chọn Tải xuống trước khi rời trang để đảm bảo các thay đổi không bị mất.'
          cancelButtonLabel='Quay lại'
          submitButtonLabel='Đã hiểu, tiếp tục đóng'
          onSubmitButtonClick={onSubmitButtonClick}
        />, 
        {
          position: 'top-left',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
     
      return;
    }
    history.go(-1);
  };

  const settingsCarousel = {
    dots: true,
    infinite: false,
    speed: 500,
    centerPadding: '15px',
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const aspectTypes = [
    {
      id: ExportMode.mode_1_1,
      value: 1,
      name: 'Hình vuông - 1 : 1',
    },
    {
      id: ExportMode.mode_191_1,
      value: 1.91,
      name: 'Hình chữ nhật theo chiều ngang - 1,91 : 1',
    },
    {
      id: ExportMode.mode_4_5,
      value: 0.8,
      name: 'Hình chữ nhật theo chiều dọc - 4 : 5',
    },
  ];

  const calculateEditImageWidth = (exportMode: ExportMode): number => {
    let editImageWidth = MaxWidthEditImage.ExportMode_19_1;
    if (imageWrapRef2.current) {
      switch (exportMode) {
        case ExportMode.mode_191_1:
          editImageWidth = imageWrapRef2.current.clientWidth;
          break;
        case ExportMode.mode_1_1:
          editImageWidth = imageWrapRef2.current.clientWidth > MaxWidthEditImage.ExportMode_1_1 ? MaxWidthEditImage.ExportMode_1_1 : imageWrapRef2.current.clientWidth;
          break;
        case ExportMode.mode_4_5:
          editImageWidth = imageWrapRef2.current.clientWidth > MaxWidthEditImage.ExportMode_4_5 ? MaxWidthEditImage.ExportMode_4_5 : imageWrapRef2.current.clientWidth;
          break;
      }
    } else {
      switch (exportMode) {
        case ExportMode.mode_191_1:
          editImageWidth = MaxWidthEditImage.ExportMode_19_1;
          break;
        case ExportMode.mode_1_1:
          editImageWidth = MaxWidthEditImage.ExportMode_1_1;
          break;
        case ExportMode.mode_4_5:
          editImageWidth = MaxWidthEditImage.ExportMode_4_5;
          break;
      }
    }
    return editImageWidth;
  };

  useEffect(() => {
    let unmounted = false;
    if (adState.frameType === FrameType.Frame4) {
      slider.slickGoTo(3);
    }
    const calculateEditImageWidth1 = () => {    
      if (!unmounted) {
        setImageWidth(calculateEditImageWidth(adState.exportMode));
      }
    };

    calculateEditImageWidth1();
    const calculateEditImageWidthThrottle = throttle(calculateEditImageWidth1, 300);
    window.addEventListener('resize', calculateEditImageWidthThrottle);

    return () => {
      unmounted = true;
      window.removeEventListener('resize', calculateEditImageWidthThrottle);
    };
  }, []);

  const onExportModeChange = (e: React.FormEvent<HTMLSelectElement>) => {    
    let newFrameStates: Array<AdFrameState> | undefined;
    if (!adStatePropRef.current.frameStates || adStatePropRef.current.frameStates.length === 0) {
      newFrameStates = adStatePropRef.current.frameStates;
    } else {
      newFrameStates = adStatePropRef.current.frameStates.map((frame, i) => {
        return {
          ...frame,
          cropArea: undefined
        };
      });
    }
    
    setAdState({
      ...adStatePropRef.current,
      frameStates: newFrameStates,
      exportMode: ExportMode[e.currentTarget.value]
    });
    
    setImageWidth(calculateEditImageWidth(ExportMode[e.currentTarget.value]));
    setHasChanged(true);
  };

  const onChangeTitle = (e: React.FormEvent<HTMLInputElement>, index: number) => {
    if (!adStatePropRef.current.frameStates || adStatePropRef.current.frameStates.length === 0) {
      return;
    }

    const newFrameStates = adStatePropRef.current.frameStates.map((frame, i) => {
      if (i === index) {
        return {
          ...frame,
          title: e.currentTarget.value
        };
      }
      return frame;
    });
    
    setAdState({
      ...adStatePropRef.current,
      frameStates: newFrameStates
    });
    setHasChanged(true);
  };

  const onChangeBody = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    if (!adStatePropRef.current.frameStates || adStatePropRef.current.frameStates.length === 0) {
      return;
    }
    const newFrameStates = adStatePropRef.current.frameStates.map((frame, i) => {
      if (i === index) {
        return {
          ...frame,
          body: e.currentTarget.value
        };
      }
      return frame;
    });
    
    setAdState({
      ...adStatePropRef.current,
      frameStates: newFrameStates
    });
    setHasChanged(true);
  };

  const onChangeFrameType = (e: React.FormEvent<HTMLInputElement>) => {
    onClickFrameImage(FrameType[e.currentTarget.value]);
  };

  const onClickFrameImage = (frameType: FrameType) => {
    setAdState({
      ...adStatePropRef.current,
      frameType: frameType
    });
    setHasChanged(true);
  };

  const onEditBkImageCallback = (index: number, src: string, cropArea?: CropPoints) => {
    if (!adStatePropRef.current.frameStates || adStatePropRef.current.frameStates.length === 0) {
      return;
    }
    const newFrameStates = adStatePropRef.current.frameStates.map((frame, i) => {
      if (i === index) {
        return {
          ...frame,
          bkImageSrc: src,
          cropArea: cropArea
        };
      }
      return frame;
    });
    
    setAdState({
      ...adStatePropRef.current,
      frameStates: newFrameStates
    });
    setHasChanged(true);
  };

  const onEditLogoImageCallback = (src: string, cropArea?: CropPoints) => {
    setAdState({
      ...adStatePropRef.current,
      logoImageSrc: src
    });
    setHasChanged(true);
  };

  const renderPreview = () => {
    return (
      <PreviewSection
        className="col-lg-3 fixed-position"
        shouldShowToolbars={false}
        doDownLoad={doDownLoad}
        adStateProp={adState}
      />
    );
  };

  const renderFrameImages = () => {
    const renderSingleImageFrames = () => {
      const frameConfigData = new AdFrames().FrameConfigData;
      return (
        <Slider ref={(component: Slider) => { slider = component; }} className="select-frame-carousel" {...settingsCarousel}>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame1)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame1} value={FrameType.Frame1} />
            <img src={frameConfigData.Frame1[adState.exportMode].imgSrc} className="img-fluid" />
          </div>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame2)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame2} value={FrameType.Frame2} />
            <img src={frameConfigData.Frame2[adState.exportMode].imgSrc} className="img-fluid" />
          </div>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame3)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame3} value={FrameType.Frame3} />
            <img src={frameConfigData.Frame3[adState.exportMode].imgSrc} className="img-fluid" />
          </div>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame4)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame4} value={FrameType.Frame4} />
            <img src={frameConfigData.Frame4[adState.exportMode].imgSrc} className="img-fluid" />
          </div>
        </Slider>
      );
    };

    const renderCarouselFrames = () => {
      const carouselConfigData = new AdFrames().CarouselConfigData;
      return (
        <Slider ref={(component: Slider) => { slider = component; }} className="select-frame-carousel" {...settingsCarousel}>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame1)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame1} value={FrameType.Frame1} />
            <img src={carouselConfigData.Frame1.frames[0].imgSrc} className="img-fluid" />
          </div>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame2)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame2} value={FrameType.Frame2} />
            <img src={carouselConfigData.Frame2.frames[0].imgSrc} className="img-fluid" />
          </div>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame3)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame3} value={FrameType.Frame3} />
            <img src={carouselConfigData.Frame3.frames[0].imgSrc} className="img-fluid" />
          </div>
          <div className="frame-carousel__item" onClick={() => onClickFrameImage(FrameType.Frame4)}>
            <input className="radioSelectFrame" type="radio" name="frame-ads" onChange={onChangeFrameType} checked={adState.frameType === FrameType.Frame4} value={FrameType.Frame4} />
            <img src={carouselConfigData.Frame4.frames[0].imgSrc} className="img-fluid" />
          </div>
        </Slider>
      );
    };

    switch (adState.imageType) {
      case ImageType.SINGLE_IMAGE:
        return renderSingleImageFrames();
      default:
        return renderCarouselFrames();
    }
  };

  const renderLogoComponent = () => {
    return (
      <div>
        <div className="d-flex">
          <label>Logo</label>
        </div>
        <div className="edit-logo-wrapper">
          <EditImage imgSrc={adState.logoImageSrc} disableCrop={true} width={128} showButtons={true} aspect={1} landingPageId={landingPageId} onEditImageCallback={onEditLogoImageCallback} />
        </div>
      </div>
    );
  };

  const renderSinglePhoto = () => {
    if (adState.imageType !== ImageType.SINGLE_IMAGE) {
      return <div />;
    }

    if (!adState.frameStates || adState.frameStates.length === 0) {
      return <div />;
    }

    let aspectType = aspectTypes.find((x) => {return x.id === adState.exportMode;});
    if (!aspectType) {
      aspectType = aspectTypes[0];
    }

    return (
      <div className="col-lg-7 offset-lg-1">
        <div className="box-shadow">
          <div className="box__title">Hình ảnh quảng cáo</div>
          <div className="box__content" ref={imageWrapRef2}>
            <EditImage imgSrc={adState.frameStates[0].bkImageSrc} width={imageWidth - 30} showButtons={true} aspect={aspectType.value} disableCrop={false} landingPageId={landingPageId} cropArea={adState.frameStates[0].cropArea} onEditImageCallback={(src: string, cropArea?: CropPoints) => onEditBkImageCallback(0, src, cropArea)} />
            <div className="form-group">
              <label>Kiểu hình ảnh</label>
              <select className="w-100 form-control" onChange={onExportModeChange} defaultValue={aspectType.id}>
                {aspectTypes.map((aspect) => (
                  <option value={aspect.id} key={aspect.id}>
                    {aspect.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex mt-3">
              <label>Khung ảnh</label>
            </div>
            {renderFrameImages()}
            <div className="d-flex">
              {renderLogoComponent()}
              <div className="flex-1 ml-4">
                <div className="form-group">
                  <label>Tiêu đề</label>
                  <input className="form-control" type="text" name="caption-title" value={adState.frameStates[0].title} onChange={(e: React.FormEvent<HTMLInputElement>) => onChangeTitle(e, 0)} />
                </div>
                <div className="form-group">
                  <label>Nội dung</label>
                  <TextareaAutosize
                    name="caption-description"
                    rows={1}
                    className="form-control"
                    placeholder="Nội dung"
                    defaultValue={adState.frameStates[0].body}
                    onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeBody(e, 0)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCarouselPhoto = () => {
    const renderCarouselFrame = (frame: AdFrameState, index: number) => {
      if (index === 0) {
        return (
          <div className="col-lg-6">
            <div className={`media__item--${index + 1}`} ref={imageWrapRef2}>
              <EditImage imgSrc={frame.bkImageSrc} width={imageWidth} showButtons={true} aspect={1} disableCrop={false} landingPageId={landingPageId} cropArea={frame.cropArea} onEditImageCallback={(src: string, cropArea?: CropPoints) => {onEditBkImageCallback(index, src, cropArea);}} />
              <div className="media__item--title">Ảnh {index + 1}</div>
              <div className="form-group">
                <label>Tiêu đề</label>
                <input className="form-control" type="text" name="caption-title" value={frame.title} onChange={(e: React.FormEvent<HTMLInputElement>) =>{onChangeTitle(e, index);}} />
              </div>
              <div className="form-group">
                <label>Nội dung</label>
                <TextareaAutosize
                  name="caption-description"
                  rows={1}
                  className="form-control"
                  placeholder="Nội dung"
                  defaultValue={frame.body}
                  onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeBody(e, index)}
                />
              </div>
            </div>
          </div>
        );
      }
  
      return (
        <div className="col-lg-6">
          <div className={`media__item--${index + 1}`}>
            <EditImage imgSrc={frame.bkImageSrc} width={imageWidth} showButtons={true} aspect={1} disableCrop={false} landingPageId={landingPageId} cropArea={frame.cropArea} onEditImageCallback={(src: string, cropArea?: CropPoints) => {onEditBkImageCallback(index, src, cropArea);}} />
            <div className="media__item--title">Ảnh {index + 1}</div>
            <div className="form-group">
              <label>Tiêu đề</label>
              <input className="form-control" type="text" name="caption-title" value={frame.title} onChange={(e: React.FormEvent<HTMLInputElement>) =>{onChangeTitle(e, index);}} />
            </div>
            <div className="form-group">
              <label>Nội dung</label>
              <TextareaAutosize
                name="caption-description"
                rows={1}
                className="form-control"
                placeholder="Nội dung"
                defaultValue={frame.body}
                onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeBody(e, index)}
              />
            </div>
          </div>
        </div>
      );
    };

    if (adState.imageType !== ImageType.CAROUSEL) {
      return <div />;
    }

    if (!adState.frameStates || adState.frameStates.length === 0) {
      return <div />;
    }

    return (
      <div className="col-lg-7 offset-lg-1">
        <div className="box-shadow">
          <div className="box__title">Hình ảnh quảng cáo</div>
          <div className="box__content">
            <div className="row">
              {adState.frameStates.map((frame, index) => {
                return renderCarouselFrame(frame, index);
              })}
            </div>
            <div className="d-flex mt-3">
              <label>Khung ảnh</label>
            </div>
            {renderFrameImages()}
            <div className="d-flex">
              {renderLogoComponent()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onDownload = () => {
    setDoDownLoad(true);
    setHasChanged(false);
    setTimeout(() => {
      setDoDownLoad(false);
    }, 1000 );
  };

  const renderDownLoadButton = () => {
    if (doDownLoad) {
      return (
        <button 
          disabled
          className="btn btn-default float-right btn-download"
          onClick={onDownload}
        >Tải xuống</button>
      );
    }

    return (
      <button 
        className="btn btn-default float-right btn-download"
        onClick={onDownload}
      >Tải xuống</button>
    );
  };

  return (
    <Styles>
      {/* <FullHeader /> */}
      <div className="container overflow-visible">
        <div className="page-heading">
          <div className="d-flex justify-content-start align-items-start">
            <span className="goBackLink" onClick={goBackHistory}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <g fill="none" fillRule="evenodd">
                      <g fill="#000" fillRule="nonzero">
                          <g>
                              <g>
                                  <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-38 -38) translate(30 30) translate(8 8)"/>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
            </span>
          </div>
        </div>
        <div className="row">
          {renderSinglePhoto()}
          {renderCarouselPhoto()}
          {renderPreview()}
        </div>
        <div className={'panel-fixed-bottom full button-group'}>
          <div className="container">
            <div className="col-lg-10 offset-lg-1">
              {renderDownLoadButton()}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

const mapStateToProps = (state: any) => {
  const { projects, landingPages } = state;
  return {
    projects,
    landingPages,
  };
};

const EditMediaAdsPage = connect(mapStateToProps)(EditMediaAds);
export default EditMediaAdsPage;
