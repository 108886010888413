"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Project = exports.ProjectCategories = exports.ProjectTypes = void 0;
var ProjectTypes;
(function (ProjectTypes) {
    ProjectTypes["APARTMENT"] = "apartment";
    ProjectTypes["SHOPHOUSE_VILLA"] = "shophouse";
    ProjectTypes["LAND"] = "land";
    ProjectTypes["CONDOTEL"] = "condotel";
    ProjectTypes["OTHER"] = "other";
})(ProjectTypes = exports.ProjectTypes || (exports.ProjectTypes = {}));
var ProjectCategories;
(function (ProjectCategories) {
    ProjectCategories["user"] = "user";
    ProjectCategories["system"] = "template";
})(ProjectCategories = exports.ProjectCategories || (exports.ProjectCategories = {}));
class Project {
    constructor(id, name, user_id, investor, location, design_company, site_area, scale, commencement_date, delivery_date, procedure, unit_detail, unit_price, total_investment, legality, construction_percentage, detail_location, utility, floor_layout, payment_policy, created_at, num_salesperson, num_landing_pages, thumbnail, is_template, has_pictures, model_house, progress, design, furniture, type_code, is_owner) {
        this.id = id;
        this.name = name;
        this.user_id = user_id;
        this.investor = investor;
        this.location = location;
        this.design_company = design_company || '';
        this.site_area = site_area || '';
        this.scale = scale || '';
        this.commencement_date = commencement_date || '';
        this.delivery_date = delivery_date || '';
        this.procedure = procedure || '';
        this.unit_detail = unit_detail || '';
        this.unit_price = unit_price || '';
        this.total_investment = total_investment || '';
        this.legality = legality || '';
        this.construction_percentage = construction_percentage || '';
        this.detail_location = detail_location || '';
        this.utility = utility || '';
        this.floor_layout = floor_layout || '';
        this.payment_policy = payment_policy || '';
        this.created_at = created_at || '';
        this.num_salesperson = num_salesperson;
        this.num_landing_pages = num_landing_pages;
        this.thumbnail = thumbnail;
        this.is_template = is_template || false;
        this.has_pictures = has_pictures || false;
        this.model_house = model_house || '';
        this.progress = progress || '';
        this.design = design || '';
        this.furniture = furniture || '';
        this.type_code = type_code || ProjectTypes.OTHER;
        this.is_owner = is_owner || false;
    }
    get isSystemProject() {
        return this.is_template;
    }
    isFulfilledProject() {
        switch (this.type_code) {
            case ProjectTypes.SHOPHOUSE_VILLA:
                return (!!this.name &&
                    !!this.investor &&
                    !!this.location &&
                    !!this.detail_location &&
                    !!this.payment_policy &&
                    this.has_pictures);
            case ProjectTypes.APARTMENT:
            case ProjectTypes.CONDOTEL:
            case ProjectTypes.LAND:
            default:
                return (!!this.name &&
                    !!this.investor &&
                    !!this.location &&
                    !!this.detail_location &&
                    !!this.payment_policy &&
                    !!this.utility &&
                    this.has_pictures);
        }
    }
    isOwnership() {
        return this.is_owner;
    }
    getKeywordGroup() {
        const projectName = this.name || 'Dự án';
        const floorplanByType = {
            [ProjectTypes.APARTMENT]: ['mặt bằng tổng quan ' + projectName, 'mặt bằng chi tiết ' + projectName],
            [ProjectTypes.SHOPHOUSE_VILLA]: ['mặt bằng tổng quan ' + projectName, 'mặt bằng chi tiết ' + projectName],
            [ProjectTypes.LAND]: ['mặt bằng phân lô ' + projectName],
            [ProjectTypes.CONDOTEL]: ['mặt bằng tổng quan ' + projectName, 'mặt bằng chi tiết ' + projectName],
            [ProjectTypes.OTHER]: ['mặt bằng ' + projectName]
        };
        const location = ['Vị trí ' + projectName];
        const floorplan = floorplanByType[this.type_code] ? floorplanByType[this.type_code] : [];
        const policy = ['chính sách bán hàng ' + projectName, 'bảng giá ' + projectName];
        return {
            projectName,
            location,
            floorplan,
            policy,
        };
    }
}
exports.Project = Project;
