import styled from 'styled-components';

export const Styles = styled.div`
  .page-heading {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    left: 30px;
    top: 0;
    z-index: 1;
  }
  .page-heading .goBackLink {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .page-heading p {
    margin-bottom: 0;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    color: #666666;
  }
  .page-heading p span {
    font-family: Propins-Medium;
  }
  .page-title {
    font-size: 20px;
    letter-spacing: -0.4px;
  }
  label {
    text-align: left;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000;
    margin: 0;
    margin-bottom: 10px;
  }
  .input-group {
    margin-bottom: 40px;
  }
  .filter-container select {
    padding: 15px 20px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    height: 52px;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.32px;
    color: #000000;
    border-radius: 6px !important;
    margin-bottom: 40px;
  }
  .box-shadow {
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .box-shadow {
    margin-top: 30px;
    margin-bottom: 98px;
  }
  .fixed-position .box-shadow.preview-box {
    position: fixed;
    right: calc((100% - 1262px) / 2);
    max-width: calc(3/12 * 1562px - 30px);
  }
  @media (max-width: 1599px) {
    .fixed-position .box-shadow.preview-box {
      top: 0;
      right: calc((100% - 905px) / 2);
      max-width: calc(3/12 * 1124px - 30px);
    }
  }
  .box__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
    padding: 15px;
    border-bottom: 1px solid #d8d8d8;
  }
  .box__title.small {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .box__content {
    padding: 15px;
    width: 100%;
  }
  .box__content--left {
    background-color: #f7f8f8;
  }
  .choose-item {
    padding: 10px;
  }
  .box__content--left .choose-item.active {
    background-color: rgba(76, 124, 243, 0.08);
  }
  .box__buttons {
    display: flex;
  }
  .box__buttons .btn-submit,
  .box__buttons .btn-default {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 12px;
  }
  .box__buttons svg {
    margin-right: 4px;
  }
  .choose-item.active .choose-item__wrapper {
    border-color: #4c7cf3;
    color: #4c7cf3;
  }
  .switch-button {
    position: relative;
    width: 44px;
    height: 25px;
    border: 0;
    border-radius: 12.5px;
    margin-bottom: 10px;
    background-color: #ccc;
  }
  .switch-button.active {
    background-color: #4c7cf3;
  }
  .switch-toggle {
    -webkit-transition: .4s;
    transition: .4s;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    border: solid 0.5px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
  }
  .select-frame-carousel {
    margin-bottom: 38px;
  }
  .media-image-wrapper {
    overflow: hidden;
    display: flex;
    position: relative;
    justify-content: center;
    align-self: center;
    display: inline-block;
  }
  .media-image-wrapper.image-logo {
    width: 128px;
    height: 128px;
    border-radius: 6px;
    border: solid 0.5px rgba(216, 216, 216, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .media-image-buttons {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
  }
  .upload-photo-button, .crop-photo-button {
    width: 48px;
    height: 36px;
    border-radius: 6px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
    border: 0;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .media__item--title {
    font-family: Propins-Regular;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: center;
    color: #666666;
    padding-bottom: 15px;
  }
  .frame-carousel__item {
    position: relative;
    cursor: pointer;
  }
  .frame-carousel__item img {
    height: 200px;
    width: 100%;
    border: solid 0.5px rgba(216, 216, 216, 0.5);
  }
  .radioSelectFrame {
    position: absolute;
    top: 8px;
    left: 15px;
    width: auto;
    z-index: 1;
  }
  .placeholder-wrapper {
    width: 0px;
    animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  .img-placeholder {
    width: 100%;
    height: 326px;
  }
  .img-placeholder.fill-height {
    height: 100%;
  }
  .frame-carousel__item {
    padding: 0 7.5px;
  }
`;