import * as React from 'react';
import { ButtonProps } from 'presenter';
import Button from './Button';
import 'stylesheets/Button.css';

type PropsType = ButtonProps & {
  activeClassName?: string;
  disabledClassName?: string;
  alignLeft?: boolean;
  alignRight?: boolean;
}

const SubmitButton: React.FC<PropsType> = (props) => {
  const activeClassName = props.activeClassName || 'btn-default';
  const disabledClassName = props.disabledClassName || 'btn-disabled';
  const aL = props.alignLeft ? 'mr-3' : '';
  const aR = props.alignRight ? 'ml-3' : '';
  let buttonClasses = `${aL} ${aR}`;
  if (props.isDisabled) {
    buttonClasses += ` ${disabledClassName}`;
  } else {
    buttonClasses += ` ${activeClassName}`;
  }

  return (
    <Button
      className={buttonClasses}
      isDisabled={props.isDisabled}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export const PrimaryButton: React.FC<PropsType> = (props) => {
  const activeClassName = props.activeClassName || 'btn-default';
  const disabledClassName = props.disabledClassName || 'btn-disabled';
  const aL = props.alignLeft ? 'mr-3' : '';
  const aR = props.alignRight ? 'ml-3' : '';
  let buttonClasses = `${aL} ${aR}`;
  if (props.isDisabled) {
    buttonClasses += ` ${disabledClassName}`;
  } else {
    buttonClasses += ` ${activeClassName}`;
  }

  return (
    <button
      className={buttonClasses}
      disabled={props.isDisabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default SubmitButton;

