import * as React from 'react';
import { useRef, useEffect } from 'react';
import * as ReactModal from 'react-modal';
import { Link } from 'react-router-dom';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

const VerifyEmailSuccessModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="emai-verified-modal">
        <div className="modal__heading">
          <h3>Kích hoạt tài khoản thành công</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          Tài khoản của bạn đã được kích hoạt thành công. Giờ đây bạn có thể sử dụng mọi tính năng của Cokhach.vn.<br /><br />
          <Link to={(globalThis as any).useHistory.generateComponent('PricingPage')}>Nâng cấp tài khoản</Link> để nhận không giới hạn thông tin khách hàng.
        </div>
        <div className="modal__bottom">
          <button onClick={closeModal} className="btn-default">Vào Dashboard</button>
        </div>
      </div>
    </ReactModal>
  );
};

VerifyEmailSuccessModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      height: '240px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default VerifyEmailSuccessModal;
