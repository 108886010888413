import * as React from 'react';
import { useRef, useEffect } from 'react';
import * as ReactModal from 'react-modal';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

const Modal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      {props.children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '600px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default Modal;
