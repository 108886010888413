"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adsCampaignSagas = exports.fetchConnectedAccountSaga = exports.connectAccountSaga = exports.fetchGGAdsCustomerSaga = exports.fetchFanpageSaga = exports.getFbAdAccountSaga = void 0;
const effects_1 = require("redux-saga/effects");
const api_1 = require("../../adapters/api");
const class_transformer_1 = require("class-transformer");
const types_1 = require("../types");
const saga_service_1 = require("./saga.service");
const saga_google_1 = require("./saga.google");
const src_1 = require("src");
const GoogleAds_1 = require("../../entities/GoogleAds");
function* getFbAdAccountSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/fb_ad_account`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetFbAdAccount.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetFbAdAccount.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getFbAdAccountSaga = getFbAdAccountSaga;
function* fetchFanpageSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/fanpages`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.FetchFanpage.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.FetchFanpage.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchFanpageSaga = fetchFanpageSaga;
function* fetchGGAdsCustomerSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/customers`,
            authenticated: true,
            params,
            setUserToken: params.setUserToken
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.FetchGGAdsCustomer.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.FetchGGAdsCustomer.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchGGAdsCustomerSaga = fetchGGAdsCustomerSaga;
function* connectAccountSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/create_connected_account`,
            authenticated: true,
            data: params.accounts,
            setUserToken: params.setUserToken
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.ConnectAccount.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.ConnectAccount.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.connectAccountSaga = connectAccountSaga;
function* fetchConnectedAccountSaga(action) {
    const params = action.params;
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/connected_account`,
            authenticated: true,
            setUserToken: params.setUserToken
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const { ConnectedAccountType } = types_1.GoogleAdsEnum;
        const res = response.data.data;
        const connectedAccount = new GoogleAds_1.AdsAccount();
        connectedAccount.google = class_transformer_1.plainToClass(src_1.GoogleAdsAccount, res.filter((acc) => acc.account_type === ConnectedAccountType.GoogleAds));
        connectedAccount.facebook = class_transformer_1.plainToClass(src_1.FacebookAdsAccount, res.filter((acc) => acc.account_type === ConnectedAccountType.FacebookAds));
        connectedAccount.fanpage = class_transformer_1.plainToClass(src_1.FanpageAdsAccount, res.filter((acc) => acc.account_type === ConnectedAccountType.FacebookFanpage));
        const payload = connectedAccount;
        yield effects_1.put({
            type: types_1.FetchConnectedAccount.success,
            payload,
            meta: action.meta
        });
    }
    catch (error) {
        console.log(error);
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.FetchConnectedAccount.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchConnectedAccountSaga = fetchConnectedAccountSaga;
exports.adsCampaignSagas = [
    effects_1.takeLatest(types_1.GetFbAdAccount.type, getFbAdAccountSaga),
    effects_1.takeLatest(types_1.FetchFanpage.type, fetchFanpageSaga),
    effects_1.takeLatest(types_1.FetchGGAdsCustomer.type, fetchGGAdsCustomerSaga),
    effects_1.takeLatest(types_1.GetDefaultExtension.type, saga_service_1.getDefaultExtension),
    effects_1.takeLatest(types_1.GetDefaultComponents.type, saga_service_1.getDefaultComponents),
    effects_1.takeLatest(types_1.GetListKeywordSuggestion.type, saga_service_1.getDefaultKeywords),
    // takeLatest(GetAllGoogleCampaign.type, getAllGoogleCampaign),
    effects_1.takeLatest(types_1.CreateGoogleCampaign.type, saga_google_1.createGoogleCampaign),
    effects_1.takeLatest(types_1.GetListAdGroupByCampaign.type, saga_google_1.getListAdGroupAdByCampaign),
    effects_1.takeLatest(types_1.CreateOrUpdateAdGroupAd.type, saga_google_1.createOrUpdateAdGroupAd),
    effects_1.takeLatest(types_1.GetAdGroupsBelongToCampaign.type, saga_google_1.getAdGroupsBelongToCampaign),
    effects_1.takeLatest(types_1.ConnectAccount.type, connectAccountSaga),
    effects_1.takeLatest(types_1.FetchConnectedAccount.type, fetchConnectedAccountSaga),
    effects_1.takeLatest(types_1.GetLocationAds.type, saga_google_1.getLocationAds),
    effects_1.takeLatest(types_1.CreateOrUpdateAdGroup.type, saga_google_1.createOrUpdateAdGroup),
    effects_1.takeLatest(types_1.UpdateGoogleCampaignStatus.type, saga_google_1.updateCampaignStatus),
    effects_1.takeLatest(types_1.GetDetailGoogleCampaign.type, saga_google_1.fetchGoogleCampaign),
    effects_1.takeLatest(types_1.DisconnectAdsAccount.type, saga_google_1.disconnectAdsAccount),
    effects_1.takeLatest(types_1.GetLPWithGoogleAdsCampaign.type, saga_service_1.getLPWithGoogleAdsCampaign),
];
