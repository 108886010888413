import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import * as ReactModal from 'react-modal';
import { useGoogleForm } from 'utils/hooks';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  submit: () => void;
  reviewAds: () => void;
  closeModal: () => void;
  isLoading?: boolean;
}
ReactModal.setAppElement('#root');

export const WarningLandingPageRunningAds: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  // const fbAccessToken = useSelector((state) => state.adsCampaign.fbAccessToken);

  const history = (globalThis as any).useHistory;
  history.hook();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const submit = () => {
    props.submit();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={()=>{}} style={props.customStyles}>
      <div className="connect-account-modal">
          <div className="modal__heading">
            <h3>Thông báo</h3>
          </div>
          <div className="modal__content">
            {
              props.isLoading 
              ? 'Đang tải dữ liệu, vui lòng đợi...' 
              : 'Landing page này đang được sử dụng để chạy quảng cáo trên một tài khoản quảng cáo khác. Để tạo chiến dịch cho trang này trên tài khoản quảng cáo hiện tại, bạn nên dừng các quảng cáo đang chạy cho trang để tránh trùng lặp và lãng phí chi phí.'
            }
          </div>
          <div className="modal__bottom">
            <button onClick={closeModal} className="btn-subsidiary btn-grey-suit">Huỷ</button>
            <button onClick={props.reviewAds} className="btn-subsidiary ml-3">Xem quảng cáo</button>
            <button onClick={submit} className="btn-default ml-3">Dừng quảng cáo</button>
          </div>
      </div>
    </ReactModal>
  );
};

WarningLandingPageRunningAds.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '530px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};