"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentService = void 0;
const types_1 = require("../types");
const ApiAction_1 = require("../../actions/ApiAction");
class PaymentService {
    constructor() { }
    fetchPackagesList(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.FetchPackagesList.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    fetchMethodsList(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.FetchMethodsList.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    createShoppingCart(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.CreateShoppingCart.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        Promise.resolve(apiAction.triggerAction()).then((response) => {
            params.callBackParams && params.callBackParams.successCallback && params.callBackParams.successCallback(response);
            switch (params.inputParams.payment_method) {
                case types_1.MethodId.Bank_Transfer:
                    const getBankTransferInfoParams = {
                        inputParams: {
                            shopping_cart_id: response.data.shopping_cart.id,
                            team_id: params.inputParams.team_id,
                        },
                        callBackParams: {}
                    };
                    this.getBankTransferInfo(getBankTransferInfoParams);
                    break;
            }
        }).catch((err) => {
            params.callBackParams && params.callBackParams.errorCallback && params.callBackParams.errorCallback(err);
        });
    }
    getBankTransferInfo(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.GetBankTransferInfo.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    fetchPaymentHistory(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.FetchPaymentHistory.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    getPricingInfo(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.GetPricingInfo.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
}
exports.PaymentService = PaymentService;
