import * as React from 'react';
import { useState, useEffect } from 'react';
import { LandingPage, ProjectState, getLandingPageAd } from 'core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FullHeader } from 'pages/header/FullHeader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'stylesheets/Carousel.css';
import PreviewSection from './PreviewSection';
import { AdFrameState, AdState, ExportMode, FrameType, ImageType } from '../entities/Types';
import { getSearchParams } from './helper';
import { History } from 'src/route/Route';

interface PropsType {
  projects: ProjectState;
  landingPages: {
    userLandingPages: Array<LandingPage>;
  };
}

const CreationPreview = (props: PropsType) => {
  const history = new History();
  const dispatch = useDispatch();

  const [imageTypeState, setImageTypeState] = useState(ImageType.SINGLE_IMAGE);
  const [singleImageExportMode, setSingleImageExportMode] = useState(ExportMode.mode_191_1);
  const [carouselExportMode, setCarouselExportMode] = useState(ExportMode.mode_1_1);
  const [doDownLoad, setDoDownLoad] = useState(false);
  const [lpUrlState, setLpUrlState] = useState('');

  const searchParams = getSearchParams(useLocation);
  const { landingPageId } = searchParams;
  
  useEffect(() => {
		const fetchLpAd = async () => {
			const lpAd: any = await dispatch(getLandingPageAd(landingPageId));
			if (lpAd.data) {
        const lpUrl = `${lpAd.data.url}`.replace(/^http[s]?:\/\//,'').toUpperCase();
        setLpUrlState(lpUrl);
			}
		};
		fetchLpAd();
	}, []);
  
  const goBackHistory = () => {
    history.go(-1);
  };

  const onChangeImageType = (e: any) => {
    setImageTypeState(e.target.value);
  };

  const onChangeSingleImageRatio = (e: any) => {
    setSingleImageExportMode(e.target.value);
  };

  const onChangeCarouselRatio = (e: any) => {
    setCarouselExportMode(e.target.value);
  };

  const onDownload = () => {
    setDoDownLoad(true);
    setTimeout(() => {
      setDoDownLoad(false);
    }, 1000 );
  };

  const renderFormat = () => {
    return (
      <div className="col-lg-5 offset-lg-1">
        <div className="box-shadow">
          <div className="box__title">Định dạng hình ảnh</div>
          <div className="box__content p-0">
            <div className="row">
              {renderImageType()}
              {renderRatio()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImageType = () => {
    return (
      <div className="col-lg-6 pr-0">
        <div className="box__content--left">
          <label className={`${imageTypeState === ImageType.SINGLE_IMAGE ? 'active' : ''} choose-item`}>
            <div className="choose-item__wrapper">
              <input
                type="radio"
                name="imageType"
                value={ImageType.SINGLE_IMAGE}
                onChange={onChangeImageType}
                checked={imageTypeState === ImageType.SINGLE_IMAGE}
              />
              Ảnh đơn
            </div>
          </label>
          <label className={`${imageTypeState === ImageType.CAROUSEL ? 'active' : ''} choose-item`}>
            <div className="choose-item__wrapper">
              <input
                type="radio"
                name="imageType"
                value={ImageType.CAROUSEL}
                onChange={onChangeImageType}
                checked={imageTypeState === ImageType.CAROUSEL}
              />
              Carousel
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderRatio = () => {
    return (
      <>
        {
          imageTypeState === ImageType.SINGLE_IMAGE ?
          renderSingleImageRatio() :
          renderCarouselRatio()
        }
      </>
    );
  };

  const renderSingleImageRatio = () => {
    return (
      <div className="col-lg-6 pl-0">
        <div className="box__content--right">
          <label className={`${singleImageExportMode === ExportMode.mode_191_1 ? 'active' : ''} choose-item`}>
            <div className="choose-item__wrapper">
              <input 
                type="radio"
                name="singleImageRatio"
                value={ExportMode.mode_191_1}
                onChange={onChangeSingleImageRatio}
                checked={singleImageExportMode === ExportMode.mode_191_1}
              />
              1,91 : 1
            </div>
          </label>
          <label className={`${singleImageExportMode === ExportMode.mode_1_1 ? 'active' : ''} choose-item`}>
            <div className="choose-item__wrapper">
              <input 
                type="radio"
                name="singleImageRatio"
                value={ExportMode.mode_1_1}
                onChange={onChangeSingleImageRatio}
                checked={singleImageExportMode === ExportMode.mode_1_1}
              />
              1 : 1
            </div>
          </label>
          <label className={`${singleImageExportMode === ExportMode.mode_4_5 ? 'active' : ''} choose-item`}>
            <div className="choose-item__wrapper">
              <input 
                type="radio"
                name="singleImageRatio"
                value={ExportMode.mode_4_5}
                onChange={onChangeSingleImageRatio}
                checked={singleImageExportMode === ExportMode.mode_4_5}
              />
              4 : 5
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderCarouselRatio = () => {
    return (
      <div className="col-lg-6 pl-0">
        <div className="box__content--right">
          <label className="choose-item active">
            <div className="choose-item__wrapper">
              <input 
                type="radio"
                name="carouselRatio"
                value={ExportMode.mode_1_1}
                onChange={onChangeCarouselRatio}
                checked={carouselExportMode === ExportMode.mode_1_1}
              />
              1 : 1
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderPreview = () => {    
    const adState: AdState = {
      isEditing: false,
      exportMode: imageTypeState === ImageType.SINGLE_IMAGE ? singleImageExportMode : carouselExportMode,
      imageType: imageTypeState,
      frameType: FrameType.Frame1
    };
    return (
      <PreviewSection
        className="col-lg-5"
        adStateProp={adState}
        doDownLoad={doDownLoad}
      />
    );
  };

  const renderDownLoadButton = () => {
    if (doDownLoad) {
      return (
        <button 
          disabled
          className="btn btn-default float-right btn-download"
          onClick={onDownload}
        >Tải xuống</button>
      );
    }

    return (
      <button 
        className="btn btn-default float-right btn-download"
        onClick={onDownload}
      >Tải xuống</button>
    );
  };

  return (
    <Styles>
      <FullHeader />
      <div className="container overflow-visible">
        <div className="page-heading row">
          <div className="col-lg-10 offset-lg-1 d-flex justify-content-start align-items-start">
            <span className="goBackLink" onClick={goBackHistory}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path fill="#FFF" d="M0 0H24V24H0z" opacity=".01" />
                  <path
                    fill="#000"
                    d="M8.004 14.124L6.94 13.06c-.282-.281-.44-.663-.44-1.061 0-.398.158-.78.44-1.061l1.05-1.05v-.001l5.6-5.6 1.797-1.798.022-.021.03-.03c.213-.2.418-.321.63-.386.23-.07.466-.07.696 0 .23.07.45.205.683.437l.06.061c.232.232.368.453.438.683.07.23.07.465 0 .695-.077.238-.21.454-.387.63L10.12 12l7.44 7.44c.2.213.321.418.386.63.07.23.07.466 0 .696-.07.23-.206.45-.438.683l-.06.06c-.232.232-.453.368-.683.438-.23.07-.465.07-.695 0-.238-.077-.454-.21-.63-.387l-7.437-7.435.001-.001z"
                  />
                </g>
              </svg>
            </span>
            <div>
              <h1 className="page-title">
                <span>{lpUrlState}</span>
                Thiết lập hình ảnh quảng cáo
              </h1>
              <p>Hình ảnh quảng cáo sẽ được tạo ngẫu nhiên dành riêng cho landing page của bạn. Chọn <span>Tải xuống</span> mẫu hình ảnh phù hợp, hoặc<br/> <span>Tạo mẫu khác</span> để xem thêm các gợi ý khác.</p>
            </div>
          </div>
        </div>
        <div className="row">
          {renderFormat()}
          {renderPreview()}
        </div>
        <div className={'panel-fixed-bottom full button-group'}>
          <div className="container">
            <div className="col-lg-10 offset-lg-1">
              {renderDownLoadButton()}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .page-heading {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page-heading p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    color: #666666;
    margin-bottom: 0;
  }
  .page-heading p span {
    color: #000000;
    font-family: Propins-Medium;
  }
  .page-title {
    font-size: 20px;
    letter-spacing: -0.4px;
  }
  .page-title span {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
  label {
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.4px;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }
  .input-group {
    margin-bottom: 40px;
  }
  .filter-container select {
    padding: 15px 20px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    height: 52px;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.32px;
    color: #000000;
    border-radius: 6px !important;
    margin-bottom: 40px;
  }
  .box-shadow {
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .box-shadow.preview-box {
    margin-bottom: 98px;
  }
  .box__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #000000;
    padding: 15px;
    border-bottom: 1px solid #d8d8d8;
  }
  .box__title.small {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .box__content {
    width: 100%;
    padding: 15px;
  }
  .box__content--left {
    background-color: #f7f8f8;
  }
  .choose-item {
    padding: 10px;
    margin-bottom: 0;
  }
  .box__content--left .choose-item.active {
    background-color: rgba(76, 124, 243, 0.08);
  }
  .box__buttons {
    display: flex;
  }
  .box__buttons .btn-submit,
  .box__buttons .btn-default {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 12px;
  }
  .box__buttons svg {
    margin-right: 4px;
  }
  .choose-item__wrapper {
    cursor: pointer;
    padding: 10px 6px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    text-align: center;
    color: #666666;
    position: relative;
  }
  .choose-item__wrapper input {
    margin-right: 10px;
    width: auto;
    position: absolute;
    left: 6px;
  }
  .choose-item.active .choose-item__wrapper {
    border-color: #4c7cf3;
    color: #4c7cf3;
  }
  .icon-wrapper {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(216, 216, 216, 0.8);
  }
  .icon-wrapper.blur,
  .box-title.blur {
    opacity: 0.15;
  }
  .box-title {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #000000;
  }
  .box-description {
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.26px;
    text-align: center;
    color: #666666;
  }
  .placeholder-wrapper {
    width: 0px;
    animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  .img-placeholder {
    width: 100%;
    height: 326px;
  }
  .img-placeholder.fill-height {
    height: 100%;
  }
`;

const mapStateToProps = (state: any) => {
  const { projects, landingPages } = state;
  return {
    projects,
    landingPages,
  };
};

const CreationPreviewPage = connect(mapStateToProps)(CreationPreview);
export default CreationPreviewPage;
