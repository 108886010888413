"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeywordData = exports.KeywordGroup = exports.KeywordInfo = void 0;
const class_transformer_1 = require("class-transformer");
const types_1 = require("../../ads-campaign/types");
const AgeRange_1 = require("./AgeRange");
const ResourceOperation_1 = require("./ResourceOperation");
class KeywordInfo extends ResourceOperation_1.ResourceOperation {
    constructor() {
        super();
        this.text = '';
        this.match_type = types_1.GoogleAdsEnum.KeywordMatchType.BROAD;
    }
    get GoogleMatchType() {
        if (this.match_type === types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_BROAD)
            throw Error('CUSTOM_BROAD should not allow');
        return this.match_type === types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE ? types_1.GoogleAdsEnum.KeywordMatchType.PHRASE : this.match_type;
    }
    get DisplayMatchType() {
        if (this.match_type === types_1.GoogleAdsEnum.KeywordMatchType.BROAD && this.text.split(' ').every((item) => item.startsWith('+'))) {
            return types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE;
        }
        return this.match_type;
    }
    isEqual(keyword) {
        return this.text === keyword.text && this.GoogleMatchType === keyword.GoogleMatchType;
    }
    cloneResource() {
        const cloneResource = new KeywordInfo();
        cloneResource.text = this.text;
        cloneResource.match_type = this.match_type;
        cloneResource.operation = this.operation;
        cloneResource.resource_name = this.resource_name;
        return cloneResource;
    }
    get label() {
        const replaceTemplate = /\+/gi;
        return this.text.replace(replaceTemplate, '');
    }
    get value() {
        return this.label;
    }
    toJson() {
        return Object.assign(Object.assign({}, this), { match_type: this.GoogleMatchType });
    }
}
exports.KeywordInfo = KeywordInfo;
class KeywordGroup extends ResourceOperation_1.ResourceOperation {
    constructor() {
        super();
        this.name = '';
        this.keywords = [];
        this.ages = [];
    }
    isEqual(keyword) {
        var _a, _b, _c;
        return this.name.trim() === ((_a = keyword.name) === null || _a === void 0 ? void 0 : _a.trim()) && this.ages.length === ((_b = keyword.ages) === null || _b === void 0 ? void 0 : _b.length) && this.keywords.length === ((_c = keyword.keywords) === null || _c === void 0 ? void 0 : _c.length);
    }
    cloneResource() {
        const cloneResource = new KeywordGroup();
        cloneResource.name = this.name;
        cloneResource.keywords = this.keywords.map((x) => { return x.cloneResource(); });
        cloneResource.ages = this.ages.map((x) => { return x.cloneResource(); });
        cloneResource.operation = this.operation;
        cloneResource.resource_name = this.resource_name;
        return cloneResource;
    }
    get MatchTypeList() {
        let list = "";
        const matchTypeArr = [...new Set(this.keywords.map(k => k.DisplayMatchType))];
        const matchTypeName = {
            [types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE.toString()]: "Đối sánh cụm từ cập nhật",
            [types_1.GoogleAdsEnum.KeywordMatchType.BROAD.toString()]: "Đối sánh rộng",
            [types_1.GoogleAdsEnum.KeywordMatchType.EXACT.toString()]: "Đối sánh chính xác",
            [types_1.GoogleAdsEnum.KeywordMatchType.PHRASE.toString()]: "Đối sánh cụm từ cập nhật",
        };
        matchTypeArr.forEach((e, i) => list += `${(i ? ", " : "") + matchTypeName[e.toString()]}`);
        return list;
    }
    splitIntoMatchTypeGroup() {
        if (this.keywords) {
            const customBroad = this.keywords.filter(k => k.DisplayMatchType === types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE);
            const broad = this.keywords.filter(k => k.DisplayMatchType === types_1.GoogleAdsEnum.KeywordMatchType.BROAD);
            const exact = this.keywords.filter(k => k.DisplayMatchType === types_1.GoogleAdsEnum.KeywordMatchType.EXACT);
            const phrase = this.keywords.filter(k => k.DisplayMatchType === types_1.GoogleAdsEnum.KeywordMatchType.PHRASE);
            return ({
                [types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]: customBroad,
                [types_1.GoogleAdsEnum.KeywordMatchType.BROAD]: broad,
                [types_1.GoogleAdsEnum.KeywordMatchType.EXACT]: exact,
                [types_1.GoogleAdsEnum.KeywordMatchType.PHRASE]: phrase
            });
        }
        return {
            [types_1.GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]: [],
            [types_1.GoogleAdsEnum.KeywordMatchType.BROAD]: [],
            [types_1.GoogleAdsEnum.KeywordMatchType.EXACT]: [],
            [types_1.GoogleAdsEnum.KeywordMatchType.PHRASE]: []
        };
    }
    joinKeywords(max) {
        if (max)
            return this.keywords.splice(0, max).join('');
        return this.keywords.join('');
    }
    toJson() {
        return Object.assign(Object.assign({}, this), { keywords: this.keywords.map((x) => { return x.toJson(); }) });
    }
}
__decorate([
    class_transformer_1.Type(() => KeywordInfo)
], KeywordGroup.prototype, "keywords", void 0);
__decorate([
    class_transformer_1.Type(() => AgeRange_1.AgeRange)
], KeywordGroup.prototype, "ages", void 0);
exports.KeywordGroup = KeywordGroup;
class KeywordData {
    constructor() {
        this.keyword_groups = [];
        this.negative_keywords = [];
    }
    fromKeywordSuggest(data) {
        this.keyword_groups = data.default_keyword_groups.map(x => {
            const keywordGroup = new KeywordGroup();
            keywordGroup.name = x.group_name;
            keywordGroup.keywords = x.keywords.map(y => {
                const keyword = new KeywordInfo();
                keyword.text = y;
                keyword.match_type = types_1.GoogleAdsEnum.KeywordMatchType.BROAD;
                return keyword;
            });
            return keywordGroup;
        });
        this.negative_keywords = data.default_negative_keywords.map(x => {
            const keyword = new KeywordInfo();
            keyword.text = x;
            keyword.match_type = types_1.GoogleAdsEnum.KeywordMatchType.BROAD;
            return keyword;
        });
    }
    cloneResource() {
        const cloneResource = new KeywordData();
        cloneResource.keyword_groups = this.keyword_groups.map(x => { return x.cloneResource(); });
        cloneResource.negative_keywords = this.negative_keywords.map(x => { return x.cloneResource(); });
        return cloneResource;
    }
    toJson() {
        // TODO: Don't know why keyword group entity to be converted to json object after binding to KeywordItemField. Let fix later
        const keywordGroups = this.keyword_groups.map((x) => {
            if (!x.toJson) {
                return class_transformer_1.plainToClass(KeywordGroup, x);
            }
            return x;
        });
        return Object.assign(Object.assign({}, this), { keyword_groups: keywordGroups.map((x) => { return x.toJson(); }) });
    }
}
__decorate([
    class_transformer_1.Type(() => KeywordGroup)
], KeywordData.prototype, "keyword_groups", void 0);
__decorate([
    class_transformer_1.Type(() => KeywordInfo)
], KeywordData.prototype, "negative_keywords", void 0);
exports.KeywordData = KeywordData;
