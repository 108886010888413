"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationTypes = exports.CampaignTypes = exports.AdGroupAdStatus = exports.CampaignCompositeStatus = exports.CampaignColumnActive = exports.CampaignStatus = exports.AccountType = void 0;
var AccountType;
(function (AccountType) {
    AccountType[AccountType["GoogleAds"] = 1] = "GoogleAds";
    AccountType[AccountType["FacebookAds"] = 2] = "FacebookAds";
    AccountType[AccountType["FacebookFanpage"] = 3] = "FacebookFanpage";
})(AccountType = exports.AccountType || (exports.AccountType = {}));
var CampaignStatus;
(function (CampaignStatus) {
    CampaignStatus[CampaignStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    CampaignStatus[CampaignStatus["UNKNOWN"] = 1] = "UNKNOWN";
    CampaignStatus[CampaignStatus["ENABLED"] = 2] = "ENABLED";
    CampaignStatus[CampaignStatus["PAUSED"] = 3] = "PAUSED";
    CampaignStatus[CampaignStatus["REMOVED"] = 4] = "REMOVED";
    CampaignStatus[CampaignStatus["DRAFT"] = 5] = "DRAFT";
})(CampaignStatus = exports.CampaignStatus || (exports.CampaignStatus = {}));
var CampaignColumnActive;
(function (CampaignColumnActive) {
    CampaignColumnActive[CampaignColumnActive["ADSACCOUNT"] = 0] = "ADSACCOUNT";
    CampaignColumnActive[CampaignColumnActive["COMPOSITESTATUS"] = 1] = "COMPOSITESTATUS";
    CampaignColumnActive[CampaignColumnActive["METRICCOST"] = 2] = "METRICCOST";
    CampaignColumnActive[CampaignColumnActive["METRICIMPRESSIONS"] = 3] = "METRICIMPRESSIONS";
    CampaignColumnActive[CampaignColumnActive["METRICNUMCLICK"] = 4] = "METRICNUMCLICK";
    CampaignColumnActive[CampaignColumnActive["METRICNUMENGAGEMENT"] = 5] = "METRICNUMENGAGEMENT";
    CampaignColumnActive[CampaignColumnActive["METRICNUMCONVERSIONS"] = 6] = "METRICNUMCONVERSIONS";
    CampaignColumnActive[CampaignColumnActive["METRICEFFECTIVE"] = 7] = "METRICEFFECTIVE";
})(CampaignColumnActive = exports.CampaignColumnActive || (exports.CampaignColumnActive = {}));
var CampaignCompositeStatus;
(function (CampaignCompositeStatus) {
    CampaignCompositeStatus[CampaignCompositeStatus["DRAFT"] = 0] = "DRAFT";
    CampaignCompositeStatus[CampaignCompositeStatus["ELIGIBLE"] = 1] = "ELIGIBLE";
    CampaignCompositeStatus[CampaignCompositeStatus["NOT_ELIGIBLE"] = 2] = "NOT_ELIGIBLE";
    CampaignCompositeStatus[CampaignCompositeStatus["PENDING"] = 3] = "PENDING";
    CampaignCompositeStatus[CampaignCompositeStatus["ENDED"] = 4] = "ENDED";
    CampaignCompositeStatus[CampaignCompositeStatus["PAUSED"] = 5] = "PAUSED";
    CampaignCompositeStatus[CampaignCompositeStatus["REMOVED"] = 6] = "REMOVED";
    CampaignCompositeStatus[CampaignCompositeStatus["UNKNOWN"] = 7] = "UNKNOWN";
    CampaignCompositeStatus[CampaignCompositeStatus["UNKNOWN_SERVING"] = 8] = "UNKNOWN_SERVING";
})(CampaignCompositeStatus = exports.CampaignCompositeStatus || (exports.CampaignCompositeStatus = {}));
// export interface Ad extends IResourceOperation{
//   type: GoogleAdsEnum.AdType;
//   descriptions: string[];
//   headlines: string[];
//   paths: string[];
// }
// export interface AdGroupAd extends IResourceOperation{
//   ad_group_resource_name: string;
//   // status?: AdGroupAdStatus;
//   ad: Ad;
// }
var AdGroupAdStatus;
(function (AdGroupAdStatus) {
    AdGroupAdStatus[AdGroupAdStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    AdGroupAdStatus[AdGroupAdStatus["UNKNOWN"] = 1] = "UNKNOWN";
    AdGroupAdStatus[AdGroupAdStatus["ENABLED"] = 2] = "ENABLED";
    AdGroupAdStatus[AdGroupAdStatus["PAUSED"] = 3] = "PAUSED";
    AdGroupAdStatus[AdGroupAdStatus["REMOVED"] = 4] = "REMOVED";
})(AdGroupAdStatus = exports.AdGroupAdStatus || (exports.AdGroupAdStatus = {}));
// -------------
var CampaignTypes;
(function (CampaignTypes) {
    CampaignTypes[CampaignTypes["Search"] = 0] = "Search";
    CampaignTypes[CampaignTypes["Display"] = 1] = "Display";
})(CampaignTypes = exports.CampaignTypes || (exports.CampaignTypes = {}));
var OperationTypes;
(function (OperationTypes) {
    OperationTypes["CREATE"] = "create";
    OperationTypes["UPDATE"] = "update";
    OperationTypes["REMOVE"] = "remove";
})(OperationTypes = exports.OperationTypes || (exports.OperationTypes = {}));
