import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Project,
  ProjectService,
  Image,
  DeletePhotoParamsType,
  DeleteImageParams,
  ProjectTypes
} from 'core';
import UploadImageButton from 'buttons/UploadImage';
import 'stylesheets/App.css';
import ReiImage from 'components/image';
import { css } from '@emotion/core';
import DotLoader from 'react-spinners/DotLoader';
import { useLocation } from 'react-router-dom';

const override = css`
  margin-top: 15px;
`;

const Styles = styled.div`
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    font-family: Propins-Medium;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  label {
    margin: 20px 0 30px;
    font-family: Propins-Regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #666666;
  }
  label span.red-label {
    color: #ff3b30;
  }
  h4 {
    font-family: Propins;
    font-size: 14px;
    letter-spacing: -0.28px;
    text-align: center;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 0;
    margin-bottom: 14px;
  }
  .listImageUpload {
    display: flex;
    flex-wrap: wrap;
  }
  .imageItem {
    position: relative;
    width: 172px;
    margin-right: 10px;
  }
  .imageItem img {
    width: 172px;
    height: 172px;
    border-radius: 3px;
  }
  .imageItem:hover .removeImage {
    opacity: 1;
    visibility: visible;
  }
  .removeImage {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .dropzone {
    width: 172px;
    height: 172px;
    border-radius: 3px;
    border: dashed 1px #aeaeb2;
    justify-content: space-between;
    display: flex;
    padding-top: 36px;
    padding-bottom: 36px;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: #000000;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #8e8e93;
    }
  }
  .dropzone.larger-button {
    width: 557px;
    height: 302px;
    border-radius: 12px;
    margin: auto;
    p {
      width: 206px;
      height: 50px;
    }
  }
  .dropzone p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    height: 37px;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: -0.32px;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
    background-color: #4c7cf3;
  }
  @media (max-width: 1300px) {
    .project-infor-form {
      min-height: auto;
    }
    .form-control {
      padding: 8px 10px;
      height: 36px;
      font-size: 14px;
    }
    label {
      font-size: 12px;
      margin: 15px 0 8px;
    }
  }
`;

type PropsType = {
  project?: Project;
  isEdit?: boolean;
  stateImages: Array<Image>;
  setStateImages: (value: any) => void;
  projectType: ProjectTypes;
}

export function ProjectUploadImageForm(props: PropsType) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isClone = pathname.includes('clone-project');

  const projectImages: Array<Image> = useSelector((state: any) => state.projects.projectImages);
  const isUploadingImage: boolean = useSelector((state: any) => state.projects.isUploadingImage);
  const { stateImages, setStateImages } = props;
  const { isEdit, project } = props;
  const displayImages = [...projectImages, ...stateImages];
  const [loadingText, setLoadingText] = React.useState('');
  const service = new ProjectService();
  // const isUserAllowed = isEdit ? project && project.is_owner : true;
  const isUserAllowed = true;

  const setImageSrcToState = (imgUrl: string, base64String: string, imgName: string) => {
    const imgObject: Image = new Image(imgUrl, base64String, imgName, undefined, null);
    setStateImages((prevState: any) => [...prevState, imgObject]);
  };

  React.useEffect(() => {
    let txtTimeout: any = null;
    if (!isUploadingImage) {
      setLoadingText('');
      if (txtTimeout) {
        clearTimeout(txtTimeout);
      }
      return;
    }
    setLoadingText('Vui lòng chờ trong giây lát, ảnh của bạn đang được tải lên');
    txtTimeout = setTimeout(() => {
      setLoadingText('Ảnh của bạn đang được xử lý. Quá trình tải ảnh có thể diễn ra lâu hơn bình thường nếu ảnh có dung lượng lớn');
    }, 30000);
    return () => {
      if (txtTimeout) {
        clearTimeout(txtTimeout);
      }
    };
  }, [isUploadingImage]);

  const deleteImg = (image: Image) => {
    const imgUrl = image.imgUrl.split('/');
    const imgName = imgUrl[imgUrl.length - 1];
    if (image.id && project && project.id) {
      const photo: DeletePhotoParamsType = {
        imageName: imgName,
        imageId: image.id
      };
      const params: DeleteImageParams = {
        inputParams: {
          projectId: project.id,
          photo
        },
      };
      service.deleteImage(params);
      return;
    }
    setStateImages((prevState: any) => prevState.filter((img: Image) => img.orgImgName !== image.orgImgName));
  };

  return (
    <Styles>
      {
        isUploadingImage &&
        <div className="loader-overlay">
          <div className="loader-wrapper">
            <div>{loadingText}</div>
            <DotLoader
              css={override}
              size={60}
              color={'#ffffff'}
              loading={isUploadingImage}
            />
          </div>
        </div>
      }
      <div className="project-infor-form justify-content-between flex-column row m-0">
        <div className="col-12">
          {isUserAllowed ? <label>
            Hãy <span className="font-weight-bold">tải ảnh lên</span> và <span className="font-weight-bold">đặt tên ảnh</span> theo mẫu dưới đây để ảnh của bạn được sắp xếp theo đúng danh mục: (VD:
            tongquan1, tongquan2, vitri1, vitri2, nhamau, matbang, noithat, kientruc, tienich, canho,...) <span className="red-label">*</span>
          </label> : <label>Bạn chỉ có thể xem các ảnh đã tải lên.</label>}
          <div className="listImageUpload">
              <React.Fragment>
                {displayImages && displayImages.length > 0 && displayImages.map((img, index) =>
                  <div key={index} className="imageItem">
                    <ReiImage src={img.getSrcBySize('172x172')} />
                    <h4>{img.getShortName()}</h4>
                    {
                      (!isClone || !img.id) &&
                      <div onClick={() => deleteImg(img)} className="removeImage">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g fill="none" fillRule="evenodd">
                            <circle cx="12" cy="12" r="11.5" fill="#000" fillOpacity=".401" stroke="#FFF" />
                            <g fill="#FFF">
                              <path d="M6 6h12v12H6z" opacity=".01" />
                              <path d="M13.273 12l3.412 3.412c.14.14.22.272.263.41a.706.706 0 0 1 0 .417.984.984 0 0 1-.263.41l-.036.036a.976.976 0 0 1-.41.263.706.706 0 0 1-.417 0 .984.984 0 0 1-.41-.263L12 13.272l-3.412 3.414a.98.98 0 0 1-.41.262.706.706 0 0 1-.417 0 .984.984 0 0 1-.41-.262l-.036-.037a.976.976 0 0 1-.263-.41.706.706 0 0 1 0-.416.984.984 0 0 1 .263-.41L10.728 12 7.314 8.588a.98.98 0 0 1-.262-.41.706.706 0 0 1 0-.417.984.984 0 0 1 .262-.41l.037-.036c.139-.14.271-.22.41-.263a.706.706 0 0 1 .416 0c.138.042.27.123.41.263L12 10.728l3.412-3.413a.98.98 0 0 1 .41-.262.706.706 0 0 1 .417 0c.138.042.27.123.41.262l.036.037c.14.139.22.272.263.41a.706.706 0 0 1 0 .417.984.984 0 0 1-.263.41L13.273 12z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                    }
                  </div>
                )}
                {isUserAllowed && <UploadImageButton
                  largeButton={!(displayImages && displayImages.length > 0)}
                  uploadLater={!isEdit}
                  imageSelectedCallback={setImageSrcToState}
                  projectId={project && project.id}
                />}
              </React.Fragment>
          </div>
        </div>
      </div>
    </Styles>
  );
}
