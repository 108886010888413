import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as ReactModal from 'react-modal';
import { copyLandingPage, CopyLandingPageParameters ,Project, AppState } from 'core';
import { ButtonProps } from 'presenter';
import Button from './Button';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import { useFetchListProjects } from 'pages/project/hooks';
import '../../stylesheets/Modal.css';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  content: {
    width: '615px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    border: '0',
    borderRadius: '12px',
    boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
    transform: 'translate(-50%, -50%)'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');
type CopyLpButtonProps = ButtonProps & ReturnType<typeof mapStateToProps> & {
  modalTitle: string;
  templateLandingPageId: number;
  templateLandingPageName: string;
  templateProjectId: number;
  templateVersionId: number;
  customButtonStyle?: string;
}

const CopyLpButton: React.FC<CopyLpButtonProps> = (props) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [lpName, setLpName] = useState(`Bản sao của ${props.templateLandingPageName}`);
  const [projectId, setProjectId] = useState(props.templateProjectId);
  const isMounted = useRef(false);

  React.useEffect(() => {
    setLpName(`Bản sao của ${props.templateLandingPageName}`);
  }, [props.templateLandingPageName]);

  React.useEffect(() => {
    setProjectId(props.templateProjectId);
  }, [props.templateProjectId]);

  useFetchListProjects(props.projects);

  useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  const openModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    if (!isMounted.current) return;
    setIsOpen(false);
  };

  const onCreateLandingPage = (): void => {
    if (!validateData()) {
      return;
    }
    const parameters: CopyLandingPageParameters = {
      project_id: +projectId,
      landing_page_name: lpName,
      landing_page_id: props.templateLandingPageId,
      version_id: props.templateVersionId
    };
    dispatch(copyLandingPage(parameters));
    closeModal();
  };

  const onLpNameChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setLpName(e.currentTarget.value);
  };

  const onProjectChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    setProjectId(+e.currentTarget.value);
  };

  const validateData = (): boolean => {
    return lpName !== '' && projectId !== 0;
  };

  const renderProjects = (): React.ReactChild => {
    const userProjects: Array<Project> | null = props.projects.userProjects;
    return (
      <div className="input-group">
        <label>DỰ ÁN</label>
        <select className="w-100 form-control filter-selection" onChange={onProjectChange} defaultValue={`${props.templateProjectId}`}>
          <option value={0}>Tên dự án</option>
          {userProjects &&
            userProjects.map((project) => (
              <option value={project.id} key={project.id}>
                {project.name}
              </option>
            ))}
        </select>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Button className={props.customButtonStyle} onClick={openModal}>
        {props.children}
      </Button>
      <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <h3 className="text-center">{props.modalTitle}</h3>
        {renderProjects()}
        <div className="pb-3 mt-3">
          <label>TÊN LANDING PAGE</label>
          <input
            className="form-control"
            value={lpName}
            autoFocus={true}
            placeholder="Nhập tên landing page"
            onChange={onLpNameChange}
          />
        </div>
        <div className="modal-group-bottons d-flex justify-content-end">
          <CancelButton onClick={closeModal}>Hủy bỏ</CancelButton>
          <SubmitButton alignRight isDisabled={!validateData()} onClick={onCreateLandingPage}>
            Tạo ngay
          </SubmitButton>
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: AppState) => {
  return {
    projects: state.projects
  };
};

CopyLpButton.defaultProps = {
  customButtonStyle: 'button-link'
};

const CopyLpButtonConnected = connect(mapStateToProps)(CopyLpButton);
export default CopyLpButtonConnected;
