import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { GoogleAdsEnum, GoogleService, ConnectedAccount, ConnectAccountParameter } from 'core';
import * as ReactModal from 'react-modal';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  maxAdwordAccount: number;
  connectedAccounts?: any[];
}
ReactModal.setAppElement('#root');

export const WarningLimitAccountModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  // const fbAccessToken = useSelector((state) => state.adsCampaign.fbAccessToken);
  const connectedAccounts = props.connectedAccounts || [];
  const ggAdsAccountIds = connectedAccounts
    .filter((acc: any) => acc.account_type == GoogleAdsEnum.ConnectedAccountType.GoogleAds)
    .map((acc: any) => (acc.provider_account_id));
    
  const [customerChecked, setCustomerChecked] = useState({});

  const history = (globalThis as any).useHistory;
  history.hook();

  useEffect(() => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {},
        errorCallback: (err: any) => {
          console.log('onErrGG', err);
        }
      }
    };

    const service = new GoogleService();
    service.fetchConnectedAccount(params);
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="connect-account-modal">
        <div className="modal__heading">
          <h3>Xác nhận</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-890 -65) translate(355 47) translate(527 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal__content">
          Bạn đã kết nối tối đa {props.maxAdwordAccount} tài khoản quảng cáo.
          Để thêm tài khoản khác, vui lòng ngưng kết nối ít nhất một tài khoản hiện tại.
        </div>
        <div className="modal__bottom d-flex justify-content-end align-items-center">
          <button className="btn-subsidiary btn-grey-suit" onClick={closeModal}>Đóng</button>
          <Link className="btn btn-default ml-3" to={history.url('/account-ads-management')}>Quản lý tài khoản QC</Link>
        </div>
      </div>
    </ReactModal>
  );
};

WarningLimitAccountModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '101'
    },
    content: {
      position: 'relative',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};