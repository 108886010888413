import SubmitButton from 'buttons/SubmitButton';
import { isInvalidAddress, isValidPhoneNumber, User, UserService } from 'core';
import { FullHeader } from 'pages/header/FullHeader';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {EmailService, ApiErrorMsg, InputErrorMsg, FieldType} from './service';
import VerifyAccountService from './service/VerifyAccountService';
import { toast } from 'react-toastify';
import { History } from 'src/route/Route';

type LocationState = {
  needVerifyEmail?: boolean;
} | undefined;

const VerifyPage: React.FC = () => {
  const isNeedVerifyEmail = (user: User, state: LocationState): boolean => {
    const verifyAccountSrv = new VerifyAccountService(user);
    if (verifyAccountSrv.NeedVerifyEmail) {
      return true;
    } 
    return false;
  };

  const dispatch = useDispatch();
  const history = new History();
  const { state: localState } = useLocation<LocationState>();
  const user: User = useSelector((state: any) => state.user.user);
  const [stateUser, setStateUser] = useState(user);
  
  const [needVerifyEmail] = useState<boolean>(isNeedVerifyEmail(user, localState));
  const { name, email, phone_no: phoneNo, auth_provider: provider } = stateUser;
  const [isInvalidNameState, setIsInvalidNameState] = useState(false);
  const [isInvalidEmailState, setIsInvalidEmailState] = useState(false);
  const [isInvalidPhoneState, setIsInvalidPhoneState] = useState(false);
  const service = new UserService(); 
  const [apiErrorCode, setApiErrorCode] = useState({
    error: false,
    code: '0'
  });
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const newStateUser: User = Object.assign({}, stateUser, { [name]: value });
    setStateUser(newStateUser);
  };

  const handleUpdateUser = (): void => {
    if (!stateUser.name) {
      setIsInvalidNameState(true);
    }
    if (isInvalidAddress(stateUser.email)) {
      setIsInvalidEmailState(true);
    }
    if (!isValidPhoneNumber(stateUser.phone_no)) {
      setIsInvalidPhoneState(true);
    }
    if(!stateUser.name || isInvalidAddress(stateUser.email) || !isValidPhoneNumber(stateUser.phone_no)) {
      return;
    }

    const params = {
      inputParams: stateUser,
      callBackParams: {
        successCallback: (user: User) => {
          setApiErrorCode({
            code: '0',
            error: false,
          });
          if (needVerifyEmail) {
            history.push('/sent-confirm-email-noti');
          } else {
            history.push('/home');
          }
        },
        errorCallback: (err: any) => {
          const message = 'Cập nhật không thành công.';
          setApiErrorCode({
            code: err.code,
            error: true,
          });
          toast.error(message);
        }
      }
    };
    service.updateCredential(params);
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg) => {
    if (!ifError) return null;
    return (
      <div style={{color: 'red'}}>{errMsg}</div>
    );
  };

  const renderEmailField = (user: User) => {
    return (
      <div className="col-lg-6">
        <label>Email <span className="label-required"> *</span></label>
        <input type="text" name="email" className="form-control" value={email || ''} disabled={provider !== 'facebook'} onChange={handleOnChange} />
        {renderInputError(isInvalidEmailState, InputErrorMsg.InvalidEmail)}
      </div>
    );
  };

  const renderApiError = (shouldShow: boolean, code: string) => {
    if (!shouldShow || !ApiErrorMsg[code]) return null;
    return (
      <div className='mt-2' style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  return (
    <Styles>
      <FullHeader />
      <div className="container verify-page">
        <div className="text-center verify-page__heading">
          <h2>Xác thực tài khoản</h2>
          <p>Vui lòng nhập các thông tin để xác thực.</p>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="row">
              <div className="col-lg-12">
                <label>Họ và tên <span className="label-required"> *</span></label>
                <input type="text" name="name" className="form-control" value={name} onChange={handleOnChange} />
                {renderInputError(isInvalidNameState, InputErrorMsg.EmptyName)}
              </div>
            </div>
            <div className="row">
              {renderEmailField(user)}
              <div className='col-lg-6'>
                <label>Số điện thoại <span className="label-required"> *</span></label>
                <input type="text" name="phone_no" className="form-control" value={phoneNo || ''}  onChange={handleOnChange} />
                {renderInputError(isInvalidPhoneState, InputErrorMsg.InvalidPhoneNo)}
              </div>
            </div>
            {renderApiError(apiErrorCode.error, apiErrorCode.code)}
            <div className="button-group">
              <SubmitButton
                className="btn-submit"
                onClick={handleUpdateUser}
              >
                Tiếp tục
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background: url(${process.env.REACT_APP_TEMPLATE_CDN_URL}/header-background.png) no-repeat;
  background-size: contain;
  .app-header.background-white {
    background-color: transparent;
    box-shadow: none;
  }
  .verify-page {
    margin-top: 34px;
  }

  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }

  p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #000000;
  }

  .verify-page__heading {
    margin-bottom: 80px;
  }
`;


export default VerifyPage;