"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacebookService = void 0;
const types_1 = require("../types");
const ApiAction_1 = require("../../actions/ApiAction");
class FacebookService {
    constructor() { }
    // getData(params: GoogleAdsSampleParams){
    //   const type = GetGoogleAdsCampaign.type;
    //   const apiAction = new ApiAction(type, FuncType.Async, params);
    //   apiAction.dispatchAction();
    // }
    getFbAdAccount(params) {
        const type = types_1.GetFbAdAccount.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    connectFbAccount(params) {
        const type = types_1.ConnectFbAccount.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    fetchFanpage(params) {
        const type = types_1.FetchFanpage.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
}
exports.FacebookService = FacebookService;
