import { isInvalidAddress, isValidPhoneNumber, User, UserService, resetInEmailRegisterProcess } from 'core';
import { FullHeader } from 'pages/header/FullHeader';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import ResendEmailSuccessModal from './ResendEmailSuccessModal';
import { toast } from 'react-toastify';
import { History } from 'src/route/Route';

const SentConfirmEmailNoti: React.FC = () => {
  const dispatch = useDispatch();
  const history = new History();
  const user: User = useSelector((state: any) => state.user.user);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [stateUser, setStateUser] = useState(user);
  const [message, setMessage] = useState(`Chúng tôi đã gửi một email chứa đường link xác thực tài khoản tới địa chỉ email (${user.email}). Hãy nhấn vào đường link đó để kích hoạt tài khoản của bạn.`);
  const service = new UserService(); 

  useEffect(() => {
    dispatch(resetInEmailRegisterProcess());
  }, []);

  const onResendVerifyEmail = () => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: () => {
          toast.success('Gửi email xác thực thành công.');
          setShouldShowModal(true);
        },
        errorCallback: (err: any) => {
          toast.error('Không thể gửi email xác thực.');
        }
      }
    };
    service.resendVerifyEmail(params);
  };

  const renderChangeEmail = () => {
    return user.auth_provider === 'facebook' ?
      (<>
        hoặc{' '}
      <Link 
        className="btn-link"
        to={{
          pathname: history.getPath('/verify'),
          state: {
            needVerifyEmail: true
          }
        }}
      >
        Thay đổi địa chỉ email
      </Link>
      </>) : null;
  };

  return (
    <Styles>
      <FullHeader />
      <div className="container verify-form">
        <div className="text-center verify-form__heading">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h2>Kích hoạt tài khoản của bạn</h2>
              <p>{message}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 text-center  offset-lg-3">
            <div className="verify-form__content">
              Không nhận được email? <a className="btn-link d-inline" href="#" onClick={onResendVerifyEmail}>Gửi lại</a><br />
              {/* {renderChangeEmail()} */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 text-center  offset-lg-3">
            <div className="verify-form__bottom d-flex justify-content-center">
            <Link 
              className="btn-subsidiary"
              to={history.component('Home')}
            >
              Vào dashboard
            </Link>
            <a target="_blank" className="btn-default align-items-center ml-3" href="https://mail.google.com/mail/#search/from%3A%40+cokhach.vn+in%3Aanywhere" rel="noreferrer">
              Tới hòm thư
            </a>
            </div>
          </div>
        </div>
      </div>
      <ResendEmailSuccessModal userEmail={user.email} isOpen={shouldShowModal} closeModal={() => {setShouldShowModal(false);}} />
    </Styles>
  );
};

const Styles = styled.div`
  background: url(${process.env.REACT_APP_TEMPLATE_CDN_URL}/header-background.png) no-repeat;
  background-size: contain;
  .app-header.background-white {
    background-color: transparent;
    box-shadow: none;
  }
  .verify-form {
    margin-top: 20px;
  }
  .verify-form__content a {
    text-decoration: underline;
  }
  .verify-form__bottom a {
    text-decoration: none;
  }
  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }
  p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #000000;
  }
  .verify-form__heading {
    margin-bottom: 42px;
  }
  .verify-form__bottom {
    margin-top: 40px;
  }
`;

export default SentConfirmEmailNoti;