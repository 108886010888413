"use strict";
//-----------------------------
// getDefaultExtension
// Type: GetDefaultExtension
//-----------------------------
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetLPWithGoogleAdsCampaign = exports.GetDefaultComponents = exports.GetDefaultExtension = void 0;
//action type definition
exports.GetDefaultExtension = {
    type: 'GetDefaultExtension',
    success: 'GetDefaultExtension_SUCCESS',
    error: 'GetDefaultExtension_ERROR',
};
//action type definition
exports.GetDefaultComponents = {
    type: 'GetDefaultComponents',
    success: 'GetDefaultComponents_SUCCESS',
    error: 'GetDefaultComponents_ERROR',
};
//action type definition
exports.GetLPWithGoogleAdsCampaign = {
    type: 'GetLPWithGoogleAdsCampaign',
    success: 'GetLPWithGoogleAdsCampaign_SUCCESS',
    error: 'GetLPWithGoogleAdsCampaign_ERROR',
};
