import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import * as ReactModal from 'react-modal';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  goBack: () => void;
  inviteEmails: string[];
  teamName: string;
}
ReactModal.setAppElement('#root');

const SentInviteMembersModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };
  const backToFirstModal = () => {
    props.goBack();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="sent-invite-members-modal">
        <div className="modal__heading">
          <h3>Đã gửi lời mời</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            <span className="member-email">{props.inviteEmails.join(', ')}</span> đã được mời làm thành viên của nhóm <span className="team-name">{props.teamName}</span><br /><br />
            Họ sẽ chính thức hoạt động trong nhóm của bạn sau khi tạo tài khoản thành công.
          </div>
        </div>
        <div className="modal__bottom">
          <button onClick={backToFirstModal} className="btn-subsidiary">Mời thêm</button>
          <button onClick={closeModal} className="btn-default ml-3">Đóng</button>
        </div>
      </div>
    </ReactModal>
  );
};

SentInviteMembersModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default SentInviteMembersModal;
