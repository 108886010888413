"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerReducer = void 0;
const types_1 = require("../../auth/types");
const types_2 = require("../types");
const action_namespace_1 = require("../../team/types/action-namespace");
const initialState = {
    data: [],
    totalRemaining: 0
};
const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.GET_CUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                data: action.customer,
                totalRemaining: action.totalRemaining
            });
        case types_2.UPDATE_CUSTOMER_SUCCESS: {
            const { customer } = action;
            const updateIndex = state.data.findIndex(({ id }) => id === customer.id);
            return Object.assign(Object.assign({}, state), { data: [
                    ...state.data.slice(0, updateIndex),
                    customer,
                    ...state.data.slice(updateIndex + 1)
                ] });
        }
        case action_namespace_1.GoToTeamAction.success:
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.customerReducer = customerReducer;
