"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleCampaignService = void 0;
const ApiAction_1 = require("../../actions/ApiAction");
const GoogleCampaignType_1 = require("../types/GoogleCampaignType");
class GoogleCampaignService {
    constructor() { }
    getDefaultExtension(params) {
        const type = GoogleCampaignType_1.GetDefaultExtension.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getDefaultComponents(params) {
        const type = GoogleCampaignType_1.GetDefaultComponents.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
}
exports.GoogleCampaignService = GoogleCampaignService;
