"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleApiErrorHandlerService = exports.CustomErrorCode = void 0;
var CustomErrorCode;
(function (CustomErrorCode) {
    CustomErrorCode[CustomErrorCode["RequestFailed"] = 0] = "RequestFailed";
    CustomErrorCode[CustomErrorCode["DefaultErrorHandler"] = 1] = "DefaultErrorHandler";
    CustomErrorCode[CustomErrorCode["MaxConversionValueTargetRoas"] = 2] = "MaxConversionValueTargetRoas";
    CustomErrorCode[CustomErrorCode["DuplicateCampaignName"] = 3] = "DuplicateCampaignName";
    CustomErrorCode[CustomErrorCode["AdScheduleOverlap"] = 4] = "AdScheduleOverlap";
    CustomErrorCode[CustomErrorCode["DuplicateKeywordGroupName"] = 5] = "DuplicateKeywordGroupName";
    CustomErrorCode[CustomErrorCode["DatePassSystemLastDate"] = 6] = "DatePassSystemLastDate";
    CustomErrorCode[CustomErrorCode["PlansBidTooHigh"] = 7] = "PlansBidTooHigh";
    CustomErrorCode[CustomErrorCode["CantModifyStartDateIfStarted"] = 8] = "CantModifyStartDateIfStarted";
    CustomErrorCode[CustomErrorCode["PastDay"] = 9] = "PastDay";
    CustomErrorCode[CustomErrorCode["UnknownError"] = 10] = "UnknownError";
    CustomErrorCode[CustomErrorCode["ProhibitedSymbol"] = 11] = "ProhibitedSymbol";
    CustomErrorCode[CustomErrorCode["UnpairedBrace"] = 12] = "UnpairedBrace";
    CustomErrorCode[CustomErrorCode["DisallowedCharacter"] = 13] = "DisallowedCharacter";
    CustomErrorCode[CustomErrorCode["TooLong"] = 14] = "TooLong";
})(CustomErrorCode = exports.CustomErrorCode || (exports.CustomErrorCode = {}));
const CustomErrorMessage = {
    RequestFailed: 'Unexpected Error. Deal with this later',
    DefaultErrorHandler: 'Dev: Please call isValid before getting ErrorMessage!',
    MaxConversionValueTargetRoas: 'Chiến dịch của bạn cần thêm lượt chuyển đổi để thiết lập lợi tức mục tiêu trên chi tiêu quảng cáo. Vui lòng sử dụng chiến lược giá thầu khác.',
    DuplicateCampaignName: "Tên chiến dịch trùng lặp với tên của một chiến dịch đang chạy hoặc đang tạm dừng khác! Hãy quay lại và đổi tên chiến dịch",
    AdScheduleOverlap: "Các lịch quảng cáo chứa những khoảng thời gian trùng nhau! Hãy tách riêng các lịch quảng cáo",
    DuplicateKeywordGroupName: "Đã tồn tại bộ từ khoá trùng tên. Hãy sử dụng tên khác cho bộ từ khoá này.",
    DatePassSystemLastDate: "Ngày bắt đầu hoặc ngày kết thúc chiến dịch vượt quá giới hạn cho phép (30/12/2037)",
    PlansBidTooHigh: "Giá thầu tối đa/click (Max CPC) quá lớn. Hay quay lại và giảm giá trị này xuống",
    CantModifyStartDateIfStarted: "Không thế thay đổi ngày bắt đầu của một chiến dịch đã khởi chạy. Hãy chuyển ngày bắt đầu về như trước hoặc thoát ra và vào lại chế độ chỉnh sửa.",
    PastDay: "Ngày bắt đầu hoặc ngày kết thúc chiến dịch không được là ngày trong quá khứ",
    UnknownError: "Đã có lỗi xảy ra. Vui lòng tạo lại chiến dịch mới hoặc liên hệ hotline CSKH 0904220612 để được hỗ trợ.",
    ProhibitedSymbol(trigger) {
        return `Không sử dụng các ký hiệu vi phạm chính sách của Google Ads: "${trigger}"`;
    },
    ProhibitedSymbolUnpairedParenthesis: "Bạn cần đảm bảo sử dụng các dấu ngoặc () trong cú pháp này theo cặp",
    UnpairedBrace: "Bạn cần đảm bảo sử dụng các dấu ngoặc {} trong cú pháp này theo cặp",
    DisallowedCharacter(trigger) {
        return `Không sử dụng các ký hiệu vi phạm chính sách của Google Ads: "${trigger}"`;
    },
    TooLong(trigger) {
        return `Độ dài của giá trị "${trigger}" vượt mức cho phép của Google Ads`;
    }
};
class BaseErrorHandler {
    isValid(error) {
        if (this._isBadRequest(error)) {
            return false;
        }
        return this._isValid(error);
    }
    _isBadRequest(error) {
        return error.statusCode === 400;
    }
    getErrorMessage(error) {
        if (this.isValid(error)) {
            return this.ErrorMessage(error);
        }
        return undefined;
    }
}
class DefaultBadRequestErrorHandler extends BaseErrorHandler {
    constructor() {
        super();
        this._customError = {
            code: CustomErrorCode.DefaultErrorHandler,
            message: CustomErrorMessage.DefaultErrorHandler
        };
    }
    ErrorMessage() {
        return this._customError;
    }
    _isValid(error) {
        this._customError.message = error.message[0];
        return true;
    }
    isValid(error) {
        if (!this._isBadRequest(error)) {
            return false;
        }
        return this._isValid(error);
    }
}
class DefaultErrorHandler extends BaseErrorHandler {
    constructor() {
        super();
        this._customError = {
            code: CustomErrorCode.DefaultErrorHandler,
            message: CustomErrorMessage.DefaultErrorHandler
        };
    }
    ErrorMessage() {
        return this._customError;
    }
    _isValid(error) {
        const googleError = error.error;
        this._customError.message = googleError.message || '';
        return true;
    }
}
// Use Conversion Value on an Ads Account with not enough Ads created
class MaxConversionValueTargetRoas extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.MaxConversionValueTargetRoas,
            message: CustomErrorMessage.MaxConversionValueTargetRoas
        };
    }
    _isValid(error) {
        const googleError = error.error;
        if (googleError.error_code && googleError.error_code.operation_access_denied_error === "OPERATION_NOT_PERMITTED_FOR_CAMPAIGN_TYPE" &&
            googleError.location.field_path_elements.length >= 5 && googleError.location.field_path_elements[3].field_name === 'maximize_conversion_value' &&
            googleError.location.field_path_elements[4].field_name === 'target_roas') {
            return true;
        }
        return false;
    }
}
// When a campaign is started, can't modify its start date or this error will be provoked
class CantModifyStartDateIfStarted extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.CantModifyStartDateIfStarted,
            message: CustomErrorMessage.CantModifyStartDateIfStarted
        };
    }
    _isValid(error) {
        const googleError = error.error;
        if (googleError.error_code && googleError.error_code.date_range_error === "CANNOT_MODIFY_START_DATE_IF_ALREADY_STARTED") {
            return true;
        }
        return false;
    }
}
// Start or end date is in the past
class PastDay extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.PastDay,
            message: CustomErrorMessage.PastDay
        };
    }
    _isValid(error) {
        const googleError = error.error;
        if (googleError.error_code && googleError.error_code.date_range_error === "CANNOT_SET_DATE_TO_PAST") {
            return true;
        }
        return false;
    }
}
class UnknownError extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.UnknownError,
            message: CustomErrorMessage.UnknownError
        };
    }
    _isValid(error) {
        const googleError = error.error;
        if (googleError.error_code && googleError.error_code.criterion_error === "UNKNOWN"
            && googleError.message === "The error code is not in this version.") {
            return true;
        }
        return false;
    }
}
// There is a limit date that can be set, any date after that date will be consider invalid
class DatePassSystemLastDate extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.DatePassSystemLastDate,
            message: CustomErrorMessage.DatePassSystemLastDate
        };
    }
    _isValid(error) {
        const googleError = error.error;
        if (googleError.error_code && googleError.error_code.date_range_error === "AFTER_MAXIMUM_ALLOWABLE_DATE") {
            return true;
        }
        return false;
    }
}
// Multiple keyword groups with a similar name
class DuplicateKeywordGroupName extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.DuplicateKeywordGroupName,
            message: CustomErrorMessage.DuplicateKeywordGroupName
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.ad_group_error === "DUPLICATE_ADGROUP_NAME") {
            return true;
        }
        return false;
    }
}
// Create a new Campaign with a similar name to another campaign
class DuplicateCampaignName extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.DuplicateCampaignName,
            message: CustomErrorMessage.DuplicateCampaignName
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.campaign_error === "DUPLICATE_CAMPAIGN_NAME" && error.location &&
            error.location.field_path_elements.length >= 4 && error.location.field_path_elements[3].field_name === "name") {
            return true;
        }
        return false;
    }
}
// Multiple Ads Schedules Overlap with each other
class AdScheduleOverlap extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.AdScheduleOverlap,
            message: CustomErrorMessage.AdScheduleOverlap
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.criterion_error === "AD_SCHEDULE_TIME_INTERVALS_OVERLAP") {
            return true;
        }
        return false;
    }
}
// Campaign Budget exceed a certain limit (~ 10 billion vnd)
class PlansBidTooHigh extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.PlansBidTooHigh,
            message: CustomErrorMessage.PlansBidTooHigh
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.keyword_plan_error === "BID_TOO_HIGH") {
            return true;
        }
        return false;
    }
}
// This special errors happen when create new ad from newly created landing page
class RequestFailed extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.RequestFailed,
            message: CustomErrorMessage.RequestFailed
        };
    }
    _isValid(apiError) {
        if (!apiError.error)
            return true;
        return false;
    }
}
// Use Special Symbol in ads display link
class DisallowedCharacter extends BaseErrorHandler {
    ErrorMessage(apiError) {
        var _a;
        const error = apiError.error;
        const trigger = (_a = error === null || error === void 0 ? void 0 : error.trigger) === null || _a === void 0 ? void 0 : _a.string_value;
        return {
            code: CustomErrorCode.DisallowedCharacter,
            message: CustomErrorMessage.DisallowedCharacter(trigger)
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.string_format_error === "ILLEGAL_CHARS") {
            return true;
        }
        return false;
    }
}
// Display link is too long
class TooLong extends BaseErrorHandler {
    ErrorMessage(apiError) {
        var _a;
        const error = apiError.error;
        let trigger = (_a = error === null || error === void 0 ? void 0 : error.trigger) === null || _a === void 0 ? void 0 : _a.string_value;
        trigger = (trigger && trigger.length > 20) ? (trigger.slice(0, 20) + "...") : trigger;
        return {
            code: CustomErrorCode.TooLong,
            message: CustomErrorMessage.TooLong(trigger)
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.string_length_error === "TOO_LONG") {
            return true;
        }
        return false;
    }
}
// This is due to usage of prohibited special symbol (example: .. ,, ... etc) or single brace like ( and )
class ProhibitedSymbol extends BaseErrorHandler {
    ErrorMessage(apiError) {
        var _a, _b, _c, _d, _e, _f;
        const error = apiError.error;
        const trigger = (_f = (_e = (_d = (_c = (_b = (_a = error.details) === null || _a === void 0 ? void 0 : _a.policy_finding_details) === null || _b === void 0 ? void 0 : _b.policy_topic_entries) === null || _c === void 0 ? void 0 : _c[0].evidences) === null || _d === void 0 ? void 0 : _d[0].text_list) === null || _e === void 0 ? void 0 : _e.texts) === null || _f === void 0 ? void 0 : _f[0];
        if (["(", ")"].includes(trigger)) {
            return {
                code: CustomErrorCode.ProhibitedSymbol,
                message: CustomErrorMessage.ProhibitedSymbolUnpairedParenthesis
            };
        }
        return {
            code: CustomErrorCode.ProhibitedSymbol,
            message: CustomErrorMessage.ProhibitedSymbol(trigger)
        };
    }
    _isValid(apiError) {
        var _a, _b, _c;
        const error = apiError.error;
        if (error.error_code && error.error_code.policy_finding_error === "POLICY_FINDING" &&
            ((_c = (_b = (_a = error.details) === null || _a === void 0 ? void 0 : _a.policy_finding_details) === null || _b === void 0 ? void 0 : _b.policy_topic_entries) === null || _c === void 0 ? void 0 : _c[0].topic) === "SYMBOLS") {
            return true;
        }
        return false;
    }
}
// This is due to usage of unpaired braces only { or } or 
class UnpairedBrace extends BaseErrorHandler {
    ErrorMessage() {
        return {
            code: CustomErrorCode.UnpairedBrace,
            message: CustomErrorMessage.UnpairedBrace
        };
    }
    _isValid(apiError) {
        const error = apiError.error;
        if (error.error_code && error.error_code.ad_error === "UNPAIRED_BRACE_IN_AD_CUSTOMIZER_TAG") {
            return true;
        }
        return false;
    }
}
class ErrorHandlerBuilder {
    constructor() {
        // Please add all the error handler here
        this._errorHandlers = [
            new MaxConversionValueTargetRoas(),
            new DuplicateCampaignName(),
            new AdScheduleOverlap(),
            new DuplicateKeywordGroupName(),
            new DatePassSystemLastDate(),
            new PlansBidTooHigh(),
            new CantModifyStartDateIfStarted(),
            new PastDay(),
            new ProhibitedSymbol(),
            new UnpairedBrace(),
            new UnknownError(),
            new DisallowedCharacter(),
            new TooLong()
        ];
        // Make sure DefaultErrorHandler is the latest error handler
        this._errorHandlers = [...this._errorHandlers, new DefaultBadRequestErrorHandler(), new DefaultErrorHandler()];
        // Make sure RequestFailed is the first error handler
        this._errorHandlers = [new RequestFailed(), ...this._errorHandlers];
    }
    getErrorMessage(error) {
        let message;
        this._errorHandlers.every(x => {
            message = x.getErrorMessage(error);
            if (message) {
                return false;
            }
            return true;
        });
        // TODO: Never happend as we got DefaultErrorHandler as last Error handler 
        if (!message) {
            message = {
                code: CustomErrorCode.DefaultErrorHandler,
                message: ''
            };
        }
        return message;
    }
}
class GoogleApiErrorHandlerService {
    static getErrorMessage(error) {
        const errorHandlerBuilder = new ErrorHandlerBuilder();
        return errorHandlerBuilder.getErrorMessage(error);
    }
}
exports.GoogleApiErrorHandlerService = GoogleApiErrorHandlerService;
// New undetected bugs
// {statusCode: 500, message: ["The required repeated field was empty."],…}
// error: {errors: [{error_code: {field_error: "REQUIRED_NONEMPTY_LIST"},…}]}
// errors: [{error_code: {field_error: "REQUIRED_NONEMPTY_LIST"},…}]
// 0: {error_code: {field_error: "REQUIRED_NONEMPTY_LIST"},…}
// error_code: {field_error: "REQUIRED_NONEMPTY_LIST"}
// field_error: "REQUIRED_NONEMPTY_LIST"
// location: {field_path_elements: [{field_name: "operations"}]}
// field_path_elements: [{field_name: "operations"}]
// 0: {field_name: "operations"}
// field_name: "operations"
// message: "The required repeated field was empty."
// message: ["The required repeated field was empty."]
// 0: "The required repeated field was empty."
// statusCode: 500
// This might due to name that similar to deleted group
// { 
//   "statusCode": 500,
//    "message": [
//      "This operation is not allowed because the campaign or adgroup is removed."
//     ],
//      "error": { 
//        "errors": [
//          { "error_code": {
//           "operation_access_denied_error": "OPERATION_NOT_PERMITTED_FOR_REMOVED_RESOURCE" },
//           "message": "This operation is not allowed because the campaign or adgroup is removed.", 
//           "trigger": { "string_value": "AdGroup" }, 
//           "location": { 
//             "field_path_elements": [
//               { "field_name": "mutate_operations", "index": 6 }, 
//               { "field_name": "ad_group_criterion_operation" }
//             ] 
//           } 
//         }] 
//       } 
//     }
// Add more ads in step 3
// {"statusCode":500,"message":["Cannot mutate the same resource twice in one request.","Cannot mutate the same resource twice in one request."],"error":{"errors":[{"error_code":{"mutate_error":"ID_EXISTS_IN_MULTIPLE_MUTATES"},"message":"Cannot mutate the same resource twice in one request.","location":{"field_path_elements":[{"field_name":"mutate_operations","index":1},{"field_name":"ad_group_ad_operation"}]}},{"error_code":{"mutate_error":"ID_EXISTS_IN_MULTIPLE_MUTATES"},"message":"Cannot mutate the same resource twice in one request.","location":{"field_path_elements":[{"field_name":"mutate_operations","index":2},{"field_name":"ad_group_ad_operation"}]}}]}}
