import { useState, useEffect, useRef } from 'react';

export * from 'pages/ads-creation/hooks';
export * from 'pages/analytics/hooks';
export * from 'pages/auth/hooks';
export * from 'pages/customer/hooks';
export * from 'pages/google-ads/hooks';
export * from 'pages/landing-page/hooks';
export * from 'pages/project/hooks';
export * from 'pages/team/hooks';

export function useKonamiCode(handler: any) {
  // State to hold array of recently pressed keys
  const [keys, setKeys] = useState<any>([]);

  // Convert stored keys to string and match against konami code string
  // const isKonamiCode = keys.join(' ') === 'up up down down left right left right B A';
  const isKonamiCode = keys.join(' ') === 'up up down down B A';

  useEffect(() => {
    let timeout: any = null;

    // When a key is pressed
    window.document.onkeydown = (e) => {
      // Update array of keys in state with new key
      setKeys((currentKeys: any) => [...currentKeys, getKeyName(e.keyCode)]);
      
      // Clear 5s timeout since key was just pressed
      clearTimeout(timeout);

      // Reset keys if 5s passes so user can try again
      timeout = setTimeout(() => setKeys([]), 5000);
    };
  }, []);

  // Once konami code is entered call handler function
  // and reset keys so user can do it again.
  useEffect(() => {
    if (isKonamiCode) {
      handler();
      setKeys([]);
    }
  }, [isKonamiCode, handler]);

  return isKonamiCode;
}

const getKeyName = (keyCode: any) => {
  return {
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    65: 'A',
    66: 'B',
  }[keyCode];
};


// modified from https://usehooks.com/useWhyDidYouUpdate/
export function useLoggingUpdate(name: any, props: any) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();

  useEffect(() => {
    const current = previousProps.current || {};
    if (previousProps) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...(current as {}), ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key: any) => {
        // If previous is different from current
        if (current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: current[key],
            to: props[key]
          };
        }
      });

      // If changesObj not empty then output to console
      // if (Object.keys(changesObj).length) {
      //   console.log('[why-did-you-update]', name, changesObj);
      // }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

// https://usehooks.com/useDebounce/
// T is a generic type for value parameter, our case this will be string
export function useDebounce<T = any>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}