"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = exports.configureStore = void 0;
const redux_1 = require("redux");
const redux_logger_1 = require("redux-logger");
const redux_saga_1 = require("redux-saga");
const redux_saga_thunk_1 = require("redux-saga-thunk");
const reducer_1 = require("./reducer");
const saga_1 = require("./saga");
const team_1 = require("../team");
const configureStore = () => {
    const middleware = [];
    const effectMiddleware = (next) => (effect) => {
        if (effect && effect.payload) {
            const { action } = effect.payload;
            if (action && action.payload) {
                if (action.payload.code === 414) {
                    const service = new team_1.WorkSpaceService();
                    const params = {
                        inputParams: {
                            team_id: null,
                            token: 'master'
                        },
                        callBackParams: {
                            successCallback: (res) => {
                                // gotoTeam(res.selectedTeam.token, res.selectedTeam, {});
                            }
                        }
                    };
                    service.gotoTeam(params);
                }
            }
        }
        return next(effect);
    };
    const sagaMiddleware = redux_saga_1.default({
    // effectMiddlewares: [effectMiddleware] 
    });
    middleware.push(redux_saga_thunk_1.middleware);
    middleware.push(sagaMiddleware);
    if (process.env.NODE_ENV !== 'production') {
        middleware.push(redux_logger_1.createLogger());
    }
    const store = redux_1.createStore(redux_1.combineReducers(reducer_1.rootReducer), redux_1.applyMiddleware(...middleware));
    sagaMiddleware.run(saga_1.rootSaga);
    return store;
};
exports.configureStore = configureStore;
const store = exports.configureStore();
exports.store = store;
