import classNames from 'classnames';
import { GoogleAdsEnum, IAgeRange } from 'core';
import React, { useEffect, useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';
import { ErrorInput } from '../fields';
import styled from 'styled-components';

interface PropTypes{
  onChange: (value: IAgeRange[]) => void;
  value?: IAgeRange[];
  errors: FieldErrors;
}

interface InitType {
  value: number;
  label: string;
  age: IAgeRange;
}

const Label = styled.label`
  &.error{
    color: red !important;
  }
`;

export const AgeField: React.FC<PropTypes> = (props) => {
  const initInput: InitType[] = [{
    value: 0,
    label: '18 - 24',
    age: {
      type: GoogleAdsEnum.AgeRangeType.AGE_RANGE_18_24,
    }
  },{
    value: 0,
    label: '25 - 34',
    age: {
      type: GoogleAdsEnum.AgeRangeType.AGE_RANGE_25_34,
    }
  },{
    value: 0,
    label: '35 - 44',
    age: {
      type: GoogleAdsEnum.AgeRangeType.AGE_RANGE_35_44,
    }
  },{
    value: 0,
    label: '45 - 54',
    age: {
      type: GoogleAdsEnum.AgeRangeType.AGE_RANGE_45_54,
    }
  },{
    value: 0,
    label: '55 - 64',
    age: {
      type: GoogleAdsEnum.AgeRangeType.AGE_RANGE_55_64,
    }
  },{
    value: 0,
    label: '65+',
    age: {
      type: GoogleAdsEnum.AgeRangeType.AGE_RANGE_65_UP,
    }
  }];
  
  const importData = (input?: InitType[]): InitType[] => {
    const ret = input ?? initInput;
    props.value?.map(el=>{
      const item = ret.find(r=>r.age.type === el.type);
      if(item) {
        item.value = 1;
        item.age = el;
      }
    });
    
    return ret;
  };
  
  const exportData = (input: InitType[]) => {
    const ret = input?.filter(el=>el.value).map(el=>{
      return el.age;
    });

    return ret;
  };

  const renderInput: InitType[] = importData();
  
  useEffect(() => {
    if(!props.value) {
      props.onChange(exportData(initInput));
    }
  }, [props.value]);
  
  const handleChange = (item: InitType) => {
    const data = renderInput;
    const i = data.find(el=>el.age.type == item.age.type);
    if(i) i.value = i.value ? 0 : 1;
    props.onChange(exportData(data));
  };
  
  
  return (<>
    <div className="input-group">
        <label>Tuổi</label>
        {renderInput?.map((item, id)=><div key={id} 
           className={classNames('form-check', {'mr-3': initInput.length > id})}>
          <div>
            <input 
              id={'ages' + id + item.label} 
              className="form-check-input" 
              type="checkbox"
              value={item.value}
              checked={!!item.value}
              onChange={(e)=>handleChange(item)} />
            <Label className={classNames('form-check-label', {'error': props.errors.ages})} htmlFor={'ages' + id + item.label}>{item.label}</Label>
          </div>
        </div>)}
      </div>
        {props.errors.ages && <ErrorInput text={props.errors.ages.message}/>}
  </>);
};