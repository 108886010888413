import numeral from 'numeral';

export const setupNumeral = () => {
  const vnLocale = {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: (num: number) => '',
    currency: {
      symbol: '₫',
    },
  };
  try {
    numeral.register('locale', 'vn', vnLocale);
  } catch (ex) {
    // Already registered!
  }
  numeral.locale('vn');
};
