import * as React from 'react';
import { Link } from 'react-router-dom';
import 'stylesheets/App.css';

const EmptyPage: React.FC = () => {
  return (
    <div className="empty-content">
      <div className="media">
        <img
          className="mr-5"
          src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/451x362/project-empty.png`}
          alt="PC Mockup"
        />
        <div className="media-body">
          <h3>Tạo dự án đầu tiên của bạn</h3>
          <p>Danh sách dự án của bạn sẽ được hiển thị ở đây.</p>
          <Link to={(globalThis as any).useHistory.getPath('/choose-project/project')}>Tạo ngay</Link>
        </div>
      </div>
    </div>
  );
};

export { EmptyPage };
