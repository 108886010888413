import { User } from 'core';

export default class VerifyAccountService {
  private _user: User;

  constructor(user: User) {
    this._user = user;
  }

  get NeedVerifyAccount(): boolean {
    return this._user.status === 'verifying' && (!this._user.email || !this._user.name || !this._user.phone_no);
  }

  get NeedVerifyEmail(): boolean {
    return this.NeedVerifyAccount && (!this._user.email || this._user.auth_provider === 'email' ||  this._user.auth_provider === 'facebook');
  }
}