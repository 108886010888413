import styled from 'styled-components';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { FieldErrors } from 'react-hook-form';
import { ErrorInput } from '../fields';
import classNames from 'classnames';
import {useEffect} from 'react';
import { CustomError, CustomErrorCode } from 'core';

interface PropType{
  onChange: (value: Date) => void; 
  errors: FieldErrors;
  value: Date | null;
  disabled?: boolean;
  customError?: CustomError;
}

const StyleDatePicker = styled(DatePicker)`
  &.error{
    border: 2px solid red !important;
    color: red;
  }
`;

export const StartDateField: React.FC<PropType> = (props) => {
  const { errors, value, disabled, customError } = props;
  const [startDate, setStartDate] = useState<Date>(new Date(value || new Date()));

  useEffect(()=>{
    if(!value) props.onChange(new Date());
  }, [value]);

  return (<>
    <div className="col-lg-6">
      <div className="input-group">
        <label>Ngày bắt đầu</label>
        <StyleDatePicker
          className={classNames('datepicker', {error: errors.start_date})}
          dateFormat="dd/MM/yyyy"
          selected={startDate} 
          disabled={disabled}
          onChange={(data: any) => {
            props.onChange(data);
            setStartDate(data);
          }}
        />
        {errors.start_date && <ErrorInput text={errors.start_date.message}/>}
        {customError &&  [CustomErrorCode.PastDay, CustomErrorCode.CantModifyStartDateIfStarted].includes(customError.code) && <ErrorInput text={customError.message}/>}
      </div>
    </div>
  </>);
};