"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTeamIntroAction = exports.TransferRoleAction = exports.JoinTeamAction = exports.GoToTeamAction = exports.InviteTeamAction = exports.TeamMembersAction = exports.CreateTeamAction = exports.LeaveTeamAction = exports.UpdateTeamAction = exports.GetTeamListAction = exports.GetTeamAction = void 0;
exports.GetTeamAction = {
    type: 'GET_TEAM',
    success: 'GET_TEAM_SUCCESS',
    error: 'GET_TEAM_ERROR',
};
exports.GetTeamListAction = {
    type: 'GET_TEAM_LIST',
    success: 'GET_TEAM_LIST_SUCCESS',
    error: 'GET_TEAM_LIST_ERROR',
};
exports.UpdateTeamAction = {
    type: 'UPDATE_TEAM',
    success: 'UPDATE_TEAM_SUCCESS',
    error: 'UPDATE_TEAM_ERROR',
    join: 'UPDATE_TEAM_JOIN',
    leave: 'UPDATE_TEAM_LEAVE'
};
exports.LeaveTeamAction = {
    type: 'LEAVE_TEAM',
    success: 'LEAVE_TEAM_SUCCESS',
    error: 'LEAVE_TEAM_ERROR',
};
exports.CreateTeamAction = {
    type: 'CREATE_TEAM',
    success: 'CREATE_TEAM_SUCCESS',
    error: 'CREATE_TEAM_ERROR',
};
exports.TeamMembersAction = {
    type: 'GET_TEAM_MEMBERS',
    success: 'GET_TEAM_MEMBERS_SUCCESS',
    error: 'GET_TEAM_MEMBERS_ERROR',
};
exports.InviteTeamAction = {
    type: 'INVITE_TEAM_MEMBERS',
    success: 'INVITE_TEAM_MEMBERS_SUCCESS',
    error: 'INVITE_TEAM_MEMBERS_ERROR',
    cancel: 'CANCEL_INVITE_TEAM_MEMBERS',
};
exports.GoToTeamAction = {
    type: 'GO_TO_TEAM',
    success: 'GO_TO_TEAM_SUCCESS',
    error: 'GO_TO_TEAM_ERROR',
};
exports.JoinTeamAction = {
    type: 'JOIN_TEAM',
    success: 'JOIN_TEAM_SUCCESS',
    error: 'JOIN_TEAM_ERROR',
};
exports.TransferRoleAction = {
    type: 'TRANSFER_ROLE_TEAM',
    success: 'TRANSFER_ROLE_TEAM_SUCCESS',
    error: 'TRANSFER_ROLE_TEAM_ERROR',
};
exports.GetTeamIntroAction = {
    type: 'GET_TEAM_INTRO',
    success: 'GET_TEAM_INTRO_SUCCESS',
    error: 'GET_TEAM_INTRO_ERROR',
};
