import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { KeywordGroup, GoogleAdsEnum, KeywordInfo, KeywordData } from 'core';
import { NegativeKeywordsProps, KeywordArrayProps, SPECS } from '../forms';
import ConfirmButton from 'components/buttons/ConfirmButton';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorInput } from '.';
import { toast } from 'react-toastify';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { ValueType } from 'react-select';
import { TooltipIcon, TrashIcon } from 'components/icons/SVGIcon';
import { Tooltip } from 'reactstrap';

const customStyles = {
  container: () => ({
    width: '100%',
  }),
  control: (provided: object) => ({
    ...provided,
    minHeight: 'auto',
    backgroundColor: '#f5f4f9',
    border: '1px solid #d8d8d8',
    borderRadius: '6px',
    marginTop: '8px'
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    padding: '8px 8px',
  }),
  input: (provided: object) => ({
    ...provided,
    margin: 0,
    fontFamily: 'Propins-Regular',
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: -0.32,
    color: '#000000'
  }),
  multiValue: (provided: object) => ({
    ...provided,
    padding: '3px 8px',
    backgroundColor: '#4c7cf3',
    borderRadius: 13
  }),
  multiValueLabel: (provided: object) => ({
    ...provided,
    color: '#ffffff',
    fontSize: 14,
    padding: 0
  }),
  placeholder: (provided: object) => ({
    ...provided,
    fontFamily: 'Propins-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.28,
    color: '#8e8e93'
  }),
};

type OptionType = {
  label: string;
  value: string;
  type: GoogleAdsEnum.KeywordMatchType;
};

interface KeywordItemProps {
  value: KeywordInfo[];
  onChange: (keywordInfos: KeywordInfo[]) => void;
  // transformOutput: (data: KeywordGroup[]) => void;
  index: number;
  errors: any;
  setError: any;
  clearErrors: any;
  collapse: boolean;
  // onRemove: () => void;
  // register: any;
}


interface KeywordMatchType {
  id: number;
  label: string;
  inputValue: string;
  type: GoogleAdsEnum.KeywordMatchType;
  items: KeywordInfo[];
  show: boolean;
}

interface InputType {
  [GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]: string;
  [GoogleAdsEnum.KeywordMatchType.BROAD]: string;
  [GoogleAdsEnum.KeywordMatchType.EXACT]: string;
}

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 999
  },
  content: {
    padding: '0px',
    paddingBottom: '20px',
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '477px',
    bottom: 'auto',
    marginRight: '-50%',
    border: '0',
    borderRadius: '12px',
    boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
    transform: 'translate(-50%, -50%)'
  }
};

export const KeywordItem: React.FC<KeywordItemProps> = (props) => {
  const [inputValueState, setInputValue] = useState<InputType>({
    [GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]: '',
    [GoogleAdsEnum.KeywordMatchType.BROAD]: '',
    [GoogleAdsEnum.KeywordMatchType.EXACT]: '',
  });

  const [keywordsList, setKeywordsList] = useState<KeywordMatchType[]>([]);
  const [tooltipOpen, setTooltipOpen] = useState<any>({
    [GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]: false,
    [GoogleAdsEnum.KeywordMatchType.BROAD]: false,
    [GoogleAdsEnum.KeywordMatchType.EXACT]: false,
  });

  const showTooltip = (el: GoogleAdsEnum.KeywordMatchType) => {
    setTooltipOpen({
      ...tooltipOpen,
      [el]: !tooltipOpen[el]
    });
  };
  
  const getKeywordMatchType = () => {
    if (keywordsList.length > 0) {
      return keywordsList.map(x => {
        return {
          ...x,
          items: []
        };
      });
    }
    const defaultKeywordMatchType: KeywordMatchType[] = [
      {
        id: 1,
        label: 'Đối sánh cụm từ cập nhật',
        type: GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE,
        items: [],
        inputValue: '',
        show: true
      },
      {
        id: 2,
        label: 'Đối sánh rộng',
        type: GoogleAdsEnum.KeywordMatchType.BROAD,
        items: [],
        inputValue: '',
        show: false
      },
      {
        id: 3,
        label: 'Đối sánh chính xác',
        type: GoogleAdsEnum.KeywordMatchType.EXACT,
        items: [],
        inputValue: '',
        show: false
      }
    ];
    return defaultKeywordMatchType;
  };

  const keywordMatchType: KeywordMatchType[] = getKeywordMatchType();

  const updateItemKeywordList = (type: GoogleAdsEnum.KeywordMatchType, value: KeywordInfo[]) => {
    const find = keywordsList.find((el: any) => el.type === type);
    if (find) find.items = value;
  };

  const iconSvg = <svg style={{width: 15, height: 15, marginBottom: 20, marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h24v24H0z" opacity=".01"/> <path fill="#ff3b30" d="M12.447 1.342a1 1 0 0 1 .447.447l10.382 20.764A1 1 0 0 1 22.382 24H1.618a1 1 0 0 1-.894-1.447L11.106 1.789a1 1 0 0 1 1.341-.447zM12 18a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-8.571c-.71 0-1.286.575-1.286 1.285v5.143a1.286 1.286 0 0 0 2.572 0v-5.143c0-.71-.576-1.285-1.286-1.285z"/> </g> </svg>;

  const addOption = (type: GoogleAdsEnum.KeywordMatchType, value: string): KeywordInfo[] | undefined => {

    const find = keywordsList?.find((el: any) => el.type === type);
    if (find) {
      let newValueArrayInput = find.items;
      if (newValueArrayInput.some(x => { return x.text === value; })) {
        return;
      }

      // const transformText = type === GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE ?
        // value.split(' ').map(el => '+' + el).join(' ') : value;

      const newKeywordInfo = new KeywordInfo();
      newKeywordInfo.text = value;
      newKeywordInfo.match_type = type;

      newValueArrayInput = [...newValueArrayInput, newKeywordInfo];

      return newValueArrayInput;
    }

    return undefined;
  };

  //transform input data to each keywords group
  const filterKeywords = (data: KeywordInfo[]): KeywordMatchType[] => {
    const list: KeywordMatchType[] = keywordMatchType;

    data.map((el: KeywordInfo) => {
      if (!el) return;
      const found = list.find(kw => kw.type === el.match_type);
      if (found) {
        
        //new update: Google remove Board Modified Match and the `+` sign
        //https://support.google.com/google-ads/answer/10286719?hl=vi
        //Keyword thoa man type `Doi sanh rong co sua doi` la:
        // + kieu doi sanh rong 
        // + tat ca ki tu bat dau bang dau `+`
        //Kieu BMM se duoc thay the bang kieu Phrase va loai bo dau +
        if (el.text && el.text.split(' ').every((item: string) => item.startsWith('+'))) {
          el.text = el.text.split(' ').map(el=>el.replace(new RegExp('^(\\+)+', 'g'), '')).join(' ');
          el.match_type = GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE;
          list[0].items.push(el);
        } else {
          found.items.push(el);
        }
      } else {
        if(el.match_type == 3) {
          el.match_type = GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE;
          list[0].items.push(el);
        }
      }
    });
    return list;
  };

  React.useEffect(() => {
    const data = filterKeywords(props.value || []);

    const [ignore, ...other] = data;
    const otherMatchKeyword = [...other.map(e => e.items)].flat().filter(e => e);
    if (otherMatchKeyword.length > 0) {
      const showAll = data.map(el => ({ ...el, show: true }));
      setKeywordsList(showAll);
    } else {
      setKeywordsList(data);
    }
  }, [props.value]);

  const notAllowDelete = keywordsList?.filter(el => el.show).length == 1;
  const toggleKeywordType = (id?: number) => {
    if (!id) {
      const showAll = keywordsList.map(el => { el.show = true; return el; });
      setKeywordsList(showAll);
    } else {
      if (notAllowDelete) return;
      const showOne = keywordsList?.map(el => {
        if (el.id === id) {
          el.show = !el.show;
          el.items = [];

          onChange(el.type, el.items);
        }
        return el;
      });
      setKeywordsList(showOne);
    }
  };

  const onKeyDown = (type: GoogleAdsEnum.KeywordMatchType, event: React.KeyboardEvent<HTMLElement>) => {
    const input = inputValueState[type];
    if (!input) return;

    if (event.key === 'Enter' || event.key === 'Tab') {
      const arr = addOption(type, inputValueState[type]);
      if (arr) {
        updateItemKeywordList(type, arr);

        const data = keywordsList?.filter(el => el.show).map(el => {
          return el.items;
        }).flat();

        if (data) props.onChange(data);
      }

      setInputValue({
        ...inputValueState,
        [type]: ''
      });
    }
  };

  const onChange = (type: GoogleAdsEnum.KeywordMatchType, value: any) => {
    props.clearErrors('keyword_groups' as never);
    updateItemKeywordList(type, value ? value : []);
    const keywordInfos = keywordsList.map(el => {
      return el.items;
    }).flat();

    if (keywordInfos) props.onChange(keywordInfos);

    setInputValue({
      ...inputValueState,
      [type]: ''
    });
  };

  const onInputChange = (type: GoogleAdsEnum.KeywordMatchType, value: string) => {
    props.clearErrors('keyword_groups' as never);
    setInputValue({
      ...inputValueState,
      [type]: value
    });
  };

  return (
    <>
      <div className={`col-lg-8 ${props.collapse ? 'hidden' : ''}`}>
        <h4>Từ khóa</h4>
        {keywordsList?.filter(el => el.show).map((el, id) => {
          return (
            <div key={el.label} className="row keyword-rows">
          <div className="col-lg-4" style={{paddingRight: 0}}>
            <div className="input-group">
                  <div className="keyword-key" >
                    {el.label}
                    {el.type === GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE &&
                      <>
                        <span className="pl-2" id={`keyword-phrase-${props.index}`}>
                          <TooltipIcon />
                        </span>
                      <Tooltip placement="bottom" isOpen={tooltipOpen[GoogleAdsEnum.KeywordMatchType.CUSTOM_PHRASE]} target={`keyword-phrase-${props.index}`} toggle={() => showTooltip(el.type)}>
                        Cho phép quảng cáo hiển thị cho các tìm kiếm bao gồm tất cả các từ trong từ khóa theo thứ tự.
                      </Tooltip>
                      </>
                    }
                    {el.type === GoogleAdsEnum.KeywordMatchType.BROAD &&
                      <>
                        <span className="pl-2" id={`keyword-broad-${props.index}`}>
                          <TooltipIcon />
                        </span>
                      <Tooltip placement="bottom" isOpen={tooltipOpen[GoogleAdsEnum.KeywordMatchType.BROAD]} target={`keyword-broad-${props.index}`} toggle={() => showTooltip(el.type)}>
                        Cho phép quảng cáo hiển thị cho các tìm kiếm liên quan đến từ khoá.
                      </Tooltip>
                      </>
                    }
                    {el.type === GoogleAdsEnum.KeywordMatchType.EXACT &&
                      <>
                        <span className="pl-2" id={`keyword-extract-${props.index}`}>
                          <TooltipIcon />
                        </span>
                      <Tooltip placement="bottom" isOpen={tooltipOpen[GoogleAdsEnum.KeywordMatchType.EXACT]} target={`keyword-extract-${props.index}`} toggle={() => showTooltip(el.type)}>
                        Cho phép quảng cáo hiển thị cho các tìm kiếm có cùng ý nghĩa với từ khoá.
                      </Tooltip>
                      </>
                    }

                  </div>
            </div>
          </div>
          <div className="col-lg-7">
            <CreatableSelect
              components={{
                DropdownIndicator: null,
              }}
              inputValue={inputValueState[el.type]}
              isMulti
              styles={customStyles}
              menuIsOpen={false}
              onChange={(data: ValueType<KeywordInfo, true>, actionMeta: any) => onChange(el.type, data)}
              onInputChange={(data: string) => data !== undefined && onInputChange(el.type, data)}
              onKeyDown={(data: any) => onKeyDown(el.type, data)}
              placeholder="Nhập và nhấn Enter để thêm từ khoá"
              value={el.items}
            />

            { 
            <ErrorMessage 
              errors={props.errors}
              name={`keyword_groups.${props.index}.keywords.${id}`}
              render={({ message }) => <ErrorInput style={{ marginTop: 5 }} text={message} />}
            />
            }

          </div>
          {!notAllowDelete &&
            <div className="col-lg-1 mt-2 delete-row-action cursor-pointer">
              <ConfirmButton
                customButtonStyle={'delete-keywords'}
                cancelButtonClassName={'btn-subsidiary'}
                onClick={() => toggleKeywordType(el.id)}
                customStyles={customModalStyles}
                confirmTitle={'Xoá'}
                cancelTitle={'Hủy'}
                comfirmMessage={
                  <div className="transfer-modal__container">
                    <h3 className="text-center">Xác nhận</h3>
                    <div className="transfer-modal__content">
                      Bạn có muốn xóa tất cả từ khóa của <span>{el.label}</span>?
                    </div>
                  </div>
                }
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2" fillRule="nonzero"> <g> <g> <g> <path d="M761 57c4.924 0 9-4.085 9-9 0-4.924-4.085-9-9.009-9-4.915 0-8.991 4.076-8.991 9 0 4.915 4.085 9 9 9zm3.097-5.153c-.203 0-.38-.088-.52-.22l-2.586-2.586-2.567 2.585c-.142.142-.327.221-.539.221-.397 0-.723-.335-.723-.741 0-.194.08-.37.212-.512l2.585-2.585-2.585-2.585c-.133-.142-.212-.318-.212-.512 0-.406.326-.724.723-.724.212 0 .38.07.512.212l2.594 2.585 2.62-2.594c.15-.15.31-.22.504-.22.397 0 .723.326.723.723 0 .203-.062.362-.212.52l-2.594 2.595 2.586 2.576c.14.15.211.318.211.52 0 .407-.326.742-.732.742z" transform="translate(-1107 -231) translate(155 166) translate(200) translate(0 26)" /> </g> </g> </g> </g> </g> </svg>
              </ConfirmButton>
            </div>}
            </div>);
        }
        )}
      </div>
      {(props.collapse && props.errors.keyword_groups && props.errors.keyword_groups[props.index]) ? <div className="col-lg-8 ">
            <div className="input-group">
              <span>{iconSvg}</span><ErrorInput text="Cần khắc phục vấn đề thiết lập bộ từ khóa"/>
            </div>
          </div> : null}
      {keywordsList?.some(el => !el.show) && <div className={`col-lg-8 offset-lg-3 ${props.collapse ? 'hidden' : ''}`}>
        <div className="link-add-another cursor-pointer" onClick={() => toggleKeywordType()}>
          Thêm loại đối sánh từ khoá
        </div>
      </div>}
    </>
  );
};

export const KeywordArray: React.FC<KeywordArrayProps> = (props) => {
  const [keywordItemsOpenState, setKeywordItemsOpenState] = useState({});
  const [editGroupNameIndex, setEditGroupIndex] = useState<number | undefined>(undefined);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: props.control,
      name: 'keyword_groups'
    }
  );

  const keywordGroups = props.watch('keyword_groups');

  const handleAddNew = () => {
    const prefixName = 'Bộ từ khóa';
    const endWithNumbers = new RegExp(/\d{1,}$/);
    const keywordWithDefaultNames = fields.filter(el => el.name?.trim().startsWith(prefixName) && endWithNumbers.test(el.name?.trim()) && el.name?.split(' ').length === 4);
    const keywordDefaultIndexs = keywordWithDefaultNames.map(x => {
      const parts = x.name?.split(' ') || [];
      return +parts[parts.length - 1];
    });

    const maxIndex = keywordDefaultIndexs.length > 0 ? Math.max.apply(null, keywordDefaultIndexs) : 0;
    const keywordGroup = new KeywordGroup();
    keywordGroup.name = prefixName + ' ' + (maxIndex + 1);

    append(keywordGroup);
  };


  const emptyNameExist = () => {
    return keywordGroups.some(e => !e.name);
  };

  const onStartEditKeywordGroupName = (id: number) => {
    if (!emptyNameExist()) {
      setEditGroupIndex(id);
    }
  };

  const onStopEditKeywordGroupName = (index: number, name: string) => {
    setEditGroupIndex(undefined);
    props.clearErrors();
  };

  const onKeyDown = (event: any, index: number, name: string) => {
    if (!name) return;
    
    if (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Esc') {
      onStopEditKeywordGroupName(index, name);
    }
  };

  const onBlur = (index: number, name: string) => {
    if (!name) return;
    onStopEditKeywordGroupName(index, name);
  };

  const renderAdGroupName = (id: number, name: string) => {
    return (
      <div>
        <div onClick={() => onStartEditKeywordGroupName(id)}>
          {editGroupNameIndex !== id && <h3>{name}</h3>}
        </div>
        <input
          className="keyword-key"
          type= {editGroupNameIndex === id ? undefined : 'hidden'}
          defaultValue={name}
          onKeyDown={(event: any) => onKeyDown(event, id, name)}
          {...props.register(`keyword_groups.${id}.name` as const)}
          onBlur={(event: any) => onBlur(id, name)}
        /> 
      </div>
    );
  };

  const renderConfirmRemoveButton = (id: number, keywordGroupName: string) => {   
    return (
      <ConfirmButton
        customButtonStyle={'delete-keywords'}
        cancelButtonClassName={'btn-subsidiary'}
        onClick={() => remove(id)}
        customStyles={customModalStyles}
        confirmTitle={'Tiếp tục'}
        cancelTitle={'Hủy'}
        comfirmMessage={
          <div className="transfer-modal__container">
            <h3 className="text-center">Xác nhận</h3>
            <div className="transfer-modal__content">
              Bạn đang thực hiện Xoá nhóm từ khoá <span>{keywordGroupName}</span>. Thao tác này sẽ đồng thời xoá nhóm quảng cáo tương ứng với nhóm từ khóa này.
            </div>
          </div>
        }
      >
        <TrashIcon />
      </ConfirmButton>
    );
  };
  
  return <>
    {fields.map((el: FieldArrayWithId<KeywordData, 'keyword_groups', 'id'>, id: number) => {

      const collapse = keywordItemsOpenState[id];
      // const kwName = props.watch(`keyword_groups.${id}.name`);
      const toggle = () => {
        setKeywordItemsOpenState(prev => ({ ...prev, [id]: !collapse }));
      };

      const keywordGroupName = keywordGroups && keywordGroups.length > id ? keywordGroups[id].name : el.name;

      return <div key={el.id}>
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h4 className="d-flex justify-content-between">Bộ từ khoá
              <div className="section-toogle cursor-pointer" onClick={() => toggle()}>
                {!collapse ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -758) translate(155 758) translate(154)" /> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -758) translate(155 758) translate(154) rotate(-180 7.807 8.331)" /> </g> </g> </g> </g> </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16)" /> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="translate(-309 -1311) translate(155 1311) matrix(1 0 0 -1 154 16) rotate(-180 7.807 8.331)" /> </g> </g> </g> </g> </svg>
                }
              </div>
            </h4>
            
            {renderAdGroupName(id, keywordGroupName)}
            
            <ErrorMessage
              errors={props.errors}
              name={`keyword_groups.${id}.name` as never}
              render={({ message }) => <ErrorInput text={message} />} />

            {/* <input {...props.register(`keyword_groups.${id}.ages`  as const)} type="hidden" defaultValue={el.ages} /> */}
            <input {...props.register(`keyword_groups.${id}.resource_name`  as const)} type="hidden" defaultValue={el.resource_name} /> 

            {renderConfirmRemoveButton(id, keywordGroupName)}
          </div>
          <KeywordItem
            {...props.register(`keyword_groups.${id}.keywords`  as const)}
            value={el.keywords}
            errors={props.errors}
            setError={props.setError}
            clearErrors={props.clearErrors}
            onChange={(keywords: KeywordInfo[]) => {
              props.setValue(`keyword_groups.${id}.keywords` as const, keywords as never);
            }}
            collapse={collapse}
            index={id}
          />

        </div>
      </div>;
    })}
    <div className="row row-seperate">
      <div className="col-lg-10 offset-lg-1">
        <ErrorMessage
          errors={props.errors}
          name={'keyword_groups' as never}
          render={({ message }) => <ErrorInput text={message} />}
        />
        <div className="add-extend-link cursor-pointer" onClick={() => handleAddNew()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M6.997 16C10.833 16 14 12.832 14 9s-3.174-7-7.01-7C3.16 2 0 5.168 0 9s3.167 7 6.997 7zm0-1.395c-3.106 0-5.59-2.498-5.59-5.605S3.891 3.401 6.99 3.401c3.106 0 5.603 2.492 5.61 5.599.006 3.107-2.498 5.605-5.603 5.605zm-.014-2.572c.4 0 .643-.27.643-.69V9.65h1.813c.406 0 .69-.244.69-.636 0-.4-.27-.644-.69-.644H7.626v-1.8c0-.42-.244-.69-.643-.69-.392 0-.63.283-.63.69v1.8H4.548c-.42 0-.697.244-.697.644 0 .392.291.636.697.636h1.807v1.692c0 .407.237.69.63.69z" transform="translate(-365 -1109) translate(355 754) translate(0 48) translate(10 305)" /> </g> </g> </g> </g> </g> </svg>
          <span>Thêm bộ từ khoá mới</span>
        </div>
      </div>
    </div>
  </>;
};

export const NegativeKeywordsArray: React.FC<NegativeKeywordsProps> = (props) => {
  const [keywords, setKeywords] = useState<KeywordInfo[]>(props.value);
  const [inputValue, setInputValue] = useState<string>('');
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  useEffect(() => {
    setKeywords(props.value);
  }, [props.value]);

  const onChange = (values: OptionType[] | undefined) => {
    let newKerwords: KeywordInfo[] = [];

    if (values) {
      newKerwords = keywords.filter(x => {
        return values.some(item => item.label === x.text);
      });
    }

    setKeywords(newKerwords);
    props.onChange(newKerwords);
  };

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onBlur = (value: any) => {
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const existedKeyword = keywords.find(el => el.text === inputValue);
      if (existedKeyword) {
        toast.error('Từ khóa này đã có, bạn hãy chọn từ khóa khác!');
        return;
      }
      const newKeyword = new KeywordInfo();
      newKeyword.text = inputValue;

      const array = [...keywords, newKeyword];
      setKeywords(array);
      setInputValue('');
      props.onChange(array);
    }
  };

  return (
    <div className="row row-seperate">
      <div className="col-lg-2 offset-lg-1">
        <div className="input-group">
          <h3>Từ khoá phủ định</h3>
          <span className="pl-2" id="negative-keyword">
            <TooltipIcon />
          </span>
          <Tooltip placement="bottom" isOpen={tooltipOpen} target="negative-keyword" toggle={() => setTooltipOpen(prev => !prev)}>
            Ngăn không cho quảng cáo của bạn hiển thị cho các tìm kiếm chứa những cụm từ khóa không liên quan đến sản phẩm và dịch vụ của bạn
          </Tooltip>
        </div>
      </div>
      <div className="col-lg-6">
        <h4 className="">Bộ từ khoá</h4>
        <CreatableSelect
          components={{
            DropdownIndicator: null,
          }}
          inputValue={inputValue}
          isClearable
          isMulti
          placeholder="+ Thêm từ khóa"
          styles={customStyles}
          menuIsOpen={false}
          onChange={onChange}
          onInputChange={onInputChange}
          onKeyDown={(data: any) => onKeyDown(data)}
          onBlur={onBlur}
          value={keywords.map(x => { return { label: x.text, value: x.text }; })}
        />
        <ErrorMessage
          errors={props.errors}
          name={'negative_keywords' as never}
          render={({ message }) => <ErrorInput text={message} />}
        />
      </div>
    </div>
  );
};

interface WordLengthPropsType {
  item: KeywordInfo[];
  max: number;
  className?: string;
  style: any;
}

export const WordLength: React.FC<WordLengthPropsType> = (props) => {
  const { item, max } = props;
  const len = item ? item.length : 0;
  const className = props.className ? props.className : 'text-field--input-counter';
  return (<>
    {len >= max ? <div className={className} style={{ color: 'red', ...props.style }}>{len + '/' + max}</div> : <div className={className} style={{ ...props.style }}>{`${len} / ${max - 1}`}</div>}
  </>);
};