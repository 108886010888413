import React, { useState, useEffect } from 'react';
import 'stylesheets/App.css';
import SubmitButton from 'buttons/SubmitButton';
import { KeywordAdsType } from '../Settings';
import { useGoogleForm, useAdGroup, useAdGroupAction } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { 
  CreateOrUpdateAdGroupParams, AppState, KeywordData, CreateOrUpdateAdGroupIP, KeywordGroup, KeywordInfo, CustomError } from 'core';
import { LoadingScreen } from 'components/common/LoadingScreen';
import { KeywordArray, NegativeKeywordsArray } from '../fields';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form';
import {isEmpty} from 'lodash';
import _ from 'lodash';

export function KeywordAdsForm(props: KeywordAdsType) {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [customError, setCustomError] = useState<CustomError>();
  const keywordSuggest = useSelector<any, KeywordData | null>((state: AppState) => state.adsCampaign.keywordSuggest);
  const adGroups = useSelector<any, KeywordData | null>((state: AppState) => state.adsCampaign.AdGroups);

  const getDefaultKeywordData = (): KeywordData => {
    if (adGroups && (adGroups.keyword_groups.length > 0 || adGroups.negative_keywords.length > 0)) {
      return adGroups.cloneResource();
    }
    if (keywordSuggest) {
      return keywordSuggest.cloneResource();
    }
    return new KeywordData();
  };

  const { 
    currentCampaign, Controller, useForm, vestResolver, keywordAdSchema
  } = useGoogleForm();
  
  const { updateAdGroup } = useAdGroupAction();
  
  const AdGroupInput = currentCampaign.getAdGroupInput();
  const campaign_resource_name = currentCampaign.getCampaignResourceName();
  
  const { register, reset, setValue, getValues, handleSubmit, watch, formState: {errors}, control, setError, clearErrors } = useForm<KeywordData>({
    resolver: vestResolver(keywordAdSchema),
    defaultValues:new KeywordData(),
    shouldUnregister: true
  });

  useEffect(() => {
    // Please don't delete this console.log(). Prevent undetected bug lost
    if (!_.isEmpty(errors)) console.log({ validationErr: errors });
    const firstError = document.querySelector('.error-input:not(.hidden *)');
    firstError?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }, [errors, customError]); 

  useEffect(() => {
    reset(getDefaultKeywordData());  
  }, [keywordSuggest, adGroups]);

  
  const watchAllFields = watch();

  const submit = (data: KeywordData) => {
    // console.log('submit', data);
    // console.log('adGroups',adGroups);
    // return;
    try{
      if(!isEmpty(errors) ) return;
      const callBackParams = {
          successCallback: (res: KeywordData) => {
            setLoadingSubmit(false);
            props.onSaveSuccess();
          },
          errorCallback: (err: any) => {
            setLoadingSubmit(false);
            props.onError(err);
          }
      };

      const keywordData = new KeywordData();
      keywordData.keyword_groups = data.keyword_groups.map(x => {
        const keywordGroup = new KeywordGroup();
        keywordGroup.ages = x.ages || [];
        keywordGroup.keywords = x.keywords?.filter(el=>el).length && x.keywords?.filter(el=>el) || [];
        keywordGroup.name = x.name || '';
        keywordGroup.operation = x.operation || undefined;
        keywordGroup.resource_name = x.resource_name || '';
        return keywordGroup;
      });
      keywordData.negative_keywords = data.negative_keywords;

      const inputData: CreateOrUpdateAdGroupIP = {
        campaign_resource_name: campaign_resource_name,
        keywordData: keywordData
      };
      const adParams: CreateOrUpdateAdGroupParams = {
        inputParams: inputData,
        callBackParams
      };
      setLoadingSubmit(true);
      updateAdGroup(adParams);
    
    }catch(err){
      console.error(err);
    }
  };
  
  useAdGroup({
    onSuccess: () => {
      setLoading(false);
    },
    campaignResourceName: campaign_resource_name,
    ages: AdGroupInput.ages,
    landing_page_id: AdGroupInput.landing_page_id
  });
  
  return (<>
    {loading && <LoadingScreen text="Đang tìm kiếm bộ từ khoá phù hợp cho chiến dịch của bạn"/>}
    <div className={`page-content keywordads-form ${loadingSubmit || loading ? 'hidden' : ''}`}>
      <div className="container overflow-visible">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="warning-text">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#8E8E93" fillRule="nonzero"> <g> <g> <g> <path d="M7 0c3.875 0 7 3.125 7 7s-3.125 7-7 7-7-3.125-7-7 3.125-7 7-7zm.426 5.833h-1.77c-.104 0-.17.017-.227.047-.057.03-.102.075-.132.132-.03.058-.047.123-.047.227v.227c0 .09.008.122.022.157.015.034.037.062.067.085.03.022.059.037.146.06l.58.144c.13.033.175.055.22.09.043.032.077.076.098.126.023.052.034.1.034.235v2.19c0 .135-.011.184-.034.235-.021.05-.055.094-.099.127-.045.034-.089.057-.22.09l-.58.144c-.086.023-.116.037-.145.06-.03.023-.052.051-.067.085-.014.034-.022.067-.022.156v.228c0 .104.016.17.047.226.03.057.075.102.132.133.057.03.123.046.226.046h2.981c.104 0 .17-.016.227-.046.056-.03.102-.076.132-.133.03-.057.047-.122.047-.226v-.228c0-.09-.008-.121-.022-.156-.015-.034-.037-.062-.067-.085-.03-.023-.059-.037-.146-.06l-.58-.144c-.13-.033-.175-.056-.22-.09-.043-.033-.077-.076-.099-.127-.022-.051-.033-.1-.033-.235V6.282c0-.156-.016-.213-.047-.27-.03-.056-.075-.102-.132-.132-.057-.03-.114-.047-.27-.047zM7 2.625c-.642 0-1.167.525-1.167 1.167 0 .641.525 1.166 1.167 1.166s1.167-.525 1.167-1.166c0-.642-.525-1.167-1.167-1.167z" transform="translate(-163 -113) translate(155 94) translate(8 8) translate(0 11)"/> </g> </g> </g> </g> </g> </svg>
              Dưới đây là các bộ từ khoá sắp xếp theo chủ đề, giúp định hướng nội dung quảng cáo. Mỗi bộ từ khoá tương ứng với một nhóm quảng cáo. Thêm mới bộ từ khoá sẽ thêm mới một nhóm quảng cáo ở bước tiếp theo.
            </div>
          </div>
        </div>
        <KeywordArray
          control={control}
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
        
        <Controller 
          name="negative_keywords"
          control={control}
          render={({field}) => <NegativeKeywordsArray 
            {...{
                value: field.value,
                onChange: field.onChange,
                watch, 
                register, 
                control, 
                setValue, 
                getValues, 
              }
            }
            errors={errors}
          />}
        />
        
        
      </div>
    </div>
    <div className={`${loadingSubmit || loading ? 'hidden' : ''} container bottom-bar`}>
      <div className="row">
        <div className="col-lg-10 offset-lg-1 display-flex">
          <SubmitButton onClick={handleSubmit(submit)}>{props.stepSettings === 4 ? 'Xuất bản chiến dịch' : 'Lưu và Tiếp tục'}</SubmitButton>
        </div>
      </div>
    </div>
    </>
  );
}

export interface NegativeKeywordsProps{
  value: KeywordInfo[];
  errors: any;
  onChange: (data: KeywordInfo[]) => void;
}

export interface KeywordArrayProps{
  register: UseFormRegister<KeywordData>;
  watch: UseFormWatch<KeywordData>;
  setValue: UseFormSetValue<KeywordData>;
  errors: any;
  setError: any;
  clearErrors: any;
  control: Control<KeywordData>;
}
