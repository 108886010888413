import { ProjectTypes } from 'core';
import { SideMenu, TopMenu } from 'pages/menu';
import * as React from 'react';
import { Prompt, Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { createEnumChecker } from 'utils/typeGuard';
import { ProjectTabs } from './ProjectTabs';

const Styles = styled.div`
  background-color: #f5f4f9;

`;

const CreateProject: React.FC = () => {
  const { projectType } = useParams<{ projectType: string }>();
  const isProjectTypes = createEnumChecker(ProjectTypes);
  const history = (globalThis as any).useHistory;
  if (!isProjectTypes(projectType)) {
    return <Redirect to={(globalThis as any).useHistory.url('/project-management')} />;
  }
  const goBackHistory = () => {
    history.go(-1);
  };

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <div className="main-content">
        <div className="page-heading">
          <div className="d-flex justify-content-start align-items-start">
            <span className="goBackLink" onClick={goBackHistory}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path fill="#FFF" d="M0 0H24V24H0z" opacity=".01" />
                  <path
                    fill="#000"
                    d="M8.004 14.124L6.94 13.06c-.282-.281-.44-.663-.44-1.061 0-.398.158-.78.44-1.061l1.05-1.05v-.001l5.6-5.6 1.797-1.798.022-.021.03-.03c.213-.2.418-.321.63-.386.23-.07.466-.07.696 0 .23.07.45.205.683.437l.06.061c.232.232.368.453.438.683.07.23.07.465 0 .695-.077.238-.21.454-.387.63L10.12 12l7.44 7.44c.2.213.321.418.386.63.07.23.07.466 0 .696-.07.23-.206.45-.438.683l-.06.06c-.232.232-.453.368-.683.438-.23.07-.465.07-.695 0-.238-.077-.454-.21-.63-.387l-7.437-7.435.001-.001z"
                  />
                </g>
              </svg>
            </span>
            <div>
              <h1>Tạo dự án</h1>
              <p>Hãy điền tất cả các thông tin để tạo dự án và landing page nhanh chóng.</p>
            </div>
          </div>
        </div>
        <ProjectTabs projectType={projectType} />
      </div>
    </Styles>
  );
};

export default CreateProject;
