import * as React from 'react';
import { ProjectService } from 'core';
import { apiErrorCodeGlobal } from 'core';

export const useFetchProject = (projectId: number | string | undefined) => {
  const service = new ProjectService();
  const [status, setStatus] = React.useState('pending');
  const [error, setError] = React.useState({});

  React.useEffect(()=>{
    function setState(status: string, error?: any){
      if(status) setStatus(status);
      if(error) setError(error);
    }
    
    if (projectId) {
      service.getProject({
        inputParams: {
          projectId: +projectId
        },
        callBackParams: {
          successCallback: (res: any) => {
            setState('success', null);
          },
          errorCallback: (err: any) => {
            setState('error', err);
          }
        }
      });
    }

    return (): void => {
      service.clearSelectedProject();
    };
  }, [projectId]);
  
  return {status, error};
};
