import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone, DropzoneProps } from 'react-dropzone';
import { Image, UploadPhotoParamsType } from 'core';
import { ProjectService, UploadImageParams } from 'core';

interface UploadImageProps extends DropzoneProps {
  projectId?: number;
  landingPageId?: number;
  category_code?: number;
  imageSelectedCallback?: (imgUrl: string, base64String: string, imgName: string) => void;
  uploadLater?: boolean;
  largeButton?: boolean;
  adsUpload?: boolean;
  uploadImageCallback?: (imgSrc: string) => void;
}

const UploadImageButton: React.FC<UploadImageProps> = (props) => {
  const service = new ProjectService();
  const dispatch = useDispatch();
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          const base64String = reader.result as string;
          const base64Data = base64String.split(',')[1];
          if (props.uploadLater && props.imageSelectedCallback) {
            const blobUrl = URL.createObjectURL(file);
            props.imageSelectedCallback(blobUrl, base64Data, file.name);
            return;
          }
          const photo: UploadPhotoParamsType =
            props.projectId ?
              {
                imageName: file.name,
                imageData: base64Data,
                projectId: props.projectId as number
              }
              :
              {
                imageName: file.name,
                imageData: base64Data,
                landingPageId: props.landingPageId as number
              };

          const params: UploadImageParams = {
            inputParams: {
              photo
            },
            callBackParams: {
              successCallback: (res: any) => {
                if (props.uploadImageCallback) {
                  props.uploadImageCallback(res.imgUrl);
                }
              },
              errorCallback: (err: any) => {
                //TODO: show error message
              }
            }
          };

          service.uploadImage(params);
        };
        reader.readAsDataURL(file);
      });
    },
    [props.imageSelectedCallback]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    onDrop: onDrop,
    onDropRejected: () => {
      //todo: show notification
    },
    ...props
  });

  if (props.adsUpload) {
    return (
      <div {...getRootProps({ className: 'dropzone upload-photo-button' })}>
        <input {...getInputProps()} />
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-670 -123) translate(143 52) translate(15 15) translate(0 31) translate(496 15) translate(16 10)" /> <path fill="#000" fillRule="nonzero" d="M14.245 12.5c.38 0 .693.282.743.648l.007.102v2c0 .38-.282.693-.648.743l-.102.007h-12.5c-.38 0-.694-.282-.743-.648l-.007-.102v-2c0-.414.336-.75.75-.75.38 0 .693.282.742.648l.007.102v1.25h11.001v-1.25c0-.38.283-.693.649-.743l.101-.007zM7.646.354c.196-.196.512-.196.708 0l4.792 4.792c.094.094.147.221.147.354 0 .276-.224.5-.5.5h-1.86c-.248 0-.46.183-.494.43l-.878 6.14c-.035.247-.246.43-.495.43H6.934c-.25 0-.46-.183-.495-.43L5.56 6.43C5.526 6.182 5.315 6 5.066 6H3.207c-.133 0-.26-.053-.353-.146-.196-.196-.196-.512 0-.708z" transform="translate(-670 -123) translate(143 52) translate(15 15) translate(0 31) translate(496 15) translate(16 10)" /> </g> </g> </g> </g> </g> </g> </g> </svg>
      </div>
    );
  }
  if (props.largeButton) {
    return (
      <div {...getRootProps({ className: 'dropzone larger-button' })}>
        <input {...getInputProps()} />
        <img src="https://cdn-static.ymeet.me/general/REI/icon/upload-files-xl.svg" alt="" />
        <h3>Hãy kéo thả ảnh vào đây</h3>
        <span>hoặc</span>
        <p>Tải ảnh</p>
      </div>
    );
  }

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <img src="https://cdn-static.ymeet.me/general/REI/icon/upload-files.svg" alt="" />
      <p>Tải thêm ảnh</p>
    </div>
  );
};

export default UploadImageButton;
