"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageService = void 0;
const frameworks_1 = require("../../frameworks");
class LandingPageService {
    static findUserLandingPage(landingPageId) {
        const state = frameworks_1.store.getState();
        if (state.landingPages.userLandingPages.length === 0) {
            return undefined;
        }
        return state.landingPages.userLandingPages.find(x => x.id === landingPageId);
    }
}
exports.LandingPageService = LandingPageService;
