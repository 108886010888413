"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignListReport = exports.CampaignReport = exports.CampaignMetrics = exports.ConversionMetrics = exports.EngagementMetrics = exports.ClickMetric = exports.ProjectInfo = exports.CampaignConnectedAccount = exports.CampaignInfo = void 0;
const class_transformer_1 = require("class-transformer");
class CampaignInfo {
    constructor() {
        this.resource_name = '';
        this.id = null;
        this.name = '';
        this.status = null;
        this.composite_status = null;
    }
    static clone(campaignInfo) {
        const newCampaignInfo = new CampaignInfo();
        newCampaignInfo.id = campaignInfo.id;
        newCampaignInfo.name = campaignInfo.name;
        newCampaignInfo.resource_name = campaignInfo.resource_name;
        newCampaignInfo.status = campaignInfo.status;
        newCampaignInfo.composite_status = campaignInfo.composite_status;
        return newCampaignInfo;
    }
}
exports.CampaignInfo = CampaignInfo;
class CampaignConnectedAccount {
    constructor() {
        this.provider_account_id = '';
        this.name = '';
        this.connected_account_id = 0;
    }
}
exports.CampaignConnectedAccount = CampaignConnectedAccount;
class ProjectInfo {
    constructor() {
        this.id = null;
        this.name = '';
    }
}
exports.ProjectInfo = ProjectInfo;
class ClickMetric {
    constructor() {
        this.num_clicks = 0;
        this.rate = 0;
    }
}
exports.ClickMetric = ClickMetric;
class EngagementMetrics {
    constructor() {
        this.num_engagements = 0;
        this.rate = 0;
    }
}
exports.EngagementMetrics = EngagementMetrics;
class ConversionMetrics {
    constructor() {
        this.num_conversions = 0;
        this.rate = 0;
    }
}
exports.ConversionMetrics = ConversionMetrics;
class CampaignMetrics {
    constructor() {
        this.cost = 0;
        this.impressions = 0;
        this.clicks = new ClickMetric();
        this.engagements = new EngagementMetrics();
        this.conversions = new ConversionMetrics();
        this.effective = '';
    }
}
__decorate([
    class_transformer_1.Type(() => ClickMetric)
], CampaignMetrics.prototype, "clicks", void 0);
__decorate([
    class_transformer_1.Type(() => EngagementMetrics)
], CampaignMetrics.prototype, "engagements", void 0);
__decorate([
    class_transformer_1.Type(() => ConversionMetrics)
], CampaignMetrics.prototype, "conversions", void 0);
exports.CampaignMetrics = CampaignMetrics;
class CampaignReport {
    constructor() {
        this.google_ads_campaign_id = null;
        this.project = new ProjectInfo();
        this.campaign = new CampaignInfo();
        this.connected_account = new CampaignConnectedAccount();
        this.metrics = new CampaignMetrics();
        this.landing_page_id = null;
    }
    get ProjectName() {
        return this.project.name;
    }
    get CampaignName() {
        return this.campaign.name;
    }
    get CampaignStatus() {
        return this.campaign.status;
    }
    get MetricCost() {
        return this.metrics.cost;
    }
    get MetricImpressions() {
        return this.metrics.impressions;
    }
    get MetricNumClick() {
        return this.metrics.clicks.num_clicks;
    }
    get MetricRateClick() {
        return this.metrics.clicks.rate;
    }
    get MetricNumConversions() {
        return this.metrics.conversions.num_conversions;
    }
    get MetricRateConversions() {
        return this.metrics.conversions.rate;
    }
    get MetricNumEngagement() {
        return this.metrics.engagements.num_engagements;
    }
    get MetricRateEngagement() {
        return this.metrics.engagements.rate;
    }
    get MetricEffective() {
        return this.metrics.effective;
    }
    get id() {
        return this.campaign.id;
    }
}
__decorate([
    class_transformer_1.Type(() => ProjectInfo)
], CampaignReport.prototype, "project", void 0);
__decorate([
    class_transformer_1.Type(() => CampaignInfo)
], CampaignReport.prototype, "campaign", void 0);
__decorate([
    class_transformer_1.Type(() => CampaignMetrics)
], CampaignReport.prototype, "metrics", void 0);
__decorate([
    class_transformer_1.Type(() => CampaignConnectedAccount)
], CampaignReport.prototype, "connected_account", void 0);
exports.CampaignReport = CampaignReport;
class CampaignListReport {
    constructor() {
        this.total_leads = 0;
        this.total_cost = 0;
        this.campaigns = [];
    }
}
__decorate([
    class_transformer_1.Type(() => CampaignReport)
], CampaignListReport.prototype, "campaigns", void 0);
exports.CampaignListReport = CampaignListReport;
