import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';

// modified from https://usehooks.com/useRouter/
export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const search = location.search.substring(1);
  let queryString = {};
  try{
    const body = decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"');
    queryString = JSON.parse(`{${body ? '"' + body + '"' : '' }}`);
  }catch(err){
    console.log(err);
  }
  
  const origin = (options?: any): string => {
    const appDomain = process.env.REACT_APP_DOMAIN || 'localhost';
    const appDomainOrigin = appDomain.startsWith('http') ? appDomain : '//' + appDomain;
    const url = new URL(appDomainOrigin, window.location.origin);
    
    if(options?.port && window.location.port) url.port = window.location.port;
    if(options?.protocol) url.protocol = window.location.protocol;
    if(options?.hostname) return url.hostname;
    if(options?.origin) return url.origin + `${window.location.port ? ':' + window.location.port : ''}`;
    
    return url.toString();
  };

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString,
        ...params
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
      origin
    };
  }, [params, match, location, history]);
}