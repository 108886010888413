import * as React from 'react';
import { ButtonProps } from 'presenter';
import Button from './Button';
import '../../stylesheets/Button.css';

const DeleteButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      className='btn-red ml-3'
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default DeleteButton;

