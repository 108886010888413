"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPage = exports.TemplateLandingPage = exports.LandingPageVersionStatus = void 0;
var LandingPageVersionStatus;
(function (LandingPageVersionStatus) {
    LandingPageVersionStatus[LandingPageVersionStatus["draft"] = 1] = "draft";
    LandingPageVersionStatus[LandingPageVersionStatus["published"] = 2] = "published";
    LandingPageVersionStatus[LandingPageVersionStatus["deactive"] = 3] = "deactive";
})(LandingPageVersionStatus = exports.LandingPageVersionStatus || (exports.LandingPageVersionStatus = {}));
class TemplateLandingPage {
    constructor(pid, ptype_code, lp_id, lp_name, lp_subname, status, is_template, versions, url, thumbnail) {
        this.pid = pid;
        this.ptype_code = ptype_code;
        this.lp_id = lp_id;
        this.lp_name = lp_name;
        this.lp_subname = lp_subname;
        this.status = status;
        this.is_template = is_template;
        this.versions = versions;
        this.thumbnail = thumbnail || '';
        this.url = url || '';
    }
    get projectType() {
        return this.ptype_code;
    }
}
exports.TemplateLandingPage = TemplateLandingPage;
class LandingPage {
    constructor(pid, pname, lp_id, lp_name, created_at, url, builder_version, lp_owner_name, status, page_views, visitors, obtained_contacts, versions, is_template, components, is_owner) {
        this.pid = pid;
        this.pname = pname;
        this.lp_id = lp_id;
        this.lp_name = lp_name;
        this.created_at = created_at;
        this.builder_version = builder_version;
        this.url = url;
        this.status = status;
        this.page_views = page_views;
        this.visitors = visitors;
        this.obtained_contacts = obtained_contacts;
        this.versions = versions;
        this.is_template = is_template;
        this.components = components;
        this.lp_owner_name = lp_owner_name;
        this.is_owner = is_owner || false;
    }
    get id() {
        return this.lp_id;
    }
    get groupId() {
        return this.pid;
    }
    get groupName() {
        return this.pname;
    }
    clone() {
        return new LandingPage(this.pid, this.pname, this.lp_id, this.lp_name, this.created_at, this.url, this.builder_version, this.lp_owner_name, this.status, this.page_views, this.visitors, this.obtained_contacts, this.versions, this.is_template, this.components, this.is_owner);
    }
}
exports.LandingPage = LandingPage;
