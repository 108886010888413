"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiErrorCodeGlobal = void 0;
exports.apiErrorCodeGlobal = {
    '100': "has_active_landing_page",
    '101': "no_landing_page_found",
    '102': "no_published_version_found",
    '103': "cannot_parse_json_string",
    '104': "failed_to_send_email.",
    '105': "Số điện thoại không được để trống.",
    '300': 'Thiếu thông tin đăng nhập.',
    '301': 'Có lỗi trong quá trình đăng nhập.',
    '302': 'Email chưa được đăng ký.',
    '303': 'Email hoặc mật khẩu không đúng.',
    '304': 'Email đã được sử dụng.',
    '305': 'Email chưa xác thực.',
    '306': 'Người dùng không hoạt động.',
    '307': 'Token không hợp lệ.',
    '308': 'Bạn đã sử dụng mật khẩu này. Vui lòng sử dụng mật khẩu mới không trùng lặp.',
    '309': 'Đã tồn tại tài khoản đăng ký với email này, vui lòng kiểm tra lại.',
    '400': 'Không tìm thấy tài khoản nhóm.',
    '401': 'Nhóm không tồn tại.',
    '402': 'Nhóm mới tạo trùng mới nhóm hiện tại.',
    '403': 'Chỉ chủ sở hữu mới có thể gán quyền.',
    '404': 'Không tìm thấy lời mời.',
    '405': 'Người được mời đã tham gia nhóm.',
    '406': 'Đường dẫn bị trùng, vui lòng sử dụng tên khác.',
    '407': 'Thành viên không ở trong nhóm.',
    '408': 'Không thể xóa thành viên ra khỏi nhóm.',
    '409': 'Chủ sở hữu không thể rời nhóm.',
    '410': 'master_user_cannot_leave',
    '411': 'Thành viên không có quyền.',
    '412': 'Email đã tồn tại trong nhóm.',
    '413': 'Không thể tạo workspace cho nhóm',
    '414': 'Người dùng không thuộc nhóm làm việc' //create_team_workspace_failed
};
exports.default = exports.apiErrorCodeGlobal;
