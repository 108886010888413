import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ProjectState, Project, AppState } from 'core';
import { ProjectsContent } from './ProjectsContent';
import { SideMenu, TopMenu } from '../menu';
import { useFetchListProjects } from './hooks';

const Styles = styled.div`
  background-color: #f5f4f9;
  .login-header {
    box-shadow: 0 1px 0 0 rgba(161, 161, 176, 0.2);
    background-color: #ffffff;
    padding: 25px 0;
    text-align: center;
  }

`;

interface ProjectProps {
  projects: ProjectState;
}

const ProjectsManagement = (props: ProjectProps) => {
  useFetchListProjects(props.projects);

  const projects: Array<Project> = props.projects.userProjects;

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <ProjectsContent projects={projects} />
    </Styles>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    projects: state.projects
  };
};

const ProjectsManagementPage = connect(mapStateToProps)(ProjectsManagement);
export default ProjectsManagementPage;
