"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.landingPageSagas = exports.removeLPListAfterDeleteProjectSaga = exports.copyLandingPageSaga = exports.updateLandingPageSaga = exports.updateLandingPageStatusSaga = exports.unpublishLandingPageSaga = exports.createLandingPageSaga = exports.getTemplateLandingPageSaga = exports.getLandingPageSaga = void 0;
const effects_1 = require("redux-saga/effects");
const class_transformer_1 = require("class-transformer");
const entities_1 = require("../../entities/");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
function* getLandingPageSaga() {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/user_landing_pages`,
            authenticated: true
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const landing_pages = response.data.data.landing_pages;
        if (landing_pages) {
            landing_pages.map((lp) => { if (lp.versions)
                lp.versions.map((el) => { el['is_owner'] = lp.is_owner; return el; }); });
        }
        const landingPages = class_transformer_1.plainToClass(entities_1.LandingPage, landing_pages);
        yield effects_1.put({
            type: types_1.GET_LANDING_PAGES_SUCCESS,
            landingPages
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.GET_LANDING_PAGES_ERROR, err });
    }
}
exports.getLandingPageSaga = getLandingPageSaga;
function* getTemplateLandingPageSaga() {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/template_landing_pages`,
            authenticated: true
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.GET_TEMPLATE_LANDING_PAGES_SUCCESS,
            landingPages: class_transformer_1.plainToClass(entities_1.TemplateLandingPage, response.data.data.landing_pages)
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.GET_TEMPLATE_LANDING_PAGES_ERROR, err });
    }
}
exports.getTemplateLandingPageSaga = getTemplateLandingPageSaga;
function* createLandingPageSaga(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/landing_page`,
            authenticated: true,
            data: {
                name: action.parameter.name,
                project_id: action.parameter.projectId,
                template_landing_page_id: action.parameter.templateLandingPageId
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const landingPage = class_transformer_1.plainToClass(entities_1.LandingPage, response.data.data.landing_page);
        yield effects_1.put({ type: types_1.CREATE_LANDING_PAGE_SUCCESS, landingPage, meta: action.meta, payload: landingPage });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.CREATE_LANDING_PAGE_ERROR, err });
    }
}
exports.createLandingPageSaga = createLandingPageSaga;
function* unpublishLandingPageSaga(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/unpublish_landing_page`,
            authenticated: true,
            data: {
                landing_page_id: action.landingPageId
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({ type: types_1.UNPUBLISH_LANDING_PAGE_SUCCESS, landingPageId: action.landingPageId });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.UNPUBLISH_LANDING_PAGE_ERROR, err });
    }
}
exports.unpublishLandingPageSaga = unpublishLandingPageSaga;
function* updateLandingPageStatusSaga(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/update_landing_page_status`,
            authenticated: true,
            data: {
                landing_page_id: action.parameter.landingPageId,
                status: action.parameter.status
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const landingPage = class_transformer_1.plainToClass(entities_1.LandingPage, response.data.data);
        yield effects_1.put({ type: types_1.UPDATE_LANDING_PAGE_STATUS_SUCCESS, landingPage, status: action.parameter.status });
        yield getLandingPageSaga();
    }
    catch (err) {
        yield effects_1.put({ type: types_1.UPDATE_LANDING_PAGE_STATUS_ERROR, err });
    }
}
exports.updateLandingPageStatusSaga = updateLandingPageStatusSaga;
function* updateLandingPageSaga(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/update_landing_page`,
            authenticated: true,
            data: Object.assign({}, action.data)
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const data = class_transformer_1.plainToClass(entities_1.LandingPage, response.data.data.landing_page);
        yield effects_1.put({ type: types_1.UPDATE_LANDING_PAGE_SUCCESS, data });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.UPDATE_LANDING_PAGE_ERROR, err });
    }
}
exports.updateLandingPageSaga = updateLandingPageSaga;
function* copyLandingPageSaga(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/copy_landing_page`,
            authenticated: true,
            data: Object.assign({}, action.parameters)
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const landingPage = class_transformer_1.plainToClass(entities_1.LandingPage, response.data.data.landing_page);
        yield effects_1.put({ type: types_1.COPY_LANDING_PAGE_SUCCESS, landingPage });
    }
    catch (error) {
        yield effects_1.put({ type: types_1.COPY_LANDING_PAGE_ERROR, error });
    }
}
exports.copyLandingPageSaga = copyLandingPageSaga;
function* removeLPListAfterDeleteProjectSaga(action) {
    try {
        yield effects_1.put({
            type: types_1.REMOVE_LP_LIST_AFTER_DELETE_PROJECT_SUCCESS,
            projectId: action.projectId
        });
    }
    catch (error) {
    }
}
exports.removeLPListAfterDeleteProjectSaga = removeLPListAfterDeleteProjectSaga;
exports.landingPageSagas = [
    effects_1.takeLatest(types_1.GET_LANDING_PAGES, getLandingPageSaga),
    effects_1.takeLatest(types_1.GET_TEMPLATE_LANDING_PAGES, getTemplateLandingPageSaga),
    effects_1.takeLatest(types_1.CREATE_LANDING_PAGE_REQUEST, createLandingPageSaga),
    effects_1.takeLatest(types_1.UNPUBLISH_LANDING_PAGE_REQUEST, unpublishLandingPageSaga),
    effects_1.takeLatest(types_1.UPDATE_LANDING_PAGE_STATUS_REQUEST, updateLandingPageStatusSaga),
    effects_1.takeLatest(types_1.UPDATE_LANDING_PAGE, updateLandingPageSaga),
    effects_1.takeLatest(types_1.COPY_LANDING_PAGE_REQUEST, copyLandingPageSaga),
    effects_1.takeLatest(types_1.REMOVE_LP_LIST_AFTER_DELETE_PROJECT, removeLPListAfterDeleteProjectSaga),
];
