import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getCustomers } from 'core';

export const useFetchCustomers = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);
};
