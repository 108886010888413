import * as React from 'react';
import { useEffect } from 'react';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { TokenCookies, Token, useCookie, useRedirectDomain } from 'pages/auth/hooks';
import { withCookies, Cookies } from 'react-cookie';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

type ConnectFacebookProps = {
  cookies: Cookies;
};

const ConnectFacebook: React.FC<ConnectFacebookProps> = (props) => {
  const { setCookie, getAllCookies } = useCookie(props.cookies);
  const query = new URLSearchParams(useLocation().search);
  const isRefresh = query.get('isRefresh') == 'true' || false;

  const handleConnectFb = (response: ReactFacebookLoginInfo) => {
    const { accessToken, userID } = response;
    const userToken = getAllCookies(Token.UserToken) as string;
    const authStr = `Bearer ${userToken}`; 

    if (accessToken && userID) {
      const URL = `${process.env.REACT_APP_API_URL}/fanpages?fb_token=${accessToken}&provider_account_id=${userID}`;
      axios.get(URL, { headers: { Authorization: authStr } })
      .then(res => {
          if (res && res.data && res.data.data) {
            if (isRefresh) {
              setCookie('isRefreshConnectFb', 'true');
            } else {
              setCookie('fanpageData', JSON.stringify(res.data.data));
            }
          }
       })
      .catch((error) => {
          console.log('error ' + error);
       });
    }
  };

  if (isRefresh) {
    return (
      <Styles2>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
          fields="name,email,picture"
          textButton={'Thêm Facebook Fanpage khác'}
          callback={handleConnectFb}
          icon={
            <div className="float-icon"></div>}
          scope="email,public_profile,pages_messaging,pages_show_list,pages_manage_metadata"
        />
      </Styles2>
    );
  }

  return (
    <Styles>
      <p>Để thiết lập chatbot cho trang landing page, trước tiên vui lòng Kết nối tài khoản Facebook của bạn.</p>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
        fields="name,email,picture"
        textButton={'Kết nối tài khoản Facebook'}
        callback={handleConnectFb}
        icon={
          <div className="float-icon"></div>}
        scope="email,public_profile,pages_messaging,pages_show_list,pages_manage_metadata"
      />
    </Styles>
  );
};

const Styles2 = styled.div`
  background-color: #ffffff;
  .kep-login-facebook.metro {
    position: relative;
    width: auto;
    margin-top: 10px;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    text-align: center;
    color: #4c7cf3;
    text-decoration: underline;
    text-transform: none;
    transition: all 0.3s;
  }
  .kep-login-facebook.metro:hover {
    box-shadow: none;
  }
  #tawkchat-minified-box {
    display: none;
  }
`;

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  height: 100vh;
  p {
    margin: 40px 0 20px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    text-align: center;
    color: #666666;
  }
  .kep-login-facebook.metro {
    position: relative;
    width: auto;
    border-radius: 6px;
    padding: 10px 20px;
    background-color: #4c7cf3;
    border: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: none;
    transition: all 0.3s;
  }
  .kep-login-facebook.metro:hover {
    box-shadow: 0 1px 8px 0 rgba(0,0,0,0.10);
  }
`;

export default withCookies(ConnectFacebook);