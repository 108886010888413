"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.campaignForecast = void 0;
const auth_1 = require("../../auth");
const ads_campaign_1 = require("../../ads-campaign");
const CampaignForecast_1 = require("../types/CampaignForecast");
const initialState = {
    campaignForecast: undefined
};
const campaignForecast = (state = initialState, action) => {
    switch (action.type) {
        case CampaignForecast_1.GoogleAdsCampaignForecastTypes.success:
            return Object.assign({}, state, {
                campaignForecast: action.payload
            });
        case ads_campaign_1.StartCreatingCampaign.type:
            return Object.assign({}, state, {
                campaignForecast: undefined
            });
        case auth_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.campaignForecast = campaignForecast;
