import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, SelectedTeam, WorkSpaceService } from 'core';
import { EmailService } from 'src/pages/auth/service';
import { ApiErrorMsg, FieldType, InputErrorMsg, State } from '../service';
import SubmitButton from 'buttons/SubmitButton';
import CreatableSelect from 'react-select/creatable';
import * as ReactModal from 'react-modal';
import { valueContainerCSS } from 'react-select/src/components/containers';
import { isIterable } from '../../../utils/typeGuard';
import { plainToClass } from 'class-transformer';
import { toast } from 'react-toastify';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  openSentInviteMembersModal: (emails: string[]) => void;
}
ReactModal.setAppElement('#root');

type OptionType = { label: string; value: string };

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

const InviteMembersModal: React.FC<ModalProps> = (props) => {
  const [inputValueState, setInputValueState] = useState('');
  const [valueArrayInput, setValueArrayInput] = useState(new Array<OptionType>());
  const selectedTeam = useSelector((state: State) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const mounted = useRef(false);
  const apiError: any = useSelector((state: any) => state.auth.forgotError);
  const [apiErrorCode, setApiErrorCode] = useState({
    error: false,
    code: '0'
  });
  const [errorStatus, setErrorStatus] = useState({
    email: false
  });
  const [isInvalidEmailState, setIsInvalidEmailState] = useState(false);

  const workspaceSrv = new WorkSpaceService();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if(!apiError) return;
    setApiErrorCode({
      error: true,
      code: apiError.code
    });
  }, [apiError]);

  const closeModal = () => {
    resetAllInput();
    props.closeModal();
  };

  const handleInviteEmail = () => {
    const emails = valueArrayInput.map(v => v.value);
    const params = {
      inputParams: {
        emails
      },
      callBackParams: {
        successCallback: (res: any) => {
          if(res['success']){
            resetAllInput();
            props.closeModal();
            props.openSentInviteMembersModal(emails);
          }
        },
        errorCallback: (err: any) => {
          if (err.code == 500) {
            toast.error('Hiện tại không thể thêm thành viên vào nhóm do vượt quá số lượng thành viên.', { autoClose: false });
          }
          if (err.code == 412) {
            toast.error(`Người dùng với địa chỉ email ${err.description} đã là thành viên của nhóm.`, { autoClose: false });
          }
        }
      }
    };
    workspaceSrv.inviteTeam(params);
  };

  const onChange = (value: any, actionMeta: any) => {
    setValueArrayInput(value);
    if (validToInvite(value)) {
      setIsInvalidEmailState(false);
    }
  };

  const onInputChange = (value: string) => {
    setInputValueState(value);

    if (value && EmailService.validateEmailFormat(value)) {
      setIsInvalidEmailState(false);
    }
    //Have to create email tag once pressing ',' or space btn
    const isCommaOrSpacePress = value.includes(' ') || value.includes(',');
    const correctInput = value.replace(/[\s,]/g, '');
    if (isCommaOrSpacePress) {
      setNewValueArrayInput(correctInput);
      setInputValueState('');
    }
  };

  const onBlurInput = (e: any) => {
    const { value } = e.target;
    if (value) {
      setNewValueArrayInput(value);
    }
  };

  const validToInvite = (arrInput: OptionType[] | null): boolean => {
    if (!arrInput || !arrInput.length) return false;
    
    for (let i = 0; i < arrInput.length; i++) {
      const email = arrInput[i].value;
      if (!EmailService.validateEmailFormat(email)) return false;
    }

    return true;
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValueState) return;

    if (event.key === 'Enter' || event.key === 'Tab') {
      setNewValueArrayInput(inputValueState);
      setInputValueState('');
    }
  };

  const setNewValueArrayInput = (value: string) => {
    let newValueArrayInput = valueArrayInput;

    if(isIterable(newValueArrayInput)) {
      newValueArrayInput = [...valueArrayInput, createOption(value)];
    }
    else {
      newValueArrayInput = [createOption(value)];
    }
    setValueArrayInput(newValueArrayInput);
    
    if (newValueArrayInput && newValueArrayInput.length && !validToInvite(newValueArrayInput)) {
      setIsInvalidEmailState(true);
    }
  };

  const resetAllInput = () => {
    setValueArrayInput(new Array<OptionType>());
    setInputValueState('');
  };

  const renderApiError = (shouldShow: boolean, code: string) => {
    if (!shouldShow) return null;
    return (
      <div className='col-lg-12 mt-1' style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg) => {
    if (!ifError) return null;
    return (
      <div className='col-lg-12 mt-1' style={{color: 'red'}}>{errMsg}</div>
    );
  };

  const customInputStyles = {
    container: () => ({
      width: '100%',
    }),
    valueContainer: (provided: object) => ({
      ...provided,
      padding: '20px 10px',
    }),
    input: (provided: object) => ({
      ...provided,
      margin: 0,
      fontFamily: 'Propins-Regular',
      fontSize: 14,
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.2,
      color: '#000000',
      marginBottom: 10
    }),
    multiValue: (provided: object) => ({
      ...provided,
      padding: '6px 10px',
      margin: 0,
      marginRight: 10,
      marginBottom: 10,
      borderRadius: 15
    }),
    multiValueLabel: (provided: object) => ({
      ...provided,
      padding: 0,
      paddingLeft: 0,
      marginRight: 10,
      fontFamily: 'Propins-Regular',
      fontSize: 14,
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.2,
      color: '#000000'
    }),
    placeholder: (provided: object) => ({
      ...provided,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      top: 'calc(50% - 5px)',
      color: '#666666'
    }),
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="invite-members-modal">
        <div className="modal__heading">
          <h3>Thêm thành viên</h3>
          <p>Nhóm #{selectedTeam.getTeamName()}</p>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            <p>Nhập địa chỉ email để mời thành viên vào nhóm</p>
            <CreatableSelect
              components={components}
              inputValue={inputValueState}
              isClearable
              isMulti
              styles={customInputStyles}
              menuIsOpen={false}
              onChange={onChange}
              onInputChange={onInputChange}
              onKeyDown={onKeyDown}
              placeholder="Nhập email và nhấn Enter"
              value={valueArrayInput}
              onBlur={onBlurInput}
            />
          </div>
        </div>
        {renderInputError(isInvalidEmailState, InputErrorMsg.InvalidEmail)}
        {renderApiError(apiErrorCode.error, apiErrorCode.code)}
        <div className="modal__bottom">
          <SubmitButton onClick={handleInviteEmail} isDisabled={!validToInvite(valueArrayInput)}>Gửi lời mời</SubmitButton>
        </div>
      </div>
    </ReactModal>
  );
};

InviteMembersModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default InviteMembersModal;
