import * as React from 'react';
import { useState, useEffect } from 'react';
import Modal from 'components/modals';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import { CropPoints } from 'core';
import { Size } from 'pages/ads-creation/entities/Types';

interface PropsType {
  imgSrc: string;
  imageSize: Size;
  aspect: number;
  cropImageCallback?: (cropArea?: CropPoints) => void;
}

const CropImageButton: React.FC<PropsType> = (props) => {
  const {imageSize} = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropPoint, setCropPoint] = useState<CropPoints | undefined>(undefined);
  const closeModal = (): void => setIsOpen(false);

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: any) => {
    setZoom(zoom);
  };

  const getModalSize = (imageSize: Size): Size => {
    let cropAreaHeight = 400;
    let  cropAreaWidth = 600;
    const imageAspect = imageSize.width / imageSize.height;
    if (imageAspect < 0.7) {
      cropAreaHeight = 600;
      cropAreaWidth = Math.round(imageSize.width * (cropAreaHeight - 148) / imageSize.height);
    } else if (imageAspect > 1.3) {
      cropAreaWidth = 700;
      cropAreaHeight = Math.round(imageSize.height * cropAreaWidth / imageSize.width) + 148;
    } else {
      cropAreaWidth = 500;
      cropAreaHeight = Math.round(imageSize.height * cropAreaWidth / imageSize.width) + 148;
    }

    return {
      width : cropAreaWidth,
      height: cropAreaHeight
    };
  };

  const demissionModal = (imageSize: Size) => {
    const modalSize = getModalSize(imageSize);

    return {
      width : `${modalSize.width}px`,
      height: `${modalSize.height}px`
    };
  };

  const demissionAreaSelect = (imageSize: Size) => {
    const cropAreaHeight = 230;
    return {
      width : Math.round(imageSize.width * cropAreaHeight / imageSize.height),
      height: cropAreaHeight
    };
  };

  const customStyleModal = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.92)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      ...demissionModal(imageSize),
      top: '50%',
      left: '50%',
      right: 'auto',
      padding: 0,
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  };

  const cropAreaStyle =  {
    color: 'rgba(0, 0, 0, 0.6)',
    border: 0,
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: CropPoints) => {
    setCropPoint(croppedAreaPixels);
  };

  const handleCrop = () => {
    props.cropImageCallback && props.cropImageCallback(cropPoint);
    closeModal();
  };

  return (
    <>
      <button
        className="crop-photo-button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="translate(-733 -123) translate(143 52) translate(15 15) translate(0 31) translate(559 15) translate(16 10)" /> <path fill="#000" fillRule="nonzero" d="M11.898 16c.465 0 .838-.336.838-.843V12.68h2.412c.508 0 .852-.343.852-.793 0-.465-.344-.8-.852-.8h-2.412V3.979c0-.393-.258-.65-.652-.65H4.925V.843c0-.5-.372-.843-.83-.843-.465 0-.838.343-.838.843v2.486H.845c-.501 0-.845.335-.845.792 0 .458.344.793.845.793h2.412v7.115c0 .385.258.65.652.65h7.159v2.478c0 .507.372.843.83.843zm-.83-4.914H5.126c-.136 0-.2-.057-.2-.193V4.914h5.941c.143 0 .2.057.2.2v5.972z" transform="translate(-733 -123) translate(143 52) translate(15 15) translate(0 31) translate(559 15) translate(16 10)" /> </g> </g> </g> </g> </g> </g> </g> </svg>
      </button>
      <Modal isOpen={modalIsOpen} customStyles={customStyleModal} closeModal={closeModal}>
        <div className="crop-image-modal">
          <div className="modal__heading">
            <h3>Crop ảnh</h3>
            <div className="modal-btn-close" onClick={closeModal}>
              <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
            </div>
          </div>
          <Cropper
            image={props.imgSrc}
            style={{
              containerStyle: {
                top: '63px',
                bottom: '135px',
                left: '15px',
                right: '15px',
              },
              cropAreaStyle: cropAreaStyle,
              mediaStyle: {
                maxHeight: getModalSize(imageSize).height - 240,
              },
            }}
            crop={crop}
            zoom={zoom}
            aspect={props.aspect}
            showGrid={false}
            onCropChange={onCropChange}
            // cropSize={demissionAreaSelect(props.aspect)}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
          <div className="textGuide">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g fill="none" fillRule="evenodd">
                <g fill="#D8D8D8" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path d="M8 15c.18-.002.341-.075.481-.22l1.785-1.92c.136-.148.198-.298.188-.448-.01-.15-.076-.278-.195-.382-.119-.104-.265-.156-.44-.156H8.603V8.6h3.263v1.215c0 .182.052.332.156.448.104.117.232.18.383.191.15.01.3-.052.449-.187l1.924-1.787c.15-.136.223-.294.223-.474s-.074-.338-.223-.474l-1.93-1.787c-.145-.14-.293-.204-.443-.194-.151.01-.279.075-.383.194-.104.119-.156.267-.156.445v1.222H8.602V4.129H9.82c.174 0 .32-.051.44-.153.118-.101.183-.229.194-.381.01-.153-.052-.303-.188-.452L8.475 1.216c-.14-.148-.3-.22-.481-.216-.18.004-.339.08-.475.23L5.73 3.15c-.132.144-.193.292-.182.445.01.152.074.28.191.381.117.102.267.153.45.153h1.216v3.282H4.14V6.189c0-.178-.052-.326-.159-.445-.106-.119-.234-.183-.385-.194-.15-.01-.3.054-.45.194L1.23 7.524c-.15.136-.226.294-.23.474-.004.18.07.339.223.474l1.925 1.794c.148.135.298.198.449.187.15-.01.28-.074.385-.19.107-.117.16-.267.16-.449V8.599h3.262v3.276H6.187c-.182 0-.332.051-.449.155-.117.104-.18.231-.191.382-.01.15.05.298.182.442l1.797 1.933c.135.144.294.215.474.213z" transform="translate(-545 -412) translate(313 80) translate(15 63) translate(217 269)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            </svg>
            Di chuyển ảnh đến vị trí phù hợp</div>
          <div className="sliderZoomWrapper">
            <button onClick={() => {setZoom(zoom - 0.1);}} className="sliderZoomIncrease">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#000"> <g> <g> <g transform="translate(-488 -453) translate(313 80) translate(171 369) translate(4 4)"> <rect width="12" height="1.5" x="2" y="7.25" rx=".75" /> </g> </g> </g> </g> </g> </svg>
            </button>
            <Slider
              value={zoom}
              min={1}
              max={2}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom: number) => {
                setZoom(zoom);
              }}
            />
            <button onClick={() => {setZoom(zoom + 0.1);}} className="sliderZoomDecrease">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <g fill="#000"> <g> <g> <g> <path d="M8 2c.414 0 .75.336.75.75v4.5h4.5c.414 0 .75.336.75.75s-.336.75-.75.75h-4.5v4.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-4.5h-4.5C2.336 8.75 2 8.414 2 8s.336-.75.75-.75h4.5v-4.5c0-.414.336-.75.75-.75z" transform="translate(-775 -453) translate(313 80) translate(171 369) translate(291 4)" /> </g> </g> </g> </g> </g> </svg>
            </button>
          </div>
          <div className="crop-image-modal__bottom">
            <button onClick={handleCrop} className="btn-default">Lưu</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CropImageButton;
