import { isToday, isYesterday, differenceInDays } from 'date-fns';

export const getDateRangeLabel = (startDate: Date, endDate: Date): string => {
  const diffDays = differenceInDays(endDate, startDate) + 1;
  switch (diffDays) {
    case 1: {
      if (isToday(endDate)) return 'Hôm nay';
      if (isYesterday(endDate)) return 'Hôm qua';
      return 'Tùy chỉnh';
    }
    case 7:
      return '7 ngày gần đây';
    case 30:
      return '30 ngày gần đây';
    case 90:
      return '90 ngày gần đây';
    case 180:
      return '180 ngày gần đây';
    default:
      return 'Tùy chỉnh';
  }
};
