import * as React from 'react';
import styled from 'styled-components';
import UploadImageButton from 'buttons/UploadImage';
import CropImageButton from 'buttons/CropImageButton';
import { useState } from 'react';
import { CropPoints } from 'core';
import ImageSourceUrl from '../entities/ImageSourceUrl';
import { Size } from '../entities/Types';

interface ComponentProps {
  imgSrc?: string;
  width: number;
  cropArea?: CropPoints;
  showButtons?: boolean;
  disableCrop: boolean;
  landingPageId: number;
  aspect: number;
  onEditImageCallback?: (src: string, cropArea?: CropPoints) => void;
}

const EditImage: React.FC<ComponentProps> = (props) => {
  const { imgSrc, width, cropArea, showButtons, landingPageId, disableCrop, aspect, onEditImageCallback } = props;
  const [imgSrcState, setImgSrcState] = useState(new ImageSourceUrl(imgSrc).getOriginalUrl());
  const [cropAreaState, setCropAreaState] = useState(cropArea);
  const [isLoading, setIsLoading] = useState(true);
  const [isCalDefaultCropArea, setIsCalDefaultCropArea] = useState(disableCrop ? false : true);
  const height = Math.round(width / aspect);
  const [imageSize, setImageSize] = useState<Size>({width: width, height: height});

  const uploadImageCallback = (imgSrc: string) => {
    setIsLoading(true);
    if (!disableCrop) {
      setIsCalDefaultCropArea(true);
      setCropAreaState(undefined);
    }
    setImgSrcState(imgSrc);
    if (onEditImageCallback) {
      onEditImageCallback(imgSrc);
    }
  };

  const cropImageCallback = (cropArea: CropPoints | undefined) => {
    setIsLoading(true);
    setCropAreaState(cropArea);
    if (onEditImageCallback) {
      onEditImageCallback(imgSrcState, cropArea);
    }
  };

  const getImageSource = (imgSrc: string, width: number, height: number, cropArea?: CropPoints): string => {
    if (!cropArea) {
      return imgSrc;
    }

    const imageSource = new ImageSourceUrl(imgSrc);
    return imageSource.getImageUrl(width, height, cropArea);
  };

  React.useEffect(() => {
    setIsLoading(true);
    setImgSrcState(new ImageSourceUrl(imgSrc).getOriginalUrl());
  }, [imgSrc]);

  React.useEffect(() => {
    setIsLoading(true);
    setCropAreaState(cropArea);
  }, [cropArea]);

  React.useEffect(() => {
    setIsLoading(true);
  }, [aspect]);

  React.useEffect(() => {
    const getDefaultCropArea = (imgObj: HTMLImageElement,  width: number, height: number): CropPoints => {
      if (imgObj.width / imgObj.height > width / height) {
        return {
          x: 0,
          y: 0,
          width: Math.round((width * imgObj.height) / height),
          height: imgObj.height
        };
      }

      return {
        x: 0,
        y: 0,
        width: imgObj.width,
        height: Math.round((height * imgObj.width) / width)
      };
    };
    if (!disableCrop) {
      const imageObj = new Image();     
      imageObj.onload = function () {  
        const defaultCropArea = getDefaultCropArea(imageObj, width, height);
        setCropAreaState(defaultCropArea);
        setIsCalDefaultCropArea(false);
        setImageSize({width: imageObj.width, height: imageObj.height});
      };
      imageObj.src = imgSrcState;
    }
  }, [width, aspect, imgSrcState]);

  React.useEffect(() => {
    if (isCalDefaultCropArea || !isLoading || !imgSrcState || imgSrcState.length === 0) {
      return;
    }
    const imageObj = new Image();     
    imageObj.onload = function () {
      setIsLoading(false);
    };
    imageObj.src = getImageSource(imgSrcState, width, height, cropAreaState);
  }, [isLoading, isCalDefaultCropArea, imgSrcState]);

  const renderMainImage = () => {  
    if (!imgSrcState || imgSrcState.length === 0) {
      return <img src={imgSrcState} width={width} height={height} className="img-fluid" />;
    }
    if(isLoading ) {
      return <div className="placeholder-wrapper" style={{width: width, height: height, animation: 'none'}}>
        <div className="img-placeholder fill-height animate"></div>
      </div>;
    }
    
    return <img src={getImageSource(imgSrcState, width, height, cropAreaState)} width={width} height={height} className="img-fluid" />;
  };

  return (
    <Styles>
      <div className={`media-image-wrapper ${disableCrop ? 'image-logo' : ''}`}>
        {renderMainImage()}
        {showButtons && <div className="media-image-buttons">
          <UploadImageButton
            adsUpload
            uploadImageCallback={uploadImageCallback}
            landingPageId={landingPageId}
          />
          {!disableCrop && <CropImageButton
            imgSrc={imgSrcState}
            imageSize={imageSize}
            cropImageCallback={cropImageCallback}
            aspect={aspect}
          />}
        </div>}
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  padding: 0 0 15px;
`;

export default EditImage;
