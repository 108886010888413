import { useState } from 'react';
import { GoogleLoginResponse } from 'react-google-login';

export interface GoogleLoginResponseWithError extends GoogleLoginResponse {
    error?: string;
    details?: string;
  }

export const useOpenCookieModal = () => {

    const [enableCookie, setEnableCookie] = useState(false);
    const [openCookieModal, setOpenCookieModal] = useState(false);

    const onLoginByGoogleFailure = (response: GoogleLoginResponseWithError) => {
        if (response.error === 'idpiframe_initialization_failed' && response.details === 'Cookies are not enabled in current environment.') {
            setEnableCookie(true);
        }
    };

    const handleGoogleClick = () => {
        if (enableCookie) setOpenCookieModal(true);
    };

    return { openCookieModal, setOpenCookieModal, onLoginByGoogleFailure, handleGoogleClick };
};