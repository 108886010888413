import { useFieldArray, useFormContext } from 'react-hook-form';
import React, { useState, useRef, useEffect } from 'react';
import { AdGroupAdExtends, SPECS, TemplateAdsFormState, AdsChain, CollapseState } from '../forms';
import { NestHeadline, InputLength, NestDescription } from '../fields';
import {
  GoogleAdsEnum,
  ComponentListService,
  Ad
} from 'core';
import { ErrorInput } from '.';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import SubmitButton from 'components/buttons/SubmitButton';
import CancelButton from 'components/buttons/CancelButton';
import { CollapseError, ExpandCollapseIcon } from 'components/icons/SVGIcon';

interface PropsType {
  // onChange: (value: any) => void;
  url: { hostname: string; origin: string };
  defaultValue: AdGroupAdExtends;
  index: number;
  componentListService: ComponentListService[];
  handleRemove: () => void;
  handleChangeType: (value: never, index: number) => void;
  chain: AdsChain | null;
  adGroup: CollapseState;
}

// true => expands, false => collapse


export const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 999
  },
  content: {
    padding: '0px',
    paddingBottom: '20px',
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '477px',
    bottom: 'auto',
    marginRight: '-50%',
    border: '0',
    borderRadius: '12px',
    boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
    transform: 'translate(-50%, -50%)'
  }
};

export const adsType = {
  [GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD]: 'Quảng cáo tìm kiếm thích ứng',
  [GoogleAdsEnum.AdType.EXPANDED_TEXT_AD]: 'Quảng cáo dạng văn bản'
};

export const TextAd: React.FC<PropsType> = ({ index, url, ...props }) => {
  const { register, control, watch, setValue, getValues, trigger, formState: { errors }, clearErrors, reset, ...formMethods } = useFormContext<TemplateAdsFormState>();
  // const [adTemplateOpenState, setAdTemplateOpenState] = useState(false);
  const adGroupOpenState = props.adGroup?.state?.[0] === index;
  const [adsDesc, setAdsDesc] = useState<string[]>([]);
  const [adsPaths, setadsPaths] = useState<string[]>([]);
  const [adsHeadlines, setAdsHeadlines] = useState<string[]>();
  const [openModal, setOpenModal] = useState(false);
  const generateUrl = (paths: string[] | undefined) => {
    return `${url.origin}${paths ? paths.filter(e => e).join('/').replaceAll(' ', '_') : ''}`;
  };

  const ads = props.defaultValue;

  const [displayUrl, setDisplayUrl] = useState(generateUrl(ads.adGroupAd.ad.paths));

  const req = SPECS[GoogleAdsEnum.AdType.EXPANDED_TEXT_AD];


  const watchField = watch('adgroupad');

  const condition = (ref: string) => {
    const criterion = {
      'headlines': 'minHeadline',
      'descriptions': 'minDesc'
    };
    const text = watchField && watchField[index] && watchField[index]?.adGroupAd.ad?.[ref];
    const firstFilled = text ? text.filter((el: string, id: number) => el && id < req[criterion[ref]].value).length : 0;
    const lastFilled = text ? text.filter((el: string, id: number) => el && id >= req[criterion[ref]].value).length : 0;
    const validateQuantity = req[criterion[ref]].value - firstFilled - lastFilled;
    return {
      text,
      firstFilled,
      lastFilled,
      validateQuantity
    };
  };

  useEffect(() => {
    const { text } = condition('headlines');
    if (!text) return;
    const defaultHeadline = props.defaultValue.adGroupAd.ad.headlines || [];
    for (let i = 0; i < text.length; i++) {
      if (i < req.defaultHeadline.value)
        setValue(`adgroupad.${index}.adGroupAd.ad.headlines.${i}` as const, (defaultHeadline[i] ? defaultHeadline[i] : '') as never);
      else
        formMethods.unregister(`adgroupad.${index}.adGroupAd.ad.headlines.${i}` as const);
    }

    const { text: descText } = condition('descriptions');
    if (!descText) return;
    const defaultDesc = props.defaultValue.adGroupAd.ad.descriptions || [];
    for (let i = 0; i < descText.length; i++) {
      if (i < req.defaultDesc.value)
        setValue(`adgroupad.${index}.adGroupAd.ad.descriptions.${i}` as const, (defaultDesc[i] ? defaultDesc[i] : '') as never);
      else
        formMethods.unregister(`adgroupad.${index}.adGroupAd.ad.descriptions.${i}` as const);
    }
  }, [props.defaultValue, props.defaultValue.adGroupAd?.ad?.type]);


  useEffect(() => {
    const setDefaultHeadLines = () => {
      const numHeadlines = props.defaultValue.adGroupAd.ad.headlines?.length || 0;
      const defaultHeadline = props.defaultValue.adGroupAd.ad.headlines || [];

      if (numHeadlines >= req.defaultHeadline.value) {
        return defaultHeadline;
      }

      return [...defaultHeadline, ...new Array(req.defaultHeadline.value - numHeadlines).fill('')];
    };

    const setDefaultDescription = () => {
      const numDesc = props.defaultValue.adGroupAd.ad.descriptions?.length || 0;
      const defaultDesc = props.defaultValue.adGroupAd.ad.descriptions || [];
      if (numDesc >= req.defaultDesc.value) {
        return defaultDesc;
      }
      return [...defaultDesc, ...new Array(req.defaultDesc.value - numDesc).fill('')];
    };

    const setDefaultPaths = () => {
      const paths = props.defaultValue.adGroupAd.ad.paths;
      const data = paths.length ? paths : ['', ''];
      return data;
    };

    setAdsHeadlines(setDefaultHeadLines());

    setAdsDesc(setDefaultDescription());

    setadsPaths(setDefaultPaths());
    setValue(`adgroupad.${index}.adGroupAd.ad.final_url` as const, (props.defaultValue.adGroupAd.ad.final_url || url.origin) as never);

  }, [props.defaultValue, props.defaultValue.adGroupAd?.ad?.type]);

  const onToggleAd = () => {
    props.adGroup.setExpandingAd();
  };

  //the length of normally ads is about 75 characters long
  const renderCapitalize = (descriptions: string[], operator = '. ', limit = 75) => {
    if (!descriptions) return;
    const allchar = descriptions.filter(el => el).map(el => el.charAt(0).toUpperCase() + el.substring(1)).join(operator);
    return allchar.length > limit + 1 ? allchar.substring(0, limit) + '...' : allchar;
  };

  const handleChangeAdType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const allAdsInList = props.chain?.list?.[index] ? props.chain.list[index] : [index];

    const value = allAdsInList.map(i => watch(`adgroupad.${i}.adGroupAd.ad` as never)) as unknown as Ad[];
    // If typed descriptions or headline in some ads or have more than 3 ads then show modal
    if (value.some(e => !!e.descriptions.some(el => !!el)) || value.some(e => !!e.headlines.some(el => !!el)) || groupShouldShow.limitAdsNum) {
      setOpenModal(true);
      setValue(`adgroupad.${index}.adGroupAd.ad.type` as const, GoogleAdsEnum.AdType.EXPANDED_TEXT_AD as never);
      return;
    }
    setValue(`adgroupad.${index}.adGroupAd.ad.type` as const, GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD as never);
    props.handleChangeType(e.target.value as never, index);
  };

  const switchAdType = () => {
    props.handleChangeType(GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD as never, index);
  };

  const handleChangeComponent = (e: any) => {
    if (!e.target.value) return;
    const value = props.componentListService?.find(el => el.id == e.target.value || el.label.toLowerCase() == e.target.value.toLowerCase());
    const componentPath = generateComponentUrl(value?.href || '');
    setValue(`adgroupad.${index}.adGroupAd.ad.final_url` as const, componentPath as never);
    // Change every ads in the group as well
    const list = props.chain?.list;
    list?.[index].map((id: number) => {
      setValue(`adgroupad.${id}.adGroupAd.ad.final_url` as const, componentPath as never);
    });
    const text = watchField && watchField[index] && watchField[index]?.adGroupAd.ad?.paths || [];
    if (!text.length) setDisplayUrl(generateUrl([componentPath]));
  };

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setValue(`adgroupad.${index}.adGroupAd.ad.paths.${id}` as const, e.target.value as never);
    const list = props.chain?.list;
    list?.[index].map((i: number) => {
      setValue(`adgroupad.${i}.adGroupAd.ad.paths.${id}` as const, e.target.value as never);
    });
  };

  const watchPaths = watch(`adgroupad.${index}.adGroupAd.ad.paths` as const);
  const watchFinalUrl = watch(`adgroupad.${index}.adGroupAd.ad.final_url` as const);

  useEffect(() => {
    if (watchPaths) {
      setDisplayUrl(generateUrl(watchPaths));
    } else {
      setValue(`adgroupad.${index}.adGroupAd.ad.paths` as const, ['', ''] as never);
      setDisplayUrl(generateUrl(props.defaultValue.adGroupAd?.ad?.paths || []));
    }
  }, [watchPaths, JSON.stringify(adsPaths), props.componentListService, ads.keywordGroup?.name, props.defaultValue.adGroupAd?.ad?.type]);

  useEffect(() => {
    if (props.componentListService) {
      if (!watchFinalUrl && ads.keywordGroup?.name) {
        handleChangeComponent({ target: { value: ads.keywordGroup.name } });
      }
    }
  }, [props.componentListService, ads.keywordGroup?.name]);

  const generateComponentUrl = (componentPath: string): string => {
    return `${url.origin}${componentPath}`;
  };

  if (!watchField[index]) return <></>;

  const renderHeadlines = () => {
    return <>{adsHeadlines?.map((headline: any, id: number) => {
      const { text: watchHeadline, firstFilled, lastFilled, validateQuantity } = condition('headlines');
      const inputText = watchHeadline ? watchHeadline[id] : headline;
      return <React.Fragment key={'headline' + index + id}><div className="position-relative w-100">
        <NestHeadline
          defaultValue={headline}
          nestIndex={id}
          index={index}
          maxLength={req.maxLengthHeadline.value}
        />
        <InputLength text={inputText} max={req.maxLengthHeadline.value + 1} />
      </div>
        {!inputText && validateQuantity > 0 && id + firstFilled < req.minHeadline.value + firstFilled - lastFilled && <ErrorMessage
          errors={errors}
          name={`adgroupad.${index}.Headline` as never}
          render={({ message }) => <ErrorInput text={message} />} />}

        <ErrorMessage
          errors={errors}
          name={`adgroupad.${index}.CommonHeadline.${id}` as never}
          render={({ message }) => <ErrorInput text={message} />} />

      </React.Fragment>;
    })}</>;
  };


  const renderDisplayUrl = () => {
    const text = watchField && watchField[index] && watchField[index]?.adGroupAd.ad?.paths;
    if (typeof text == 'string') return null;
    const firstPath = watch(`adgroupad.${index}.adGroupAd.ad.paths.0` as never);
    return <>{adsPaths.filter(el => el || el === '').map((path: any, id: number) => {
      const inputText = text ? text[id] : path;
      return <React.Fragment key={'paths' + index + id}><div className="position-relative">
        {props.chain?.prev ? inputText
          : <><input
            type="text"
            className="form-control pr-2"
            placeholder={`Nhập đường dẫn ${id + 1}`}
            defaultValue={path}
            {...register(`adgroupad.${index}.adGroupAd.ad.paths.${id}` as const)}
            onChange={(e) => handlePathChange(e, id)}
          />
          </>}
      </div>
        {id == 0 && (!props.chain?.prev || !!firstPath) && '/'}
      </React.Fragment>;
    })}</>;
  };

  const renderDesc = () => {


    return <>{adsDesc && adsDesc.length > 0 && adsDesc?.map((descriptions, id) => {
      const { text: watchDesc, firstFilled, lastFilled, validateQuantity } = condition('descriptions');
      const inputText = watchDesc ? watchDesc[id] : descriptions;

      return <React.Fragment key={'desc' + index + id}>
        <div className="position-relative w-100">
          < NestDescription
            nestIndex={id}
            index={index}
            defaultValue={descriptions}
            maxLength={req.maxLengthDesc.value}
          />
          <InputLength text={inputText} max={req.maxLengthDesc.value + 1} /></div>
        {!inputText && validateQuantity > 0 && id + firstFilled < req.minDesc.value + firstFilled - lastFilled && <ErrorMessage
          errors={errors}
          name={`adgroupad.${index}.Desc` as never}
          render={({ message }) => <ErrorInput text={message} />} />}

        <ErrorMessage
          errors={errors}
          name={`adgroupad.${index}.CommonDesc.${id}` as never}
          render={({ message }) => <ErrorInput text={message} />}
        />

      </React.Fragment>;
    })}</>;
  };

  const groupShouldShow = {
    isExpanding: adGroupOpenState,
    isExpandingGroup: props.adGroup.isExpandingGroup,
    title: !props.chain?.prev,
    content: adGroupOpenState,
    borderTop: props.chain?.hasChain && props.chain?.prev, //TODO: check if ad type has changed,
    removeAds: props.chain?.prev,
    isSingleAd: !props.chain?.hasChain,
    limitAdsNum: props.chain?.list && props.chain?.list?.[index]?.length > 3
  };

  const isFirstBlock = props.chain?.list[index].indexOf(index) == 0 || undefined;
  const renderFullContentUI = () => (
    <div className={classNames({ 'hidden': !groupShouldShow.isExpanding })}>
      <div className="col-lg-12" style={{ borderTop: groupShouldShow.borderTop ? 'solid 1px #d8d8d8' : 'none', paddingTop: groupShouldShow.borderTop ? '20px' : 'initial', paddingBottom: '10px' }}>
        <div className="ad-template__title w-100 cursor-pointer" onClick={onToggleAd}>
          Mẫu quảng cáo {(props.chain?.list[index].indexOf(index) || 0) + 1}
          <div className="section-toogle">
            <ExpandCollapseIcon state={true} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="input-group">
              <div className="d-flex w-100 justify-content-between">
                <h4>URL đích</h4>
                <a className="mb-2 btn-link" href={watchFinalUrl} target="_blank" rel="noreferrer">Kiểm tra URL</a>
              </div>
              <div className={`${props.chain?.prev ? 'url-overfollow' : ''} form-inline w-100`}>
                {isFirstBlock && url.origin}
                {props.componentListService && !props.chain?.prev ? <select
                  className="auto-width-select"
                  onChange={handleChangeComponent}>
                  <option>Chọn đường dẫn</option>
                  {props.componentListService.map((el: ComponentListService) => {
                    const selected = watchFinalUrl === generateComponentUrl(el.href);
                    if (selected) {
                      return <option key={'component' + el.id} value={el.id} selected>{el.href}</option>;
                    }
                    return <option key={'component' + el.id} value={el.id}>{el.href}</option>;
                  }
                  )}
                </select> : watchFinalUrl}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h4>Loại quảng cáo</h4>
            {props.chain?.prev ? <span className="mt-2">Quảng cáo dạng văn bản</span>
              :
              <select {...register(`adgroupad.${index}.adGroupAd.ad.type` as const)} onChange={handleChangeAdType} defaultValue={GoogleAdsEnum.AdType.EXPANDED_TEXT_AD}>
                {Object.keys(adsType).map(el => <option key={el} value={parseInt(el)}>{adsType[el]}</option>)}
              </select>
            }
          </div>
          <ReactModal isOpen={openModal} onRequestClose={() => setOpenModal(false)} style={customModalStyles}>
            <div className="modal-btn-close" onClick={() => setOpenModal(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" /> <path fill="#000" fillRule="nonzero" d="M9.697 8l4.55 4.55c.186.185.294.362.35.546.056.184.056.372 0 .556-.056.184-.164.36-.35.546l-.049.048c-.185.187-.362.295-.546.35-.184.057-.372.057-.556 0-.184-.055-.36-.163-.546-.349L8 9.696l-4.55 4.552c-.185.186-.362.294-.546.35-.184.056-.372.056-.556 0-.184-.056-.36-.164-.546-.35l-.048-.049c-.187-.185-.295-.362-.35-.546-.057-.184-.057-.372 0-.556.055-.184.163-.36.349-.547L6.304 8 1.752 3.45c-.186-.185-.294-.362-.35-.546-.056-.184-.056-.372 0-.556.056-.184.164-.36.35-.546l.049-.048c.185-.187.362-.295.546-.35.184-.057.372-.057.556 0 .184.055.36.163.547.349L8 6.304l4.55-4.55c.185-.186.362-.294.546-.35.184-.056.372-.056.556 0 .184.056.36.164.546.35l.048.048c.187.186.295.363.35.547.057.184.057.372 0 .556-.055.184-.163.36-.349.546L9.697 8z" /> </g> </svg>
            </div>
            <div className="transfer-modal__container">
              <h3 className="text-center">Xác nhận</h3>
              <div className="transfer-modal__content">
                {groupShouldShow.limitAdsNum
                  ? 'Chỉ có thể có tối đa 3 mẫu quảng cáo tìm kiếm thích ứng trong nhóm quảng cáo. Vui lòng xóa các mẫu quảng cáo thừa trước khi chuyển sang loại quảng cáo tìm kiếm thích ứng.'
                  : 'Khi thay đổi loại quảng cáo, các thông tin bạn đã nhập vào mẫu quảng cáo hiện tại sẽ bị xoá.'}
              </div>
            </div>
            <div className="d-flex justify-content-end mr-3">
              {groupShouldShow.limitAdsNum ?
                <SubmitButton onClick={() => setOpenModal(false)}>Đã hiểu</SubmitButton>
                :
                <>
                  <CancelButton onClick={switchAdType}>Đồng ý</CancelButton>
                  <div style={{ width: 10 }}></div>
                  <SubmitButton onClick={() => setOpenModal(false)}>Huỷ</SubmitButton>
                </>
              }
            </div>
          </ReactModal>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="input-group">
              <h4>Hiển thị đường dẫn</h4>
              <div className="form-inline w-100">
                {url.origin}
                {renderDisplayUrl()}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="input-group">
              <h4>Tiêu đề</h4>
              {renderHeadlines()}
            </div>
            <div className="link-add-another cursor-pointer hidden">
              Thêm tiêu đề mới
            </div>
            <div className="input-group">
              <h4>Mô tả</h4>
              {renderDesc()}
            </div>
            <div className="link-add-another cursor-pointer hidden">
              Thêm mô tả mới
            </div>
          </div>
          <div className="col-lg-6">
            <div className="previewBox">
              <span className="previewBox-title">Xem trước</span> • Ví dụ minh hoạ
              <h4 className="mt-3">Điện thoại</h4>
              <div className="mobile-preview">
                <div className="project-name"><strong>Quảng cáo</strong>   •   {displayUrl}</div>
                <div className="ad-title">{renderCapitalize(watchField[index].adGroupAd.ad.headlines, ' | ')}</div>
                <div className="ad-description">{renderCapitalize(watchField[index].adGroupAd.ad.descriptions, '. ', 122)}</div>
              </div>
              <h4>Máy tính</h4>
              <div className="desktop-preview">
                <div className="project-name"><strong>Quảng cáo</strong>   •   {displayUrl}</div>
                <div className="ad-title">{renderCapitalize(watchField[index].adGroupAd.ad.headlines, ' | ')}</div>
                <div className="ad-description">{renderCapitalize(watchField[index].adGroupAd.ad.descriptions, '. ', 122)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {groupShouldShow.removeAds && <div className="add-extend-link" style={{ borderTop: 'none', color: '#ff3b30', paddingBottom: 10 }} onClick={props.handleRemove}>
        Xóa mẫu quảng cáo
      </div>}
    </div>
  );

  const renderCollapseContentUI = () => {
    if (groupShouldShow.isExpanding) return;
    return (<>
      <div className="col-lg-12" style={{ borderTop: props.chain?.prev ? 'solid 1px #d8d8d8' : 'none', padding: '20px', paddingTop: props.chain?.prev ? '20px' : 'initial' }}>
          <div className="ad-template__title w-100 cursor-pointer" onClick={onToggleAd}>
          Mẫu quảng cáo {(props.chain?.list[index].indexOf(index) || 0) + 1}
          <div className="section-toogle">
            <ExpandCollapseIcon state={false} />
          </div>
        </div>
        {(errors.adgroupad && errors.adgroupad[index]) ?
          <div className="input-group">
            <span><CollapseError /></span><ErrorInput style={{ marginBottom: 0 }} text="Cần khắc phục vấn đề thiết lập quảng cáo" />
          </div>
          : null}
      </div>
    </>
    );
  };


  if (!groupShouldShow.isExpandingGroup && !groupShouldShow.title) return <></>;
  return (<>
    <div className={classNames('row')} >
      {renderCollapseContentUI()}
      {renderFullContentUI()}
    </div>
  </>
  );
};
