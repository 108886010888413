import * as React from 'react';
import { useRef, useEffect } from 'react';
import * as ReactModal from 'react-modal';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  userEmail: string;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

const VerifyEmailSuccessModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="emai-verified-modal">
        <div className="modal__heading">
          <h3>Gửi lại Email thành công</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          Email kích hoạt tài khoản đã được gửi lại tới địa chỉ {props.userEmail ? props.userEmail : '' }
        </div>
        <div className="modal__bottom">
          <a target="_blank" className="btn-default align-items-center ml-3" href="https://mail.google.com/mail/#search/from%3A%40+cokhach.vn+in%3Aanywhere" rel="noreferrer">
            Tới hòm thư
          </a>
        </div>
      </div>
    </ReactModal>
  );
};

VerifyEmailSuccessModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      minHeight: '180px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default VerifyEmailSuccessModal;
