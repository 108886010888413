import * as React from 'react';
import { ButtonProps } from 'presenter';
import Button from './Button';

type PropsType = ButtonProps & {
  activeClassName?: string;
  disabledClassName?: string;
}

const CancelButton: React.FC<PropsType> = (props) => {
  const activeClassName = props.activeClassName || 'btn-subsidiary';
  const disabledClassName = props.disabledClassName || 'btn-disabled';
  let buttonClasses = '';
  if (props.isDisabled) {
    buttonClasses += ` ${disabledClassName}`;
  } else {
    buttonClasses += ` ${activeClassName}`;
  }

  return (
    <Button
      className={buttonClasses}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default CancelButton;

