import { SideMenu, TopMenu } from 'pages/menu';
import React, { useState, useEffect } from 'react';
import { SettingStyle } from '../CreateGoogleCampaign/style';
import Iframe from 'react-iframe';
import { GoogleService, GoogleAdsEnum, AppState, TeamPermissionService, AdsAccount } from 'core';
import { useSelector } from 'react-redux';
import { useRouter } from 'utils/hooks';
import WarningLimitedFeatureModal from 'pages/menu/modals/WarningLimitedFeatureModal';
import { useLocation } from 'react-router-dom';

const ConnectBoards: React.FC<{}> = () => {
  const history = (globalThis as any).useHistory;
  history.hook();
  const route = useRouter();
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);
  const userToken = useSelector<any, string | null>((state: AppState) => state.auth.userToken);
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const maxAccountConnectable = teamPermissionService.MaxAdwordAccount.toString();
  const maxAllowAccounts = teamPermissionService.MaxAdwordAccountCanConnect.toString();
  const connectedAccountGroup = useSelector<any, AdsAccount | null>((state: AppState) => state.adsCampaign.connectedAccountGroup);
  const service = new GoogleService();
  
  useEffect(() => {
    if (connectedAccountGroup) {
      return;
    }
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {
          // if (teamPermissionService.CanUseAdCreation && !teamPermissionService.CanConnectMoreAccount) {
          //   history.push('/create-google-campaign');
          // }
        },
        errorCallback: (err: any) => {
          console.log('onErrGG', err);
        }
      }
    };
    service.fetchConnectedAccount(params);
  }, []);

  useEffect(() => {
    const isForceAddingMode = !!route.query['force_adding'];
    if(teamPermissionService.CanUseAdCreation === false && connectedAccountGroup?.google && connectedAccountGroup.google.length >= teamPermissionService.MaxAdwordAccount) {
      setIsOpenNeedToUpgradeModalState(true);
    }

    if (teamPermissionService.CanUseAdCreation && connectedAccountGroup?.google && connectedAccountGroup.google.length > 0 && !isForceAddingMode) {
      history.push('/create-google-campaign');
    }
  }, [connectedAccountGroup]);
 
  return <SettingStyle>
    <TopMenu />
    <SideMenu />
    <WarningLimitedFeatureModal 
      isOpen={isOpenNeedToUpgradeModalState} 
      closeModal={() => {setIsOpenNeedToUpgradeModalState(false);}} 
    />
    <Iframe url={`${route.origin({origin: true})}/dashboard/connect-ads/${userToken}/${maxAccountConnectable}/${maxAllowAccounts}/${encodeURIComponent(window.location.origin)}`}
      width="1300px"
      height="1000px"
      id="myId"
      className="connectAdsIframe"
      position="relative"/>
  </SettingStyle>;
};

export default ConnectBoards;
