import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as ReactModal from 'react-modal';
import { ButtonProps } from 'presenter';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectService, ValidateDeleteProjectParams } from 'core';
import { cpuUsage } from 'process';
import Button from './Button';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import DeleteButton from './DeleteButton';
import { History } from 'src/route/Route';

const ErrorInvalidDeleteProject = 100;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');
interface ConfirmButtonProps extends ButtonProps {
  comfirmMessage: React.ReactNode;
  isDeleteStyle?: boolean;
  confirmStyle?: string;
  confirmTitle?: string;
  cancelStyle?: string;
  cancelTitle?: string;
  customButtonStyle?: string;
  customStyles?: object;
  isDeleteProject?: boolean;
  shouldNotShowDialog?: boolean;
  isInformationModal?: boolean;
  detailTitle?: string;
  cancelButtonClassName?: string;
  rowId?: number;
  rowName?: string;
  onConfirmSuccess?: () => void;
}
const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
  const dispatch = useDispatch();
  const history = new History();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [comfirmMessage, setConfirmMessage] = useState(props.comfirmMessage);
  const [validDeleteProject, setValidDeleteProject] = useState(true);
  const mounted = useRef(false);
  const service = new ProjectService();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setConfirmMessage(props.comfirmMessage);
  }, [props.comfirmMessage]);

  const openModal = async () => {
    if (props.isDeleteProject) {
      await validateDeleteProjectFunc();
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    if (!mounted.current) return;

    setIsOpen(false);
    setValidDeleteProject(true);
    setConfirmMessage(props.comfirmMessage);
    props.onConfirmSuccess && props.onConfirmSuccess();
  };

  const confirm = async () => {
    await props.onClick();
    closeModal();
  };

  const redirectToLPManagement = () => {
    history.push('/landing-management');
    closeModal();
  };

  const validateDeleteProjectFunc = async () => {
    const params: ValidateDeleteProjectParams = {
      inputParams: {
        projectId: props.rowId
      },
      callBackParams: {
        errorCallback: (error: any) => {
          if(error.code === ErrorInvalidDeleteProject){
            const msg = (
              <div>
                <h3 className="text-center">Thông báo</h3>
                <p className="pt-4 pb-3">
                  Vui lòng kiểm tra và ngừng xuất bản các landing page gắn với dự án {props.rowName} trước khi thao tác Xoá dự án.
                </p>
              </div>
            );
            setConfirmMessage(msg);
            setValidDeleteProject(false);
          }
        }
      }
    };

    service.validateDeleteProject(params);
  };
  
  if(props.shouldNotShowDialog) return <>{props.children}</>;
  if(props.isInformationModal) return (//show only message and cancel button 
    <React.Fragment>
      <Button className={props.customButtonStyle} onClick={openModal}>
        {props.children}
      </Button>
      <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} style={props.customStyles}>
        <div className="modal-btn-close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01"/> <path fill="#000" fillRule="nonzero" d="M9.697 8l4.55 4.55c.186.185.294.362.35.546.056.184.056.372 0 .556-.056.184-.164.36-.35.546l-.049.048c-.185.187-.362.295-.546.35-.184.057-.372.057-.556 0-.184-.055-.36-.163-.546-.349L8 9.696l-4.55 4.552c-.185.186-.362.294-.546.35-.184.056-.372.056-.556 0-.184-.056-.36-.164-.546-.35l-.048-.049c-.187-.185-.295-.362-.35-.546-.057-.184-.057-.372 0-.556.055-.184.163-.36.349-.547L6.304 8 1.752 3.45c-.186-.185-.294-.362-.35-.546-.056-.184-.056-.372 0-.556.056-.184.164-.36.35-.546l.049-.048c.185-.187.362-.295.546-.35.184-.057.372-.057.556 0 .184.055.36.163.547.349L8 6.304l4.55-4.55c.185-.186.362-.294.546-.35.184-.056.372-.056.556 0 .184.056.36.164.546.35l.048.048c.187.186.295.363.35.547.057.184.057.372 0 .556-.055.184-.163.36-.349.546L9.697 8z"/> </g> </svg>
        </div>
        {comfirmMessage}
        <div className="d-flex justify-content-end mr-3">
          <SubmitButton onClick={closeModal}>{props.cancelTitle}</SubmitButton>
        </div>
      </ReactModal>
    </React.Fragment>
  );
    
  return (
    <React.Fragment>
      <Button className={props.customButtonStyle} onClick={openModal}>
        {props.children}
      </Button>
      <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} style={props.customStyles}>
        <div className="modal-btn-close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01"/> <path fill="#000" fillRule="nonzero" d="M9.697 8l4.55 4.55c.186.185.294.362.35.546.056.184.056.372 0 .556-.056.184-.164.36-.35.546l-.049.048c-.185.187-.362.295-.546.35-.184.057-.372.057-.556 0-.184-.055-.36-.163-.546-.349L8 9.696l-4.55 4.552c-.185.186-.362.294-.546.35-.184.056-.372.056-.556 0-.184-.056-.36-.164-.546-.35l-.048-.049c-.187-.185-.295-.362-.35-.546-.057-.184-.057-.372 0-.556.055-.184.163-.36.349-.547L6.304 8 1.752 3.45c-.186-.185-.294-.362-.35-.546-.056-.184-.056-.372 0-.556.056-.184.164-.36.35-.546l.049-.048c.185-.187.362-.295.546-.35.184-.057.372-.057.556 0 .184.055.36.163.547.349L8 6.304l4.55-4.55c.185-.186.362-.294.546-.35.184-.056.372-.056.556 0 .184.056.36.164.546.35l.048.048c.187.186.295.363.35.547.057.184.057.372 0 .556-.055.184-.163.36-.349.546L9.697 8z"/> </g> </svg>
        </div>
        {comfirmMessage}
        <div className="d-flex justify-content-end mr-3">
          <CancelButton activeClassName={props.cancelButtonClassName} onClick={closeModal}>{props.cancelTitle}</CancelButton>
          { !validDeleteProject ? (
            <SubmitButton onClick={redirectToLPManagement} alignRight>{props.detailTitle}</SubmitButton>
          ) : props.isDeleteStyle ? (
            <DeleteButton onClick={confirm}>{props.confirmTitle}</DeleteButton>
          ) : (
            <SubmitButton onClick={confirm} alignRight>{props.confirmTitle}</SubmitButton>
          )}
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

ConfirmButton.defaultProps = {
  isDeleteStyle: false,
  customButtonStyle: 'button-link',
  confirmStyle: 'btn-red ml-3',
  cancelStyle: 'btn-submit',
  confirmTitle: 'OK',
  cancelTitle: 'Cancel',
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 999
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  },
  isDeleteProject: false,
  detailTitle: 'Xem chi tiết',
};

export default ConfirmButton;
