import { SideMenu, TopMenu } from 'pages/menu';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import React, { useState, useEffect } from 'react';
import { GoogleService } from 'core';

const ListCampaignNeedOptimazation: React.FC<{}> = () => {

  const [createCampaignState, setCreateCampaignState] = useState(false);
  
  const service = new GoogleService();

  const handleConnectToFb = (res: ReactFacebookLoginInfo) => {
    const params = {
      inputParams: {
        access_token: res.accessToken,
        fb_user_id: res.id,
      }
    };
    // service.getFbAdsId(params);
  };

  return  <>
    <TopMenu />
    <SideMenu />
    <div className={'main-content campaign-optimazation-page fix-height'}>
      <div className="page-heading">
        <div className="d-flex justify-content-start align-items-start">
          <div>
            <h1 className="page-title">Gợi ý tối ưu quảng cáo</h1>
            <p>Cung cấp các gợi ý tối ưu tốt nhất cho quảng cáo bất động sản của bạn trên Google Ads.</p>
          </div>
        </div>
      </div>
      <div className="filter-container">
        <div className="row">
          <div className="col-lg-12">
            <h2>Campaign</h2>
            <div className="campagin-list d-flex">
              <div className="campagin-name active cursor-pointer" onClick={() => {}}>
                VHSC Test QC (5)
              </div>
              <div className="campagin-name active cursor-pointer" onClick={() => {}}>
                Geleximco 897 (4)
              </div>
              <div className="campagin-name active cursor-pointer" onClick={() => {}}>
                Geleximco - CPA (2)
              </div>
            </div>
            <div className="recommend-optimization-list row">
              <div className="col-lg-4">
                <div className="recommend-optimization-card">
                  <div className="d-flex">
                    <div className="recommend-optimization-card__name pink">
                      Keyword
                    </div>
                    <div className="recommend-optimization-card__priority">
                      Cao
                    </div>
                  </div>
                  <div className="recommend-optimization-card__title">
                    Keyword
                  </div>
                  <div className="recommend-optimization-card__content">
                    Keyword
                  </div>
                  <div className="recommend-optimization-card__button">
                    Fix it
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};
export default ListCampaignNeedOptimazation;