"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPricingInfo = exports.FetchPaymentHistory = exports.GetBankTransferInfo = exports.CreateShoppingCart = exports.FetchMethodsList = exports.FetchPackagesList = exports.PurchasesStatus = exports.MethodInfo = exports.PackageName = exports.PaymentType = exports.MethodId = exports.PackagePermission = exports.PackageCode = void 0;
var PackageCode;
(function (PackageCode) {
    PackageCode["FREE"] = "FREE";
    PackageCode["STARTER"] = "STARTER";
    PackageCode["PRO"] = "PRO";
})(PackageCode = exports.PackageCode || (exports.PackageCode = {}));
class PackagePermission {
    constructor() {
        this.key = '';
        this.value = 0;
    }
}
exports.PackagePermission = PackagePermission;
var MethodId;
(function (MethodId) {
    MethodId[MethodId["Bank_Transfer"] = 1] = "Bank_Transfer";
})(MethodId = exports.MethodId || (exports.MethodId = {}));
var PaymentType;
(function (PaymentType) {
    PaymentType[PaymentType["Create_New"] = 1] = "Create_New";
    PaymentType[PaymentType["Upgrade"] = 2] = "Upgrade";
    PaymentType[PaymentType["Downgrade"] = 3] = "Downgrade";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
var PackageName;
(function (PackageName) {
    PackageName["FREE"] = "Mi\u1EC5n ph\u00ED";
    PackageName["STARTER"] = "C\u01A1 b\u1EA3n";
    PackageName["PRO"] = "Chuy\u00EAn nghi\u1EC7p";
})(PackageName = exports.PackageName || (exports.PackageName = {}));
exports.MethodInfo = {
    [MethodId.Bank_Transfer]: {
        icon: 'https://cdn-static.ymeet.me/general/MobileApp/Payment/Bank.png',
        title: 'Chuyển khoản qua ngân hàng',
        description: 'Chuyển tiền mặt tại quầy giao dịch hoặc chuyển khoản qua Internet Banking',
    },
};
var PurchasesStatus;
(function (PurchasesStatus) {
    PurchasesStatus[PurchasesStatus["Active"] = 1] = "Active";
    PurchasesStatus[PurchasesStatus["Expired"] = 2] = "Expired";
    PurchasesStatus[PurchasesStatus["Upcoming"] = 3] = "Upcoming";
})(PurchasesStatus = exports.PurchasesStatus || (exports.PurchasesStatus = {}));
exports.FetchPackagesList = {
    type: 'FetchPackagesList',
    success: 'FetchPackagesList_SUCCESS',
    error: 'FetchPackagesList_ERROR',
};
exports.FetchMethodsList = {
    type: 'FetchMethodsList',
    success: 'FetchMethodsList_SUCCESS',
    error: 'FetchMethodsList_ERROR',
};
exports.CreateShoppingCart = {
    type: 'CreateShoppingCart',
    success: 'CreateShoppingCart_SUCCESS',
    error: 'CreateShoppingCart_ERROR',
};
exports.GetBankTransferInfo = {
    type: 'GetBankTransferInfo',
    success: 'GetBankTransferInfo_SUCCESS',
    error: 'GetBankTransferInfo_ERROR',
};
exports.FetchPaymentHistory = {
    type: 'FetchPaymentHistory',
    success: 'FetchPaymentHistory_SUCCESS',
    error: 'FetchPaymentHistory_ERROR',
};
exports.GetPricingInfo = {
    type: 'GetPricingInfo',
    success: 'GetPricingInfo_SUCCESS',
    error: 'GetPricingInfo_ERROR',
};
