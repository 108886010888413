"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessageService = void 0;
const ConfigInfoService_1 = require("./ConfigInfoService");
class ErrorMessageService {
    static getErrorMessageByCode(errorCode, defaultMessage = undefined) {
        switch (errorCode) {
            case 500:
                return `Số lượng thành viên tham gia nhóm đã lớn hơn số lượng thành viên tối đa được phép. Bạn hãy liên hệ với quản trị nhóm để được hỗ trợ!`;
            case 501:
                return 'Bạn đang có nhiều hơn 1 gói nâng cấp tài khoản. Bạn chưa thể thực hiện việc nâng cấp thêm một gói nâng cấp mới.';
            default:
                return defaultMessage ? defaultMessage : `Xin lỗi bạn, hệ thống đang gặp lỗi. Xin vui lòng liên hệ số điện thoại ${ConfigInfoService_1.ConfigInfoService.HotLine()} để được hỗ trợ!`;
        }
    }
}
exports.ErrorMessageService = ErrorMessageService;
