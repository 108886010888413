"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAdsEnum = void 0;
var GoogleAdsEnum;
(function (GoogleAdsEnum) {
    /**
     * @name DayOfWeekEnum.DayOfWeek
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/DayOfWeekEnum.DayOfWeek
     */
    let DayOfWeek;
    (function (DayOfWeek) {
        DayOfWeek[DayOfWeek["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        DayOfWeek[DayOfWeek["UNKNOWN"] = 1] = "UNKNOWN";
        DayOfWeek[DayOfWeek["MONDAY"] = 2] = "MONDAY";
        DayOfWeek[DayOfWeek["TUESDAY"] = 3] = "TUESDAY";
        DayOfWeek[DayOfWeek["WEDNESDAY"] = 4] = "WEDNESDAY";
        DayOfWeek[DayOfWeek["THURSDAY"] = 5] = "THURSDAY";
        DayOfWeek[DayOfWeek["FRIDAY"] = 6] = "FRIDAY";
        DayOfWeek[DayOfWeek["SATURDAY"] = 7] = "SATURDAY";
        DayOfWeek[DayOfWeek["SUNDAY"] = 8] = "SUNDAY";
        DayOfWeek[DayOfWeek["WEEKDAYS"] = 9] = "WEEKDAYS";
        DayOfWeek[DayOfWeek["WEEKENDS"] = 10] = "WEEKENDS";
        DayOfWeek[DayOfWeek["EVERYDAY"] = 11] = "EVERYDAY";
    })(DayOfWeek = GoogleAdsEnum.DayOfWeek || (GoogleAdsEnum.DayOfWeek = {}));
    /**
     * @name MinuteOfHourEnum.MinuteOfHour
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/MinuteOfHourEnum.MinuteOfHour
     */
    let MinuteOfHour;
    (function (MinuteOfHour) {
        MinuteOfHour[MinuteOfHour["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        MinuteOfHour[MinuteOfHour["UNKNOWN"] = 1] = "UNKNOWN";
        MinuteOfHour[MinuteOfHour["ZERO"] = 2] = "ZERO";
        MinuteOfHour[MinuteOfHour["FIFTEEN"] = 3] = "FIFTEEN";
        MinuteOfHour[MinuteOfHour["THIRTY"] = 4] = "THIRTY";
        MinuteOfHour[MinuteOfHour["FORTY_FIVE"] = 5] = "FORTY_FIVE";
    })(MinuteOfHour = GoogleAdsEnum.MinuteOfHour || (GoogleAdsEnum.MinuteOfHour = {}));
    /**
     * @name CampaignCriterionStatusEnum.CampaignCriterionStatus
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/CampaignCriterionStatusEnum.CampaignCriterionStatus
     */
    let CampaignCriterionStatus;
    (function (CampaignCriterionStatus) {
        CampaignCriterionStatus[CampaignCriterionStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        CampaignCriterionStatus[CampaignCriterionStatus["UNKNOWN"] = 1] = "UNKNOWN";
        CampaignCriterionStatus[CampaignCriterionStatus["ENABLED"] = 2] = "ENABLED";
        CampaignCriterionStatus[CampaignCriterionStatus["PAUSED"] = 3] = "PAUSED";
        CampaignCriterionStatus[CampaignCriterionStatus["REMOVED"] = 4] = "REMOVED";
    })(CampaignCriterionStatus = GoogleAdsEnum.CampaignCriterionStatus || (GoogleAdsEnum.CampaignCriterionStatus = {}));
    /**
     * @name BiddingStrategyTypeEnum.BiddingStrategyType
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/BiddingStrategyTypeEnum.BiddingStrategyType
     */
    let BiddingStrategyType;
    (function (BiddingStrategyType) {
        BiddingStrategyType[BiddingStrategyType["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        BiddingStrategyType[BiddingStrategyType["UNKNOWN"] = 1] = "UNKNOWN";
        BiddingStrategyType[BiddingStrategyType["COMMISSION"] = 16] = "COMMISSION";
        BiddingStrategyType[BiddingStrategyType["ENHANCED_CPC"] = 2] = "ENHANCED_CPC";
        BiddingStrategyType[BiddingStrategyType["MANUAL_CPC"] = 3] = "MANUAL_CPC";
        BiddingStrategyType[BiddingStrategyType["MANUAL_CPM"] = 4] = "MANUAL_CPM";
        BiddingStrategyType[BiddingStrategyType["MANUAL_CPV"] = 13] = "MANUAL_CPV";
        BiddingStrategyType[BiddingStrategyType["MAXIMIZE_CONVERSIONS"] = 10] = "MAXIMIZE_CONVERSIONS";
        BiddingStrategyType[BiddingStrategyType["MAXIMIZE_CONVERSION_VALUE"] = 11] = "MAXIMIZE_CONVERSION_VALUE";
        BiddingStrategyType[BiddingStrategyType["PAGE_ONE_PROMOTED"] = 5] = "PAGE_ONE_PROMOTED";
        BiddingStrategyType[BiddingStrategyType["PERCENT_CPC"] = 12] = "PERCENT_CPC";
        BiddingStrategyType[BiddingStrategyType["TARGET_CPA"] = 6] = "TARGET_CPA";
        BiddingStrategyType[BiddingStrategyType["TARGET_CPM"] = 14] = "TARGET_CPM";
        BiddingStrategyType[BiddingStrategyType["TARGET_IMPRESSION_SHARE"] = 15] = "TARGET_IMPRESSION_SHARE";
        BiddingStrategyType[BiddingStrategyType["TARGET_OUTRANK_SHARE"] = 7] = "TARGET_OUTRANK_SHARE";
        BiddingStrategyType[BiddingStrategyType["TARGET_ROAS"] = 8] = "TARGET_ROAS";
        BiddingStrategyType[BiddingStrategyType["TARGET_SPEND"] = 9] = "TARGET_SPEND";
    })(BiddingStrategyType = GoogleAdsEnum.BiddingStrategyType || (GoogleAdsEnum.BiddingStrategyType = {}));
    /**
     * @name AgeRangeTypeEnum.AgeRangeType
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/AgeRangeTypeEnum.AgeRangeType
     */
    let AgeRangeType;
    (function (AgeRangeType) {
        AgeRangeType[AgeRangeType["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        AgeRangeType[AgeRangeType["UNKNOWN"] = 1] = "UNKNOWN";
        AgeRangeType[AgeRangeType["AGE_RANGE_18_24"] = 503001] = "AGE_RANGE_18_24";
        AgeRangeType[AgeRangeType["AGE_RANGE_25_34"] = 503002] = "AGE_RANGE_25_34";
        AgeRangeType[AgeRangeType["AGE_RANGE_35_44"] = 503003] = "AGE_RANGE_35_44";
        AgeRangeType[AgeRangeType["AGE_RANGE_45_54"] = 503004] = "AGE_RANGE_45_54";
        AgeRangeType[AgeRangeType["AGE_RANGE_55_64"] = 503005] = "AGE_RANGE_55_64";
        AgeRangeType[AgeRangeType["AGE_RANGE_65_UP"] = 503006] = "AGE_RANGE_65_UP";
        AgeRangeType[AgeRangeType["AGE_RANGE_UNDETERMINED"] = 503999] = "AGE_RANGE_UNDETERMINED";
    })(AgeRangeType = GoogleAdsEnum.AgeRangeType || (GoogleAdsEnum.AgeRangeType = {}));
    /**
     * @name DeviceEnum.Device
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/DeviceEnum.Device
     */
    let Device;
    (function (Device) {
        Device[Device["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        Device[Device["UNKNOWN"] = 1] = "UNKNOWN";
        Device[Device["MOBILE"] = 2] = "MOBILE";
        Device[Device["TABLET"] = 3] = "TABLET";
        Device[Device["DESKTOP"] = 4] = "DESKTOP";
        Device[Device["CONNECTED_TV"] = 6] = "CONNECTED_TV";
        Device[Device["OTHER"] = 5] = "OTHER";
    })(Device = GoogleAdsEnum.Device || (GoogleAdsEnum.Device = {}));
    /**
     * @name CampaignStatusEnum.CampaignStatus
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/CampaignStatusEnum.CampaignStatus
     */
    let CampaignStatus;
    (function (CampaignStatus) {
        CampaignStatus[CampaignStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        CampaignStatus[CampaignStatus["UNKNOWN"] = 1] = "UNKNOWN";
        CampaignStatus[CampaignStatus["ENABLED"] = 2] = "ENABLED";
        CampaignStatus[CampaignStatus["PAUSED"] = 3] = "PAUSED";
        CampaignStatus[CampaignStatus["REMOVED"] = 4] = "REMOVED";
    })(CampaignStatus = GoogleAdsEnum.CampaignStatus || (GoogleAdsEnum.CampaignStatus = {}));
    let Languages;
    (function (Languages) {
        Languages[Languages["Arabic"] = 1019] = "Arabic";
        Languages[Languages["Bengali"] = 1056] = "Bengali";
        Languages[Languages["Bulgarian"] = 1020] = "Bulgarian";
        Languages[Languages["Catalan"] = 1038] = "Catalan";
        Languages[Languages["Chinese_simplified"] = 1017] = "Chinese_simplified";
        Languages[Languages["Chinese_traditional"] = 1018] = "Chinese_traditional";
        Languages[Languages["Croatian"] = 1039] = "Croatian";
        Languages[Languages["Czech"] = 1021] = "Czech";
        Languages[Languages["Danish"] = 1009] = "Danish";
        Languages[Languages["Dutch"] = 1010] = "Dutch";
        Languages[Languages["English"] = 1000] = "English";
        Languages[Languages["Estonian"] = 1043] = "Estonian";
        Languages[Languages["Filipino"] = 1042] = "Filipino";
        Languages[Languages["Finnish"] = 1011] = "Finnish";
        Languages[Languages["French"] = 1002] = "French";
        Languages[Languages["German"] = 1001] = "German";
        Languages[Languages["Greek"] = 1022] = "Greek";
        Languages[Languages["Gujarati"] = 1072] = "Gujarati";
        Languages[Languages["Hebrew"] = 1027] = "Hebrew";
        Languages[Languages["Hindi"] = 1023] = "Hindi";
        Languages[Languages["Hungarian"] = 1024] = "Hungarian";
        Languages[Languages["Icelandic"] = 1026] = "Icelandic";
        Languages[Languages["Indonesian"] = 1025] = "Indonesian";
        Languages[Languages["Italian"] = 1004] = "Italian";
        Languages[Languages["Japanese"] = 1005] = "Japanese";
        Languages[Languages["Kannada"] = 1086] = "Kannada";
        Languages[Languages["Korean"] = 1012] = "Korean";
        Languages[Languages["Latvian"] = 1028] = "Latvian";
        Languages[Languages["Lithuanian"] = 1029] = "Lithuanian";
        Languages[Languages["Malay"] = 1102] = "Malay";
        Languages[Languages["Malayalam"] = 1098] = "Malayalam";
        Languages[Languages["Marathi"] = 1101] = "Marathi";
        Languages[Languages["Norwegian"] = 1013] = "Norwegian";
        Languages[Languages["Persian"] = 1064] = "Persian";
        Languages[Languages["Polish"] = 1030] = "Polish";
        Languages[Languages["Portuguese"] = 1014] = "Portuguese";
        Languages[Languages["Romanian"] = 1032] = "Romanian";
        Languages[Languages["Russian"] = 1031] = "Russian";
        Languages[Languages["Serbian"] = 1035] = "Serbian";
        Languages[Languages["Slovak"] = 1033] = "Slovak";
        Languages[Languages["Slovenian"] = 1034] = "Slovenian";
        Languages[Languages["Spanish"] = 1003] = "Spanish";
        Languages[Languages["Swedish"] = 1015] = "Swedish";
        Languages[Languages["Tamil"] = 1130] = "Tamil";
        Languages[Languages["Telugu"] = 1131] = "Telugu";
        Languages[Languages["Thai"] = 1044] = "Thai";
        Languages[Languages["Turkish"] = 1037] = "Turkish";
        Languages[Languages["Ukrainian"] = 1036] = "Ukrainian";
        Languages[Languages["Urdu"] = 1041] = "Urdu";
        Languages[Languages["Vietnamese"] = 1040] = "Vietnamese";
    })(Languages = GoogleAdsEnum.Languages || (GoogleAdsEnum.Languages = {}));
    let GeoTargetConstant;
    (function (GeoTargetConstant) {
        GeoTargetConstant["Afghanistan"] = "AF";
        GeoTargetConstant["Albania"] = "AL";
        GeoTargetConstant["Algeria"] = "DZ";
        GeoTargetConstant["American Samoa"] = "AS";
        GeoTargetConstant["Andorra"] = "AD";
        GeoTargetConstant["Angola"] = "AO";
        GeoTargetConstant["Anguilla"] = "AI";
        GeoTargetConstant["Antarctica"] = "AQ";
        GeoTargetConstant["Antigua and Barbuda"] = "AG";
        GeoTargetConstant["Argentina"] = "AR";
        GeoTargetConstant["Armenia"] = "AM";
        GeoTargetConstant["Aruba"] = "AW";
        GeoTargetConstant["Australia"] = "AU";
        GeoTargetConstant["Austria"] = "AT";
        GeoTargetConstant["Azerbaijan"] = "AZ";
        GeoTargetConstant["Bahamas"] = "BS";
        GeoTargetConstant["Bahrain"] = "BH";
        GeoTargetConstant["Bangladesh"] = "BD";
        GeoTargetConstant["Barbados"] = "BB";
        GeoTargetConstant["Belarus"] = "BY";
        GeoTargetConstant["Belgium"] = "BE";
        GeoTargetConstant["Belize"] = "BZ";
        GeoTargetConstant["Benin"] = "BJ";
        GeoTargetConstant["Bermuda"] = "BM";
        GeoTargetConstant["Bhutan"] = "BT";
        GeoTargetConstant["Bolivia"] = "BO";
        GeoTargetConstant["Bonaire, Sint Eustatius and Saba"] = "BQ";
        GeoTargetConstant["Bosnia and Herzegovina"] = "BA";
        GeoTargetConstant["Botswana"] = "BW";
        GeoTargetConstant["Bouvet Island"] = "BV";
        GeoTargetConstant["Brazil"] = "BR";
        GeoTargetConstant["British Indian Ocean Territory"] = "IO";
        GeoTargetConstant["Brunei Darussalam"] = "BN";
        GeoTargetConstant["Bulgaria"] = "BG";
        GeoTargetConstant["Burkina Faso"] = "BF";
        GeoTargetConstant["Burundi"] = "BI";
        GeoTargetConstant["Cambodia"] = "KH";
        GeoTargetConstant["Cameroon"] = "CM";
        GeoTargetConstant["Canada"] = "CA";
        GeoTargetConstant["Cape Verde"] = "CV";
        GeoTargetConstant["Cayman Islands"] = "KY";
        GeoTargetConstant["Central African Republic"] = "CF";
        GeoTargetConstant["Chad"] = "TD";
        GeoTargetConstant["Chile"] = "CL";
        GeoTargetConstant["China"] = "CN";
        GeoTargetConstant["Christmas Island"] = "CX";
        GeoTargetConstant["Cocos (Keeling) Islands"] = "CC";
        GeoTargetConstant["Colombia"] = "CO";
        GeoTargetConstant["Comoros"] = "KM";
        GeoTargetConstant["Congo"] = "CG";
        GeoTargetConstant["Congo, Democratic Republic"] = "CD";
        GeoTargetConstant["Cook Islands"] = "CK";
        GeoTargetConstant["Costa Rica"] = "CR";
        GeoTargetConstant["Cote d'Ivoire"] = "CI";
        GeoTargetConstant["Croatia"] = "HR";
        GeoTargetConstant["Cuba"] = "CU";
        GeoTargetConstant["Cura\u00C3\u00A7ao"] = "CW";
        GeoTargetConstant["Cyprus"] = "CY";
        GeoTargetConstant["Czechia"] = "CZ";
        GeoTargetConstant["Denmark"] = "DK";
        GeoTargetConstant["Djibouti"] = "DJ";
        GeoTargetConstant["Dominica"] = "DM";
        GeoTargetConstant["Dominican Republic"] = "DO";
        GeoTargetConstant["East Timor"] = "TL";
        GeoTargetConstant["Ecuador"] = "EC";
        GeoTargetConstant["Egypt"] = "EG";
        GeoTargetConstant["El Salvador"] = "SV";
        GeoTargetConstant["Equatorial Guinea"] = "GQ";
        GeoTargetConstant["Eritrea"] = "ER";
        GeoTargetConstant["Estonia"] = "EE";
        GeoTargetConstant["Ethiopia"] = "ET";
        GeoTargetConstant["Falkland Islands (Malvinas)"] = "FK";
        GeoTargetConstant["Faroe Islands"] = "FO";
        GeoTargetConstant["Fiji"] = "FJ";
        GeoTargetConstant["Finland"] = "FI";
        GeoTargetConstant["France"] = "FR";
        GeoTargetConstant["French Guiana"] = "GF";
        GeoTargetConstant["French Polynesia"] = "PF";
        GeoTargetConstant["French Southern Territories"] = "TF";
        GeoTargetConstant["Gabon"] = "GA";
        GeoTargetConstant["Gambia"] = "GM";
        GeoTargetConstant["Georgia"] = "GE";
        GeoTargetConstant["Germany"] = "DE";
        GeoTargetConstant["Ghana"] = "GH";
        GeoTargetConstant["Gibraltar"] = "GI";
        GeoTargetConstant["Greece"] = "GR";
        GeoTargetConstant["Greenland"] = "GL";
        GeoTargetConstant["Grenada"] = "GD";
        GeoTargetConstant["Guadeloupe"] = "GP";
        GeoTargetConstant["Guam"] = "GU";
        GeoTargetConstant["Guatemala"] = "GT";
        GeoTargetConstant["Guernsey"] = "GG";
        GeoTargetConstant["Guinea"] = "GN";
        GeoTargetConstant["Guinea-Bissau"] = "GW";
        GeoTargetConstant["Guyana"] = "GY";
        GeoTargetConstant["Haiti"] = "HT";
        GeoTargetConstant["Heard and McDonald Islands"] = "HM";
        GeoTargetConstant["Honduras"] = "HN";
        GeoTargetConstant["Hong Kong"] = "HK";
        GeoTargetConstant["Hungary"] = "HU";
        GeoTargetConstant["Iceland"] = "IS";
        GeoTargetConstant["India"] = "IN";
        GeoTargetConstant["Indonesia"] = "ID";
        GeoTargetConstant["Iran"] = "IR";
        GeoTargetConstant["Iraq"] = "IQ";
        GeoTargetConstant["Ireland"] = "IE";
        GeoTargetConstant["Israel"] = "IL";
        GeoTargetConstant["Italy"] = "IT";
        GeoTargetConstant["Jamaica"] = "JM";
        GeoTargetConstant["Japan"] = "JP";
        GeoTargetConstant["Jersey"] = "JE";
        GeoTargetConstant["Jordan"] = "JO";
        GeoTargetConstant["Kazakhstan"] = "KZ";
        GeoTargetConstant["Kenya"] = "KE";
        GeoTargetConstant["Kiribati"] = "KI";
        GeoTargetConstant["Kuwait"] = "KW";
        GeoTargetConstant["Kyrgyzstan"] = "KG";
        GeoTargetConstant["Lao People's Democratic Republic"] = "LA";
        GeoTargetConstant["Latvia"] = "LV";
        GeoTargetConstant["Lebanon"] = "LB";
        GeoTargetConstant["Lesotho"] = "LS";
        GeoTargetConstant["Liberia"] = "LR";
        GeoTargetConstant["Libya"] = "LY";
        GeoTargetConstant["Liechtenstein"] = "LI";
        GeoTargetConstant["Lithuania"] = "LT";
        GeoTargetConstant["Luxembourg"] = "LU";
        GeoTargetConstant["Macau"] = "MO";
        GeoTargetConstant["Macedonia"] = "MK";
        GeoTargetConstant["Madagascar"] = "MG";
        GeoTargetConstant["Malawi"] = "MW";
        GeoTargetConstant["Malaysia"] = "MY";
        GeoTargetConstant["Maldives"] = "MV";
        GeoTargetConstant["Mali"] = "ML";
        GeoTargetConstant["Malta"] = "MT";
        GeoTargetConstant["Marshall Islands"] = "MH";
        GeoTargetConstant["Martinique"] = "MQ";
        GeoTargetConstant["Mauritania"] = "MR";
        GeoTargetConstant["Mauritius"] = "MU";
        GeoTargetConstant["Mayotte"] = "YT";
        GeoTargetConstant["Mexico"] = "MX";
        GeoTargetConstant["Micronesia"] = "FM";
        GeoTargetConstant["Moldova"] = "MD";
        GeoTargetConstant["Monaco"] = "MC";
        GeoTargetConstant["Mongolia"] = "MN";
        GeoTargetConstant["Montenegro"] = "ME";
        GeoTargetConstant["Montserrat"] = "MS";
        GeoTargetConstant["Morocco"] = "MA";
        GeoTargetConstant["Mozambique"] = "MZ";
        GeoTargetConstant["Myanmar"] = "MM";
        GeoTargetConstant["Namibia"] = "NA";
        GeoTargetConstant["Nauru"] = "NR";
        GeoTargetConstant["Nepal"] = "NP";
        GeoTargetConstant["Netherlands"] = "NL";
        GeoTargetConstant["Netherlands Antilles"] = "AN";
        GeoTargetConstant["New Caledonia"] = "NC";
        GeoTargetConstant["New Zealand"] = "NZ";
        GeoTargetConstant["Nicaragua"] = "NI";
        GeoTargetConstant["Niger"] = "NE";
        GeoTargetConstant["Nigeria"] = "NG";
        GeoTargetConstant["Niue"] = "NU";
        GeoTargetConstant["Norfolk Island"] = "NF";
        GeoTargetConstant["North Korea"] = "KP";
        GeoTargetConstant["Northern Mariana Islands"] = "MP";
        GeoTargetConstant["Norway"] = "NO";
        GeoTargetConstant["Oman"] = "OM";
        GeoTargetConstant["Pakistan"] = "PK";
        GeoTargetConstant["Palau"] = "PW";
        GeoTargetConstant["Palestinian Territory"] = "PS";
        GeoTargetConstant["Panama"] = "PA";
        GeoTargetConstant["Papua New Guinea"] = "PG";
        GeoTargetConstant["Paraguay"] = "PY";
        GeoTargetConstant["Peru"] = "PE";
        GeoTargetConstant["Philippines"] = "PH";
        GeoTargetConstant["Pitcairn"] = "PN";
        GeoTargetConstant["Poland"] = "PL";
        GeoTargetConstant["Portugal"] = "PT";
        GeoTargetConstant["Puerto Rico"] = "PR";
        GeoTargetConstant["Qatar"] = "QA";
        GeoTargetConstant["Republic of Kosovo"] = "XK";
        GeoTargetConstant["Reunion"] = "RE";
        GeoTargetConstant["Romania"] = "RO";
        GeoTargetConstant["Russian Federation"] = "RU";
        GeoTargetConstant["Rwanda"] = "RW";
        GeoTargetConstant["Saint Kitts and Nevis"] = "KN";
        GeoTargetConstant["Saint Lucia"] = "LC";
        GeoTargetConstant["Saint Vincent and the Grenadines"] = "VC";
        GeoTargetConstant["Samoa"] = "WS";
        GeoTargetConstant["San Marino"] = "SM";
        GeoTargetConstant["Sao Tome and Principe"] = "ST";
        GeoTargetConstant["Saudi Arabia"] = "SA";
        GeoTargetConstant["Senegal"] = "SN";
        GeoTargetConstant["Serbia"] = "RS";
        GeoTargetConstant["Seychelles"] = "SC";
        GeoTargetConstant["Sierra Leone"] = "SL";
        GeoTargetConstant["Singapore"] = "SG";
        GeoTargetConstant["Sint Maarten (Dutch part)"] = "SX";
        GeoTargetConstant["Slovakia"] = "SK";
        GeoTargetConstant["Slovenia"] = "SI";
        GeoTargetConstant["Solomon Islands"] = "SB";
        GeoTargetConstant["Somalia"] = "SO";
        GeoTargetConstant["South Africa"] = "ZA";
        GeoTargetConstant["South Georgia and The South Sandwich Islands"] = "GS";
        GeoTargetConstant["South Korea"] = "KR";
        GeoTargetConstant["Spain"] = "ES";
        GeoTargetConstant["Sri Lanka"] = "LK";
        GeoTargetConstant["St. Helena"] = "SH";
        GeoTargetConstant["St. Pierre and Miquelon"] = "PM";
        GeoTargetConstant["Sudan"] = "SD";
        GeoTargetConstant["Suriname"] = "SR";
        GeoTargetConstant["Svalbard and Jan Mayen Islands"] = "SJ";
        GeoTargetConstant["Swaziland"] = "SZ";
        GeoTargetConstant["Sweden"] = "SE";
        GeoTargetConstant["Switzerland"] = "CH";
        GeoTargetConstant["Syria"] = "SY";
        GeoTargetConstant["Taiwan"] = "TW";
        GeoTargetConstant["Tajikistan"] = "TJ";
        GeoTargetConstant["Tanzania"] = "TZ";
        GeoTargetConstant["Thailand"] = "TH";
        GeoTargetConstant["Togo"] = "TG";
        GeoTargetConstant["Tokelau"] = "TK";
        GeoTargetConstant["Tonga"] = "TO";
        GeoTargetConstant["Trinidad and Tobago"] = "TT";
        GeoTargetConstant["Tunisia"] = "TN";
        GeoTargetConstant["Turkey"] = "TR";
        GeoTargetConstant["Turkmenistan"] = "TM";
        GeoTargetConstant["Turks and Caicos Islands"] = "TC";
        GeoTargetConstant["Tuvalu"] = "TV";
        GeoTargetConstant["Uganda"] = "UG";
        GeoTargetConstant["Ukraine"] = "UA";
        GeoTargetConstant["United Arab Emirates"] = "AE";
        GeoTargetConstant["Anh Qu\u1ED1c - United Kingdom"] = "GB";
        GeoTargetConstant["Hoa K\u1EF3 - United States"] = "US";
        GeoTargetConstant["United States Minor Outlying Islands"] = "UM";
        GeoTargetConstant["Uruguay"] = "UY";
        GeoTargetConstant["Uzbekistan"] = "UZ";
        GeoTargetConstant["Vanuatu"] = "VU";
        GeoTargetConstant["Vatican"] = "VA";
        GeoTargetConstant["Venezuela"] = "VE";
        GeoTargetConstant["Vietnam"] = "VN";
        GeoTargetConstant["Virgin Islands (British)"] = "VG";
        GeoTargetConstant["Virgin Islands (U.S.)"] = "VI";
        GeoTargetConstant["Wallis and Futuna Islands"] = "WF";
        GeoTargetConstant["Western Sahara"] = "EH";
        GeoTargetConstant["Yemen"] = "YE";
        GeoTargetConstant["Zambia"] = "ZM";
        GeoTargetConstant["Zimbabwe"] = "ZW";
    })(GeoTargetConstant = GoogleAdsEnum.GeoTargetConstant || (GoogleAdsEnum.GeoTargetConstant = {}));
    /**
     * @name KeywordMatchTypeEnum.KeywordMatchType
     * @link https://developers.google.com/google-ads/api/reference/rpc/v6/KeywordMatchTypeEnum.KeywordMatchType
     */
    let KeywordMatchType;
    (function (KeywordMatchType) {
        KeywordMatchType[KeywordMatchType["UNSPECIFIED"] = 0] = "UNSPECIFIED";
        KeywordMatchType[KeywordMatchType["UNKNOWN"] = 1] = "UNKNOWN";
        KeywordMatchType[KeywordMatchType["EXACT"] = 2] = "EXACT";
        KeywordMatchType[KeywordMatchType["PHRASE"] = 3] = "PHRASE";
        KeywordMatchType[KeywordMatchType["BROAD"] = 4] = "BROAD";
        KeywordMatchType[KeywordMatchType["CUSTOM_BROAD"] = 100] = "CUSTOM_BROAD";
        KeywordMatchType[KeywordMatchType["CUSTOM_PHRASE"] = 101] = "CUSTOM_PHRASE";
    })(KeywordMatchType = GoogleAdsEnum.KeywordMatchType || (GoogleAdsEnum.KeywordMatchType = {}));
    let AdType;
    (function (AdType) {
        // "UNSPECIFIED" = 0,
        // "UNKNOWN" = 1,
        // "TEXT_AD" = 2,
        AdType[AdType["EXPANDED_TEXT_AD"] = 3] = "EXPANDED_TEXT_AD";
        // "CALL_ONLY_AD" = 6,
        // "EXPANDED_DYNAMIC_SEARCH_AD" = 7,
        // "HOTEL_AD" = 8,
        // "SHOPPING_SMART_AD" = 9,
        // "SHOPPING_PRODUCT_AD" = 10,
        // "VIDEO_AD" = 12,
        // "GMAIL_AD" = 13,
        // "IMAGE_AD" = 14,
        AdType[AdType["RESPONSIVE_SEARCH_AD"] = 15] = "RESPONSIVE_SEARCH_AD";
        // "LEGACY_RESPONSIVE_DISPLAY_AD" = 16,
        // "APP_AD" = 17,
        // "LEGACY_APP_INSTALL_AD" = 18,
        // "RESPONSIVE_DISPLAY_AD" = 19,
        // "LOCAL_AD" = 20,
        // "HTML5_UPLOAD_AD" = 21,
        // "DYNAMIC_HTML5_AD" = 22,
        // "APP_ENGAGEMENT_AD" = 23,
        // "SHOPPING_COMPARISON_LISTING_AD" = 24,
        // "VIDEO_BUMPER_AD" = 25,
        // "VIDEO_NON_SKIPPABLE_IN_STREAM_AD" = 26,
        // "VIDEO_OUTSTREAM_AD" = 27,
        // "VIDEO_TRUEVIEW_DISCOVERY_AD" = 28,
        // "VIDEO_TRUEVIEW_IN_STREAM_AD" = 29,
        // "VIDEO_RESPONSIVE_AD" = 30,
    })(AdType = GoogleAdsEnum.AdType || (GoogleAdsEnum.AdType = {}));
    let ConnectedAccountType;
    (function (ConnectedAccountType) {
        ConnectedAccountType[ConnectedAccountType["GoogleAds"] = 1] = "GoogleAds";
        ConnectedAccountType[ConnectedAccountType["FacebookAds"] = 2] = "FacebookAds";
        ConnectedAccountType[ConnectedAccountType["FacebookFanpage"] = 3] = "FacebookFanpage";
    })(ConnectedAccountType = GoogleAdsEnum.ConnectedAccountType || (GoogleAdsEnum.ConnectedAccountType = {}));
    ;
    let ConnectedAccountStatus;
    (function (ConnectedAccountStatus) {
        ConnectedAccountStatus[ConnectedAccountStatus["Connected"] = 1] = "Connected";
        ConnectedAccountStatus[ConnectedAccountStatus["Disconnected"] = 2] = "Disconnected";
    })(ConnectedAccountStatus = GoogleAdsEnum.ConnectedAccountStatus || (GoogleAdsEnum.ConnectedAccountStatus = {}));
    ;
})(GoogleAdsEnum = exports.GoogleAdsEnum || (exports.GoogleAdsEnum = {}));
