import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { AuthService, RefreshParams, WorkSpaceService, setPreviousPage, resetPreviousPage, apiErrorCodeGlobal, ErrorMessageService } from 'core';
import { withCookies, Cookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useCookie, Token, useRedirectDomain } from 'pages/auth/hooks';
import { toast } from 'react-toastify';

interface JoinTeamProps {
  cookies: Cookies;
}

const JoinTeam: React.FC<JoinTeamProps> = (props) => {
  const { token } = useParams<{ token: string }>();
  const { getAllCookies } = useCookie(props.cookies);
  const isLoggedIn = getAllCookies(Token.UserToken) as string !== undefined;
  const user = useSelector((state) => state.user.user);
  const { gotoTeam } = useRedirectDomain(props.cookies);
  const history = (globalThis as any).useHistory;
  history.hook();
  const dispatch = useDispatch();
  const service = new AuthService();
  const workspaceSrv = new WorkSpaceService();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && !user) {
      const params: RefreshParams = {
        inputParams: {
          userToken: getAllCookies(Token.UserToken) as string,
          masterUserToken: getAllCookies(Token.MasterUserToken) as string,
          hostname: new URL(window.location.origin).hostname,
        },
      };

      const getTeamParams = {
        inputParams: {},
        callBackParams: {
          successCallback: (res: any)=>{},
          errorCallback: (res: any)=>{}
        }
      };

      service.refresh(params, getTeamParams);
    }
  }, []);

  useEffect(() => {
    dispatch(resetPreviousPage());
  }, []);

  useEffect(() => {
    if (!token) {
      history.push('/login');
      return;
    }

    if (!isLoggedIn) {
      dispatch(setPreviousPage(history.generateComponent('JoinTeam', {token: token})));
      history.push({
        pathname: history.url('/team-login'),
        state: { 
          inviteToken: token,
        }
      });
      return;
    }

    if (user) {
      const joinTeamParams = {
        inputParams: {
          token,
        },
        callBackParams: {
          successCallback: (res: any) => {
            history.push('/');
          },
          errorCallback: (err: any) => {
            toast.error(ErrorMessageService.getErrorMessageByCode(err.code, 'Không thể tham gia nhóm.'));
            history.push('/link-expired');
          },
        }
      };

      const gotoTeamParams = {
        inputParams: {
          team_id: -1
        },
        callBackParams: {
          successCallback: (res: any) => {
            gotoTeam(res.selectedTeam.token, res.selectedTeam, {message: 301});
          },
          errorCallback: (err: any) => {
            console.log(err, 'join then go to team err');
          },
        }
      };

      workspaceSrv.joinTeam(joinTeamParams, gotoTeamParams);
    }
  }, [user && user.id]);

  return (
    <div />
  );
};

export default withCookies(JoinTeam);