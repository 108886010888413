"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = exports.SetNotificationAction = void 0;
exports.SetNotificationAction = {
    type: 'SetNotificationAction',
    success: 'SetNotificationAction_SUCCESS',
    error: 'SetNotificationAction_ERROR',
};
/*
 *
 * types
 *
 *
 */
var NotificationType;
(function (NotificationType) {
    NotificationType["Success"] = "success";
    NotificationType["Error"] = "error";
    NotificationType["Warning"] = "warning";
    NotificationType["Info"] = "info";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
