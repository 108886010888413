import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Project, SelectedTeam, ProjectService, DeleteProjectParams, removeLPListAfterDeleteProject, DeleteProjectSuccessParams } from 'core';
import Table, { TableActions } from 'components/tables/base';
import { CustomCellProps } from 'components/tables/base/index.d';
import {History} from 'src/route';
import { plainToClass } from 'class-transformer';

const Styles = styled.div`
  padding: 40px 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      :first-child {
        td {
          :first-child{
            border-top-left-radius: 12px;
          }
          :last-child{
            border-top-right-radius: 12px;
          }
        }
      }
      :last-child {
        td {
          border-bottom: 0;
          :first-child{
            border-bottom-left-radius: 12px;
          }
          :last-child{
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    thead th {
      font-family: Propins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #aeaeb2;
      padding: 15px;

    }

    tbody {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      td:last-child {
        .btn-group {
          opacity: 0;
          visibility: hidden;
        }
      }
      tr:hover {
        td:last-child {
          .btn-group {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    tbody th,
    tbody td {
      margin: 0;
      padding: 20px;
      border-bottom: solid 1px rgba(216, 216, 216, 0.5);
      @media (max-width: 1300px) {
        padding: 15px 10px;
      }
      :last-child {
        border-right: 0;
        text-align: left;
      }
    }

    tbody th {
      padding: 12px 15px;
      background-color: #f7f8f8;
      font-family: Propins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: #aeaeb2;
      position: relative;
    }
    tbody th:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 45px;
      border-radius: 1.5px;
      background-color: #4c7cf3;
    }
    tbody td {
      font-family: Propins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #000000;
      @media (max-width: 1300px) {
        font-size: 14px;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  @media (max-width: 1300px) {
    padding: 10px 20px 20px;

    table thead th {
      padding: 10px 0;
    }
  }
`;

type PropsType = {
  projects: Array<Project>;
}

function ProjectsTable(props: PropsType): React.ReactElement {
  const dispatch = useDispatch();
  // const history = (globalThis as any).useHistory;
  // history.hook();
  const history = new History();
  const service = new ProjectService();
  const team = useSelector((state) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));

  const columns = React.useMemo(
    () => [
      {
        Header: 'TÊN DỰ ÁN',
        accessor: 'name',
        Cell: (cellprops: CustomCellProps<Project>): React.ReactChild => {
          return (
            <Link to={history.generateComponent('EditProjectPage', {id: cellprops.row.original.id})} >
              {cellprops.cell.value}
            </Link>
          );
        }
      },
      {
        Header: 'NGÀY TẠO',
        accessor: 'created_at'
      },
      {
        Header: 'SỐ SALE THAM GIA',
        accessor: 'num_salesperson'
      },
      {
        Header: 'SỐ LANDING PAGE',
        accessor: 'num_landing_pages',
        Cell: (cellprops: CustomCellProps<Project>): React.ReactChild => {
          return (
            <Link to={history.url('/landing-management')}>
              {cellprops.cell.value}
            </Link>
          );
        }
      }
    ],
    []
  );

  const onEditRow = (rowId: number): void => {
    history.push({
      pathname: `/edit-project/${rowId}`,
    });
  };

  const onDeleteRow = (rowId: number): void => {
    const params: DeleteProjectParams = {
      inputParams: {
        projectId: rowId
      },
      // callBackParams: {
      //   successCallback: (res: DeleteProjectSuccessParams) => {
          
      //   },
      //   errorCallback: (err: any) => {

      //   }
      // }
    };
    service.deleteProject(params);
    dispatch(removeLPListAfterDeleteProject(rowId));
  };

  const shouldShowAction = (project: Project, action: TableActions): boolean => {
    let shouldShow = false;
    switch (action) {
      case TableActions.Delete:
        shouldShow = project.is_owner || team.checkUserRole(['manager']);
        break;
      case TableActions.Edit:
        shouldShow = project.is_owner || false;
        break;
      default:
        break;
    }
    return shouldShow;
  };

  return (
    <Styles>
      <Table
        columns={columns}
        data={props.projects}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        shouldShowAction={shouldShowAction}
        actions={[TableActions.Edit, TableActions.Delete]}
      />
    </Styles>
  );
}

export default ProjectsTable;
