import Button from 'components/buttons/Button';
import CancelButton from 'components/buttons/CancelButton';
import SubmitButton from 'components/buttons/SubmitButton';
import { Customer, updateCustomer, UpdateCustomerData } from 'core';
import { ButtonProps } from 'presenter';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');
interface EditButtonProps extends ButtonProps {
  comfirmMessage: React.ReactNode;
  isDeleteStyle?: boolean;
  confirmStyle?: string;
  confirmTitle?: string;
  cancelStyle?: string;
  cancelTitle?: string;
  customButtonStyle?: string;
  customer: Customer;
  customStyles?: object;
}
const EditButton: React.FC<EditButtonProps> = (props) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const mounted = useRef(false);
  const { customer } = props;

  const [createdAt, setCreatedAt] = useState(customer.created_at || '');
  const [fullName, setFullName] = useState(customer.full_name || '');
  const [phoneNumber, setPhoneNumber] = useState(customer.phone_no || '');
  const [email, setEmail] = useState(customer.email || '');
  const [location, setLocation] = useState(customer.location || '');
  const [budget, setBudget] = useState(customer.budget || '');
  const [preference, setPreference] = useState(customer.preference || '');
  const [adSource, setAdSource] = useState(customer.ad_source || '');
  const is_owner = customer.is_owner && customer.is_owner;
  
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    if (!mounted.current) return;
    setIsOpen(false);
  };
  const confirm = async () => {
    const { id, pid, pname } = customer;
    const customerData: UpdateCustomerData = {
      id,
      created_at: createdAt,
      full_name: fullName,
      phone_no: phoneNumber,
      email,
      location,
      budget,
      preference,
      ad_source: adSource,
    };
    try {
      dispatch(updateCustomer(customerData));
      // dispatch(getCustomers());
    } catch(e) {
      // TODO: handle error
    }
    closeModal();
  };

  return (
    <React.Fragment>
      <Button className={props.customButtonStyle} onClick={openModal}>
        {props.children}
      </Button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={props.customStyles}
      >
        <div className="modal-title">
          {props.comfirmMessage}
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal-wrapper flex-1">
          <div className="row">
            <div className="col-6">
              <label>NGÀY TẠO</label>
              <input
                className="form-control"
                value={createdAt}
                onChange={(e) => setCreatedAt(e.target.value)}
                disabled
              />
            </div>
            <div className="col-6">
              <label>TÊN KHÁCH HÀNG</label>
              <input
                className="form-control"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>SĐT</label>
              <input
                className="form-control"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>EMAIL</label>
              <input
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>KHU VỰC</label>
              <input
                className="form-control"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>TÀI CHÍNH</label>
              <input
                className="form-control"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>NHU CẦU</label>
              <input
                className="form-control"
                value={preference}
                onChange={(e) => setPreference(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>NGUỒN KHÁCH HÀNG</label>
              <input
                className="form-control"
                value={adSource}
                onChange={(e) => setAdSource(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end" style={{marginBottom: '80px'}}>
          <CancelButton activeClassName="btn-subsidiary btn-grey-suit" onClick={closeModal}>
            {props.cancelTitle}
          </CancelButton>
          {is_owner && <SubmitButton alignRight onClick={confirm}>{props.confirmTitle}</SubmitButton>}
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

EditButton.defaultProps = {
  isDeleteStyle: false,
  customButtonStyle: 'button-link',
  confirmStyle: 'btn-red ml-3',
  cancelStyle: 'btn-submit',
  confirmTitle: 'OK',
  cancelTitle: 'Cancel',
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 99
    },
    content: {
      top: '0',
      left: 'auto',
      right: '0',
      bottom: '0',
      border: '0',
      maxWidth: '688px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(0, 0)',
    },
  },
};

export default EditButton;
