import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ProjectTypes } from 'core';
import { History } from 'src/route/Route';

interface EmptySearchResultProps {
  hasNoSearchResult?: boolean;
}

function EmptySearchResult({ hasNoSearchResult = true }: EmptySearchResultProps) {
  const history = new History();
  const [projectType, setProjectType] = React.useState<string>();

  const goToCreateProject = () => {
    history.push(`/create-project/${projectType}`);
  };

  return (
    <div className="more-action row">
      <div className={hasNoSearchResult ? 'd-none' : 'col-lg-3'}>
        <div className="box-shadow">
          <img
            src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/${hasNoSearchResult ? '207x201' : '138x100'}/chat.png`}
            alt="chat with us"
          />
          <div className="box-title">
            Chat với chúng tôi
            <br />
            để tải lên thông tin dự án
          </div>
          <button className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
              <path
                fill="#FFF"
                fillRule="evenodd"
                d="M8.506 17.687c-1.368.716-3.171 1.47-5.41 2.265a.82.82 0 0 1-1.055-1.025 34.86 34.86 0 0 0 .977-3.637C1.11 13.743 0 11.595 0 9.002 0 3.501 5 0 12 0s12 3.5 12 9.002c0 5.502-5 9.002-12 9.002-1.232 0-2.402-.108-3.494-.317z"
              />
            </svg>
            Chat ngay
          </button>
        </div>
      </div>
      <div className={hasNoSearchResult ? 'col-lg-8 offset-lg-2' : 'col-lg-3'}>
        <div className="box-shadow">
          <div className="row">
            <div className="col-lg-4">
              <img
                src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/432x346/choose-project.png`}
                alt="create project"
                width="216"
                height="173"
              />
            </div>
            <div className="col-lg-8">
              <div className="box-title">Tạo dự án mới</div>
              <p className="box-descriptiion">Chọn loại hình bất động sản phù hợp với bạn</p>
              <div className="project-type-list">
                <button
                  className={projectType === ProjectTypes.APARTMENT ? 'project-type-item active' : 'project-type-item'}
                  onClick={() => setProjectType(ProjectTypes.APARTMENT)}
                >
                  Chung cư
                </button>
                <button
                  className={
                    projectType === ProjectTypes.SHOPHOUSE_VILLA ? 'project-type-item active' : 'project-type-item'
                  }
                  onClick={() => setProjectType(ProjectTypes.SHOPHOUSE_VILLA)}
                >
                  Shophouse/BT liền kề
                </button>
                <button
                  className={projectType === ProjectTypes.CONDOTEL ? 'project-type-item active' : 'project-type-item'}
                  onClick={() => setProjectType(ProjectTypes.CONDOTEL)}
                >
                  Căn hộ/BT nghỉ dưỡng
                </button>
                <button
                  className={projectType === ProjectTypes.LAND ? 'project-type-item active' : 'project-type-item'}
                  onClick={() => setProjectType(ProjectTypes.LAND)}
                >
                  Đất nền
                </button>
              </div>
              <button className="btn" disabled={!projectType} onClick={goToCreateProject}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g fill="#FFF" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" opacity=".01" />
                    <path d="M13.143 10.857h5.747c.25 0 .442.046.603.133a.899.899 0 0 1 .374.374c.087.161.133.353.133.603v.066c0 .25-.046.442-.133.603a.899.899 0 0 1-.374.374 1.237 1.237 0 0 1-.603.133h-5.747v5.747c0 .25-.046.442-.133.603a.899.899 0 0 1-.374.374 1.237 1.237 0 0 1-.603.133h-.066c-.25 0-.442-.046-.603-.133a.9.9 0 0 1-.374-.374 1.237 1.237 0 0 1-.133-.603v-5.747H5.11c-.25 0-.442-.046-.603-.133a.903.903 0 0 1-.374-.374A1.24 1.24 0 0 1 4 12.033v-.066c0-.25.046-.442.133-.603a.899.899 0 0 1 .374-.374c.16-.086.354-.133.603-.133h5.747V5.11c0-.25.046-.442.133-.603a.903.903 0 0 1 .374-.374c.161-.087.353-.133.603-.133h.066c.25 0 .442.046.603.133a.899.899 0 0 1 .374.374c.086.16.133.353.133.603v5.747z" />
                  </g>
                </svg>
                Tạo ngay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptySearchResult;
