import { useFormContext } from 'react-hook-form';
import React, {useState, useEffect} from 'react';
import { AdGroupAdExtends, CollapseState, SPECS, TemplateAdsFormState, AdsChain } from '../forms';
import { adsType, customModalStyles, ErrorInput } from '.';
import { 
  GoogleAdsEnum,
  Ad
} from 'core';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import SubmitButton from 'components/buttons/SubmitButton';
import CancelButton from 'components/buttons/CancelButton';
import { CollapseError, ExpandCollapseIcon } from 'components/icons/SVGIcon';
interface PropsType{
  // onChange: (value: any) => void;
  url: any;
  defaultValue: AdGroupAdExtends;
  index: number;
  adGroup: CollapseState;
  chain: AdsChain | null;
  handleRemove: () => void;
  handleChangeType: (value: never, index: number) => void;
}

interface NestType {
  nestIndex: number;
  index: number;
  defaultValue: any;
  maxLength: number;
}

export const NestHeadline: React.FC<NestType> = (props) => {

  const { clearErrors, setValue, register } = useFormContext<TemplateAdsFormState>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`adgroupad.${props.index}.adGroupAd.ad.headlines.${props.nestIndex}` as const, e.target.value as never);
    clearErrors([`adgroupad.${props.index}.Headline` as never]);
  };

  return <input
      key={props.index}
      type="text"
      className="form-control w-100"
      placeholder={`Nhập tiêu đề ${props.nestIndex + 1}`}
      maxLength={props.maxLength}
      {...register(`adgroupad.${props.index}.adGroupAd.ad.headlines.${props.nestIndex}` as const)}
      onChange={onChange}
      defaultValue={props.defaultValue}
      />;
};

export const NestDescription: React.FC<NestType> = (props) => {
  const { clearErrors, setValue, register } = useFormContext<TemplateAdsFormState>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`adgroupad.${props.index}.adGroupAd.ad.descriptions.${props.nestIndex}` as const, e.target.value as never);
    clearErrors(`adgroupad.${props.index}.Desc` as never);
    clearErrors(`adgroupad.${props.index}.CommonDesc.${props.nestIndex}` as never);
  };

  return <input
    type="text"
    className="form-control w-100"
    placeholder={`Nhập mô tả ${props.nestIndex + 1}`}
    maxLength={props.maxLength}
    defaultValue={props.defaultValue}
    {...register(`adgroupad.${props.index}.adGroupAd.ad.descriptions.${props.nestIndex}` as const)}
    onChange={onChange}
  />;

};

export function ResponsiveAd({index, defaultValue, url, ...props}: PropsType){
    const { register, control, watch, setValue, trigger, formState: {errors}, clearErrors, reset, ...formMethods } = useFormContext<TemplateAdsFormState>();
    const [adsHeadlines, setAdsHeadlines] = useState<string[]>();
    const [adsDesc, setAdsDesc] = useState<string[]>();
  const [openModal, setOpenModal] = useState(false);

    const adGroupOpenState = props.adGroup.state?.[0] === index;
    const req = SPECS[GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD];

    useEffect(() => {
      const firstError = document.querySelector('.error-input:not(.hidden *)');
      firstError?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [errors]); 
    
    const watchField = watch('adgroupad');
    
    const condition = (ref: string) => {
      const criterion = {
        'headlines': 'minHeadline',
        'descriptions': 'minDesc'
      };
      const text = watchField && watchField[index] && watchField[index]?.adGroupAd.ad?.[ref];
      const firstFilled = text ? text.filter((el: string, id: number)=>el && id < req[criterion[ref]].value).length : 0;
      const lastFilled = text ? text.filter((el: string, id: number)=>el && id >= req[criterion[ref]].value).length : 0;
      const validateQuantity = req[criterion[ref]].value - firstFilled - lastFilled;
      return {
        text,
        firstFilled,
        lastFilled,
        validateQuantity
      };
    };
    
    useEffect(() => {
      const setDefaultHeadLines = () => {
        const {text} = condition('headlines');
        if(!text) return;
        
        const numHeadlines = defaultValue.adGroupAd.ad.headlines?.length || 0;
        const defaultHeadline = defaultValue.adGroupAd.ad.headlines || [];
        
        for(let i = 0; i < text.length; i++){
          if(i < req.defaultHeadline.value){
            setValue(`adgroupad.${index}.adGroupAd.ad.headlines.${i}` as const, (defaultHeadline[i] ? defaultHeadline[i] : '') as never);
          }else{
            formMethods.unregister(`adgroupad.${index}.adGroupAd.ad.headlines.${i}` as const);
          }
        }
      
        if (numHeadlines >= req.defaultHeadline.value) {
          return defaultHeadline;
        }
        return [...defaultHeadline, ...new Array(req.defaultHeadline.value - numHeadlines).fill('')];
      };

      const setDefaultDescription = () => {
        const {text} = condition('descriptions');
        if(!text) return;
        
        const numDesc = defaultValue.adGroupAd.ad.descriptions?.length || 0;
        const defaultDesc = defaultValue.adGroupAd.ad.descriptions || [];
        
        for(let i = 0; i < text.length; i++){
          if(i < req.defaultDesc.value){
            setValue(`adgroupad.${index}.adGroupAd.ad.descriptions.${i}` as const, (defaultDesc[i] ? defaultDesc[i] : '') as never);
          }else{
            formMethods.unregister(`adgroupad.${index}.adGroupAd.ad.descriptions.${i}` as const);
          }
        }
        
        
        if (numDesc >= req.defaultDesc.value) {
          return defaultDesc;
        }
        const desc = [...defaultDesc, ...new Array(req.defaultDesc.value - numDesc).fill('')];
        desc.map((el, i)=>{
          setValue(`adgroupad.${index}.adGroupAd.ad.descriptions.${i}` as const, el as never);
        });
        return desc;
      };

      setAdsHeadlines(setDefaultHeadLines());
      
      setAdsDesc(setDefaultDescription());

      setValue(`adgroupad.${index}.adGroupAd.ad.final_url` as const, (url.origin || process.env.REACT_APP_DOMAIN) as never);
      
      // handleChangeUrl({target: {value: ''}});
    }, [defaultValue, defaultValue.adGroupAd?.ad?.type]);

  const onToggleAd = () => {
    props.adGroup.setExpandingAd();
  };

    const renderCapitalize = (descriptions: string[], operator = '. ', limit = 75) => {
      if(!descriptions) return;
      const allchar = descriptions.filter(el=>el).map(el=>el.charAt(0).toUpperCase() + el.substring(1)).join(operator);
      return allchar.length > limit + 1 ? allchar.substring(0,limit) + '...' : allchar;
    };
    
//     const handleChangeUrl = (e: any) => {
//         const value = e.target.value;
//         const url = new URL(value, window.location.origin);
//         const path = [url.hostname, url.pathname.split('/')];
// console.group('handleChangeUrl')        
//         setValue(`adgroupad.${index}.adGroupAd.ad.paths` as const, path.flat().filter(el=>el) as never);
//     };
    
    const handleChangeAdType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const allAdsInList = props.chain?.list?.[index] ? props.chain.list[index] : [index];

    const value = allAdsInList.map(i => watch(`adgroupad.${i}.adGroupAd.ad` as never)) as unknown as Ad[];
    // If typed descriptions or headline in some ads or have more than 3 ads then show modal
    if (value.some(e => !!e.descriptions.some(el => !!el)) || value.some(e => !!e.headlines.some(el => !!el))) {
      setOpenModal(true);
      setValue(`adgroupad.${index}.adGroupAd.ad.type` as const, GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD as never);
      return;
    }
      setValue(`adgroupad.${index}.adGroupAd.ad.type` as const, GoogleAdsEnum.AdType.EXPANDED_TEXT_AD as never);
      props.handleChangeType(e.target.value as never, index);
      reset(watch() as never);
  };

  const switchAdType = () => {
    props.handleChangeType(GoogleAdsEnum.AdType.EXPANDED_TEXT_AD as never, index);
    reset(watch() as never);
  };

    const handleAddHeadline = () => {
      const headlines = [...adsHeadlines as string[], ''];
      if(headlines.length > req.maxHeadline.value) return;
      setAdsHeadlines(headlines);
    };
    
    const handleAddDescriptions = () => {
      const desc = [...adsDesc as string[], ''];
      if(desc.length > req.maxDesc.value) return;
      setAdsDesc(desc);
    };
    
    if(!watchField[index]) return <>nothing to show</>;

    const groupShouldShow = {
      isExpanding: adGroupOpenState,
      isExpandingGroup: props.adGroup.isExpandingGroup,
      title: !props.chain?.prev,
      content: adGroupOpenState,
      borderTop: props.chain?.hasChain && props.chain?.prev, //TODO: check if ad type has changed,
      removeAds: props.chain?.prev,
      isSingleAd: !props.chain?.hasChain
    };


  const renderFullContentUI = () => (
    <div className={classNames({ 'hidden': !groupShouldShow.isExpanding })}>
      <div className='col-lg-12' style={{ borderTop: groupShouldShow.borderTop ? 'solid 1px #d8d8d8' : 'none', paddingTop: groupShouldShow.borderTop ? '20px' : 'initial', paddingBottom: '10px' }}>
        <div className="ad-template__title w-100 cursor-pointer" onClick={onToggleAd}>
          Mẫu quảng cáo {(props.chain?.list[index].indexOf(index) || 0) + 1}
          <div className="section-toogle">
            <ExpandCollapseIcon state={true} />
          </div>
        </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="input-group">
            <h4>URL hiển thị</h4>
            <input
              type="text"
              name="url-display"
              className="form-control w-100"
              disabled
              defaultValue={url.hostname}
              placeholder={'vd: https://vinhomesmetropolis.cokhach.vn'}
              // onChange={handleChangeUrl}
            />
            <input type="hidden" 
              {...register(`adgroupad.${index}.adGroupAd.ad.paths` as const)}
              defaultValue={[]}
            />
          </div>
          <div className="input-group">
              <h4>Tiêu đề</h4>
            {adsHeadlines?.map((headline: any,  id: number)=>{
              const {text, firstFilled, lastFilled, validateQuantity} = condition('headlines');
              
              return <React.Fragment key={id}><div className="position-relative w-100">
              <NestHeadline
                defaultValue={headline} 
                nestIndex={id}
                index={index} 
                maxLength={req.maxLengthHeadline.value}
              /><InputLength text={text[id]} max={req.maxLengthHeadline.value + 1}/></div>
              {!text[id] && validateQuantity > 0 && id + firstFilled < req.minHeadline.value + firstFilled - lastFilled && <ErrorMessage 
              errors={errors} 
              name={`adgroupad.${index}.Headline` as never} 
              render={({message})=><ErrorInput text={message}/>}/>}
              
              <ErrorMessage 
                errors={errors} 
                name={`adgroupad.${index}.CommonHeadline.${id}` as never} 
                render={({message})=><ErrorInput text={message}/>}
              />
              
              </React.Fragment>;
            })}
            
          </div>
          {adsHeadlines && adsHeadlines.length < req.maxHeadline.value && <div className="link-add-another cursor-pointer" onClick={handleAddHeadline}>
            Thêm tiêu đề mới
          </div>}
          <div className="input-group">
            <h4>Mô tả</h4>
            { adsDesc && adsDesc.length > 0 && adsDesc?.map((descriptions, id)=>{
              const {text, firstFilled, lastFilled, validateQuantity} = condition('descriptions');
              
              return <React.Fragment key={id}><div className="position-relative w-100">
                <NestDescription
                  nestIndex={id}
                  index={index}
                  defaultValue={descriptions}
                  maxLength={req.maxLengthDesc.value}
                />
                <InputLength text={text[id]} max={req.maxLengthDesc.value + 1}/></div>
            {!text[id] && validateQuantity > 0 && id + firstFilled < req.minDesc.value + firstFilled - lastFilled && <ErrorMessage 
              errors={errors} 
              name={`adgroupad.${index}.Desc` as never} 
              render={({message})=><ErrorInput text={message}/>}/>}
              
              <ErrorMessage 
                errors={errors} 
                name={`adgroupad.${index}.CommonDesc.${id}` as never} 
                render={({message})=><ErrorInput text={message}/>}
              />
      
            </React.Fragment>;})}
          </div>
          {adsDesc && adsDesc.length < req.maxDesc.value && <div className="link-add-another cursor-pointer" onClick={handleAddDescriptions}>
            Thêm mô tả mới
          </div>}
        </div>
        <div className="col-lg-6">
          <h4>Loại quảng cáo</h4>
            {props.chain?.prev ? <span className="mt-2">Quảng cáo dạng tìm kiếm thích ứng</span> 
            : <select {...register(`adgroupad.${index}.adGroupAd.ad.type` as const)} onChange={handleChangeAdType} defaultValue={GoogleAdsEnum.AdType.RESPONSIVE_SEARCH_AD}>
                {Object.keys(adsType).map(el => <option key={el} value={parseInt(el)}>{adsType[el]}</option>)}
            </select>}

          <div className="previewBox">
            <span className="previewBox-title">Xem trước</span> • Ví dụ minh hoạ
            <h4 className="mt-3">Điện thoại</h4>
            <div className="mobile-preview">
                <div className="project-name"><strong>Quảng cáo</strong>   •   {url.hostname}</div>
              <div className="ad-title">{renderCapitalize(watchField[index]?.adGroupAd.ad.headlines, ' | ')}</div>
              <div className="ad-description">{renderCapitalize(watchField[index]?.adGroupAd.ad.descriptions, '. ', 122)}</div>
            </div>
            <h4>Máy tính</h4>
            <div className="desktop-preview">
                <div className="project-name"><strong>Quảng cáo</strong>   •   {url.hostname}</div>
              <div className="ad-title">{renderCapitalize(watchField[index]?.adGroupAd.ad.headlines, ' | ')}</div>
              <div className="ad-description">{renderCapitalize(watchField[index]?.adGroupAd.ad.descriptions, '. ', 122)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
      {groupShouldShow.removeAds && <div className=" add-extend-link" style={{ borderTop: 'none', color: '#ff3b30', paddingBottom: 10 }} onClick={props.handleRemove}>
        Xóa mẫu quảng cáo
          </div>}
        </div>
    );

    const renderAdinGroup = () => (
      <div className="col-lg-12" style={{ borderTop: props.chain?.prev ? 'solid 1px #d8d8d8' : 'none', padding: '20px', paddingTop: props.chain?.prev ? '20px' : 'initial' }}>
        <div className="ad-template__title w-100 cursor-pointer" onClick={onToggleAd}>
          Mẫu quảng cáo {(props.chain?.list[index].indexOf(index) || 0) + 1}
          <div className="section-toogle">
            <ExpandCollapseIcon state={false} />
          </div>
        </div>
        {(errors.adgroupad && errors.adgroupad[index]) ?
          <div className="input-group">
            <span><CollapseError /></span><ErrorInput style={{ marginBottom: 0 }} text="Cần khắc phục vấn đề thiết lập quảng cáo" />
          </div>
          : null}
      </div>
    );
    
    const renderCollapseContentUI = () => {
      if (groupShouldShow.isExpanding) return;
      return renderAdinGroup();
    };
    
  if (!groupShouldShow.isExpandingGroup && !groupShouldShow.title) return <></>;
    return (<>
      <ReactModal isOpen={openModal} onRequestClose={() => setOpenModal(false)} style={customModalStyles}>
        <div className="modal-btn-close" onClick={() => setOpenModal(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" /> <path fill="#000" fillRule="nonzero" d="M9.697 8l4.55 4.55c.186.185.294.362.35.546.056.184.056.372 0 .556-.056.184-.164.36-.35.546l-.049.048c-.185.187-.362.295-.546.35-.184.057-.372.057-.556 0-.184-.055-.36-.163-.546-.349L8 9.696l-4.55 4.552c-.185.186-.362.294-.546.35-.184.056-.372.056-.556 0-.184-.056-.36-.164-.546-.35l-.048-.049c-.187-.185-.295-.362-.35-.546-.057-.184-.057-.372 0-.556.055-.184.163-.36.349-.547L6.304 8 1.752 3.45c-.186-.185-.294-.362-.35-.546-.056-.184-.056-.372 0-.556.056-.184.164-.36.35-.546l.049-.048c.185-.187.362-.295.546-.35.184-.057.372-.057.556 0 .184.055.36.163.547.349L8 6.304l4.55-4.55c.185-.186.362-.294.546-.35.184-.056.372-.056.556 0 .184.056.36.164.546.35l.048.048c.187.186.295.363.35.547.057.184.057.372 0 .556-.055.184-.163.36-.349.546L9.697 8z" /> </g> </svg>
        </div>
        <div className="transfer-modal__container">
          <h3 className="text-center">Xác nhận</h3>
          <div className="transfer-modal__content">
            Khi thay đổi loại quảng cáo, các thông tin bạn đã nhập vào mẫu quảng cáo hiện tại sẽ bị xoá.
          </div>
        </div>
        <div className="d-flex justify-content-end mr-3">
          <CancelButton onClick={switchAdType}>Đồng ý</CancelButton>
          <div style={{ width: 10 }}></div>
          <SubmitButton onClick={() => setOpenModal(false)}>Huỷ</SubmitButton>
        </div>
      </ReactModal>
      <div className={classNames('row')}>
        {renderFullContentUI()}
        {renderCollapseContentUI()}
        </div>
      </>
    );
  }
  
  
  interface InputLengthPropsType {
    text: string;
    max: number;
    className?: string;
  }
  
  export const InputLength: React.FC<InputLengthPropsType> = (props) => {
    const {text, max} = props;
    const len = text ? text.length : 0;
    const className = props.className ? props.className : 'text-field--input-counter';
    return (<>
      {len >= max ? <div className={className} style={{color: 'red'}}>{len + '/' + max}</div> : <div className={className}>{`${len} / ${max - 1}`}</div>}
    </>);
  };