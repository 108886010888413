import { CropPoints } from 'core';

export default class ImageSourceUrl {
  private _name?: string;
  private _domain?: string;

  constructor(url?: string) {
    if (!url) {
      return;
    }
    const urlText = url.replace('http://', '').replace('https://', '');
    const parts = urlText.split('/');
    
    if (parts.length >= 3) {
      this._name = parts[parts.length - 1];
      this._domain = `https://${parts[0]}/${parts[1]}/`;
    }
  }

  private getSizeParam(imageWidth?: number, imageHeight?: number) {
    if (imageWidth && imageHeight) {
      return `${imageWidth}x${imageHeight}/`;
    }
    if (imageHeight) {
      return `x${imageHeight}/`;
    }
    if (imageWidth) {
      return `${imageWidth}x/`;
    }
    return '';
  }

  private getCropParam(width?: number, height?: number, cropArea?: CropPoints) {
    if (!cropArea) {
      return '';
    }

    if (width) {
      return `filters:mmjcrop(${cropArea.y},${cropArea.x},${cropArea.width},${cropArea.height},${width},x)/`;
    }
    return `filters:mmjcrop((${cropArea.y},${cropArea.x},${cropArea.width},${cropArea.height},x,${height})/`;
  }

  getOriginalUrl(): string {
    return this.getImageUrl();
  }

  getImageUrl(width?: number, height?: number, cropArea?: CropPoints): string {
    if (!this._domain) {
      return '';
    }
    if (cropArea) {
      return `${this._domain}${this.getCropParam(width, height, cropArea)}${this._name}`;
    }
    return `${this._domain}${this.getSizeParam(width, height)}${this._name}`;
  }
}
