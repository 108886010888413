import * as React from 'react';
import { Link, Prompt, useParams, useLocation, useHistory, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { AppState } from 'core';
import { SideMenu, TopMenu } from 'pages/menu';
import { LoadingScreen } from 'components/common/LoadingScreen';
import { ProjectTabs } from './ProjectTabs';
import { useFetchProject, useRouter } from 'utils/hooks';
import { History } from 'src/route/Route';
import { toast } from 'react-toastify';
import { apiErrorCodeGlobal } from 'core';

type PropsType = ReturnType<typeof mapStateToProps> & {}

type LocationState = {
  fromChooseProject?: boolean;
} | undefined;

interface ProjectParams {
  id: number;
}

const EditProject: React.FC<PropsType> = (props: PropsType) => {
  const { project } = props;
  const history = new History();

  const router = useRouter();
  const { id: projectId } = router.query as ProjectParams;
  const state = router.location as LocationState;
  const fromChooseProject = state?.fromChooseProject;
  const [ loading, setLoading ] = React.useState(true);
  const { status, error } = useFetchProject(projectId);

  React.useEffect(()=>{
    if(error && error['code']){
      const message = apiErrorCodeGlobal[error['code']] ? apiErrorCodeGlobal[error['code']] : 'Không lấy được thông tin dự án.';
      toast.error(message);
    }
  }, [error]);
  
  if(!project && status !== 'error') return <LoadingScreen />;
  if(!project && status === 'error' && error['code'] === 106) return <Redirect push to={{pathname: history.url('/forbidden'), }} />;
  if(!project) return <Redirect push to={{pathname: history.url('/project-management'), }} />;

  
  const goBackHistory = () => {
    history.go(-1);
  };

  const projectDesc = () => {
    // if(!project.isOwnership())
    //   return <>Bạn chỉ có thể xem thông tin dự án. Bạn có thể xem landing page của dự án này{' '}
    //       <Link to={(globalThis as any).useHistory.url('/landing-management')}>tại đây.</Link></>;

    return <>Xem và chỉnh sửa dự án. Bạn có thể xem landing page của dự án này{' '}
          <Link to={(globalThis as any).useHistory.url('/landing-management')}>tại đây.</Link></>;
  };

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <div className="main-content">
        <div className="page-heading">
          <div className="d-flex justify-content-start align-items-start">
            <span className="goBackLink" onClick={goBackHistory}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path fill="#FFF" d="M0 0H24V24H0z" opacity=".01" />
                  <path
                    fill="#000"
                    d="M8.004 14.124L6.94 13.06c-.282-.281-.44-.663-.44-1.061 0-.398.158-.78.44-1.061l1.05-1.05v-.001l5.6-5.6 1.797-1.798.022-.021.03-.03c.213-.2.418-.321.63-.386.23-.07.466-.07.696 0 .23.07.45.205.683.437l.06.061c.232.232.368.453.438.683.07.23.07.465 0 .695-.077.238-.21.454-.387.63L10.12 12l7.44 7.44c.2.213.321.418.386.63.07.23.07.466 0 .696-.07.23-.206.45-.438.683l-.06.06c-.232.232-.453.368-.683.438-.23.07-.465.07-.695 0-.238-.077-.454-.21-.63-.387l-7.437-7.435.001-.001z"
                  />
                </g>
              </svg>
            </span>
            <div>
              <h1>{project && project.name ? project.name : 'Untitled'}</h1>
              <p>{projectDesc()}</p>
            </div>
          </div>
        </div>
        <ProjectTabs
          isEdit
          project={project}
          projectType={project.type_code}
          fromChooseProject={fromChooseProject}
        />
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background-color: #f5f4f9;

`;

const mapStateToProps = (state: AppState) => {
  return {
    project: state.projects.selectedProject
  };
};

const EditProjectPage = connect(mapStateToProps)(EditProject);
export default EditProjectPage;
