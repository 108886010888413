"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectSagas = exports.validateDeleteProjectSaga = exports.deleteImageSaga = exports.deleteProjectSaga = exports.updateProjectSaga = exports.createProjectSaga = exports.getProjectSaga = exports.uploadImageSaga = exports.getProjectImageSaga = void 0;
const effects_1 = require("redux-saga/effects");
const class_transformer_1 = require("class-transformer");
const entities_1 = require("../../entities/");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
function* getProjectImageSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/pictures_library`,
            authenticated: true,
            params: {
                project_id: params.projectId
            }
        };
        const imagesResponse = yield effects_1.call(api_1.callApi, request);
        const images = imagesResponse.data.data
            ? imagesResponse.data.data.map((pic) => new entities_1.Image(pic.filename, '', pic.org_filename, pic.id, pic.category_code)) : [];
        yield effects_1.put({
            type: types_1.GetProjectImageAction.success,
            images,
            projectId: params.projectId,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetProjectImageAction.error,
            error: true,
            payload,
            meta: action.meta,
        });
    }
}
exports.getProjectImageSaga = getProjectImageSaga;
function* uploadImageSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_UPLOAD_URL}/upload_photo/upload`,
            authenticated: true,
            data: params
        };
        const uploadResponse = yield effects_1.call(api_1.callApi, request);
        const image = new entities_1.Image(uploadResponse.data.data.src, '', uploadResponse.data.data.org_filename, uploadResponse.data.data.id, uploadResponse.data.category_code);
        yield effects_1.put({
            type: types_1.UploadImageAction.success,
            payload: image,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.UploadImageAction.error,
            error: true,
            payload,
            meta: action.meta,
        });
    }
}
exports.uploadImageSaga = uploadImageSaga;
function* getListProjectsSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/projects`,
            authenticated: true,
            params
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        const projects = responseData.data.projects.map((project) => {
            return class_transformer_1.plainToClass(entities_1.Project, project);
        });
        let actionType = types_1.GetListProjectsAction.allSuccess;
        const projectType = params && params.project_type;
        switch (projectType) {
            case entities_1.ProjectCategories.user:
                actionType = types_1.GetListProjectsAction.userSuccess;
                break;
            case entities_1.ProjectCategories.system:
                actionType = types_1.GetListProjectsAction.systemSuccess;
                break;
            default:
                actionType = types_1.GetListProjectsAction.allSuccess;
                break;
        }
        yield effects_1.put({ type: actionType, projects });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetListProjectsAction.error,
            error: true,
            payload,
            meta: action.meta,
        });
    }
}
function* getProjectSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/project`,
            authenticated: true,
            params: {
                id: params.projectId
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        const project = class_transformer_1.plainToClass(entities_1.Project, responseData.data);
        yield effects_1.put({ type: types_1.GetProjectAction.success, project: project });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.GetProjectAction.error,
            error: true,
            payload: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
exports.getProjectSaga = getProjectSaga;
function* createProjectSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/create_project`,
            authenticated: true,
            data: Object.assign(Object.assign({}, params.project), { template_id: params.projectTemplateId })
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        responseData.data.has_pictures = true;
        const payload = {
            project: class_transformer_1.plainToClass(entities_1.Project, responseData.data)
        };
        yield effects_1.put({
            type: types_1.CreateProjectAction.success,
            meta: action.meta,
            payload
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.CreateProjectAction.error,
            error: true,
            payload: error.response && error.response.data && error.response.data.error,
            meta: action.meta
        });
    }
}
exports.createProjectSaga = createProjectSaga;
function* updateProjectSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/update_project`,
            authenticated: true,
            data: Object.assign({}, params.project)
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        const project = class_transformer_1.plainToClass(entities_1.Project, responseData.data);
        yield effects_1.put({
            type: types_1.UpdateProjectAction.success,
            project: project,
            payload: { project },
            meta: action.meta
        });
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.UpdateProjectAction.error,
            error: true,
            meta: action.meta,
            payload: error.response && error.response.data && error.response.data.error
        });
    }
}
exports.updateProjectSaga = updateProjectSaga;
function* deleteProjectSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/project`,
            authenticated: true,
            params: {
                id: params.projectId
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.DeleteProjectAction.success,
            projectId: params.projectId
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.DeleteProjectAction.error,
            error: true,
            payload,
            meta: action.meta,
        });
    }
}
exports.deleteProjectSaga = deleteProjectSaga;
function* deleteImageSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_UPLOAD_URL}/upload_photo/delete`,
            authenticated: true,
            data: {
                photo: params.photo
            }
        };
        yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.DeleteImageAction.success,
            imageId: params.photo.imageId,
            projectId: params.projectId,
            meta: action.meta,
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.DeleteImageAction.error,
            error: true,
            payload,
            meta: action.meta,
        });
    }
}
exports.deleteImageSaga = deleteImageSaga;
function* validateDeleteProjectSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/validate_delete_project`,
            authenticated: true,
            data: {
                id: params.projectId
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        const responseData = response.data;
        yield effects_1.put({
            type: types_1.ValidateDeleteProjectAction.success,
            payload: responseData,
            meta: action.meta,
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.ValidateDeleteProjectAction.error,
            error: true,
            payload,
            meta: action.meta,
        });
    }
}
exports.validateDeleteProjectSaga = validateDeleteProjectSaga;
exports.projectSagas = [
    effects_1.takeEvery(types_1.UploadImageAction.type, uploadImageSaga),
    effects_1.debounce(100, types_1.GetListProjectsAction.type, getListProjectsSaga),
    effects_1.takeLatest(types_1.GetProjectAction.type, getProjectSaga),
    effects_1.takeLatest(types_1.CreateProjectAction.type, createProjectSaga),
    effects_1.takeLatest(types_1.UpdateProjectAction.type, updateProjectSaga),
    effects_1.takeLatest(types_1.DeleteProjectAction.type, deleteProjectSaga),
    effects_1.takeLatest(types_1.GetProjectImageAction.type, getProjectImageSaga),
    effects_1.takeEvery(types_1.DeleteImageAction.type, deleteImageSaga),
    effects_1.takeEvery(types_1.ValidateDeleteProjectAction.type, validateDeleteProjectSaga),
];
