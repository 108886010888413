import * as React from 'react';
import { useState } from 'react';

interface ImageProps {
  src: string;
}

const ReiImage: React.FC<ImageProps> = (props) => {
  const [errorCount, setErrorCount] = useState(0);

  const onError = () => {
    if (props.src.substring(0, 4) !== 'http' || errorCount === 5) return;
    setTimeout(() => {
      setErrorCount(prevState => prevState + 1);
    }, 2000);
  };
  return(
    <img
      src={errorCount > 0 ? `${props.src}?reload=${errorCount}` : `${props.src}`}
      onError={onError}
      alt=""
    />
  );
};

export default ReiImage;
