"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedTeam = exports.TeamMembers = exports.Team = exports.PendingInvite = void 0;
class PendingInvite {
    constructor(id, email, created_at) { }
}
exports.PendingInvite = PendingInvite;
class Team {
    constructor(id, name, domain, num_members, license_infor) {
        this.id = id;
        this.name = name;
        this.domain = domain;
        this.num_members = num_members;
        this.license_infor = license_infor;
    }
}
exports.Team = Team;
class TeamMembers extends Array {
    getOwner() {
        // this.roles.map((role: any)=>role.code).includes('owner')
    }
    getMemberByType(type) {
        // this.roles.map((role: any)=>role.code).includes(type)
    }
    getMemberQuantity() {
        return this.length;
    }
}
exports.TeamMembers = TeamMembers;
class SelectedTeam {
    constructor(token, team, user) {
        this.token = token;
        this.team = team;
        this.user = user;
    }
    getTeamId() {
        return this.team && this.team.id || null;
    }
    getTeamName() {
        return this.team && this.team.name || '';
    }
    getTeamDomain() {
        if (this.team && this.team.domain && process.env.REACT_APP_DOMAIN) {
            const { domain } = this.team;
            return `${domain}.${process.env.REACT_APP_DOMAIN}`;
        }
        return process.env.REACT_APP_DOMAIN || '';
    }
    getUserRoles() {
        const roles = this.user && this.user.roles || [];
        return roles;
        // if(isEveryRoleAvail){
        //   return contains.every((contain: RoleCode) => roles.map((el: Role)=>el.code).includes(contain))
        // }else{
        //   const res = contains.some((contain: RoleCode) => roles.map((el: Role)=>el.code).includes(contain))
        //   console.log(res, contains, roles)
        //   return res
        // }
    }
    checkUserRole(contains, isEveryRoleAvail = false) {
        const roles = this.user && this.user.roles || [];
        if (!contains)
            return isEveryRoleAvail;
        if (isEveryRoleAvail) {
            return contains.every((contain) => roles.map((el) => el.code).includes(contain));
        }
        else {
            return contains.some((contain) => roles.map((el) => el.code).includes(contain));
        }
    }
}
exports.SelectedTeam = SelectedTeam;
