import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { Project } from 'core';

type UserProjectResultProps = {
  projects: Array<Project>;
};

type LocationState =
  | {
      fromCreateProject?: boolean;
    }
  | null
  | undefined;

const UserProjectResult: React.FC<UserProjectResultProps> = (props: UserProjectResultProps) => {
  const { projects } = props;
  const { state }: Location<LocationState> = useLocation();
  const fromCreateProject = state && state.fromCreateProject;

  if (!projects.length) return null;

  return (
    <div className="list-projects">
      <h4>Dự án của bạn</h4>
      <div className="row">
        {projects.map((project, index: number) => {
          const link = project.isFulfilledProject()
            ? `/create-landingpage/${project.id}`
            : `/edit-project/${project.id}`;
          return (
            <Link
              key={index}
              to={{
                pathname: (globalThis as any).useHistory.getPath(link),
                state: { fromChooseProject: true },
              }}
              className="col-lg-3"
            >
              {!project.thumbnail && <img className="img-fluid" src="https://via.placeholder.com/361x226" alt="" />}
              {project.thumbnail && (
                <img
                  className="img-fluid"
                  src={`${process.env.REACT_APP_CDN_URL}/361x226/${project.thumbnail}`}
                  alt=""
                />
              )}
              <div className="project-title">{project.name}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default UserProjectResult;
