import * as React from 'react';
import styled from 'styled-components';
import { Project, ProjectTypes } from 'core';
import TextareaAutosize from 'react-autosize-textarea';
import 'stylesheets/App.css';

const Styles = styled.div`
  label {
    margin: 20px 0 10px;
    font-family: Propins;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #8e8e93;
  }

  .form-control {
    font-family: Propins;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #000000;
    padding: 10px 15px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
  }

  .form-control::placeholder {
    color: #aeaeb2;
  }
  @media (max-width: 1300px) {
    .project-infor-form {
      min-height: auto;
    }
    .form-control {
      padding: 8px 10px;
      height: 36px;
      font-size: 14px;
    }
    label {
      font-size: 12px;
      margin: 15px 0 8px;
    }
  }
`;

type PropsType = {
  project?: Project;
  handleInputChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  projectType: ProjectTypes;
}

export function ProjectPolicyForm(props: PropsType) {
  const { project } = props;
  let guideText = '';
  switch (props.projectType) {
    case ProjectTypes.LAND:
    case ProjectTypes.CONDOTEL:
      guideText = 'Bạn hãy miêu tả về chính sách bán hàng và bảng giá của dự án này';
      break;
    case ProjectTypes.APARTMENT:
    case ProjectTypes.SHOPHOUSE_VILLA:
    default:
      guideText = 'Bạn hãy liệt kê các điểm nổi bật trong chính sách bán hàng của dự án này';
      break;
  }

  return (
    <Styles>
      <div className="project-infor-form justify-content-between flex-column row m-0">
        <div className="col-12">
          <label>
            {guideText} <span className="label-required"> *</span>
          </label>
          <TextareaAutosize
            name="payment_policy"
            autoFocus={true}
            rows={3}
            className="form-control"
            placeholder="VD: + Tặng miễn phí gói smarthome trị giá 300 triệu khi mua căn hộ Vinhomes
              + Hỗ trợ đưa khách hàng xem đúng căn hộ thực tế sẽ mua trước khi quyết định mua nhà.
              + Chương trình hỗ trợ lãi suất năm đầu tiên cho khách hàng có nhu cầu vay ngân hàng khi mua căn hộ.. "
            defaultValue={project ? project.payment_policy : ''}
            onChange={props.handleInputChange}
          />
        </div>
      </div>
    </Styles>
  );
}
