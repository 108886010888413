import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { GoogleAdsEnum, IDevice } from 'core';
import { FieldErrors } from 'react-hook-form';
import classNames from 'classnames';
import { ErrorInput } from '../fields';

interface PropTypes{
  onChange: (value: IDevice[]) => void;
  value?: IDevice[];
  errors: FieldErrors;
}

interface InitType {
  value: number;
  text: string;
  label: string;
  hidden: boolean;
  device: IDevice;
}

const Label = styled.label`
  &.error{
    color: red !important;
  }
`;

export const DeviceField: React.FC<PropTypes> = (props) => {
  const initInput: InitType[] = [{
    value: 0,
    text: 'device-mobile',
    label: 'Điện thoại',
    hidden: false,
    device: {
      type: GoogleAdsEnum.Device.MOBILE,
    }
  },
  {
    value: 0,
    text: 'device-tablet',
    label: 'Máy tính bảng',
    hidden: false,
    device: {
      type: GoogleAdsEnum.Device.TABLET,
    }
  },
  {
    value: 0,
    text: 'device-desktop',
    label: 'Máy tính',
    hidden: false,
    device: {
      type: GoogleAdsEnum.Device.DESKTOP,
    }
  }];

  const importData = (input?: InitType[]): InitType[] => {
    const ret = input ?? initInput;
    props.value?.map(el=>{
      const item = ret.find(r=>r.device.type === el.type);
      if(item) {
        item.value = 1;
        item.device = el;
      }
    });
    
    return ret;
  };
  
  const exportData = (input: InitType[]): IDevice[] => {
    const ret = input?.filter(el=>el.value).map(el=>{
      return el.device;
    });

    return ret;
  };

  const renderInput: InitType[] = importData();
  
  useEffect(() => {
    if(!props.value) {
      props.onChange(exportData(initInput));
    }
  }, [props.value]);
  
  const handleChange = (item: InitType) => {
    const data = renderInput;
    const i = data.find(el=>el.device.type == item.device.type);
    if(i) i.value = i.value ? 0 : 1;
    // console.log(data);
    props.onChange(exportData(data));
  };
  
  
  return (<>
    <div className="input-group mt-3">
      <label>Thiết bị</label>
      {initInput.map((item, id)=><div key={item.text} 
           className={classNames('form-check', {'mr-3': initInput.filter(el=>!el.hidden).length > id, 'hidden': item.hidden})}>
        <div>
          <input id={item.text} className="form-check-input" type="checkbox" 
              value={item.value}
              checked={!!item.value}
              onChange={(e)=>handleChange(item)} />
          <Label className={classNames('form-check-label', {'error': props.errors.devices})} htmlFor={item.text}>{item.label}</Label>
        </div>
      </div>)}
    </div>
    {props.errors.devices && <ErrorInput text={props.errors.devices.message}/>}
    </>
  );
};