import { apiErrorCodeGlobal, ITeamState } from 'core';
import { ApiErrorMsg as AuthApiErrorMsg, EmailService, FieldType as AuthFT, InputErrorMsg as AuthIEM } from 'src/pages/auth/service';

export default class TeamService {
  constructor(){}

  static validateEmailFormat(email: string): boolean {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  static validateLinkFormat(text: string): boolean {
    const reg = /^(?=.{2,32})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
    return reg.test(text);
  }

  static hasEnoughType(text: string): boolean {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
    return reg.test(text);
  }

  static hasEnoughCharacter(text: string): boolean {
    const reg = /^(?=.{2,32})/;
    return reg.test(text);
  }

  static validateArrayEmailFormat(emails: EmailsInvite[]){
    if(emails.length > 0 ){
      return emails.every((el: EmailsInvite)=>{
        TeamService.validateEmailFormat(el.value);
      });
    }
    return false;
  }
}

enum AuthInputErrorMsg {
  InvalidName = 'Tên nhóm không hợp lệ.',
  InvalidLink = 'Link không hợp lệ.',
  InvalidEmail = 'Email không hợp lệ.',
}
export const InputErrorMsg = {...AuthInputErrorMsg, ...AuthIEM };
export type InputErrorMsg = AuthInputErrorMsg | AuthIEM;

enum TeamFT {
  TeamName = 'TeamName',
  TeamMember = 'TeamMember',
  TeamLink = 'TeamLink',
  Emails = 'Emails'
}

export const FieldType = { ...TeamFT, ...AuthFT };
export type FieldType = TeamFT | AuthFT;
export type EmailsInvite = { label: string; value: string };
export interface State { team: ITeamState }
export const ApiErrorMsg = {
  ...AuthApiErrorMsg,
  ...apiErrorCodeGlobal,
  // Tên nhóm không hợp lệ. Vui lòng chọn nhóm khác.
  // Đường link doanh nghiệp không hợp lệ. Vui lòng chọn đường link khác.
};
