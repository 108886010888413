import { CropPoints } from 'core';

export enum ExportMode {
  mode_1_1 = 'mode_1_1',
  mode_191_1 = 'mode_191_1',
  mode_4_5 = 'mode_4_5'
}

export enum FrameType {
  Frame1 = 'Frame1',
  Frame2 = 'Frame2',
  Frame3 = 'Frame3',
  Frame4 = 'Frame4'
}

export interface Point {
  readonly x: number;
  readonly y: number;
}

export interface Area {
  topLeft: Point;
  bottomRight: Point;
}

export interface RelativeArea {
  centerTop?: Point;
  topLeft?: Point;
  topRight?: Point;
  width?: number;
  height: number;
}

export interface ImageSource {
  source: string;
  cropArea?: Area;
  drawArea?: Area;
  drawRelativeArea?: RelativeArea;
}

export interface Font {
  name: string;
  size: number;
}

export interface TextSource {
  text: string;
  centerPoint: Point;
  maxWidth: number;
  font: Font;
  colors: Array<string>;
}

export interface Size {
  width: number;
  height: number;
}

export enum ImageType {
  SINGLE_IMAGE = 'SINGLE_IMAGE',
  CAROUSEL = 'CAROUSEL'
}

export interface AdFrameState {
  bkImageSrc: string;
  title: string;
  body: string;
  cropArea?: CropPoints;
}

export interface AdState {
  isEditing: boolean;
  exportMode: ExportMode;
  imageType: ImageType;
  frameType: FrameType;
  logoImageSrc?: string;
  frameStates?: Array<AdFrameState>;
}

export enum ComponentType {
	header = 'header',
	policy = 'policy',
	projectLocation = 'projectLocation',
	projectGround = 'projectGround',
	utility = 'utility'
}
