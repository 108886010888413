"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentSagas = exports.getPricingInfoSaga = exports.fetchPaymentHistorySaga = exports.getBankTransferInfoSaga = exports.createShoppingCartSaga = exports.fetchMethodsListSaga = exports.fetchPackagesListSaga = void 0;
const effects_1 = require("redux-saga/effects");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
function* fetchPackagesListSaga(action) {
    try {
        // const params: FetchPackagesListIP = action.params as FetchPackagesListIP;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/payment/packages`,
            authenticated: true,
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.FetchPackagesList.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.FetchPackagesList.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchPackagesListSaga = fetchPackagesListSaga;
function* fetchMethodsListSaga(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/payment/methods`,
            authenticated: true,
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.FetchMethodsList.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.FetchMethodsList.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchMethodsListSaga = fetchMethodsListSaga;
function* createShoppingCartSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/payment/shopping_cart`,
            authenticated: true,
            data: params,
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.CreateShoppingCart.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.CreateShoppingCart.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.createShoppingCartSaga = createShoppingCartSaga;
function* getBankTransferInfoSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/payment/bank_transfer_info`,
            authenticated: true,
            params: {
                shopping_cart_id: params.shopping_cart_id,
                team_id: params.team_id,
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetBankTransferInfo.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetBankTransferInfo.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getBankTransferInfoSaga = getBankTransferInfoSaga;
function* fetchPaymentHistorySaga(action) {
    try {
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/payment/payment_history`,
            authenticated: true,
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.FetchPaymentHistory.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.FetchPaymentHistory.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.fetchPaymentHistorySaga = fetchPaymentHistorySaga;
function* getPricingInfoSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/payment/pricing_info`,
            authenticated: true,
            params: {
                package_id: params.package_id,
                team_id: params.team_id,
                payment_type: params.payment_type,
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.GetPricingInfo.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.GetPricingInfo.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getPricingInfoSaga = getPricingInfoSaga;
exports.paymentSagas = [
    effects_1.takeLatest(types_1.FetchPackagesList.type, fetchPackagesListSaga),
    effects_1.takeLatest(types_1.FetchMethodsList.type, fetchMethodsListSaga),
    effects_1.takeLatest(types_1.CreateShoppingCart.type, createShoppingCartSaga),
    effects_1.takeLatest(types_1.GetBankTransferInfo.type, getBankTransferInfoSaga),
    effects_1.takeLatest(types_1.FetchPaymentHistory.type, fetchPaymentHistorySaga),
    effects_1.takeLatest(types_1.GetPricingInfo.type, getPricingInfoSaga),
];
