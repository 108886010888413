import React from 'react';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';

export const LoadingScreen: React.FC<{text?: string}> = (props) => {
  return <><PulseLoader css={LoaderStyles} size={15} color={'#666666'} loading={true} />
  {props.text && <div style={{margin: '0 auto', width: 500, textAlign: 'center', transform: 'translateY(40vh)'}}>{props.text}</div>}
  </>;
};

const LoaderStyles = css`
  transform: translateY(40vh);
  text-align: center;
`;