import Button from 'components/buttons/Button';
import { PackageCode } from 'core';
import { ButtonProps } from 'presenter';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');
interface ExpandButtonProps extends ButtonProps {
  packageName?: string;
  packageCode?: string;
  packageDescription?: string;
  packagePermission?: Array<string>;
  customButtonStyle?: string;
  customStyles?: object;
}
const ExpandButton: React.FC<ExpandButtonProps> = (props) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const mounted = useRef(false);

  const cricleItem = <span className="cricleBullet rounded-circle"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H10.667V10.667H0z" opacity=".01" transform="translate(-222 -648) translate(205 395) translate(15 247) translate(0 3) translate(2.667 3)"/> <path fill="#4C7CF3" fillRule="nonzero" d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z" transform="translate(-222 -648) translate(205 395) translate(15 247) translate(0 3) translate(2.667 3)"/> </g> </g> </g> </g> </g> </g> </svg></span>;
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    if (!mounted.current) return;
    setIsOpen(false);
  };

  const renderText = () => {
    if (props.packageCode == PackageCode.STARTER) return 'Tất cả quyền lợi của gói Gói Miễn phí, và:';
    if (props.packageCode == PackageCode.PRO) return 'Tất cả quyền lợi của gói Gói Cơ bản, và:';
    return null;
  };

  return (
    <React.Fragment>
      <Button className={props.customButtonStyle} onClick={openModal}>
        {props.children}
      </Button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={props.customStyles}
      >
        <div className="modal-title">
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-1245 -18) translate(1010) translate(227 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal-wrapper flex-1">
          <h1>{props.packageName}</h1>
          <p>{props.packageDescription}</p>
          <div className="gray-text">{renderText}</div>
          <ul className="list-unstyled">
            {props.packagePermission && props.packagePermission.map((p, index) => (<li key={index}>{cricleItem}{p}</li>))}
          </ul>
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

ExpandButton.defaultProps = {
  customButtonStyle: 'button-link',
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 99
    },
    content: {
      top: '0',
      left: 'auto',
      right: '0',
      bottom: '0',
      border: '0',
      maxWidth: '270px',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(0, 0)',
    },
  },
};

export default ExpandButton;
