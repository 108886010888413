import { useHistory } from 'react-router-dom';
import { LocationDescriptor, Path, LocationState, LocationDescriptorObject } from 'history';
import { generatePath } from 'react-router';

export class RoutePath {
  page: any;
  showStatic: boolean;
  prefixPath: string;

  constructor(showStatic = false){
    this.prefixPath = process.env.REACT_APP_PUBLIC_URL || '';
    this.showStatic = showStatic;
    this.page = {
      default: '/',
      Home: '/home',
      Dashboard: '/',
      LoginPage: '/login',
      LogoutPage: '/logout',
      TeamLoginPage: '/team-login',
      RegisterPage: '/register',
      ResetPasswordPage: '/forgot-pass',
      VerifyEmailSuccess: '/verify-email-success',
      LinkExpiredPage: '/link-expired',
      PermissionRequired: '/forbidden',
      VerifyPage: '/verify',
      TransferOwnershipPage: '/transfer-ownership',
      SentConfirmEmailNoti: '/sent-confirm-email-noti',
      ProjectsManagementPage: '/project-management',
      LandingManagementPage: '/landing-management',
      CustomerManagementPage: '/customer-management',
      AdsCreationPage: '/ads-creation',
      EditMediaAdsPage: '/edit-ads-media',
      // AnalyticsManagement: '/analytics-management',
      DemoChart: '/demo-chart',
      ResetPasswordPageRecover: '/recover-password/:token',
      VerifyEmail: '/verify-email/:token/:user_id',
      JoinTeam: '/join/:token',
      CreateProjectPage: '/create-project/:projectType',
      CloneProject: '/clone-project/:id',
      EditProjectPage: '/edit-project/:id',
      CreationPreviewPage: '/ads-preview/:type',
      PackageInfoPage: '/payment/package-info',
      PricingPage: '/payment/pricing-page',
      PaymentMethodPage: '/payment/payment-method',
      CreateLandingPage: '/create-landingpage/:pid',
      // DetailAnalyticsPage: '/analytics/:type/:lpId',
      // AnalyticDashboardPage: '/analytics-dashboard/:lpId',
      // DetailRealtime: '/analytics-realtime/:lpId',
      // ChannelAnalytics: '/channel-analytics/:lpId/:cId',
      // CampaignAnalytics: '/campaign-analytics/:lpId/:cId/:campaign',
      CreateTeamPage: '/create-team/:step?',
      ChooseProjectTemplatePage: '/choose-project/:url_referrer?',
      LandingManagementPageVerify: '/home/:isVerify?',
      // InitialGoogleCampaign: '/init-google-campaign',
      CreateGoogleCampaign: '/create-google-campaign',
      EditGoogleCampaign: '/edit-ads-campaign/:resource',
      AccountAdsManagementPage: '/account-ads-management',
      GoogleAdsDashboard: '/google-ads-dashboard',
      GoogleAdsSettings: '/google-ads-setting',
      GoogleAdsOptimization: '/ads-optimization',
      ConnectBoards: '/connect-account',
      ConnectFacebook: '/connect-facebook',
      ConnectAdsAccount: '/connect-ads/:userToken/:maxAccountConnectable/:maxAllowAccounts/:callbackOriginlocation',
    };
  }

  checkComponent(page: string): boolean{
    const pages = Object.keys(this.page);
    return pages.includes(page);
  }

  checkPath(path: string): boolean{
    const pages = Object.values(this.page).map((el: string)=>this.trimParams(el, true));
    return pages.includes(path);
  }

  //isStatic: show only relative path, without any variable inside the path
  //input: '/campaign-analytics/:lpId/:cId/:campaign'
  //output: '/campaign-analytics'
  component(component: string, isStatic?: boolean){
    if(!this.checkComponent(component)) {
      throw new Error('Khong tim thay duong dan ' + component);
      return '';
    }

    const path = this.page[component] ? this.page[component] : this.page.default;
    const wLeadingSlash = path[0] && path[0] === '/' ? path : '/' + path;

    const staticMode = isStatic !== undefined ? isStatic : this.showStatic;
    const wPathVariable = staticMode ? this.getStaticPath(wLeadingSlash) : wLeadingSlash;
    return this.prefixPath + wPathVariable;
  }

  url(path: string){
    if(!this.checkPath(path)) {
      throw new Error('Khong tim thay duong dan ' + path);
      return '';
    }

    return this.getFullPath(path);
  }

  mainUrl(path: string){
    if(!this.checkPath(path)) {
      throw new Error('Khong tim thay duong dan ' + path);
      return '';
    }

    const wDoublePrefix = this.filterDoublePublicUrl(path, 'http://' + process.env.REACT_APP_DOMAIN);
    const respath = 'http://' + process.env.REACT_APP_DOMAIN + wDoublePrefix;
    return respath;
  }

  //input: /login/:teamId
  //output: /dashboard/login where '/dashboard' is a prefix for example
  getPath(path: string, isStatic?: boolean){
    const staticMode = isStatic !== undefined ? isStatic : this.showStatic;
    const wDoublePrefix = this.filterDoublePublicUrl(path);
    const wLeadingSlash = wDoublePrefix[0] && wDoublePrefix[0] === '/' ? wDoublePrefix : '/' + wDoublePrefix;
    const wPathVariable = staticMode ? this.getStaticPath(wDoublePrefix) : wDoublePrefix;
    return this.prefixPath + wPathVariable;
  }

  //input: /login/:teamId
  //output: /dashboard/login/:teamId where '/dashboard' is a prefix for example
  getFullPath(path: string): string{
    if(!path) return this.prefixPath;
    const wDoublePrefix = this.filterDoublePublicUrl(path);
    const wLeadingSlash = wDoublePrefix.startsWith('/') ? wDoublePrefix : '/' + wDoublePrefix;
    return this.prefixPath + wDoublePrefix;
  }

  filterDoublePublicUrl(path: string, startsWith = this.prefixPath): string{
    if(!path) return '';
    const filterTarget = path.startsWith(startsWith) ? path.replace(startsWith, '') : path;
    return filterTarget;
  }

  getStaticPath(path: string){
    if(!this.showStatic) return path;
    const finalPath = this.trimParams(path);
    return finalPath;
  }

  trimParams(path: string, stripUndefinedOnly = false){
    return path.split('/').filter((el: string)=>stripUndefinedOnly ? !(el[0] === ':' && el.includes('?')) : el[0] !== ':').join('/');
  }

  private setPage(component: string, url: string){
    if(!url) return;
    this.page[component] = url;
  }
}

export class History {
  history: any;
  route: any;
  _static: boolean | undefined;

  constructor(showStatic?: boolean){
    this._static = showStatic;
    this.history = useHistory();
    this.route = new RoutePath();
  }

  set static(staticMode: boolean){
    this._static = staticMode;
  }

  push(location: LocationDescriptor, state?: LocationState) {
    if(this.history === undefined){
      throw new Error('Please call hook inside of the body of a function component. Call: history.hook();');
    }

    if(typeof location === 'string'){
      const target = this.route.getFullPath(location);
      this.history.push(target, state);
      return;
    }
    const changePathLocation: LocationDescriptorObject = {
      pathname: this.route.getFullPath(location['pathname'])
    };

    const target: LocationDescriptorObject = Object.assign({}, location, changePathLocation);

    this.history.push(target);
  }

  hook(){
    this.history = useHistory();
  }

  replace(path: any, state?: any): void{
    if(this.history === undefined){
      throw new Error('Please call hook inside of the body of a function component. Call: history.hook();');
    }
    this.history.replace(path, state);
  }

  go(n: number): void{
    if(this.history === undefined){
      throw new Error('Please call hook inside of the body of a function component. Call: history.hook();');
    }
    this.history.go(n);
  }

  goBack(): void{
    if(this.history === undefined){
      throw new Error('Please call hook inside of the body of a function component. Call: history.hook();');
    }
    this.history.goBack();
  }

  generatePath(patternKey: string, params: any){
    const target = this.route.url(patternKey);
    return generatePath(target, params);
  }

  generateComponent(patternKey: string, params: any){
    const target = this.route.component(patternKey);
    return generatePath(target, params);
  }

  component(component: string, isStatic?: boolean){
    const staticMode = this._static !== undefined ? this._static : isStatic;
    return this.route.component(component, staticMode);
  }

  //always getFullPath (with parameters)
  url(component: string){
    return this.route.url(component);
  }

  //return path to primary domain
  //'case team domain using subdomain like teamid.primary.com
  //we need to lead them to the primary.com
  mainUrl(component: string){
    return this.route.mainUrl(component);
  }

  //can get static path (without parameter) or full path
  getPath(component: string, isStatic?: boolean){
    return this.route.getPath(component, isStatic);
  }

  isHomeWorkspace(hostname?: string): boolean{
    if(hostname) return process.env.REACT_APP_DOMAIN === hostname;
    return process.env.REACT_APP_DOMAIN === window.location.hostname;
  }

  generateBuilder(queryProperties: Record<string, any>, isBuiderV2: boolean){
    const builderEnv = process.env.REACT_APP_LPBUILDER_DOMAIN || '';
    const builderv2Env = process.env.REACT_APP_LPBUILDER_V2_DOMAIN || '';
    if(!builderEnv || !builderv2Env) throw Error('Please setup REACT_APP_LPBUILDER_DOMAIN from .env.');
    const builderPath = new URL(isBuiderV2 ? builderv2Env : builderEnv);
    if(!this.isHomeWorkspace()) builderPath.hostname = window.location.hostname;

    const query = builderPath.searchParams;
    Object.keys(queryProperties).map((property: string)=>{
      query.set(property, queryProperties[property]);
    });

    return builderPath.toString();
  }

}

export default {};