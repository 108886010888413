import * as React from 'react';
import { TableMarkupProps, CustomRowProps } from './index.d';

export default function TableMarkup(props: TableMarkupProps) {
  // use this variable to decide whether rendering Groups or not
  let tempGroupId = -1;
  const { tableInstance, activeGroup, groupComponent } = props;
  const { getTableProps, headerGroups, getTableBodyProps, prepareRow, page } = tableInstance;

  function validToRenderGroup(row: CustomRowProps): boolean {
    const original = row.original as any;
    const groupId = original.groupId || 0;

    if (!activeGroup || !groupId) {
      return false;
    }

    if (groupId === tempGroupId) {
      return false;
    }

    return true;
  }

  function renderGroup(row: CustomRowProps) {
    if (!validToRenderGroup(row)) return null;

    // marking that we are rendering this groupId
    const original = row.original;
    const { groupId = 0, groupName = '' } = original;
    tempGroupId = groupId;

    if (groupComponent) {
      const Group = groupComponent;
      return <Group row={row} />;
    }

    return (
      <div className="table-group-row" key={groupId}>
        <div className="table-group-cell">{groupName}</div>
      </div>
    );
  }

  return (
    <div className="table" {...getTableProps()}>
      <div className="table-head">
        {headerGroups.map((headerGroup) => (
          <div className="table-head-row" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <div className="table-head-cell" {...column.getHeaderProps()}>{column.render('Header')}</div>
            ))}
          </div>
        ))}
      </div>
      <div className="table-body" {...getTableBodyProps()}>
        {page.map((row, id) => {
          prepareRow(row);
          const rowClassName = row.original.isLast ? 'table-row table-row-last' : 'table-row';

          return (
            <React.Fragment key={row.id}>
              {renderGroup(row)}
              <div className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <div className="table-cell" {...cell.getCellProps()}>{cell.render('Cell')}</div>;
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
