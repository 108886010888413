"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const _1 = require(".");
const ApiAction_1 = require("../actions/ApiAction");
class NotificationService {
    constructor() { }
    setNotification(params) {
        const type = _1.SetNotificationAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
}
exports.NotificationService = NotificationService;
