import * as React from 'react';
import styled from 'styled-components';
const Styles = styled.div`
  padding: 0 15px 15px;
  .skeleton__header--avatar {
    width: 36px;
    height: 36px;
    border: 0.5px solid #d8d8d8;
    border-radius: 50%;
    background-color: rgba(216, 216, 216, 0.5);
  }
  .skeleton__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .skeleton__header--title {
    width: 82px;
    height: 9px;
    background-color: rgba(216, 216, 216, 0.5);
  }
  .skeleton__header--title {
    margin-left: 10px;
    width: 82px;
    height: 9px;
    background-color: rgba(216, 216, 216, 0.5);
  }
  .skeleton__header--subtitle {
    margin-left: 10px;
    margin-top: 5px;
    width: 45px;
    height: 3px;
    background-color: rgba(216, 216, 216, 0.5);
  }
  .skeleton__body--line {
    margin-top: 5px;
    width: 100%;
    height: 7px;
    background-color: rgba(216, 216, 216, 0.5);
  }
`;
interface ComponentProps {
  
}

const SkeletonText: React.FC<ComponentProps> = (props) => {

  return (
    <Styles>
      <div className="skeleton">
        <div className="skeleton__header">
          <div className="skeleton__header--avatar"></div>
          <div>
            <div className="skeleton__header--title"></div>
            <div className="skeleton__header--subtitle"></div>
          </div>
        </div>
        <div className="skeleton__body">
          <div className="skeleton__body--line"></div>
          <div className="skeleton__body--line"></div>
          <div className="skeleton__body--line"></div>
        </div>
      </div>
    </Styles>
  );
};

export default SkeletonText;
