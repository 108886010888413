import io from 'socket.io-client';

interface Event {
  eventName: string;
  data: object;
}

class ConnectedSocket {
  url: string;
  connectedStatus: boolean;
  emitQueues: Array<Event>;
  socket: SocketIOClient.Socket;

  constructor(url: string) {
    this.url = url;
    this.connectedStatus = false;
    this.emitQueues = [];
    this.socket = io(url, {
      transports: ['websocket'],
      reconnectionDelay: 100,
      reconnectionDelayMax: 500,
      reconnectionAttempts: Infinity
    });
    this.onConnect();
    this.onDisconnect();
  }

  onConnect() {
    this.socket.on('connect', () => {
      this.connectedStatus = true;
      this.process_all_queue();
    });
  }

  onDisconnect() {
    this.socket.on('disconnect', () => {
      this.connectedStatus = false;
      this.emitQueues = [];
    });
  }

  emit(eventName: string, data: object) {
    if (this.connectedStatus) {
      this.socket.emit(eventName, data);
    } else {
      this.emitQueues.push({eventName, data});
    }
  }

  on(eventName: string, func: any) {
    this.socket.on(eventName, func);
  }

  off(eventName: string, func: any) {
    this.socket.off(eventName, func);
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  process_all_queue() {
    if (!this.socket) {
      return;
    }

    if (this.emitQueues && this.emitQueues.length > 0) {
      this.emitQueues.forEach((queue) => {
        if (this.connectedStatus) {
          this.socket.emit(queue.eventName, queue.data);
        }
      });
    }
  }
}

class SocketInstance {
  socketInstance: ConnectedSocket | null;
  constructor() {
    this.socketInstance = null;
  }
  createInstance(socketUrl: string) {
    if (!this.socketInstance) {
      this.socketInstance = new ConnectedSocket(socketUrl);
    }
    return this.socketInstance;
  }
}

const socketInstance = new SocketInstance();
const socket = socketInstance.createInstance(process.env.REACT_APP_SOCKET_URL as string);
export { socket };
