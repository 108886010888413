import AdsCreationPage from 'pages/ads-creation/AdsCreationPage';
import CreationPreviewPage from 'pages/ads-creation/preview/PreviewPage';
import EditMediaAdsPage from 'pages/ads-creation/EditMediaAdsPage';
import AnalyticDashboardPage from 'pages/analytics/AnalyticDashboard';
import AnalyticsManagement from 'pages/analytics/AnalyticsManagement';
import ChannelAnalytics from 'pages/analytics/ChannelAnalytics';
import DetailAnalyticsPage from 'pages/analytics/DetailAnalytics';
import DetailRealtime from 'pages/analytics/DetailRealtime';
import LoginPage from 'pages/auth/login/Login';
import LogoutPage from 'pages/auth/Logout';
import TeamLoginPage from 'pages/auth/login/TeamLogin';
import TransferOwnershipPage from 'pages/team/TransferOwnership';
import ResetPasswordPage from 'pages/auth/ResetPassword';
import AccountAdsManagementPage from 'pages/google-ads/ConnectBoard/AccountAdsManagement';
import ConnectBoards from 'pages/google-ads/ConnectBoard';
import InitialGoogleCampaign from 'pages/google-ads/CreateGoogleCampaign/InitialGoogleCampaign';
import CreateGoogleCampaign from 'pages/google-ads/CreateGoogleCampaign';
import GoogleAdsSettings from 'pages/google-ads/CreateGoogleCampaign/Settings';

import GoogleAdsOptimization from 'pages/google-ads/OptimazationCampaign/index';
import LinkExpiredPage from 'pages/auth/LinkExpiredPage';
import PermissionRequired from 'pages/auth/PermissionRequired';
import VerifyPage from 'pages/auth/Verify';
import VerifyEmailSuccess from 'pages/auth/VerifyEmailSuccess';
import CreateTeamPage from 'pages/team/CreateTeam';
import SentConfirmEmailNoti from 'pages/auth/SentConfirmEmailNoti';
import RegisterPage from 'pages/auth/register/Register';
import CustomerManagementPage from 'pages/customer/CustomerManagement';
// import DemoChart from 'pages/demo-chart';
import CreateLandingPage from 'pages/landing-page/CreateLanding';
import LandingManagementPage from 'pages/landing-page/LandingManagement';
import NotFoundPage from 'pages/NotFoundPage';
import ChooseProjectTemplatePage from 'pages/project/choose-project/ChooseProjectTemplate';
import CloneProject from 'pages/project/CloneProject';
import CreateProjectPage from 'pages/project/CreateProject';
import EditProjectPage from 'pages/project/EditProject';
import ProjectsManagementPage from 'pages/project/ProjectsManagement';
import VerifyEmail from 'pages/auth/VerifyEmail';
import JoinTeam from 'pages/team/JoinTeam';
import ConnectFacebook from 'pages/ConnectFacebook';
import PricingPage from 'pages/payment/PricingPage';
import PackageInfoPage from 'pages/payment/PackageInfoPage';
import PaymentMethodPage from 'pages/payment/PaymentMethodPage';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CampaignAnalytics from 'pages/analytics/CampaignAnalytics';
import PrivateRoute from './PrivateRoute';
import CampaignMetricDashboard from 'pages/google-ads/Reports';
import ConnectAdsAccount from 'pages/google-ads/ConnectBoard/ConnectAdsAccount';

const Router: React.FC = () => {
  const history = (globalThis as any).useHistory;
  return (
    <Switch>
      <Route path={history.component('LoginPage')} component={LoginPage} />
      <Route path={history.component('LogoutPage')} component={LogoutPage} />
      <Route path={history.component('TeamLoginPage')} component={TeamLoginPage} />
      <Route path={history.component('RegisterPage')} component={RegisterPage} />
      <Route path={history.component('ResetPasswordPage')} component={ResetPasswordPage} />
      <Route path={history.component('ResetPasswordPageRecover')} component={ResetPasswordPage} />
      <Route path={history.component('VerifyEmail')} component={VerifyEmail} />
      <Route path={history.component('JoinTeam')} component={JoinTeam} />
      <Route path={history.component('VerifyEmailSuccess')} component={VerifyEmailSuccess} />
      <Route path={history.component('LinkExpiredPage')} component={LinkExpiredPage} />
      <Route path={history.component('PermissionRequired')} component={PermissionRequired} />
      <Route path={history.component('ConnectFacebook')} component={ConnectFacebook} />
      <PrivateRoute exact path={[history.component('Home'), history.component('Dashboard'), '/']} Component={LandingManagementPage} />
      <PrivateRoute path={history.component('LandingManagementPage')} Component={LandingManagementPage} />
      <PrivateRoute path={history.component('VerifyPage')} Component={VerifyPage} />
      <PrivateRoute path={history.component('TransferOwnershipPage')} Component={TransferOwnershipPage} />
      <PrivateRoute path={history.component('CreateTeamPage')} Component={CreateTeamPage} />
      <PrivateRoute path={history.component('SentConfirmEmailNoti')} Component={SentConfirmEmailNoti} />
      <PrivateRoute path={history.component('ProjectsManagementPage')} exact Component={ProjectsManagementPage} />
      <PrivateRoute path={history.component('CreateProjectPage')} Component={CreateProjectPage} />
      <PrivateRoute path={history.component('CloneProject')} Component={CloneProject} />
      <PrivateRoute path={history.component('EditProjectPage')} exact Component={EditProjectPage} />
      <PrivateRoute path={history.component('ChooseProjectTemplatePage')} Component={ChooseProjectTemplatePage} />
      <PrivateRoute path={history.component('LandingManagementPage')} Component={LandingManagementPage} />
      <PrivateRoute path={history.component('CustomerManagementPage')} Component={CustomerManagementPage} />
      <PrivateRoute path={history.component('AdsCreationPage')} Component={AdsCreationPage} />
      <PrivateRoute path={history.component('CreationPreviewPage')} Component={CreationPreviewPage} />
      <PrivateRoute path={history.component('EditMediaAdsPage')} Component={EditMediaAdsPage} />
      <PrivateRoute path={history.component('CreateLandingPage')} Component={CreateLandingPage} />
      {/* <PrivateRoute path={history.component('DetailAnalyticsPage')} Component={DetailAnalyticsPage} /> */}
      {/* <PrivateRoute path={history.component('AnalyticDashboardPage')} Component={AnalyticDashboardPage} /> */}
      {/* <PrivateRoute path={history.component('AnalyticsManagement')} Component={AnalyticsManagement} /> */}
      {/* <PrivateRoute path={history.component('DetailRealtime')} Component={DetailRealtime} /> */}
      {/* <PrivateRoute path={history.component('ChannelAnalytics')} Component={ChannelAnalytics} /> */}
      {/* <PrivateRoute path={history.component('CampaignAnalytics')} Component={CampaignAnalytics} /> */}
      <PrivateRoute path={history.component('AccountAdsManagementPage')} Component={AccountAdsManagementPage} />
      <PrivateRoute path={history.component('GoogleAdsDashboard')} Component={CampaignMetricDashboard} />
      <PrivateRoute path={history.component('GoogleAdsSettings')} Component={GoogleAdsSettings} />
      <PrivateRoute path={history.component('GoogleAdsOptimization')} Component={GoogleAdsOptimization} />
      <PrivateRoute path={history.component('PricingPage')} Component={PricingPage} />
      <PrivateRoute path={history.component('PackageInfoPage')} Component={PackageInfoPage} />
      <PrivateRoute path={history.component('PaymentMethodPage')} Component={PaymentMethodPage} />
      {/* <PrivateRoute path={history.component('DemoChart')} Component={DemoChart} /> */}
      {/*<PrivateRoute path={history.component('InitialGoogleCampaign')} Component={InitialGoogleCampaign} />*/}
      <PrivateRoute path={history.component('CreateGoogleCampaign')} Component={CreateGoogleCampaign} />
      <PrivateRoute path={history.component('EditGoogleCampaign')} Component={CreateGoogleCampaign} />
      <PrivateRoute path={history.component('ConnectBoards')} Component={ConnectBoards} />
      <PrivateRoute path={history.component('ConnectAdsAccount')} Component={ConnectAdsAccount} />

      <PrivateRoute path="*" Component={NotFoundPage} />
    </Switch>
  );
};

export default Router;
