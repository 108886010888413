import { Dispatch } from 'react';
import { apiErrorCodeGlobal, registerByEmail, forgotPasswordResetEmail, forgotPasswordResetPassword } from 'core';

export default class EmailService {
	constructor(){}

	static dispatchRegister(dispatch: Dispatch<any>, email: string, password: string, name: string, phoneNo: string) {
		if (!email && !password && !name && !phoneNo) return;

		const prm = { email, password, name, phoneNo };
		dispatch(registerByEmail(prm));
	}

  static dispatchForgotEmail(dispatch: Dispatch<any>, email: string) {
    if(!email) return;
    dispatch(forgotPasswordResetEmail({email}));
  }

  dispatchForgotEmailResetPassword = async (dispatch: Dispatch<any>, token: string, email: string, new_password: string) => {
    if(!new_password || !token) return;
    const res = await dispatch(forgotPasswordResetPassword({token, new_password}));
  }

	static validateEmailFormat(email: string): boolean {
		const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		return reg.test(email);
	}

	static validatePasswordFormat(password: string): boolean {
		const reg = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
		return reg.test(password);
	}

	static hasEnoughCharacterTypeInPassword(password: string): boolean {
		const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
		return reg.test(password);
	}

	static hasEnoughCharacterInPassword(password: string): boolean {
		const reg = /^(?=.{8,})/;
		return reg.test(password);
	}

	static validatePhoneNo(phoneNo: string) {
		const reg = /((09|03|07|08|05|\+84)+([0-9]{8})\b)/g;
		return reg.test(phoneNo);
	}

	static isCorrectConfirmPassword(confirmPassword: string, password: string) {
		return confirmPassword === password;
	}
}

export enum InputErrorMsg {
  InvalidEmail = 'Email không hợp lệ',
  InvalidPassword = 'Mật khẩu không hợp lệ',
  UnmatchedConfirmPassword = 'Mật khẩu không trùng khớp, vui lòng kiểm tra lại',
  EmptyName = 'Họ tên không được bỏ trống',
  EmptyPhoneNo = 'Số điện thoại không được bỏ trống',
  InvalidPhoneNo = 'Số điện thoại không hợp lệ',
  RefusePolicy = 'Cần chấp nhận Điều khoản sử dụng',
}

export enum FieldType {
  Email = 'Email',
  Password = 'Password',
  ConfirmPassword = 'ConfirmPassword',
  Name = 'Name',
  PhoneNo = 'PhoneNo',
  AcceptPolicy = 'AcceptPolicy',
}

export const ApiErrorMsg = {
  ...apiErrorCodeGlobal,
	'301': 'Có lỗi trong quá trình đăng nhập.',
	'302': 'Không tìm thấy tài khoản đăng ký với email này, vui lòng kiểm tra lại.',
	'303': 'Email hoặc mật khẩu không đúng.',
	'304': 'Email đã được sử dụng.',
	'305': 'Email chưa xác thực.',
  '307': 'Link reset mật khẩu không hợp lệ.',
  '308': 'Bạn đã sử dụng mật khẩu này. Vui lòng sử dụng mật khẩu mới không trùng lặp.'
};
