import React, { useState, useEffect, useMemo } from 'react';
import FlatList from 'flatlist-react';
import { ISiteLinkExtension, ISitelinkExtensionSetting } from 'core';
import { FieldErrors } from 'react-hook-form';
import { ExtensionAction } from '../forms/CommonSettings';
import { ErrorInput } from './ErrorInput';
import { PreviewSiteLinkModal } from '../modals';
import classNames from 'classnames';
import { SPECS } from '../forms/validate';

type PropsType = {
  handleExtensionAction: (item?: InputType, action?: ExtensionAction) => void;
  onChange: (value?: ISitelinkExtensionSetting) => void;
  value: ISitelinkExtensionSetting | undefined;
  errors: FieldErrors;
  sitelinksApi: InputType[];
}

export interface InputType extends ISiteLinkExtension {
  component: string;
  value: number;
}

export const importSiteLinkData = (inputSitelink: UpdateSitelinkIO): InputType[] => {
  const ret: InputType[] = inputSitelink.sitelinksApi;
  const data: ISiteLinkExtension[] = inputSitelink.value?.sitelinks || [];
  data?.map((el: ISiteLinkExtension)=>{
    // const {id, title, description1, description2, url} = el;
    const found = ret.find(item=>item.url === el.url);
    if(found) {
      found.value = 1;
      found.resource_name = el.resource_name;
    }
    else
      ret.unshift({...el, component: el.url, value: 1});
  });
  return ret;
};

export const exportSiteLinkData = (input: InputType[], resource_name: string | undefined, withoutFilter = false): ISitelinkExtensionSetting | undefined => {
  const data = withoutFilter ? input : input.filter(el=>el.value);
  const sitelinks: ISiteLinkExtension[] = data.map(el=>({
    title: el.title, 
    description1: el.description1, 
    description2: el.description2, 
    url: el.url,
    resource_name: el.resource_name
  }));

  // if(sitelinks.length == 0) return undefined;
  return {
    sitelinks,
    resource_name: resource_name
  };
};

export interface UpdateSitelinkIO {
  sitelinksApi: InputType[];
  value?: ISitelinkExtensionSetting;
}
interface EditItemSitelinkIO{
  updateItem: InputType;
  oldItem: InputType;
}

export const updateSitelinkIO = (inputSitelink: UpdateSitelinkIO, data: EditItemSitelinkIO, resource_name: string | undefined): ISitelinkExtensionSetting | undefined => {
  const input: InputType[] = importSiteLinkData(inputSitelink);
  const {updateItem, oldItem} = data;
  
  if(!oldItem) return;
  //cancel if it's already exists
  const dupl = updateItem.url !== oldItem.url && input.find(el=>el.url === updateItem.url);
  if(dupl) return;
  
  //update new item into current place
  const found = input.find(el=>oldItem ? el.url === oldItem.url : el.url === updateItem.url);
  if(found){
    Object.keys(found).map(el=>found[el] = updateItem[el]);
  }
  
  const exportData = exportSiteLinkData(input, resource_name, false);
  return exportData;
};
  
export const addSitelinkIO = (inputSitelink: UpdateSitelinkIO, updateItem: InputType[], resource_name: string | undefined): ISitelinkExtensionSetting | undefined => {
  const input: InputType[] = importSiteLinkData(inputSitelink);
  
  updateItem.map(item=>{
    const found = input.find(el=>el.url == item.url);
    if(found){
      Object.keys(found).map(el=>found[el] = item[el]);
    }else{
      input.push({...item, component: item.url, value: 1});
    }
  });
  
  const exportData = exportSiteLinkData(input, resource_name, false);
  return exportData;
};
  
export const SitelinkComponent: React.FC<PropsType> = (props) => {
  const sitelinks: InputType[] = useMemo(
    ()=>importSiteLinkData({sitelinksApi: props.sitelinksApi, value: props.value}),
    [props.sitelinksApi, props.value]
  );

  const [modalIsOpen, setIsOpen] = useState(false);
  const renderItem = (item: InputType, handleChange: (item: InputType, action: ExtensionAction) => void) => {
      const { title, description1, description2, url, component } = item;
      return (
        <div className="extend-item" key={'sitelink/' + component}>
          <div className="form-check mr-3">
            <div className="d-flex align-items-center">
              <input id={`extend-sitelink-${component}`} className="form-check-input" 
                type="checkbox" 
                value={item.value}
                checked={!!item.value}
                onChange={() => handleChange(item, ExtensionAction.Toggle)} 
              />
              <label htmlFor={`extend-sitelink-${component}`}>
                <h4>{title}</h4>
                <p>{description1}</p>
                <p>{description2}</p>
              </label>
            </div>
            <div className="item-action">
              <a className="edit-link cursor-pointer" onClick={() => handleChange(item, ExtensionAction.Edit)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fillRule="nonzero"> <g> <g> <g> <path fill="#FFF" d="M0 0H18V18H0z" opacity=".01" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)"/> <path fill="#8E8E93" d="M15.432 4.472l1.038-1.033c.52-.52.538-1.096.064-1.58l-.392-.403c-.473-.475-1.065-.429-1.575.074L13.52 2.57l1.912 1.9zM1.662 16.853l2.286-.877L14.485 5.404l-1.885-1.9L2.063 14.074l-.91 2.248c-.128.32.21.64.51.53z" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)"/> </g> </g> </g> </g> </g> </svg></a>
              <a className="remove-link cursor-pointer hidden" onClick={() => handleChange(item, ExtensionAction.Remove)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2" fillRule="nonzero"> <g> <g> <path d="M670 46c4.924 0 9-4.085 9-9 0-4.924-4.085-9-9.009-9-4.915 0-8.991 4.076-8.991 9 0 4.915 4.085 9 9 9zm3.097-5.153c-.203 0-.38-.088-.52-.22l-2.586-2.586-2.567 2.585c-.142.142-.327.221-.539.221-.397 0-.723-.335-.723-.741 0-.194.08-.37.212-.512l2.585-2.585-2.585-2.585c-.133-.142-.212-.318-.212-.512 0-.406.326-.724.723-.724.212 0 .38.07.512.212l2.594 2.585 2.62-2.594c.15-.15.31-.22.504-.22.397 0 .723.326.723.723 0 .203-.062.362-.212.52l-2.594 2.595 2.586 2.576c.14.15.211.318.211.52 0 .407-.326.742-.732.742z" transform="translate(-1016 -830) translate(355 754) translate(0 48)"/> </g> </g> </g> </g> </svg></a>
            </div>
          </div>
        </div>
      );
  };

  const renderPreviewItem = (item: InputType, handleChange: (item: InputType, action: ExtensionAction) => void) => {
      return <div className="extend-item" key={'sitelink-preview-' + item.component}>
        <h4>{item.title}</h4>
        <p>{item.description1}</p>
        <p>{item.description2}</p>
        <div className="item-action">
          <a className="edit-link cursor-pointer" onClick={() => handleChange(item, ExtensionAction.Edit)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fillRule="nonzero"> <g> <g> <g> <path fill="#FFF" d="M0 0H18V18H0z" opacity=".01" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)"/> <path fill="#8E8E93" d="M15.432 4.472l1.038-1.033c.52-.52.538-1.096.064-1.58l-.392-.403c-.473-.475-1.065-.429-1.575.074L13.52 2.57l1.912 1.9zM1.662 16.853l2.286-.877L14.485 5.404l-1.885-1.9L2.063 14.074l-.91 2.248c-.128.32.21.64.51.53z" transform="translate(-986 -830) translate(355 754) translate(0 48) translate(631 28)"/> </g> </g> </g> </g> </g> </svg></a>
          <a className="delete-link cursor-pointer" onClick={() => handleChange(item, ExtensionAction.Delete)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g fill="#AEAEB2" fillRule="nonzero"> <g> <g> <path d="M670 46c4.924 0 9-4.085 9-9 0-4.924-4.085-9-9.009-9-4.915 0-8.991 4.076-8.991 9 0 4.915 4.085 9 9 9zm3.097-5.153c-.203 0-.38-.088-.52-.22l-2.586-2.586-2.567 2.585c-.142.142-.327.221-.539.221-.397 0-.723-.335-.723-.741 0-.194.08-.37.212-.512l2.585-2.585-2.585-2.585c-.133-.142-.212-.318-.212-.512 0-.406.326-.724.723-.724.212 0 .38.07.512.212l2.594 2.585 2.62-2.594c.15-.15.31-.22.504-.22.397 0 .723.326.723.723 0 .203-.062.362-.212.52l-2.594 2.595 2.586 2.576c.14.15.211.318.211.52 0 .407-.326.742-.732.742z" transform="translate(-1016 -830) translate(355 754) translate(0 48)"/> </g> </g> </g> </g> </svg></a>
        </div>
      </div>;
  };
  
  const handleToggleItem = (item: InputType, action: ExtensionAction) => {
    if(action === ExtensionAction.Toggle){
      const data: InputType[] = sitelinks;
      const found = data.find(el=>el.url == item.url);
      if(found) found.value = found.value ? 0 : 1;
      if(found && found.value === 1 && data.filter(el=>el.value).length > SPECS.commonSetting.site_link.value) {
        found.value = 0;
        return;
      }
      props.onChange(exportSiteLinkData(data, props.value?.resource_name));
    }
    if(action === ExtensionAction.Edit){
      props.handleExtensionAction(item, action);
    }
    if(action === ExtensionAction.Delete){
      const data: InputType[] = sitelinks;
      const found = data.find(el=>el.url == item.url);
      if(found) found.value = 0;
      props.onChange(exportSiteLinkData(data, props.value?.resource_name));
    }
  };

  const showPreviewSiteLinkModal = () => {
    setIsOpen(true);
  };

  return (<>
      <PreviewSiteLinkModal 
        isOpen={modalIsOpen}
        dataSiteLink={sitelinks.filter(el=>el.value)} 
        closeModal={() => {setIsOpen(false);}} 
      />
        <label>Tiện ích mở rộng liên kết trang web</label>
        <p className="form-description">Thêm ít nhất 2 (tối đa 6) tiện ích liên kết trang web</p>
        <div className={classNames('extends-list-wrapper', {'errors': props.errors.site_link})}>
          <div className="settings-list">
            <FlatList
              list={sitelinks}
              renderItem={(item: InputType)=>renderItem(item, handleToggleItem)}
              renderWhenEmpty={() => <></>}
            />
          </div>
          <div className="preview-list">
            <FlatList
              list={sitelinks.filter(el=>el.value)}
              renderItem={(item: InputType)=>renderPreviewItem(item, handleToggleItem)}
              renderWhenEmpty={() => <></>}
            />
          </div>
          <div className="preview-list__label" onClick={showPreviewSiteLinkModal}>
            XEM <br />TRƯỚC
          </div>
          <div className="add-extend-link" onClick={()=>props.handleExtensionAction(undefined, ExtensionAction.Add)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#4C7CF3" fillRule="nonzero"> <g> <g> <g> <path d="M6.997 16C10.833 16 14 12.832 14 9s-3.174-7-7.01-7C3.16 2 0 5.168 0 9s3.167 7 6.997 7zm0-1.395c-3.106 0-5.59-2.498-5.59-5.605S3.891 3.401 6.99 3.401c3.106 0 5.603 2.492 5.61 5.599.006 3.107-2.498 5.605-5.603 5.605zm-.014-2.572c.4 0 .643-.27.643-.69V9.65h1.813c.406 0 .69-.244.69-.636 0-.4-.27-.644-.69-.644H7.626v-1.8c0-.42-.244-.69-.643-.69-.392 0-.63.283-.63.69v1.8H4.548c-.42 0-.697.244-.697.644 0 .392.291.636.697.636h1.807v1.692c0 .407.237.69.63.69z" transform="translate(-365 -1109) translate(355 754) translate(0 48) translate(10 305)"/> </g> </g> </g> </g> </g> </svg>
            <span>Thêm mới tiện ích liên kết trang web</span>
          </div>
        </div>{/*extends-list-wrapper*/}
        {props.errors.site_link && <ErrorInput text={props.errors.site_link.message}/>}
    </>
  );
};
