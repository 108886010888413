import { GetLandingPageDataParamsType, Image, ExtractLandingPageData } from 'core';
import _ from 'lodash';
import { FrameType, ComponentType, AdFrameState } from '../entities/Types';

export const getSlideNumber = () => 4;

export const buildParamsFetchLpData = (landingPageId: number): GetLandingPageDataParamsType => {
	return {
		landing_page_id: landingPageId,
		length: 2,
		search: JSON.stringify(
			[
				{
					component: ComponentType.header,
					fields: ['outstanding', 'potential'],
				},
				{
					component: ComponentType.policy,
					fields: ['title', 'image']
				},
				{
					component: ComponentType.projectGround,
					fields: ['title', 'image']
				},
				{
					component: ComponentType.projectLocation,
					fields: ['title', 'image']
				},
				{
					component: ComponentType.utility,
					fields: ['title', 'image']
				},
			]
		),
	};
};

type SearchParamsType = {
	landingPageId: number;
	projectId: number;
}
export const getSearchParams = (location: Function): SearchParamsType => {
	const { search } = location();
	const searchParams = new URLSearchParams(search);

	return {
		landingPageId: +(searchParams.get('landing_page_id') as string),
		projectId: +(searchParams.get('project_id') as string)
	};
};

const randomEnum = (enumObj: any) => {
	const keys = Object.keys(enumObj) as typeof enumObj[];
	return randomArr(keys);
};

const randomArr = (arrObj: Array<any>) => {
	return arrObj[Math.floor(Math.random() * arrObj.length)];
};

export const randomComponentType = (lpData: ExtractLandingPageData, excludeType?: ComponentType) => {
	if (_.isEmpty(lpData)) return randomEnum(ComponentType);
	
	const enumObj = excludeType ? _.omit(lpData, excludeType) : lpData;
	return randomEnum(enumObj);
};

export const randomFrame = () => (randomEnum(FrameType));

export const randomComponent = (lpData: ExtractLandingPageData) => {
	if (_.isEmpty(lpData)) return;

	const compType = randomComponentType(lpData);
	const component = getComponent(lpData, compType);
	return component;
};

export const getComponent = (lpData: ExtractLandingPageData, componentType: ComponentType) => (
	{
		...lpData[componentType],
		type: componentType
	}
);

export const getTemplateImage = (lpData: ExtractLandingPageData, componentType: ComponentType): string => {
	if (_.isEmpty(lpData)) return '';

	const component = getComponent(lpData, componentType);
	if (_.isEmpty(component)) return '';
	
	if (component.type === ComponentType.header) return component.background_images ? component.background_images[0] : '';

	return component.image && Array.isArray(component.image) ? randomArr(component.image) : '';
};

export const getTemplateLogo = (lpData: ExtractLandingPageData): string => {
	if (_.isEmpty(lpData)) return '';

	const header = getComponent(lpData, ComponentType.header);
	return header && header.logo_images ? header.logo_images[0] : '';
};


export const getTemplateDescription = (lpData: ExtractLandingPageData, componentType: ComponentType): string => {
	if (_.isEmpty(lpData)) return '';

	const component = getComponent(lpData, componentType);
	const randomDes = () => {
		const compTypeExcludeHeader = _.omit(lpData, ComponentType.header);
		const randomComp = randomComponent(compTypeExcludeHeader);
		return randomComp && randomComp.title ? randomComp.title[0] : '';
	};
	if (_.isEmpty(component)) return '';

	let des = '';
	if (component.type === ComponentType.header) {
		const { outstanding, potential } = component;
		if (_.isEmpty(outstanding) && _.isEmpty(potential)) {
			des = randomDes();
			return des;
		}
		des = component.outstanding[0] || component.potential[0];
		if (!des) {
			des = randomDes();
			return des;
		}
		return des;
	}

	return component.title ? component.title[0] : '';
};

export const getSingleImageTemplate = (lpData: ExtractLandingPageData, componentType: ComponentType): AdFrameState => {
	if (_.isEmpty(lpData)) return {
		title: '',
		bkImageSrc: '',
		body: '',
	};

	const compnentsHasImage = Object.keys(lpData)
		.filter(key => key === ComponentType.header || !_.isEmpty(lpData[key].image))
		.reduce((obj, key) => {
			obj[key] = lpData[key];
			return obj;
		}, {});
	
	const compType = componentType === ComponentType.header ? ComponentType.header :  randomComponentType(compnentsHasImage);
	return {
		title: '',
		bkImageSrc: getTemplateImage(compnentsHasImage, compType),
		body: getTemplateDescription(compnentsHasImage, compType),
	};
};

export const getCarouselTemplate = (lpData: ExtractLandingPageData): Array<AdFrameState> => {
	if (_.isEmpty(lpData)) return [];

	const slideNumber = getSlideNumber();
	const templates = new Array<AdFrameState>();
	const slideHeader = {
		title: '',
		bkImageSrc: getTemplateImage(lpData, ComponentType.header),
		body: getTemplateDescription(lpData, ComponentType.header),
	};

	templates.push(slideHeader);

	let restComponents = _.omit(lpData, ComponentType.header);
	for (let i = 1; i < slideNumber; i++) {
		const compType = randomComponentType(restComponents);
		const bkImageSrc = getTemplateImage(restComponents, compType);

		if (_.isEmpty(compType) || !bkImageSrc) {
			templates.push(slideHeader);
			
		} else {
			templates.push({
				title: '',
				bkImageSrc,
				body: getTemplateDescription(restComponents, compType),
			});
		}

		restComponents = _.omit(restComponents, compType);
	}

	return templates;
};
