"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleCampaignForcastSaga = exports.generateCampaignForcast = void 0;
const effects_1 = require("@redux-saga/core/effects");
const class_transformer_1 = require("class-transformer");
const adapters_1 = require("../../adapters");
const CampaignForecast_1 = require("../types/CampaignForecast");
const GoogleAds_1 = require("../../entities/GoogleAds");
function* generateCampaignForcast(action) {
    try {
        const params = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/generate_forecast`,
            authenticated: true,
            data: params
        };
        const response = yield effects_1.call(adapters_1.callApi, request);
        yield effects_1.put({
            type: CampaignForecast_1.GoogleAdsCampaignForecastTypes.success,
            payload: class_transformer_1.plainToClass(GoogleAds_1.CampaignForecast, response.data.data.campaign_forecast),
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data;
        yield effects_1.put({
            type: CampaignForecast_1.GoogleAdsCampaignForecastTypes.error,
            meta: action && action.meta,
            payload,
            error: true
        });
    }
}
exports.generateCampaignForcast = generateCampaignForcast;
exports.googleCampaignForcastSaga = [
    effects_1.takeLatest(CampaignForecast_1.GoogleAdsCampaignForecastTypes.type, generateCampaignForcast),
];
