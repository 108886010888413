import * as React from 'react';
import { usePagination, useTable } from 'react-table';
import { LandingPage, Project } from 'core';
import ConfirmButton from 'components/buttons/ConfirmButton';
import CopyLpButton from 'components/buttons/CopyLpButton';
import RenameLandingPageButton from 'components/buttons/RenameLandingPageButton';
import { CustomCellProps, BaseTableProps, UseTableInstance, UseTableState } from './index.d';
import TableMarkup from './TableMarkup';
import Pagination from './Pagination';
import DivMarkup from './DivMarkup';

import 'stylesheets/Button.css';

export enum TableActions {
  Edit = 'edit',
  Delete = 'delete',
  Duplicate = 'duplicate',
  Unpublish = 'unpublish',
  Rename = 'rename',
  View = 'view',
  Restore = 'restore',
}

const defaultProps = {
  showPagination: true,
  actions: [] as TableActions[],
};

export default function Table(props: BaseTableProps): React.ReactElement {
  const {
    columns,
    data,
    actions,
    activeGroup,
    activeVersion,
    activeCampaign,
    activePermission,
    showPagination,
    toggleViewAll,
    groupComponent,
    useDivMarkup,
  } = props;

  const colWithActions = React.useMemo(() => {
    return [
      ...columns,
      {
        Header: (): React.ReactChild => <div />,
        accessor: 'actions',
        Cell: renderActionColumn,
        width: 50
      },
    ];
  }, [columns]);

  function renderActionColumn(cellProps: CustomCellProps): React.ReactChild {
    if(renderActions(cellProps).length === 0) return <div></div>;
    return (
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-link dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h26v26H0z" opacity=".01" /> <g fill="#AEAEB2" transform="translate(0 10)"> <circle cx="23" cy="3" r="3" /> <circle cx="13" cy="3" r="3" /> <circle cx="3" cy="3" r="3" /> </g> </g> </svg>
        </button>
        <div className="dropdown-menu dropdown-menu-right">{renderActions(cellProps)}</div>
      </div>
    );
  }

  function renderActions(cellProps: CustomCellProps): Array<React.ReactChild> {
    const actionButtons: Array<React.ReactChild> = [];
    actions.forEach((action) => {
      let shouldShowAction = true;
      if (props.shouldShowAction && cellProps.row && !props.shouldShowAction(cellProps.row.original, action)) {
        shouldShowAction = false;
      }
      if (shouldShowAction) {
        actionButtons.push(getActionElement(action, cellProps));
      }
    });
    return actionButtons;
  }

  function getActionElement(action: TableActions, cellProps: CustomCellProps): React.ReactChild {
    switch (action) {
      case TableActions.Edit: {
        const originalRow = cellProps.row.original as any;
        return (
          <button
            key={TableActions.Edit}
            className="button-link"
            onClick={(e): void => {
              e.preventDefault();           
              editRow(originalRow.id);
            }}
          >
            Chỉnh sửa
          </button>
        );
      }
      case TableActions.Delete: {
        const originalRow = cellProps.row.original as LandingPage | Project | any;
        let confirmMessage;
        if (originalRow instanceof LandingPage) {
          confirmMessage = (
            <div>
              <h3 className="text-center">Thông báo</h3>
              <p className="pt-4 pb-3">
                Bạn có chắc chắn muốn xóa landing page <span className="font-weight-bold">{originalRow.lp_name}</span>?
              </p>
            </div>
          );
        } else if (originalRow instanceof Project) {
          confirmMessage = (
            <div>
              <h3 className="text-center">Thông báo</h3>
              <p className="pt-4 pb-3">
                Bạn có chắc chắn muốn xóa dự án <span className="font-weight-bold">{originalRow.name}</span>?
              </p>
            </div>
          );
        } else {
          confirmMessage = (
            <div>
              <h3 className="text-center">Thông báo</h3>
              <p className="pt-4 pb-3">
                Bạn có chắc chắn muốn xóa dự án <span className="font-weight-bold">{originalRow.name}</span>?
              </p>
            </div>
          );
        }

        return (
          <ConfirmButton
            key={TableActions.Delete}
            isDeleteStyle
            confirmTitle="Xóa"
            cancelTitle="Huỷ bỏ"
            detailTitle="Xem chi tiết"
            onClick={(): void => deleteRow(originalRow.id)}
            comfirmMessage={confirmMessage}
            isDeleteProject={originalRow instanceof Project}
            rowId={originalRow.id}
            rowName={originalRow.name}
          >
            Xoá
          </ConfirmButton>
        );
      }
      case TableActions.Duplicate: {
        const originalRow = cellProps.row.original as LandingPage;
        return (
          <CopyLpButton
            key={TableActions.Duplicate}
            onClick={(): void => {}}
            modalTitle="Tạo bản sao"
            templateLandingPageId={originalRow.id}
            templateLandingPageName={originalRow.lp_name}
            templateProjectId={originalRow.pid}
            templateVersionId={originalRow.versions[0].id}
          >
            Tạo bản sao
          </CopyLpButton>
        );
      }
      case TableActions.Unpublish: {
        const originalRow = cellProps.row.original as LandingPage;
        return (
          <ConfirmButton
            key={TableActions.Unpublish}
            isDeleteStyle
            confirmTitle="Ngừng xuất bản"
            cancelTitle="Huỷ bỏ"
            onClick={(): void => onUnpublish(originalRow.id)}
            comfirmMessage={
              <div>
                <h3 className="text-center">Thông báo</h3>
                <p className="pt-4 pb-3">
                  Bạn có chắc chắn muốn ngừng xuất bản landing page{' '}
                  <span className="font-weight-bold">{originalRow.lp_name}</span>?
                </p>
              </div>
            }
          >
            Ngừng xuất bản
          </ConfirmButton>
        );
      }
      case TableActions.View: {
        const originalRow = cellProps.row.original as any;
        return (
          <button
            key={TableActions.View}
            className="button-link"
            onClick={(e): void => {
              e.preventDefault();
              editRow(originalRow.id);
            }}
          >
            Xem
          </button>
        );
      }
      case TableActions.Rename: {
        const originalRow = cellProps.row.original as LandingPage;
        return (
          <RenameLandingPageButton
            key={TableActions.Rename}
            onClick={() => {}}
            modalTitle="Đổi tên"
            landingInputLabel="TÊN LANDING PAGE"
            landing_page_id={originalRow.id}
            name={originalRow.lp_name}
          >
            Đổi tên
          </RenameLandingPageButton>
        );
      }
      default:
        return <></>;
    }
  }

  function editRow(rowId: number): void {
    if (props.onEditRow) {
      props.onEditRow(rowId);
    }
  }

  function deleteRow(rowId: number): void {
    if (props.onDeleteRow) {
      props.onDeleteRow(rowId);
    }
  }

  function onUnpublish(rowId: number): void {
    if (props.onUnpublishAction) {
      props.onUnpublishAction(rowId);
    }
  }

  const tableInstance = useTable(
    {
      columns: actions.length ? colWithActions : columns,
      data,
    },
    usePagination
  ) as UseTableInstance;

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = tableInstance;

  const { pageIndex, pageSize } = state as UseTableState;

  const renderTable = (): React.ReactChild => {
    const Markup = useDivMarkup ? DivMarkup : TableMarkup;

    return (
      <Markup
        tableInstance={tableInstance}
        activeGroup={activeGroup}
        activeVersion={activeVersion}
        activeCampaign={activeCampaign}
        activePermission={activePermission}
        toggleViewAll={toggleViewAll}
        groupComponent={groupComponent}
      />
    );
  };

  const renderPagination = (): React.ReactChild => (
    <Pagination
      page={page}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      gotoPage={gotoPage}
      previousPage={previousPage}
      nextPage={nextPage}
      pageCount={pageCount}
      pageOptions={pageOptions}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );

  return (
    <>
      {renderTable()}
      {showPagination && renderPagination()}
    </>
  );
}

Table.defaultProps = defaultProps;
