"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentReducer = void 0;
const types_1 = require("../../auth/types");
const types_2 = require("../types");
const initialState = {
    packages: null,
    methods: null,
    shoppingCart: null,
    bankTransferInfo: null,
    paymentHistory: null,
};
const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.FetchPackagesList.success:
            return Object.assign({}, state, {
                packages: action.payload.data.team_packages,
            });
        case types_2.FetchMethodsList.success:
            const methods = action.payload.data.payment_methods
                .map((pm) => (Object.assign(Object.assign({}, pm), types_2.MethodInfo[pm.id])));
            return Object.assign({}, state, {
                methods
            });
        case types_2.CreateShoppingCart.success:
            return Object.assign({}, state, {
                shoppingCart: action.payload.data.shopping_cart,
            });
        case types_2.GetBankTransferInfo.success:
            return Object.assign({}, state, {
                bankTransferInfo: action.payload.data.bank_transfer_info,
            });
        case types_2.FetchPaymentHistory.success:
            return Object.assign({}, state, {
                paymentHistory: action.payload.data,
            });
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.paymentReducer = paymentReducer;
