"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamReducer = void 0;
const types_1 = require("../../auth/types");
const types_2 = require("../types");
const initialState = {
    teams: [],
    pendingInvites: [],
    members: [],
    config: {
        domainTeam: process.env.REACT_APP_DOMAIN,
        isHomeWorkspace: true
    },
    selectedTeam: null,
    error: null,
    licenseInfor: {},
};
// private type ActionTypes = any
const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.CreateTeamAction.success:
            return Object.assign({}, state, {
                error: null
            });
        case types_2.UpdateTeamAction.success:
            return Object.assign({}, state, {
                error: null,
                selectedTeam: Object.assign(Object.assign({}, state.selectedTeam), { team: action.selectedTeam })
            });
        case types_2.LeaveTeamAction.success:
            return state;
        case types_2.GetTeamListAction.success:
            return Object.assign({}, state, {
                teams: action.teams
            });
        case types_2.GetTeamAction.success:
        case types_2.GoToTeamAction.success:
            const isHome = !action.selectedTeam.team || !action.selectedTeam.team.id;
            return Object.assign({}, state, {
                selectedTeam: !isHome ? action.selectedTeam : null,
                licenseInfor: action.selectedTeam.license_infor,
                config: Object.assign(Object.assign({}, state.config), { isHomeWorkspace: isHome })
            });
        case types_2.TeamMembersAction.success:
            const members = action.payload.members;
            return Object.assign({}, state, {
                members: members && members.length > 0 ? members : [],
                pendingInvites: action.payload.pending_invitees,
            });
        // case GetTeamAction.error:
        // case GetTeamListAction.error:
        // case LeaveTeamAction.error:
        // case TeamMembersAction.error:
        // case JoinTeamAction.error:
        // case TransferRoleAction.error:
        // case InviteTeamAction.error:
        case types_2.UpdateTeamAction.error:
        case types_2.CreateTeamAction.error:
        case types_2.GoToTeamAction.error:
            return Object.assign({}, state, {
                error: action.error
            });
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.teamReducer = teamReducer;
