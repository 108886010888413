import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import * as ReactModal from 'react-modal';
import { History } from 'src/route/Route';
import { PackageCode, Team, TeamPackage, TeamPermissionService } from 'core';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  teamName: string;
}
ReactModal.setAppElement('#root');

const NeedToUpgradeModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const history = new History();
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const workingTeam: Team | null = teamPermissionService.WorkingTeam;
  const licencePackage: TeamPackage = teamPermissionService.LicensePackage;
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const rediectToPricingPage = () => {
    history.push('/payment/pricing-page');
    return;
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="package-upgrade-modal-container">
        <div className="modal__heading">
          <h3>Thông báo</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            {licencePackage && licencePackage.code !==  PackageCode.FREE ? 
              <p className="pb-0">Nhóm <span>{workingTeam ? workingTeam.name : ''}</span> đang sử dụng gói <span>{licencePackage?.name} (tối đa {teamPermissionService.MaxTeamSize} thành viên)</span> - hiện tại không thể thêm thành viên vào nhóm do vượt quá số lượng thành viên. Để thêm thành viên vào nhóm, vui lòng nâng cấp tài khoản với số lượng thành viên lớn hơn.</p>
            :
              <p className="pb-0">Nhóm <span>{workingTeam ? workingTeam.name : ''}</span> đang sử dụng gói <span>MIỄN PHÍ</span> - hiện tại không thể thêm thành viên vào nhóm. Để thêm thành viên vào nhóm, vui lòng nâng cấp tài khoản.</p> 
            }
          </div>
        </div>
        <div className="modal__bottom">
          <button onClick={rediectToPricingPage} className="btn-default ml-3">Nâng cấp ngay</button>
        </div>
      </div>
    </ReactModal>
  );
};

NeedToUpgradeModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default NeedToUpgradeModal;
