import { LandingPage, Project, ProjectState, userPublishedLandingPagesState } from 'core';
import { useFetchUserLandingPages } from 'pages/landing-page/hooks';
import { useFetchListProjects } from 'pages/project/hooks';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SideMenu, TopMenu } from '../menu';
import { Content } from './ChooseLandingpageContent';

interface AdsCreationProps {
  projects: ProjectState;
  landingPages: {
    userLandingPages: Array<LandingPage>;
  };
  userPublishedUrls: userPublishedLandingPagesState;
}

const AdsCreation = (props: AdsCreationProps) => {
  useFetchListProjects(props.projects);

  const projects: Array<Project> = props.projects.userProjects;

  const landingPages: Array<LandingPage> = props.landingPages.userLandingPages;

  useFetchUserLandingPages(landingPages);

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <Content
        projects={projects}
        landing_pages={landingPages}
        userPublishedUrls={props.userPublishedUrls}
      />
    </Styles>
  );
};

const mapStateToProps = (state: any) => {
  const { projects, landingPages, userPublishedUrls } = state;
  return {
    projects,
    landingPages,
    userPublishedUrls
  };
};

const AdsCreationPage = connect(mapStateToProps)(AdsCreation);
const Styles = styled.div`
  background-color: #f5f4f9;
  .page-heading p {
    margin-bottom: 40px;
  }
  label {
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.4px;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }
  .input-group {
    margin-bottom: 40px;
  }
  .filter-container select {
    padding: 15px 20px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    height: 52px;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.32px;
    color: #000000;
    border-radius: 6px!important;
    margin-bottom: 40px;
  }
  .box-shadow {
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    &.active {
      border: solid 2px #4c7cf3;
    }
    img {
      margin: 25px auto;
    }
  }
  .icon-anchor {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    background-color: #4c7cf3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-wrapper {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(216, 216, 216, 0.8);
  }
  .icon-wrapper.blur, 
  .box-title.blur {
    opacity: 0.15;
  }
  .box-title {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #000000;
  }
  .box-description {
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.26px;
    text-align: center;
    color: #666666;
    margin: 0 30px 30px;
  }
`;

export default AdsCreationPage;
