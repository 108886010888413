"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
const ApiAction_1 = require("../../actions/ApiAction");
const types_1 = require("../types");
class UserService {
    constructor() { }
    updateUser(params) {
        const asyncFunc = ApiAction_1.FuncType.Async;
        const type = types_1.UpdateUserAction.type;
        const apiAction = new ApiAction_1.ApiAction(type, asyncFunc, params);
        apiAction.dispatchAction();
    }
    resendVerifyEmail(params) {
        const apiAction = new ApiAction_1.ApiAction(types_1.ResendUserAction.type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    updateCredential(params) {
        const apiAction = new ApiAction_1.ApiAction(types_1.UpdateCredentialAction.type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
}
exports.UserService = UserService;
