import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SubmitButton from 'buttons/SubmitButton';
import { WorkSpaceService, User, AuthService, LoginParameter } from 'core';
import { FullHeader } from 'pages/header/FullHeader';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { History } from 'src/route/Route';
import { withCookies, Cookies } from 'react-cookie';
import { TokenCookies, Token, useRedirectDomain } from 'pages/auth/hooks';
import { toast } from 'react-toastify';

type TeamLoginProps = {
  cookies: Cookies;
}
type LocationState = {
  inviteToken?: string;
} | undefined;

const TeamLoginPage: React.FC<TeamLoginProps> = (props) => {
  const dispatch = useDispatch();
  const history = new History();
  const location = useLocation<LocationState>();
  const { redirect } = useRedirectDomain(props.cookies);
  const inviteToken = location && location.state ? location.state.inviteToken : null;

  const [teamNameState, setTeamNameState] = useState('');
  const [membersState, setMembersState] = useState([]);

  const service = new WorkSpaceService();
  const authService = new AuthService();

  useEffect(() => {
    if (!inviteToken) {
      history.push('/login');
      return;
    }

    const getTeamIntroParams = {
      inputParams: {
        token: inviteToken
      },
      callBackParams: {
        successCallback: (res: any) => {
          console.log('res', res);
          setTeamNameState(res.data.name);
          setMembersState(res.data.memebers);
        },
        errorCallback: (err: any) => {
          history.push('/link-expired');
        },
      }
    };

    service.getTeamIntro(getTeamIntroParams);
  }, []);

  const redirectToRegister = (): void => {
    history.push('/register');
  };

  const loginByFacebook = (response: ReactFacebookLoginInfo) => {
    const { accessToken } = response;
    if (accessToken) {
      const params: LoginParameter = {
        inputParams: {
          user_access_token: accessToken,
          provider: 'facebook',
        },
        callBackParams: {
          successCallback: (res: any) => {
            const cookies: TokenCookies = {
              [Token.UserToken]: res.token,
              [Token.MasterUserToken]: res.token,
            };
            redirect(cookies);
          },
          errorCallback: (res: any) => {
            console.log('onErrFb', res);
            toast.error('Có lỗi xảy ra. Không thể đăng nhập bằng Facebook.');
          }
        }
      };
      authService.login(params);
    }
  };

  const onLoginByGoogle = (response: GoogleLoginResponse) => {
    const { accessToken } = response;
    if (accessToken) {
      const params: LoginParameter = {
        inputParams: {
          user_access_token: accessToken,
          provider: 'google',
        },
        callBackParams: {
          successCallback: (res: any) => {
            const cookies: TokenCookies = {
              [Token.UserToken]: res.token,
              [Token.MasterUserToken]: res.token,
            };
            redirect(cookies);
          },
          errorCallback: (res: any) => {
            console.log('onErrGG', res);
            toast.error('Có lỗi xảy ra. Không thể đăng nhập bằng Google.');
          }
        }
      };
      authService.login(params);
    }
  };

  const onLoginByGoogleFailure = (response: GoogleLoginResponse) => {
    console.log('onLoginByGoogleFailure', response);
  };

  const renderListAvatarMember = () => {
    const memberList = membersState && membersState.slice(0, 5);

    const getDefaultAvatarUrl = (user: any, index: number) => {
      if(user.avatar_url) return user.avatar_url;
      const userEmail = user.email ? user.email : user.name;
      const number = userEmail.split('').map((x: string)=>x.charCodeAt(0)).reduce((a: number, b: number)=>a + b);
      const imageId = (number + index) % 120 + 1;
      const defaultAvatar = `${process.env.REACT_APP_TEMPLATE_CDN_URL}/defaul-avatar/avatar_${imageId}.jpg`;

      return defaultAvatar;
    };

    return (
      <div className="list-memeber">
        {memberList.map((m: any, index) => <div key={index} className="avatar-item"><img src={getDefaultAvatarUrl(m, index)} alt="Hình đại diện" /></div>)}
        {memberList.length > 5 && <div className="more-item">+ {memberList.length}</div>}
      </div>
    );
  };

  const renderNameMember = () => {
    const memberList = membersState && membersState.slice(0, 2);
    const other = membersState.length - 2;
    if (membersState.length > 2) {
      return (
        <h4>{memberList.map((m: any) => m.name).join(', ')} {other > 0 ? 'và ' + other : ''} người khác đã tham gia vào nhóm {teamNameState}</h4>  
      );
    }
    return (
      <h4>{memberList.map((m: any) => m.name).join(', ')} đã tham gia vào nhóm {teamNameState}</h4>
    );
  };

  const renderTeamIntroduction = () => {
    return (
      <div className="container team-login">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="text-center team-login__heading">
              <h2>Tham gia nhóm <span>{teamNameState}</span></h2>
              {renderListAvatarMember()}
              {renderNameMember()}
              <p>Hoạt động và nội dung trong trang này chỉ khả dụng cho thành viên của nhóm. Vui lòng đăng nhập tài khoản thành viên hợp lệ để có quyền truy cập thông tin.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="row">
              <div className="col-lg-6">
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
                  fields="name,email,picture"
                  textButton={'Tiếp tục với Facebook'}
                  callback={loginByFacebook}
                  icon={
                    <div className="float-icon">
                      <img src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/40x40/ic-facebook.png`} width="20" height="20" alt="logo" />
                    </div>}
                  scope="public_profile, email"
                />
              </div>
              <div className="col-lg-6">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_APP_ID as string}
                  buttonText='Tiếp tục với Google'
                  onSuccess={onLoginByGoogle}
                  onFailure={onLoginByGoogleFailure}
                  className="google-login-button"
                  cookiePolicy={'single_host_origin'}
                />
              </div>
            </div>
            <div className="separator-section">Hoặc</div>
          </div>
          <div className="col-lg-4 offset-lg-4 text-center">
            <SubmitButton
              alignLeft
              activeClassName="btn-outline-white"
              onClick={redirectToRegister}
            >
              Tạo tài khoản mới
            </SubmitButton>
            <div className="team-login__footer">Bạn đã có tài khoản? <Link to={(globalThis as any).useHistory.url('/')}>Đăng nhập ngay</Link></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Styles>
      <FullHeader />
      {renderTeamIntroduction()}
    </Styles>
  );
};

const Styles = styled.div`
  .google-login-button {
    position: relative;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px!important;
    box-shadow: none!important;
    -webkit-font-smoothing: antialiased;
    border: solid 1px rgba(216, 216, 216, 0.5)!important;
    transition: all 0.3s;
  }
  .google-login-button:hover {
    box-shadow: 0 1px 8px 0 rgba(0,0,0,0.10)!important;
  }
  .google-login-button > div {
    padding-left: 15px!important;
    position: absolute;
    background: transparent!important;
  }
  .google-login-button span {
    width: 100%;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .float-icon {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    top: 9px;
  }
  .kep-login-facebook.metro {
    position: relative;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
    padding: 10px;
    background-color: #ffffff;
    border-color: rgba(216, 216, 216, 0.5);
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    text-transform: none;
    transition: all 0.3s;
  }
  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }
  h3 {
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    color: #000000;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 40px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    color: #000000;
  }
  ul li, p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #666666;
  }
  label {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
    margin-top: 20px;
  }
  .team-login__heading {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .team-login__heading span{
    color: #1e0e62;
  }
  .separator-section {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    position: relative;
    font-family: Propins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e93;
  }
  .separator-section::after {
    content: '';
    position: absolute;
    top: 11px;
    left: calc(50% + 32px);
    width: 39px;
    height: 1px;
    background-color: rgba(216, 216, 216, 0.5);
  }
  .separator-section::before {
    content: '';
    position: absolute;
    top: 11px;
    right: calc(50% + 32px);
    width: 39px;
    height: 1px;
    background-color: rgba(216, 216, 216, 0.5);
  }
  .team-login__footer {
    margin-top: 30px;
  }
  .list-memeber {
    display: flex;
    justify-content: center;
  }
  .list-memeber .more-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    text-align: center;
    color: #ffffff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-color: #4c7cf3;
  }
  .avatar-item {
    margin-right: -8px;
  }
  .avatar-item img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #ffffff;
  }
`;

export default withCookies(TeamLoginPage);