"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignForecast = void 0;
class CampaignForecast {
    constructor() {
        this.clicks = 0;
        this.cost = 0;
    }
}
exports.CampaignForecast = CampaignForecast;
