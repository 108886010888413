import { Customer, LandingPage, Project, ProjectState } from 'core';
import { useFetchUserLandingPages } from 'pages/landing-page/hooks';
import { useFetchListProjects } from 'pages/project/hooks';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SideMenu, TopMenu } from '../menu';
import { Content } from './CustomersContent';
import { useFetchCustomers } from './hooks';


const Styles = styled.div`
  background-color: #f5f4f9;
  .filter-selection {
    border-radius: 6px!important;
  }
`;

interface CustomerProps {
  projects: ProjectState;
  customers: {
    data: Array<Customer>;
  };
  landingPages: {
    userLandingPages: Array<LandingPage>;
  };
}

const CustomerManagement = (props: CustomerProps) => {
  useFetchListProjects(props.projects);

  const projects: Array<Project> = props.projects.userProjects;

  const landingPages: Array<LandingPage> = props.landingPages.userLandingPages;

  useFetchUserLandingPages(landingPages);

  const customers: Array<Customer> = props.customers.data;
  useFetchCustomers();

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <Content
        projects={projects}
        landing_pages={landingPages}
        customers={customers}
      />
    </Styles>
  );
};

const mapStateToProps = (state: any) => {
  const { projects, landingPages, customers } = state;
  return {
    projects,
    landingPages,
    customers,
  };
};

const CustomerManagementPage = connect(mapStateToProps)(CustomerManagement);
export default CustomerManagementPage;
