"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeleteProjectAction = exports.DeleteImageAction = exports.DeleteProjectAction = exports.UpdateProjectAction = exports.CreateProjectAction = exports.GetProjectAction = exports.GetListProjectsAction = exports.UploadImageAction = exports.GetProjectImageAction = void 0;
exports.GetProjectImageAction = {
    type: 'GET_PROJECT_IMAGE',
    success: 'GET_PROJECT_IMAGE_SUCCESS',
    error: 'GET_PROJECT_IMAGE_ERROR',
};
exports.UploadImageAction = {
    type: 'UPLOAD_IMAGE',
    success: 'UPLOAD_IMAGE_SUCCESS',
    error: 'UPLOAD_IMAGE_ERROR',
};
exports.GetListProjectsAction = {
    type: 'GET_LIST_PROJECTS_REQUEST',
    allSuccess: 'GET_LIST_ALL_PROJECTS_SUCCESS',
    userSuccess: 'GET_LIST_USER_PROJECTS_SUCCESS',
    systemSuccess: 'GET_LIST_SYSTEM_PROJECTS_SUCCESS',
    error: 'GET_LIST_PROJECTS_ERROR',
};
exports.GetProjectAction = {
    type: 'GET_PROJECT',
    success: 'GET_PROJECT_SUCCESS',
    error: 'GET_PROJECT_ERROR',
    clear: 'CLEAR_SELECTED_PROJECT'
};
exports.CreateProjectAction = {
    type: 'CREATE_PROJECT',
    success: 'CREATE_PROJECT_SUCCESS',
    error: 'CREATE_PROJECT_ERROR',
};
exports.UpdateProjectAction = {
    type: 'UPDATE_PROJECT',
    success: 'UPDATE_PROJECT_SUCCESS',
    error: 'UPDATE_PROJECT_ERROR',
    image: 'UPDATE_SELECTED_IMAGE',
};
exports.DeleteProjectAction = {
    type: 'DELETE_PROJECT',
    success: 'DELETE_PROJECT_SUCCESS',
    error: 'DELETE_PROJECT_ERROR',
};
exports.DeleteImageAction = {
    type: 'DELETE_IMAGE',
    success: 'DELETE_IMAGE_SUCCESS',
    error: 'DELETE_IMAGE_ERROR',
};
exports.ValidateDeleteProjectAction = {
    type: 'VALIDATE_DELETE_PROJECT',
    success: "VALIDATE_DELETE_PROJECT_SUCCESS",
    error: "VALIDATE_DELETE_PROJECT_ERROR",
};
