import * as React from 'react';
import SubmitButton from 'buttons/SubmitButton';
import UpgradePackageModal from './modals/UpgradePackageModal';
import DowngradePackageModal from './modals/DowngradePackageModal';
import ContactHotlineModal from './modals/ContactHotlineModal';
import { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import numeral from 'numeral';
import ConfirmButton from 'components/buttons/ConfirmButton';
// import InputRange from 'react-input-range';
// import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PaymentService, Package, SelectedTeam, PaymentType, LicenseInfor, PackageCode, Team, PackageInfo, Packages, TeamPermissionService } from 'core';
import _ from 'lodash';
import { History } from 'src/route/Route';
import { useRouter } from 'utils/hooks';
interface PricingProps {

}

interface ParamType {
  teamId: string;
}

const getCurrentPackageByTeam = (packages: Package[], team: Team | undefined): (Package | undefined) => {
  if (!team) return packages[0];
  return packages.find((pk: Package) => pk.team_size.min <= team.num_members && team.num_members <= pk.team_size.max );
};

const getCurrentPackageByInput = (packages: Package[], input: number): (Package | undefined) => {
  return packages.find((pk: Package) => pk.team_size.min <= input && input <= pk.team_size.max );
};

const PackageCodeToValue = {
  [PackageCode.FREE]: 0,
  [PackageCode.STARTER]: 1,
  [PackageCode.PRO]: 2,
};

const Pricing = (props: PricingProps) => {
  const history = new History();
  history.hook();

  const packages: Packages = useSelector((state: any) => state.payment.packages);
  const team: SelectedTeam = useSelector((state: any) => state.team.selectedTeam);
  const teams: Team[] = useSelector((state: any) => state.team.teams);

  const [paymentFaqIndexActive, setPaymentFaqIndexActive] = useState(-1);
  const [selectNumberMembers, setSelectNumberMembers] = useState(1);
  const [isOpenUpgradePackageModal, setIsOpenUpgradePackageModal] = useState(false);
  const [isOpenDowngradePackageModal, setIsOpenDowngradePackageModal] = useState(false);
  const [isOpenContactHotlineModal, setIsOpenContactHotlineModal] = useState(false);
  const [currentPackage, setCurrentPackage] = useState<Package | null>();
  const [selectedPackage, setSelectedPackage] = useState<PackageInfo>();
  const [paymentTypeState, setPaymentTypeState] = useState<PaymentType>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [activedLicense, setActivedLicense] = useState<LicenseInfor>();
  const [teamPermissionService, setTeamPermissionService] = useState<TeamPermissionService>();
  const paymentService = new PaymentService();
  useEffect(() => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {
          console.log('res', res);
        },
        errorCallback: (err: any) => {
          console.log('err', err);
        }
      }
    };
    paymentService.fetchPackagesList(params);
  }, []);

  useEffect(() => {
    setTeamPermissionService(TeamPermissionService.getTeamPermission(selectedTeam?.id));
  }, [selectedTeam]);

  useEffect(() => {
    if (!_.isEmpty(packages)) {
      const key = selectedTeam && selectedTeam.id ? selectedTeam.id : 'home';
      setCurrentPackage(getCurrentPackageByTeam(packages[key], selectedTeam));
      setSelectNumberMembers(getSelectNumberMembers());
    }
  }, [packages]);

  useEffect(() => {
    if (teams.length && !_.isEmpty(packages)) {
      const defaultTeam = team ? teams.find(t => t.id == team.team.id) : teams[0];
      if (defaultTeam) {
        const key = defaultTeam && defaultTeam.id ? defaultTeam.id : 'home';

        setActivedLicense(defaultTeam.license_infor);
        setSelectedTeam(defaultTeam);
        setSelectNumberMembers(getSelectNumberMembers());
        setCurrentPackage(getCurrentPackageByTeam(packages[key], selectedTeam));
      }
    }
  }, [teams, team, packages]);

  const getSelectNumberMembers = () => {
    return (!team || team.team.num_members <= 5) ? 5 :
      (6 <= team.team.num_members && team.team.num_members <= 10) ? 10 :
      (11 <= team.team.num_members && team.team.num_members <= 20) ? 20 :
      (21 <= team.team.num_members && team.team.num_members <= 40) ? 40 :
      1;
  };

  const handleSubmitButton = (packageIndex: number): void => {
    if (!currentPackage) return;
    const paymentType = getPaymentType(currentPackage.packages[packageIndex]);
    setPaymentTypeState(paymentType);

    if (!paymentType) {
      setIsOpenContactHotlineModal(true);
      return;
    } 
    if (paymentType == PaymentType.Upgrade) {
      setIsOpenUpgradePackageModal(true);
      setSelectedPackage(currentPackage.packages[packageIndex]);
      return;
    }
    if (paymentType == PaymentType.Downgrade) {
      setIsOpenDowngradePackageModal(true);
      setSelectedPackage(currentPackage.packages[packageIndex]);
      return;
    }
    history.push('/payment/payment-method', { selectedPackage: currentPackage.packages[packageIndex], paymentType, selectNumberMembers, selectedTeam });
  };

  const getPaymentType = (selectPackage: PackageInfo): PaymentType | undefined => {
    if (!selectedTeam && activedLicense && activedLicense.package.code == PackageCode.FREE) return PaymentType.Create_New;
    if (selectedTeam && selectedTeam.license_infor.package.code == PackageCode.FREE) return PaymentType.Create_New;
    return isUpgrade(selectPackage) ? PaymentType.Upgrade :
      isDowngrade(selectPackage) ? PaymentType.Downgrade :
      handleMannually(selectPackage) ? undefined :
      PaymentType.Create_New;
  };
  
  const isUpgrade = (selectPackage: PackageInfo) => {
    const usedPackageCodeValue = activedLicense ? PackageCodeToValue[activedLicense.package.code] : PackageCodeToValue[PackageCode.FREE];
    const selectPackageCodeValue = PackageCodeToValue[selectPackage.code];
    if (!selectedTeam && !teamPermissionService) { //Home workspace
      return (selectPackageCodeValue > usedPackageCodeValue);
    } else {
      const numMembers = teamPermissionService?.MaxTeamSize as number;
      return (selectPackageCodeValue == usedPackageCodeValue && selectNumberMembers > numMembers) ||
        (selectPackageCodeValue > usedPackageCodeValue && selectNumberMembers == numMembers) ||
        (selectPackageCodeValue > usedPackageCodeValue && selectNumberMembers > numMembers); 
    }
      
  };
  
  const isDowngrade = (selectPackage: PackageInfo) => {
    const usedPackageCodeValue = activedLicense ? PackageCodeToValue[activedLicense.package.code] : PackageCodeToValue[PackageCode.FREE];
    const selectPackageCodeValue = PackageCodeToValue[selectPackage.code];
    if (!selectedTeam && !teamPermissionService) { //Home workspace
      return (selectPackageCodeValue < usedPackageCodeValue);
    } else {
      const numMembers = teamPermissionService?.MaxTeamSize as number;
      return (selectPackageCodeValue == usedPackageCodeValue && selectNumberMembers < numMembers) ||
        (selectPackageCodeValue < usedPackageCodeValue && selectNumberMembers == numMembers) ||
        (selectPackageCodeValue < usedPackageCodeValue && selectNumberMembers < numMembers);
    }
  };
  
  const handleMannually = (selectPackage: PackageInfo) => {
    const usedPackageCodeValue = activedLicense ? PackageCodeToValue[activedLicense.package.code] : PackageCodeToValue[PackageCode.FREE];
    const selectPackageCodeValue = PackageCodeToValue[selectPackage.code];
    if (!selectedTeam && !teamPermissionService) return false;
    else {
      const numMembers = teamPermissionService?.MaxTeamSize as number;
      return (selectPackageCodeValue < usedPackageCodeValue && selectNumberMembers > numMembers) ||
        (selectPackageCodeValue > usedPackageCodeValue && selectNumberMembers < numMembers);
    }
    
  };

  const defaultSelectedTeam = () => {
    if (selectedTeam && selectedTeam.id) return selectedTeam.id;
    return '';
  };

  const goBackHistory = () => {
    history.push('/dashboard');
  };

  const cricleItem = <span className="cricleBullet rounded-circle"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <g> <g> <path fill="#FFF" d="M0 0H10.667V10.667H0z" opacity=".01" transform="translate(-222 -648) translate(205 395) translate(15 247) translate(0 3) translate(2.667 3)"/> <path fill="#4C7CF3" fillRule="nonzero" d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z" transform="translate(-222 -648) translate(205 395) translate(15 247) translate(0 3) translate(2.667 3)"/> </g> </g> </g> </g> </g> </g> </svg></span>;
  return currentPackage ? (
    <Styles>
      <DowngradePackageModal 
        isOpen={isOpenDowngradePackageModal} 
        closeModal={() => {setIsOpenDowngradePackageModal(false);}}
        selectedPackage={selectedPackage}
        selectNumberMembers={selectNumberMembers}
        paymentType={paymentTypeState}
        selectedTeam={selectedTeam}
        activedLicense={activedLicense}
        goNext={() => {}}
      />
      <UpgradePackageModal 
        isOpen={isOpenUpgradePackageModal} 
        closeModal={() => {setIsOpenUpgradePackageModal(false);}}
        selectedPackage={selectedPackage}
        selectNumberMembers={selectNumberMembers}
        paymentType={paymentTypeState}
        selectedTeam={selectedTeam}
        activedLicense={activedLicense}
        goNext={() => {}}
      />
      <ContactHotlineModal 
        isOpen={isOpenContactHotlineModal}
        closeModal={() => {setIsOpenContactHotlineModal(false);}}
        goNext={() => {}}
      />
      <span onClick={goBackHistory} className="closeButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-38 -18) translate(30 10) translate(8 8)"/> </g> </g> </g> </g> </svg>
      </span>
      <div className="phone-support">Hỗ trợ thanh toán: <span>0904.220.612</span></div>
      <div className="phone-support fixed-bottom">Hỗ trợ thanh toán: <span>0904.220.612</span></div>
      <div className="container">
        <div className="page-heading row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="text-center">Trải nghiệm tạo & tối ưu quảng cáo dễ dàng, hiệu quả, không cần thầy hướng dẫn. </h1>
            <p className="text-center">Dành riêng cho sales bất động sản.
            Nhận thêm nhiều khách, tiết kiệm ngân sách khi sử dụng các gói cước trả phí được thiết kế theo tăng trưởng kinh doanh của bạn.</p>
          </div>
        </div>
        <div className="input-slider-wrapper row">
          <div className="col-lg-4 offset-lg-2">
            <h3>Chọn nhóm nâng cấp</h3>
            <select
              className="form-control w-100 select-number-member"
              onChange={(e) => {
                  const teamId  = parseInt(e.target.value);
                  const team = teams.find((t: Team) => t.id == teamId);
                  const license = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data-license') || '');
                  const key = team ? team.id : 'home';
                  setSelectedTeam(team);
                  setActivedLicense(license);
                  setCurrentPackage(getCurrentPackageByTeam(packages[key], team));
                  if (team && team?.num_members) {
                    (team?.num_members <= 5) ? setSelectNumberMembers(5) :
                    (team?.num_members <= 10) ? setSelectNumberMembers(10) :
                    (team?.num_members <= 20) ? setSelectNumberMembers(20) :
                    setSelectNumberMembers(40);
                  } else {
                    setSelectNumberMembers(5);
                  }
      
                  
                }}
              value={defaultSelectedTeam()}
            >
              {teams.map((t: Team) => (
                <option key={t.id} value={t.id} data-license={JSON.stringify(t.license_infor)}>{t.name}</option>
              ))}
            </select>
            {selectedTeam && selectedTeam.id !== null && <p>Nhóm này hiện có {selectedTeam ? selectedTeam.num_members : '1'} thành viên</p>}
          </div>
          <div className="col-lg-4">
            <h3>Quy mô nhóm</h3>
            <select
              className="form-control w-100 select-number-member"
              onChange={(e) => {
                const val = parseInt(e.target.value);
                const key = selectedTeam ? selectedTeam.id : 'home';
                setCurrentPackage(getCurrentPackageByInput(packages[key], val));
                setSelectNumberMembers(val);
              }}
              value={selectNumberMembers}
            >
              <option disabled={selectedTeam && selectedTeam.num_members > 5} value={5}>1 đến 5 thành viên</option>
              {selectedTeam && selectedTeam.id && <option disabled={selectedTeam && selectedTeam.num_members > 10} value={10}>6 đến 10 thành viên</option>}
              {selectedTeam && selectedTeam.id && <option disabled={selectedTeam && selectedTeam.num_members > 20 } value={20}>11 đến 20 thành viên</option>}
              {selectedTeam && selectedTeam.id && <option disabled={selectedTeam && selectedTeam.num_members > 40} value={40}>21 đến 40 thành viên</option>}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1 d-flex">
            <div className="col-lg-4">
              <div className="pricing-box">
                <div className="pricing-box__heading">
                  <h2>{currentPackage.packages[0].name}</h2>
                  <p>{currentPackage.packages[0].description}</p>
                </div>
                <div className="pricing-box__content">
                  <div className="pricing-box__content--wrapper">
                    <h1>Miễn phí</h1>
                    <ul className="permisstion-list list-unstyled pl-0">
                      {currentPackage.packages[0].permission_descs.map((des: string) => (
                        <li key={des}>{cricleItem}{des}</li>
                      ))}
                    </ul>
                  </div>
                  <Link 
                    to={{
                      pathname: (globalThis as any).useHistory.getPath('/home'),
                    }} 
                      className="btn btn-default w-100"
                    >
                    Dùng thử
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-box">
                <div className="pricing-box__heading">
                  <div className="pricing-box__recommend-tag">{currentPackage.packages[1].head_line}</div>
                  <h2>{currentPackage.packages[1].name}</h2>
                  <p>{currentPackage.packages[1].description}</p>
                </div>
                <div className="pricing-box__content">
                  <div className="pricing-box__content--wrapper">
                    <h1>{numeral(currentPackage.packages[1].amount).format('0,0')}₫ <span className="price-unit">/{currentPackage.packages[1].num_months == 1 ? '' : currentPackage.packages[1].num_months}tháng</span></h1>
                    <p>Tất cả quyền lợi của gói Gói Miễn phí, và:</p>
                    <ul className="permisstion-list list-unstyled pl-0">
                      {currentPackage.packages[1].permission_descs.map((des: string) => (
                        <li key={des}>{cricleItem}{des}</li>
                      ))}
                    </ul>
                  </div>
                  <SubmitButton
                    className="btn-submit"
                    onClick={() => {handleSubmitButton(1);}}
                    >
                    Nâng cấp ngay
                  </SubmitButton>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
            <div className="pricing-box">
              <div className="pricing-box__heading">
                <div className="pricing-box__recommend-tag">{currentPackage.packages[2].head_line}</div>
                <h2>{currentPackage.packages[2].name}</h2>
                <p>{currentPackage.packages[2].description}</p>
              </div>
              <div className="pricing-box__content">
                <div className="pricing-box__content--wrapper">
                  <h1>{numeral(currentPackage.packages[2].amount).format('0,0')}₫ <span className="price-unit">/{currentPackage.packages[2].num_months == 1 ? '' : currentPackage.packages[2].num_months}tháng</span></h1>
                  <p>Tất cả quyền lợi của gói Gói Cơ bản, và:</p>
                  <ul className="permisstion-list list-unstyled pl-0">
                    {currentPackage.packages[2].permission_descs.map((des: string) => (
                      <li key={des}>{cricleItem}{des}</li>
                    ))}
                  </ul>
                </div>
                <SubmitButton
                  className="btn-submit"
                  onClick={() => {handleSubmitButton(2);}}
                >
                  Nâng cấp ngay
                </SubmitButton>
              </div>
            </div>
          </div>
          </div>
        </div>
        {/* <div className="faq-section">
          <div className="row">
            <div className="col-lg-4 offset-lg-1">
              <img
                className="mb-8"
                src="https://rei-cdn.ymeet.me/pimage/home-page/img-questions.png"
                alt="icon img"
                width="48"
                height="48"
              />
              <div className="faq-section__title">
                Các câu hỏi
                thường gặp
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-section__content">
                <h3 className="faq-section__heading">THANH TOÁN</h3>
                <div className="questions">
                  <div className="item">
                    <div
                      onClick={() => {
                        setPaymentFaqIndexActive(0);
                      }}
                      className="questions-items__heading"
                    >
                      Có các phương thức thanh toán nào?
                      <span className="questions-items__heading--icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          {' '}
                          <g fill="none" fillRule="evenodd">
                            {' '}
                            <g>
                              {' '}
                              <g>
                                {' '}
                                <g>
                                  {' '}
                                  <g>
                                    {' '}
                                    <g>
                                      {' '}
                                      <path
                                        fill="#FFF"
                                        d="M0 0H20V20H0z"
                                        opacity=".01"
                                        transform="translate(-1105 -1727) translate(155 1430) translate(400) translate(0 295) translate(550 2)"
                                      />{' '}
                                      <g fill="#000" fillRule="nonzero">
                                        {' '}
                                        <path
                                          d="M7.619 5.714h4.79c.208 0 .368.038.502.11.135.073.24.179.312.313.072.134.11.294.11.502v.055c0 .208-.038.368-.11.503-.072.134-.178.24-.312.312-.134.072-.294.11-.503.11H7.62v4.79c0 .208-.038.368-.11.502-.072.135-.178.24-.312.312-.135.072-.295.11-.503.11h-.055c-.208 0-.368-.038-.502-.11-.133-.07-.242-.18-.312-.312-.073-.134-.11-.294-.11-.503V7.62H.924c-.208 0-.368-.038-.503-.11-.134-.073-.24-.178-.312-.312C.038 7.062 0 6.902 0 6.694v-.055c0-.208.038-.368.11-.502.072-.135.178-.24.312-.312.134-.072.295-.11.503-.11h4.79V.924c0-.208.037-.368.11-.503.072-.134.178-.24.312-.312C6.27.038 6.43 0 6.639 0h.055c.208 0 .368.038.503.11.134.072.24.178.312.312.071.134.11.295.11.503v4.79z"
                                          transform="translate(-1105 -1727) translate(155 1430) translate(400) translate(0 295) translate(550 2) translate(3.333 3.333)"
                                        />{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>{' '}
                                </g>{' '}
                              </g>{' '}
                            </g>{' '}
                          </g>{' '}
                        </svg>
                      </span>
                    </div>
                    <p
                      className={`${
                        paymentFaqIndexActive == 0 ? 'pb-8 block' : 'pb-0 hidden'
                      } transition-all`}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but
                      also the leap into electronic typesetting, remaining
                      essentially unchanged. It was popularised in the 1960s with
                      the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing software
                      like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                  </div>
                  <div className="item">
                    <div
                      onClick={() => {
                        setPaymentFaqIndexActive(1);
                      }}
                      className="questions-items__heading"
                    >
                      Sau khi thanh toán xong, tài khoản chưa được nâng cấp thì làm
                      thế nào?
                      <span className="questions-items__heading--icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          {' '}
                          <g fill="none" fillRule="evenodd">
                            {' '}
                            <g>
                              {' '}
                              <g>
                                {' '}
                                <g>
                                  {' '}
                                  <g>
                                    {' '}
                                    <g>
                                      {' '}
                                      <path
                                        fill="#FFF"
                                        d="M0 0H20V20H0z"
                                        opacity=".01"
                                        transform="translate(-1105 -1727) translate(155 1430) translate(400) translate(0 295) translate(550 2)"
                                      />{' '}
                                      <g fill="#000" fillRule="nonzero">
                                        {' '}
                                        <path
                                          d="M7.619 5.714h4.79c.208 0 .368.038.502.11.135.073.24.179.312.313.072.134.11.294.11.502v.055c0 .208-.038.368-.11.503-.072.134-.178.24-.312.312-.134.072-.294.11-.503.11H7.62v4.79c0 .208-.038.368-.11.502-.072.135-.178.24-.312.312-.135.072-.295.11-.503.11h-.055c-.208 0-.368-.038-.502-.11-.133-.07-.242-.18-.312-.312-.073-.134-.11-.294-.11-.503V7.62H.924c-.208 0-.368-.038-.503-.11-.134-.073-.24-.178-.312-.312C.038 7.062 0 6.902 0 6.694v-.055c0-.208.038-.368.11-.502.072-.135.178-.24.312-.312.134-.072.295-.11.503-.11h4.79V.924c0-.208.037-.368.11-.503.072-.134.178-.24.312-.312C6.27.038 6.43 0 6.639 0h.055c.208 0 .368.038.503.11.134.072.24.178.312.312.071.134.11.295.11.503v4.79z"
                                          transform="translate(-1105 -1727) translate(155 1430) translate(400) translate(0 295) translate(550 2) translate(3.333 3.333)"
                                        />{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>{' '}
                                </g>{' '}
                              </g>{' '}
                            </g>{' '}
                          </g>{' '}
                        </svg>
                      </span>
                    </div>
                    <p
                      className={`${
                        paymentFaqIndexActive == 1 ? 'pb-8 block' : 'pb-0 hidden'
                      } transition-all`}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but
                      also the leap into electronic typesetting, remaining
                      essentially unchanged. It was popularised in the 1960s with
                      the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing software
                      like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                  </div>
                  <div className="item">
                    <div
                      onClick={() => {
                        setPaymentFaqIndexActive(2);
                      }}
                      className="questions-items__heading"
                    >
                      Tôi có thể xuất hóa đơn đỏ được không?
                      <span className="questions-items__heading--icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          {' '}
                          <g fill="none" fillRule="evenodd">
                            {' '}
                            <g>
                              {' '}
                              <g>
                                {' '}
                                <g>
                                  {' '}
                                  <g>
                                    {' '}
                                    <g>
                                      {' '}
                                      <path
                                        fill="#FFF"
                                        d="M0 0H20V20H0z"
                                        opacity=".01"
                                        transform="translate(-1105 -1727) translate(155 1430) translate(400) translate(0 295) translate(550 2)"
                                      />{' '}
                                      <g fill="#000" fillRule="nonzero">
                                        {' '}
                                        <path
                                          d="M7.619 5.714h4.79c.208 0 .368.038.502.11.135.073.24.179.312.313.072.134.11.294.11.502v.055c0 .208-.038.368-.11.503-.072.134-.178.24-.312.312-.134.072-.294.11-.503.11H7.62v4.79c0 .208-.038.368-.11.502-.072.135-.178.24-.312.312-.135.072-.295.11-.503.11h-.055c-.208 0-.368-.038-.502-.11-.133-.07-.242-.18-.312-.312-.073-.134-.11-.294-.11-.503V7.62H.924c-.208 0-.368-.038-.503-.11-.134-.073-.24-.178-.312-.312C.038 7.062 0 6.902 0 6.694v-.055c0-.208.038-.368.11-.502.072-.135.178-.24.312-.312.134-.072.295-.11.503-.11h4.79V.924c0-.208.037-.368.11-.503.072-.134.178-.24.312-.312C6.27.038 6.43 0 6.639 0h.055c.208 0 .368.038.503.11.134.072.24.178.312.312.071.134.11.295.11.503v4.79z"
                                          transform="translate(-1105 -1727) translate(155 1430) translate(400) translate(0 295) translate(550 2) translate(3.333 3.333)"
                                        />{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>{' '}
                                </g>{' '}
                              </g>{' '}
                            </g>{' '}
                          </g>{' '}
                        </svg>
                      </span>
                    </div>
                    <p
                      className={`${
                        paymentFaqIndexActive == 2 ? 'pb-8 block' : 'pb-0 hidden'
                      } transition-all`}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but
                      also the leap into electronic typesetting, remaining
                      essentially unchanged. It was popularised in the 1960s with
                      the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing software
                      like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Styles>
  ) : null;
};

const mapStateToProps = (state: any) => {
  return {
  };
};

const Styles = styled.div`
  background-color: #f5f4f9;
  min-height: 100vh;
  padding-bottom: 100px;
  .closeButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 10px;
    left: 30px;
  }
  .phone-support {
    display: inlne-block;
    position: absolute;
    color: #000000;
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    top: 10px;
    right: 30px;
    padding: 6px 12px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
  }
  .phone-support.fixed-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 0;
    border: 0;
    text-align: center;
    border-top: solid 1px #d8d8d8;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
  }
  .phone-support span {
    font-family: Propins-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #4c7cf3; 
  }
  .page-heading p {
    margin-bottom: 30px;
    font-family: Propins-Regular;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.25px;
    text-align: center;
    color: #666666;
  }
  h1 {
    color: #000000;
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
  }
  .pricing-box {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 0.5px #d8d8d8;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .pricing-box__recommend-tag {
    position: absolute;
    top: 0;
    padding: 6px;
    left: 0;
    width: 100%;
    background-image: linear-gradient(96deg, #4c7cf3, #47d1ff);
    font-family: Propins-Bold;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
  }
  .pricing-box__heading {
    position: relative;
    padding: 15px;
    padding-top: 45px;
  }
  .pricing-box__heading h2 {
    font-family: Propins-Bold;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #000000;
  }
  .pricing-box__heading p {
    background-image: linear-gradient(105deg, var(--dodger-blue), #47d1ff 100%);
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.2px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .pricing-box__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 15px;
    padding-top: 20px;
    border-top: solid 1px rgba(216, 216, 216, 0.5);
  }
  .pricing-box__content ul {
    margin-bottom: 30px;
    padding-left: 36px;
    margin-top: 24px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.18px;
    color: #000000;
  }
  .pricing-box__content .price-unit {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    color: #666666;
  }
  .pricing-box__content--wrapper p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #666666;
  }
  .faq-section {
    margin-top: 40px;
    border-top: solid 1px #d8d8d8;
    padding: 40px 0;
  }
  .faq-section__heading {
    font-family: Propins-Bold;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.16px;
    color: #8e8e93;
  }
  .questions-items__heading {
    cursor: pointer;
    position: relative;
    font-family: Propins-Medium;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
    padding-bottom: 8px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 15px;
  }
  .questions-items__heading--icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  .faq-section__title {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #000000;
    margin-top: 15px;
  }
  .cricleBullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #4c7cf3;
    margin-right: 5px;
  }
  .permisstion-list li {
    margin-bottom: 10px;
  }
  .input-slider-wrapper {
    margin-bottom: 54px;
  }
  .input-slider-wrapper h3 {
    font-family: Propins-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.45px;
    color: #000000;
  }
  .input-slider-wrapper p {
    margin-bottom: 0;
    margin-top: 6px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.2px;
    color: #666666;
  }
  .input-range__label--min, .input-range__label--max {
    display: none;
  }
  .input-range__track {
    height: 9px!important;
    background-color: rgba(216, 216, 216, 0.4)!important;
  }
  .input-range__track--active {
    background-color: #4c7cf3!important;
  }
  .input-range__slider {
    background: #4c7cf3!important;
    border: 4px solid #ffffff!important;
    margin-top: -.75rem!important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%)!important;
  }
  .input-range__label .input-range__label-container {
    position: relative;
    top: -12px;
    padding: 1px 6px;
    border-radius: 4px;
    background-color: #010245;
    font-family: Propins-Medium;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .input-range__label .input-range__label-container::before {
    content: '';
    left: calc(50% - 7px);
    bottom: -7px;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #010245;
  }
`;

const PricingPage = connect(mapStateToProps)(Pricing);
export default PricingPage;
