"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeywordDataInfoService = void 0;
const entities_1 = require("../../entities");
const ads_campaign_1 = require("../../ads-campaign");
const frameworks_1 = require("../../frameworks");
const AgeRange_1 = require("../../entities/GoogleAds/AgeRange");
const BaseDataInfoService_1 = require("./BaseDataInfoService");
class KeywordDataInfoService extends BaseDataInfoService_1.BaseDataInfoService {
    setOperationKeywordGroup(keywordGroup, oldKeywordGroups) {
        const getCampaignAges = () => {
            var _a;
            const state = frameworks_1.store.getState();
            if ((_a = state.adsCampaign.currentCampaign) === null || _a === void 0 ? void 0 : _a.ages) {
                return state.adsCampaign.currentCampaign.ages.map(x => {
                    const age = new AgeRange_1.AgeRange();
                    age.type = x.type;
                    return age;
                });
            }
            return [];
        };
        const setCreateOperation = (keywordGroup) => {
            keywordGroup.operation = ads_campaign_1.OperationTypes.CREATE;
            keywordGroup.ages = getCampaignAges();
            keywordGroup.ages.forEach(x => { x.operation = ads_campaign_1.OperationTypes.CREATE; });
            keywordGroup.keywords.forEach(x => x.operation = ads_campaign_1.OperationTypes.CREATE);
        };
        try {
            if (!keywordGroup.resource_name) {
                setCreateOperation(keywordGroup);
            }
            else {
                const oldKeywordGroup = oldKeywordGroups.find(x => x.resource_name === keywordGroup.resource_name);
                if (!oldKeywordGroup) {
                    // No change
                    return;
                }
                // 1.Set ages operation
                // keywordGroup.ages = this.setArrayOperation(oldKeywordGroup.ages, getCampaignAges());    
                keywordGroup.ages = oldKeywordGroup.ages;
                // 2. Set Keywords operation
                keywordGroup.keywords = this.setArrayOperation(oldKeywordGroup.keywords, keywordGroup.keywords);
                if (!keywordGroup.isEqual(oldKeywordGroup) || keywordGroup.ages.some(x => { return x.operation !== undefined; }) || keywordGroup.keywords.some(x => { return x.operation !== undefined; })) {
                    keywordGroup.operation = ads_campaign_1.OperationTypes.UPDATE;
                }
            }
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    }
    setOperation(keywordData) {
        try {
            const state = frameworks_1.store.getState();
            const oldKeywordData = state.adsCampaign.AdGroups ? state.adsCampaign.AdGroups : new entities_1.KeywordData();
            // 1. Keyword Group
            keywordData.keyword_groups.forEach(x => this.setOperationKeywordGroup(x, oldKeywordData.keyword_groups));
            const deletingKeywordGroup = oldKeywordData.keyword_groups.filter(x => {
                return x && x.resource_name && !keywordData.keyword_groups.find(y => (y === null || y === void 0 ? void 0 : y.resource_name) == x.resource_name);
            });
            deletingKeywordGroup.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
            keywordData.keyword_groups = [...keywordData.keyword_groups, ...deletingKeywordGroup];
            // 2. Negative keyword
            keywordData.negative_keywords = this.setArrayOperation(oldKeywordData.negative_keywords, keywordData.negative_keywords);
            const deletingNegativeKeyword = oldKeywordData.negative_keywords.filter(x => {
                return x && x.resource_name && !keywordData.negative_keywords.find(y => (y === null || y === void 0 ? void 0 : y.resource_name) == x.resource_name);
            });
            deletingNegativeKeyword.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
            keywordData.negative_keywords = [...keywordData.negative_keywords, ...deletingNegativeKeyword];
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    }
}
exports.KeywordDataInfoService = KeywordDataInfoService;
