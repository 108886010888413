import Table, { TableActions }  from 'components/tables/base';
import { CustomCellProps } from 'components/tables/base/index.d';
import { CampaignStatus, CampaignCompositeStatus, CampaignConnectedAccount, TeamPermissionService, CampaignColumnActive, CampaignInfo, CampaignListReport, CampaignReport } from 'core';

import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import numeral from 'numeral';
import { CampaignUpdateInfo, listCampaignColumnActive } from '.';
import classNames from 'classnames';

interface PropsType {
  setIsOpenNeedToUpgradeModalState: (value: boolean) => void;
  selectedColumnActive: CampaignColumnActive[];
  onToggleAd: (campaign: CampaignUpdateInfo) => void;
}

function CampaignMetrics(props: PropsType) {
  const {selectedColumnActive, setIsOpenNeedToUpgradeModalState} = props;
  const campaignReports = useSelector<any, CampaignListReport | null>(state => state.adsReport.campaignReports);
  const reportData = campaignReports ? campaignReports.campaigns : [];
  
  const history = (globalThis as any).useHistory;
  history.hook();
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();

  const onOnOffClick = (campaign: CampaignInfo, rowData: CampaignReport) => {
    if(!teamPermissionService.CanUseAdCreation){
      return;
    }
    
    const landing_page_id: number | null = rowData.landing_page_id;
    props.onToggleAd({
      connected_account_id: rowData.connected_account.connected_account_id ,
      status: campaign.status, 
      resource_name: campaign.resource_name, 
      landing_page_id
    });
  };

  const renderCompositeStatusLabel = (status: CampaignCompositeStatus) => {
    if(status === CampaignCompositeStatus.DRAFT) return 'Bản nháp';
    if(status === CampaignCompositeStatus.ELIGIBLE) return 'Đủ điều kiện';
    if(status === CampaignCompositeStatus.NOT_ELIGIBLE) return 'Không đủ điều kiện';
    if(status === CampaignCompositeStatus.PENDING) return 'Đang chờ xử lý';
    if(status === CampaignCompositeStatus.ENDED) return 'Đã kết thúc';
    if(status === CampaignCompositeStatus.PAUSED) return 'Tạm hoãn';
    if(status === CampaignCompositeStatus.REMOVED) return 'Đã loại bỏ';
    return 'Không rõ ràng';
  };

  const columns = React.useMemo(
    () => {
      const columns = [
        {
          Header: 'TÊN DỰ ÁN',
          accessor: 'ProjectName',
        },
        {
          Header: 'BẬT/TẮT',
          accessor: 'campaign',
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            const campaign: CampaignInfo | null = props.cell.value as CampaignInfo;
            const className = campaign.status === CampaignStatus.ENABLED ? 'active' : '';
            const disabledClassName = !teamPermissionService.CanUseAdCreation ? 'disabled' : '';
            const landing_page_id = props.cell.row.original.landing_page_id;
            if(props.cell.row.original.campaign.status === CampaignStatus.DRAFT) return '';
            return (<div className="d-flex">
              <div className="d-flex">
                <button onClick={() => onOnOffClick(props.cell.value, props.cell.row.original)} className={`${className} ${disabledClassName} switch-box`}>
                  <div className="switch-toggle"></div>
                </button>
              </div>
              
              {!teamPermissionService.CanUseAdCreation && <div className="tooltip-container">
                <span id="TooltipNoteOnOff" className="account-type pro">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> 
                    <g fill="none" fillRule="evenodd"> <g fill="#FFF" fillRule="nonzero"> <g> <g> <g> <path d="M11.875 9.57l.74-3.016.046.002c.243 0 .466-.06.67-.18.203-.12.365-.28.487-.481.121-.202.182-.426.182-.673 0-.243-.06-.465-.18-.666-.12-.202-.28-.362-.484-.482-.203-.12-.428-.18-.675-.18-.243 0-.464.06-.664.18s-.359.281-.478.484c-.12.204-.18.425-.18.664 0 .198.038.38.115.55l.02.04-1.582 1.142c-.156.11-.283.092-.382-.057L7.648 4.12l.022-.01c.2-.12.361-.28.481-.482.12-.202.18-.426.18-.673 0-.239-.06-.46-.18-.663-.12-.204-.28-.365-.481-.485-.202-.12-.424-.18-.667-.18-.247 0-.471.06-.673.18-.2.12-.361.28-.481.482-.12.201-.18.423-.18.666 0 .247.061.471.183.673.121.201.283.362.484.481l.018.01-1.864 2.79c-.046.065-.096.104-.151.118-.055.013-.126-.01-.214-.072L2.531 5.801l.015-.03c.077-.168.115-.351.115-.549 0-.24-.06-.46-.18-.664-.12-.203-.28-.364-.481-.484-.201-.12-.424-.18-.667-.18-.247 0-.47.06-.672.18-.201.12-.362.28-.482.482-.12.2-.179.423-.179.666 0 .247.06.471.182.673.122.2.282.361.482.481s.422.18.67.18l.057-.003.746 3.017h9.738zm-1.96 2.803c.452 0 .803-.11 1.054-.33.25-.22.429-.547.535-.98l.171-.684H2.33l.166.684c.106.433.285.76.535.98.251.22.604.33 1.06.33h5.824z" transform="translate(-1199 -50) translate(1066 37) translate(128 11) translate(5 2)"/> </g> </g> </g> </g> </g> 
                  </svg>
                </span>
                <div className="tooltip-content">
                  Tính năng này dành riêng cho tài khoản sử dụng Gói cơ bản và Gói chuyên nghiệp
                </div>
              </div>}
              </div>
            );
          },
          width: 100
        },
        {
          Header: 'TÊN CHIẾN DỊCH',
          accessor: 'CampaignName',
        },
      ];
      const optionalColumns = [
        {
          Header: 'TÀI KHOẢN QC',
          accessor: 'connected_account',
          width: 130,
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            const campaignConnectedAccount: CampaignConnectedAccount | null = props.cell.value as CampaignConnectedAccount; 
            let paId = campaignConnectedAccount.provider_account_id;
            paId = paId.replace(/[^0-9]/g, '');
            paId = paId.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            return (
              <div className="campaign-account">
                {campaignConnectedAccount.name}
                <p>{paId}</p>
              </div>
            );
          },
        },
        {
          Header: 'TRẠNG THÁI P.PHỐI',
          accessor: 'campaign.composite_status',
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            return <div>{renderCompositeStatusLabel(props.cell.value)}</div>;
          },
          width: 88
        },
        {
          Header: 'CHI PHÍ',
          accessor: 'MetricCost',
          width: 88,
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            return <div>{numeral(props.cell.value).format('0,0 $')}</div>;
          }
        },
        {
          Header: 'LƯỢT H.THỊ',
          accessor: 'MetricImpressions',
          width: 88
        },
        {
          Header: 'LƯỢT CLICK',
          accessor: 'MetricNumClick',
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            return (
              <div className="d-flex">
                <span className="col-dercoration d-flex flex-column">
                  <img src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/40x40/gray-arrow.png`} width="18" height="18" alt="logo" />
                  {props.data[0].MetricRateClick}%
                </span>
                <span>{props.cell.value}</span>
              </div>
            );
          },
          width: 88
        },
        {
          Header: 'TƯƠNG TÁC',
          accessor: 'MetricNumEngagement',
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            return (
              <div className="d-flex">
                <span className="col-dercoration d-flex flex-column">
                  <img src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/40x40/gray-arrow.png`} width="18" height="18" alt="logo" />
                  {props.data[0].MetricRateEngagement}%
                </span>
                <span>{props.cell.value}</span>
              </div>
            );
          },
          width: 88
        },
        {
          Header: 'CHUYỂN ĐỔI',
          accessor: 'MetricNumConversions',
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            return (
              <div className="d-flex">
                <span className="col-dercoration d-flex flex-column">
                  <img src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/40x40/gray-arrow.png`} width="18" height="18" alt="logo" />
                  {props.data[0].MetricRateConversions}%
                </span>
                <span>{props.cell.value}</span>
              </div>
            );
          },
          width: 88
        },
        {
          Header: 'HIỆU QUẢ',
          accessor: 'MetricEffective',
          Cell: (props: CustomCellProps<CampaignReport>): React.ReactChild => {
            return (
              <>
                <div className="text-wrapper">{props.cell.value}</div>
              </>
            );
          },
          width: 88
        },
      ];
      listCampaignColumnActive.forEach((e, i) => {
        if (selectedColumnActive.includes(e)) {
          columns.push(optionalColumns[i] as never);
        }
      });
      return columns;
    },
    [selectedColumnActive]
  );

  const redirectToEditCampaign = (campaignId: number): void => {
    if (!teamPermissionService.CanUseAdCreation) {
      props.setIsOpenNeedToUpgradeModalState(true);
    } else {
      const campaign = reportData.find(x => x.id === campaignId);
      if (campaign) {
        history.push('/create-google-campaign?resource_name=' + campaign.campaign.resource_name);
      }
    }
  };

  return (
    <Styles>
      <Table 
        columns={columns}
        actions={[
          TableActions.Edit,
        ]}
        activeGroup={false} 
        onEditRow={redirectToEditCampaign}
        data={reportData}
        // hiddenColumns={[]}
      />
    </Styles>
  );
}

const Styles = styled.div`
  padding: 10px 30px 40px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  .switch-box {
    border: 0;
    position: relative;
    width: 44px;
    height: 25px;
    background: #fff;
    border-radius: 12.5px;
    background-color: #d8d8d8;
  }
  .switch-box.disabled {
    opacity: 0.5;
  }
  .switch-box.active {
    background-color: #4c7cf3;
  }  
  .switch-toggle {
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 999px;
    width: 21px;
    height: 21px;
    background-color: #ffffff;
  }
  .switch-box.active .switch-toggle {
    right: 2px; 
    left: auto;
  }
  .text-wrapper {
    width: 44px;
    height: 18px;
    border-radius: 9px;
    background-color: rgba(34, 197, 61, 0.2);
    font-family: Propins-Medium;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: center;
    color: #22c53d;
  }
  .campaign-account p {
    margin-bottom: 0;
    font-family: Propins;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    color: #666666;
  }

  #TooltipNoteOnOff {
    margin-left: 8px;
    margin-right: 8px;
  }

  .tooltip-content {
    width: 275px;
    height: 30px;
    display: none;
    position: absolute;
    left: 1px;
    top: 30px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    box-shadow: 0 2px 10.5px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px rgba(255, 255, 255, 0.15);
    background-color: rgba(51, 51, 51, 0.75);
    font-family: Propins;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.07px;
    text-align: center;
    color: #ffffff;
    z-index: 22;
  }
  
  .tooltip-container {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }

  .tooltip-container:hover .tooltip-content {
    display: block;
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      :first-child {
        td {
          :first-child {
            border-top-left-radius: 12px;
          }
          :last-child {
            border-top-right-radius: 12px;
          }
        }
      }
      :last-child {
        td {
          border-bottom: 0;
          :first-child {
            border-bottom-left-radius: 12px;
          }
          :last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    thead th {
      font-family: Propins;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #aeaeb2;
      padding: 15px;
      @media (max-width: 1300px) {
        padding: 10px;
      }
    }

    tbody {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      td:last-child {
        .btn-group {
          opacity: 0;
          visibility: hidden;
        }
      }
      td:nth-child(3) {
        border-right: 1px solid #d8d8d8;
      }
      tr:hover {
        td:last-child {
          .btn-group {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    tbody th,
    tbody td {
      margin: 0;
      padding: 20px;
      border-bottom: solid 1px rgba(216, 216, 216, 0.5);
      @media (max-width: 1300px) {
        padding: 8px 10px;
      }
      :last-child {
        border-right: 0;
        text-align: left;
      }
    }

    tbody th {
      padding: 12px 15px;
      background-color: #f7f8f8;
      font-family: Propins;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: #aeaeb2;
      position: relative;
      @media (max-width: 1300px) {
        font-size: 14px;
        padding: 8px 10px;
      }
    }
    tbody th:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 45px;
      border-radius: 1.5px;
      background-color: #4c7cf3;
      @media (max-width: 1300px) {
        height: 34px;
      }
    }
    .col-dercoration {
      font-family: Propins-Regular;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.24px;
      text-align: center;
      color: #8e8e93;
      margin-right: 10px;
      position: absolute;
      left: -9px;
    }
    tbody td {
      font-family: Propins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #000000;
      position: relative;
      @media (max-width: 1300px) {
        font-size: 14px;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }
  
  .account-type {
    background-color: #aeaeb2;
    margin-top: 2px;
    margin-left: 5px;
    display: inline-block;
    padding: 2px 6px;
    text-transform: uppercase;
    font-family: Propins-Medium;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.71px;
    color: #ffffff;
    border-radius: 4px;
    border: solid 1px #ffffff;
    cursor: pointer;
  }
  .account-type.pro {
    display: inline-flex;
    background-image: linear-gradient(to left, #ea4c89, #e47b49 0%);
  }
  
  .pagination {
    padding: 0.5rem;
  }
  @media (max-width: 1300px) {
    padding: 10px 20px;
  }
`;

export default CampaignMetrics;