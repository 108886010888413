"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateCredentialAction = exports.ResendUserAction = exports.GetUserAction = exports.UpdateUserAction = void 0;
exports.UpdateUserAction = {
    type: 'UPDATE_USER',
    success: 'UPDATE_USER_SUCCESS',
    error: 'UPDATE_USER_ERROR'
};
exports.GetUserAction = {
    type: 'GET_USER',
    success: 'GET_USER_SUCCESS',
    error: 'GET_USER_ERROR',
};
exports.ResendUserAction = {
    type: 'RESEND_VERIFY_EMAIL',
    success: 'RESEND_VERIFY_EMAIL_SUCCESS',
    error: 'RESEND_VERIFY_EMAIL_ERROR',
};
exports.UpdateCredentialAction = {
    type: 'UPDATE_CREDENTIAL',
    success: 'UPDATE_CREDENTIAL_SUCCESS',
    error: 'UPDATE_CREDENTIAL_ERROR',
};
