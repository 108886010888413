import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { ButtonProps } from 'presenter';
import Button from './Button';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import { UpdateLandingPageData, updateLandingPage } from 'core';
import '../../stylesheets/Modal.css';

const customStyles = {
  overlay: {
    backgroundColor       : 'rgba(0, 0, 0, 0.3)'
  },
  content : {
    width                 : '615px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    border                : '0',
    borderRadius          : '12px',
    boxShadow             : '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
    transform             : 'translate(-50%, -50%)'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');
interface DuplicateLPButtonProps extends ButtonProps {
  modalTitle: string;
  customButtonStyle?: string;
  landingInputLabel: string;
  landing_page_id: number;
  name: string;
}
const RenameLPButtonComponent: React.FC<DuplicateLPButtonProps> = (props) => {
  const dispatch = useDispatch();
  const { landing_page_id, name } = props;
  const [modalIsOpen,setIsOpen] = useState(false);
  const [lpName, setLpName] = useState(name || '');
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => { mounted.current = false; };
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    if (!mounted.current) return;
    setIsOpen(false);
  };

  const onRenameLandingPage = async () => {
    const data: UpdateLandingPageData = {
      name: lpName.toString(),
      landing_page_id
    };
    try {
      await dispatch(updateLandingPage(data));
      toast.success('Cập nhật thành công');
      closeModal();
    } catch(e) {
      // TODO: handle error
    }
  };

  const onLpNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setLpName(e.currentTarget.value);
  };

  const keyPressed = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onRenameLandingPage();
    }
  };

  return (
    <React.Fragment>
      <Button className={props.customButtonStyle} onClick={openModal}>
        {props.children}
      </Button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h3 className="text-center">{props.modalTitle}</h3>
        <div className="pb-3 mt-3">
          <label>{props.landingInputLabel}</label>
          <input className="form-control" autoFocus={true} value={lpName} placeholder="Nhập tên landing page" onChange={onLpNameChange} onKeyPress={keyPressed}/>
        </div>
        <div className="modal-group-bottons d-flex justify-content-end">
          <CancelButton onClick={closeModal}>Hủy bỏ</CancelButton>
          <SubmitButton alignRight isDisabled={!lpName.length} onClick={onRenameLandingPage}>Xác nhận</SubmitButton>
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

RenameLPButtonComponent.defaultProps = {
  customButtonStyle: 'button-link',
};

export default RenameLPButtonComponent;
