import * as React from 'react';
import { Link } from 'react-router-dom';
import 'stylesheets/App.css';

const EmptyPage: React.FC = () => {
  return (
    <div className="empty-content">
      <div className="media align-items-center">
        <img
          className="mr-5"
          src={`${process.env.REACT_APP_TEMPLATE_CDN_URL}/494x466/leads-empty.png`}
          alt="Create landing page image"
        />
        <div className="media-body">
          <h3>Khách hàng của bạn</h3>
          <p>
            Danh sách khách hàng thu được từ các nguồn sẽ được hiển thị ở đây.
          </p>
        </div>
      </div>
    </div>
  );
};

export { EmptyPage };
