import {
  InitialCampaignParams,
  UserPublishedLandingPagesData,
  CampaignTypes,
  GoogleService,
  UpdateCampaignIP,
  LandingPageWithGoogleAdsCampaign,
  GoogleCampaignAds,
  UpdateGoogleCampaignStatusCB,
  ErrorMessageService,
  GetLPWithGoogleAdsCampaignParams,
  GetLPWithGoogleAdsCampaignSuccessType,
  CampaignStatus,
  AppState
  } from 'core';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useMemo } from 'react';
import SubmitButton from 'buttons/SubmitButton';
import Select, { ValueType } from 'react-select';
import 'stylesheets/App.css';
import { History } from 'src/route/Route';
import { useKeyboard } from '@react-aria/interactions';
import { FormData, initGoogleCampaignEditSchema } from './forms/validate';
import { useGoogleForm, useFetchUserPublishedGoogleUrls } from 'utils/hooks';
import { SettingStyle, LandingPageDescription } from './style';
import { ErrorInput } from './fields/ErrorInput';
import { InitialGoogleCampaignProps } from '.';
import { SelectAccountGoogleModal, WarningLandingPageRunningAds } from './modals';
import { toast } from 'react-toastify';
import { LocationDescriptor } from 'history';
import { LoadingScreen } from 'components/common/LoadingScreen';
import { ControllerRenderProps } from 'react-hook-form';
import { useSelector } from 'react-redux';

type OptionType = { label: string; value: number; resource_name?: GoogleCampaignAds[]; currentAccount?: boolean };

const InitialGoogleCampaign: React.FC<InitialGoogleCampaignProps> = (props) => {
  const history = new History();
  const [openModal, setOpenModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const urlData = useSelector<any, LandingPageWithGoogleAdsCampaign[] | undefined>((state: AppState) => state.adsCampaign.currentLandingPage?.landing_pages);
  
  const isEditMode = props.isEditMode || !!props.currentCampaign;
  const [selectedLandingPage, setSelectedLandingPage] = useState<LandingPageWithGoogleAdsCampaign>();
  const [pendingLandingPage, setPendingLandingPage] = useState<LandingPageWithGoogleAdsCampaign>();
  // const urlData: LandingPageWithGoogleAdsCampaign[] | undefined = props.landingPages;
  const [groupOptions, setGroupOptions] = useState<{label: string; options: OptionType[]}[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingLandingPage, setLoadingLandingPage] = useState(false);
  const service = new GoogleService();

  const { accounts, 
    initialCampaign,
    initGoogleCampaignSchema,
    useForm, 
    vestResolver, 
    Controller, 
    CreateFields,
    changeCampaignStatus
  } = useGoogleForm();
  
  const { CampaignTypeField } = CreateFields;
  const getDefaultValues = (): FormData => {
    return {
      CampaignName: props.currentCampaign ? props.currentCampaign.name : `Tìm kiếm ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`,
      LandingPageId: props.currentCampaign ? props.currentCampaign.landing_page_id : 0,
      CampaignType: props.currentCampaign ? props.currentCampaign.campaign_type : CampaignTypes.Search,
      account_id: props.currentCampaign ? props.currentCampaign.account_id : 0
    };
  };
  useFetchUserPublishedGoogleUrls();

  const { register, reset, setValue, handleSubmit, watch, formState: {errors}, control } = useForm<FormData>({
    resolver: vestResolver(initGoogleCampaignSchema),
    defaultValues: getDefaultValues()
  });

  const watchAccountId = watch('account_id');

  const options = (isCurrentAcc = true, urlData?: LandingPageWithGoogleAdsCampaign[]): OptionType[] => {
    if (!urlData) {
      return [];
    }

    const ret = urlData.filter((el: LandingPageWithGoogleAdsCampaign)=>{
      const campaignAccountSet = new Set(el.google_campaigns?.map(el=>el.connected_account_id));
      const isCurrentUser = campaignAccountSet.has(watchAccountId) && campaignAccountSet.size === 1;
      return isCurrentAcc ? (!el.google_campaigns || el.google_campaigns?.length === 0 || isCurrentUser) : campaignAccountSet.size > 0 && !isCurrentUser;
    }).map(item => ({
      currentAccount: isCurrentAcc,
      value: item.lp_id, 
      label: item.url, 
      resource_name: item.google_campaigns?.length > 0 ? item.google_campaigns : undefined
    }));
    return ret; 
  };
  
  useEffect(() => {
    const groupData = [
      {
        label: 'LANDING PAGE SẴN SÀNG CHẠY QUẢNG CÁO',
        options: options(true, urlData)
      },{
        label: 'LANDING PAGE ĐANG CHẠY QUẢNG CÁO',
        options: options(false, urlData)
      }
    ];
    setGroupOptions(groupData);
  }, [urlData]);
  
  useEffect(() => {
    reset(getDefaultValues());
  }, [props.currentCampaign]);
  
  useEffect(() => {
    if(!props.currentCampaign?.landing_page_id || !urlData) {
      setSelectedLandingPage(undefined);
      return;
    }
    
    const pInfo = urlData.find((lp: LandingPageWithGoogleAdsCampaign) => lp.lp_id === props.currentCampaign?.landing_page_id);

    setSelectedLandingPage(pInfo);
  }, [urlData]);

  useEffect(() => {
    if(!urlData && !openModal) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [urlData, openModal]);
  
  useEffect(()=>{  
    if(errors.account_id || !watchAccountId){
      if(props.currentCampaign?.account_id)
        setValue('account_id', props.currentCampaign?.account_id);
      else {
        setOpenModal(true);
      }
    }
  }, [props.currentCampaign, watchAccountId, errors]);
  
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e: any) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        //TODO: handle press enter 
        if(!errors) handleSubmit(handleCreateCampaign);
      }
    }
  });

  const getSelectedOption = (landingPage: LandingPageWithGoogleAdsCampaign | undefined): OptionType | null => {
    if (!landingPage) {
      return null;
    }
    const option: OptionType = {
      value: landingPage.lp_id,
      label: landingPage.url
    };
    return option;
  };
  
  const customStyles = {
    container: () => ({
      width: '100%',
      marginBottom: 10,
    }),
    valueContainer: (provided: object) => ({
      ...provided,
      padding: '15px 20px',
    }),
    input: (provided: object) => ({
      ...provided,
      margin: 0,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      color: '#000000'
    }),
    singleValue: (provided: object) => ({
      ...provided,
      color: '#000000'
    }),
    placeholder: (provided: object) => ({
      ...provided,
      fontFamily: 'Propins-Regular',
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: -0.32,
      color: '#666666'
    }),
  };

  const handleCreateCampaign = (data: FormData) => {
    if(isEditMode) {
      const inputParams: UpdateCampaignIP = {
        name: data.CampaignName
      };
      service.updateCampaign({inputParams: inputParams});
      history.push('/google-ads-setting');
      return;
    }
    
    const params: InitialCampaignParams = {
      inputParams: {
        name: data.CampaignName,
        account_id: data.account_id,
        landing_page_id: data.LandingPageId,
        campaign_type: data.CampaignType
      }
    };
    initialCampaign(params);
    history.push('/google-ads-setting');
  };
  
  const goBackHistory = () => {
    service.startCreatingNewCampaign();

    history.push('/google-ads-dashboard');
  };
  
  register('account_id', { required: true });
  
  const closeModal = (data: number) => {
    setValue('account_id', data);
    setOpenModal(false);
  };

  const closeWarningModal = () => {
    setOpenWarningModal(false);
    setLoadingLandingPage(false);
  };
  
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  
  const groupBadgeStyles = {
    display: 'none'
  };
  
  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>10</span>
    </div>
  );
  
  const handleStopCampaign = () => {
    const callBackParams = {
      successCallback: (res: any) => {
        toast.success('Đã tạm dừng chiến dịch thành công');
        closeWarningModal();
        const updateData: GetLPWithGoogleAdsCampaignParams = {
          inputParams: {},
          callBackParams: {
            successCallback: (res: GetLPWithGoogleAdsCampaignSuccessType)=>{
              console.log('handleStopCampaign success', res);
              const pInfo = pendingLandingPage;
              setSelectedLandingPage(pInfo);
              const pId = pInfo ? pInfo.lp_id : 0;
              setValue('LandingPageId', pId);
              setLoadingLandingPage(false);
            }
          }
        };
        service.getLPWithGoogleAdsCampaign(updateData);
      },
      errorCallback: (err: any) => {
        setLoadingLandingPage(false);
        toast.error(ErrorMessageService.getErrorMessageByCode(err.code));
      }
    } as UpdateGoogleCampaignStatusCB;

    const params = {
      inputParams: {
        resource_names: pendingLandingPage?.google_campaigns?.map((el: GoogleCampaignAds)=>el.campaign_resource_name) || [],
        status: CampaignStatus.PAUSED,
        // landing_page_id: pendingLandingPage?.lp_id
      },
      callBackParams
    };
    changeCampaignStatus(params);
  };
  
  const reviewAds = () => {
      const search = 'lp_id=' + pendingLandingPage?.lp_id + '&ad_account=' + watchAccountId;
      // history.push({
      //   pathname: '/google-ads-dashboard', 
      //   search
      // } as LocationDescriptor);
      window.open('/dashboard/google-ads-dashboard?' + search, '_blank');
  };

  const onLandingPageSelectChanged = (field: ControllerRenderProps<FormData, 'LandingPageId'>, selectedOption: ValueType<OptionType, false>, action: any) => {
    const value = selectedOption ? (selectedOption as OptionType).value : 0;
    const pInfo = props.landingPages?.find((lp: LandingPageWithGoogleAdsCampaign) => lp.lp_id === value);
    
    if(!selectedOption?.currentAccount) {
      setOpenWarningModal(true);
      setLoadingLandingPage(true);
      setPendingLandingPage(pInfo);
      return;
    }
    
    const pId = pInfo ? pInfo.lp_id : 0;
    setSelectedLandingPage(pInfo);
    field.onChange(pId);
  };
  
  if (loading) return <LoadingScreen />;

  return (<>
  <SelectAccountGoogleModal 
    director="google"
    isOpen={openModal} 
    closeModal={closeModal}
    connectedAccounts={accounts}
  />
  <WarningLandingPageRunningAds 
    isOpen={openWarningModal} 
    closeModal={closeWarningModal}
    reviewAds={reviewAds}
    submit={handleStopCampaign}
  />
  
  <SettingStyle>
    <div className={'container container-min-height'}>
      <Link to='#' onClick={goBackHistory} className="closeButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-38 -18) translate(30 10) translate(8 8)"/> </g> </g> </g> </g> </svg>
      </Link>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">  
            <div className="filter-container">
              <div className="page-heading pl-0 pr-0 pb-3">
                <div className="d-flex justify-content-start align-items-start">
                  <h1 className="page-title">Tạo chiến dịch</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={'input-group ' + errors.CampaignName ?? 'error'}>
                    <label>1. Tên chiến dịch</label>
                    <div className="warning-text">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"> <g fill="none" fillRule="evenodd"> <g fill="#8E8E93" fillRule="nonzero"> <g> <g> <g> <path d="M7 0c3.875 0 7 3.125 7 7s-3.125 7-7 7-7-3.125-7-7 3.125-7 7-7zm.426 5.833h-1.77c-.104 0-.17.017-.227.047-.057.03-.102.075-.132.132-.03.058-.047.123-.047.227v.227c0 .09.008.122.022.157.015.034.037.062.067.085.03.022.059.037.146.06l.58.144c.13.033.175.055.22.09.043.032.077.076.098.126.023.052.034.1.034.235v2.19c0 .135-.011.184-.034.235-.021.05-.055.094-.099.127-.045.034-.089.057-.22.09l-.58.144c-.086.023-.116.037-.145.06-.03.023-.052.051-.067.085-.014.034-.022.067-.022.156v.228c0 .104.016.17.047.226.03.057.075.102.132.133.057.03.123.046.226.046h2.981c.104 0 .17-.016.227-.046.056-.03.102-.076.132-.133.03-.057.047-.122.047-.226v-.228c0-.09-.008-.121-.022-.156-.015-.034-.037-.062-.067-.085-.03-.023-.059-.037-.146-.06l-.58-.144c-.13-.033-.175-.056-.22-.09-.043-.033-.077-.076-.099-.127-.022-.051-.033-.1-.033-.235V6.282c0-.156-.016-.213-.047-.27-.03-.056-.075-.102-.132-.132-.057-.03-.114-.047-.27-.047zM7 2.625c-.642 0-1.167.525-1.167 1.167 0 .641.525 1.166 1.167 1.166s1.167-.525 1.167-1.166c0-.642-.525-1.167-1.167-1.167z" transform="translate(-163 -113) translate(155 94) translate(8 8) translate(0 11)"/> </g> </g> </g> </g> </g> </svg>
                      Bạn có thể sử dụng một tên khác gắn liền với tên dự án để phân biệt các chiến dịch quảng cáo thuận tiện hơn.
                    </div>
                    <input
                      {...register('CampaignName')}
                      type="text"
                      autoFocus={true}
                      className="form-control"
                      placeholder="Nhập tên chiến dịch"
                    />
                  </div>
                  {errors.CampaignName && <ErrorInput text={errors.CampaignName.message}/>}

                  <div className={'input-group position-relative ' + errors.LandingPageId ?? 'error'}>
                    <label>2. Chọn landing page để chạy quảng cáo</label>
                    <Controller
                      name="LandingPageId"
                      control={control}
                      render={({field})=><Select
                        onChange={(selectedOption: ValueType<OptionType, false>, action: any) => {
                          onLandingPageSelectChanged(field, selectedOption, action);
                        }}
                        isDisabled={loadingLandingPage || isEditMode}
                        styles={customStyles}
                        isSearchable={true}
                        isLoading={loadingLandingPage}
                        placeholder={loadingLandingPage ? 'Đang cập nhật danh sách, vui lòng đợi...' : 'Tìm kiếm hoặc lựa chọn landing page đang chạy'}
                        noOptionsMessage={({ inputValue: string }) => 'Landingpage url không hợp lệ. Vui lòng kiểm tra lại.'}
                        formatGroupLabel={formatGroupLabel}
                        defaultValue={getSelectedOption(selectedLandingPage)}
                        value={getSelectedOption(selectedLandingPage)}
                        options={groupOptions}
                      />}
                    />
                    {errors.LandingPageId && <ErrorInput text={errors.LandingPageId.message}/>}
                  </div>
                  {selectedLandingPage && <LandingPageDescription>Dự án: {selectedLandingPage.pname}</LandingPageDescription>}
                  <div className="input-group">
                    <label>3. Chọn loại chiến dịch</label>
                    <Controller
                      name="CampaignType"
                      control={control}
                      defaultValue={getDefaultValues().CampaignType}
                      render={({field})=><CampaignTypeField
                        campaignType={field.value as CampaignTypes} 
                        onChange={(type: CampaignTypes) => field.onChange(type)}
                      />}
                    />
                    {errors.CampaignType && <ErrorInput text={errors.CampaignType.message}/>}
                  </div>
                  <div className="input-group input-buttons">
                    <SubmitButton onClick={handleSubmit(handleCreateCampaign)}>Tiếp tục</SubmitButton>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    </SettingStyle>
  </>);
};

export default InitialGoogleCampaign;