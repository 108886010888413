"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignDataInfoService = void 0;
const frameworks_1 = require("../../frameworks");
const ads_campaign_1 = require("../../ads-campaign");
const moment = require("moment");
class CampaignDataInfoService {
    _defaultLanguages() {
        const defaultLanguages = [ads_campaign_1.GoogleAdsEnum.Languages.Vietnamese];
        return defaultLanguages.map(x => {
            return {
                language_constant: x
            };
        });
    }
    _defaultAges() {
        const defaultAges = [
            ads_campaign_1.GoogleAdsEnum.AgeRangeType.AGE_RANGE_25_34,
            ads_campaign_1.GoogleAdsEnum.AgeRangeType.AGE_RANGE_35_44,
            ads_campaign_1.GoogleAdsEnum.AgeRangeType.AGE_RANGE_45_54,
            ads_campaign_1.GoogleAdsEnum.AgeRangeType.AGE_RANGE_55_64,
            ads_campaign_1.GoogleAdsEnum.AgeRangeType.AGE_RANGE_65_UP
        ];
        return defaultAges.map(x => {
            return {
                type: x
            };
        });
    }
    _defaultLocations() {
        // 2704 is Viet Nam
        const defaultLocations = ['geoTargetConstants/2704'];
        return defaultLocations.map(x => {
            return {
                geo_target_constant: x
            };
        });
    }
    _defaultDevices() {
        const defaultDevices = [
            ads_campaign_1.GoogleAdsEnum.Device.MOBILE,
            ads_campaign_1.GoogleAdsEnum.Device.TABLET,
            ads_campaign_1.GoogleAdsEnum.Device.DESKTOP
        ];
        return defaultDevices.map(x => {
            return {
                type: x
            };
        });
    }
    static getDefaultSchedule() {
        const everyDay = {
            day_of_week: ads_campaign_1.GoogleAdsEnum.DayOfWeek.EVERYDAY,
            start_hour: 0,
            start_minute: ads_campaign_1.GoogleAdsEnum.MinuteOfHour.ZERO,
            end_hour: 24,
            end_minute: ads_campaign_1.GoogleAdsEnum.MinuteOfHour.ZERO
        };
        return [everyDay];
    }
    _defaultDailySchedules() {
        return CampaignDataInfoService.getDefaultSchedule();
    }
    getStateData() {
        const state = frameworks_1.store.getState();
        return state.adsCampaign.currentCampaign;
    }
    initNewCampaignData(initData) {
        const campaignName = initData.name;
        const defaultData = {
            name: campaignName,
            account_id: initData.account_id,
            landing_page_id: initData.landing_page_id,
            campaign_type: initData.campaign_type,
            start_date: new Date(),
            end_date: undefined,
            daily_schedules: this._defaultDailySchedules(),
            bidding_strategy: {
                auto_optimize_bid: true,
                max_cpc: undefined,
            },
            bidding_strategy_type: ads_campaign_1.GoogleAdsEnum.BiddingStrategyType.TARGET_SPEND,
            maximize_conversion_value: {
                target_roas: undefined
            },
            campaign_budget: {
                amount: undefined,
                name: campaignName,
                // operation: OperationTypes.CREATE
            },
            languages: this._defaultLanguages(),
            ages: this._defaultAges(),
            devices: this._defaultDevices(),
            locations: this._defaultLocations(),
            site_link: undefined,
            call: undefined
        };
        return this._setOperation(defaultData, ads_campaign_1.OperationTypes.CREATE);
    }
    generateBiddingStrategyName(campaignName) {
        return campaignName + ' ' + new Date().getTime();
    }
    // public static resetOperation(data: ICampaignData) : ICampaignData {
    //   const service = new CampaignDataInfoService();
    //   return service._setOperation(data, undefined)
    // }
    _setOperation(data, operation) {
        var _a, _b, _c, _d, _e;
        const returnData = data;
        try {
            returnData.operation = operation;
            (_a = returnData.daily_schedules) === null || _a === void 0 ? void 0 : _a.forEach(x => x.operation = operation);
            returnData.campaign_budget.operation = operation;
            (_b = returnData.languages) === null || _b === void 0 ? void 0 : _b.forEach(x => x.operation = operation);
            (_c = returnData.ages) === null || _c === void 0 ? void 0 : _c.forEach(x => x.operation = operation);
            (_d = returnData.devices) === null || _d === void 0 ? void 0 : _d.forEach(x => x.operation = operation);
            (_e = returnData.locations) === null || _e === void 0 ? void 0 : _e.forEach(x => x.operation = operation);
            if (returnData.site_link && returnData.site_link.sitelinks.length > 0) {
                returnData.site_link.operation = operation;
                returnData.site_link.sitelinks.forEach(x => {
                    x.operation = operation;
                    x.description1 = x.description1 === "" ? undefined : x.description1;
                    x.description2 = x.description2 === "" ? undefined : x.description2;
                });
            }
            else {
                returnData.site_link = undefined;
            }
            if (returnData.call && returnData.call.calls.length > 0) {
                returnData.call.operation = operation;
                returnData.call.calls.forEach(x => x.operation = operation);
            }
            else {
                returnData.call = undefined;
            }
        }
        catch (ex) {
            console.log('_setCreateOperation exception', ex);
            throw ex;
        }
        return returnData;
    }
    _shallowEqual(object1, object2) {
        if (!object1 || !object2) {
            return false;
        }
        const keys1 = Object.keys(object1);
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }
    _setOperationForCreatedResource(newData, oldArr) {
        const oldData = oldArr.find(x => x.resource_name === newData.resource_name);
        if (!oldData) {
            return;
        }
        if (this._shallowEqual(oldData, newData)) {
            newData.operation = undefined;
            return;
        }
        newData.operation = ads_campaign_1.OperationTypes.UPDATE;
    }
    _setUpdateOperation(oldData, newData, hasChangedAtInitStep) {
        const setOperation = (oldArrParam, newArrParam, isEqual) => {
            const oldArr = oldArrParam ? oldArrParam : [];
            const newArr = newArrParam ? newArrParam : [];
            let createdResources = newArr.filter(x => { return x.resource_name; });
            let notCreatedResources = newArr.filter(x => { return !x.resource_name; });
            let deletedResources = oldArr.filter(x => {
                return x.resource_name && !newArr.find(y => y.resource_name == x.resource_name);
            }).map(y => {
                // Try to clone to new object so that when we set operation to remove, it doesn't effect to original data
                return Object.assign({}, y);
            });
            // If a resource which is already created then remove and add again --> We should not touch it.
            if (isEqual) {
                // Find the created again resources
                const createdAgainResources = deletedResources.filter((x) => {
                    return notCreatedResources.some((y) => { return isEqual(x, y); });
                });
                if (createdAgainResources.length > 0) {
                    // Keep it 
                    createdResources = [...createdResources, ...createdAgainResources];
                    // Remove it from list of resources which are going to be created
                    notCreatedResources = notCreatedResources.filter((x) => {
                        return !createdAgainResources.some((y) => {
                            return isEqual(x, y);
                        });
                    });
                    // Remove it from list of resources which are going to be removed
                    deletedResources = deletedResources.filter((x) => {
                        return !createdAgainResources.some((y) => {
                            return isEqual(x, y);
                        });
                    });
                }
            }
            deletedResources.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
            createdResources.forEach(x => this._setOperationForCreatedResource(x, oldArr));
            notCreatedResources.forEach(x => x.operation = ads_campaign_1.OperationTypes.CREATE);
            return [...createdResources, ...notCreatedResources, ...deletedResources];
        };
        const setOperationSitelink = (oldData, newData) => {
            if (!oldData && !newData) {
                return undefined;
            }
            if (!oldData) {
                if (newData) {
                    newData.operation = ads_campaign_1.OperationTypes.CREATE;
                    newData.sitelinks.forEach(x => x.operation = ads_campaign_1.OperationTypes.CREATE);
                }
            }
            else {
                if (!newData) {
                    newData = oldData;
                    newData.operation = ads_campaign_1.OperationTypes.REMOVE;
                    newData.sitelinks.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
                }
                else {
                    newData.sitelinks = setOperation(oldData.sitelinks, newData.sitelinks);
                    const hasChanged = newData.sitelinks.find(x => { return x.operation === ads_campaign_1.OperationTypes.CREATE || x.operation === ads_campaign_1.OperationTypes.UPDATE || x.operation === ads_campaign_1.OperationTypes.REMOVE; });
                    newData.operation = hasChanged ? ads_campaign_1.OperationTypes.UPDATE : undefined;
                }
            }
            return newData;
        };
        const setOperationCall = (oldData, newData) => {
            if (!oldData && !newData) {
                return undefined;
            }
            if (!oldData) {
                if (newData) {
                    newData.operation = ads_campaign_1.OperationTypes.CREATE;
                    newData.calls.forEach(x => x.operation = ads_campaign_1.OperationTypes.CREATE);
                }
            }
            else {
                if (!newData) {
                    newData = oldData;
                    newData.operation = ads_campaign_1.OperationTypes.UPDATE;
                    newData.calls.forEach(x => x.operation = ads_campaign_1.OperationTypes.REMOVE);
                }
                else {
                    newData.calls = setOperation(oldData.calls, newData.calls);
                    const hasChanged = newData.calls.find(x => { return x.operation === ads_campaign_1.OperationTypes.CREATE || x.operation === ads_campaign_1.OperationTypes.UPDATE || x.operation === ads_campaign_1.OperationTypes.REMOVE; });
                    newData.operation = hasChanged ? ads_campaign_1.OperationTypes.UPDATE : undefined;
                }
            }
            return newData;
        };
        const _isCampaignChanged = (oldData, newData, hasChangedAtInitStep) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            if (newData.end_date === undefined) {
                newData.end_date = new Date('2037-12-30');
            }
            if (newData.name != oldData.name ||
                newData.start_date != oldData.start_date ||
                newData.end_date !== oldData.end_date ||
                hasChangedAtInitStep) {
                return true;
            }
            if (newData.bidding_strategy_type !== oldData.bidding_strategy_type) {
                return true;
            }
            if (newData.bidding_strategy_type === ads_campaign_1.GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE) {
                const newTargetRoas = ((_a = newData.maximize_conversion_value) === null || _a === void 0 ? void 0 : _a.target_roas) ? +newData.maximize_conversion_value.target_roas : undefined;
                const oldTargetRoas = ((_b = oldData.maximize_conversion_value) === null || _b === void 0 ? void 0 : _b.target_roas) ? +oldData.maximize_conversion_value.target_roas : undefined;
                if (newTargetRoas !== oldTargetRoas) {
                    return true;
                }
            }
            if (newData.bidding_strategy_type === ads_campaign_1.GoogleAdsEnum.BiddingStrategyType.TARGET_SPEND) {
                const oldMaxCpc = ((_c = oldData.bidding_strategy) === null || _c === void 0 ? void 0 : _c.max_cpc) ? +((_d = oldData.bidding_strategy) === null || _d === void 0 ? void 0 : _d.max_cpc) : undefined;
                const newMaxCpc = ((_e = newData.bidding_strategy) === null || _e === void 0 ? void 0 : _e.max_cpc) ? +((_f = newData.bidding_strategy) === null || _f === void 0 ? void 0 : _f.max_cpc) : undefined;
                if (((_g = newData.bidding_strategy) === null || _g === void 0 ? void 0 : _g.auto_optimize_bid) !== ((_h = oldData.bidding_strategy) === null || _h === void 0 ? void 0 : _h.auto_optimize_bid) || newMaxCpc !== oldMaxCpc) {
                    return true;
                }
            }
            if ((_j = newData.daily_schedules) === null || _j === void 0 ? void 0 : _j.some((x) => { return x.operation; })) {
                return true;
            }
            // if (newData.bidding_strategy?.auto_optimize_bid != oldData.bidding_strategy?.auto_optimize_bid ||
            //   newData.bidding_strategy?.max_cpc !== oldData.bidding_strategy?.max_cpc) {
            //   return true;
            // }
            if ((_k = newData.languages) === null || _k === void 0 ? void 0 : _k.some((x) => { return x.operation; })) {
                return true;
            }
            if ((_l = newData.devices) === null || _l === void 0 ? void 0 : _l.some((x) => { return x.operation; })) {
                return true;
            }
            if ((_m = newData.locations) === null || _m === void 0 ? void 0 : _m.some((x) => { return x.operation; })) {
                return true;
            }
            // TODO: Now sitelink and call extensions are not mutate by atomic mutation then we do not need to active the code below
            // if (newData.site_link?.operation) {
            //   return true;
            // }
            // if (newData.call?.operation) {
            //   return true;
            // }
            return false;
        };
        const retData = newData;
        try {
            retData.daily_schedules = setOperation(oldData.daily_schedules, newData.daily_schedules);
            // Google does not allow to update a Daily Schedule. In order to update we need:
            // 1. Create new one
            // 2. Remove old one
            // TODO: In the future, we should convert Campaign interface to entity. In BaseDataInfoService, we have setArrayOperation which allow to setup notAllowUpdate
            const needToUpdateSchedules = retData.daily_schedules.filter(x => x.operation === ads_campaign_1.OperationTypes.UPDATE);
            // 1. Create new one
            const needToCreateSchedules = needToUpdateSchedules.map(x => {
                const newSchedule = {
                    day_of_week: x.day_of_week,
                    start_hour: x.start_hour,
                    end_hour: x.end_hour,
                    start_minute: x.start_minute,
                    end_minute: x.end_minute,
                    operation: ads_campaign_1.OperationTypes.CREATE
                };
                return newSchedule;
            });
            // 2. Remove old one
            const needToRemoveSchedules = needToUpdateSchedules.map(x => {
                x.operation = ads_campaign_1.OperationTypes.REMOVE;
                return x;
            });
            retData.daily_schedules = [...retData.daily_schedules.filter(x => x.operation !== ads_campaign_1.OperationTypes.UPDATE), ...needToRemoveSchedules, ...needToCreateSchedules];
            if (!this._shallowEqual(oldData.campaign_budget, newData.campaign_budget)) {
                retData.campaign_budget.operation = ads_campaign_1.OperationTypes.UPDATE;
                retData.operation = ads_campaign_1.OperationTypes.UPDATE;
            }
            // TODO: Google does not allow to remove and create again the same language in 1 operation then we should avoid it
            const isEqualLanguage = (a, b) => {
                return a.language_constant === b.language_constant;
            };
            retData.languages = setOperation(oldData.languages, newData.languages, isEqualLanguage);
            retData.ages = setOperation(oldData.ages, newData.ages);
            retData.devices = setOperation(oldData.devices, newData.devices);
            // TODO: Google does not allow to remove and create again the same Location in 1 operation then we should avoid it
            const isEqualLocation = (a, b) => {
                return a.geo_target_constant === b.geo_target_constant;
            };
            retData.locations = setOperation(oldData.locations, newData.locations, isEqualLocation);
            retData.site_link = setOperationSitelink(oldData.site_link, newData.site_link);
            retData.call = setOperationCall(oldData.call, newData.call);
            if (_isCampaignChanged(oldData, newData, hasChangedAtInitStep)) {
                retData.operation = ads_campaign_1.OperationTypes.UPDATE;
            }
        }
        catch (ex) {
            console.log('_setUpdateOperation exception', ex);
            throw ex;
        }
        return retData;
    }
    prepareOperationData(oldData, newData, hasChangedAtInitStep) {
        if (!oldData || newData.operation === ads_campaign_1.OperationTypes.CREATE) {
            return this._setOperation(newData, ads_campaign_1.OperationTypes.CREATE);
        }
        return this._setUpdateOperation(oldData, newData, hasChangedAtInitStep);
    }
    static jsonToString(data) {
        const specialToArraySchedule = (scpecialSchedule) => {
            let days = [];
            switch (scpecialSchedule.day_of_week) {
                case ads_campaign_1.GoogleAdsEnum.DayOfWeek.WEEKDAYS:
                    days = [ads_campaign_1.GoogleAdsEnum.DayOfWeek.MONDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.TUESDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.WEDNESDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.THURSDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.FRIDAY];
                    break;
                case ads_campaign_1.GoogleAdsEnum.DayOfWeek.WEEKENDS:
                    days = [ads_campaign_1.GoogleAdsEnum.DayOfWeek.SATURDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.SUNDAY];
                    break;
                case ads_campaign_1.GoogleAdsEnum.DayOfWeek.EVERYDAY:
                    days = [ads_campaign_1.GoogleAdsEnum.DayOfWeek.MONDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.TUESDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.WEDNESDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.THURSDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.FRIDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.SATURDAY, ads_campaign_1.GoogleAdsEnum.DayOfWeek.SUNDAY];
                    break;
                default:
                    days = [scpecialSchedule.day_of_week];
                    break;
            }
            return days.map(x => {
                return {
                    start_hour: scpecialSchedule.start_hour,
                    start_minute: scpecialSchedule.start_minute,
                    end_hour: scpecialSchedule.end_hour,
                    end_minute: scpecialSchedule.end_minute,
                    day_of_week: x,
                    resource_name: scpecialSchedule.resource_name,
                    operation: scpecialSchedule.operation
                };
            });
        };
        const dailySchedules = data.daily_schedules ? data.daily_schedules.map(x => { return specialToArraySchedule(x); }).flat() : undefined;
        let target_roas = undefined;
        if (data.bidding_strategy_type === ads_campaign_1.GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE &&
            data.maximize_conversion_value && data.maximize_conversion_value.target_roas && data.maximize_conversion_value.target_roas !== NaN) {
            target_roas = data.maximize_conversion_value.target_roas;
        }
        return Object.assign(Object.assign({}, data), { maximize_conversion_value: {
                target_roas: target_roas
            }, start_date: moment(data.start_date).format('YYYY-MM-DD'), end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : undefined, daily_schedules: dailySchedules });
    }
}
exports.CampaignDataInfoService = CampaignDataInfoService;
