"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authSagas = exports.resetPass = exports.forgotEmail = exports.resetInEmailRegisterProcessSaga = exports.registerByEmailSaga = exports.verifyEmailSaga = exports.userRefreshSaga = exports.loginSaga = void 0;
const effects_1 = require("redux-saga/effects");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
const user_1 = require("../../user");
const team_1 = require("../../team");
function* loginSaga(action) {
    try {
        const params = action.params;
        const { user_access_token, email, password, provider } = params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/auth`,
            params: {
                user_access_token,
                email,
                password,
                provider,
            }
        };
        const auth = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.LoginAction.success,
            userToken: auth.data.data,
            payload: { token: auth.data.data },
            meta: action.meta
        });
        yield user_1.getUserSaga();
        yield team_1.getTeamList();
        yield team_1.getTeam();
        yield team_1.getTeamMembers();
    }
    catch (error) {
        yield effects_1.put({
            type: types_1.LoginAction.error,
            payload: error.response && error.response.data && error.response.data.error,
            error: true,
            meta: action.meta
        });
    }
}
exports.loginSaga = loginSaga;
function* userRefreshSaga(action) {
    try {
        const params = action.params;
        yield effects_1.put({
            type: types_1.RefreshAction.success,
            meta: action.meta,
            userToken: params.userToken,
            masterUserToken: params.masterUserToken
        });
        yield user_1.getUserSaga();
    }
    catch (err) {
        // TODO: handle error
    }
}
exports.userRefreshSaga = userRefreshSaga;
function* verifyEmailSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/email_verify`,
            authenticated: false,
            params: {
                token: params.token,
                user_id: params.user_id
            }
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({ type: types_1.VerifyEmailAction.success, userToken: response.data.data, payload: { success: true }, meta: action.meta });
        yield user_1.getUserSaga();
    }
    catch (err) {
        yield effects_1.put({ type: types_1.VerifyEmailAction.error, err, payload: { error: true }, meta: action.meta });
    }
}
exports.verifyEmailSaga = verifyEmailSaga;
function* registerByEmailSaga(action) {
    try {
        const { email, password, name, phoneNo } = action.params;
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/email_register`,
            data: {
                email,
                password,
                name,
                phone_no: phoneNo,
            }
        };
        const auth = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({ type: types_1.REGISTER_BY_EMAIL_SUCCESS, userToken: auth.data.data });
        yield user_1.getUserSaga();
    }
    catch (err) {
        yield effects_1.put({ type: types_1.REGISTER_BY_EMAIL_ERROR, error: err.response.data.error });
    }
}
exports.registerByEmailSaga = registerByEmailSaga;
function* resetInEmailRegisterProcessSaga(action) {
    try {
        yield effects_1.put({ type: types_1.RESET_IN_EMAIL_REGISTER_PROCESS_SUCCESS });
    }
    catch (err) {
        // TODO: handle error
    }
}
exports.resetInEmailRegisterProcessSaga = resetInEmailRegisterProcessSaga;
function* forgotEmail(action) {
    try {
        const { email } = action.params;
        const request = {
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/recover_password`,
            authenticated: false,
            params: {
                email,
            }
        };
        const auth = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.ForgotPasswordResetEmail.success,
            payload: { success: true },
            meta: action.meta,
        });
    }
    catch (err) {
        yield effects_1.put({
            type: types_1.ForgotPasswordResetEmail.error,
            error: err.response.data.error,
            meta: action.meta,
            payload: err
        });
    }
}
exports.forgotEmail = forgotEmail;
function* resetPass(action) {
    try {
        const request = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/reset_password`,
            data: action.params
        };
        const auth = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.ForgotPasswordResetPassword.success,
            payload: { success: true },
            meta: action.meta,
        });
    }
    catch (err) {
        yield effects_1.put({
            type: types_1.ForgotPasswordResetPassword.error,
            error: err.response.data.error,
        });
    }
}
exports.resetPass = resetPass;
exports.authSagas = [
    effects_1.takeLatest(types_1.LoginAction.type, loginSaga),
    effects_1.takeLatest(types_1.RefreshAction.type, userRefreshSaga),
    effects_1.takeLatest(types_1.VerifyEmailAction.type, verifyEmailSaga),
    effects_1.takeLatest(types_1.REGISTER_BY_EMAIL, registerByEmailSaga),
    effects_1.takeLatest(types_1.RESET_IN_EMAIL_REGISTER_PROCESS, resetInEmailRegisterProcessSaga),
    effects_1.takeLatest(types_1.ForgotPasswordResetEmail.common, forgotEmail),
    effects_1.takeLatest(types_1.ForgotPasswordResetPassword.common, resetPass),
];
