import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PackageInfoTable from './PackageInfoTable';
import PaymentHistoryTable from './PaymentHistoryTable';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Customer,
  PaymentHistory,
  PaymentService,
} from 'core';

interface PackageInfoProps {
  customers: {
    data: Array<Customer>;
  };
}

const PackageInfo = (props: PackageInfoProps) => {
  const paymentHistory: PaymentHistory = useSelector((state: any) => state.payment.paymentHistory);

  const paymentService = new PaymentService();
  useEffect(() => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {
          // console.log('res', res);
        },
        errorCallback: (err: any) => {
          // console.log('err', err);
        }
      }
    };
    paymentService.fetchPaymentHistory(params);
  }, []);

  return paymentHistory ? (
    <Styles>
      <Link to={(globalThis as any).useHistory.url('/home')} className="closeButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-38 -18) translate(30 10) translate(8 8)"/> </g> </g> </g> </g> </svg>
      </Link>
      <div className="container">
        <div className="page-heading row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="text-center">Thông tin gói nâng cấp</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <PackageInfoTable active_payments={paymentHistory.active_payments} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h2>Lịch sử mua</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <PaymentHistoryTable payment_histories={paymentHistory.payment_histories} />
          </div>
        </div>
      </div>
    </Styles>
  ) : null;
};

const mapStateToProps = (state: any) => {
  const { customers } = state;
  return {
    customers,
  };
};

const Styles = styled.div`
  background-color: #f5f4f9;
  min-height: 100vh;
  .closeButton {
    cusor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 10px;
    left: 30px;
  }
  .page-heading p {
    margin-bottom: 30px;
    font-family: Propins-Regular;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.25px;
    text-align: center;
    color: #666666;
  }
  h1 {
    color: #000000;
    font-family: Propins-Bold;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
  }
  h2 {
    margin: 0 30px;
    padding-top: 30px;
    border-top: 1px solid #d8d8d8;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
  }
  .pricing-box {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 0.5px #d8d8d8;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .pricing-box__recommend-tag {
    position: absolute;
    top: 0;
    padding: 6px;
    left: 0;
    width: 100%;
    background-image: linear-gradient(96deg, #4c7cf3, #47d1ff);
    font-family: Propins-Bold;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
  }
  .pricing-box__heading {
    position: relative;
    padding: 15px;
    padding-top: 45px;
  }
  .pricing-box__heading h2 {
    font-family: Propins-Bold;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #000000;
  }
  .pricing-box__heading p {
    background-image: linear-gradient(105deg, var(--dodger-blue), #47d1ff 100%);
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.2px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .pricing-box__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 15px;
    padding-top: 20px;
    border-top: solid 1px rgba(216, 216, 216, 0.5);
  }
  .pricing-box__content ul {
    margin-bottom: 30px;
    padding-left: 36px;
    margin-top: 24px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.18px;
    color: #000000;
  }
  .pricing-box__content .price-unit {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    color: #666666;
  }
  .faq-section {
    margin-top: 40px;
    border-top: solid 1px #d8d8d8;
    padding: 40px 0;
  }
  .faq-section__heading {
    font-family: Propins-Bold;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.16px;
    color: #8e8e93;
  }
  .questions-items__heading {
    cursor: pointer;
    position: relative;
    font-family: Propins-Medium;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
    padding-bottom: 8px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 15px;
  }
  .questions-items__heading--icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  .faq-section__title {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #000000;
    margin-top: 15px;
  }
  .cricleBullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #4c7cf3;
    margin-right: 5px;
  }
  .permisstion-list li {
    margin-bottom: 10px;
  }
  .input-slider-wrapper {
    margin-bottom: 54px;
  }
  .input-slider-wrapper h3 {
    font-family: Propins-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.45px;
    color: #000000;
  }
  .input-range__label--min, .input-range__label--max {
    display: none;
  }
  .input-range__track {
    height: 9px!important;
    background-color: rgba(216, 216, 216, 0.4)!important;
  }
  .input-range__track--active {
    background-color: #4c7cf3!important;
  }
  .input-range__slider {
    background: #4c7cf3!important;
    border: 4px solid #ffffff!important;
    margin-top: -.75rem!important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%)!important;
  }
  .input-range__label .input-range__label-container {
    position: relative;
    top: -12px;
    padding: 1px 6px;
    border-radius: 4px;
    background-color: #010245;
    font-family: Propins-Medium;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .input-range__label .input-range__label-container::before {
    content: '';
    left: calc(50% - 7px);
    bottom: -7px;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #010245;
  }
`;

const PackageInfoPage = connect(mapStateToProps)(PackageInfo);
export default PackageInfoPage;
