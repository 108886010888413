import * as React from 'react';
import Modal from 'components/modals';
import SubmitButton from 'buttons/SubmitButton';
import { isValidPhoneNumber, User, forgotPasswordResetEmail, forgotPasswordResetPassword } from 'core';
import { FullHeader } from 'pages/header/FullHeader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CancelButton from 'components/buttons/CancelButton';
import styled from 'styled-components';
import { EmailService, ApiErrorMsg, FieldType, InputErrorMsg } from './service';
// import {ErrorBoundary} from 'react-error-boundary';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { History } from 'src/route/Route';

interface PasswordStateType {
  elength: boolean | '';
  special: boolean | '';
}                                                                                                                                                            

const ResetPasswordPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const history = new History();
  const apiError: any = useSelector((state: any) => state.auth.forgotError);
  const [isInputPass, setIsInputPass] = useState(!!token);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [checkingEmailIsOpen, setCheckingEmailIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  
  const [showPassword, setShowPasswordVisible] = useState({
    password: false,
    rePassword: false,
  });

  const [apiErrorCode, setApiErrorCode] = useState({
    error: false,
    code: '0'
  });

  const [errorStatus, setErrorStatus] = useState({
    password: false,
    repassword: false,
    email: false
  });

  const [passwordState, setPasswordState] = useState<PasswordStateType>({
    elength: false,
    special: false
  });

  const closeModal = (): void => {
    setIsOpen(false);
    setCheckingEmailIsOpen(false);
    history.push('/login');
  };

  useEffect(() => {
    if(!apiError) return;
    setApiErrorCode({
      error: true,
      code: apiError.code
    });
  }, [apiError]);

  const customStyleModal = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: 470,
      height: 310,
      top: '50%',
      left: '50%',
      right: 'auto',
      padding: 0,
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      borderRadius: '12px',
      transform: 'translate(-50%, -50%)'
    }
  };
  
  const customStyleModalResetPassword = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: 470,
      height: 200,
      top: '50%',
      left: '50%',
      right: 'auto',
      padding: 0,
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      borderRadius: '12px',
      transform: 'translate(-50%, -50%)'
    }
  };
  
  const checkValidField = (type: FieldType, input: any): boolean => {
    switch (type) {
      case FieldType.Email:
        return !!(input && EmailService.validateEmailFormat(input));
      case FieldType.Password:
        return !!(input && EmailService.validatePasswordFormat(input));
      case FieldType.ConfirmPassword:
        return !!(input && EmailService.isCorrectConfirmPassword(input, password));
      default:
        return true;
    }
  };

  const handleOnChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setEmail(value);
    const valueCondition = value && checkValidField(FieldType.Email, value);
    setErrorStatus({
      ...errorStatus, 
      email: !valueCondition
    });

    setApiErrorCode({
      ...apiErrorCode,
      error: false,
    });
  };

  const onBlurInputEmail = (e: any) => {
    const { value } = e.target;
    const valueCondition = value && checkValidField(FieldType.Email, value);
    setErrorStatus({
      ...errorStatus, 
      email: !valueCondition
    });
    setApiErrorCode({
      ...apiErrorCode,
      error: false,
    });
  };

  const handleOnChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setPassword(value);
    const valueCondition = value && checkValidField(FieldType.Password, value);
    setErrorStatus({
      ...errorStatus, 
      password: !valueCondition
    });

    const elength = value && EmailService.hasEnoughCharacterInPassword(value);
    const special = value && EmailService.hasEnoughCharacterTypeInPassword(value);
    setPasswordState({
      elength, special
    });
  };

  const onBlurInputPassword = (e: any) => {
    const { value } = e.target;
    const valueCondition = value && checkValidField(FieldType.Password, value);
    setErrorStatus({
      ...errorStatus, 
      password: !valueCondition
    });
  };

  const handleOnChangeRePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setRePassword(value);
    const valueCondition = value && checkValidField(FieldType.ConfirmPassword, value) ;
    setErrorStatus({
      ...errorStatus, 
      repassword: !valueCondition
    });
  };

  const onBlurInputRePassword = (e: any) => {
    const { value } = e.target;
    const valueCondition = value && checkValidField(FieldType.ConfirmPassword, value) ;
    setErrorStatus({
      ...errorStatus, 
      repassword: !valueCondition
    });
  };

  const handleSendEmail = async () => {
    const valueCondition = checkValidField(FieldType.Email, email);
    setErrorStatus({
      ...errorStatus, 
      email: !valueCondition
    });

    if (!valueCondition) {
      return;
    }

    try{
      const response = await dispatch(forgotPasswordResetEmail({email: email.toLowerCase()}));
      if(response['success']){
        toast.success('Gửi email thành công');
        setCheckingEmailIsOpen(true);
      }
    }catch(err){
      console.log(err, 'err');
    }
  };

  const handleInputNewPass = async () => {
    if (!validToChangePass()) return;
    try{
      const respone = await dispatch(forgotPasswordResetPassword({token, new_password: rePassword}));
      if(respone['success']){
        toast.success('Thay đổi mật khẩu thành công');
        setIsOpen(true);
      }
    }catch(err){
      console.log(err, 'err');
    }
  };

  const validToChangePass = (): boolean => {
    return (
      checkValidField(FieldType.Password, password) &&
      checkValidField(FieldType.ConfirmPassword ,rePassword)
    );
  };

  const validToLogin = (): boolean => {
    return (
      !!(email && EmailService.validateEmailFormat(email))
    );
  };

  const handleShowPass = (data = {}): void => {
    setShowPasswordVisible({
      ...showPassword,
      ...data
    });
  };

  const sendEmail = async () => {
    // EmailService.dispatchForgotEmail(dispatch, email);
    const valueCondition = checkValidField(FieldType.Email, email);
    setErrorStatus({
      ...errorStatus, 
      email: !valueCondition
    });

    if (!valueCondition) {
      return;
    }

    try{
      const response = await dispatch(forgotPasswordResetEmail({email}));
      if(response['success'])
        toast.success('Gửi lại email thành công');

    }catch(err){
      console.log(err, 'err');
    }
  };

  const goToLogin = (): void => {
    history.push('/login');
    return;
  };

  const gotoGmail = (): void => {
    window.open('https://mail.google.com/mail/#search/from%3A%40+cokhach.vn+in%3Aanywhere', '_blank');
    return;
  };
  
  const renderInputPassword = () => {
    return (
      <div className="container reset-pass-page">
        <div className="text-center reset-pass__heading">
          <h2>Thiết lập lại mật khẩu</h2>
          <p>Vui lòng nhập mật khẩu mới mà bạn muốn thay đổi:</p>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <div className="row">
              <div className="col-lg-12">
                <label>Mật khẩu mới <span className="label-required"> *</span></label>
                <input 
                  type={showPassword.password ? 'text' : 'password'}
                  name="password" 
                  className="form-control" 
                  value={password}
                  onChange={handleOnChangePassword} 
                  onBlur={onBlurInputPassword}
                />
                {password && <div className="show-password" onClick={()=>handleShowPass({password: !showPassword.password})}>
                  {showPassword.password ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <g> <path d="M10.003 15.858c5.524 0 9.338-4.463 9.338-5.858 0-1.401-3.821-5.858-9.338-5.858C4.555 4.142.66 8.599.66 10c0 1.395 3.89 5.858 9.344 5.858zm0-1.08c-4.504 0-8.148-3.814-8.148-4.778 0-.813 3.644-4.778 8.148-4.778 4.485 0 8.142 3.965 8.142 4.778 0 .964-3.657 4.778-8.142 4.778zm0-.95c2.126 0 3.835-1.743 3.835-3.828 0-2.14-1.709-3.828-3.835-3.828-2.14 0-3.855 1.688-3.848 3.828.013 2.085 1.709 3.828 3.848 3.828zm0-2.557c-.71 0-1.285-.574-1.285-1.271 0-.704.574-1.271 1.285-1.271.705 0 1.279.567 1.279 1.271 0 .697-.574 1.271-1.279 1.271z" transform="translate(-827 -368) translate(402 113) translate(20 222) translate(405 33)"/> </g> </g> </g> </g> </g> </svg>
                  : 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <path d="M16.06 16.079c.219-.226.205-.533 0-.745L4.63 3.91c-.205-.205-.547-.205-.752 0-.198.198-.198.547 0 .745l11.437 11.423c.205.212.533.225.745 0zm-.164-1.873c2.16-1.402 3.445-3.24 3.445-4.047 0-1.401-3.814-5.858-9.338-5.858-1.189 0-2.27.205-3.274.54l.902.895c.752-.226 1.525-.355 2.372-.355 4.498 0 8.142 3.964 8.142 4.778 0 .554-1.196 2.044-3.09 3.206l.841.84zm-5.893 1.811c1.272 0 2.434-.225 3.494-.588l-.896-.895c-.813.253-1.675.403-2.598.403-4.504 0-8.148-3.814-8.148-4.778 0-.479 1.265-2.071 3.281-3.281L4.29 6.03C2.012 7.438.659 9.332.659 10.159c0 1.394 3.89 5.858 9.344 5.858zm3.507-4.361c.212-.458.328-.97.328-1.497 0-2.14-1.709-3.828-3.835-3.828-.54 0-1.045.116-1.503.314l5.01 5.011zm-3.507 2.331c.602 0 1.17-.15 1.675-.396L6.551 8.464c-.253.505-.396 1.08-.396 1.695.007 2.085 1.709 3.828 3.848 3.828z" transform="translate(-1090 -331) translate(555 298) translate(535 33)"/> </g> </g> </g> </g> </svg>
                  }
                </div>}
              </div>

              <div className="col-lg-12">
                <div className="verify-pass1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> 
                  <g fill='none' fillRule="evenodd"> 
                  <g fill={passwordState.elength ? '#38cd52' : '#AEAEB2'} fillRule="nonzero"> <g> <g> 
                  <path d="M8 17c4.376 0 8-3.631 8-8 0-4.376-3.631-8-8.008-8C3.624 1 0 4.624 0 9c0 4.369 3.631 8 8 8zm0-1.333c-3.702 0-6.659-2.965-6.659-6.667s2.95-6.667 6.651-6.667c3.702 0 6.667 2.965 6.675 6.667.008 3.702-2.965 6.667-6.667 6.667zm-.863-2.942c.26 0 .479-.125.636-.368l3.584-5.64c.086-.148.188-.329.188-.501 0-.345-.314-.573-.643-.573-.196 0-.392.118-.541.353l-3.255 5.216L5.56 9.22c-.188-.251-.361-.322-.58-.322-.338 0-.605.275-.605.627 0 .165.071.338.18.487l1.915 2.345c.196.259.407.368.666.368z" transform="translate(-555 -397) translate(555 298) translate(0 98)"/> </g> </g> </g> </g> </svg>
                  Có độ dài tối thiểu 8 ký tự.
                </div>
                
                <div className="verify-pass2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> 
                  <g fill='none' fillRule="evenodd"> 
                  <g fill={passwordState.special ? '#38cd52' : '#AEAEB2'} fillRule="nonzero"> <g> <g> 
                  <path d="M8 17c4.376 0 8-3.631 8-8 0-4.376-3.631-8-8.008-8C3.624 1 0 4.624 0 9c0 4.369 3.631 8 8 8zm0-1.333c-3.702 0-6.659-2.965-6.659-6.667s2.95-6.667 6.651-6.667c3.702 0 6.667 2.965 6.675 6.667.008 3.702-2.965 6.667-6.667 6.667zm-.863-2.942c.26 0 .479-.125.636-.368l3.584-5.64c.086-.148.188-.329.188-.501 0-.345-.314-.573-.643-.573-.196 0-.392.118-.541.353l-3.255 5.216L5.56 9.22c-.188-.251-.361-.322-.58-.322-.338 0-.605.275-.605.627 0 .165.071.338.18.487l1.915 2.345c.196.259.407.368.666.368z" transform="translate(-555 -397) translate(555 298) translate(0 98)"/> </g> </g> </g> </g> </svg>
                  Bao gồm chữ in hoa, in thường và chữ số.
                </div>
              </div>
              {renderInputError(errorStatus.password, InputErrorMsg.InvalidPassword)}

              <div className="col-lg-12 mt-1">
                <label>Nhập lại mật khẩu mới <span className="label-required"> *</span></label>
                <input 
                  type={showPassword.rePassword ? 'text' : 'password'}
                  name="rePassword" 
                  className="form-control" 
                  value={rePassword}
                  onChange={handleOnChangeRePassword} 
                  onBlur={onBlurInputRePassword}
                />
                {rePassword && <div className="show-password" onClick={()=>handleShowPass({rePassword: !showPassword.rePassword})}>
                  {showPassword.rePassword ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <g> <path d="M10.003 15.858c5.524 0 9.338-4.463 9.338-5.858 0-1.401-3.821-5.858-9.338-5.858C4.555 4.142.66 8.599.66 10c0 1.395 3.89 5.858 9.344 5.858zm0-1.08c-4.504 0-8.148-3.814-8.148-4.778 0-.813 3.644-4.778 8.148-4.778 4.485 0 8.142 3.965 8.142 4.778 0 .964-3.657 4.778-8.142 4.778zm0-.95c2.126 0 3.835-1.743 3.835-3.828 0-2.14-1.709-3.828-3.835-3.828-2.14 0-3.855 1.688-3.848 3.828.013 2.085 1.709 3.828 3.848 3.828zm0-2.557c-.71 0-1.285-.574-1.285-1.271 0-.704.574-1.271 1.285-1.271.705 0 1.279.567 1.279 1.271 0 .697-.574 1.271-1.279 1.271z" transform="translate(-827 -368) translate(402 113) translate(20 222) translate(405 33)"/> </g> </g> </g> </g> </g> </svg>
                  : 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="none" fillRule="evenodd"> <g fill="#666" fillRule="nonzero"> <g> <g> <path d="M16.06 16.079c.219-.226.205-.533 0-.745L4.63 3.91c-.205-.205-.547-.205-.752 0-.198.198-.198.547 0 .745l11.437 11.423c.205.212.533.225.745 0zm-.164-1.873c2.16-1.402 3.445-3.24 3.445-4.047 0-1.401-3.814-5.858-9.338-5.858-1.189 0-2.27.205-3.274.54l.902.895c.752-.226 1.525-.355 2.372-.355 4.498 0 8.142 3.964 8.142 4.778 0 .554-1.196 2.044-3.09 3.206l.841.84zm-5.893 1.811c1.272 0 2.434-.225 3.494-.588l-.896-.895c-.813.253-1.675.403-2.598.403-4.504 0-8.148-3.814-8.148-4.778 0-.479 1.265-2.071 3.281-3.281L4.29 6.03C2.012 7.438.659 9.332.659 10.159c0 1.394 3.89 5.858 9.344 5.858zm3.507-4.361c.212-.458.328-.97.328-1.497 0-2.14-1.709-3.828-3.835-3.828-.54 0-1.045.116-1.503.314l5.01 5.011zm-3.507 2.331c.602 0 1.17-.15 1.675-.396L6.551 8.464c-.253.505-.396 1.08-.396 1.695.007 2.085 1.709 3.828 3.848 3.828z" transform="translate(-1090 -331) translate(555 298) translate(535 33)"/> </g> </g> </g> </g> </svg>
                  }
                </div>}
              </div>
              {renderInputError(errorStatus.repassword, InputErrorMsg.UnmatchedConfirmPassword)}
              {renderApiError(apiErrorCode.error, apiErrorCode.code)}
            </div>
            <div className="button-group align-center-left">
              <SubmitButton
                className="btn-submit"
                alignLeft
                onClick={handleInputNewPass}
              >
                Cập nhật
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInputError = (ifError: boolean, errMsg: InputErrorMsg, emailLayout = false) => {
    if (!ifError) return null;
    return (
      <div className={emailLayout ? '' : 'col-lg-12 mt-1'} style={{color: 'red'}}>{errMsg}</div>
    );
  };

  const renderApiError = (shouldShow: boolean, code: string, emailLayout = false) => {
    if (!shouldShow) return null;
    return (
      <div className={emailLayout ? '' : 'col-lg-12 mt-1'} style={{color: 'red'}}>{ApiErrorMsg[code]}</div>
    );
  };

  const renderInputEmail = () => {
    return (
      <div className="container reset-pass-page">
        <div className="text-center reset-pass-page__heading">
          <h2>Bạn đã quên mật khẩu?</h2>
          <p>Vui lòng nhập email bạn đã sử dụng để đăng ký tài khoản dưới đây và chúng tôi sẽ gửi tới bạn hướng dẫn để thiết lập lại mật khẩu.</p>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <div className="row">
              <div className="col-lg-12">
                <label>Email</label>
                <input 
                  type="text" name="email" className="form-control" value={email || ''}
                  onChange={handleOnChangeEmail}
                  onBlur={onBlurInputEmail}
                  onKeyPress={(event)=>{if(event.key === 'Enter' || event.which === 13 || event.keyCode === 13) handleSendEmail();}}
                />
              </div>
            </div>
            {renderInputError(errorStatus.email, InputErrorMsg.InvalidEmail, true)}
            {renderApiError(apiErrorCode.error, apiErrorCode.code, true)}
            <div className="button-group align-center justify-space">
              <SubmitButton
                className="btn-submit"
                isDisabled={!validToLogin()}
                onClick={handleSendEmail}
              >
                Thiết lập lại mật khẩu
              </SubmitButton>
            </div>
            <Link to={(globalThis as any).useHistory.url('/login')}>Trở lại đăng nhập</Link>
          </div>
        </div>
      </div>
    );
  };

  const renderSuccessModal = () => {
    return (
      <Modal isOpen={modalIsOpen} customStyles={customStyleModalResetPassword} closeModal={closeModal}>
        <div className="noti-modal">
          <div className="modal__heading">
            <h3>Thông báo</h3>
            <div className="modal-btn-close" onClick={closeModal}>
              <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
            </div>
          </div>
          <div className="modal__content">
            Mật khẩu mới đã được cập nhật. Vui lòng đăng nhập để tiếp tục.
          </div>
          <div className="modal__bottom">
            <button onClick={goToLogin} className="btn-default">Đăng nhập</button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderSentEmailSuccessModal = () => {
    return (
      <Modal isOpen={checkingEmailIsOpen} customStyles={customStyleModal} closeModal={closeModal}>
        <div className="noti-modal">
          <div className="modal__heading">
            <h3>Kiểm tra hòm thư email</h3>
            <div className="modal-btn-close" onClick={closeModal}>
              <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
            </div>
          </div>
          <div className="modal__content">
          <p>Đường dẫn thiết lập lại mật khẩu đã được gửi đến bạn.
          <br/>Vui lòng kiểm tra hòm thư của bạn (bao gồm cả thư spam) để nhận email của Cokhach.vn
          và nhấp vào đường dẫn thiết lập lại mật khẩu để bắt đầu thay đổi mật khẩu.</p>
          <p>Không nhận được email từ Cokhach.vn ({email})?</p>
          </div>
          <div className="modal__bottom">
            <CancelButton onClick={sendEmail} className="ml-3 btn-default">Gửi lại</CancelButton>
            {!!email.match(/^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(gmail|google)\.com$/g) && 
              <button onClick={gotoGmail} className="btn-default ml-3">Tới hòm thư</button>}
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Styles>
      {isInputPass ? renderSuccessModal() : renderSentEmailSuccessModal()}
      <FullHeader />
      {isInputPass ? renderInputPassword() : renderInputEmail()}
    </Styles>
  );
};

const Styles = styled.div`
  background: url(${process.env.REACT_APP_TEMPLATE_CDN_URL}/header-background.png) no-repeat;
  background-size: contain;
  .app-header.background-white {
    background-color: transparent;
    box-shadow: none;
  }
  .reset-pass-page {
    margin-top: 34px;
  }
  .align-center{
    align-items: center;
  }
  .justify-space{
    justify-content: space-between;
  }

  .btn-link:hover{
    color: #ffffff!important;
    text-decoration: none!important;
  }
  .btn-link.focus, .btn-link:focus {
    color: #ffffff!important;
    text-decoration: none!important;
    box-shadow: none!important;
  }

  .show-password {
    cursor: pointer;
    position: absolute;
    right: 30px;
    bottom: 12px;
  }

  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }

  p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #000000;
  }

  .verify-pass1, .verify-pass2 {
    margin-top: 10px;
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeb2;
    align-items: center;
    display: flex;
  }
  .verify-pass1 svg, .verify-pass2 svg {
    margin-right: 8px;
  }

  .reset-pass__heading {
    margin-bottom: 80px;
  }
`;

export default ResetPasswordPage;