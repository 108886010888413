import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import * as ReactModal from 'react-modal';
import { History } from 'src/route/Route';
import _ from 'lodash';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
  goNext: () => void;
}
ReactModal.setAppElement('#root');

const ContactHotlineModal: React.FC<ModalProps> = (props) => {
  const mounted = useRef(false);
  const history = new History();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };


  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="package-upgrade-modal-container">
        <div className="modal__heading">
          <h3>Thông báo</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <img src="https://cdn-static.ymeet.me/general/REI/icon/close-modal.svg" />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content--row">
            <p className="pb-0">Vui lòng liên hệ với tổng đài hỗ trợ để được xử lý yêu cầu</p>
          </div>
        </div>
        <div className="modal__bottom">
          <a href="tel:0904220612" className="btn-default ml-3">Call 0904220612</a>
        </div>
      </div>
    </ReactModal>
  );
};

ContactHotlineModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '477px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

export default ContactHotlineModal;
