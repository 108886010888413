import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'components/modals';
import {
  Image,
  Project,
  LandingPage,
  CreateLandingPageParametersType,
  createLandingPage,
  AppState,
  ProjectTypes,
  TemplateLandingPage
} from 'core';
import CancelButton from 'buttons/CancelButton';
import SubmitButton from 'buttons/SubmitButton';
import { FullHeader } from 'pages/header/FullHeader';
import { useFetchListProjects } from 'pages/project/hooks';
import { LoadingScreen } from 'components/common/LoadingScreen';
import { useFetchTemplateLandingPages } from './hooks';
import 'stylesheets/Button.css';
import { isNull } from 'lodash';
import { History } from 'src/route/Route';

type CreateLandingPageProps = {};

const CreateLandingPage: React.FC<CreateLandingPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = new History();
  const { pid: projectId } = useParams<{ pid: string }>();
  const templateLandingPages = useSelector((state: AppState) => state.landingPages.templateLandingPages);
  const projects = useSelector((state: AppState) => state.projects);
  const userProjects = projects.userProjects;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [lpName, setLpName] = useState('');
  const [templateId, setTemplateId] = useState<number>();
  // We will save selected project as state instead of getting it directly from reducer.
  // Because in some case `projectState.userProjects` is not fetched yet,
  // and we have usecases that need to redirect page if project is not exist or fullfilled
  const [selectedProject, setMatchedProject] = useState<Project>();

  useFetchTemplateLandingPages(templateLandingPages);

  useFetchListProjects(projects);

  // Effect used for validating selected project
  useEffect(() => {
    if (!userProjects.length) return;

    const project = userProjects.find((p: Project) => p.id === parseInt(projectId));
    if (!project) {
      history.push('/project-management');
      return;
    }

    if (!project.isFulfilledProject()) {
      history.push({
        pathname: `/edit-project/${project.id}`,
      });
      return;
    }

    setMatchedProject(project);
  }, [userProjects, projectId, history]);

  const closeModal = (): void => setIsOpen(false);

  const openModal = (landingPageId: number): void => {
    setIsOpen(true);
    setTemplateId(landingPageId);
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setLpName(event.currentTarget.value);
  };

  const createLp = (): void => {
    const parameters: CreateLandingPageParametersType = {
      name: lpName,
      projectId: +projectId,
      templateLandingPageId: templateId!,
    };
    dispatch(createLandingPage(parameters)).then((landingPage: LandingPage) => {
      const latestVersion = landingPage.versions[0];
      const path = history.generateBuilder({landing_page_id: landingPage.lp_id, version_id: latestVersion.id}, true);
      window.open(path);
    });

    history.push('/landing-management');
    closeModal();
  };

  //TODO: update link preview
  const viewLandingPage = (url: string, id: number, versionId: number): void => {
    const path = history.generateBuilder({landing_page_id: id, version_id: versionId, mode: 'preview_editing'}, true);
    if(url) 
      window.open(url);
    else
      window.open(path);
  };

  const keyPressed = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && lpName.length) {
      createLp();
    }
  };

  if (!selectedProject) {
    return <LoadingScreen />;
  }

  return (
    <Styles>
      <FullHeader />
      <div className="container">
        <div className="form-search-project">
          <h1>Danh sách giao diện mẫu</h1>
          <p>Hãy lựa chọn một giao diện phù hợp cho trang landing page của bạn.</p>
        </div>
        <div className="list-landingpages">
          <div className="row">
            {templateLandingPages
              .filter((templateLandingPage: TemplateLandingPage) => {
                return (
                  selectedProject.type_code === null || // TODO: remove this in future when we can set default type is `other`
                  selectedProject.type_code === ProjectTypes.OTHER ||
                  selectedProject.type_code === templateLandingPage.ptype_code
                );
              })
              .map((templateLandingPage: TemplateLandingPage, index: number, array: any) => {
                const latestVersion = templateLandingPage.versions[0];
                const thumbImg = templateLandingPage.thumbnail
                  ? new Image(`361x226/${templateLandingPage.thumbnail}`, '', templateLandingPage.thumbnail, undefined, null)
                  : new Image('361x226/img-header.png', '', 'img-header.png', undefined, null);

                return (
                  <div key={templateLandingPage.lp_id} className="col-lg-3">
                    <div className="landingpage-image p-relative">
                      <div className="landingpage-action">
                        <button className="btn-default" onClick={() => openModal(templateLandingPage.lp_id)}>
                          Sử dụng
                        </button>
                        <button
                          className="btn-submit"
                          onClick={() => viewLandingPage(templateLandingPage.url, templateLandingPage.lp_id, latestVersion.id)}
                        >
                          Xem
                        </button>
                      </div>
                      <img className="img-fluid" src={thumbImg.imgUrl} alt="" />
                    </div>
                    <div className="landingpage-title">Mẫu {array.length - index}</div>
                    <div className="landingpage-subtitle">({templateLandingPage.lp_subname})</div>
                  </div>
                );
              })}
          </div>
        </div>

        <Modal isOpen={modalIsOpen} closeModal={closeModal}>
          <h3 className="text-center">Tạo tên landing page</h3>
          <div className="form-group">
            <label>TÊN LANDING PAGE</label>
            <input
              type="text"
              name="landingpage_name"
              className="form-control"
              placeholder="VD: Vinhomes Smart City 1"
              onChange={handleInputChange}
              onKeyPress={keyPressed}
            />
          </div>
          <div className="d-flex justify-content-end">
            <CancelButton onClick={closeModal}>Hủy bỏ</CancelButton>
            <SubmitButton alignRight isDisabled={lpName === ''} onClick={createLp}>
              Tạo ngay
            </SubmitButton>
          </div>
        </Modal>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  h1 {
    font-family: Propins-Bold;
    font-size: 32px;
    letter-spacing: -0.6px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  p {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.3px;
    color: #000000;
  }
  h4 {
    font-family: Propins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 20px;
  }
  .input-container {
    display: flex;
    align-items: center;
    max-width: 622px;
    padding-left: 15px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(216, 216, 216, 0.7);
    background-color: #ffffff;
  }
  .select-project-category {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #000000;
    margin-bottom: 20px;
  }
  .select-project-category .project-category {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 6px;
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #8e8e93;
    border: 1px solid #d8d8d8;
    margin-left: 15px;
  }
  .select-project-category .project-category.active {
    background-color: #4c7cf3;
    color: #ffffff;
    border-color: #4c7cf3;
  }
  .search-input {
    flex: 1;
    padding: 15px;
    border: 0;
    outline: none;
    border-radius: 6px;
  }
  .list-landingpages {
    padding: 20px 0;
    margin-bottom: 20px;
    img {
      border-radius: 6px;
    }
    .landingpage-title {
      font-family: Propins;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.36px;
      text-align: center;
      color: #000000;
      padding-top: 15px;
      padding-bottom: 5px;
    }
    .landingpage-subtitle {
      font-family: Propins-Regular;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      text-align: center;
      padding-bottom: 15px;
      color: var(--brownish-grey);
    }
  }
  .more-action {
    margin-bottom: 40px;
  }
  .box-shadow {
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    img {
      margin: 25px auto;
    }
  }
  .landingpage-image button {
    min-width: 134px;
  }
  .landingpage-image {
    position: relative;
  }
  .landingpage-image:hover .landingpage-action {
    opacity: 0.9;
  }
  .landingpage-image .landingpage-action .btn-default {
    margin-bottom: 20px;
  }
  .landingpage-image .landingpage-action {
    flex-direction: column;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    border-radius: 6px;
    background-color: #ffffff;
  }
`;

export default CreateLandingPage;
