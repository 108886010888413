import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'src/route/Route';
import { toast } from 'react-toastify';
import { User, SelectedTeam, setPreviousPage, resetPreviousPage } from 'core';
import { Cookies } from 'react-cookie';
import { plainToClass } from 'class-transformer';
import { TokenCookies, Token, useCookie } from './useCookie';
import { isPlainObject } from 'utils/object';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type GenerateLocation = {
  query?: Record<string, any>;
  pathname?: string;
  subdomain: string;
}

export const useRedirectDomain = (cookies: Cookies) => {
  const query = useQuery();
  const history = new History();
  const previousPage = useSelector((state) => state.auth.previousPage);
  const dispatch = useDispatch();
  const location = useLocation();
  const state: any = location.state || { from: { pathname: '/' } };
  const { from: fromLocation } = state;
  const user: User = useSelector((state: any) => state.user.user);
  const appDomain = process.env.REACT_APP_DOMAIN || 'localhost';
  const {setCookies, getAllCookies} = useCookie(cookies);

  //generate full path with protocol, domain, port, pathname and query string
  const generateDomain = (location?: GenerateLocation) => {
    const subdomain = location && location.subdomain || '';
    const pathname = location && location.pathname || '';
    const appDomainOrigin = appDomain.startsWith('http') ? appDomain : '//' + appDomain;
    const subdomainPattern = '*.' + appDomain;

    const url = new URL(appDomainOrigin, window.location.origin);
    if(location && location.query){
      const keys = location.query;
      Object.keys(location.query).map((property: string)=>{
        url.searchParams.set(property, keys[property]);
      });
    }

    if(subdomain) url.hostname = subdomainPattern.replace('*', subdomain);
    if(pathname) url.pathname = pathname;

    url.port = window.location.port;
    url.protocol = window.location.protocol;

    return url.toString();
  };

  const redirect = (cookie?: TokenCookies, path?: GenerateLocation) => {
    const fromPath = query.get('from');
    setCookies(cookie);

    const fullpath = path ? generateDomain(path) : null;
    const page = fullpath || previousPage || fromPath;
    if(!fullpath && fromLocation) {
      history.replace(fromLocation);
      return;
    }
    if (page) {
      if(previousPage){
        dispatch(resetPreviousPage());
      }
      try{
        const url = new URL(page);
        if(url.hostname !== process.env.REACT_APP_DOMAIN) {
            window.location.replace(url.toString());
            // window.open(page, '_blank');
        } else {
          history.push(page);
        }
      }catch(err){
        history.push(page);
      }
    }
  };

  const saveFromPath = async (path = '/login', thenRedirect = true) => {
    if(window.location.hostname == process.env.REACT_APP_DOMAIN) return;
    try{
      const mainDomainPath = generateDomain();
      let pathConcat = history.getPath(path);
      pathConcat = pathConcat.startsWith('/') ? pathConcat.slice(1) : pathConcat;
      const url = new URL(mainDomainPath + pathConcat);
      const query = new URLSearchParams(url.search);

      // save current path after redirecting webpage
      const currentDomainPath = window.location.origin + fromLocation.pathname;
      query.set('from', currentDomainPath);
      await dispatch(setPreviousPage(currentDomainPath));

      //generate login url with from query like /login?from=
      const redirectPath = url.toString() + '?' + query.toString();
      if(thenRedirect) window.location.replace(redirectPath);
    }catch(err){
      history.push('/');
    }
  };
  
  const gotoTeam = (token: string | object, team: SelectedTeam, query: object, pathname: string | undefined = undefined) => {
    if(query === null || query === undefined) query = {'team-manager': 'show'};
    if(typeof token === 'string') {
      const cookies: TokenCookies = {
        [Token.UserToken]: token,
      };
      setCookies(cookies);
    }else{
      setCookies(token as TokenCookies);
    }

    const responseTeam = plainToClass(SelectedTeam, team || {});
    const path = new URL(window.location.protocol + '//' + responseTeam.getTeamDomain());
    path.port = window.location.port;
    path.pathname = pathname ? pathname : history.getPath('/');
    if(query) {
      Object.keys(query).map((property: string)=>{
        path.searchParams.set(property, query[property]);
      });
    }

    if((!team.team || !team.team.id) && path.hostname === window.location.hostname)
      return;
    else
      window.location.replace(path.toString());
  };

  const gotoHome = (query?: object, token?: string) => {
    if(window.location.hostname === process.env.REACT_APP_DOMAIN) return;
    if(!token) token = getAllCookies(Token.MasterUserToken) as string;
    const cookies: TokenCookies = {
      [Token.UserToken]: token,
      [Token.MasterUserToken]: token,
    };
    setCookies(cookies);
    const path = new URL(window.location.protocol + '//' + appDomain);
    path.port = window.location.port;
    path.pathname = history.getPath('/');
    if(query && isPlainObject(query)) {
      query && Object.keys(query).map((property: string)=>{
        path.searchParams.set(property, query[property]);
      });
    }
    window.location.replace(path.toString());
  };

  return {redirect, saveFromPath, generateDomain, gotoTeam, gotoHome};
};