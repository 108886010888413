import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useState, useRef, useEffect } from 'react';
import { ImageSource, TextSource, Area, Size, Font, Point } from '../entities/Types';

interface Props {
  width: number;
  height: number;
  bkImage: ImageSource;
  loadingStyle?: CSSProperties;
  frameImage: ImageSource;
  logoImage?: ImageSource;
  title: TextSource;
  body: TextSource;
  doDownLoad: boolean;
  fileName: string;
}

const DEFAULT_TEXT_COLOR = 'red';
const DESCRIPTION_TEXT_RATIO = 1.28;

const deepEqualImageSource = (oldImageSource?: ImageSource, newImageSource?: ImageSource): boolean => {
  if (!oldImageSource && !newImageSource) {
    return true;
  }

  if (!oldImageSource || !newImageSource) {
    return false;
  }

  if (oldImageSource === newImageSource) {
    return true;
  }

  if (oldImageSource.source != newImageSource.source) {
    return false;
  }

  if (oldImageSource.cropArea != newImageSource.cropArea) {
    return false;
  }

  if (oldImageSource.drawArea != newImageSource.drawArea) {
    return false;
  }

  if (oldImageSource.drawRelativeArea != newImageSource.drawRelativeArea) {
    return false;
  }
  
  return true;
};

function usePrevious(value: Props) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AdCanvasGenerator = (props: Props) => {
  const {
    bkImage, frameImage, logoImage, title, body, width, height, doDownLoad, fileName, loadingStyle
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const canvasImgRef = useRef<HTMLImageElement>(null);

  const previousProps = usePrevious(props);

  const drawImage = (context: CanvasRenderingContext2D, imageSource: ImageSource, doDefaultCrop = false, autoWidth = false) => {
    const getDrawTopLeft = (imageSource: ImageSource, imgObj: HTMLImageElement): Point => {
      if (imageSource.drawArea) {
        return imageSource.drawArea.topLeft;
      }

      if (imageSource.drawRelativeArea) {
        const imageHeight = imageSource.drawRelativeArea.height;
        const imageWidth = imageSource.drawRelativeArea.width ? imageSource.drawRelativeArea.width : getAutoWidth(imgObj, imageHeight);

        if (imageSource.drawRelativeArea.centerTop) {
          return {
            x: imageSource.drawRelativeArea.centerTop.x - Math.round(imageWidth / 2),
            y: imageSource.drawRelativeArea.centerTop.y 
          };
        }

        if (imageSource.drawRelativeArea.topLeft) {
          return {
            x: imageSource.drawRelativeArea.topLeft.x,
            y: imageSource.drawRelativeArea.topLeft.y 
          };
        }

        if (imageSource.drawRelativeArea.topRight) {
          return {
            x: width - imageSource.drawRelativeArea.topRight.x - imageWidth,
            y: imageSource.drawRelativeArea.topRight.y 
          };
        }
        
      }
      return {x: 0, y: 0};
    };
    
    const getImageDrawSize = (imageSource: ImageSource, imgObj: HTMLImageElement): Size => {
      if (imageSource.drawArea) {
        return {
          width: imageSource.drawArea.bottomRight.x - imageSource.drawArea.topLeft.x,
          height: imageSource.drawArea.bottomRight.y - imageSource.drawArea.topLeft.y
        };
      }

      if (imageSource.drawRelativeArea) {
        const height = imageSource.drawRelativeArea.height;//imageSource.drawRelativeArea.height > imgObj.height ? imgObj.height : imageSource.drawRelativeArea.height;
        const width = imageSource.drawRelativeArea.width ? imageSource.drawRelativeArea.width : getAutoWidth(imgObj, height);
        return {
          width,
          height
        };
      }

      return {
        width,
        height
      };
    };

    const getAreaSize = (area?: Area): Size => {
      if (area) {
        return {
          width: area.bottomRight.x - area.topLeft.x,
          height: area.bottomRight.y - area.topLeft.y
        };
      }

      return {
        width,
        height
      };
    };

    
    const getDefaultCropArea = (imgObj: HTMLImageElement, drawSize: Size): Area => {
      if (imgObj.width / imgObj.height > drawSize.width / drawSize.height) {
        return {
          topLeft: {
            x: 0,
            y: 0
          },
          bottomRight:{
            x: (drawSize.width * imgObj.height) / drawSize.height,
            y: imgObj.height
          }
        };
      }
      return {
        topLeft: {
          x: 0,
          y: 0
        },
        bottomRight:{
          x: imgObj.width,
          y: (drawSize.height * imgObj.width) / drawSize.width
        }
      };
    };

    const getAutoWidth = (imgObj: HTMLImageElement, height: number): number => {
      return Math.round((imgObj.width * height) / imgObj.height);
    };

    return new Promise((resolve, reject) => {
      const imageObj = new Image();
      imageObj.crossOrigin = 'anonymous';
      imageObj.onload = function () {
        const topLeft = getDrawTopLeft(imageSource, imageObj);
        const drawSize = getImageDrawSize(imageSource, imageObj);

        const cropArea = imageSource.cropArea ? 
          imageSource.cropArea : 
          (doDefaultCrop ? getDefaultCropArea(imageObj, drawSize) : undefined);

        if (cropArea) {
          // Crop source image before drawing on canvas
          const cropSize = getAreaSize(cropArea);
          context.drawImage(imageObj, cropArea.topLeft.x, cropArea.topLeft.y, cropSize.width, cropSize.height, topLeft.x, topLeft.y, drawSize.width, drawSize.height);
        } else {
          const drawWidth = autoWidth ? getAutoWidth(imageObj, drawSize.height) : drawSize.width;         
          context.drawImage(imageObj, topLeft.x, topLeft.y, drawWidth, drawSize.height);
        }
        resolve(true);
      };
      imageObj.src = imageSource.source;
    });
  };

  const drawText = (context: CanvasRenderingContext2D, textSource: TextSource, maxLine?: number) => {
    const getFillStyle = (textSource: TextSource): string | CanvasGradient => {
      if (textSource.colors.length === 0) {
        return DEFAULT_TEXT_COLOR;
      }

      if (textSource.colors.length === 1) {
        return textSource.colors[0];
      }

      const gradient = context.createLinearGradient(0, 0, textSource.maxWidth, 0);
      const step = 1 / (textSource.colors.length - 1);
      let graValue = 0;

      textSource.colors.forEach((color) => {
        gradient.addColorStop(graValue, color);
        graValue += step;
      });

      return gradient;
    };

    const drawMultiLineText = (context: CanvasRenderingContext2D, textSource: TextSource, maxLine?: number) => {
      const fillText = (context: CanvasRenderingContext2D, text: string, x: number, y: number, height: number, fontSize: number, lineIndex: number, maxLine?: number) => {
        const canDrawMoreTextLine = (y: number, height: number, fontSize: number, lineIndex: number, maxLine?: number): boolean => {
          if (maxLine && lineIndex >= maxLine) {
            return false;
          }
          return y <= height - fontSize / 2;
        };

        if (canDrawMoreTextLine(y, height, fontSize, lineIndex, maxLine)) {
          context.fillText(text, x, y);
        }
      };

      const getLineHeight = (textSource: TextSource): number => {
        return Math.round(DESCRIPTION_TEXT_RATIO * textSource.font.size);
      };

      if (!textSource.text) {
        return;
      }
      
      const x = textSource.centerPoint.x;
      let y = textSource.centerPoint.y;

      if (x > width || y > height) {
        return;
      }

      const textLines = textSource.text.split('\n');
      let lineIndex = 0;
      const lineHeight = getLineHeight(textSource);

      textLines.forEach((textLine) => {
        const words = textLine.split(' ');
        let line = '';
        let testLine = '';
        words.forEach((word, index) => {
          testLine = line + word + ' ';
          const metrics = context.measureText(testLine);
          if ( metrics.width > textSource.maxWidth && index > 0) {
            fillText(context, line, x, y, height, textSource.font.size, lineIndex, maxLine);
            lineIndex ++;
            line = word + ' ';
            y += lineHeight;
          } else {
            line = testLine;
          }
        });
        
        fillText(context, line, x, y, height, textSource.font.size, lineIndex, maxLine);
        lineIndex ++;
        y += lineHeight;
      });
    };

    const getFont = (font: Font): string => {
      return `${font.size}px ${font.name}`;
    };

    context.font = getFont(textSource.font);
    context.fillStyle = getFillStyle(textSource);
    context.textAlign = 'center';

    drawMultiLineText(context, textSource, maxLine);
  };
  
  const draw = async (context: CanvasRenderingContext2D) => {
    await drawImage(context, bkImage, true);

    //Turn transparency on
    context.globalAlpha = 1;
    await drawImage(context, frameImage);
    if (logoImage && logoImage.source) {
      await drawImage(context, logoImage, false, true);
    }

    drawText(context, title, 1);
    drawText(context, body);
  };
  
  const generateImage = async () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    
    canvas.width = width;
    canvas.height = height;
    await draw(context);
    const canvasImg = canvasImgRef.current;
    if (canvasImg) {
      canvasImg.onload = function () {
        setIsLoading(false);
      };
      canvasImg.src = canvas.toDataURL();
    }
  };

  useEffect(() => {
    generateImage();
  });

  useEffect(() => {
    if (doDownLoad) {
      const canvasImg = canvasImgRef.current;
      if (canvasImg) {
        const a = document.createElement('a');
        a.href = canvasImg.src;
        a.download = fileName;
        a.click();
      }
    }
  }, [doDownLoad]);

  useEffect(() => {
    if (!deepEqualImageSource(bkImage, previousProps.bkImage) || 
    !deepEqualImageSource(frameImage, previousProps.frameImage) ||
    !deepEqualImageSource(logoImage, previousProps.logoImage)) {
      setIsLoading(true);
    }
  }, [bkImage, frameImage, logoImage]);
  
  const renderIsLoading = () => { 
    if(isLoading) {
      return <div className="placeholder-wrapper" style={loadingStyle}>
        <div className={'img-placeholder animate fill-height'}></div>
      </div>;
    }
    return undefined;
  };

  return (
    <div>
      <img ref={canvasImgRef} id='canvasImg' className={`${isLoading ? 'd-none img-fluid' : 'img-fluid'}`} src='' alt='Advertisement review' width={width} height={height} />
      {renderIsLoading()}
    </div>
  );
};

export default AdCanvasGenerator;