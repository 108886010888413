"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.landingPagesReducer = void 0;
const types_1 = require("../../auth/types");
const types_2 = require("../types");
const entities_1 = require("../../entities");
const types_3 = require("../types");
const action_namespace_1 = require("../../team/types/action-namespace");
const initialState = {
    userLandingPages: [],
    templateLandingPages: []
};
const landingPagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.GET_LANDING_PAGES_SUCCESS:
            return Object.assign({}, state, {
                userLandingPages: action.landingPages
            });
        case types_2.GET_TEMPLATE_LANDING_PAGES_SUCCESS:
            return Object.assign({}, state, {
                templateLandingPages: action.landingPages
            });
        case types_2.CREATE_LANDING_PAGE_SUCCESS: {
            if (!action.landingPage) {
                return state;
            }
            if (state.userLandingPages.length === 0) {
                return Object.assign({}, state, {
                    userLandingPages: [action.landingPage]
                });
            }
            const sameProjectLpIndex = state.userLandingPages.findIndex((lp) => lp.pid === action.landingPage.pid);
            let newUserLandingPages = null;
            if (sameProjectLpIndex) {
                newUserLandingPages = state.userLandingPages.slice();
                newUserLandingPages.splice(sameProjectLpIndex, 0, action.landingPage);
            }
            else {
                newUserLandingPages = [action.landingPage].concat(state.userLandingPages);
            }
            return Object.assign({}, state, {
                userLandingPages: newUserLandingPages
            });
        }
        case types_2.UNPUBLISH_LANDING_PAGE_SUCCESS: {
            const newUserLandingPages = state.userLandingPages.map((lp) => {
                if (lp.lp_id !== action.landingPageId)
                    return lp;
                const newLandingPage = lp.clone();
                newLandingPage.status = entities_1.LandingPageVersionStatus.deactive;
                return newLandingPage;
            });
            return Object.assign({}, state, {
                userLandingPages: newUserLandingPages
            });
        }
        case types_3.UPDATE_LANDING_PAGE_SUCCESS: {
            const { lp_id } = action.data;
            const updateIndex = state.userLandingPages.findIndex((lp) => lp_id === lp.lp_id);
            const updatedLandingPage = action.data;
            return Object.assign(Object.assign({}, state), { userLandingPages: [
                    ...state.userLandingPages.slice(0, updateIndex),
                    updatedLandingPage,
                    ...state.userLandingPages.slice(updateIndex + 1)
                ] });
        }
        case types_2.COPY_LANDING_PAGE_SUCCESS: {
            let newUserLandingPages;
            const indexOfGroupProject = state.userLandingPages.findIndex((lp) => lp.pid === action.landingPage.pid);
            if (indexOfGroupProject) {
                newUserLandingPages = state.userLandingPages.slice();
                newUserLandingPages.splice(indexOfGroupProject, 0, action.landingPage);
            }
            else {
                newUserLandingPages = [action.landingPage].concat(state.userLandingPages);
            }
            return Object.assign({}, state, {
                userLandingPages: newUserLandingPages
            });
        }
        case types_2.REMOVE_LP_LIST_AFTER_DELETE_PROJECT_SUCCESS: {
            const newUserLPs = state.userLandingPages.filter(lp => lp.pid !== action.projectId);
            return Object.assign(Object.assign({}, state), { userLandingPages: newUserLPs });
        }
        case action_namespace_1.GoToTeamAction.success:
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.landingPagesReducer = landingPagesReducer;
