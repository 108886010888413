"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_LP_LIST_AFTER_DELETE_PROJECT_SUCCESS = exports.REMOVE_LP_LIST_AFTER_DELETE_PROJECT = exports.COPY_LANDING_PAGE_ERROR = exports.COPY_LANDING_PAGE_SUCCESS = exports.COPY_LANDING_PAGE_REQUEST = exports.UPDATE_LANDING_PAGE_ERROR = exports.UPDATE_LANDING_PAGE_SUCCESS = exports.UPDATE_LANDING_PAGE = exports.LandingPageStatus = exports.UPDATE_LANDING_PAGE_STATUS_ERROR = exports.UPDATE_LANDING_PAGE_STATUS_SUCCESS = exports.UPDATE_LANDING_PAGE_STATUS_REQUEST = exports.UNPUBLISH_LANDING_PAGE_ERROR = exports.UNPUBLISH_LANDING_PAGE_SUCCESS = exports.UNPUBLISH_LANDING_PAGE_REQUEST = exports.CREATE_LANDING_PAGE_ERROR = exports.CREATE_LANDING_PAGE_SUCCESS = exports.CREATE_LANDING_PAGE_REQUEST = exports.GET_TEMPLATE_LANDING_PAGES_ERROR = exports.GET_TEMPLATE_LANDING_PAGES_SUCCESS = exports.GET_TEMPLATE_LANDING_PAGES = exports.GET_LANDING_PAGES_ERROR = exports.GET_LANDING_PAGES_SUCCESS = exports.GET_LANDING_PAGES = void 0;
exports.GET_LANDING_PAGES = 'GET_LANDING_PAGES';
exports.GET_LANDING_PAGES_SUCCESS = 'GET_LANDING_PAGES_SUCCESS';
exports.GET_LANDING_PAGES_ERROR = 'GET_LANDING_PAGES_ERROR';
exports.GET_TEMPLATE_LANDING_PAGES = 'GET_TEMPLATE_LANDING_PAGES';
exports.GET_TEMPLATE_LANDING_PAGES_SUCCESS = 'GET_TEMPLATE_LANDING_PAGES_SUCCESS';
exports.GET_TEMPLATE_LANDING_PAGES_ERROR = 'GET_TEMPLATE_LANDING_PAGES_ERROR';
exports.CREATE_LANDING_PAGE_REQUEST = 'CREATE_LANDING_PAGE_REQUEST';
exports.CREATE_LANDING_PAGE_SUCCESS = 'CREATE_LANDING_PAGE_SUCCESS';
exports.CREATE_LANDING_PAGE_ERROR = 'CREATE_LANDING_PAGE_ERROR';
exports.UNPUBLISH_LANDING_PAGE_REQUEST = 'UNPUBLISH_LANDING_PAGE_REQUEST';
exports.UNPUBLISH_LANDING_PAGE_SUCCESS = 'UNPUBLISH_LANDING_PAGE_SUCCESS';
exports.UNPUBLISH_LANDING_PAGE_ERROR = 'UNPUBLISH_LANDING_PAGE_ERROR';
exports.UPDATE_LANDING_PAGE_STATUS_REQUEST = 'UPDATE_LANDING_PAGE_STATUS_REQUEST';
exports.UPDATE_LANDING_PAGE_STATUS_SUCCESS = 'UPDATE_LANDING_PAGE_STATUS_SUCCESS';
exports.UPDATE_LANDING_PAGE_STATUS_ERROR = 'UPDATE_LANDING_PAGE_STATUS_ERROR';
var LandingPageStatus;
(function (LandingPageStatus) {
    LandingPageStatus["active"] = "active";
    LandingPageStatus["deactive"] = "deactive";
})(LandingPageStatus = exports.LandingPageStatus || (exports.LandingPageStatus = {}));
exports.UPDATE_LANDING_PAGE = 'UPDATE_LANDING_PAGE';
exports.UPDATE_LANDING_PAGE_SUCCESS = 'UPDATE_LANDING_PAGE_SUCCESS';
exports.UPDATE_LANDING_PAGE_ERROR = 'UPDATE_LANDING_PAGE_ERROR';
exports.COPY_LANDING_PAGE_REQUEST = 'COPY_LANDING_PAGE_REQUEST';
exports.COPY_LANDING_PAGE_SUCCESS = 'COPY_LANDING_PAGE_SUCCESS';
exports.COPY_LANDING_PAGE_ERROR = 'COPY_LANDING_PAGE_ERROR';
exports.REMOVE_LP_LIST_AFTER_DELETE_PROJECT = "REMOVE_LP_LIST_AFTER_DELETE_PROJECT";
exports.REMOVE_LP_LIST_AFTER_DELETE_PROJECT_SUCCESS = "REMOVE_LP_LIST_AFTER_DELETE_PROJECT_SUCCESS";
