import { useSelector } from 'react-redux';
import { SelectedTeam } from 'core';
import { plainToClass } from 'class-transformer';

const useTeamPermission = () => {
  const team = useSelector((state) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));

  const permissionByRole = (roles: string[]) => {
    return team.checkUserRole(roles);
  };

  return { permissionByRole };
};


export default useTeamPermission;