import * as React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import { Project, ProjectTypes } from 'core';
import 'stylesheets/App.css';

const Styles = styled.div`
  label {
    margin: 20px 0 10px;
    font-family: Propins;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #8e8e93;
  }

  .form-control {
    font-family: Propins;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #000000;
    padding: 10px 15px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
  }

  .form-control::placeholder {
    color: #aeaeb2;
  }
  @media (max-width: 1300px) {
    .project-infor-form {
      min-height: auto;
    }
    .form-control {
      padding: 8px 10px;
      height: 36px;
      font-size: 14px;
    }
    label {
      font-size: 12px;
      margin: 15px 0 8px;
    }
  }
`;

type PropsType = {
  project?: Project;
  handleInputChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  projectType: ProjectTypes;
}

export function ProjectInfoForm(props: PropsType) {
  const { project } = props;

  return (
    <Styles>
      <div className="project-infor-form row m-0">
        <div className="col-6">
          <label>
            TÊN DỰ ÁN<span className="label-required"> *</span>
          </label>
          <input
            type="text"
            name="name"
            autoFocus={true}
            className="form-control"
            placeholder="VD: Vinhomes  Central Park"
            defaultValue={project ? project.name : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>
            CHỦ ĐẦU TƯ<span className="label-required"> *</span>
          </label>
          <input
            type="text"
            name="investor"
            className="form-control"
            placeholder="VD: Vinhomes"
            defaultValue={project ? project.investor : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>
            VỊ TRÍ <span className="label-required"> *</span>
          </label>
          <input
            type="text"
            name="location"
            className="form-control"
            placeholder="VD: Tọa lạc tại khu Tân Cảng, một trong những vị trí đắc địa bậc nhất …"
            defaultValue={project ? project.location : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>THIẾT KẾ</label>
          <input
            type="text"
            name="design_company"
            className="form-control"
            placeholder="VD: Coteccons"
            defaultValue={project ? project.design_company : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>DIỆN TÍCH DỰ ÁN</label>
          <input
            type="text"
            name="site_area"
            className="form-control"
            placeholder="VD: 50 ha"
            defaultValue={project ? project.site_area : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>QUY MÔ DỰ ÁN</label>
          <input
            type="text"
            name="scale"
            className="form-control"
            placeholder="VD: Quy mô trên 10.000 căn hộ và hơn 100 căn biệt thự cao cấp ..."
            defaultValue={project ? project.scale : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>NGÀY KHỞI CÔNG</label>
          <input
            type="text"
            name="commencement_date"
            className="form-control"
            placeholder="VD: Q2/2015"
            defaultValue={project ? project.commencement_date : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>NGÀY BÀN GIAO</label>
          <input
            type="text"
            name="delivery_date"
            className="form-control"
            placeholder="VD: Q4/2019"
            defaultValue={project ? project.delivery_date : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>GIAI ĐOẠN DỰ ÁN</label>
          <TextareaAutosize
            name="procedure"
            className="form-control"
            placeholder="VD: • Giai đoạn 1: Xây dựng (2 tòa tháp số 4 và số 7 cao từ 25 đến 35  …"
            defaultValue={project ? project.procedure : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>CÁC LOẠI CĂN HỘ VÀ DIỆN TÍCH</label>
          <TextareaAutosize
            name="unit_detail"
            className="form-control"
            placeholder="VD: 1PN: 50-55 m2 …"
            defaultValue={project ? project.unit_detail : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>GIÁ</label>
          <input
            type="text"
            name="unit_price"
            className="form-control"
            placeholder="VD: Từ 30tr/m2"
            defaultValue={project ? project.unit_price : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>TỔNG MỨC ĐẦU TƯ</label>
          <input
            type="text"
            name="total_investment"
            className="form-control"
            placeholder="VD: 37.712 tỷ đồng"
            defaultValue={project ? project.total_investment : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>HÌNH THỨC SỞ HỮU / PHÁP LÝ</label>
          <input
            type="text"
            name="legality"
            className="form-control"
            placeholder="VD: Căn hộ: Sở hữu lâu dài; Căn hộ officetel/shophouse: thuê dài hạn 50 năm ..."
            defaultValue={project ? project.legality : ''}
            onChange={props.handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>MẬT ĐỘ XÂY DỰNG</label>
          <input
            type="text"
            name="construction_percentage"
            className="form-control"
            placeholder="23%"
            defaultValue={project ? project.construction_percentage : ''}
            onChange={props.handleInputChange}
          />
        </div>
      </div>
    </Styles>
  );
}
