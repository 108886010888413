"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectReducer = void 0;
const types_1 = require("../../auth/types");
const landing_page_1 = require("../../landing-page");
const types_2 = require("../types");
const types_3 = require("../../team/types");
const initialState = {
    systemProjects: [],
    userProjects: [],
    selectedProject: undefined,
    selectedImages: [],
    projectImages: [],
    isFetching: false,
    errorMessage: undefined,
    isUploadingImage: false
};
const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.GetListProjectsAction.type: {
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: undefined,
            });
        }
        case types_2.GetListProjectsAction.allSuccess: {
            const userProjects = action.projects ? action.projects.filter((x) => !x.isSystemProject) : [];
            const systemProjects = action.projects ? action.projects.filter((x) => x.isSystemProject) : [];
            return Object.assign({}, state, {
                isFetching: false,
                userProjects: userProjects,
                systemProjects: systemProjects
            });
        }
        case types_2.GetListProjectsAction.error:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload
            });
        case types_2.GetListProjectsAction.userSuccess: {
            return Object.assign({}, state, {
                userProjects: action.projects
            });
        }
        case types_2.GetListProjectsAction.systemSuccess: {
            return Object.assign({}, state, {
                systemProjects: action.projects
            });
        }
        case types_2.GetProjectAction.success: {
            return Object.assign({}, state, {
                selectedProject: action.project
            });
        }
        case types_2.CreateProjectAction.success: {
            const userProjects = state.userProjects || [];
            userProjects.unshift(action.payload.project);
            return Object.assign({}, state, {
                userProjects
            });
        }
        case types_2.UpdateProjectAction.success: {
            const newUserProjects = state.userProjects.map((project) => {
                if (project.id === action.project.id) {
                    return action.project;
                }
                return project;
            });
            return Object.assign({}, state, {
                selectedProject: action.project,
                userProjects: newUserProjects
            });
        }
        case types_2.DeleteProjectAction.success: {
            if (!state.userProjects)
                return state;
            const deletedId = action.projectId;
            const updatedListProject = state.userProjects.filter((project) => {
                return project.id !== deletedId;
            });
            return Object.assign({}, state, {
                userProjects: updatedListProject
            });
        }
        case types_2.GetProjectImageAction.success: {
            return Object.assign({}, state, {
                projectImages: action.images
            });
        }
        case types_2.UploadImageAction.type:
            return Object.assign({}, state, {
                isUploadingImage: true
            });
        case types_2.UploadImageAction.error:
            return Object.assign({}, state, {
                isUploadingImage: false
            });
        case types_2.UploadImageAction.success: {
            return Object.assign({}, state, {
                projectImages: [...state.projectImages, action.payload],
                isUploadingImage: false
            });
        }
        case types_2.UpdateProjectAction.image:
            return Object.assign({}, state, {
                selectedImages: action.params.selectedImages
            });
        case types_2.DeleteImageAction.success: {
            return Object.assign({}, state, {
                projectImages: state.projectImages.filter((img) => img.id !== action.imageId)
            });
        }
        case types_2.GetProjectAction.clear: {
            return Object.assign({}, state, {
                selectedProject: null
            });
        }
        case landing_page_1.CREATE_LANDING_PAGE_SUCCESS: {
            const newUserProjects = state.userProjects.map((project) => {
                if (project.id !== action.landingPage.pid)
                    return project;
                project.num_landing_pages = project.num_landing_pages + 1;
                return project;
            });
            return Object.assign({}, state, {
                userProjects: newUserProjects
            });
        }
        case landing_page_1.UPDATE_LANDING_PAGE_STATUS_SUCCESS: {
            if (action.status === landing_page_1.LandingPageStatus.deactive) {
                const newUserProjects = state.userProjects.map(project => {
                    if (project.id !== action.landingPage.pid)
                        return project;
                    project.num_landing_pages = project.num_landing_pages - 1;
                    return project;
                });
                return Object.assign({}, state, {
                    userProjects: newUserProjects
                });
            }
            return state;
        }
        case types_3.GoToTeamAction.success:
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.projectReducer = projectReducer;
