import { useForm, Controller, useFormContext, useFieldArray} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { vestResolver } from '@hookform/resolvers/vest';
import * as validate from '../CreateGoogleCampaign/forms';
import {
  GoogleService, 
  GoogleCampaignClass, 
  InitialCampaignParams,
  CreateOrUpdateAdGroupAdParams,
  ILocation,
  GetDetailGoogleCampaignParams,
  AdsAccount,
  KeywordData,
  UserPublishedLandingPagesData,
  GoogleCampaignAds,
  UpdateGoogleCampaignStatusCB,
  CampaignStatus,
  LandingPageWithGoogleAdsCampaign
} from 'core';
import * as CreateFields from '../CreateGoogleCampaign/fields';
import { plainToClass } from 'class-transformer';
import {AdsCriterion} from '../criterion';
import { UpdateGoogleCampaignStatusParams } from 'core';


export const useGoogleForm = () => {
  const service = new GoogleService();
  const currentCampaign = plainToClass(GoogleCampaignClass, useSelector((state) => state.adsCampaign.currentCampaign || {}));
  const currentAdgroup = useSelector<any, KeywordData | null>((state) => state.adsCampaign.AdGroups);
  const allAccounts = useSelector<any, AdsAccount>((state) => state.adsCampaign.connectedAccountGroup);
  const accounts = allAccounts && allAccounts.getGoogleAccounts();
  const methods = useFormContext();
  const lpPublished = useSelector<any, UserPublishedLandingPagesData>((state) => {
    const alllp = state.adsCampaign.currentLandingPage?.landing_pages || state.userPublishedUrls?.data || [];
    const selectedlp = state.adsCampaign.currentCampaign?.landing_page_id;
    const found = alllp.find((el: UserPublishedLandingPagesData)=>el.lp_id === selectedlp);
    if(!selectedlp || !found) return null; 
    return found;
  });
  
  const lpOnSelectedAdAccount = useSelector<any, LandingPageWithGoogleAdsCampaign[]>((state) => state.adsCampaign.currentLandingPage?.landing_pages);
  const lpSelected = (selectedlp: number) => {
    const found = lpOnSelectedAdAccount?.find((el: LandingPageWithGoogleAdsCampaign)=>el.lp_id === selectedlp);
    if(!selectedlp || !found) return null; 
    return found;
  };
  
  const initGoogleCampaignData = currentCampaign.getInitialData();
  const currentCampaignData = currentCampaign.getCurrentCampaignData();
  
  const initialCampaign = (params: InitialCampaignParams) => {
    service.initialCampaign(params);
  };
  
  // const getLocationAds = (params: GetLocationAdsParams) => {
  //   return useMemo(()=>{
  //     service.getLocationAds(params);
  //   }, [locations]);
  // };

  const createOrUpdateAdGroupAd = (params: CreateOrUpdateAdGroupAdParams) => {
    service.createOrUpdateAdGroupAd(params);
  };
  
  const getCampaign = (params: GetDetailGoogleCampaignParams) => {
    service.getDetailGoogleCampaign(params);
  };
  
  const changeCampaignStatus = (params: UpdateGoogleCampaignStatusParams) => {
    service.updateGoogleCampaignStatus(params);
  };
  
  const resourceLocationTag = 'geoTargetConstants/';
  
  const defaultLocation = {
    tag: resourceLocationTag,
    vn: {
      geo_target_constant: {
        resource_name: resourceLocationTag + '2704',
        name: 'Vietnam',
        canonical_name: 'Vietnam'
      },
      reach: '',
      search_term: 'Vietnam'
    } as ILocation
  };
  
  return {
    useForm, Controller,
    vestResolver,
    ...validate,
    getCampaign,
    initialCampaign,
    createOrUpdateAdGroupAd,
    CreateFields,
    currentCampaign,
    AdsCriterion,
    methods,
    useFieldArray,
    GoogleCampaignClass,
    defaultLocation,
    accounts,
    initGoogleCampaignData,
    currentCampaignData,
    currentAdgroup,
    lpPublished, lpSelected,
    changeCampaignStatus
  };
};
