import React, {Fragment} from 'react';
import styled from 'styled-components';
import ConfirmButton from 'components/buttons/ConfirmButton';
import { useDispatch, useSelector } from 'react-redux';
import { 
  GetTeamMemberListParams, 
  LeaveTeamParams,
  TransferTeamOwnershipParams,
  InviteTeamParams,
  Role,
  User,
  getCustomers
} from 'core';
import '../../stylesheets/Button.css';
import { toast } from 'react-toastify';
import { WorkSpaceService, GetTeamsParams, TeamPermissionService, SelectedTeam, RoleCode } from 'core';
import { plainToClass } from 'class-transformer';
import { ApiErrorMsg } from './service';
import { History } from 'src/route/Route';

type PropsType = {
  type?: string;
  user: any;
  showWarningLimitedFeatureModal: () => void;
  showMemberInformationModal: (user: any) => void;
};

const MemberItem: React.FC<PropsType> = (props: PropsType) => {
  const history = new History();
  const dispatch = useDispatch();
  const team = useSelector((state) => plainToClass(SelectedTeam, state.team.selectedTeam || {}));
  const currentUser: User = useSelector((state: any) => state.user.user);
  const service = new WorkSpaceService();
  const user = props.user;
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const handleTransferOwnership = () => {
    if(!teamPermissionService.CanChangeTeamRole) {
      props.showWarningLimitedFeatureModal();
      return;
    }
    history.push('/transfer-ownership');
  };
  
  const handleRemoveUserFromTeam = () => {
    if(!teamPermissionService.CanRemoveTeamMember) {
      props.showWarningLimitedFeatureModal();
      return;
    }
    const params: LeaveTeamParams = {
      inputParams: {
        user_id: user.id
      },
      callBackParams: {
        successCallback: (response: any) => {
          toast.success('Bạn đã xóa thành viên khỏi nhóm.');
        },
        errorCallback: (err: any) => {
          const error = err.code ? ApiErrorMsg[err.code] : 'Bạn đã xóa thành viên thất bại.';
          toast.error(error);
          console.log(err, 'err');
        }
      }
    };
    service.leaveTeam(params);
  };
  
  const handleDeprecateManagementRole = () => {
    const params: TransferTeamOwnershipParams = {
      inputParams: {
        user_id: user.id,
        command: 'remove',
        role_code: RoleCode[RoleCode.manager]
      },
      callBackParams: {
        successCallback: (response: any) => {
          toast.success('Bạn đã chuyển quyền thành viên thành công.');
        },
        errorCallback: (err: any) => {
          const error = err.code ? ApiErrorMsg[err.code] : 'Bạn đã chuyển quyền thành viên thất bại.';
          toast.error(error);
          console.log(err, 'err');
        }
      }
    };
    service.transferRole(params);
  };

  const handleTransferOwnershipPermanent = (role: RoleCode) => {
    const params: TransferTeamOwnershipParams = {
      inputParams: {
        user_id: user.id,
        command: 'add',
        role_code: role ? role : RoleCode[RoleCode.manager]
      },
      callBackParams: {
        successCallback: (response: any) => {
          if(role === RoleCode[RoleCode.owner]){
            toast.success(`Bạn đã rời khỏi vị trí Chủ sở hữu của nhóm #${team.getTeamName()}`);
            history.push({
              pathname: '/',
              state: {
                shouldShowManageMember: true,
              }
            });
          } else {
            toast.success('Bạn đã chuyển thành viên thành quản lý.');
          }
        },
        errorCallback: (err: any) => {
          const error = err.code ? ApiErrorMsg[err.code] : 'Bạn đã chuyển quyền thành viên thất bại.';
          toast.error(error);
          console.log(err, 'err');
        }
      }
    };
    service.transferRole(params);
  };

  const handleResendInviteTeam = () => {
    if(!teamPermissionService.CanInviteTeamMember) {
      const error = 'Nhóm cuả bạn đã đạt tới giới hạn thành viên';
      toast.error(error);
      return;
    }
    const params: InviteTeamParams = {
      inputParams: {
        emails: [user.email]
      },
      callBackParams: {
        successCallback: (response: any) => {
          toast.success('Gửi lại lời mời thành công.');
        },
        errorCallback: (err: any) => {
          const error = err.code ? ApiErrorMsg[err.code] : 'Gửi lời mời không thành công.';
          toast.error(error);
          console.log(err, 'err');
        }
      }
    }; 
    service.inviteTeam(params);
  };

  const showMemberInformationModal = () => {
    props.showMemberInformationModal(user);
  };

  const handleCancelInviteTeam = () => {
    const params: InviteTeamParams = {
      inputParams: {
        id: user.id
      },
      callBackParams: {
        successCallback: (response: any) => {
          toast.success('Đã hủy lời mời.');
        },
        errorCallback: (err: any) => {
          const error = err.code ? ApiErrorMsg[err.code] : 'Hủy lời mời không thành công.';
          toast.error(error);
          console.log(err, 'err');
        }
      }
    };

    service.cancelInviteTeam(params);
  };

  const rolesCurrentUser = team.getUserRoles();
  const roles = rolesCurrentUser.map((el: Role)=>el.code as string);
  const isOwner = roles.includes('owner');
  const isManager = roles.includes('manager');
  const isMember = roles.includes('member');
  const sameAsCurrentUser = currentUser.email === user.email;

  const renderButtons = (type?: string, user?: any) => {
    if (type === 'owner' && isOwner) {
      return (
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h26v26H0z" opacity=".01" /> <g fill="#AEAEB2" transform="translate(0 10)"> <circle cx="23" cy="3" r="3" /> <circle cx="13" cy="3" r="3" /> <circle cx="3" cy="3" r="3" /> </g> </g> </svg>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="button-link"
              onClick={handleTransferOwnership}
            >
              Chuyển quyền sở hữu
            </button>
          </div>
        </div>
      );
    }
    if (type === 'manager' && (isOwner || isManager) && !sameAsCurrentUser) {
      return (
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h26v26H0z" opacity=".01" /> <g fill="#AEAEB2" transform="translate(0 10)"> <circle cx="23" cy="3" r="3" /> <circle cx="13" cy="3" r="3" /> <circle cx="3" cy="3" r="3" /> </g> </g> </svg>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="button-link"
              onClick={handleDeprecateManagementRole}
            >
              Bỏ quyền Quản lý
            </button>
            <button
              className="button-link"
              onClick={handleRemoveUserFromTeam}
            >
              Xóa khỏi nhóm
            </button>
          </div>
        </div>
      );
    }
    if (type === 'member' && (isOwner || isManager)) {
      return (
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h26v26H0z" opacity=".01" /> <g fill="#AEAEB2" transform="translate(0 10)"> <circle cx="23" cy="3" r="3" /> <circle cx="13" cy="3" r="3" /> <circle cx="3" cy="3" r="3" /> </g> </g> </svg>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="button-link"
              onClick={() => handleTransferOwnershipPermanent(RoleCode[RoleCode.manager])}
            >
              Trao quyền Quản lý
            </button>
            <button
              className="button-link"
              onClick={handleRemoveUserFromTeam}
            >
              Xóa khỏi nhóm
            </button>
          </div>
        </div>
      );
    }
    if (type === 'pending' && (isOwner || isManager)) {
      return (
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h26v26H0z" opacity=".01" /> <g fill="#AEAEB2" transform="translate(0 10)"> <circle cx="23" cy="3" r="3" /> <circle cx="13" cy="3" r="3" /> <circle cx="3" cy="3" r="3" /> </g> </g> </svg>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="button-link"
              onClick={handleResendInviteTeam}
            >
              Gửi lại lời mời
            </button>
            <button
              className="button-link"
              onClick={handleCancelInviteTeam}
            >
              Hủy lời mời
            </button>
          </div>
        </div>
      );
    }
    if (type === 'changeOwner' && isOwner) {
      return (
        <div className="btn-group">
          <ConfirmButton
            onClick={() => handleTransferOwnershipPermanent(RoleCode[RoleCode.owner])}
            confirmTitle={'Chuyển quyền sở hữu'}
            cancelTitle={'Hủy'}
            cancelButtonClassName={'btn-subsidiary btn-grey-suit'}
            customButtonStyle={'btn-default'}
            customStyles={customModalStyles}
            comfirmMessage={
              <div className="transfer-modal__container">
                <h3 className="text-center">Xác nhận</h3>
                <div className="transfer-modal__content">
                  Bạn sẽ bị mất tất cả quyền của chủ sở hữu nhóm <span>#{team.getTeamName()}</span><br /><br />
                  Bạn có chắc chắn chuyển quyền sở hữu cho <strong>{user && user.name} - {user && user.email}</strong> không?
                </div>
              </div>
            }
          >
            Chuyển
          </ConfirmButton>
        </div>
      );
    }
    return null;
  };
  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 999
    },
    content: {
      padding: '0px',
      paddingBottom: '20px',
      top: '50%',
      left: '50%',
      right: 'auto',
      width: '477px',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  };
  return (
    <Styles>
      <div className="member-item">
        <div className="item-wrapper">
          {user && user.name ? <div className="card-wrapper" onClick={showMemberInformationModal}>
            <Avatar user={user}/>
              <div className="user-info-wrapper">
                <div className="user-info">
                  <div className="user-name">{user && user.name}</div>
                  <div className="email">{user && user.email}</div>
                </div>
              </div>
          </div> : <div className="card-wrapper">
            <Avatar user={user}/>
              <div className="user-info-wrapper">
                <div className="user-info">
                  <div className="user-name">{user && user.email}</div>
                </div>
              </div>
          </div>}
        </div>
        {renderButtons(props.type, props.user)}
      </div>
    </Styles>
  );
};

type AvatarProps = {
  user: any;
}

const Avatar: React.FC<AvatarProps> = (props) => {
  if(props.user && props.user.avatar_url)
    return <img
        className="avatar"
        width="36"
        height="36"
        src={props.user.avatar_url}
    />;
  const userId = (props.user && props.user.master_id ? props.user.master_id : props.user.id) || 1;
  const getAvatarById = Math.floor(userId % 120) + 1;
  const defaultAvatar = `${process.env.REACT_APP_TEMPLATE_CDN_URL}/defaul-avatar/avatar_${getAvatarById}.jpg`;
  return <img
        className="avatar"
        width="36"
        height="36"
        src={defaultAvatar}
    />;
};

const Styles = styled.div`
  .member-item {
    padding: 8px 10px 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-wrapper{
    cursor: pointer;
  }
  .member-item:nth-of-type(odd){
    background-color: #f7f8f8;
  }
  .card-wrapper {
    display: flex;
  }
  .user-name {
    font-family: Propins-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .user-info-wrapper {
    display: flex;
    align-items: center;
  }
  .user-info {
    align-items: flex-start;
  }
  .avatar {
    border-radius: 36px;
    border: solid 0.5px #ececec;
    margin-right: 10px;
  }
  .email {
    font-family: Propins-Regular;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8e93;
  }
  .button-link{
    font-size: 14px;
    height: 35px;
  }
  .dropdown-menu{
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .avatar-alias{
    width: 36px;
    height: 36px;
    text-align: center;
    justify-content: center;
    background: #d8d8d8;
    border-radius: 40px;
    margin: 0 10px 0 0;
    padding-top: 7px;
    font-weight: 500;
    font-size: 16px;
    color: #8e8e93;
    text-transform: uppercase;
  }
`;
export default MemberItem;
