import * as React from 'react';
import { 
  GoogleService, 
  GetLPWithGoogleAdsCampaignParams,
  LandingPageWithGoogleAdsCampaign,
  AppState,
  GoogleCampaignAds, 
  // AdsCampaignGoogleAccount 
} from 'core';
import { notification } from 'src/components/notification';
import { useSelector } from 'react-redux';

export const useFetchUserPublishedGoogleUrls = (params?: GetLPWithGoogleAdsCampaignParams) => {
  const service = new GoogleService();

  React.useEffect(() => {
    service.getLPWithGoogleAdsCampaign(params || {});
  }, [params]);
  
};

export const usePublishLP = () => {
  const landingPages = useSelector<any, Array<LandingPageWithGoogleAdsCampaign> | undefined>((state: AppState) => state.adsCampaign.currentLandingPage?.landing_pages);
  
  const getResourceName = (connected_account_id: number, landing_page_id: number, landingPages: LandingPageWithGoogleAdsCampaign[]): GoogleCampaignAds[] => {
    if (!landingPages) {
      return [];
    }

    const ret = landingPages
    .filter((el: LandingPageWithGoogleAdsCampaign)=>el.lp_id === landing_page_id)
    .map(item => item.google_campaigns).flat();
    return ret; 
  };
  
  const checkUserExists = (connected_account_id: number, landing_page_id: number, landingPages: LandingPageWithGoogleAdsCampaign[]): boolean => {
    if (!landingPages) {
      return false;
    }

    const obj = landingPages.find((el: LandingPageWithGoogleAdsCampaign)=>el.lp_id === landing_page_id);
    const campaignAccountSet = new Set(obj?.google_campaigns?.map(el=>el.connected_account_id));
    const isCurrentUser = !obj?.google_campaigns || obj?.google_campaigns?.length === 0 || campaignAccountSet.has(connected_account_id) && campaignAccountSet.size === 1;

    return isCurrentUser; 
  };

  return {
    landingPages, getResourceName, checkUserExists
  };
};