import * as React from 'react';
import { LandingPageVersionStatus, LandingPageVersion } from 'core';
import { TableActions } from '../index';
import { History } from 'src/route/Route';
import useTeamPermission from 'src/pages/team/hooks/useTeamPermission';

interface VersionProps{
  version: LandingPageVersion;
  landingPageId: number;
  actions: TableActions[];
  builder_version: number;
}

const Version: React.FC<VersionProps> = ({version, landingPageId, actions}) => {
  const history = new History();
  const { permissionByRole } = useTeamPermission();
  const {
    id: versionId,
    name,
    created_at: createdAt,
    updated_at: updatedAt,
    url,
    status,
    builder_version,
    page_views: pageViews,
    visitors,
    obtained_contacts: obtainedContacts,
    is_owner
  } = version;

  const ACTIONS = {
    [TableActions.Edit]: (versionId: number, landingPageId: number, builder_version: number): React.ReactChild => {
      return (
        <a
          key={TableActions.Edit}
          href="#"
          onClick={(e): void => {
            e.preventDefault();
            edit(versionId, landingPageId, builder_version);
          }}
        >
          Chỉnh sửa
        </a>
      );
    },

    [TableActions.View]: (versionId: number, landingPageId: number, builder_version: number): React.ReactChild => {
      return (
        <a
          key={TableActions.View}
          href="#"
          onClick={(e): void => {
            e.preventDefault();
            view(versionId, landingPageId, builder_version);
          }}
        >
          Xem
        </a>
      );
    },

    [TableActions.Restore]: (versionId: number, landingPageId: number, builder_version: number): React.ReactChild => {
      return (
        <a
          key={TableActions.Restore}
          href="#"
          onClick={(e): void => {
            e.preventDefault();
            restore(versionId, landingPageId, builder_version);
          }}
        >
          Khôi phục
        </a>
      );
    }
  };

  function edit(versionId: number, landingPageId: number, builder_version: number): void {
    const isBuiderV2 = builder_version === 2;
    const path = history.generateBuilder({landing_page_id: landingPageId, version_id: versionId}, isBuiderV2); 
    window.open(path);
  }

  function view(versionId: number, landingPageId: number, builder_version: number): void {
    const isBuiderV2 = builder_version === 2;
    const path = history.generateBuilder({landing_page_id: landingPageId, version_id: versionId, mode: 'preview_editing'}, isBuiderV2); 
    window.open(path);
  }

  function restore(versionId: number, landingPageId: number, builder_version: number): void {
    const isBuiderV2 = builder_version === 2;
    const path = history.generateBuilder({landing_page_id: landingPageId, version_id: versionId}, isBuiderV2); 
    window.open(path);
  }

  function renderActionColumn(): React.ReactChild {
    if(renderActions(versionId, landingPageId).length === 0) return <div></div>;
    return (
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-link dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g fill="none" fillRule="evenodd">
              <path fill="#FFF" d="M0 0h26v26H0z" opacity=".01" />
              <g fill="#AEAEB2" transform="translate(0 10)">
                <circle cx="23" cy="3" r="3" />
                <circle cx="13" cy="3" r="3" />
                <circle cx="3" cy="3" r="3" />
              </g>
            </g>
          </svg>
        </button>
        <div className="dropdown-menu dropdown-menu-right">{renderActions(versionId, landingPageId)}</div>
      </div>
    );
  }

  function renderActions(versionId: number, landingPageId: number): Array<React.ReactChild> {
    const actionButtons: Array<React.ReactChild> = [];
    actions.forEach((action: TableActions) => {
      const renderBtn = ACTIONS[action];
      const shouldShow = {
        [TableActions.Edit]: is_owner || false,
        // [TableActions.View]: is_owner || permissionByRole(['manager']) || false, TODO: get back to this when manager can see landing page 
        [TableActions.View]: is_owner || false,
        [TableActions.Restore]: is_owner || false
      };
      if (typeof renderBtn === 'function' && shouldShow[action]) {
        actionButtons.push(renderBtn(versionId, landingPageId));
      }
    });
    return actionButtons;
  }

  function renderStatus(): React.ReactChild {
    return (<span className={`lp-status-oval lp-status-${status}`}></span>);
  }

  function renderUrl(): React.ReactChild {
    return status === LandingPageVersionStatus.published && url !== null ?
      <a href={url} target="_blank" rel="noreferrer">{url}</a> : '';
  }

  return (
    <tr id={`${landingPageId}-version-${versionId}`} className="lp-version hidden">
      <td colSpan={1}>{name}</td>
      <td colSpan={1}>{createdAt}</td>
      <td colSpan={1}>{updatedAt}</td>
      <td colSpan={1}>{renderUrl()}</td>
      <td colSpan={1}>{renderStatus()}</td>
      <td colSpan={1}>{pageViews}</td>
      <td colSpan={1}>{visitors}</td>
      <td colSpan={1}>{obtainedContacts}</td>
      <td colSpan={1}>{renderActionColumn()}</td>
    </tr>
  );
};
export default Version;