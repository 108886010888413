"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyticsSagas = exports.getChannelAnalyticsSaga = exports.getDashboardAnalyticsSaga = exports.getLandingPageOverviewAnalyticsSaga = exports.getConversionRateAnalyticsSaga = exports.getTotalConversionAnalyticsSaga = exports.getTotalSessionAnalyticsSaga = exports.getDataRealtimeSaga = exports.getTotalUserAnalyticsSaga = void 0;
const moment = require("moment");
const effects_1 = require("redux-saga/effects");
const api_1 = require("../../adapters/api");
const types_1 = require("../types");
function* getTotalUserAnalyticsSaga(action) {
    try {
        const params = action.params;
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/user`,
            authenticated: true,
            params: {
                landingPageId: params.landingPageId,
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.UserAnalyticAction.success,
            meta: action.meta,
            payload: {
                users: response.data,
            }
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.UserAnalyticAction.error, err });
    }
}
exports.getTotalUserAnalyticsSaga = getTotalUserAnalyticsSaga;
function* getDataRealtimeSaga(action) {
    try {
        const params = action.params;
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/realtime-data`,
            authenticated: true,
            params,
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.RealtimeAction.success,
            meta: action.meta,
            data: response.data.data
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.RealtimeAction.error, err });
    }
}
exports.getDataRealtimeSaga = getDataRealtimeSaga;
function* getTotalSessionAnalyticsSaga(action) {
    try {
        const params = action.params;
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/session`,
            authenticated: true,
            params: {
                landingPageId: params.landingPageId,
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.SessionAnalyticAction.success,
            meta: action.meta,
            payload: {
                sessions: response.data,
            }
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.SessionAnalyticAction.error, err });
    }
}
exports.getTotalSessionAnalyticsSaga = getTotalSessionAnalyticsSaga;
function* getTotalConversionAnalyticsSaga(action) {
    try {
        const params = action.params;
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/conversion`,
            authenticated: true,
            params: {
                landingPageId: params.landingPageId,
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.ConversionAnalyticAction.success,
            meta: action.meta,
            payload: {
                conversions: response.data,
            }
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.ConversionAnalyticAction.error, err });
    }
}
exports.getTotalConversionAnalyticsSaga = getTotalConversionAnalyticsSaga;
function* getConversionRateAnalyticsSaga(action) {
    try {
        const params = action.params;
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/conversion_rate`,
            authenticated: true,
            params: {
                landingPageId: params.landingPageId,
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.ConversionRateAnalyticAction.success,
            meta: action.meta,
            payload: {
                conversionRates: response.data,
            }
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.ConversionRateAnalyticAction.error, err });
    }
}
exports.getConversionRateAnalyticsSaga = getConversionRateAnalyticsSaga;
function* getLandingPageOverviewAnalyticsSaga(action) {
    try {
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/landingpages_overview`,
            authenticated: true,
            params: {
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.LandingpageOverviewAnalyticAction.success,
            meta: action.meta,
            payload: response.data,
        });
    }
    catch (err) {
        yield effects_1.put({ type: types_1.LandingpageOverviewAnalyticAction.error, err });
    }
}
exports.getLandingPageOverviewAnalyticsSaga = getLandingPageOverviewAnalyticsSaga;
function* getDashboardAnalyticsSaga(action) {
    try {
        const params = action.params;
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/user_dashboard`,
            authenticated: true,
            params: {
                landingPageId: params.landingPageId,
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.DashboardAnalyticAction.success,
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.DashboardAnalyticAction.error,
            error: true,
            payload,
            meta: action.meta
        });
    }
}
exports.getDashboardAnalyticsSaga = getDashboardAnalyticsSaga;
function* getChannelAnalyticsSaga(action) {
    try {
        const params = action.params;
        const analyticDuration = yield effects_1.select((state) => state.analytics.duration);
        const request = {
            method: 'get',
            url: `${process.env.REACT_APP_ANALYTICS_API}/analytics/channel_detail`,
            authenticated: true,
            params: {
                landingPageId: params.landingPageId,
                channelId: params.channelId,
                startDate: moment(analyticDuration.startDate).format('YYYY-MM-DD'),
                endDate: moment(analyticDuration.endDate).format('YYYY-MM-DD'),
            },
        };
        const response = yield effects_1.call(api_1.callApi, request);
        yield effects_1.put({
            type: types_1.ChannelAnalyticAction.success,
            channel: Object.assign(Object.assign({}, response.data.channel_detail), { lpLabel: response.data.lpLabel, projectName: response.data.projectName }),
            payload: response.data,
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: types_1.ChannelAnalyticAction.error,
            channel: null,
            error: true,
            payload: Object.assign(Object.assign({}, payload), { data: null }),
            meta: action.meta
        });
    }
}
exports.getChannelAnalyticsSaga = getChannelAnalyticsSaga;
exports.analyticsSagas = [
    effects_1.takeLatest(types_1.UserAnalyticAction.type, getTotalUserAnalyticsSaga),
    effects_1.takeLatest(types_1.RealtimeAction.type, getDataRealtimeSaga),
    effects_1.takeLatest(types_1.SessionAnalyticAction.type, getTotalSessionAnalyticsSaga),
    effects_1.takeLatest(types_1.ConversionAnalyticAction.type, getTotalConversionAnalyticsSaga),
    effects_1.takeLatest(types_1.ConversionRateAnalyticAction.type, getConversionRateAnalyticsSaga),
    effects_1.takeLatest(types_1.ChannelAnalyticAction.type, getChannelAnalyticsSaga),
    effects_1.takeLatest(types_1.LandingpageOverviewAnalyticAction.type, getLandingPageOverviewAnalyticsSaga),
    effects_1.takeLatest(types_1.DashboardAnalyticAction.type, getDashboardAnalyticsSaga),
];
