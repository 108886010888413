import * as React from 'react';
import Campaigns from './campaigns/Campaigns';
import UpgradePackageBanner from './permissions/UpgradePackageBanner';
import { TableMarkupProps, CustomRowProps } from './index.d';
import Versions from './versions/Versions';

export default function TableMarkup(props: TableMarkupProps) {
  // use this variable to decide whether rendering Groups or not
  let tempGroupId = -1;
  const { tableInstance, activeGroup, activeVersion, activeCampaign, activePermission, toggleViewAll } = props;
  const { getTableProps, headerGroups, getTableBodyProps, prepareRow, page } = tableInstance;
  const pageLength = page.length;
  const [isCollapse, setIsCallapse] = React.useState(true);

  function validToRenderGroup(row: CustomRowProps): boolean {
    const original = row.original as any;
    const groupId = original.groupId || 0;

    if (!activeGroup || !groupId) return false;
    if (groupId === tempGroupId) return false;

    return true;
  }

  function renderGroup(row: CustomRowProps) {
    if (!validToRenderGroup(row)) return null;

    const original = row.original as any;
    const { groupId = 0, groupName = '' } = original;
    tempGroupId = groupId;

    return (
      <tr key={groupId}>
        <th colSpan={row.cells.length}>{groupName}</th>
      </tr>
    );
  }

  function renderVersion(row: CustomRowProps) {
    return activeVersion ? <Versions row={row} /> : null;
  }

  function renderCampaign(row: CustomRowProps) {
    return activeCampaign ? <Campaigns row={row} /> : null;
  }

  function renderPermission() {
    return activePermission ? <UpgradePackageBanner /> : null;
  }

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, idx) => {
          prepareRow(row);

          if (toggleViewAll && page.length > 2 && idx === 2 && isCollapse) {
            return (
              <tr key={idx}>
                <td colSpan={row.cells.length}>
                  <button
                    className="button-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsCallapse(false);
                    }}
                  >
                    Xem tất cả version
                  </button>
                  <img
                    className="icon-expand ml-1"
                    src="https://cdn-static.ymeet.me/general/REI/icon/ic_collapse.svg"
                    alt="collapse"
                  ></img>
                </td>
              </tr>
            );
          }

          if (toggleViewAll && page.length > 2 && idx > 2 && isCollapse) {
            return null;
          }

          return (
            <React.Fragment key={row.id}>
              {renderGroup(row)}
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td style={{minWidth: cell.column.width}} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
              {idx == pageLength - 1 && renderPermission()}
              {renderVersion(row)}
              {renderCampaign(row)}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
