import React, { useEffect } from 'react';
import { useForm, Controller, useFormContext, useFieldArray} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { 
  GetDefaultExtensionParams, 
  GetDefaultComponentsParams, 
  GoogleCampaignService, 
  ExtensionServiceType, 
  ComponentListService 
} from 'core';
import { InputType } from '../CreateGoogleCampaign/fields/SitelinkComponent';
import { InputCallType} from '../CreateGoogleCampaign/fields/CallComponent';

interface ErrorResponse{}
interface PropsType<T>{
  landing_page_id: number;
  onSuccess?: (res: T) => void;
}

const service = new GoogleCampaignService();

export const useExtensionCall = (props: PropsType<ExtensionServiceType>) => {
  useEffect(()=>{
    const params: GetDefaultExtensionParams = {
      inputParams: {
        landing_page_id: props.landing_page_id
      },
      callBackParams: {
        successCallback: (res: ExtensionServiceType) => {
          props.onSuccess && props.onSuccess(res);
        },
        errorCallback: (err: ErrorResponse) => {
          // toast.error('Có lỗi khi lấy thông tin');
        }
      }
    };

    props.landing_page_id && service.getDefaultExtension(params);
  // }, [props.landing_page_id]);
  }, []);
};

export const useComponentCall = (props: PropsType<ComponentListService[]>) => {
  useEffect(()=>{
    const params: GetDefaultComponentsParams = {
      inputParams: {
        landing_page_id: props.landing_page_id,
      },
      callBackParams: {
        successCallback: (res: ComponentListService[]) => {
          props.onSuccess && props.onSuccess(res);
        },
        errorCallback: (err: ErrorResponse) => {
          // toast.error('Có lỗi khi lấy thông tin');
        }
      }
    };

    props.landing_page_id && service.getDefaultComponents(params);
  }, [props.landing_page_id]);
};