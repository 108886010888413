"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleAdsSaga = exports.googleAdsReport = void 0;
const effects_1 = require("@redux-saga/core/effects");
const class_transformer_1 = require("class-transformer");
const adapters_1 = require("../../adapters");
const campaignReport_1 = require("../../entities/GoogleAds/campaignReport");
const Report_1 = require("../types/Report");
function* googleAdsReport(action) {
    try {
        const params = action.params;
        const mutateRequest = {
            method: 'get',
            url: `${process.env.REACT_APP_ADS_URL}/google_ads/campaigns`,
            authenticated: true,
            params: params
        };
        const response = yield effects_1.call(adapters_1.callApi, mutateRequest);
        yield effects_1.put({
            type: Report_1.GoogleAdsReportTypes.success,
            payload: class_transformer_1.plainToClass(campaignReport_1.CampaignListReport, response.data.data),
            meta: action.meta
        });
    }
    catch (error) {
        const payload = error.response && error.response.data && error.response.data.error;
        yield effects_1.put({
            type: Report_1.GoogleAdsReportTypes.error,
            meta: action && action.meta,
            payload,
            error: true
        });
    }
}
exports.googleAdsReport = googleAdsReport;
exports.googleAdsSaga = [
    effects_1.takeLatest(Report_1.GoogleAdsReportTypes.type, googleAdsReport),
];
