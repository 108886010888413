"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelAnalyticAction = exports.DashboardAnalyticAction = exports.LandingpageOverviewAnalyticAction = exports.ConversionRateAnalyticAction = exports.ConversionAnalyticAction = exports.RealtimeAction = exports.SessionAnalyticAction = exports.UserAnalyticAction = exports.UpdateAnalyticAction = void 0;
exports.UpdateAnalyticAction = {
    setDuration: 'SET_ANALYTIC_DURATION',
    updateRealtime: 'UPDATE_DETAIL_DATA_IN_REALTIME_ANALYTICS',
    updateChannel: 'UPDATE_DATA_BY_CHANNEL_IN_REALTIME_ANALYTICS',
    updateMinute: 'UPDATE_DATA_PER_MINUTE_IN_REALTIME_ANALYTICS',
    updateLastMinute: 'UPDATE_DATA_LAST_MINUTE_IN_REALTIME_ANALYTICS',
    success: 'UPDATE_DATA_ANALYTICS_SUCCESS',
    error: 'UPDATE_DATA_ANALYTICS_ERROR'
};
exports.UserAnalyticAction = {
    type: 'GET_TOTAL_USER_ANALYTICS',
    success: 'GET_TOTAL_USER_ANALYTICS_SUCCESS',
    error: 'GET_TOTAL_USER_ANALYTICS_ERROR',
};
exports.SessionAnalyticAction = {
    type: 'GET_TOTAL_SESSION_ANALYTICS',
    success: 'GET_TOTAL_SESSION_ANALYTICS_SUCCESS',
    error: 'GET_TOTAL_SESSION_ANALYTICS_ERROR',
};
exports.RealtimeAction = {
    type: 'GET_DATA_REALTIME',
    success: 'GET_DATA_REALTIME_SUCCESS',
    error: 'GET_DATA_REALTIME_ERROR',
};
exports.ConversionAnalyticAction = {
    type: 'GET_TOTAL_CONVERSION_ANALYTICS',
    success: 'GET_TOTAL_CONVERSION_ANALYTICS_SUCCESS',
    error: 'GET_TOTAL_CONVERSION_ANALYTICS_ERROR',
};
exports.ConversionRateAnalyticAction = {
    type: 'GET_CONVERSION_RATE_ANALYTICS',
    success: 'GET_CONVERSION_RATE_ANALYTICS_SUCCESS',
    error: 'GET_CONVERSION_RATE_ANALYTICS_ERROR',
};
exports.LandingpageOverviewAnalyticAction = {
    type: 'GET_LANDINGPAGE_OVERVIEW_ANALYTICS',
    success: 'GET_LANDINGPAGE_OVERVIEW_ANALYTICS_SUCCESS',
    error: 'GET_LANDINGPAGE_OVERVIEW_ANALYTICS_ERROR',
};
exports.DashboardAnalyticAction = {
    type: 'GET_DASHBOARD_ANALYTICS',
    success: 'GET_DASHBOARD_ANALYTICS_SUCCESS',
    error: 'GET_DASHBOARD_ANALYTICS_ERROR',
};
exports.ChannelAnalyticAction = {
    type: 'GET_CHANNEL_ANALYTICS',
    success: 'GET_CHANNEL_ANALYTICS_SUCCESS',
    error: 'GET_CHANNEL_ANALYTICS_ERROR',
};
