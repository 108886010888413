"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidPhoneNumber = exports.isInvalidAddress = exports.User = void 0;
class User {
    constructor(id, master_id, name, email, phone_no, status, address, birthyear, avatar_url, roles) {
        this.email = email;
        this.id = id;
        this.master_id = master_id;
        this.name = name;
        this.phone_no = phone_no;
        this.status = status;
        this.address = address;
        this.birthyear = birthyear;
        this.avatar_url = avatar_url;
        this.auth_provider = '';
        this.roles = roles;
    }
    getUserEmail() {
        return this.email;
    }
    getUserName() {
        return this.name;
    }
    getUserMasterId() {
        return this.master_id ? this.master_id : this.id;
    }
    getUserId() {
        return this.id;
    }
    isActive() {
        return this.status === 'active';
    }
    getDefaultAvatarUrl() {
        const userId = (this.master_id ? this.master_id : this.id) || 1;
        const getAvatarById = Math.floor(userId % 120) + 1;
        const defaultAvatar = `${process.env.REACT_APP_TEMPLATE_CDN_URL}/defaul-avatar/avatar_${getAvatarById}.jpg`;
        return defaultAvatar;
    }
    getAvatarUrl() {
        return this.avatar_url ? this.avatar_url : this.getDefaultAvatarUrl();
    }
    getAccountMembership() {
        return '';
    }
    getAcountType() {
        return this.auth_provider ? this.auth_provider : 'email';
    }
    getRoles(getCode = false) {
        if (!getCode)
            return this.roles && this.roles.map((el) => el.code);
        return this.roles;
    }
    getHigestRole() {
        const roles = this.roles ? this.roles.map((role) => role.code) : [];
        let id = '';
        ['owner', 'manager', 'member'].every((s) => { const check = roles.includes(s); if (check)
            id = s; return !check; });
        return this.roles && this.roles.find((el) => el.code === id);
    }
    checkUserRole(contains, isEveryRoleAvail = false) {
        const roles = this.roles || [];
        if (!contains)
            return roles;
        if (isEveryRoleAvail) {
            return contains.every((contain) => roles.map((el) => el.code).includes(contain));
        }
        else {
            return contains.some((contain) => roles.map((el) => el.code).includes(contain));
        }
    }
}
exports.User = User;
function isInvalidAddress(address) {
    const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !validEmailRegex.test(address);
}
exports.isInvalidAddress = isInvalidAddress;
function isValidPhoneNumber(phone_no) {
    const REGEX_PHONE = /^[0][3|5|7|8|9|]\d{8}$|^[3|5|7|8|9]\d{8}$/;
    return REGEX_PHONE.test(phone_no);
}
exports.isValidPhoneNumber = isValidPhoneNumber;
