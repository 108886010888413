import { LandingPage, Project, ProjectState, userPublishedLandingPagesState,
  ConnectedAccount, //GetAllConnectedGAccountParams,
  AdsCampaignState
} from 'core';
import { useFetchUserLandingPages } from 'pages/landing-page/hooks';
import { useFetchListProjects } from 'pages/project/hooks';
import { useFetchUserPublishedUrls } from 'pages/ads-creation/hooks';
import { useLoggingUpdate, 
  //useAdsCampaign 
} from 'utils/hooks';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ConnectBoards from '../ConnectBoard';
import ListCampaignNeedOptimazation from './ListCampaignNeedOptimazation';

interface ConnectGoogleAdsProps {
  projects: ProjectState;
  landingPages: {
    userLandingPages: Array<LandingPage>;
  };
  userPublishedUrls: userPublishedLandingPagesState;
  adsCampaign: AdsCampaignState;
}

const ConnectGoogleAds = (props: ConnectGoogleAdsProps) => {
  const projects: Array<Project> = props.projects.userProjects;
  const landingPages: Array<LandingPage> = props.landingPages.userLandingPages;
  const accounts: Array<ConnectedAccount> = props.adsCampaign.connectedAccountGroup?.google || [];

  useFetchListProjects(props.projects);
  useFetchUserLandingPages(landingPages);
  // useAdsCampaign();
  useFetchUserPublishedUrls();
  useLoggingUpdate('googleAdsAccount', props);

  return (
    <Styles>
      {accounts.length > 1 
      ? <ConnectBoards />
      : <ListCampaignNeedOptimazation />}
    </Styles>
  );
};

const mapStateToProps = (state: any) => {
  const { projects, landingPages, userPublishedUrls, adsCampaign } = state;
  return {
    adsCampaign,
    projects,
    landingPages,
    userPublishedUrls
  };
};

const ConnectGoogleAdsPage = connect(mapStateToProps)(ConnectGoogleAds);
const Styles = styled.div`
  background-color: #f5f4f9;
  strong {
    font-family: Propins-Medium;
    color: #000000;
  }
  label {
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.4px;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }
  .input-group {
    margin-bottom: 20px;
  }
  .box-shadow {
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    &.active {
      border: solid 2px #4c7cf3;
    }
    img {
      margin: 25px auto;
    }
  }
  .campaign-optimazation-page h2 {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    color: #000000;
  }
  .connect-account p {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.32px;
    color: #666666;
  }
  .recommend-optimization-list {
    margin-top: 20px;
  }
  .recommend-optimization-card {
    padding: 10px;
    border-radius: 8px;
    border: solid 1px rgba(216, 216, 216, 0.5);
    background-color: #ffffff;
  }
  .recommend-optimization-card__name {
    padding: 4px 8px;
    border-radius: 14px;
  }
  .recommend-optimization-card__name.pink {
    background-color: rgba(244, 80, 165, 0.2);
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    color: #f450a5;
  }
  .icon-anchor {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    background-color: #4c7cf3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-wrapper {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(216, 216, 216, 0.8);
  }
  .icon-wrapper.blur, 
  .box-title.blur {
    opacity: 0.15;
  }
  .box-title {
    font-family: Propins-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #000000;
  }
  .box-description {
    font-family: Propins-Regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.26px;
    text-align: center;
    color: #666666;
    margin: 0 30px 30px;
  }
  .campagin-name {
    padding: 4px 8px;
    border-radius: 14px;
    border: solid 1px rgba(216, 216, 216, 0.5);
    background-color: #ffffff;
  }

`;
export default ConnectGoogleAdsPage;
  