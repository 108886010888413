import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LandingPage, TemplateLandingPage, getLandingPage, getTemplateLandingPage } from 'core';

export const useFetchUserLandingPages = (userLandingPages: Array<LandingPage>) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (userLandingPages.length === 0) {
      dispatch(getLandingPage());
    }
  }, []);
};

export const useFetchTemplateLandingPages = (templateLandingPages: Array<TemplateLandingPage>) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (templateLandingPages.length === 0) {
      dispatch(getTemplateLandingPage());
    }
  }, []);
};
