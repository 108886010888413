import * as React from 'react';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { getLandingPageData, AppState, ProjectService, GetProjectParams } from 'core';
import { useLocation, Link } from 'react-router-dom';
import _, { throttle } from 'lodash';
import AdCanvasGenerator from '../components/AdCanvasGenerator';
import { buildParamsFetchLpData, getSearchParams, randomFrame, getTemplateLogo, getCarouselTemplate,randomComponentType, getSingleImageTemplate, getSlideNumber } from './helper';
import SkeletonText from '../SkeletonText';
import { AdFrames } from '../entities/AdFrames';
import { AdFrameState, AdState, ExportMode, FrameType, ImageType, ComponentType, ImageSource } from '../entities/Types';
import ImageSourceUrl from '../entities/ImageSourceUrl';

interface PropsType {
	className: string;
	shouldShowToolbars?: boolean;

	doDownLoad: boolean;
	adStateProp: AdState;
}

const settingsCarousel = {
	dots: true,
	infinite: false,
  speed: 500,
	variableWidth: true,
	slidesToShow: 1,
	slidesToScroll: 1
};

const getFrameModeConfig = (frameType: FrameType, exportMode: ExportMode) => {
	return new AdFrames().getFrameModeConfig(frameType, exportMode);
};

const PreviewSection: React.FC<PropsType> = (props) => {
	let slider: Slider;

	const dispatch = useDispatch();
	const { doDownLoad, className, adStateProp } = props;

	const searchParams = getSearchParams(useLocation);
	const { landingPageId, projectId } = searchParams;

	const project = useSelector((state: AppState) => state.projects);

	const [lpDataState, setLpDataState] = useState({});
	const [titleState, setTitleState] = useState('');
	const [logoState, setLogoState] = useState('');
	const [frameTypeState, setFrameTypeState] = useState(randomFrame());
	const [singleImageState, setSingleImageState] = useState({} as AdFrameState);
	const [carouselState, setCarouselState] = useState([] as Array<AdFrameState>);
	const [carouselWidth, setCarouselWidth] = useState(450);
	const [singleImageWidth, setSingleImageWidth] = useState(450);

	const frameModeConfig = getFrameModeConfig(frameTypeState, adStateProp.exportMode);
	const previewContainer = React.useRef<HTMLDivElement>(null);
  const service = new ProjectService();

	useEffect(() => {
		setFrameTypeState(adStateProp.frameType);
		if (adStateProp.imageType === ImageType.CAROUSEL && slider) {
			slider.slickGoTo(0);
		}
	}, [adStateProp.frameType]);

	useEffect(() => {
    let unmounted = false;
    const updateCarouselImageWidth = () => {      

      if (previewContainer.current && !unmounted) {
				setCarouselWidth(Math.round(previewContainer.current.clientWidth * 0.7) - 15);
				setSingleImageWidth(previewContainer.current.clientWidth - 30);
      }
    };

    updateCarouselImageWidth();
    const updateCarouselImageWidthThrottle = throttle(updateCarouselImageWidth, 300);
    window.addEventListener('resize', updateCarouselImageWidthThrottle);

    return () => {
      unmounted = true;
      window.removeEventListener('resize', updateCarouselImageWidthThrottle);
    };
  }, []);

	useEffect(() => {
		const params: GetProjectParams = {
			inputParams: {projectId},
		};
		
		service.getProject(params);
  }, [dispatch]);

	useEffect(() => {
		const fetchLpData = async () => {
			const prms = buildParamsFetchLpData(landingPageId);
			const lpData = await dispatch(getLandingPageData(prms));
			setLpDataState(lpData);
		};
		fetchLpData();
	}, []);

	useEffect(() => {
		if (_.isEmpty(lpDataState)) return;

		setLogoState(getTemplateLogo(lpDataState));
		setSingleImageState(getSingleImageTemplate(lpDataState, ComponentType.header));
	
		setCarouselState(getCarouselTemplate(lpDataState));
	}, [lpDataState]);

	useEffect(() => {
		if (project.selectedProject) {
			setTitleState(project.selectedProject.name);
		}
	}, [project.selectedProject]);

	const onChooseOtherTemplate = () => {
		if (_.isEmpty(lpDataState)) return;

		setFrameTypeState(randomFrame());
		setSingleImageState(getSingleImageTemplate(lpDataState, randomComponentType(lpDataState)));
		setCarouselState(getCarouselTemplate(lpDataState));

		if (slider) {
			slider.slickGoTo(0);
		}
	};

	const getCroppedImageSource = (adFrameState: AdFrameState): string => {
		const imageSource = new ImageSourceUrl(adFrameState.bkImageSrc);
		return imageSource.getImageUrl(frameModeConfig.size.width, undefined, adFrameState.cropArea);
	};

	const renderSingleImageCanvas = () => {
		const adState = getAdState();
		if (!adState.frameStates || adState.frameStates.length === 0) {
			return;
		}

		const frameState = adState.frameStates[0];
    return (
			<AdCanvasGenerator
          bkImage={{source: getCroppedImageSource(frameState)}}
					frameImage={{source: frameModeConfig.imgSrc}}
					logoImage={{source: adState.logoImageSrc ? adState.logoImageSrc : '', drawArea: frameModeConfig.logo.drawArea, drawRelativeArea: frameModeConfig.logo.drawRelativeArea}}
          title={{text: frameState.title, centerPoint: frameModeConfig.title.centerPoint, font: frameModeConfig.title.font, maxWidth: frameModeConfig.title.maxWidth, colors: frameModeConfig.title.colors}}
          body={{text: frameState.body, centerPoint: frameModeConfig.body.centerPoint, font: frameModeConfig.body.font, maxWidth: frameModeConfig.body.maxWidth, colors: frameModeConfig.body.colors}}
          width={frameModeConfig.size.width}
					height={frameModeConfig.size.height}
					loadingStyle={{width: singleImageWidth, height: Math.round(singleImageWidth * frameModeConfig.size.height / frameModeConfig.size.width), animation: 'none'}}
          doDownLoad={doDownLoad}
					fileName={`${titleState.trim().substr(0, 30)}_${frameModeConfig.exportFileName}`}
        />
    );
  };
  
  const renderCarouselCanvas = () => {
		const adState = getAdState();
		const frameStates = adState.frameStates;
		if (!frameStates || frameStates.length === 0) {
			return;
		}

		const carouselConfig = new AdFrames().getCarouselFrameConfigDate(frameTypeState);

    return (
      <Slider ref={(component: Slider) => { slider = component; }} className="ads-preview-carousel" {...settingsCarousel}>
				{frameStates.map((frameState, index) => {
					const frameConfig = carouselConfig.frames.length > index ? carouselConfig.frames[index] : carouselConfig.frames[0];
					let logoImage: ImageSource | undefined = undefined;
					if (adState.logoImageSrc) {
						logoImage = {
							source: adState.logoImageSrc,
							drawArea: frameConfig.logo.drawArea,
							drawRelativeArea: frameConfig.logo.drawRelativeArea
						};
					}
					
					return (
						<div key={index} style={{width: carouselWidth, height: carouselWidth}}>
							<AdCanvasGenerator 
								bkImage={{source: getCroppedImageSource(frameState)}}
								frameImage={{source: frameConfig.imgSrc}}
								logoImage={logoImage}
								title={{text: frameState.title, centerPoint: frameConfig.title.centerPoint, font: frameConfig.title.font, maxWidth: frameConfig.title.maxWidth, colors: frameConfig.title.colors}}
								body={{text: frameState.body, centerPoint: frameConfig.body.centerPoint, font: frameConfig.body.font, maxWidth: frameConfig.body.maxWidth, colors: frameConfig.body.colors}}
								width={frameConfig.size.width}
								height={frameConfig.size.height}
								loadingStyle={{width: carouselWidth, height: carouselWidth, animation: 'none'}}
								doDownLoad={doDownLoad}
								fileName={`${titleState.trim().substr(0, 30)}_${frameModeConfig.exportFileName}`}
							/>
						</div>
					);
				})}
      </Slider>
    );
	};
	
	const renderCanvas = () => {
    return (
      <>
        {
          adStateProp.imageType === ImageType.SINGLE_IMAGE ?
          renderSingleImageCanvas() :
          renderCarouselCanvas()
        }
      </>
    );
	};

	const getAdState = (): AdState => {
		const getSingleImageFrame = (): Array<AdFrameState> => {
			const frameStates = new Array<AdFrameState>();
			frameStates.push({
				bkImageSrc: singleImageState.bkImageSrc,
				title: titleState,
				body: singleImageState.body,
				cropArea: undefined
			});
			return frameStates;
		};

		const getCaroselFrames = (): Array<AdFrameState> => {
			const frameStates = new Array<AdFrameState>();
			const slideNumber = getSlideNumber();

			for (let i = 0; i < slideNumber; i++) {
				frameStates.push({
					bkImageSrc: carouselState.length > i ? carouselState[i].bkImageSrc : '',
					title: titleState,
					body: carouselState.length > i ? carouselState[i].body : '',
					cropArea: undefined
				});
			}

			return frameStates;
		};
		
		if (adStateProp.isEditing) {
			return adStateProp;
		}
		
		return {
			isEditing: false,
			exportMode: adStateProp.exportMode,
			imageType: adStateProp.imageType,
			frameType: frameTypeState,
			logoImageSrc: logoState,
			frameStates: adStateProp.imageType === ImageType.SINGLE_IMAGE ? getSingleImageFrame() : getCaroselFrames(),
		};
	};
	
	const renderToolbars = () => {
		return (
			<div className="box__buttons">
				<button className="btn-submit" onClick={onChooseOtherTemplate}>
					<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
						<g fill="none" fillRule="evenodd">
								<g>
										<g>
												<g>
														<g>
																<path fill="#FFF" d="M0 0H12V12H0z" opacity=".01" transform="translate(-916 -198) translate(484 178) translate(424 12) translate(8 8)"/>
																<path fill="#4C7CF3" fillRule="nonzero" d="M9.307 10.435c.114 0 .233-.043.324-.12l1.8-1.485c.213-.176.213-.462 0-.638l-1.8-1.495c-.09-.076-.21-.119-.324-.119-.262 0-.424.162-.424.42v.923h-.847c-.495 0-.8-.157-1.186-.61L5.713 5.984 6.85 4.66c.386-.457.69-.614 1.186-.614h.847v.957c0 .257.162.419.424.419.114 0 .233-.043.324-.12l1.8-1.485c.213-.176.213-.457 0-.638l-1.8-1.495c-.09-.076-.21-.119-.324-.119-.262 0-.424.162-.424.42v1.028h-.876c-.828 0-1.323.242-1.909.933L5.027 5.193 3.961 3.946c-.595-.69-1.086-.933-1.91-.933H.972c-.338 0-.562.21-.562.519 0 .304.229.518.562.518h1.104c.457 0 .748.158 1.138.61l1.138 1.323-1.138 1.329c-.395.452-.685.609-1.138.609H.971c-.333 0-.562.214-.562.519 0 .305.224.514.562.514h1.08c.824 0 1.315-.238 1.91-.928l1.066-1.248 1.071 1.248c.586.69 1.08.933 1.91.933h.875v1.057c0 .257.162.419.424.419z" transform="translate(-916 -198) translate(484 178) translate(424 12) translate(8 8)"/>
														</g>
												</g>
										</g>
								</g>
						</g>
					</svg>
					Tạo mẫu khác</button>
				<Link
					className="btn-default"
					to={{
						pathname: (globalThis as any).useHistory.getPath('/edit-ads-media'),
						search: `?project_id=${projectId}&landing_page_id=${landingPageId}`,
            state: {
							adEditState: getAdState(),
						}
          }}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
						<g fill="none" fillRule="evenodd">
							<g fill="#FFF">
								<g>
									<g>
										<g>
											<path d="M0 0H12V12H0z" opacity=".01" transform="translate(-1040 -198) translate(484 178) translate(548 12) translate(8 8)"/>
											<path fillRule="nonzero" d="M10.48 2.749l.69-.677c.35-.348.363-.724.055-1.039l-.232-.232c-.308-.308-.684-.274-1.032.061l-.69.684 1.21 1.203zM.842 11.397l1.579-.609 7.458-7.451-1.197-1.203-7.464 7.458-.65 1.517c-.061.164.11.356.274.288z" transform="translate(-1040 -198) translate(484 178) translate(548 12) translate(8 8)"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
						Chỉnh sửa
					</Link>
			</div>
		);
	};

	return (
		<div className={className}>
			<div className="box-shadow preview-box" ref={previewContainer}>
				<div className="box__title small">
					Xem trước
					{props.shouldShowToolbars && renderToolbars()}
				</div>
				<div className="box__content">
					<SkeletonText />
					{renderCanvas()}
				</div>
			</div>
		</div>
	);
};

PreviewSection.defaultProps = {
	shouldShowToolbars: true,
};

export default PreviewSection;