import { User, UserService } from 'core';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ResendEmailSuccessModal from 'pages/auth/ResendEmailSuccessModal';
import { toast } from 'react-toastify';
import { History } from 'src/route/Route';

const BadgeNoti: React.FC = () => {
  const dispatch = useDispatch();
  const history = new History();
  const user: User = useSelector((state: any) => state.user.user);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [stateUser, setStateUser] = useState(user);
  const [message, setMessage] = useState(`Chúng tôi đã gửi một email chứa đường link xác thực tài khoản tới địa chỉ email (${user.email}). Hãy nhấn vào đường link đó để kích hoạt tài khoản của bạn.`);
  const service = new UserService(); 

  const onResendVerifyEmail = () => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: () => {
          toast.success('Gửi email xác thực thành công.');
          setShouldShowModal(true);
        },
        errorCallback: (err: any) => {
          toast.error('Không thể gửi email xác thực.');
        }
      }
    };
    service.resendVerifyEmail(params);
  };

  if (stateUser.isActive()) {
    return null;
  }

  return (
    <Styles>
      <p>Kiểm tra email và kích hoạt tài khoản để sử dụng trọn vẹn các tính năng của cokhach.vn. Chưa nhận được email? <a className="btn-link d-inline" href="#" onClick={onResendVerifyEmail}>Gửi lại</a>.</p>
      <ResendEmailSuccessModal userEmail={user.email} isOpen={shouldShowModal} closeModal={() => {setShouldShowModal(false);}} />
    </Styles>
  );
};

const Styles = styled.div`
  background-size: contain;
  top: 40px;
  left: 300px;
  width: calc(100% - 300px);
  position: absolute;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  @media (max-width: 1300px) {
    left: 236px;
    width: calc(100% - 236px);
  }
  p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #000000;
    margin: 12px 0;
  }
  .verify-form__heading {
    margin-bottom: 42px;
  }
  .verify-form__bottom {
    margin-top: 40px;
  }
`;

export default BadgeNoti;