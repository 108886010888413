import socket from 'components/socket';
import { getLandingPage, LandingPage } from 'core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import VerifyEmailSuccessModal from '../auth/VerifyEmailSuccessModal';
import { SideMenu, TopMenu } from '../menu';
import { useFetchUserLandingPages } from './hooks';
import { Content } from './LandingPagesContent';

const Styles = styled.div`
  background-color: #f5f4f9;
`;

const LandingManagement = () => {
  const dispatch = useDispatch();
  const landingPages: Array<LandingPage> = useSelector((state) => state.landingPages.userLandingPages);
  const { isVerify } = useParams<{ isVerify: string | undefined }>();
  const shouldShowVerifyModal = (isVerify?: string): boolean => {
    if (!isVerify) {
      return false;
    }
    return isVerify === '1' || isVerify === 'true';
  };
  const [shouldShowModal, setShouldShowModal] = React.useState<boolean>(shouldShowVerifyModal(isVerify));

  useFetchUserLandingPages(useSelector((state) => state.landingPages.userLandingPages));

  const receiveEvent = (data: any) => {
    const { eventCode } = data;
    switch (eventCode) {
      case 'Builder_Save':
        dispatch(getLandingPage());
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    socket.on('receiveEvent', receiveEvent);
    return () => {
      return socket.off('receiveEvent', () => {});
    };
  }, []);

  return (
    <Styles>
      <TopMenu />
      <SideMenu />
      <Content
        landingPages={landingPages}
      />
      <VerifyEmailSuccessModal isOpen={shouldShowModal} closeModal={() => {setShouldShowModal(false);}} />
    </Styles>
  );
};
export default LandingManagement;