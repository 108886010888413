import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import styled from 'styled-components';
import { AppState, Project, ProjectService } from 'core';
import EmptySearchResult from 'pages/project/choose-project/EmptySearchResult';
import SystemProjectResult from 'pages/project/choose-project/SystemProjectResult';
import UserProjectResult from 'pages/project/choose-project/UserProjectResult';
import { useRouter } from 'utils/hooks';

type ChooseProjectTemplateProps = ReturnType<typeof mapStateToProps> & {};
interface ProjectParams {
  url_referrer: string;
}

function ChooseProjectTemplate(props: ChooseProjectTemplateProps) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { url_referrer: urlReferrer } = router.query as ProjectParams;
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const searchingTimer = useRef<number>();
  const systemProjects = useSelector((state: AppState) => state.projects.systemProjects) || [];
  const userProjects = useSelector((state: AppState) => state.projects.userProjects) || [];
  const service = new ProjectService();

  useEffect(() => {
    if (!systemProjects.length) {
      service.getListProjects();
    }
  }, [dispatch, systemProjects.length]);

  const changeSearchTerm: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const keyword = event.currentTarget.value;
    setSearchTerm(keyword.trim());
  };
  const userProjectResult = !searchTerm
    ? userProjects
    : userProjects.filter((el: Project) => el.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
  const systemProjectResult = !searchTerm
    ? systemProjects
    : systemProjects.filter((el: Project) => el.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
  const hasNoSearchResult = !!searchTerm && !userProjectResult.length && !systemProjectResult.length;

  useEffect(() => {
    clearTimeout(searchingTimer.current);
    if (hasNoSearchResult) {
      setIsSearching(true);
      searchingTimer.current = setTimeout(() => {
        setIsSearching(false);
      }, 2000);
    } else {
      setIsSearching(false);
    }

    return () => {
      clearTimeout(searchingTimer.current);
    };
  }, [searchTerm, hasNoSearchResult]);

  const closeLink = urlReferrer === 'project' ? 'project-management' : 'landing-management';

  const renderSearchResult = () => {
    if (!searchTerm) return null;

    if (!hasNoSearchResult) {
      return (<>
        {urlReferrer === 'project' && <React.Fragment>
            <SystemProjectResult projects={systemProjectResult} />
            <UserProjectResult projects={userProjectResult} />
          </React.Fragment>
        }
        {urlReferrer !== 'project' && <React.Fragment>
            <UserProjectResult projects={userProjectResult} />
            <SystemProjectResult projects={systemProjectResult} />
          </React.Fragment>
        }
        </>
      );
    }

    if (!isSearching) {
      return <EmptySearchResult />;
    }

    return (
      <PulseLoader
        css={LoaderStyles}
        size={15}
        color={'#666666'}
        loading={true}
      />
    );
  };

  const gotoGmail = (event: any): void => {
    event.preventDefault();
    window.open('https://mail.google.com/mail/u/0/#inbox?compose=CllgCJvqKnJNHVlTsTlGsFmFFLhRfnjtMNmXLpdDcZsMLgCpkczrVSPfwkqmnMjDQDrbXFprmkg', '_blank');
    return;
  };

  return (
    <Styles>
      <div className="page-heading"></div>
      <div className="container overflow-visible">
        <div className="form-search-project">
          <h1>Chọn một dự án để bắt đầu</h1>
          <p>Để tạo trang landing page, trước tiên bạn hãy tìm kiếm và lựa chọn dự án mình đang bán.</p>
          <Link to={(globalThis as any).useHistory.url(`/${closeLink}`)} className="closeChooseProject">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
              <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h28v28H0z" opacity=".01" />
                <path
                  fill="#000"
                  fillRule="nonzero"
                  d="M16.97 14l7.963 7.962c.324.325.513.634.611.956.098.322.098.651 0 .973-.098.322-.287.63-.611.956l-.086.084c-.325.326-.634.515-.956.613a1.647 1.647 0 0 1-.973 0c-.322-.098-.63-.287-.956-.611L14 16.968l-7.962 7.966c-.325.325-.634.514-.956.612a1.647 1.647 0 0 1-.973 0c-.322-.098-.63-.287-.956-.612l-.084-.085c-.326-.325-.515-.635-.613-.957a1.647 1.647 0 0 1 0-.973c.098-.322.287-.63.611-.956L11.032 14 3.066 6.038c-.325-.325-.514-.634-.612-.956a1.647 1.647 0 0 1 0-.973c.098-.322.287-.63.612-.956l.085-.084c.325-.326.635-.515.957-.613.322-.098.65-.098.973 0 .322.098.63.287.956.611L14 11.032l7.962-7.963c.325-.325.634-.514.956-.612.322-.098.651-.098.973 0 .322.098.63.287.956.612l.084.085c.326.325.515.634.613.956.098.322.098.651 0 .973-.098.322-.287.63-.611.957L16.969 14z"
                />
              </g>
            </svg>
          </Link>
          <div className="input-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
              <path
                fill="#AEAEB2"
                fillRule="evenodd"
                d="M17.046 14.833l5.515 5.516c.53.53.53 1.388 0 1.918l-.294.294c-.53.53-1.388.53-1.918 0l-5.516-5.515a9.391 9.391 0 1 1 2.213-2.213zM9.391 2.609a6.783 6.783 0 1 0 0 13.565 6.783 6.783 0 0 0 0-13.565z"
              />
            </svg>
            <input
              className="search-input"
              name="search-project"
              placeholder="Tìm kiếm dự án (VD: Vinhomes Royal City, FLC Nha Trang,…) "
              onChange={changeSearchTerm}
            />
          </div>
          {hasNoSearchResult && isSearching && (
            <p className="textNoResult">Đang tìm kiếm dự án ...</p>
          )}
          {hasNoSearchResult && !isSearching && (
            <p className="textNoResult">Hiện tại chưa có kết quả phù hợp với từ khoá này. Vui lòng <b>Tạo dự án mới</b> hoặc tìm kiếm bằng từ khoá khác hoặc <a onClick={gotoGmail} className="linkContact" href="mailto:maihuong@mmj.vn">Liên hệ</a> với chúng tôi để tải lên thông tin dự án.</p>
          )}
        </div>
        {renderSearchResult()}
      </div>
    </Styles>
  );
}

const LoaderStyles = css`
  margin: auto;
  text-align: center;
`;

const Styles = styled.div`
  background-color: #f5f4f9;
  overflow: hidden;
  min-height: 100vh;
  h1 {
    font-family: Propins;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -0.3px;
    color: #000000;
    margin-bottom: 10px;
  }
  p {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.3px;
    color: #000000;
  }
  p b {
    color: #000000;
    font-family: Propins-Medium; 
  }
  p a {
    text-decoration: underline;
  }
  h4 {
    font-family: Propins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 20px;
  }
  .input-container {
    display: flex;
    align-items: center;
    max-width: 622px;
    padding-left: 15px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(216, 216, 216, 0.7);
    background-color: #ffffff;
  }
  .search-input {
    flex: 1;
    padding: 15px;
    border: 0;
    outline: none;
    border-radius: 6px;
  }
  .form-search-project {
    position: relative;
  }
  .closeChooseProject {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .textNoResult {
    color: #666666;
    font-family: Propins-Regular;
    font-size: 28px;
    margin-bottom: 60px;
    margin-top: 30px;
    letter-spacing: -0.56px;
  }
  .linkContact {
    color: #4c7cf3;
  }
  .list-projects {
    padding: 20px 0;
    border-bottom: solid 1px #d8d8d8;
    margin-bottom: 20px;
    img {
      border-radius: 6px;
      height: 226px;
      object-fit: cover;
      width: 100%;
    }
    .project-title {
      font-family: Propins;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.36px;
      text-align: center;
      color: #000000;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }
  .more-action {
    margin-bottom: 40px;
  }
  .box-shadow {
    border-radius: 12px;
    box-shadow: 0 0 60px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    height: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    img {
      margin: 25px auto;
    }
  }
  .box-title {
    font-family: Propins;
    font-size: 28px;
    line-height: 1.44;
    letter-spacing: -0.56px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  }
  .box-descriptiion {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: center;
    color: #000000;
  }
  .project-type-list .project-type-item {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #8e8e93;
    padding: 10px 15px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
    margin: 0 8px;
    background-color: #ffffff;
    margin-bottom: 10px;
  }
  .project-type-list .project-type-item.active {
    color: #4c7cf3;
    border-color: #4c7cf3;
  }
  .btn {
    padding: 12px 20px;
    letter-spacing: -0.4px;
    font-size: 20px;
    border-radius: 6px;
    background-color: #4c7cf3;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    svg {
      margin-right: 10px;
    }
  }
  @media (max-width: 1300px) {
    h1 {
      font-size: 24px;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
    }
    .box-title {
      font-size: 20px;
      font-family: Propins-Medium;
    }
    .box-descriptiion {
      margin-bottom: 20px;
    }
    .textNoResult {
      font-size: 16px;
      margin-bottom: 30px;
      margin-top: 15px;
    }
    .btn {
      font-family: Propins-Medium;
      padding: 10px 20px;
      font-size: 16px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .box-shadow img {
      margin: 20px;
    }
  }
`;

const mapStateToProps = (state: AppState) => {
  return {
    userToken: state.auth.userToken,
    projects: state.projects,
  };
};

const ChooseProjectTemplatePage = connect(mapStateToProps)(ChooseProjectTemplate);
export default ChooseProjectTemplatePage;
