import * as React from 'react';
import { ProjectState, ProjectService} from 'core';

export const useFetchListProjects = (projectState: ProjectState) => {
const service = new ProjectService();
  React.useEffect(() => {
    if (projectState.isFetching) {
      return;
    }
    if (projectState.systemProjects.length > 0 || projectState.userProjects.length > 0) {
      return;
    }
    
    service.getListProjects();
  }, []);
};
