import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { 
  AuthService,
  VerifyEmailParameter,
} from 'core';
import { History } from 'src/route/Route';

const VerifyEmail: React.FC = () => {
  const { token, user_id } = useParams<{ token: string; user_id: string }>();
  const history = new History();
  const authService = new AuthService();

  React.useEffect(() => {
    if (!token) {
      history.push('/login');
      return;
    }

    const params: VerifyEmailParameter = {
      inputParams: {
        token,
        user_id
      },
      callBackParams: {
        successCallback: (res: any) => {
          history.push('/verify-email-success', {errCode: res.error?.response?.data?.error?.code || null});
        },
        errorCallback: (res: any) => {
          history.push('/link-expired');
        }
      }
    };
    authService.verifyEmail(params);
  }, []);

  return (
    <div />
  );
};

export default VerifyEmail;