import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import numeral from 'numeral';
import { Tooltip } from 'reactstrap';
import {  GoogleAdsCampaignForecastParams, ForecastGeoTarget, ForecastAdGroup, ForecastKeyword, GoogleAdsEnum, AppState, GoogleService, KeywordData, ApiRequestError, GoogleApiErrorHandlerService, LandingPageWithGoogleAdsCampaign, GetLPWithGoogleAdsCampaignParams, GetLPWithGoogleAdsCampaignSuccessType, UpdateGoogleCampaignStatusCB, ErrorMessageService, CampaignStatus, GoogleCampaignAds, UpdateGoogleCampaignStatusParams } from 'core';
import 'stylesheets/App.css';
import { ReviewAdsType } from '../Settings';
import {AdsCriterion} from '../../criterion';
import { useSelector } from 'react-redux';
import SubmitButton from 'buttons/SubmitButton';
import { CampaignForecast, ICampaignData, CampaignForecastService, ILocation } from 'core';
import { History } from 'src/route';
import { LoadingScreen } from 'components/common/LoadingScreen';
import { useAdGroupAd, useFetchUserPublishedGoogleUrls, useGoogleForm, usePublishLP } from 'utils/hooks';
import { toast } from 'react-toastify';
import { LocationDescriptor } from 'history';
import { WarningLandingPageRunningAds } from '../modals';
import { TooltipIcon } from 'components/icons/SVGIcon';
import { CreateCampaignSuccess } from '../modals/CreateCampaignSuccess';

type ItemLanguageType = { label: string; value: GoogleAdsEnum.Languages};

export function ReviewAdsForm(props: ReviewAdsType) {
  const history = new History();
  const [loading, setLoading] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const toggle = (index: any) => {
    if(tooltipOpen === index)
      setTooltipOpen(null); 
    else
      setTooltipOpen(index);
  };
  const currentCampaign = useSelector<any, ICampaignData | null>((state: AppState) => state.adsCampaign.currentCampaign);
  const arrayLocations = useSelector((state) => state.adsCampaign.locations);
  const adGroups = useSelector<any, KeywordData | null>((state: AppState) => state.adsCampaign.AdGroups);
  const { changeCampaignStatus } = useGoogleForm();
  const campaignForecast = useSelector<any, CampaignForecast | undefined>((state: AppState) => state.campaignForecast.campaignForecast);
  const [numberOfClick, setNumberOfClick] = useState(campaignForecast?.clicks);
  const [pricingEstimed, setPricingEstimed] = useState(campaignForecast?.cost);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [pendingLandingPage, setPendingLandingPage] = useState<any>({
    resourceNames: undefined,
    isReady: false,
  });
  const {landingPages, checkUserExists, getResourceName} = usePublishLP();
  const service = new GoogleService();

  useEffect(() => {
    setNumberOfClick(campaignForecast?.clicks);
    setPricingEstimed(campaignForecast?.cost);
  }, [campaignForecast]);
  
  useEffect(() => {
    if(currentCampaign){
      const {account_id, landing_page_id} = currentCampaign;
      setLoading(true);

      if(!landingPages || !landingPages.length){

        const loadLandingPage: GetLPWithGoogleAdsCampaignParams = {
          inputParams: {
            landing_page_id
          },
          callBackParams: {
            successCallback: (res: any) => {
              setLoading(false);
            },
            errorCallback: (err: any) => {
              setLoading(false);
            }
          }
        };
        service.getLPWithGoogleAdsCampaign(loadLandingPage);
      } else {
        const resourceNames = getResourceName(account_id, landing_page_id, landingPages);
        const userExists = checkUserExists(account_id, landing_page_id, landingPages);
        setLoading(false);

        setPendingLandingPage({
          ...pendingLandingPage,
          resourceNames,
          isReady: userExists
        });

      }
      
    }
  }, [currentCampaign, landingPages]);

  const needGetForecast = (): boolean => {
    if (currentCampaign && currentCampaign.bidding_strategy_type === GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE) {
      return false;
    }

    return true;
  };

  const handleError = (err: ApiRequestError) => {
    toast.error(GoogleApiErrorHandlerService.getErrorMessage(err).message);
  };

  useAdGroupAd({
    onSuccess: (data: any) => {
      setLoading(false);
    },
    campaignResourceName: currentCampaign?.resource_name ?  currentCampaign.resource_name : ''
  });

  React.useEffect(() => {
    if (!needGetForecast()) {
      return;
    }
    const geo_targets: ForecastGeoTarget[] = currentCampaign?.locations ? currentCampaign.locations.map(x => {return {geo_target_constant: x.geo_target_constant};}) : [];
    const ad_groups: ForecastAdGroup[] | undefined = adGroups?.keyword_groups?.map(x => {
      const keywords: ForecastKeyword[] = x.keywords.map(y => {
        return {
          text: y.text,
          match_type: y.match_type
        };
      });
      const adGroup: ForecastAdGroup = {
        name: x.name,
        keywords: keywords
      };
      return adGroup;
    });
    
    const params: GoogleAdsCampaignForecastParams = {
      inputParams: {
        max_cpc: currentCampaign?.bidding_strategy?.max_cpc ? currentCampaign.bidding_strategy.max_cpc : 0,
        geo_targets: geo_targets,
        // language_constants: currentCampaign?.languages ? currentCampaign.languages.map(x => {return 'languageConstants/' + x.language_constant.toString();}) : [],
        language_constants: currentCampaign?.languages ? ['languageConstants/' + currentCampaign?.languages[0].language_constant.toString()] : [],
        ad_groups: ad_groups ? ad_groups : [],
        negative_keywords: adGroups?.negative_keywords ? adGroups.negative_keywords.map(y => {return y.text;}) : [],
        campaign_resource_name: currentCampaign?.resource_name ? currentCampaign?.resource_name : ''
      },
      callBackParams: {
        successCallback: (res: any) => {
          setLoading(false);
        },
        errorCallback: (err: any) => {
          setPricingEstimed(-1);
          setNumberOfClick(-1);
          setLoading(false);
          handleError(err);
        }
      }
    };
    setLoading(true);
    CampaignForecastService.get(params);
    setPricingEstimed(undefined);
    setNumberOfClick(undefined);
  }, []);

  const options = AdsCriterion.language.map(el=>({label: el.name, value: el.id}));
  
  const getLanguagesValue = () => {
    const ret: ItemLanguageType[] = [];
    currentCampaign?.languages?.map(el=> {
      const found = options.find(opt=> opt.value === el.language_constant);
      if(found) ret.push(found);
    });
    
    return ret;
  };

  const getLocationsValue = () => {
    const ret: ILocation[] = [];
    currentCampaign?.locations?.map(l => {
      const found = arrayLocations?.find(opt => opt.geo_target_constant.resource_name == l.geo_target_constant);
      if(found) ret.push(found);
    });
    return ret;
  };

  const selectedLanguageValue = getLanguagesValue();
  const selectedLocationValue = getLocationsValue();

  const redirectToDashboard = () => {
    history.push('/google-ads-dashboard');
  };

  const handleSubmit = React.useCallback((bypass = false) => {
    if(!pendingLandingPage.isReady && !bypass){
      setOpenWarningModal(true);
    } else {
      service.startCreatingNewCampaign();

      if(currentCampaign?.landing_page_id && currentCampaign?.resource_name){
        const callBackParams = {
          successCallback: (res: any) => {
            setOpenSuccessModal(true);
          },
          errorCallback: (err: any) => {
            redirectToDashboard();
            toast.error(ErrorMessageService.getErrorMessageByCode(err.code));
          }
        } as UpdateGoogleCampaignStatusCB;

        const params: UpdateGoogleCampaignStatusParams = {
          inputParams: {
            resource_names: [currentCampaign.resource_name],
            status: CampaignStatus.ENABLED,
            // landing_page_id: currentCampaign.landing_page_id
          },
          callBackParams
        };

        changeCampaignStatus(params);
      }

    }
  }, [pendingLandingPage]);

  const reviewAds = React.useCallback(() => {
    const search = 'lp_id=' + currentCampaign?.landing_page_id + '&ad_account=' + currentCampaign?.account_id;
    // history.push({
    //   pathname: '/google-ads-dashboard', 
    //   search
    // } as LocationDescriptor);
    window.open('/dashboard/google-ads-dashboard?' + search, '_blank');
  }, [currentCampaign]);

  const closeWarningModal = () => {
    setOpenWarningModal(false);
  };

  const handleStopCampaign = () => {
    if(pendingLandingPage?.resourceNames.length > 0 && currentCampaign?.landing_page_id){
      const callBackParams = {
        successCallback: (res: any) => {
          toast.success('Đã tạm dừng chiến dịch thành công');
          closeWarningModal();
          const params: GetLPWithGoogleAdsCampaignParams = {
            inputParams: {
              landing_page_id: currentCampaign.landing_page_id
            },
            callBackParams: {
              successCallback: (res: GetLPWithGoogleAdsCampaignSuccessType) => {
                setPendingLandingPage({
                  ...pendingLandingPage,
                  isReady: true
                });
                handleSubmit(true);
              }
            }
          };
          service.getLPWithGoogleAdsCampaign(params);
        },
        errorCallback: (err: any) => {
          toast.error(ErrorMessageService.getErrorMessageByCode(err.code));
        }
      } as UpdateGoogleCampaignStatusCB;

      const params = {
        inputParams: {
          resource_names: pendingLandingPage.resourceNames.map((el: GoogleCampaignAds)=>el.campaign_resource_name) || [],
          status: CampaignStatus.PAUSED,
          // landing_page_id: pendingLandingPage.lp_id
        },
        callBackParams
      };
      changeCampaignStatus(params);
    }
  };

  const renderEstimatedCostAndClick = () => {
    if (!needGetForecast()) {
      return;
    }

    return (
      <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3>Chi phí & Lượt click</h3>
          </div>
          <div className="col-lg-2">
            <h4>Chi phí (ước tính chi tiêu hàng ngày)</h4>
          <div className="keyword-key big-text">
              {
                numberOfClick === 0 ? 0
                : !pricingEstimed ? 'Đang tải...' 
                : (pricingEstimed !== -1) ? new Intl.NumberFormat('vi-VN', { maximumSignificantDigits: 3 }).format(pricingEstimed) + ' đ' 
                : 'Có lỗi xảy ra'
              } 
            </div>
          </div>
          <div className="col-lg-3">
            <h4>Lượt click (ước tính hàng ngày)</h4>
            <div className="keyword-key big-text">
              { 
                numberOfClick === 0 ? 0
                : !numberOfClick ? 'Đang tải...'
                : numberOfClick === -1 ? 'Có lỗi xảy ra'
                : Math.round(numberOfClick)
              }
            </div>
          </div>
        </div>
    );
  };
  if (loading) {
    return <LoadingScreen />;
  }
  return (<>
    <WarningLandingPageRunningAds 
      isOpen={openWarningModal} 
      closeModal={closeWarningModal}
      reviewAds={reviewAds}
      submit={handleStopCampaign}
    />

    <CreateCampaignSuccess
      isOpen={openSuccessModal}
      submit={redirectToDashboard}
    />
    <div className="page-content review-ads-form">
      <div className="container overflow-visible">
        <div className="row row-seperate">  
          <div className="col-lg-2 offset-lg-1">
            <h3>Quảng cáo</h3>
          </div>
          <div className="col-lg-8">
            {adGroups?.keyword_groups.map((data, index, array) => {
              const isTooltipOpen = tooltipOpen === index;
              return (
                <div key={index} className={`${(array.length > 0 && index < array.length - 1) ? 'row row-seperate--blur' : 'row py-2'}`}>
                  <div className="col-lg-6">
                    <h4>Nhóm QC (Bộ từ khóa)</h4>
                    <div className="keyword-key">{data?.name}</div>
                  </div>
                  <div className="col-lg-6">
                    <h4>Từ khóa
                      <span className="pl-2" id={`TooltipKeywordType${index}`}>
                        <TooltipIcon />
                      </span>
                      <Tooltip placement="bottom" isOpen={isTooltipOpen} target={`TooltipKeywordType${index}`} toggle={()=>toggle(index)}>
                        Dạng từ khóa: {data.MatchTypeList}
                      </Tooltip>
                    </h4>
                    <div className="keyword-key">
                      {data?.keywords.map((v, i) => (i ? ', ' : '') + v.label )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {renderEstimatedCostAndClick()}
        <div className="row row-seperate">
          <div className="col-lg-2 offset-lg-1">
            <h3>Các chi tiết khác</h3>
          </div>
          <div className="col-lg-4">
            <h4>Lịch quảng cáo</h4>
            <div className="keyword-key">
              {moment(currentCampaign?.start_date).format('DD/MM/YYYY')} - {((moment(currentCampaign?.end_date).isSameOrAfter('2037-12-30')) || !currentCampaign?.end_date ) ?  'Không có ngày kết thúc' : moment(currentCampaign?.end_date).format('DD/MM/YYYY')}
            </div>
            <h4>Ngôn ngữ</h4>
            <div className="keyword-key">
              {selectedLanguageValue?.map((e, i) => (i ? ', ' : '') + e.label)}
            </div>
            <h4>Chiến lược giá thầu</h4>
            <div className="keyword-key">
              {currentCampaign?.bidding_strategy_type === GoogleAdsEnum.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE ? 'Tối đa hoá giá trị chuyển đổi' : 'Tối đa hóa số lượt click'}
            </div>
          </div>
          <div className="col-lg-4">
            <h4>Vị trí</h4>
            <div className="keyword-key">
              {!_.isEmpty(selectedLocationValue) ? selectedLocationValue.map((l, i) => `${i > 0 ? ', ' : ''}${l.geo_target_constant.name}`) : 'Việt nam'}
            </div>
            <h4>Ngân sách</h4>
            <div className="keyword-key">
              {numeral(currentCampaign?.campaign_budget?.amount).format('0,0')} VND/ngày
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container bottom-bar">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <SubmitButton onClick={handleSubmit}>Xuất bản chiến dịch</SubmitButton>
        </div>
      </div>
    </div>
    </>
  );
}
