import Table, { TableActions } from 'components/tables/base';
import { CustomCellProps } from 'components/tables/base/index.d';
import {
  LandingPage,
  LandingPageStatus,
  LandingPageVersion,
  LandingPageVersionStatus,
  unpublishLandingPage,
  updateLandingPageStatus,
  UpdateLandingPageStatusParameters,
} from 'core';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import DOM from 'utils/dom';
import { History } from 'src/route/Route';
import { Tooltip } from 'reactstrap';
import useTeamPermission from 'src/pages/team/hooks/useTeamPermission';

type PropsType = {
  landingPages: Array<LandingPage>;
};

const LandingPagesTable: React.FC<PropsType> = (props) => {
  const dispatch = useDispatch();
  const history = new History();
  const { permissionByRole } = useTeamPermission();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'TÊN LANDING PAGE',
        accessor: 'lp_name',
        Cell: (props: CustomCellProps<LandingPage>): React.ReactChild => {
          const versions = props.row.original.versions as LandingPageVersion[];
          const numOfVers = versions.length;
          const latestVer = versions[0] || {} as LandingPageVersion;
          const isBuilderVer2 = props.row.original.builder_version === 2;
          const { id, lp_owner_name, is_owner } = props.row.original;
          return (
            <div>
              {is_owner ? 
                <a
                  className="lp-name"
                  href={history.generateBuilder({landing_page_id: props.row.original.lp_id, version_id: latestVer.id}, isBuilderVer2)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.cell.value}
                </a>
              :
                <>
                  <span className="lp-name">{props.cell.value}</span>
                  <div className="tooltip-container">
                    <span id="TooltipNoteLpName">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"> <g fill="none" fillRule="evenodd"> <g> <g> <g> <path fill="#FFF" d="M0 0H18V18H0z" opacity=".01" transform="translate(-462.000000, -289.000000) translate(329.000000, 222.000000) translate(133.000000, 67.000000)"/> <path fill="#8E8E93" fillRule="nonzero" d="M12.925 17c1.053 0 1.575-.513 1.575-1.65V9.315c0-1.011-.42-1.548-1.274-1.643V5.802C13.226 2.572 11.208 1 9 1S4.774 2.572 4.774 5.802v1.895C3.92 7.8 3.5 8.313 3.5 9.324v6.025C3.5 16.487 4.022 17 5.075 17h7.85zM6.048 7.673V5.628c0-2.204 1.314-3.412 2.952-3.412s2.952 1.208 2.952 3.412v2.037l-5.904.008z" transform="translate(-462.000000, -289.000000) translate(329.000000, 222.000000) translate(133.000000, 67.000000)"/> </g> </g> </g> </g> </svg>
                    </span>
                    <div className="tooltip-content">
                      Bạn chưa được cấp quyền quản lý và chỉnh sửa trang landing page tạo bởi người dùng này 
                    </div>
                  </div>
                </>
              }
              <div
                id={`${id}-toggle`}
                className="lp-toggle"
                onClick={(): void => {
                  onExpandOrCollapse(id);
                }}
              >
                {numOfVers > 0 && <span className="num-of-vers">{` (${numOfVers})`}</span>}
                <img
                  className="icon-expand ml-1"
                  src={'https://cdn-static.ymeet.me/general/REI/icon/ic_collapse.svg'}
                  alt="collapse"
                />
              </div>
              <div className="lp_creater">Tạo bởi: {is_owner ? 'bạn' : lp_owner_name}</div>
            </div>
          );
        },
      },
      {
        Header: 'NGÀY TẠO',
        accessor: 'created_at',
        width: 130,
      },
      {
        Header: 'SỬA LẦN CUỐI',
        accessor: 'updated_at',
        width: 130,
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: (props: CustomCellProps<LandingPage>): React.ReactChild => {
          if (props.row.original.status === LandingPageVersionStatus.published) {
            return (
              <a href={props.cell.value} target="_blank" rel="noreferrer" id={`${props.row.original.id}-url`}>
                {props.cell.value}
              </a>
            );
          }
          return '';
        },
      },
      {
        Header: 'TRẠNG THÁI',
        accessor: 'status',
        width: 68,
        Cell: (props: CustomCellProps<LandingPage>): React.ReactChild => {
          return (
            <span
              id={`${props.row.original.id}-status`}
              className={`lp-status-oval lp-status-${props.cell.value}`}
            ></span>
          );
        },
      },
      {
        Header: 'LƯỢT XEM',
        accessor: 'page_views',
        width: 68,
      },
      {
        Header: 'KHÁCH THĂM',
        accessor: 'visitors',
        width: 68,
      },
      {
        Header: 'KHÁCH THU ĐƯỢC',
        accessor: 'obtained_contacts',
        Cell: (props: CustomCellProps<LandingPage>): React.ReactChild => {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return <a href="#">{props.cell.value}</a>;
        },
        width: 68,
      },
    ],
    []
  );

  const hideLineOnTable = (): void => {
    const hasItemExpaned = document.getElementsByClassName('lp-expanded').length > 0;
    if (hasItemExpaned) {
      const tableTag = document.getElementsByTagName('table');
      tableTag[0].classList.add('no-line-heading');
    }
  };

  const showLineOnTable = (): void => {
    const hasItemExpaned = document.getElementsByClassName('lp-expanded').length > 0;
    if (!hasItemExpaned) {
      const tableTag = document.getElementsByTagName('table');
      tableTag[0].classList.remove('no-line-heading');
    }
  };

  const getVersionToggle = (lpId: number): HTMLElement | null => document.getElementById(`${lpId}-toggle`);

  const isExpandedLP = (lpId: number): boolean => {
    const toggle = getVersionToggle(lpId);
    if (!toggle) return false;
    return toggle.classList.contains('lp-expanded');
  };

  const collapseLP = (lpId: number): void => {
    const toggle = getVersionToggle(lpId);
    toggle && toggle.classList.remove('lp-expanded');

    showLineOnTable();
    showLpUrl(lpId);
    showLpStatus(lpId);
    hideVersion(lpId);
  };

  const expandLP = (lpId: number): void => {
    const toggle = getVersionToggle(lpId);
    toggle && toggle.classList.add('lp-expanded');

    hideLineOnTable();
    hideLpUrl(lpId);
    hideLpStatus(lpId);
    showVersion(lpId);
  };

  const onExpandOrCollapse = (lpId: number): void => {
    isExpandedLP(lpId) ? collapseLP(lpId) : expandLP(lpId);
  };

  const showVersion = (lpId: number): void => {
    const versionEls = document.querySelectorAll(`[id^="${lpId}-version"]`);
    versionEls.forEach((v) => DOM.show(v));
  };

  const hideVersion = (lpId: number): void => {
    const versionEls = document.querySelectorAll(`[id^="${lpId}-version"]`);
    versionEls.forEach((v) => DOM.hide(v));
  };

  const showLpUrl = (lpId: number): void => {
    const urlEl = document.getElementById(`${lpId}-url`);
    DOM.show(urlEl);
  };

  const hideLpUrl = (lpId: number): void => {
    const urlEl = document.getElementById(`${lpId}-url`);
    DOM.hide(urlEl);
  };

  const showLpStatus = (lpId: number): void => {
    const statusEl = document.getElementById(`${lpId}-status`);
    DOM.show(statusEl);
  };

  const hideLpStatus = (lpId: number): void => {
    const statusEl = document.getElementById(`${lpId}-status`);
    DOM.hide(statusEl);
  };

  const onDeleteLandingPage = (landingPageId: number): void => {
    const parameter: UpdateLandingPageStatusParameters = {
      landingPageId: landingPageId,
      status: LandingPageStatus.deactive,
    };
    dispatch(updateLandingPageStatus(parameter));
  };

  const onUnpublishLandingPage = (landingPageId: number): void => {
    dispatch(unpublishLandingPage(landingPageId));
  };

  const redirectToLandingPageBuilder = (landingPageId: number): void => {
    const landingPages = props.landingPages;
    const selectedLandingPage = landingPages.find((landingPage) => {
      return landingPage.id === landingPageId;
    });
    if (!selectedLandingPage) return;
    const latestVersion = selectedLandingPage.versions[0];
    const isBuilderVer2 = selectedLandingPage.builder_version === 2;
    const path = history.generateBuilder({landing_page_id: landingPageId, version_id: latestVersion.id}, isBuilderVer2);
    window.open(path);
  };

  const shouldShowAction = (landingPage: LandingPage, action: TableActions): boolean => {
    let shouldShow = false;
    switch (action) {
      case TableActions.Delete:
        shouldShow = landingPage.is_owner || false;
        break;
      case TableActions.Rename:
        shouldShow = landingPage.is_owner || false;
        break;
      case TableActions.Duplicate:
        shouldShow = landingPage.is_owner || false;
        break;
      case TableActions.Edit:
        shouldShow = landingPage.is_owner || false;
        break;
      case TableActions.Unpublish:
        shouldShow = landingPage.status === LandingPageVersionStatus.published && landingPage.is_owner;
        break;
      default:
        break;
    }
    return shouldShow;
  };

  return (
    <Styles>
      <Table
        columns={columns}
        data={props.landingPages}
        actions={[
          TableActions.Edit,
          TableActions.View,
          TableActions.Duplicate,
          TableActions.Rename,
          TableActions.Unpublish,
          TableActions.Delete,
        ]}
        activeGroup //<= This table should active groups as the project names
        onDeleteRow={onDeleteLandingPage}
        onUnpublishAction={onUnpublishLandingPage}
        onEditRow={redirectToLandingPageBuilder}
        shouldShowAction={shouldShowAction}
        activeVersion
      />
    </Styles>
  );
};

const Styles = styled.div`
  padding: 40px 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  table {
    width: 100%;
    border-spacing: 0;
    @media (max-width: 1300px) {
      min-width: calc(100vw - 276px);
    }

    &.no-line-heading {
      tbody th:before {
        content: none;
      }
    }

    tr {
      :first-child {
        td {
          :first-child {
            border-top-left-radius: 12px;
          }
          :last-child {
            border-top-right-radius: 12px;
          }
        }
      }
      :last-child {
        td {
          border-bottom: 0;
          :first-child {
            border-bottom-left-radius: 12px;
          }
          :last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    tbody tr.lp-version td:first-child:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: calc(100% + 1px);
      background-color: #4c7cf3;
    }

    tbody tr.lp-version td {
      position: relative;
      font-family: Propins-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.26px;
      color: #666666;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .lp-status-oval {
      border-radius: 100%;
      width: 8px;
      height: 8px;
      display: inline-block;
    }

    .lp-status-1 {
      background-color: #f7b500;
    }

    .lp-status-2 {
      background-color: #38cd52;
    }

    .lp-status-3 {
      background-color: #ff3b30;
    }

    #TooltipNoteLpName {
      margin-left: 12px;
      margin-right: 12px;
    }

    .tooltip-content {
      width: 275px;
      height: 30px;
      display: none;
      position: absolute;
      left: 1px;
      top: 30px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      box-shadow: 0 2px 10.5px 0 rgba(0, 0, 0, 0.25);
      border: solid 1px rgba(255, 255, 255, 0.15);
      background-color: rgba(51, 51, 51, 0.75);
      font-family: Propins;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.07px;
      text-align: center;
      color: #ffffff;
      z-index: 22;
    }
    
    .tooltip-container {
      cursor: pointer;
      position: relative;
      display: inline-block;
    }

    .tooltip-container:hover .tooltip-content {
      display: block;
    }

    .lp_creater {
      font-family: Propins-Regular;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      color: #8e8e93;
    }

    thead th {
      font-family: Propins-Bold;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #aeaeb2;
      padding: 15px;
    }

    tbody {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      td:last-child {
        @media (max-width: 1300px) {
          min-width: 50px;
          position: sticky;
          right: 0px;
          background-color: white;
          border-bottom: 0;
          border-left: solid 1px rgba(216,216,216,0.5);
        }
        .btn-group {
          opacity: 0;
          visibility: hidden;
          @media (max-width: 1300px) {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      tr:hover {
        td:last-child {
          .btn-group {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    tbody th,
    tbody td {
      margin: 0;
      padding: 20px;
      border-bottom: solid 1px rgba(216, 216, 216, 0.5);
      @media (max-width: 1300px) {
        padding: 12px 10px;
      }

      :last-child {
        border-right: 0;
        text-align: left;
      }
    }

    tbody th {
      padding: 12px 15px;
      background-color: #f7f8f8;
      font-family: Propins-Medium;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: #aeaeb2;
      position: relative;
      @media (max-width: 1300px) {
        font-size: 14px;
      }
    }
    tbody th:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 45px;
      border-radius: 1.5px;
      background-color: #4c7cf3;
    }
    tbody td {
      font-family: Propins-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #000000;
      @media (max-width: 1300px) {
        font-size: 14px;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .num-of-vers {
    font-family: Propins-Medium;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #4c7cf3;
  }

  .lp-toggle {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
  }

  .lp-toggle .icon-expand {
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out 0s;
  }

  .lp-toggle.lp-expanded .icon-expand {
    transform: rotate(180deg);
  }

  .hidden {
    display: none !important;
  }

  .lp-name {
    color: #000;
  }

  .lp-name:hover {
    color: #4c7cf3;
  }
  @media (max-width: 1300px) {
    padding: 10px 20px 20px;

    table thead th {
      padding: 10px;
    }
  }
`;

export default LandingPagesTable;
