"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartCreatingCampaign = exports.UpdateCampaign = exports.GetLocationAds = exports.FetchConnectedAccount = exports.DisconnectAdsAccount = exports.ConnectAccount = exports.FetchGGAdsCustomer = exports.CreateOrUpdateAdGroup = exports.CreateOrUpdateAdGroupAd = exports.GetListAdGroupByCampaign = exports.GetAdGroupsBelongToCampaign = exports.SaveDraftGoogleCampaign = exports.CreateGoogleCampaign = exports.InitialCampaign = exports.GetDetailGoogleCampaign = exports.UpdateGoogleCampaignStatus = exports.GetGKeywordEstimation = exports.GetListKeywordSuggestion = void 0;
exports.GetListKeywordSuggestion = {
    type: 'GetListKeywordSuggestion',
    success: 'GetListKeywordSuggestion_SUCCESS',
    error: 'GetListKeywordSuggestion_ERROR',
};
exports.GetGKeywordEstimation = {
    type: 'GetGKeywordEstimation',
    success: 'GetGKeywordEstimation_SUCCESS',
    error: 'GetGKeywordEstimation_ERROR',
};
exports.UpdateGoogleCampaignStatus = {
    type: 'UpdateGoogleCampaignStatus',
    success: 'UpdateGoogleCampaignStatus_SUCCESS',
    error: 'UpdateGoogleCampaignStatus_ERROR',
};
exports.GetDetailGoogleCampaign = {
    type: 'GetDetailGoogleCampaign',
    success: 'GetDetailGoogleCampaign_SUCCESS',
    error: 'GetDetailGoogleCampaign_ERROR',
};
exports.InitialCampaign = {
    type: 'InitialCampaign',
    success: 'InitialCampaign_SUCCESS',
    error: 'InitialCampaign_ERROR',
};
exports.CreateGoogleCampaign = {
    type: 'CreateGoogleCampaign',
    success: 'CreateGoogleCampaign_SUCCESS',
    error: 'CreateGoogleCampaign_ERROR',
};
exports.SaveDraftGoogleCampaign = {
    type: 'SaveDraftGoogleCampaign',
    success: 'SaveDraftGoogleCampaign_SUCCESS',
    error: 'SaveDraftGoogleCampaign_ERROR',
};
exports.GetAdGroupsBelongToCampaign = {
    type: 'GetAdGroupsBelongToCampaign',
    success: 'GetAdGroupsBelongToCampaign_SUCCESS',
    error: 'GetAdGroupsBelongToCampaign_ERROR',
};
exports.GetListAdGroupByCampaign = {
    type: 'GetListAdGroupByCampaign',
    success: 'GetListAdGroupByCampaign_SUCCESS',
    error: 'GetListAdGroupByCampaign_ERROR',
};
exports.CreateOrUpdateAdGroupAd = {
    type: 'CreateOrUpdateAdGroupAd',
    success: 'CreateOrUpdateAdGroupAd_SUCCESS',
    error: 'CreateOrUpdateAdGroupAd_ERROR',
};
exports.CreateOrUpdateAdGroup = {
    type: 'CreateOrUpdateAdGroup',
    success: 'CreateOrUpdateAdGroup_SUCCESS',
    error: 'CreateOrUpdateAdGroup_ERROR',
};
exports.FetchGGAdsCustomer = {
    type: 'FetchGGAdsCustomer',
    success: 'FetchGGAdsCustomer_SUCCESS',
    error: 'FetchGGAdsCustomer_ERROR',
};
exports.ConnectAccount = {
    type: 'ConnectAccount',
    success: 'ConnectAccount_SUCCESS',
    error: 'ConnectAccount_ERROR',
};
exports.DisconnectAdsAccount = {
    type: 'DisconnectAdsAccount',
    success: 'DisconnectAdsAccount_SUCCESS',
    error: 'DisconnectAdsAccount_ERROR'
};
exports.FetchConnectedAccount = {
    type: 'FetchConnectedAccount',
    success: 'FetchConnectedAccount_SUCCESS',
    error: 'FetchConnectedAccount_ERROR',
};
exports.GetLocationAds = {
    type: 'GetLocationAds',
    success: 'GetLocationAds_SUCCESS',
    error: 'GetLocationAds_ERROR',
};
exports.UpdateCampaign = {
    type: 'UpdateCampaign',
};
exports.StartCreatingCampaign = {
    type: 'StartCreatingCampaign'
};
