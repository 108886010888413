import * as React from 'react';
import { useRef, useEffect, useState } from 'react';

import * as ReactModal from 'react-modal';

interface ModalProps {
    customStyles?: object;
    isOpen: boolean;
    submit: () => void;
}
ReactModal.setAppElement('#root');

export const CreateCampaignSuccess: React.FC<ModalProps> = (props) => {
    const mounted = useRef(false);
    // const fbAccessToken = useSelector((state) => state.adsCampaign.fbAccessToken);

    const history = (globalThis as any).useHistory;
    history.hook();

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const submit = () => {
        props.submit();
    };

    return (
        <ReactModal isOpen={props.isOpen} onRequestClose={() => { }} style={props.customStyles}>
            <div className="connect-account-modal">
                <div className="modal__heading">
                    <h3>Xuất bản thành công</h3>
                </div>
                <div className="modal__content">
                    Chiến dịch quảng cáo tìm kiếm Google Ads của bạn đã được tạo thành công. Hiệu quả của quảng cáo sẽ được cập nhật sau khi quảng cáo bắt đầu được phân phối.
                </div>
                <div className="modal__bottom">
                    <button onClick={submit} className="btn-default ml-3">Xong</button>
                </div>
            </div>
        </ReactModal>
    );
};

CreateCampaignSuccess.defaultProps = {
    customStyles: {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: '9'
        },
        content: {
            position: 'relative',
            width: '530px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '0',
            padding: '0',
            borderRadius: '12px',
            boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
            transform: 'translate(-50%, -50%)'
        }
    }
};