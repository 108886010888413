import {ExportMode, FrameType, Area, Point, Font, Size, RelativeArea} from './Types';

interface FrameModeConfig {
  size: Size;
  imgSrc: string;
  logo: {
    drawArea?: Area;
    drawRelativeArea?: RelativeArea;
  };
  title: {
    centerPoint: Point;
    font: Font;
    maxWidth: number;
    colors: Array<string>;
  };
  body: {
    centerPoint: Point;
    font: Font;
    maxWidth: number;
    colors: Array<string>;
  };
  exportFileName: string;
}

type FrameConfig = {
  [key in ExportMode]: FrameModeConfig;  
}

type FrameConfigData = {
  [key in FrameType]: FrameConfig;
}

type CarouselConfig = {
  frames: Array<FrameModeConfig>;
}

type CarouselConfigData = {
  [key in FrameType]: CarouselConfig;
}

const defaultFrameConfig: FrameConfigData = {
  Frame1: {
    mode_191_1: {
      size: {
        width: 1200,
        height: 628
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-1-1-91-1.png`,
      logo: {
        drawRelativeArea: {
          topLeft: {x: 50, y: 528},
          height: 80
        }
      },
      title: {
        centerPoint:{
          x: 831, 
          y: 510
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 30
        },
        maxWidth: 658, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 831, y: 562}, 
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 36
        },
        maxWidth: 658,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame1_191.jpg'
    },
    mode_1_1: {
      size: {
        width: 1080,
        height: 1080
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-1-1-1.png`,
      logo: {
        drawRelativeArea: {
          topLeft: {x: 50, y: 980},
          height: 80
        }
      },
      title: {
        centerPoint:{
          x: 748, 
          y: 962
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 30
        },
        maxWidth: 614, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 748, y: 1014}, 
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 36
        }, 
        maxWidth: 614,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame1_11.jpg'
    },
    mode_4_5: {
      size: {
        width: 960,
        height: 1200
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-1-4-5.png`,
      logo: {
        drawRelativeArea: {
          topLeft: {x: 50, y: 1040},
          height: 80
        }
      },
      title: {
        centerPoint:{
          x: 649, 
          y: 969
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 36
        },
        maxWidth: 552, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 649, y: 1064},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 48
        }, 
        maxWidth: 552,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame1_45.jpg'
    }
  },
  Frame2: {
    mode_191_1: {
      size: {
        width: 1200,
        height: 628
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-2-1-91-1.png`,
      logo: {
        drawRelativeArea: {
          topRight: {x: 30, y: 528},
          height: 80
        }
      },
      title: {
        centerPoint:{
          x: 425, 
          y: 478
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 30
        },
        maxWidth: 751, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 425, y: 536},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 40
        }, 
        maxWidth: 751,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame2_191.jpg'
    },
    mode_1_1: {
      size: {
        width: 1080,
        height: 1080
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-2-1-1.png`,
      logo: {
        drawRelativeArea: {
          topRight: {x: 30, y: 960},
          height: 100
        }
      },
      title: {
        centerPoint:{
          x: 425, 
          y: 906
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 34
        },
        maxWidth: 751, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 425, y: 966},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 44
        }, 
        maxWidth: 751,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame2_11.jpg'
    },
    mode_4_5: {
      size: {
        width: 960,
        height: 1200
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-2-4-5.png`,
      logo: {
        drawRelativeArea: {
          topRight: {x: 30, y: 1070},
          height: 100
        }
      },
      title: {
        centerPoint:{
          x: 383,
          y: 945
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 36
        },
        maxWidth: 667, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 383, y: 1022},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 50
        }, 
        maxWidth: 667,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame2_45.jpg'
    }
  },
  Frame3: {
    mode_191_1: {
      size: {
        width: 1200,
        height: 628
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-3-1-91-1.png`,
      logo: {
        drawRelativeArea: {
          topLeft: {x: 143, y: 518},
          height: 70
        }
      },
      title: {
        centerPoint:{
          x: 777,
          y: 520
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 24
        },
        maxWidth: 725, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 777, y: 562},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 34
        }, 
        maxWidth: 725,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame3_191.jpg'
    },
    mode_1_1: {
      size: {
        width: 1080,
        height: 1080
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-3-1-1.png`,
      logo: {
        drawRelativeArea: {
          topLeft: {x: 100, y: 945},
          height: 70
        }
      },
      title: {
        centerPoint:{
          x: 675,
          y: 934
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 34
        },
        maxWidth: 751, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 675, y: 995},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 44
        }, 
        maxWidth: 751,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame3_11.jpg'
    },
    mode_4_5: {
      size: {
        width: 960,
        height: 1200
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-3-4-5.png`,
      logo: {
        drawRelativeArea: {
          topLeft: {x: 80, y: 1035},
          height: 80
        }
      },
      title: {
        centerPoint:{
          x: 598,
          y: 1024
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 36
        },
        maxWidth: 643, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 598, y: 1089},
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 48
        }, 
        maxWidth: 643,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame3_45.jpg'
    }
  },
  Frame4: {
    mode_191_1: {
      size: {
        width: 1200,
        height: 628
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-1-91-1.png`,
      logo: {
        drawRelativeArea: {
          centerTop: {x: 600, y: 442},
          height: 50
        }
      },
      title: {
        centerPoint:{
          x: 600, 
          y: 528
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 30
        },
        maxWidth: 1080, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 600, y: 581}, 
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 40
        }, 
        maxWidth: 1080,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame4_191.jpg'
    },
    mode_1_1: {
      size: {
        width: 1080,
        height: 1080
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-1-1.png`,
      logo: {
        drawRelativeArea: {
          centerTop: {x: 540, y: 824},
          height: 50
        }
      },
      title: {
        centerPoint:{
          x: 540, 
          y: 938
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 30
        },
        maxWidth: 960, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 540, y: 995}, 
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 48
        }, 
        maxWidth: 960,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame4_11.jpg'
    },
    mode_4_5: {
      size: {
        width: 960,
        height: 1200
      },
      imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-4-5.png`,
      logo: {
        drawRelativeArea: {
          centerTop: {x: 480, y: 908},
          height: 50
        }
      },
      title: {
        centerPoint:{
          x: 480, 
          y: 1024
        },
        font: {
          name: 'NewYorkLarge-Medium', 
          size: 34
        },
        maxWidth: 840, 
        colors: ['white']
      },
      body: {
        centerPoint: {x: 480, y: 1088}, 
        font: {
          name: 'NewYorkLarge-Semibold', 
          size: 48
        }, 
        maxWidth: 840,
        colors: ['#b6962d', '#e6e7dc', '#b6962d']
      },
      exportFileName: 'AdFame4_45.jpg'
    }
  }
};

const defaultCaroselConfidData: CarouselConfigData = {
  Frame1: {
    frames: [
      {
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-1-1-1.png`,
        logo: {
          drawRelativeArea: {
            topLeft: {x: 50, y: 980},
            height: 80
          }
        },
        title: {
          centerPoint:{
            x: 748, 
            y: 962
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 30
          },
          maxWidth: 614, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 748, y: 1014}, 
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 36
          }, 
          maxWidth: 614,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel1_1.jpg'
      }
    ]
  },
  Frame2: {
    frames: [
      {
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-2-1-1.png`,
        logo: {
          drawRelativeArea: {
            topRight: {x: 30, y: 960},
            height: 100
          }
        },
        title: {
          centerPoint:{
            x: 425, 
            y: 906
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 34
          },
          maxWidth: 751, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 425, y: 966},
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 44
          }, 
          maxWidth: 751,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel2_1.jpg'
      }
    ]
  },
  Frame3: {
    frames: [
      {
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-3-1-1.png`,
        logo: {
          drawRelativeArea: {
            topLeft: {x: 100, y: 945},
            height: 70
          }
        },
        title: {
          centerPoint:{
            x: 675,
            y: 934
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 34
          },
          maxWidth: 751, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 675, y: 995},
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 44
          }, 
          maxWidth: 751,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel3_1.jpg'
      }
    ]
  },
  Frame4: {
    frames: [
      {
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-carousel-1.png`,
        logo: {
          drawRelativeArea: {
            centerTop: {x: 540, y: 824},
            height: 50
          }
        },
        title: {
          centerPoint:{
            x: 540, 
            y: 938
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 30
          },
          maxWidth: 960, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 540, y: 995}, 
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 48
          }, 
          maxWidth: 960,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel4_1.jpg'
      },
      { 
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-carousel-2.png`,
        logo: {
          drawRelativeArea: {
            centerTop: {x: 540, y: 824},
            height: 50
          }
        },
        title: {
          centerPoint:{
            x: 540, 
            y: 938
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 30
          },
          maxWidth: 960, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 540, y: 995}, 
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 48
          }, 
          maxWidth: 960,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel4_2.jpg'
      },
      { 
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-carousel-3.png`,
        logo: {
          drawRelativeArea: {
            centerTop: {x: 540, y: 824},
            height: 50
          }
        },
        title: {
          centerPoint:{
            x: 540, 
            y: 938
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 30
          },
          maxWidth: 960, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 540, y: 995},
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 48
          }, 
          maxWidth: 960,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel4_3.jpg'
      },
      { 
        size: {
          width: 1080,
          height: 1080
        },
        imgSrc: `${process.env.REACT_APP_TEMPLATE_CDN_URL}/frame-4-carousel-4.png`,
        logo: {
          drawRelativeArea: {
            centerTop: {x: 540, y: 824},
            height: 50
          }
        },
        title: {
          centerPoint:{
            x: 540, 
            y: 938
          },
          font: {
            name: 'NewYorkLarge-Medium', 
            size: 30
          },
          maxWidth: 960, 
          colors: ['white']
        },
        body: {
          centerPoint: {x: 540, y: 995},
          font: {
            name: 'NewYorkLarge-Semibold', 
            size: 48
          }, 
          maxWidth: 960,
          colors: ['#b6962d', '#e6e7dc', '#b6962d']
        },
        exportFileName: 'AdFame_Carosel4_4.jpg'
      }
    ]
  }
};

export class AdFrames {
  private _frameConfigData: FrameConfigData;
  private _carouselConfigData: CarouselConfigData;

  constructor() {
    this._frameConfigData = defaultFrameConfig;
    this._carouselConfigData = defaultCaroselConfidData;
  }

  get FrameConfigData(): FrameConfigData {
    return this._frameConfigData;
  }

  getFrameModeConfig = (frameType: FrameType, exportMode: ExportMode): FrameModeConfig => {
    return this._frameConfigData[frameType][exportMode];
  }

  get CarouselConfigData(): CarouselConfigData {
    return this._carouselConfigData;
  }

  getCarouselFrameConfigDate = (frameType: FrameType): CarouselConfig => {
    return this._carouselConfigData[frameType];
  }
}