"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdGroupAd = exports.Ad = void 0;
const class_transformer_1 = require("class-transformer");
const types_1 = require("../../ads-campaign/types");
const ResourceOperation_1 = require("./ResourceOperation");
class Ad extends ResourceOperation_1.ResourceOperation {
    constructor() {
        super();
        this.type = types_1.GoogleAdsEnum.AdType.EXPANDED_TEXT_AD;
        this.descriptions = [''];
        this.headlines = [''];
        this.paths = ['', ''];
        this.final_url = '';
    }
    _isEqual(textArr1, textArr2) {
        const filteredArray1 = textArr1.filter(x => (x === null || x === void 0 ? void 0 : x.trim().length) > 0);
        const filteredArray2 = textArr2.filter(x => (x === null || x === void 0 ? void 0 : x.trim().length) > 0);
        if (filteredArray1.length !== filteredArray2.length ||
            filteredArray1.some(x => !filteredArray2.some(y => y === x)) ||
            filteredArray2.some(x => !filteredArray1.some(y => y === x))) {
            return false;
        }
        return true;
    }
    isEqual(ad) {
        if (this.type !== ad.type) {
            return false;
        }
        if (!this._isEqual(this.descriptions, ad.descriptions)) {
            return false;
        }
        if (!this._isEqual(this.headlines, ad.headlines)) {
            return false;
        }
        if (!this._isEqual(this.paths, ad.paths)) {
            return false;
        }
        if (this.final_url !== ad.final_url)
            return false;
        return true;
    }
    cloneResource() {
        const cloneAd = new Ad();
        cloneAd.type = this.type;
        cloneAd.descriptions = this.descriptions;
        cloneAd.headlines = this.headlines;
        cloneAd.paths = this.paths;
        cloneAd.final_url = this.final_url;
        return cloneAd;
    }
    toJson() {
        const filterEmpty = (values) => {
            return values.filter(x => { return x && x.trim().length > 0; });
        };
        return Object.assign(Object.assign({}, this), { descriptions: filterEmpty(this.descriptions), headlines: filterEmpty(this.headlines), paths: filterEmpty(this.paths) });
    }
}
exports.Ad = Ad;
class AdGroupAd extends ResourceOperation_1.ResourceOperation {
    constructor() {
        super();
        this.ad_group_resource_name = '';
        this.ad = new Ad();
    }
    isEqual(adGroupAd) {
        return this.ad_group_resource_name === adGroupAd.ad_group_resource_name && this.ad.isEqual(adGroupAd.ad);
    }
    cloneResource() {
        const cloneAdGroupAd = new AdGroupAd();
        cloneAdGroupAd.ad_group_resource_name = this.ad_group_resource_name;
        cloneAdGroupAd.ad = this.ad.cloneResource();
        return cloneAdGroupAd;
    }
    toJson() {
        return Object.assign(Object.assign({}, this), { ad: this.ad.toJson() });
    }
}
__decorate([
    class_transformer_1.Type(() => Ad)
], AdGroupAd.prototype, "ad", void 0);
exports.AdGroupAd = AdGroupAd;
