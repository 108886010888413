import * as React from 'react';
import { useRef, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FullHeader } from 'pages/header/FullHeader';
import styled from 'styled-components';
import CancelButton from 'buttons/CancelButton';
import SubmitButton from 'buttons/SubmitButton';
import { History } from 'src/route/Route';
import { Location } from 'history';

type LocationState = {
  errCode: number;
} 

const VerifyEmailSuccess: React.FC = (props) => {
  const mounted = useRef(false);
  const history = new History();
  const { state }: Location<LocationState> = useLocation();
  const { errCode } = state;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Styles>
      <FullHeader />
      <div className="container emai-verified-page">
        <div className="text-center emai-verified__heading">
          {
            (errCode !== 310)
              ?
              <>
                <h3>Kích hoạt tài khoản thành công</h3>
                <p>Tài khoản của bạn đã được kích hoạt thành công. Giờ đây bạn có thể sử dụng mọi tính năng của Cokhach.vn.<br />

                <Link to={(globalThis as any).useHistory.generateComponent('PricingPage')}>Nâng cấp tài khoản</Link> để nhận không giới hạn thông tin khách hàng.</p>
              </>
              :
              <>
                <h3>Bạn đã xác thực tài khoản</h3>
                <p>Vào dashboard để bắt đầu<br />

                <Link to={(globalThis as any).useHistory.generateComponent('PricingPage')}>Nâng cấp tài khoản</Link> để nhận không giới hạn thông tin khách hàng.</p>
              </>
          }
        </div>
        <div className="emai-verified__footer">
          <CancelButton onClick={() => { history.push('/'); }}>Vào Dashboard</CancelButton>
          <SubmitButton alignRight onClick={() => { history.push('/create-team'); }}>
            Tạo đội nhóm
          </SubmitButton>
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background: url(${process.env.REACT_APP_TEMPLATE_CDN_URL}/header-background.png) no-repeat;
  background-size: contain;
  .app-header.background-white {
    background-color: transparent;
    box-shadow: none;
  }
  .verify-page {
    margin-top: 34px;
  }

  h2 {
    font-family: Propins-Bold;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;
  }

  p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #000000;
  }

  .emai-verified__heading {
    margin-bottom: 40px;
  }

  .emai-verified__footer {
    margin-bottom: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
  }
`;


export default VerifyEmailSuccess;
