import * as React from 'react';
import { useEffect } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation, useHistory } from 'react-router-dom';
import { LoadingScreen } from 'components/common/LoadingScreen';
import { AuthService, RefreshParams, GetTeamParams, setPreviousPage, resetPreviousPage } from 'core';
import VerifyAccountService from 'pages/auth/service/VerifyAccountService';
import { History } from 'src/route/Route';
import { useCookie, Token, useRedirectDomain } from 'pages/auth/hooks';
import { messageCode } from 'presenter';
import { toast } from 'react-toastify';
import { useKonamiCode } from 'utils/hooks';

interface PrivateRouteProps extends RouteProps {
  Component: React.ComponentType;
  cookies: Cookies;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ Component, ...rest }) => {
  const { cookies } = rest;
  const { getAllCookies } = useCookie(cookies);
  const userToken = getAllCookies(Token.UserToken) as string;
  const masterToken = getAllCookies(Token.MasterUserToken) as string;
  const isNullCookies = (cookie: string) => cookie === undefined || cookie === 'null';
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const inEmailRegisterProcess = useSelector((state) => state.auth.inEmailRegisterProcess);
  const previousPage = useSelector((state) => state.auth.previousPage);
  const location = useLocation();
  const history = new History();
  const service = new AuthService();
  const { redirect, gotoTeam, gotoHome } = useRedirectDomain(cookies);

  useEffect(() => {
    if (!isNullCookies(userToken) && !user) {
      const params: RefreshParams = {
        inputParams: {
          userToken: getAllCookies(Token.UserToken) as string,
          masterUserToken: getAllCookies(Token.MasterUserToken) as string,
          hostname: new URL(window.location.origin).hostname,
        },
      };

      // getTeam callback only call
      // if hostname is diff with process.env.REACT_APP_DOMAIN
      // Check refresh service for more details
      const getTeamParams: GetTeamParams = {
        inputParams: {},
        callBackParams: {
          successCallback: (res: any) => {
            console.log('hostname is diff with process.env.REACT_APP_DOMAIN', res);
            if (!res.selectedTeam.team || !res.selectedTeam.team.id) {
              // gotoTeam(res.selectedTeam.token, res.selectedTeam, {message: 303});
            }
          },
          errorCallback: (err: any) => {
            gotoHome({ message: 303 });
          }
        }
      };

      service.refresh(params, getTeamParams);
    }
  }, []);

  useKonamiCode(() => {
    history.push('/cheatsheet');
  });

  const renderComponent = () => {
    if (isNullCookies(userToken) && location.pathname !== '/login') {
      return (
        <Redirect
          push
          to={{
            pathname: history.url('/login'),
            state: { from: location },
          }}
        />
      );
    }
    if (!user) {
      return <LoadingScreen />;
    }

    const verifyAccountSrv = new VerifyAccountService(user);
    if (verifyAccountSrv.NeedVerifyAccount && location.pathname !== history.url('/verify')) {
      return <Redirect push to={{ pathname: history.url('/verify') }} />;
    }

    if (previousPage) {
      try {

        const url = new URL(previousPage);
        dispatch(resetPreviousPage());
        if (url.hostname !== process.env.REACT_APP_DOMAIN) {
          window.location.replace(previousPage);
        } else {
          history.push(previousPage);
        }

      } catch (err) {
        history.push(previousPage);
      }
    }

    if (location.search) {
      try {
        const query = new URLSearchParams(location.search);
        if (query.get('message')) {
          const messages = query.get('message') || '';
          const allMess = messages.split(',');

          allMess.map((m: string) => {
            const message = messageCode[m || 0];
            if (message) toast[message.type](message.text);
          });
        }

        if (query.get('team-manager') === 'show')
          return (
            <Redirect
              push
              to={{
                pathname: history.url('/'),
                state: {
                  shouldShowManageMember: true,
                }
              }}
            />
          );

      } catch (err) {
        console.error(err);
      }
    }

    if (inEmailRegisterProcess) {
      history.push('/sent-confirm-email-noti');
    }

    return <Component />;
  };

  return <Route {...rest} render={renderComponent} />;
};

export default withCookies(PrivateRoute);
