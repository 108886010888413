"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = void 0;
class Image {
    constructor(imgUrl, base64String, orgImgName, id, categoryCode) {
        const prefixUrl = imgUrl.substr(0, 4);
        this.imgUrl = (prefixUrl === 'http' || prefixUrl === 'blob') ? imgUrl : `${process.env.REACT_APP_CDN_URL}/${imgUrl}`;
        this.base64String = base64String;
        this.id = id;
        this.orgImgName = orgImgName;
        this.categoryCode = categoryCode;
    }
    getShortName() {
        const orgName = this.orgImgName.substring(0, this.orgImgName.lastIndexOf('.'));
        const shortName = orgName.substring(0, 20);
        return this.orgImgName.replace(orgName, shortName);
    }
    getSrcBySize(imgSize) {
        if (this.imgUrl.substring(0, 4) !== 'http')
            return this.imgUrl;
        const imgName = this.imgUrl.substring(this.imgUrl.lastIndexOf('/') + 1);
        return this.imgUrl.replace(imgName, `${imgSize}/${imgName}`);
    }
}
exports.Image = Image;
