import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import SubmitButton from 'buttons/SubmitButton';
import { connect, useSelector } from 'react-redux';
import { FullHeader } from 'pages/header/FullHeader';
import styled from 'styled-components';
import { PackageInfo, Team, PaymentService, Method, MethodId, BankTransferInfo, PaymentType, LicenseInfor, ErrorMessageService, GetPricingInfoParams, TeamPermissionService } from 'core';
import { useLocation } from 'react-router-dom';
import { Location, createBrowserHistory } from 'history';
import { History } from 'src/route/Route';

interface PaymentMethodProps {

}

type LocationState =
  | {
      selectedPackage?: PackageInfo;
      paymentType?: PaymentType;
      selectNumberMembers?: number;
      selectedTeam?: Team;
      activedLicense?: LicenseInfor;
    }
  | null
  | undefined;

const PaymentMethod = (props: PaymentMethodProps) => {
  const [paymentMethodProcess, setPaymentMethodProcess] = useState(true);
  const { state }: Location<LocationState> = useLocation();
  const selectedPackage = state && state.selectedPackage;
  const paymentType = state && state.paymentType;
  const selectNumberMembers = state && state.selectNumberMembers;
  const selectedTeam = state && state.selectedTeam;
  const activedLicense = state && state.activedLicense;
  const history = new History();
  history.hook();

  const paymentMethods: Method[] = useSelector((state: any) => state.payment.methods);
  const bankTransferInfo: BankTransferInfo = useSelector((state: any) => state.payment.bankTransferInfo);

  const [methodId, setMethodId] = useState(MethodId.Bank_Transfer);
  const [pricingInfo, setPricingInfo] = useState<any>();

  const paymentService = new PaymentService();
  useEffect(() => {
    const params = {
      inputParams: {},
      callBackParams: {
        successCallback: (res: any) => {
        },
        errorCallback: (err: any) => {
          console.log('err', err);
        }
      }
    };
    paymentService.fetchMethodsList(params);
  }, []);

  useEffect(() => {
    if (selectedPackage) {
      const params: GetPricingInfoParams = {
        inputParams: {
          payment_type: paymentType,
          team_id: selectedTeam ? selectedTeam.id : null,
          package_id: selectedPackage.id,
        },
        callBackParams: {
          successCallback: (res: any) => {
            setPricingInfo(res.data.pricing_info);
          },
          errorCallback: (err: any) => {
            toast.error(ErrorMessageService.getErrorMessageByCode(err.code));
          }
        }
      };
      paymentService.getPricingInfo(params);
    }
  }, []);

  useEffect(() => {
    const createHistory = createBrowserHistory();
    if (selectedPackage || paymentType || selectNumberMembers || selectedTeam) {
      const state = createHistory.location.state as LocationState;
      if (state) {
        delete state.selectedPackage;
        delete state.paymentType;
        delete state.selectNumberMembers;
        delete state.selectedTeam;
        delete state.activedLicense;
        createHistory.replace({ ...createHistory.location, state });
      } 
    } else {
      history.push('/payment/pricing-page');
    }
  }, []);

  const goBackHistory = () => {
    history.push('/payment/pricing-page');
  };

  const handleChangeMethod = (event: any) => {
    setMethodId(parseInt(event.target.value));
  };

  const createShoppingCart = () => {
    const params = {
      inputParams: {
        team_id: selectedTeam ? selectedTeam.id : null,
        package_id: selectedPackage ? selectedPackage.id : 0,
        payment_type: paymentType,
        payment_method: methodId,
      },
      callBackParams: {
        successCallback: (res: any) => {
          setPaymentMethodProcess(false);
        },
        errorCallback: (err: any) => {
          toast.error(ErrorMessageService.getErrorMessageByCode(err.code));
        }
      }
    };

    paymentService.createShoppingCart(params);
  };

  const renderUpgradeInfor = () => {
    const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
    const oldNumberMembers = teamPermissionService.MaxTeamSize; 
    return selectedPackage && pricingInfo && activedLicense ? (
      <>
        <div className="package-info d-flex align-items-center">
          <h3>{selectedPackage.name} {selectNumberMembers} người</h3>
          <span>{numeral(pricingInfo.total_amount).format('0,0')} ₫</span>
        </div>
        <div className="package-info d-flex align-items-center">
          <h3>{activedLicense.package.name} {oldNumberMembers} người (Giá trị còn lại)</h3>
          <span>{numeral(pricingInfo.remain_amount).format('0,0')} ₫</span>
        </div>
        <div className="package-info d-flex align-items-center">
          <h3>Số tiền cần thanh toán</h3>
          <span>{numeral(pricingInfo.payable_amount).format('0,0')} ₫</span>
        </div>
        <div className="package-description">
          {selectedPackage.permission_descs.map((des: string) => (
            <div key={des}>• {des}</div>
          ))}
        </div>
      </>
    ) : null;
  };

  const renderDowngradeInfor = () => {
    return selectedPackage ? (
      <>
        <div className="package-info d-flex align-items-center">
          <h3>{selectedPackage.name} {selectNumberMembers} người (Thanh toán trước)</h3>
          <span>{numeral(selectedPackage.amount).format('0,0')} ₫</span>
        </div>
        <div className="package-description">
          {selectedPackage.permission_descs.map((des: string) => (
            <div key={des}>• {des}</div>
          ))}
        </div>
      </>
    ) : null;
  };

  const renderCreateNewInfor = () => {
    return selectedPackage ? (
      <>
        <div className="package-info d-flex align-items-center">
          <h3>{selectedPackage.name} {selectNumberMembers} người</h3>
          <span>{numeral(selectedPackage.amount).format('0,0')} ₫</span>
        </div>
        <div className="package-description">
          {selectedPackage.permission_descs.map((des: string) => (
            <div key={des}>• {des}</div>
          ))}
        </div>
      </>
    ) : null;
  };

  const renderPackageInforStep = () => {
    return selectedPackage ? (
      <div className="">
        <h1 className="text-left">Thông tin thanh toán</h1>
        {
          paymentType == PaymentType.Create_New ? renderCreateNewInfor() :
          paymentType == PaymentType.Upgrade ? renderUpgradeInfor() :
          paymentType == PaymentType.Downgrade ? renderDowngradeInfor() : null
        }
        <h1 className="text-left">Phương thức thanh toán</h1>
        <div>
          {paymentMethods && paymentMethods.map((pm: Method) => (
            <div className="payment-method d-flex align-items-center" key={pm.id}>
              <input type="radio" name="payment_method" onChange={handleChangeMethod} checked={methodId == pm.id}/>
              <img src={pm.icon} width="24" height="24" alt={pm.title} />
              <div className="payment-method__description">
                <span>{pm.title}</span>
                <p>{pm.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="button-group justify-content-start">
          <SubmitButton
            onClick={createShoppingCart}
          >
            Tiếp tục
          </SubmitButton>
        </div>
      </div>
    ) : null;
  };

  const renderBankTransferInfoStep = () => {
    return bankTransferInfo ? (
      <div className="bank-transfer-step">
        <h1 className="text-left">Thông tin chuyển khoản</h1>
        <div className="transfer-description d-flex align-items-center">
          <label>Để hoàn tất thanh toán, vui lòng chuyển khoản theo thông tin dưới đây:</label>
        </div>
        <div>
          <h1 className="text-left"><span>1</span> Lưu ý trước khi chuyển khoản</h1>
        </div>
        <div className="transfer-note">
          <ul>
            <li>Bạn có thể chuyển khoản qua Internet Banking hoặc chuyển tiền mặt tại quầy giao dịch.</li>
            <li>Phí chuyển khoản sẽ do người chuyển thanh toán.</li>
          </ul>
        </div>
        <div className="d-flex">
          <h1 className="text-left w-100"><span>2</span>Thông tin chuyển khoản</h1>
        </div>
        <div className="transfer-info">
          <div className="row position-relative">
            <div className="col-lg-4">Số tài khoản</div>
            <div className="col-lg-8 highlight-text">{bankTransferInfo.account_number}</div>
            <CopyToClipboard
              text={bankTransferInfo.account_number}
              onCopy={() => {
                toast.success('Sao chép số tài khoản thành công.', {position: 'top-center'});
              }}
            >
              <span className="copy-button">Sao chép</span>
            </CopyToClipboard>
          </div>
          <div className="row">
            <div className="col-lg-4">Chủ tài khoản:</div>
            <div className="col-lg-8 highlight-text">{bankTransferInfo.account_name}</div>
          </div>
          <div className="row">
            <div className="col-lg-4">Ngân hàng:</div>
            <div className="col-lg-8 highlight-text">{bankTransferInfo.bank_name}</div>
          </div>
          <div className="row">
            <div className="col-lg-4">Chi nhánh:</div>
            <div className="col-lg-8 highlight-text">{bankTransferInfo.branch_name}</div>
          </div>
          <div className="row">
            <div className="col-lg-4">Số tiền:</div>
            <div className="col-lg-8 highlight-text">{numeral(bankTransferInfo.amount).format('0,0')} ₫</div>
          </div>
          <div className="row position-relative">
            <div className="col-lg-4">Nội dung:</div>
            <div className="col-lg-8 blue-text">{bankTransferInfo.content}</div>
            <CopyToClipboard
              text={bankTransferInfo.content}
              onCopy={() => {
                toast.success('Sao chép nội dung chuyển khoản thành công.', {position: 'top-center'});
              }}
            >
              <span className="copy-button">Sao chép</span>
            </CopyToClipboard>
          </div>
          <div className="warning-text">
            <span>LƯU Ý!</span> Bạn phải chuyển khoản với nội dung trên.
          </div>
        </div>
        <div className="support-info">
          Để được hỗ trợ nhanh hơn về các vấn đề thanh toán, vui lòng liên hệ hotline <span>0904.220.612</span> CSKH của chúng tôi.
        </div>
      </div>
    ) : null;
  };

  return selectedPackage ? (
    <Styles>
      <FullHeader />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <span onClick={goBackHistory} className="closeButton">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"> <g fill="none" fillRule="evenodd"> <g> <g transform="translate(-155 -68) translate(155 68)"> <circle cx="14" cy="14" r="14" fill="#000" fillOpacity=".08"/> <g> <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01" transform="rotate(90 8 14)"/> <path fill="#000" fillRule="nonzero" d="M7.807 11.824c-.24 0-.438-.095-.63-.287L1.983 6.22c-.15-.15-.239-.342-.239-.568 0-.45.356-.813.814-.813.225 0 .423.096.58.246l4.676 4.792 4.663-4.792c.164-.157.369-.246.587-.246.452 0 .807.362.807.813 0 .226-.075.417-.232.568l-5.196 5.318c-.177.192-.39.28-.635.287z" transform="rotate(90 8 14)"/> </g> </g> </g> </g> </svg>
            </span>
            {paymentMethodProcess ? renderPackageInforStep() : renderBankTransferInfoStep()}
          </div>
        </div>
      </div>
    </Styles>
  ) : null;
};

const mapStateToProps = (state: any) => {
  return {
  };
};

const Styles = styled.div`
  background-color: #f5f4f9;
  min-height: 100vh;
  .page-heading p {
    margin-bottom: 40px;
  }
  .closeButton {
    cusor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 20px;
    left: -50px;
  }
  h1 {
    color: #000000;
    font-family: Propins-Medium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    border-bottom: solid 1px #e2e2e2;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .bank-transfer-step h1 {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
  h1 span {
    width: 18px;
    height: 18px;
    display: inline-block;
    font-family: Propins-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    text-align: center;
    color: #ffffff;
    padding: 0 3px;
    background-color: #f450a5;
    border-radius: 50%;
    margin-right: 10px;
  }
  .transfer-description {
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #000000;
  }
  .select-team-info {
    border-bottom: 1px dashed #e2e2e2;
  }
  .select-team-info label {
    font-family: Propins-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.45px;
    color: #000000;
    margin-bottom: 10px;
  }
  .select-team-info select {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    border-color: #d8d8d8;
    height: 44px;
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.23px;
    color: #aeaeb2;
  }
  .package-info {
    font-family: Propins-Regular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.45px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .package-info h3 {
    font-size: 18px;
    flex: 1;
  }
  .package-info span {
    font-family: Propins-Medium;
  }
  .transfer-note {
    font-family: Propins-Regular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    color: #000000;
  }
  .transfer-note ul { 
    padding-left: 15px;
    margin-bottom: 0;
  }
  .transfer-note, .transfer-info {
    position: relative;
    padding: 10px 15px;
    border-radius: 12px;
    border: solid 1px #d8d8d8;
  }
  .transfer-info .row {
    margin-bottom: 12px;
  }
  .payment-method img {
    margin-left: 15px;
    margin-right: 10px;
  }
  .payment-method__description p {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #666666;
    margin-bottom: 0;
  }
  .payment-method__description span {
    font-family: Propins-Medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    color: #000000;
  }
  .package-description {
    font-family: Propins-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.35px;
    color: #666666;
  }
  .transfer-info {
    padding-bottom: 46px;
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    color: #666666;
  }
  .warning-text {
    font-family: Propins-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    color: #000000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 15px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #ffcc00;
  }
  .warning-text:before {
    position: absolute;
    top: -13px;
    left: 42%;
    content: '';
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #ffcc00;
  }
  .warning-text span {
    font-family: Propins-Medium;
  }
  .highlight-text {
    font-family: Propins-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #000000;
  }
  .blue-text {
    font-family: Propins-Medium;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #4c7cf3;
  }
  .support-info {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #d8d8d8;
    font-family: Propins-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    color: #000000;
    margin-bottom: 15px;
  }
  .support-info span {
    font-family: Propins-Medium;
  }
  .copy-button {
    position: absolute;
    right: 15px;
    top: 0px;
    background-color: transparent;
    font-family: Propins-Medium;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: right;
    border: 0;
    color: #4c7cf3;
    cursor: pointer;
  }
`;

const PaymentMethodPage = connect(mapStateToProps)(PaymentMethod);
export default PaymentMethodPage;
