import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AccountType, FacebookService, GoogleAdsEnum } from 'core';
import * as ReactModal from 'react-modal';

interface ModalProps {
  customStyles?: object;
  isOpen: boolean;
  closeModal: () => void;
}
ReactModal.setAppElement('#root');

export const ConnectAccountFacebookModal: React.FC<ModalProps> = (props) => {
  const { Connected, Disconnected } = GoogleAdsEnum.ConnectedAccountStatus;
  const fbAdsIds = useSelector((state) => state.adsCampaign.fbAdsIds);
  const fbAccessToken = useSelector((state) => state.adsCampaign.fbAccessToken);
  const fbFanpages = useSelector((state) => state.adsCampaign.fbFanpages);
  const [fbAdsIdListState, setFbAdsIdListState] = React.useState([] as any[]);
  const [fanpageListState, setFanpageListState] = React.useState([] as any[]);

  const mounted = useRef(false);

  const service = new FacebookService();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const closeModal = () => {
    props.closeModal();
  };

  const handleOnchangeFbAdsId = (e: any) => {
    const fbAdsId = {
      provider_account_id: e.target.value,
      name: e.target.name,
      account_type: AccountType.FacebookAds,
      access_token: fbAccessToken,
      status: e.target.checked ? Connected : Disconnected,
    };
    const newFbAdsIdList = [ ...fbAdsIdListState.filter((el: any) => el.provider_account_id != fbAdsId.provider_account_id), fbAdsId];

    setFbAdsIdListState(newFbAdsIdList);
  };

  const handleOnchangeFanpage = (e: any) => {
    const fanpage = {
      id: e.target.value,
      name: e.target.name,
      status: e.target.checked ? Connected : Disconnected,
    };
    const newFanpageList = [ ...fanpageListState.filter((el: any) => el.id != fanpage.id), fanpage];

    setFanpageListState(newFanpageList);
  };

  const handleConnecting = () => {
    const params = {
      inputParams: {
        accounts: fbAdsIdListState,
        fanpages: fanpageListState,
      },
      callBackParams: {
        successCallback: (res: any) => {
        },
      }
    };

    service.connectFbAccount(params);
    closeModal();
  };

  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={closeModal} style={props.customStyles}>
      <div className="connect-account-modal">
        <div className="modal__heading">
          <h3>Kết nối tài khoản Facebook</h3>
          <div className="modal-btn-close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g fill="none" fillRule="evenodd"> <g fill="#000" fillRule="nonzero"> <g> <g> <g> <path d="M10.795 10.795c.267-.279.273-.731 0-.998l-3.793-3.8 3.793-3.794c.273-.266.273-.725 0-.998-.279-.266-.731-.272-.998-.006L5.997 5 2.203 1.2c-.26-.26-.731-.273-.998.006-.272.273-.266.738-.006.998L5 5.997l-3.8 3.8c-.26.26-.273.726.006.998.273.273.738.267.998.007l3.794-3.8 3.8 3.8c.267.266.726.266.998-.007z" transform="translate(-890 -65) translate(355 47) translate(527 10) translate(8 8)"/> </g> </g> </g> </g> </g> </svg>
          </div>
        </div>
        <div className="modal__content">
          <div>Lựa chọn tài khoản Facebook để kết nối. Bạn có thể liên kết tối đa 2 tài khoản quảng cáo.</div>
          <div>Facebook Ads ID</div>
          <div className="form-check">
            {fbAdsIds && fbAdsIds.map((el: any) => (
              <div className="d-flex align-items-center" key={el.account_id}>
                <input
                  className="form-check-input"
                  id="select-account"
                  type="checkbox"
                  name={el.name}
                  value={el.account_id}
                  onChange={handleOnchangeFbAdsId}
                  checked={el.status === Connected}
                />
                <label className="radio-label ml-2 mr-2" htmlFor="select-account">{el.name} ({el.account_id})</label>
              </div>
            ))}
          </div>
          <div>Fanpage</div>
          <div className="form-check">
            {fbFanpages && fbFanpages.map((el: any) => (
              <div className="d-flex align-items-center" key={el.id}>
                <input
                  className="form-check-input"
                  id="select-account"
                  type="checkbox"
                  name={el.name}
                  value={el.id}
                  onChange={handleOnchangeFanpage}
                  checked={el.status === Connected}
                />
                <label className="radio-label ml-2 mr-2" htmlFor="select-account">{el.name}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="modal__bottom">
          <button onClick={handleConnecting} className="btn-default ml-3">Kết nối</button>
        </div>
      </div>
    </ReactModal>
  );
};

ConnectAccountFacebookModal.defaultProps = {
  customStyles: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: '9'
    },
    content: {
      position: 'relative',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: '0',
      padding: '0',
      borderRadius: '12px',
      boxShadow: '0 12px 64px 0 rgba(0, 0, 0, 0.3)',
      transform: 'translate(-50%, -50%)'
    }
  }
};

