import * as React from 'react';
import { Link } from 'react-router-dom';
import 'stylesheets/App.css';
import LandingPagesTable from './LandingPagesTable';
import { EmptyPage } from './LandingPagesEmpty';
import { LandingPage } from 'core';

interface PropsType {
  landingPages: Array<LandingPage>;
}

const Content: React.FC<PropsType> = (props) => {
  return (
    <div className={props.landingPages && props.landingPages.length > 0 ? 'main-content' : 'main-content fix-height'}>
      <div className="page-heading">
        <h1 className="page-title">Quản lý landing page</h1>
        <Link className="btn btn-default" to={(globalThis as any).useHistory.getPath('/choose-project')} style={{alignItems: 'center'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="#FFF" fillRule="evenodd">
                <path d="M0 0H20V20H0z" opacity=".01"/>
                <path d="M10.952 9.048h4.79c.208 0 .368.038.502.11.135.072.24.178.312.312.073.134.11.294.11.503v.054c0 .209-.037.369-.11.503-.072.135-.178.24-.312.312-.134.072-.294.11-.502.11h-4.79v4.79c0 .208-.038.368-.11.502-.072.135-.178.24-.312.312-.134.073-.294.11-.503.11h-.054c-.209 0-.369-.037-.503-.11-.133-.07-.242-.179-.312-.312-.072-.134-.11-.294-.11-.502v-4.79h-4.79c-.208 0-.368-.038-.502-.11-.135-.072-.24-.178-.312-.312-.073-.134-.11-.295-.11-.503v-.054c0-.209.037-.369.11-.503.072-.135.178-.24.312-.312.134-.072.295-.11.502-.11h4.79v-4.79c0-.208.038-.368.11-.502.072-.135.178-.24.312-.312.134-.073.294-.11.503-.11h.054c.209 0 .369.037.503.11.135.072.24.178.312.312.072.134.11.294.11.502v4.79z"/>
            </g>
          </svg>
          Tạo landing page mới
        </Link>
      </div>
      {props.landingPages && props.landingPages.length > 0 ? (
        <LandingPagesTable landingPages={props.landingPages || []}/>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};

export { Content };
