import { LandingPageWithGoogleAdsCampaign, ProjectState, userPublishedLandingPagesState,
  AdsCampaignState, ICampaignData, TeamPermissionService, 
} from 'core';
import { 
  useLoggingUpdate, 
  // useFetchUserLandingPages, 
  useFetchUserPublishedGoogleUrls, 
  useRouter, useGoogleForm } from 'utils/hooks';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import InitialGoogleCampaign from './InitialGoogleCampaign';
import { LoadingScreen } from 'components/common';
import WarningLimitedFeatureModal from 'pages/menu/modals/WarningLimitedFeatureModal';

interface ConnectGoogleAdsProps {
  projects: ProjectState;
  adsCampaign: AdsCampaignState;
}

const ConnectGoogleAds = (props: ConnectGoogleAdsProps) => {
  const [loading, setLoading] = useState(false);
  const landingPages: Array<LandingPageWithGoogleAdsCampaign> | undefined = props.adsCampaign.currentLandingPage?.landing_pages;
  const currentCampaign: ICampaignData | null = props.adsCampaign.currentCampaign;
  const router = useRouter();
  const history = (globalThis as any).useHistory;
  history.hook();
  const {getCampaign} = useGoogleForm();
  const [isOpenNeedToUpgradeModalState, setIsOpenNeedToUpgradeModalState] = useState(false);

  useLoggingUpdate('googleAdsAccount', props);
  useFetchUserPublishedGoogleUrls();
  const teamPermissionService = TeamPermissionService.getCurrentTeamPermission();
  const isEditMode = !!router.query['resource_name'];
  const handleClose = () => {
    setIsOpenNeedToUpgradeModalState(false);
    history.push('/google-ads-dashboard');
  };

  React.useEffect(() => {
    if (teamPermissionService.CanUseAdCreation === false) {
      setIsOpenNeedToUpgradeModalState(true);
    } else {
      setIsOpenNeedToUpgradeModalState(false);
    }
  }, [teamPermissionService.CanUseAdCreation]);

  React.useEffect(() => {
    if(router.query['resource_name']){
      setLoading(true);
      const params = {
        inputParams: {
          resourceName: router.query['resource_name']
        },
        callBackParams: {
          successCallback: (res: any) => {
            setLoading(false);
          },
          errorCallback: (res: any) => {
            setLoading(false);
          }
        }
      };
      getCampaign(params);
    }
  }, [router.query]);

  if(loading) return <LoadingScreen />;

  return (
    <>
      <WarningLimitedFeatureModal 
        isOpen={isOpenNeedToUpgradeModalState} 
        closeModal={handleClose} 
      />
      <InitialGoogleCampaign
        isEditMode={isEditMode}
        currentCampaign={currentCampaign}
        landingPages={landingPages}
      />
    </>

  );
};

export interface InitialGoogleCampaignProps {
  isEditMode: boolean;
  landingPages: Array<LandingPageWithGoogleAdsCampaign> | undefined;
  currentCampaign: ICampaignData | null;
}

const mapStateToProps = (state: any) => {
  const { projects, adsCampaign } = state;
  return {
    adsCampaign,
    projects,
  };
};

const ConnectGoogleAdsPage = connect(mapStateToProps)(ConnectGoogleAds);
export default ConnectGoogleAdsPage;