"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformCustomerExportData = void 0;
const getHeaderExcel = (includedLP) => {
    const headerExcel = [
        'NGÀY TẠO',
        'NGÀY CẬP NHẬT',
        'Tên KH',
        'SĐT',
        'Email',
        'KHU VỰC',
        'TÀI CHÍNH',
        'NHU CẦU',
        'NGUỒN KH',
    ];
    if (includedLP) {
        headerExcel.unshift('Landing Page');
    }
    headerExcel.unshift('STT');
    return headerExcel;
};
const transformCustomerExportData = (customers, selectedLP) => {
    //@TODO need to be refactor any type here
    const groupDataByProjects = {};
    customers.forEach(({ pid, pname, created_at, updated_at, full_name, phone_no, email, location, budget, preference, ad_source, }, index) => {
        const rowData = [
            created_at,
            updated_at,
            full_name,
            phone_no,
            email,
            location,
            budget,
            preference,
            ad_source,
        ];
        let includedLp = false;
        let lp_name = '';
        if (selectedLP) {
            includedLp = true;
            lp_name = selectedLP.lp_name;
            rowData.unshift(lp_name);
        }
        if (!groupDataByProjects[pid]) {
            groupDataByProjects[pid] = {
                id: pid,
                name: pname,
                customers: [getHeaderExcel(includedLp)],
                maxWidths: {
                    stt: 5,
                    lp_name: lp_name.length,
                    created_at: created_at && created_at.length > 12 ? created_at.length : 12,
                    full_name: full_name && full_name.length > 12 ? full_name.length : 12,
                    phone_no: phone_no && phone_no.length > 12 ? phone_no.length : 12,
                    email: email && email.length > 12 ? email.length : 12,
                    location: location && location.length > 12 ? location.length : 12,
                    budget: budget && budget.length > 12 ? budget.length : 12,
                    preference: preference && preference.length > 12 ? preference.length : 12,
                    ad_source: ad_source && ad_source.length > 12 ? ad_source.length : 12,
                },
            };
        }
        if (full_name &&
            full_name.length > groupDataByProjects[pid]['maxWidths']['full_name']) {
            groupDataByProjects[pid]['maxWidths']['full_name'] = full_name.length; // increase more 5 for cell's right-margin
        }
        if (phone_no &&
            phone_no.length > groupDataByProjects[pid]['maxWidths']['phone_no']) {
            groupDataByProjects[pid]['maxWidths']['phone_no'] = phone_no.length;
        }
        if (email &&
            email.length > groupDataByProjects[pid]['maxWidths']['email']) {
            groupDataByProjects[pid]['maxWidths']['email'] = email.length;
        }
        if (location &&
            location.length > groupDataByProjects[pid]['maxWidths']['location']) {
            groupDataByProjects[pid]['maxWidths']['location'] = location.length;
        }
        if (budget &&
            budget.length > groupDataByProjects[pid]['maxWidths']['budget']) {
            groupDataByProjects[pid]['maxWidths']['budget'] = budget.length;
        }
        if (preference &&
            preference.length > groupDataByProjects[pid]['maxWidths']['preference']) {
            groupDataByProjects[pid]['maxWidths']['preference'] = preference.length;
        }
        if (ad_source &&
            ad_source.length > groupDataByProjects[pid]['maxWidths']['ad_source']) {
            groupDataByProjects[pid]['maxWidths']['ad_source'] = ad_source.length;
        }
        const stt = groupDataByProjects[pid]['customers'].length;
        rowData.unshift(stt.toString()); // align left STT
        groupDataByProjects[pid]['customers'].push(rowData);
    });
    return groupDataByProjects;
};
exports.transformCustomerExportData = transformCustomerExportData;
