"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userPublishedUrlsReducer = void 0;
const types_1 = require("../../auth/types");
const types_2 = require("../types");
const action_namespace_1 = require("../../team/types/action-namespace");
const initialState = {
    data: []
};
const userPublishedUrlsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types_2.GET_USER_PUBLISHED_LANDING_PAGES_SUCCESS:
            return Object.assign({}, state, {
                data: action.data.landing_pages
            });
        case action_namespace_1.GoToTeamAction.success:
        case types_1.LOGOUT:
            return initialState;
        default:
            return state;
    }
};
exports.userPublishedUrlsReducer = userPublishedUrlsReducer;
