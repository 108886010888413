"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleService = void 0;
const types_1 = require("../types");
const ApiAction_1 = require("../../actions/ApiAction");
const frameworks_1 = require("../../frameworks");
const GoogleAds_1 = require("../../GoogleAds");
class GoogleService {
    constructor() {
        const state = frameworks_1.store.getState();
        const adsCampaign = state['adsCampaign'];
        const token = state['adsCampaign'].access_token;
        this._adsCampaign = adsCampaign;
        this._token = token;
    }
    // //Please get all connected account to current user from table connected_accounts
    // getAllConnectedAccount(params: GetAllConnectedGAccountParams){
    //   // get /connected_account
    //   const type = GetAllConnectedGAccount.type;
    //   const apiAction = new ApiAction(type, FuncType.Async, params);
    //   apiAction.dispatchAction();
    // }
    // //add a connected_accounts to user
    // addConnectedAccountToUser(params: AddConnectedAccountToUserParams){
    //   // post /connected_account
    //   Object.assign(params, {}, {
    //     inputParams: {
    //       access_token: this._token
    //     }
    //   });
    //   const type = AddConnectedAccountToUser.type;
    //   const apiAction = new ApiAction(type, FuncType.Async, params);
    //   apiAction.dispatchAction();
    // }
    //generate keyword
    getGKeywordEstimation(params) {
        // get /google_ads/keyword_estimate
        const type = types_1.GetGKeywordEstimation.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    //get default keywords
    getDefaultKeywords(params) {
        // get /google_ads/default_keywords
        const type = types_1.GetListKeywordSuggestion.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    //------------
    // List Google ads campaign
    //------------
    //get All Campaign
    // getAllGoogleCampaign(params: GetAllGoogleCampaignParams){
    //   // post /google_ads/campaigns
    //   const type = GetAllGoogleCampaign.type;
    //   const apiAction = new ApiAction(type, FuncType.Async, params);
    //   apiAction.dispatchAction();
    // }
    //Update campaign status
    updateGoogleCampaignStatus(params) {
        // post /google_ads/campaign_status
        const type = types_1.UpdateGoogleCampaignStatus.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    //Get detail google ads campaign 
    getDetailGoogleCampaign(params) {
        // get /google_ads/campaign
        const type = types_1.GetDetailGoogleCampaign.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    //Create a campaign: post /google_ads/campaign
    createGoogleCampaign(params) {
        var _a;
        const state = frameworks_1.store.getState();
        if (state.adsCampaign.needFetchCurrentCampaign) {
            if ((_a = params.callBackParams) === null || _a === void 0 ? void 0 : _a.errorCallback) {
                const error = {
                    message: 'Bạn cần lấy lại dữ liệu mới nhất của chiến dịch quảng cáo từ Google về trước khi lưu dữ liệu mới'
                };
                params.callBackParams.errorCallback(error);
                return;
            }
        }
        const oldData = state.adsCampaign.currentCampaign;
        const newData = params.inputParams;
        const service = new GoogleAds_1.CampaignDataInfoService();
        const postData = service.prepareOperationData(oldData, newData, state.adsCampaign.hasChangedAtInitStep);
        const type = types_1.CreateGoogleCampaign.type;
        const createParam = {
            inputParams: postData,
            callBackParams: {
                successCallback: (res) => {
                    var _a, _b;
                    const getCampaignParam = {
                        inputParams: {
                            resourceName: res.resource_name
                        }
                    };
                    this.getDetailGoogleCampaign(getCampaignParam);
                    if ((_a = params.callBackParams) === null || _a === void 0 ? void 0 : _a.successCallback) {
                        (_b = params.callBackParams) === null || _b === void 0 ? void 0 : _b.successCallback(res);
                    }
                },
                errorCallback: (err) => {
                    var _a, _b;
                    if ((_a = params.callBackParams) === null || _a === void 0 ? void 0 : _a.errorCallback) {
                        (_b = params.callBackParams) === null || _b === void 0 ? void 0 : _b.errorCallback(err);
                    }
                }
            }
        };
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, createParam);
        apiAction.dispatchAction();
    }
    //save current work
    saveDraftGoogleCampaign(params) {
        // post /google_ads/campaign
        const type = types_1.SaveDraftGoogleCampaign.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        apiAction.dispatchAction();
    }
    initialCampaign(params) {
        const type = types_1.InitialCampaign.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Sync, params);
        apiAction.dispatchAction();
    }
    //AdGroups
    //Get list of AdGroups which belongs to a campaign
    getAdGroupsBelongToCampaign(params) {
        // get /google_ads/adgroups
        const type = types_1.GetAdGroupsBelongToCampaign.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    // AdGroupAd Resource
    // Get list AdGroupAd by list of AdGroup
    getListAdGroupAdByCampaign(params) {
        // get /adgroupad
        const type = types_1.GetListAdGroupByCampaign.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getListAdFlow(adParams, adGroupAdParams) {
        const params = {
            inputParams: adParams.inputParams,
            callBackParams: {
                successCallback: (res) => {
                    const adIds = res;
                    const adgroup_ids = adIds.AdGroups.keyword_groups.map((el, id) => el.resource_name);
                    const newParams = {
                        inputParams: {
                            adGroupResourceNames: adgroup_ids
                        },
                        callBackParams: adGroupAdParams.callBackParams
                    };
                    this.getListAdGroupAdByCampaign(newParams);
                }
            }
        };
        this.getAdGroupsBelongToCampaign(params);
    }
    getAdGroupFlow(adParams, defaultKeywordParams) {
        const params = {
            inputParams: adParams.inputParams,
            callBackParams: {
                successCallback: (res) => {
                    var _a;
                    const adIds = res;
                    if (adIds.AdGroups.keyword_groups.length == 0) {
                        const newParams = {
                            inputParams: {
                                landing_page_id: (_a = adParams.inputParams) === null || _a === void 0 ? void 0 : _a.landing_page_id,
                                campaign_resource_name: adParams.inputParams.campaignResourceName,
                            },
                            callBackParams: {
                                successCallback: (successAdGroupAd) => {
                                    defaultKeywordParams.callBackParams
                                        && defaultKeywordParams.callBackParams.successCallback
                                        && defaultKeywordParams.callBackParams.successCallback({
                                            AdGroups: successAdGroupAd
                                        });
                                },
                                errorCallback: (errorAdGroupAd) => {
                                    defaultKeywordParams.callBackParams
                                        && defaultKeywordParams.callBackParams.errorCallback
                                        && defaultKeywordParams.callBackParams.errorCallback(res);
                                }
                            }
                        };
                        this.getDefaultKeywords(newParams);
                    }
                    else {
                        adParams.callBackParams
                            && adParams.callBackParams.successCallback
                            && adParams.callBackParams.successCallback(adIds);
                    }
                },
                errorCallback: (res) => {
                    adParams.callBackParams
                        && adParams.callBackParams.errorCallback
                        && adParams.callBackParams.errorCallback(res);
                }
            }
        };
        this.getAdGroupsBelongToCampaign(params);
    }
    // Create / Update AdGroupAd in Campaign
    createOrUpdateAdGroupAd(params) {
        var _a, _b;
        // post /adgroupad
        const type = types_1.CreateOrUpdateAdGroupAd.type;
        const service = new GoogleAds_1.AdGroupAdDataInfoService();
        const newParams = {
            inputParams: {
                AdGroupAds: service.setOperation(params.inputParams.AdGroupAds)
            }
        };
        const state = frameworks_1.store.getState();
        const campaignResourceName = (_a = state.adsCampaign.currentCampaign) === null || _a === void 0 ? void 0 : _a.resource_name;
        const keywordData = service.removeAdGroup(newParams.inputParams.AdGroupAds);
        if (keywordData && campaignResourceName && service.needRemoveAdGroup(keywordData)) {
            // After update AdGroupAd, we also remove AdGroup which are not having any AdGroupAd --> It is requirements from production team
            newParams.callBackParams = {
                successCallback: (res) => {
                    const removeAdGroupParam = {
                        inputParams: {
                            campaign_resource_name: campaignResourceName,
                            keywordData
                        },
                        callBackParams: {
                            successCallback: (keywordData) => {
                                var _a;
                                if ((_a = params.callBackParams) === null || _a === void 0 ? void 0 : _a.successCallback) {
                                    params.callBackParams.successCallback(res);
                                }
                            },
                            errorCallback: (err) => {
                                var _a;
                                if ((_a = params.callBackParams) === null || _a === void 0 ? void 0 : _a.errorCallback) {
                                    params.callBackParams.errorCallback(err);
                                }
                            }
                        }
                    };
                    this.createOrUpdateAdGroup(removeAdGroupParam);
                },
                errorCallback: (_b = params.callBackParams) === null || _b === void 0 ? void 0 : _b.errorCallback
            };
        }
        else {
            newParams.callBackParams = params.callBackParams;
        }
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, newParams);
        apiAction.dispatchAction();
    }
    // Create / Update AdGroupAd in Campaign
    createOrUpdateAdGroup(params) {
        try {
            const service = new GoogleAds_1.KeywordDataInfoService();
            service.setOperation(params.inputParams.keywordData);
            if (!params.inputParams.keywordData.keyword_groups.some(x => { return x.operation; }) && !params.inputParams.keywordData.negative_keywords.some(x => { return x.operation; })) {
                // No need to call google's api
                if (params.callBackParams && params.callBackParams.successCallback) {
                    params.callBackParams.successCallback(params.inputParams.keywordData);
                }
                return;
            }
        }
        catch (err) {
            console.log(err);
            throw err;
        }
        // post /adgroup
        const apiAction = new ApiAction_1.ApiAction(types_1.CreateOrUpdateAdGroup.type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    fetchGGAdsCustomer(params) {
        const type = types_1.FetchGGAdsCustomer.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    connectAccount(params) {
        const type = types_1.ConnectAccount.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    disconnectAccount(params) {
        const type = types_1.DisconnectAdsAccount.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    fetchConnectedAccount(params) {
        const type = types_1.FetchConnectedAccount.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getLocationAds(params) {
        // get /google_ads/locations
        const type = types_1.GetLocationAds.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    getLPWithGoogleAdsCampaign(params) {
        // get /google_ads/locations
        const type = types_1.GetLPWithGoogleAdsCampaign.type;
        const apiAction = new ApiAction_1.ApiAction(type, ApiAction_1.FuncType.Async, params);
        apiAction.dispatchAction();
    }
    startCreatingNewCampaign() {
        const apiAction = new ApiAction_1.ApiAction(types_1.StartCreatingCampaign.type, ApiAction_1.FuncType.Sync);
        apiAction.dispatchAction();
    }
    updateCampaign(params) {
        const apiAction = new ApiAction_1.ApiAction(types_1.UpdateCampaign.type, ApiAction_1.FuncType.Sync, params);
        apiAction.dispatchAction();
    }
}
exports.GoogleService = GoogleService;
